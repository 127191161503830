<template>
    <div class="container-fluid">
        
        <div class="row">
    
            <div class="col-lg-12 mb-3">
                <label>Question Title*</label>
                <div class="kt-input-icon">
                    <input type="text" class="form-control" :value="sitems.question_title" name="language_title" maxlength="200" placeholder="Question Title" @input="updateQuestionTitle" />
                </div>
                
            </div>
    
            <div class="col-lg-12 mb-3">
                <label>Required</label>
                <br />
                <div class="switch switch-sm switch-primary">
                    <input-switch name="require" :value="sitems.require" id="customSwitchesEx" class="custom-control-input" @change="updateIs_required" />
                </div>
            </div>
    
            <div class="col-lg-12 mb-3">
                <label>Question Type *</label>
                <!-- :modelValue="getQuestionType(surveyList.question_type)" -->
                <v-select name="questiontype" class="" placeholder="Question Type" label="type" track-by="id" :reduce="(option) => option.id" :options="questiontype" @option:selected="selectoptions" />
                    
           
                </div>
    
            <div class="col-lg-12 mt-3" v-if="showdiv==true && sitems.question_type!='textbox'">
                <label>Options</label>
                <input type="text" class="form-control" name="addtask" maxlength="200" placeholder="" v-model="newTask"   @keyup.enter="addtask"   />
               
                <span class="textcenter">To add multiple options please enter</span>
            </div>
    
            <div class="col-lg-12 taskItems" v-if="showdiv==true && sitems.question_type!='textbox'">
    
               
    
                <ul>
                    <!-- v-for="(task,index) in tasks" :key="index" -->
                    <li v-for="(answer, index) in  sitems.answers" :key="index" >
                       
                        <div class="input-group input-group-4 mb-3" v-if="answer.answer_title!=null">
                            <label class="p-3">Option</label>
                            <input   @input="updateQOptions" :target_id="answer._id" type="text" class="form-control" :value="answer.answer_title" name={{answer.answer_title}} maxlength="200" placeholder="" />
                          <!--
                                <input  @input="updateOptions" :target_id="answer._id" type="text" class="form-control" :value="answer.answer_title" name={{answer.answer_title}} maxlength="200" placeholder="" />
                            -->  
                        
                            <span class="input-group-text" @click="removeItem(answer._id)"><i class="fa fa-trash-alt"></i></span>
                        </div>
    
                    </li>
                </ul>
    
            </div>
    
            <div class="text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="createSurveyquestions()">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelQuestion">Cancel</button>
            </div>
    
        </div>
    </div>
    <FlashMessage group="survey_q_create" />
    
    </template>
    
    <script>
    import InputSwitch from "@/components/Commons/InputSwitch";
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import ObjectID from 'bson-objectid';
    import _, {
        clone
    } from "lodash";
    
    
    
    import {
        gettingErrorMessage
    } from "@/services/GetValidationMessage";
    //import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    
    import { ref } from 'vue';



    export default {
        data() {
            return {
                questiontype: [{
                    id: 1,
                    type: 'radio'
                }, {
                    id: 2,
                    type: 'checkbox'
                }, {
                    id: 3,
                    type: 'textbox'
                }],
                tasks: [],
                newTask: "",
                showdiv: false,
                newItem :'',
                items : []

    
            }
        },
        name: "SurveyQuestionCreate",
    
        props: {
    
            surveyList: {
                type: Object,
                default () {
                    return null;
                }
            },
            agenda_id: {
            type: [String, null],
            default () {
                return null;
            }
        },
    
            surveyid: String,
        },
    
        components: {
            InputSwitch,
           // ValidationMessages,
        },
    
        computed: {
            ...mapGetters('SurveysSingle', [
                'sitems',
            ]),
        },
    
    
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {           
               
                sitems: {
                   
                    question_title: {
                        required,
                    }
                },
    
                questiontype:{
                    required,
                }
    
            };
        },
    
        beforeUnmount() {
            this.resetState();
            delete this.$flashMessage.groups.survey_q_create;
     
            
        },
    
        mounted() {
            this.fetchsurveydata(this.agenda_id); //questions data 
           // this.fetchSurveyDataforFeedback(this.$route.params.event_id); //questions data   
    
            //var surv_que = {questionid:this.questionid,surveyid:this.surveyid};
            // this.fetchsurveydatainfo(surv_que);  
            //console.log('Question id is ',this.questionindex);   
    
        },
    
        methods: {
    
          //  ...mapActions('SurveysIndex', {
             //   fetchSurveyDataforFeedback: "fetchSurveyDataforFeedback"
         //   }), //questions data

         ...mapActions('SurveysIndex', {
            fetchsurveydata: "fetchSurveyData"
        }), //questions data
    
            ...mapActions('SurveysSingle', {
                setQItem: "setQItem",
                storeData: "storeData"
            }),
    
            ...mapActions('SurveysSingle', [
                'setIs_required',
                'setselectoptions',
                'setQuestionTitle',
                'resetState',
                'setQuestionstatus',
                'setOptionFunction',
                'setAnswersOptions'
            ]),
            updateQOptions(e){
                let multidata = {
                    answerText  : e.target.value,
                    answerId    : e.target.attributes.target_id.nodeValue
                };
                this.setAnswersOptions(multidata);
                
    
            },

    
            getQuestionType(question_type) {
                return _.has(question_type, '_id') ? question_type._id : question_type;
            },
    
            updateIs_required(e) {
                this.setIs_required(e.target.checked)
            },
    
            selectoptions(value) {  
                console.log("Value",value)          
                if (value.type == 'checkbox' || value.type == 'radio') {
                    this.showdiv = true;
                }
                this.setselectoptions(value.type);
            },
            addItem() {
                console.log("new item",this.newItem,this.items);
                if (this.newItem !== '') {
                    this.items.push(this.newItem);
                    this.newItem = '';
                }
            },

    
            // addtask() {
            //     if (this.newTask) {
            //         var shallow = _.clone(this.tasks); 
            //         console.log(shallow); 
            //         shallow.push({
            //             answer_title: this.newTask,
            //             description: this.newTask,
            //             _id: ObjectID(),
            //         })
            //         this.setQItem(shallow);
            //     }
            //     this.newTask = "";
            // },

            updateOptions(e) {
                let multidata = {
                    corevalue: e.target.attributes.target_id.nodeValue,
                    evalue: e.target.value
                };
                this.setOptionFunction(multidata);
            },
    
            addtask() {
                if (this.newTask) {
                    let objId = ObjectID();
                    var shallow = _.clone(this.sitems.answers);               
                    shallow.push({
                        answer_title    : this.newTask,
                        description     : this.newTask,
                        _id             : objId,
                        tempID          : objId.toString()
                    });
                    this.setQItem(shallow);
                }
    
                this.newTask = "";
            },
    
            // removeItem(index2) {
            //     console.log('indexxxxxxxxxxxxxxxxxxxx', index2)
            //     this.tasks.splice(index2, 1)
            // },
    
            removeItem(rmid) {
                console.log("This is removeItem",rmid)
            
    
                var asnRemove = _.clone(this.sitems.answers);
                asnRemove = _.remove(asnRemove, (currentObject) => currentObject._id !== rmid);
                this.setQItem(asnRemove);
    
            },
    
          
    
            async createSurveyquestions() {
    
                const isFormCorrect = await this.v$.$validate()   
    
    
                let generalError = []; 
                _.map(this.v$.$errors, (item) => {
                  if(item.$property == "question_title"){
                    generalError.push("<li>Question title is required</li>");
                  }                  
                });
                if(this.sitems.question_type === null){
                    generalError.push("<li>Question type is required</li>");
                  }
    
    
                
                    console.log('print general error',generalError)
                    if (!_.isEmpty(generalError)) {
                        this.$flashMessage.show({
                            group   : 'survey_index',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul>${generalError.join("")}</ul>`
                        });
                    return;
                }
                
    
    
    
    
    
                var sur_id = {
                    surveyid: this.surveyid
                };
    
    
    
    
    
                this.storeData(sur_id)
                    .then(async () => {
    
                        this.$flashMessage.show({
                            group : 'survey_index',
                            type  : 'success',
                            title : 'Success',
                            html  : '<ul><li>Question has been added successfully</li></ul>'
    
                        });
    
                        await this.fetchsurveydata(this.agenda_id);
                       // this.fetchSurveyDataforFeedback(this.$route.params.event_id)
    
                        //await this.fetchEventUsersAll(this.$route.params.event_id);
                       // this.resetState();
                        //this.dialog = false;
                    })
                    .catch((error) => {
                        console.error("Error update panel discussion", error)
                        let html = gettingErrorMessage(error);
    
                        this.$flashMessage.show({
                            group: 'survey_q_create',
                            type: 'error',
                            title: 'Error',
                            text: html || 'Something went wrong.',
                            html: html
                        });
                    })
            },
    
            updateQuestionTitle(e) {
                //console.log(e.target.value,'targetttttt')
                this.setQuestionTitle(e.target.value);
            },
    
            cancelQuestion() {           
                this.setQuestionstatus(false);
                this.fetchsurveydata(this.agenda_id);

            },
    
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .button {
        border: 1px solid white;
        border-radius: 2px;
        color: white;
        text-decoration: none;
        padding: 8px 15px;
        transition: all 0.3s ease;
        cursor: pointer;
    
        &--transparent {
            background-color: transparent;
        }
    
        &:hover {
            background-color: white;
            border: 1px solid #1fa9e5;
            color: #1fa9e5;
        }
    }
    
    .taskItems li {
        list-style: none;
        margin-bottom: 10px;
    }
    
    .taskItems ul {
        padding-left: 0px;
        margin-left: 0px;
    }
    
    .textcenter {
        text-align: center;
        margin-bottom: 20px;
        display: block;
    }
    </style>
    