<template>



    <div class="">
          <div class="modals" style="width:52%; height:80%;left:26%;top:10%">
     
    <body>
           <div class="fluid-container">
            <header class="modal-header">
              <slot name="header">Event Role / Permissions</slot>
              <button type="button" class="btn-close" @click="close">x</button>
            </header>
      
            <div class="container-fluid">
                <div class="row">
                        <div class="event_ls_label col-md-2">User </div>
                        <div class="col-md-8">
                          <select class="form-control" @change="UpdateOwner">
                            <option value="" selected>Select User</option>
                            <option v-for="option in allUsers" :value="option._id" :key="option._id">
                              {{ option.email }}
                            </option>
                          </select>
                        </div>
                  </div>

                <div class="row">
                        <div class="event_ls_label col-md-2">Role </div>
                        <div class="col-md-8">
                        <select class="form-control" @change="UpdateRole" >
                          <option value="" selected>Select Role</option>
                          
                          <option v-for="option in allRole" :value="option._id" :key="option._id">
                              {{ option.title }}
                            </option>
                        
                        </select>
                        </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                      <button class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="addUserRole">Add User</button>
      
                      <button class="mb-1 mt-1 me-1 btn btn-lg btn-warning"  @click="close">Cancel</button>
      
                </div>
              
              </div>
   
                   
            </div>
    
           </div>
        </body>
    
    
    </div>
    </div>
    </template>
    <style>
      .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .modals {
        background: #FFFFFF !important;
        box-shadow: 2px 2px 20px 1px !important;
        overflow-x: auto !important;
        display: flex !important;
        flex-direction: column !important;
        --bs-modal-zindex: 1055;
        --bs-modal-width: 500px;
        --bs-modal-padding: 1rem;
        --bs-modal-margin: 0.5rem;
        --bs-modal-color: ;
        --bs-modal-bg: #fff;
        --bs-modal-border-color: var(--bs-border-color-translucent);
        --bs-modal-border-width: 1px;
        --bs-modal-border-radius: 0.5rem;
        --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        --bs-modal-inner-border-radius: calc(0.5rem - 1px);
        --bs-modal-header-padding-x: 1rem;
        --bs-modal-header-padding-y: 1rem;
        --bs-modal-header-padding: 1rem 1rem;
        --bs-modal-header-border-color: var(--bs-border-color);
        --bs-modal-header-border-width: 1px;
        --bs-modal-title-line-height: 1.5;
        --bs-modal-footer-gap: 0.5rem;
        --bs-modal-footer-bg: ;
        --bs-modal-footer-border-color: var(--bs-border-color);
        --bs-modal-footer-border-width: 1px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-modal-zindex);
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
      }
    
      .modal-header,
      .modal-footer {
        padding: 15px;
        display: flex;
      }
    
      .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
      }
    
      .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
      }
    
      .modal-body {
        position: relative;
        padding: 20px 10px;
      }
    
      .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
      }
    
      .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
      }
      
    </style>
    
    <script>
    
    import constants from "@/config/constants";
    
    import {
      mapActions,
      mapGetters
    } from "vuex";
    export default {
      name: 'eventRole',
      methods: {
        close() {
          this.$emit('close');
          
        },
        ...mapActions('RolesIndex', ['fetchDataByAccessLevel', 'resetState']),
        ...mapActions("EventsSingle", [
          "setUserId",
          "setUserEmail",
          "setRoleId",
          'setRoleTitle',
          "resetState",
          'setOwners'
        ]),
        UpdateOwner(e){
          this.setUserId(e.target.value);
          this.setUserEmail(e.target.options[e.target.options.selectedIndex].text)
        },
        UpdateRole(e){
          this.setRoleId(e.target.value);
          this.setRoleTitle(e.target.options[e.target.options.selectedIndex].text)
        },
        addUserRole(){
          this.setOwners();
          this.$emit('close');
        }
     
      },
      created() {
        this.fetchDataByAccessLevel('event');
      },
      data() {
        return {
          IMAGE_URL: constants.IMAGE_URL
        }
      },
      computed: {
        ...mapGetters('RolesIndex', {allRole:'accessLevelRole'}),
  
        ...mapGetters("UsersIndex", { allUsers: 'all' })
      }
      
    };
    </script>