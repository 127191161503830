<template>
  <div class="searchable-container container-fluid" >
    <slot name="default" :all="filteredAll" />
    <div>
      <paginate
          v-if="perPage > 0"
          :pageCount="totalPages"
          :clickHandler="clickCallback"
          :prevText="'Previous'"
          :nextText="'Next'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Paginate from "vuejs-paginate-next";

export default {
  name: "SearchableContainer",
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      items: [],
      filteredAll: [],
      totalPages: 0,
      currentPage: 1,
    }
  },
  props: {
    all: {
      type: Array,
      required: true
    },
    search: {
      type: [String, null],
      required: false
    },
    perPage: {
      type: [Number, null],
      required: false
    }
  },
  created() {
    if (this.search === null || this.search === '') {
      this.items = this.all;
    }
  },
  methods: {
    clickCallback(pageNum) {
      const start = (pageNum - 1) * this.perPage;
      const stop = start + this.perPage;
      this.filteredAll = this.items.slice(start, stop);
    }
  },
  watch: {
    all(val) {
      if (this.search === null || this.search === '') {
        this.items = val;
      }

      this.totalPages = Math.ceil(this.items.length / this.perPage);
      this.filteredAll = this.items.slice(0, this.perPage);
    },
    perPage(val) {
      this.totalPages = Math.ceil(this.items.length / val);
      this.filteredAll = this.items.slice(0, val);
    },
    search(val) {
      if (val === null || val === '') {
        this.items = this.all;
      } else {
        this.items = _.filter(this.all, (item) => {
          let text = JSON.stringify(item);
          let position = text.search(new RegExp(this.search, "i"))
          if(position !== -1){
            return true
          }
          return false;
          /* _.includes(v, val) can be also updated with params for property */
          //const includesValue = (val, obj) => _.some(obj, v => _.includes(v, val))
          //return includesValue(this.search, item);
        })
      }

      this.totalPages = Math.ceil(this.items.length / this.perPage);
      this.filteredAll = this.items.slice(0, this.perPage);
    }
  }
}
</script>

<style scoped>
.pagination {
  margin-top: 20px;
  justify-content: flex-end;
}
</style>