import axios from "axios";
import constants from "@/config/constants";
import moment from "moment";
import _ from "lodash";

function initialState() {
    return {
        item: {
            id: null,
            event_id: null,
            event_users: null,
            event_users_s: null,
            title: null,
            title_English: null,
            title_German: null,
            title_Spanish: null,
            title_French: null,
            title_Portuguese: null,
            description: null,
            description_English: null,
            description_German: null,
            description_Spanish: null,
            description_French: null,
            description_Portuguese: null,
            location: "",
            event_location: null,
            start_time: null,
            end_time: null,
            is_restricted: false,
            join_with_video: true,
            minutes: 0,
            exhibitor: null,
            images: [],
            uploaded_images: [],
            videos: null,
            logo: null,
            uploaded_videos: [],
            documents: null,
            uploaded_documents: [],
            webcast_link: null,
            is_live: 0,
            is_training: 0,
            agenda_type: 'webcast',
            channel_type: 'STANDARD',
            configuration_type: 'default',
            latency: 'LOW',
            recording: 'disabled',
            videos_deleted: 0,
            documents_deleted: 0,
            logo_deleted: 0,
            session_theme_deleted: 0,
            left_first_deleted: 0,
            left_second_deleted: 0,
            left_third_deleted: 0,
            right_first_deleted: 0,
            right_second_deleted: 0,
            right_third_deleted: 0,
            attendees: [],
            max_no_of_attendees: null,
            is_open: false,
            is_rating: false,
            screen_sharing: false,
            status: true,
            enable_chat: true,
            enable_qa: true,
            enable_resources: true,
            session_theme: null,
            left_first: null,
            left_second: null,
            left_third: null,
            right_first: null,
            right_second: null,
            right_third: null,
            no_of_boards: null,
            qa_type: 0,
            banner_deleted: 0,
            background_color: '#999999',
            background_color_bottom: '#999999',
            notes_color: '#62cbbf',
            background_stripes_color: '#14ac35',
            podium_color: '#62cbbf',
            podium_picture: null,
            speaker_picture: null,
            left1_pillar_color: '#6dc2e6',
            left2_pillar_color: '#6dc2e6',
            right1_pillar_color: '#6dc2e6',
            right2_pillar_color: '#6dc2e6',
            stage_color1: '#999999',
            stage_color2: '#cccccc',
            banner_long1: null,
            banner_long2: null,
            banner_long3: null,
            banner_long4: null,
            banner_long5: null,
            banner_long6: null,
            banner_long7: null,
            banner_long8: null,
            banner_long9: null,
            banner_long10: null,
            banner_long11: null,
            banner_medium1: null,
            banner_medium2: null,
            banner_medium3: null,
            banner_medium4: null,
            banner_medium5: null,
            banner_medium6: null,
            left1_pillar_logo1: null,
            left1_pillar_logo2: null,
            left1_pillar_logo3: null,
            left2_pillar_logo1: null,
            left2_pillar_logo2: null,
            left2_pillar_logo3: null,
            right1_pillar_logo1: null,
            right1_pillar_logo2: null,
            right1_pillar_logo3: null,
            right2_pillar_logo1: null,
            right2_pillar_logo2: null,
            right2_pillar_logo3: null,
            background_picture: null,
            background_picture_agenda: null,
            banner_long1_delete: 0,
            banner_long2_delete: 0,
            banner_long3_delete: 0,
            banner_long4_delete: 0,
            banner_long5_delete: 0,
            banner_long6_delete: 0,
            banner_long7_delete: 0,
            banner_long8_delete: 0,
            banner_long9_delete: 0,
            banner_long10_delete: 0,
            banner_long11_delete: 0,
            banner_medium1_delete: 0,
            banner_medium2_delete: 0,
            banner_medium3_delete: 0,
            banner_medium4_delete: 0,
            banner_medium5_delete: 0,
            banner_medium6_delete: 0,
            pillar_left1_logo1_delete: 0,
            pillar_left1_logo2_delete: 0,
            pillar_left1_logo3_delete: 0,
            pillar_left2_logo1_delete: 0,
            pillar_left2_logo2_delete: 0,
            pillar_left2_logo3_delete: 0,
            pillar_right1_logo1_delete: 0,
            pillar_right1_logo2_delete: 0,
            pillar_right1_logo3_delete: 0,
            pillar_right2_logo1_delete: 0,
            pillar_right2_logo2_delete: 0,
            pillar_right2_logo3_delete: 0,
            podium_picture_delete: 0,
            speaker_picture_delete: 0,
            background_picture_delete: 0,
            background_picture_agenda_delete: 0,
            skybox: null,
            environment: null,
            skybox_is_custom: 1,
            webcast_region: { "id": 'us-west-2', "title": "US West (Oregon)" },
            streaming_device: { "id": 'external_camera', "title": "External Camera" },
            channel_name: null,
            environment_is_custom: 1,
            is_background_picture: 1,
            wall_left_color: "#8788a1",
            wall_right_color: "#8788a1",
            wall_front_color: "#8788a1",
            wall_back_color: "#8788a1",
            skybox_picture: null,
            environment_picture: null,
            is_live_stream: false,
            stream_url: null,
            interpretation: {
                is_interprefy_type_enabled: 0,
                interprefy_token: null,
                is_aws_type_enabled: 0,
                is_aws_speech_to_speech_enabled: 0,
                is_aws_speech_to_text_enabled: 0,
                is_chat_type_enabled: 0,
            },
            south_america_delay: 0,
            north_america_delay: 0,
            central_america_delay: 0,
            europe_delay: 0,
            asia_delay: 0,
            australia_delay: 0,
            oceania_delay: 0,
            middle_east_delay: 0,
            africa_delay: 0,
            south_africa_delay: 0,
            north_africa_delay: 0,



        },
        eventsAll: {
            plan: {
                is_aws_interpretation_active: false,
                is_chat_interpretation_active: false,
                is_interprefy_interpretation_active: false
            }
        },
        eventusersAll: [],
        eventusersAllattendees: [],
        exhibitorsAll: [],
        eventlocationsAll: [],
        event_timezone: null,
        event_type: null,
        loading: false,
        limit_error: "",
        max_cap_training_room: [{ "id": 10, "title": "10" },
            { "id": 20, "title": "20" },
            { "id": 30, "title": "30" }],
        no_of_boardsEnum: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' },],
        skyboxes: [
            { "id": 'Day Time', "title": "Day Time" },
            { "id": 'Night Time', "title": "Night Time" },
            { "id": 'Dusk', "title": "Dusk" },

        ],
        environments: [
            { "id": 'Mountains', "title": "US West (Oregon)" },
            { "id": 'City Landscape', "title": "City Landscape" },
            { "id": 'Forest', "title": "Forest" },

        ],
        webcastRegions: [
            { "id": 'us-west-2', "title": "US West (Oregon)" },
            { "id": 'us-east-1', "title": "US East (N. Virginia)" },
            { "id": 'eu-west-1', "title": "Europe (Ireland)" },

        ],
        streamingDevices: [
            { "id": 'external_camera', "title": "External Camera" },
            { "id": 'build_in_webcam', "title": "Build in webcam" },

        ],
        is_new: false,
        is_editing: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    eventsAll: state => state.eventsAll,
    eventusersAll: state => state.eventusersAll,
    eventusersAllattendees: state => state.eventusersAllattendees,
    exhibitorsAll: state => state.exhibitorsAll,
    eventlocationsAll: state => state.eventlocationsAll,
    event_timezone: state => state.event_timezone,
    event_type: state => state.event_type,
    limit_error: state => state.limit_error,
    max_cap_training_room: state => state.max_cap_training_room,
    no_of_boardsEnum: state => state.no_of_boardsEnum,
    skyboxes: state => state.skyboxes,
    environments: state => state.environments,
    webcastRegions: state => state.webcastRegions,
    streamingDevices: state => state.streamingDevices,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
}

const actions = {
    getAdminSettings({ commit, state, dispatch }) {

       return axios.get(constants.MOCK_API_URL + '/api/v1/get_admin_settings/max_cap_' + state.item.agenda_type + '_room')
            .then(response => {
                if (response.data.data[0] && response.data.data[0].value > 0 && response.data.data[0].value <= 10) {
                    commit('setMax_cap_training_room', [{ "id": 10, "title": "10" }]);
                } else if (response.data.data[0] && response.data.data[0].value > 10 && response.data.data[0].value <= 20) {
                    commit('setMax_cap_training_room', [{ "id": 10, "title": "10" }, { "id": 20, "title": "20" }]);
                } else if (response.data.data[0] && response.data.data[0].value > 20 && response.data.data[0].value <= 30) {
                    commit('setMax_cap_training_room', [{ "id": 10, "title": "10" }, { "id": 20, "title": "20" }, { "id": 30, "title": "30" }]);
                }
                //state.max_cap_training_room = response.data.data[0].value
                // debugger;
            })

    },
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/' + event_id)
            .then(response => {
                commit('setEventTimezone', response.data.data[0].time_zone)
                commit('setEventType', response.data.data[0].event_type);
                // state.event_type = response.data.data[0].event_type
                if (state.event_type == 'virtual_exhibition' && state.item.is_live && state.item.location == "") {
                    commit('setLocation', 'Auditorium Live');
                }
                moment.tz.setDefault(state.event_timezone);
            })

    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        const params = _.cloneDeep(state.item);

        return axios.post(constants.MOCK_API_URL + '/api/v1/agendas', params)
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        return axios.post(constants.MOCK_API_URL + '/api/v1/agendas/update/' + state.item.id, params)
            .then(response => {
                commit('setItem', response.data.data);
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ state, commit, dispatch }, params) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/agendas/' + params.id)
            .then(response => {
                //  var start_time = response.data.data.start_time
                //  var end_time = response.data.data.start_time
                commit('setEventTimezone', response.data.data.event.time_zone);
                moment.tz.setDefault(state.event_timezone);
                console.log(state.event_timezone)

                let agendaData = response.data.data
                //debugger;
                //agendaData.map((obj, i) => {
                if (agendaData.event_users) {
                    agendaData.event_users.first_name = agendaData.event_users.first_name + " " + agendaData.event_users.last_name;
                }
                if (agendaData.event_users_s) {
                    agendaData.event_users_s.first_name = agendaData.event_users_s.first_name + " " + agendaData.event_users_s.last_name;
                }
                if (agendaData.attendees) {
                    agendaData.attendees.map((obj, i) => {
                        agendaData.attendees[i].first_name = obj.first_name + " " + obj.last_name;
                    })
                    //agendaData.attendees.first_name = agendaData.attendees.first_name + " " + agendaData.attendees.last_name;
                }
                if (agendaData.exhibitor) {
                    agendaData.exhibitor.name = agendaData.exhibitor['name_' + agendaData.event.language[0].language]
                    // agendaData.exhibitor.name = agendaData.event.language[0].language === 'English' ? agendaData.exhibitor.name_English : agendaData.event.language[0].language === 'German' ? agendaData.exhibitor.name_German : agendaData.event.language[0].language === 'Spanish' ? agendaData.exhibitor.name_Spanish : agendaData.event.language[0].language === 'French' ? agendaData.exhibitor.name_French : agendaData.exhibitor.name;
                }
                //agendaData.event_users.first_name = agendaData.event_users.first_name + " " + agendaData.event_users.last_name;
                //})

                var skyboxes = [
                    { "id": 'Day Time', "title": "Day Time" },
                    { "id": 'Night Time', "title": "Night Time" },
                    { "id": 'Dusk', "title": "Dusk" },

                ];
                var environments = [
                    { "id": 'Mountains', "title": "Mountains" },
                    { "id": 'City Landscape', "title": "City Landscape" },
                    { "id": 'Forest', "title": "Forest" },

                ];

                var webcastRegion = [
                    { "id": 'us-west-2', "title": "US West (Oregon)" },
                    { "id": 'us-east-1', "title": "US East (N. Virginia)" },
                    { "id": 'eu-west-1', "title": "Europe (Ireland)" },
                ];

                var streamingDevices = [
                    { "id": 'external_camera', "title": "External Camera" },
                    { "id": 'build_in_webcam', "title": "Build in webcam" },

                ];
                //var res = roomData;

                let selected_webcastRegion = webcastRegion.find(obj => {
                    return obj.id === agendaData.webcast_region;
                })
                agendaData.webcast_region = selected_webcastRegion;



                let selected_device = streamingDevices.find(obj => {
                    return obj.id === agendaData.streaming_device;
                })
                agendaData.streaming_device = selected_device;


                let selected_skybox = skyboxes.find(obj => {
                    return obj.id === agendaData.skybox;
                })
                agendaData.skybox = selected_skybox;

                let selected_env = environments.find(obj => {
                    return obj.id === agendaData.environment;
                })
                agendaData.environment = selected_env;

                agendaData.interpretation = agendaData.interpretation ? agendaData.interpretation : state.item.interpretation;

                commit('setItem', agendaData)

                commit('setLocalTime', 'start_time')
                commit('setLocalTime', 'end_time')

                //this.$route.params.event_id
                dispatch('fetchEventsAll', params.event_id)
                dispatch('fetchEventusersAll', params.event_id)
                dispatch('fetchEventusersAllattendees', params.event_id)
                dispatch('fetchExhibitorsAll', params.event_id)
                dispatch('fetchEventlocationsAll', params.event_id)
            })

    },
    fetchExhibitorsAll({ commit }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event/' + event_id + '/exhibitors')
            .then(response => {
                if (response.data.data.length) {
                    let data = response.data.data
                    data.map((obj, i) => {
                        data[i].name = obj['name_' + obj.event.language[0].language]
                        //data[i].name = obj.event.language[0].language === 'English' ? obj.name_English : obj.event.language[0].language === 'German' ? obj.name_German : obj.event.language[0].language === 'Spanish' ? obj.name_Spanish : obj.event.language[0].language === 'French' ? obj.name_French : obj.name;
                    })
                    //debugger;
                    //console.log(data)
                    commit('setExhibitorsAll', data)
                } else {
                    commit('setExhibitorsAll', response.data.data)
                }
            })
    },
    fetchEventlocationsAll({ commit }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event/' + event_id + '/event-locations')
            .then(response => {
                commit('setEventlocationsAll', response.data.data)
            })
    },
    fetchEventsAll({ commit }, event_id) {
        commit('setEvent', event_id)
        return axios.get(constants.MOCK_API_URL + '/api/v1/events/' + event_id)
            .then(response => {
                commit('setEventAll', response.data.data)
            })
    },
    fetchEventusersAll({ commit }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_users_detail/' + event_id)
            .then(response => {
                let eventUsers = response.data.data
                //debugger;
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })
                //debugger;
                //res.event_type = usersFullname;
                commit('setEventusersAll', eventUsers)
            })
    },
    fetchEventusersAllattendees({ commit }, event_id) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_users_attendees/' + event_id)
            .then(response => {
                let eventUsers = response.data.data
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })
                //res.event_type = usersFullname;
                commit('setEventusersAllattendees', eventUsers)
            })
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setEvent_users({ commit }, value) {
        commit('setEvent_users', value)
    },
    setEvent_users_s({ commit }, value) {
        commit('setEvent_users_s', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setDelaySouthAmerica({ commit }, value) {
        commit('setDelaySouthAmerica', value)
    },
    setDelayNorthAmerica({ commit }, value) {
        commit('setDelayNorthAmerica', value)
    },
    setDelayCentralAmerica({ commit }, value) {
        commit('setDelayCentralAmerica', value)
    },
    setDelayEurope({ commit }, value) {
        commit('setDelayEurope', value)
    },
    setDelayAsia({ commit }, value) {
        commit('setDelayAsia', value)
    },
    setDelayAustralia({ commit }, value) {
        commit('setDelayAustralia', value)
    },
    setDelayOceania({ commit }, value) {
        commit('setDelayOceania', value)
    },
    setDelayMiddleEast({ commit }, value) {
        commit('setDelayMiddleEast', value)
    },
    setDelayAfrica({ commit }, value) {
        commit('setDelayAfrica', value)
    },
    setDelaySouthAfrica({ commit }, value) {
        commit('setDelaySouthAfrica', value)
    },
    setDelayNorthAfrica({ commit }, value) {
        commit('setDelayNorthAfrica', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescription({ commit }, value) {
        commit('setLangDescription', value)
    },
    setLocation({ commit }, value) {
        commit('setLocation', value)
    },
    setIs_restricted({ commit }, value) {
        commit('setIs_restricted', value)
    },
    setJoinWithVideo({ commit }, value) {
        commit('setJoinWithVideo', value)
    },
    setMinutes({ commit }, value) {
        commit('setMinutes', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },
    setExhibitor({ commit }, value) {
        commit('setExhibitor', value)
    },
    setEvent_location({ commit }, value) {
        commit('setEvent_location', value)
    },
    setIs_live({ commit }, value) {
        commit('setIs_live', value)
    },
    setIs_training({ commit }, value) {
        commit('setIs_training', value)
    },
    setAgenda_type({ commit }, value) {
        commit('setAgenda_type', value)
    },
    setChannelType({ commit }, value) {
        commit('setChannelType', value)
    },
    setConfigurationType({ commit }, value) {
        commit('setConfigurationType', value)
    },
    setLatency({ commit }, value) {
        commit('setLatency', value)
    },
    setRecording({ commit }, value) {
        commit('setRecording', value)
    },
    setImages({ commit }, value) {
        commit('setImages', value)
    },
    destroyImages({ commit }, value) {
        commit('destroyImages', value)
    },
    destroyUploadedImages({ commit }, value) {
        commit('destroyUploadedImages', value)
    },
    setVideos({ commit }, value) {
        commit('setVideos', value)
    },
    setLogo({ commit }, value) {
        commit('setLogo', value)
    },
    setSessionTheme({ commit }, value) {
        commit('setSessionTheme', value)
    },
    setBanner({ commit }, value) {
        commit('setBanner', value)
    },
    destroyVideos({ commit }, value) {
        commit('destroyVideos', value)
    },
    destroySessionTheme({ commit }, value) {
        commit('destroySessionTheme', value)
    },
    destroyUploadedVideos({ commit }, value) {
        commit('destroyUploadedVideos', value)
    },
    setDocuments({ commit }, value) {
        commit('setDocuments', value)
    },
    destroyDocuments({ commit }, value) {
        commit('destroyDocuments', value)
    },
    destroyUploadedDocuments({ commit }, value) {
        commit('destroyUploadedDocuments', value)
    },
    setWebcast_link({ commit }, value) {
        commit('setWebcast_link', value)
    },
    setAttendees({ commit }, value) {
        commit('setAttendees', value)
    },
    setMax_no_of_attendees({ commit }, value) {
        commit('setMax_no_of_attendees', value)
    },
    setIs_open({ commit }, value) {
        commit('setIs_open', value)
    },
    setIsLiveStream({ commit }, value) {
        commit('setIsLiveStream', value)
    },
    setStreamUrl({ commit }, value) {
        commit('setStreamUrl', value)
    },
    setChannelName({ commit }, value) {
        commit('setChannelName', value)
    },
    setIs_rating({ commit }, value) {
        commit('setIs_rating', value)
    },
    setScreenSharing({ commit }, value) {
        commit('setScreenSharing', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setEnableChat({ commit }, value) {
        commit('setEnableChat', value)
    },
    setEnableQA({ commit }, value) {
        commit('setEnableQA', value)
    },
    setEnableResources({ commit }, value) {
        commit('setEnableResources', value)
    },
    setQa_type({ commit }, value) {
        commit('setQa_type', value)
    },
    setLeft_first({ commit }, value) {
        commit('setLeft_first', value)
    },

    setLeft_second({ commit }, value) {
        commit('setLeft_second', value)
    },

    setLeft_third({ commit }, value) {
        commit('setLeft_third', value)
    },

    setRight_first({ commit }, value) {
        commit('setRight_first', value)
    },

    setRight_second({ commit }, value) {
        commit('setRight_second', value)
    },

    setRight_third({ commit }, value) {
        commit('setRight_third', value)
    },
    setNo_of_boards({ commit }, value) {
        commit('setNo_of_boards', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setNotesColor({ commit }, value) {
        commit('setNotesColor', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setShowText({ commit }, value) {
        commit('setShowText', value)
    },
    setWallLeftColor({ commit, dispatch }, value) {
        commit('setWallLeftColor', value)
    },
    setWallRightColor({ commit, dispatch }, value) {
        commit('setWallRightColor', value)
    },
    setWallFrontColor({ commit, dispatch }, value) {
        commit('setWallFrontColor', value)
    },
    setWallBackColor({ commit, dispatch }, value) {
        commit('setWallBackColor', value)
    },
    setSkybox({ commit }, value) {
        commit('setSkybox', value)
    },
    setEnvironment({ commit }, value) {
        commit('setEnvironment', value)
    },
    setWebcastRegion({ commit }, value) {
        commit('setWebcastRegion', value)
    },
    setStreamingDevice({ commit }, value) {
        commit('setStreamingDevice', value)
    },
    setSkyboxPicture({ commit }, value) {
        commit('setSkyboxPicture', value)
    },
    setEnvironmentPicture({ commit }, value) {
        commit('setEnvironmentPicture', value)
    },
    setSkybox_is_custom({ commit }, value) {
        commit('setSkybox_is_custom', value)
    },
    setIsBackgroundPicture({ commit }, value) {
        commit('setIsBackgroundPicture', value)
    },
    setEnvironment_is_custom({ commit }, value) {
        commit('setEnvironment_is_custom', value)
    },
    setBannerLong1({ commit }, value) {
        commit('setBannerLong1', value)
    },
    setBannerLong2({ commit }, value) {
        commit('setBannerLong2', value)
    },
    setBannerLong3({ commit }, value) {
        commit('setBannerLong3', value)
    },
    setBannerLong4({ commit }, value) {
        commit('setBannerLong4', value)
    },
    setBannerLong5({ commit }, value) {
        commit('setBannerLong5', value)
    },
    setBannerLong6({ commit }, value) {
        commit('setBannerLong6', value)
    },
    setBannerLong7({ commit }, value) {
        commit('setBannerLong7', value)
    },
    setBannerLong8({ commit }, value) {
        commit('setBannerLong8', value)
    },
    setBannerLong9({ commit }, value) {
        commit('setBannerLong9', value)
    },
    setBannerLong10({ commit }, value) {
        commit('setBannerLong10', value)
    },
    setBannerLong11({ commit }, value) {
        commit('setBannerLong11', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setBackgroundPictureAgenda({ commit }, value) {
        commit('setBackgroundPictureAgenda', value)
    },
    setStageColor1({ commit }, value) {
        commit('setStageColor1', value)
    },
    setStageColor2({ commit }, value) {
        commit('setStageColor2', value)
    },
    setBackgroundStripesColor({ commit }, value) {
        commit('setBackgroundStripesColor', value)
    },
    setPodiumColor({ commit }, value) {
        commit('setPodiumColor', value)
    },
    setPodiumPicture({ commit }, value) {
        commit('setPodiumPicture', value)
    },
    setSpeakerPicture({ commit }, value) {
        commit('setSpeakerPicture', value)
    },
    setBannerMedium1({ commit }, value) {
        commit('setBannerMedium1', value)
    },
    setBannerMedium2({ commit }, value) {
        commit('setBannerMedium2', value)
    },
    setBannerMedium3({ commit }, value) {
        commit('setBannerMedium3', value)
    },
    setBannerMedium4({ commit }, value) {
        commit('setBannerMedium4', value)
    },
    setBannerMedium5({ commit }, value) {
        commit('setBannerMedium5', value)
    },
    setBannerMedium6({ commit }, value) {
        commit('setBannerMedium6', value)
    },
    setRight1PillarColor({ commit }, value) {
        commit('setRight1PillarColor', value)
    },
    setRight2PillarColor({ commit }, value) {
        commit('setRight2PillarColor', value)
    },
    setLeft1PillarBottomColor({ commit }, value) {
        commit('setLeft1PillarBottomColor', value)
    },
    setLeft2PillarBottomColor({ commit }, value) {
        commit('setLeft2PillarBottomColor', value)
    },
    setLeft1PillarColor({ commit }, value) {
        commit('setLeft1PillarColor', value)
    },
    setLeft2PillarColor({ commit }, value) {
        commit('setLeft2PillarColor', value)
    },
    setRight1PillarBottomColor({ commit }, value) {
        commit('setRight1PillarBottomColor', value)
    },
    setRight2PillarBottomColor({ commit }, value) {
        commit('setRight2PillarBottomColor', value)
    },
    setPillarLeft1Logo1({ commit }, value) {
        commit('setPillarLeft1Logo1', value)
    },
    setPillarLeft1Logo2({ commit }, value) {
        commit('setPillarLeft1Logo2', value)
    },
    setPillarLeft1Logo3({ commit }, value) {
        commit('setPillarLeft1Logo3', value)
    },
    setPillarLeft2Logo1({ commit }, value) {
        commit('setPillarLeft2Logo1', value)
    },
    setPillarLeft2Logo2({ commit }, value) {
        commit('setPillarLeft2Logo2', value)
    },
    setPillarLeft2Logo3({ commit }, value) {
        commit('setPillarLeft2Logo3', value)
    },
    setPillarRight1Logo1({ commit }, value) {
        commit('setPillarRight1Logo1', value)
    },
    setPillarRight1Logo2({ commit }, value) {
        commit('setPillarRight1Logo2', value)
    },
    setPillarRight1Logo3({ commit }, value) {
        commit('setPillarRight1Logo3', value)
    },
    setPillarRight2Logo1({ commit }, value) {
        commit('setPillarRight2Logo1', value)
    },
    setPillarRight2Logo2({ commit }, value) {
        commit('setPillarRight2Logo2', value)
    },
    setPillarRight2Logo3({ commit }, value) {
        commit('setPillarRight2Logo3', value)
    },
    setInterpretation({ commit }, value) {
        commit('setInterpretation', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setEvent_users(state, value) {
        state.item.event_users = value
    },
    setEvent_users_s(state, value) {
        state.item.event_users_s = value
    },
    setEventTimezone(state, value) {
        state.event_timezone = value;
    },
    setEventType(state, value) {
        state.event_type = value;
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setDelay(state, value) {
        state.item.delay = value
    },
    setDelaySouthAmerica(state, value) {
        state.item.south_america_delay = value
    },
    setDelayNorthAmerica(state, value) {
        state.item.north_america_delay = value
    },
    setDelayCentralAmerica(state, value) {
        state.item.central_america_delay = value
    },
    setDelayEurope(state, value) {
        state.item.europe_delay = value
    },
    setDelayAsia(state, value) {
        state.item.asia_delay = value
    },
    setDelayAustralia(state, value) {
        state.item.australia_delay = value
    },
    setDelayOceania(state, value) {
        state.item.oceania_delay = value
    },
    setDelayMiddleEast(state, value) {
        state.item.middle_east_delay = value
    },
    setDelayAfrica(state, value) {
        state.item.africa_delay = value
    },
    setDelaySouthAfrica(state, value) {
        state.item.south_africa_delay = value
    },
    setDelayNorthAfrica(state, value) {
        state.item.north_africa_delay = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setLangTitle(state, value) {
        var name_lang = value.lang_name
        state.item['title_' + name_lang] = value.value

        // switch (name_lang) {
        //     case 'English':
        //         c
        //         break;
        //     case 'German':
        //         state.item.title_German = value.value
        //         break;
        //     case 'Spanish':
        //         state.item.title_Spanish = value.value
        //         break;
        //     case 'French':
        //         state.item.title_French = value.value
        //         break;
        //     default:
        //         state.item.title = value.value

        // }
    },
    setLangDescription(state, value) {
        var name_lang = value.lang_name

        state.item['description_' + name_lang] = value.value
        // switch (name_lang) {
        //     case 'English':
        //         state.item.description_English = value.value
        //         break;
        //     case 'German':
        //         state.item.description_German = value.value
        //         break;
        //     case 'Spanish':
        //         state.item.description_Spanish = value.value
        //         break;
        //     case 'French':
        //         state.item.description_French = value.value
        //         break;
        //     default:
        //         state.item.description = value.value

        // }
    },
    setIs_restricted(state, value) {
        state.item.is_restricted = value

    },
    setJoinWithVideo(state, value) {
        state.item.join_with_video = value
    },
    setMinutes(state, value) {
        state.item.minutes = value
    },
    setLocation(state, value) {
        state.item.location = value
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setEventAll(state, value) {
        // debugger;
        state.eventsAll = value
    },
    setEventusersAll(state, value) {
        state.eventusersAll = value
    },
    setEventusersAllattendees(state, value) {
        state.eventusersAllattendees = value
    },
    setExhibitor(state, value) {
        state.item.exhibitor = value
    },
    setAttendees(state, value) {
        let max_attendees = null;
        if (state.item.max_no_of_attendees && state.item.max_no_of_attendees.id) {
            max_attendees = parseInt(state.item.max_no_of_attendees.id);
        }

        if (value && max_attendees) {
            if (value.length <= max_attendees) {
                //debugger
                state.item.attendees = value
            } else {
                state.limit_error = "Maximum " + max_attendees + " attendees can be selected."
                //$('#attendees').hide();
                state.item.attendees.pop()
            }
        } else {
            state.item.attendees = value
        }
    },
    setMax_no_of_attendees(state, value) {
        if (typeof value != "object") {
            value = { id: value, title: value }
        }
        state.item.max_no_of_attendees = value
    },
    setMax_cap_training_room(state, value) {
        state.max_cap_training_room = value;
    },
    setIs_open(state, value) {
        state.item.is_open = value
        if (value) {
            state.item.max_no_of_attendees = null
            state.item.attendees = []
        }
    },
    setIsLiveStream(state, value) {
        state.item.is_live_stream = value

    },
    setStreamUrl(state, value) {
        state.item.stream_url = value

    },
    setChannelName(state, value) {
        state.item.channel_name = value
    },
    setIs_rating(state, value) {
        state.item.is_rating = value
    },
    setScreenSharing(state, value) {
        state.item.screen_sharing = value
    },
    setStatus(state, value) {
        state.item.status = value
    },
    setEnableChat(state, value) {
        state.item.enable_chat = value
    },
    setEnableQA(state, value) {
        state.item.enable_qa = value
    },
    setEnableResources(state, value) {
        state.item.enable_resources = value
    },
    setQa_type(state, value) {
        state.item.qa_type = value ? 1 : 0
    },
    setLeft_first(state, value) {
        state.item.left_first = value
    },
    setLeft_second(state, value) {
        state.item.left_second = value
    },
    setLeft_third(state, value) {
        state.item.left_third = value
    },
    setRight_first(state, value) {
        state.item.right_first = value

    },
    setRight_second(state, value) {
        state.item.right_second = value

    },
    setRight_third(state, value) {
        state.item.right_third = value

    },
    setNo_of_boards(state, value) {
        state.item.no_of_boards = value
    },
    setNo_of_boardsEnum(state, value) {
        state.no_of_boardsEnum = value
    },
    setImages(state, value) {
        for (let i in value) {
            let images = value[i];
            if (typeof images === "object") {
                state.item.images.push(images);
            }
        }
    },
    destroyImages(state, value) {
        for (let i in state.item.images) {
            if (i == value) {
                state.item.images.splice(i, 1);
            }
        }
    },
    destroyUploadedImages(state, value) {
        for (let i in state.item.uploaded_images) {
            let data = state.item.uploaded_images[i];
            if (data.id === value) {
                state.item.uploaded_images.splice(i, 1);
            }
        }
    },
    setVideos(state, value) {
        state.item.videos = value
    },
    setLogo(state, value) {
        state.item.logo = value
    },
    setSessionTheme(state, value) {
        state.item.session_theme = value
    },
    setBackgroundColor(state, value) {
        state.item.background_color = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setStageColor1(state, value) {
        state.item.stage_color1 = value
    },
    setStageColor2(state, value) {
        state.item.stage_color2 = value
    },
    setBackgroundStripesColor(state, value) {
        state.item.background_stripes_color = value
    },
    setNotesColor(state, value) {
        state.item.notes_color = value
    },
    setBanner(state, value) {
        state.item.banner = value
    },
    destroySessionTheme(state, value) {
        for (let i in state.item.session_theme) {
            if (i == value) {
                state.item.session_theme.splice(i, 1);
            }
        }
    },
    destroyVideos(state, value) {
        for (let i in state.item.videos) {
            if (i == value) {
                state.item.videos.splice(i, 1);
            }
        }
    },
    destroyUploadedVideos(state, value) {
        for (let i in state.item.uploaded_videos) {
            let data = state.item.uploaded_videos[i];
            if (data.id === value) {
                state.item.uploaded_videos.splice(i, 1);
            }
        }
    },
    setDocuments(state, value) {
        state.item.documents = value
    },
    destroyDocuments(state, value) {
        for (let i in state.item.documents) {
            if (i == value) {
                state.item.documents.splice(i, 1);
            }
        }
    },
    destroyUploadedDocuments(state, value) {
        for (let i in state.item.uploaded_documents) {
            let data = state.item.uploaded_documents[i];
            if (data.id === value) {
                state.item.uploaded_documents.splice(i, 1);
            }
        }
    },
    setWebcast_link(state, value) {
        state.item.webcast_link = value
    },
    setExhibitorsAll(state, value) {
        state.exhibitorsAll = value
    },
    setEvent_location(state, value) {
        state.item.event_location = value
    },
    setEventlocationsAll(state, value) {
        state.eventlocationsAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setUTCTime(state, type) {

        if (type == 'start_time') {

            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
            var localTime = moment.tz(state.item.start_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm:ss');

            // var ct = new Date(usaTime).toUTCString();
        }
        if (type == 'end_time') {
            var localTime = moment.tz(state.item.end_time, state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
            var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
        }

        if (type == 'start_time') {
            state.item.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setIs_live(state, value) {
        state.item.is_live = value
        if (state.item.is_live) {
            state.item.is_training = false;
            state.item.location = "Auditorium Live"
        } else {
            state.item.location = "Auditorium Pre-Recorded"
        }
    },
    setIs_training(state, value) {
        state.item.is_training = value
        if (state.item.is_training) {
            state.item.is_live = false
            state.item.location = "Training Room"
        } else {
            state.item.location = "Auditorium Pre-Recorded"
        }
    },
    setAgenda_type(state, value) {
        state.item.agenda_type = value
        if (state.item.agenda_type === 'training') {
            state.item.location = "Training Room"
        } else if (state.item.agenda_type === 'live') {
            state.item.location = "Auditorium Live"
        } else {
            state.item.location = "Auditorium Pre-Recorded"
        }
    },
    setChannelType(state, value) {
        state.item.channel_type = value
    },
    setConfigurationType(state, value) {
        state.item.configuration_type = value
    },
    setLatency(state, value) {
        state.item.latency = value
    },
    setRecording(state, value) {
        state.item.recording = value
    },
    setLocalTime(state, type) {
        if (type == 'start_time') {
            var value = state.item.start_time;
        }
        if (type == 'end_time') {
            var value = state.item.end_time;
        }
        var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm:ss');
        var formatedDate = moment(String(date)).format('YYYY-MM-DD HH:mm:ss')

        if (type == 'start_time') {
            state.item.start_time = formatedDate;
        }

        if (type == 'end_time') {
            state.item.end_time = formatedDate;
        }
    },
    setBannerLong5(state, value) {
        state.item.banner_long5 = value
    },
    setBannerLong6(state, value) {
        state.item.banner_long6 = value
    },
    setBannerLong7(state, value) {
        state.item.banner_long7 = value
    },
    setBannerLong8(state, value) {
        state.item.banner_long8 = value
    },
    setBannerLong9(state, value) {
        state.item.banner_long9 = value
    },
    setBannerLong10(state, value) {
        state.item.banner_long10 = value
    },
    setBannerLong11(state, value) {
        state.item.banner_long11 = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setBackgroundPictureAgenda(state, value) {
        state.item.background_picture_agenda = value
    },
    setSkybox_is_custom(state, value) {
        state.item.skybox_is_custom = value
        if (!value) {
            state.item.skybox_picture_delete = 1
            state.item.skybox_picture = null
        }
    },
    setIsBackgroundPicture(state, value) {
        state.item.is_background_picture = value
    },
    setEnvironment_is_custom(state, value) {
        state.item.environment_is_custom = value
        if (!value) {
            state.item.environment_picture_delete = true
            state.item.environment_picture = null
        }
    },
    setSkybox(state, value) {
        state.item.skybox = value
    },
    setEnvironment(state, value) {
        state.item.environment = value
    },
    setWebcastRegion(state, value) {
        state.item.webcast_region = value
    },
    setStreamingDevice(state, value) {
        state.item.streaming_device = value
    },
    setSkyboxPicture(state, value) {
        state.item.skybox_picture = value
    },
    setEnvironmentPicture(state, value) {
        state.item.environment_picture = value
    },
    setPodiumColor(state, value) {
        state.item.podium_color = value
    },
    setPodiumPicture(state, value) {
        state.item.podium_picture = value
    },
    setSpeakerPicture(state, value) {
        state.item.speaker_picture = value
    },
    setBannerLong1(state, value) {
        state.item.banner_long1 = value
    },
    setBannerLong2(state, value) {
        state.item.banner_long2 = value
    },
    setBannerLong3(state, value) {
        state.item.banner_long3 = value
    },
    setBannerLong4(state, value) {
        state.item.banner_long4 = value
    },
    setBannerMedium1(state, value) {
        state.item.banner_medium1 = value
    },
    setBannerMedium2(state, value) {
        state.item.banner_medium2 = value
    },
    setBannerMedium3(state, value) {
        state.item.banner_medium3 = value
    },
    setBannerMedium4(state, value) {
        state.item.banner_medium4 = value
    },
    setBannerMedium5(state, value) {
        state.item.banner_medium5 = value
    },
    setBannerMedium6(state, value) {
        state.item.banner_medium6 = value
    },
    setRight1PillarColor(state, value) {
        state.item.right1_pillar_color = value
    },
    setRight2PillarColor(state, value) {
        state.item.right2_pillar_color = value
    },
    setLeft1PillarBottomColor(state, value) {
        state.item.left1_pillar_color_bottom = value
    },
    setLeft2PillarBottomColor(state, value) {
        state.item.left2_pillar_color_bottom = value
    },
    setLeft1PillarColor(state, value) {
        state.item.left1_pillar_color = value
    },
    setLeft2PillarColor(state, value) {
        state.item.left2_pillar_color = value
    },
    setRight1PillarBottomColor(state, value) {
        state.item.right1_pillar_color_bottom = value
    },
    setRight2PillarBottomColor(state, value) {
        state.item.right2_pillar_color_bottom = value
    },
    setPillarLeft1Logo1(state, value) {
        state.item.pillar_left1_logo1 = value
    },
    setPillarLeft1Logo2(state, value) {
        state.item.pillar_left1_logo2 = value
    },
    setPillarLeft1Logo3(state, value) {
        state.item.pillar_left1_logo3 = value
    },
    setPillarLeft2Logo1(state, value) {
        state.item.pillar_left2_logo1 = value
    },
    setPillarLeft2Logo2(state, value) {
        state.item.pillar_left2_logo2 = value
    },
    setPillarLeft2Logo3(state, value) {
        state.item.pillar_left2_logo3 = value
    },
    setPillarRight1Logo1(state, value) {
        state.item.pillar_right1_logo1 = value
    },
    setPillarRight1Logo2(state, value) {
        state.item.pillar_right1_logo2 = value
    },
    setPillarRight1Logo3(state, value) {
        state.item.pillar_right1_logo3 = value
    },
    setPillarRight2Logo1(state, value) {
        state.item.pillar_right2_logo1 = value
    },
    setPillarRight2Logo2(state, value) {
        state.item.pillar_right2_logo2 = value
    },
    setPillarRight2Logo3(state, value) {
        state.item.pillar_right2_logo3 = value
    },
    setInterpretation(state, value) {
        state.item.interpretation = { ...state.item.interpretation, ...value };
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
}
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
