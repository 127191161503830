<template>
 
 <div class="tabs">
      <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`"
                    data-bs-toggle="tab">Language 
                    <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
           
                  </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`"
                    data-bs-toggle="tab">General Settings
                    <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
           
                  </a>
            </li>
            <li class="nav-item">

                <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme
                    Settings {{ (this.error_theme_tab) ? 'error' : '' }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`"
                    data-bs-toggle="tab">Interpretation Settings {{ (this.error_interpretation_tab) ? 'error' : '' }}</a>
            </li>
      </ul>
      <div class="tab-content">

        <div :id="`language-settings`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Language *</label>
                            <v-select name="language" placeholder="Select language" label="language"
                                @option:selected="updateLaguage" :options="languages" />
                            <ValidationMessages :element="v$.item.translations" />
                        </div>
                        <div class="col-lg-6">
                            <label>Title *</label>

                            <div class="kt-input-icon">
                                <input type="text" class="form-control" name="language_title" maxlength="200"
                                    placeholder="Enter language title" :value="this.selectedLanguageTitle"
                                    @input="updateLanguageTitle" />
                            </div>
                            <ValidationMessages :element="v$.item.translations" />

                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label>Description *</label>
                                <div class="kt-input-icon">
                                    <textarea rows="3" class="form-control" name="language_description"
                                        placeholder="Enter language description" :value="this.selectedLanguageDescirption"
                                        @input="updateLanguageDescription"></textarea>
                                </div>
                                <ValidationMessages :element="v$.item.translations" />

                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div :id="`general-settings`" class="tab-pane">
          <div class="snippet general-event-config-snippet">
              <div class="form-group row">
                      <div class="col-lg-4">
                        <label>Booth </label>
                        <v-select placeholder="Select Booth" label="title" :options="booths" track-by="id" :modelValue="item.booth" :reduce="(option) => option.id" @option:selected="updateBooth" />
                      </div>
              </div>
               <br/> 
              <div class="form-group row" v-if="event_days.length === 1">
                <div class="col-lg-4">
                        <label>Room Start Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <VueTimepicker :format="timeFormat"
                              name="start_time" 
                              :value="item.start_time
                              ? item.start_time
                              : null" 
                              placeholder="Room Start Time"
                              @change="updateStart_time"
                    
                            />
                          </div>
                        <ValidationMessages :element="v$.item.start_time"/>
                       
                    </div>
                    <div class="col-lg-4">
                        <label class>Room End Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <VueTimepicker 
                            :format="timeFormat" name="end_time" :value="
                            item.end_time
                              ? item.end_time
                              : null"
                          :config="getEndRoomTimeConfig()"
                          placeholder= "Room End Time"
                          @change="updateEnd_time"
                          />
                        </div>
                        <ValidationMessages :element="v$.item.end_time"/>
                    </div>
                    <div class="col-lg-2">
                        <label class>Meeting Slot *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                            </span>
                            <input 
                            type="number"
                            class="form-control minutes_input"
                            name="time_slot"
                            placeholder="Enter Time slot"
                            :value="item.time_slot"
                            @input="updateTime_slot"
                            min="0"
                            />
                        </div>
                        <ValidationMessages :element="v$.item.time_slot"/>
                    </div>
                </div>  

                <div class="form-group row" v-if="event_days.length === 1">
                  <div class="col-lg-3">
                      <label>Limited Room Sessions ?</label>
                      <br />
                        <div class="switch switch-sm switch-primary">
                          <input-switch name="limited_sessions" 
                          :value="item.limited_sessions" 
                          id="customEETR" 
                          class="custom-control-input" 
                          @change="updateLimitedSessions" />
                          <label class="custom-control-label" for="customERR"></label>
                        </div>
                  </div>

                  <div class="col-lg-3" v-if="item.limited_sessions">
                      <label>Number of sessions*</label>
                      <input
                          type="number"
                          class="form-control"
                          name="no_of_sessions"
                          maxlength="191"
                          min="0"
                          placeholder="Enter Number"
                          :value="item.no_of_sessions"
                          @input="updateNoOfSessions"
                        />
                  </div>

                </div>


                <tabs :options="{ useUrlFragment: false, defaultTabHash: 'day-0' }"
                      v-if="event_days.length > 1"
                    >
                      <tab :key="i" v-for="(day , i) in event_days" :name="day" :id="'day-' + i">
                        <div class="form-group row">
                          <div class="col-lg-6">
                            <label>Meetings for this day ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                              <input-switch
                                name="is_rating"
                                :id="'customMeetingHas' + i"
                                :value="item.room_settings[i].has_meetings"
                                class="custom-control-input"
                                @change="updateHasMeetings($event, i)"
                              />
                              <label class="custom-control-label" :for="'customMeetingHas' + i"></label>
                            </div>

                          </div>
                        </div>

                        <div class="form-group row" v-if="item.room_settings[i].has_meetings">
                          
                          <div class="col-lg-4">
                            <div class="col-lg-4">
                              <label for="start_time">Room Start Time *</label>
                            </div>
                            <VueTimepicker
                              :value="item.room_settings[i].start_time ? item.room_settings[i].start_time: null"
                             :format="timeFormat"
                              name="start_time"
                              placeholder="Room Start Time"
                              @change="updateStart_timeDay($event, i)"
                            ></VueTimepicker>

                           
                          </div>
                          <div class="col-lg-4">
                            <div class="col-lg-4">
                              <label for="end_time">Room End Time *</label>
                           </div>
                            <VueTimepicker
                              :value="
                                item.room_settings[i].end_time
                                  ? item.room_settings[i].end_time
                                  : null
                              "
                              :format ="timeFormat"
                              :config="getEndRoomTimeConfig()"
                              name="end_time"
                              placeholder="Enter Room Day End Time"
                              @change="updateEnd_timeDay($event, i)"
                            ></VueTimepicker>
                          </div>

                          <div class="col-lg-3">
                            <label for="time_slot">Meeting slot *</label>
                            <input
                              type="number"
                              class="form-control minutes_input"
                              name="time_slot"
                              placeholder="Enter Time slot"
                              :value="item.room_settings[i].time_slot"
                              @input="updateTime_slotDay($event, i)"
                              min="0"
                            />
                          </div>
                        </div>
                        <div class="form-group row" v-if="item.room_settings[i].has_meetings">
                          <div class="col-lg-3">
                            <label>Limited Room Sessions ?</label>
                            <br />
                            <div class="switch switch-sm switch-primary">
                              <input-switch
                                name="is_rating"
                                :id="'customERR' + i"
                                :checked="item.room_settings[i].limited_sessions"
                                :value="item.room_settings[i].limited_sessions"
                                class="custom-control-input"
                                @change="updateLimitedSessionsDay($event, i)"
                              />
                              <label class="custom-control-label" :for="'customERR' + i"></label>
                            </div>
                          </div>
                          <div class="col-lg-3" v-if="item.room_settings[i].limited_sessions">
                            <label for="room_no">Number of sessions*</label>
                            <input
                              type="number"
                              class="form-control"
                              name="no_of_sessions"
                              maxlength="191"
                              min="0"
                              placeholder="Enter Number"
                              :value="item.room_settings[i].no_of_sessions"
                              @input="updateNoOfSessionsDay($event, i)"
                            />
                          </div>
                        </div>
                        <div class="form-group row" v-if="item.room_settings[i].has_meetings">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-lg-6" v-show="!item.room_settings[i].is_no_break">
                                <label for="start_time">Room Break Start Time *</label>
                                <VueTimepicker
                                  :value="
                                    item.room_settings[i].break_start_time
                                      ? item.room_settings[i].break_start_time
                                      : null
                                  "
                                  :format="timeFormat"
                                  name="break_start_time"
                                  placeholder="Break Start Time"
                                  @change="updateBreak_Start_timeDay($event, i)"
                                ></VueTimepicker>
                              </div>
                              <div class="col-lg-6" v-show="!item.room_settings[i].is_no_break">
                                <label for="end_time">Room Break End Time *</label>
                                <VueTimepicker
                                  :value="
                                    item.room_settings[i].break_end_time
                                      ? item.room_settings[i].break_end_time
                                      : null
                                  "
                                  :format="timeFormat"
                                  name="break_end_time"
                                  placeholder=" Break End Time"
                                  @change="updateBreak_End_timeDay($event, i)"
                                ></VueTimepicker>
                              </div>
                              <div class="col-lg-12">
                                <label for="no_break" class="mt-2">There is no break</label>
                                <div class="switch switch-sm switch-primary">
                                  <input-switch
                                    :id="'no_break' + i"
                                    class="custom-control-input"
                                    :checked="item.room_settings[i].is_no_break"
                                    :value="item.room_settings[i].is_no_break"
                                    @change="changeNoBreakDay($event, i)"
                                  />
                                  <label :for="'no_break' + i" class="custom-control-label"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tab>
                    </tabs>

                    <div class="form-group row">
                      <div class="col-md-6" v-if="event_days.length === 1">
                        <div class="row">
                          <div class="col-lg-6" v-if="!item.is_no_break">
                            <label for="start_time">Room Break Start Time *</label>
                            <VueTimepicker
                              :value="item.break_start_time ? item.break_start_time : null"
                              :format = "timeFormat"
                           
                              name="break_start_time"
                              placeholder=" Break Start Time"
                              @change="updateBreak_Start_time"
                            ></VueTimepicker>
                            
                          </div>
                          <div class="col-lg-6" v-if="!item.is_no_break">
                            <div class="col-lg-6">
                              <label for="end_time">Room Break End Time </label>
                            </div>
                           
                            <VueTimepicker
                              :value="
                                item.break_end_time
                                  ? item.break_end_time
                                  : null
                              "
                              :format = "timeFormat"
                              :config="getEndRoomBreakTimeConfig()"
                              name="break_end_time"
                              placeholder="Break End Time"
                              @change="updateBreak_End_time"
                            ></VueTimepicker>
                            
                          </div>
                          <div class="col-lg-12">
                            <label for="no_break" class="mt-2">There is no break</label>
                            <div class="switch switch-sm switch-primary">
                              <input-switch
                                id="no_break"
                                class="custom-control-input"
                                type="checkbox"
                                :value="item.is_no_break"
                                @change="changeNoBreak"
                              />
                              <label for="no_break" class="custom-control-label"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label>Screen Sharing ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                          <input-switch
                            type="checkbox"
                            name="is_rating"
                            id="customEETT"
                            :value="item.screen_sharing"
                            class="custom-control-input"
                            @change="updateScreenSharing"
                          />
                          <label class="custom-control-label" for="customEETT"></label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-lg-6">
                        <label>Live Streaming ?</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                          <input-switch
                            name="is_rating"
                            id="customEEL"
                            :value="item.is_live_stream"
                            class="custom-control-input"
                            @change="updateIsLiveStream"
                          />
                        </div>
                        <label class="custom-control-label" for="customEETT" style="padding-left: 1em;">{{ item.is_live_stream?"Enabled":"Disabled" }}</label>
               
                      </div>
                      <div class="col-lg-6" v-if="item.is_live_stream">
                        <label for="title">Stream URL</label>
                        <div class="kt-input-icon">
                          <input
                            type="text"
                            class="form-control"
                            name="stream_url"
                            placeholder="Enter Live Stream URL"
                            :value="item.stream_url"
                            @input="updateStreamUrl"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2">
                            <label for="preset_image">Screen Logo*</label>
                        </div>
                          <div class="col-md-10">
                              <DropFiles :file="item.logo"
                                      :module_id="this.moduleId"
                                      @onUploaded="updateLogo"
                                      @onRemoved="removeLogo"
                                      @onError="errCb"
                                      :accept="'image/jpeg'"
                                      dimmention="455X256"
                                      :module_type="'meetings'"
                                      :asset_type="'meetings'">
                              <template #header>
                                  <p> (Size should be 455x256 pixel and format jpg.)</p>
                              </template>
                              </DropFiles>
                          </div>
                          <ValidationMessages :element="v$.item.logo" />
                  
                    </div>



          </div>  
        </div><!--End of General Settings-->
        <div :id="`theme-settings`" class="tab-pane">

          <div class="tabs">
            <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                      <a class="nav-link active" :data-bs-target="`#theme-contents`" :href="`#theme-contents`" data-bs-toggle="tab">Theme Contents</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" :data-bs-target="`#banner-logo`" :href="`#banner-logo`" data-bs-toggle="tab">Banner/Logo</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" :data-bs-target="`#documents`" :href="`#documents`" data-bs-toggle="tab">Documents</a>
                  </li>
                  
            </ul>
          <div class="tab-content">

            <div :id="`theme-contents`" class="tab-pane active">
                    <div class="snippet general-event-config-snippet">
                        <div class="form-group row">
                            <div class="col-lg-6">
                              <label>Background Color or Picture ?</label>
                              <div class="switch switch-sm switch-primary" style="padding-left: 2em">
                                <input-switch
                                  name="is_rating"
                                  id="customEEL"
                                  :value="item.is_background_picture"
                                  class="custom-control-input"
                                  @change="updateIsBackgroundPicture"
                                />
                                <label
                                class="custom-control-label"
                                for="customR"
                                style="padding-left: 2em"
                              > {{item.is_background_picture ? 'Picture' : 'Color'}}</label>
                              </div>
                                
                            </div>
                            
                              <div class="form-group row" v-if="item.is_background_picture">
                                <div class="col-lg-12">
                                    <label>Background Picture</label>
                                    <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        <img src ="/assets/img/event_hall_latest.png"/>
                                      </template>
                                    </VPopper>

                                    <br>
                                    <div class="col-md-10">
                                        <DropFiles :file="item.background_picture"
                                                :module_id="this.moduleId"
                                                @onUploaded="updateBackgroundPicture"
                                                @onRemoved="removeBackgroundPicture"
                                                @onError="errCb"
                                                dimmention="1920X470"
                                                :accept="'image/jpeg'"
                                                :module_type="'meetings'"
                                                :asset_type="'meetings'">
                                        <template #header>
                                            <p> (Size should be 1920x470 pixel and format JPG.)</p>
                                        </template>
                                        </DropFiles>
                                     </div>
                          
                                </div>
                              </div><!--End of if-->
                              <div class="form-group row" v-else>
                                <div class="col-lg-6">
                                    <label>Background Color</label>
                                    <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Select Color For Background
                                      </template>
                                    </VPopper>

                                    <div class="input-group color" data-plugin-colorpicker>
                                          <input
                                          type="color"
                                          class="form-control"
                                          name="background_color"
                                          placeholder="Enter color code "
                                          :value="item.background_color"
                                          @input="updateBackgroundColor"
                                          />
                                      </div>
                                 </div>
                                 <div class="col-lg-6">
                                  <label>Background Stripes Color</label>
                                  <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Select Color For Background
                                      </template>
                                    </VPopper>
                                  <div class="input-group color" data-plugin-colorpicker>
                                      <input
                                      type="color"
                                      class="form-control"
                                      name="background_stripes_color"
                                      placeholder="Enter color code "
                                      :value="item.background_stripes_color"
                                      @input="updateBackgroundStripesColor"
                                      />
                                    </div>
                                  </div>

                              </div><!-- Else-->

                         </div>
                      </div>
            </div>
            <div :id="`banner-logo`" class="tab-pane">
                    <div class="snippet general-event-config-snippet">
                      <div class="form-group row" >
                                <div class="col-lg-12">
                                    <label>Banner Logo</label>
                                    <div class="col-md-10">
                                        <DropFiles :file="item.banner"
                                                :module_id="this.moduleId"
                                                @onUploaded="updateBanner"
                                                @onRemoved="removeBanner"
                                                @onError="errCb"
                                                dimmention="552X173"
                                                :accept="'image/jpeg'"
                                                :module_type="'meetings'"
                                                :asset_type="'meetings'">
                                        <template #header>
                                            <p> (Size should be 552x173 pixel and format JPG.)</p>
                                        </template>
                                        </DropFiles>
                                     </div>
                          
                                </div>
                        </div>

                        <div class="form-group row" >
                                <div class="col-lg-12">
                                    <label>Room Logo</label>
                                    <div class="col-md-10">
                                        <DropFiles :file="item.room_logo"
                                                :module_id="this.moduleId"
                                                @onUploaded="updateRoomLogo"
                                                @onRemoved="removeRoomLogo"
                                                @onError="errCb"
                                                dimmention="120X80"
                                                :accept="'image/jpeg'"
                                                :module_type="'meetings'"
                                                :asset_type="'meetings'">
                                        <template #header>
                                            <p> (Size should be 120x80 pixel and format JPG.)</p>
                                        </template>
                                        </DropFiles>
                                     </div>
                          
                                </div>
                        </div>

                    </div>
            </div>
            <div :id="`documents`" class="tab-pane">
                    <div class="snippet general-event-config-snippet">
                          <div class="form-group row">
                              <div class="col-lg-6">
                                <label>Show Text instead of documents on booth ?</label>
                                <br />
                                <div class="switch switch-sm switch-primary">
                                  
                                  <input-switch
                                    type="checkbox"
                                    name="show_text"
                                    id="customEERR"
                                    :value="item.show_text"
                                    class="custom-control-input"
                                    @change="updateShowText"
                                  />
                                </div>
                              </div>

                              <div class="col-lg-6" v-show="item.show_text">
                                <label for="description"> Description</label>
                                <div class="kt-input-icon">
                                  <textarea
                                    name="description"
                                    :id="'description'"
                                    :value="item.room_description"
                                    @input="updateDescription"
                                    placeholder="Enter the  description"
                                    style="border: 1px solid rgb(23, 23, 23); box-shadow: rgb(113, 158, 206) 0px 0px 10px; outline: none !important; height: 247px;"
                                    rows="10" cols = "80"
                                  />
                                </div>
                              </div>

                          </div>
                          <div v-show="!item.show_text">
                            <div class="form-group row">
                              <div class="col-md-2">
                                <label for="preset_image">Document 1</label>
                                <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        <img src ="/assets/img/event_hall_latest.png"/>
                                      </template>
                                    </VPopper>

                              </div>
                              <div class="col-md-10">
                                  <DropFiles :file="item.document1"
                                          :module_id="this.moduleId"
                                          @onUploaded="updateDocument1"
                                          @onRemoved="removeDocument1"
                                          @onError="errCb"
                                          :accept="'application/pdf'"
                                          :module_type="'meetings'"
                                          :asset_type="'meetings'">
                                  <template #header>
                                      <p> (Should be in PDF format)</p>
                                  </template>
                                  </DropFiles>
                              </div>

                            </div>
                            <div class="form-group row">
                              <div class="col-md-2">
                                <label for="preset_image">Document 2</label>
                                <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        <img src ="/assets/img/event_hall_latest.png"/>
                                      </template>
                                    </VPopper>


                              </div>
                              <div class="col-md-10">
                                  <DropFiles :file="item.document2"
                                          :module_id="this.moduleId"
                                          @onUploaded="updateDocument2"
                                          @onRemoved="removeDocument2"
                                          @onError="errCb"
                                          :accept="'application/pdf'"
                                          :module_type="'meetings'"
                                          :asset_type="'meetings'">
                                  <template #header>
                                      <p> (Should be in PDF format)</p>
                                  </template>
                                  </DropFiles>
                              </div>

                            </div>
                            <div class="form-group row">
                              <div class="col-md-2">
                                <label for="preset_image">Document 3</label>
                                <VPopper hover style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        <img src ="/assets/img/event_hall_latest.png"/>
                                      </template>
                                    </VPopper>

                              </div>
                              <div class="col-md-10">
                                  <DropFiles :file="item.document3"
                                          :module_id="this.moduleId"
                                          @onUploaded="updateDocument3"
                                          @onRemoved="removeDocument3"
                                          @onError="errCb"
                                          :accept="'application/pdf'"
                                          :module_type="'meetings'"
                                          :asset_type="'meetings'">
                                  <template #header>
                                      <p> (Should be in PDF format)</p>
                                  </template>
                                  </DropFiles>
                              </div>

                            </div>
                        </div>
                          

                    </div>
            </div>
          </div>
        </div>























        </div> <!--End Of Theme Settings-->
        <div :id="`interpretify-settings`" class="tab-pane">

          <div class="snippet general-event-config-snippet">

              <div class="form-group row">
                  <div class="col-lg-4">
                      <label>Enable "Interprefy" Interpretation</label>
                      <br />
                      <div class="switch switch-sm switch-primary">
                          <input-switch name="interprefy" 
                          :value="item.interprefy"
                           id="customSwitchesEx"
                           class="custom-control-input" 
                           @change="updateInterprefy" />
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <label>Interprefy token</label>
                      <div class="kt-input-icon">
                          <input type="text" class="form-control" 
                              name="title" maxlength="200"
                              placeholder="Enter Interprefy Token" 
                              :disabled="interprefy_disabled == true"
                              @input="updateInterprefyToken" />
                      </div>
                  </div>

              </div>

              <div class="form-group row">
                  <div class="col-lg-4">
                      <label>Enable "AWS" Interpretation</label>
                      <br />
                      <div class="switch switch-sm switch-primary">
                          <input-switch name="interprefy" :value="item.aws_interpretation" id="customSwitchesEx"
                              class="custom-control-input" @change="updateAwsInterpretation" />
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <label>Enable "Speech to Speech"</label>
                      <div class="kt-input-icon">
                          <input-switch name="speech_to_speech" :value="item.aws_enable_speech_to_speech"
                              id="enableSpeechToSpeech" class="custom-control-input"
                              @change="updateAwsEnableSpeechToSpeech" :styleDisable="this.aws_interprefy_disabled" />
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <label>Enable "Speech to Text"</label>
                      <div class="kt-input-icon">
                          <input-switch name="speech_to_text" :value="item.aws_enable_speech_to_text"
                              id="enableSpeechToText" class="custom-control-input"
                              @change="updateAwsEnableSpeechToText" :styleDisable="this.aws_interprefy_disabled" />
                      </div>
                  </div>

              </div>

              <div class="form-group row">

                  <div class="col-lg-4">
                      <label>Enable "Chat" Interpretation</label>
                      <br />
                      <div class="switch switch-sm switch-primary">
                          <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx"
                              class="custom-control-input" @change="updateChat" />
                      </div>
                  </div>

              </div>

          </div>
        </div>

        <div class="card-body text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      
        </div>   

      </div>
 </div>  
 <FlashMessage group="meetings_create" />
</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {mapGetters, mapActions} from "vuex";
import $ from 'jquery';
import InputSwitch from "@/components/Commons/InputSwitch";
import _ from "lodash";
import ObjectID from 'bson-objectid';
import DropFiles from "@/components/Commons/DropFiles";
import {v4 as uuidv4} from 'uuid';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";

import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'
import {
    upload
} from "@/services/FileUploader";
import moment from 'moment';
export default {
  name: "PanelAgendaCreateForm",
  components: {    
    ValidationMessages,
    InputSwitch
  },
  data() {
    return {
      moduleId: ObjectID(),
      err_translations  :null,
      dialog: false,
      translation: [],
      languageKey: '',
      languageTitle: '',
      languageDescription: '',
      stream_disabled: true,
      restric_user_disabled: true,
      session_open_disabled: true,
      interprefy_disabled: true,
      aws_interprefy_disabled: true,
      timeFormat: 'HH:mm',
    }
  },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            item: {
               
                translations: {
                    required
                },                
                logo: {
                    required,
                }
            }
        };
    },

  props: {
    event: {
            type: Object,
            default() {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                    langauge: null
                }
            }
        },
        languages: {
            type: Array,
        }
    },
  mounted() {
    this.checkPermission();
    this.fetchEventsAll(this.$route.params.event_id);
    this.updateEvent(this.$route.params.event_id);
  },
  computed: {
        ...mapGetters('MeetingRoomsSingle', ['error_translation_tab','error_general_tab','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading',  'eventID','event_days']),
        ...mapGetters('MeetingRoomsIndex', ['speakers','event_timezone','booths']),
    },
  beforeUnmount() {
    this.resetState()
    delete this.$flashMessage.groups.meetings_create;

  },
  methods: {
    ...mapActions("AuthenticationsIndex",["checkMeetingSubscrptions"]),
   
      ...mapActions('MeetingRoomsIndex', {
            fetchAllData: 'fetchData',
      }),
      ...mapActions('MeetingRoomsSingle', [
          'fetchEventsAll',
          'storeData',
          'fetchData',
          'resetState',
          'setLangKey',
          'setLangTitle',
          'setLangDescirption',
          'setTranslations',
          'setBooth',
          'setLogo',
          'setEvent',
          'setTime_slot',
          'setTime_slotDay',
          'setHours_per_day',
          'setEnd_time',
          'setStart_time',
          'setEnd_date',
          'setStart_date',
          'setEnd_timeDay',
          'setBreak_Start_time',
          'setBreak_Start_timeDay',
          'setBreak_End_time',
          'setBreak_End_timeDay',
          'setStart_timeDay',
          'setIsNoBreakDay',
          'setHasMeetings',
          'setLimitedSessionsDay',
          'setScreenSharing',
          'setIsLiveStream',
          'setIsBackgroundPicture',
          'setBackgroundPicture',
          'setBackgroundStripesColor',
          'setBackgroundColor',
          'setRoomLogo',
          'setBanner',
          'setShowText',
          'setDescription',
          'setDocument1',
          'setDocument2',
          'setDocument3',
          'setInterprefy',
          'setInterprefyToken',
          'setAwsInterpretation',
          'setAwsEnableSpeechToSpeech',
          'setAwsEnableSpeechToText',
          'setChat',
          'setNoOfSessions',
          'setIsNoBreak',
          'setLimitedSessions',
          'setErrorTranslationTab',
          'setErrorGeneralTab',
          'setNoOfSessionsDay',
          'setStreamUrl',
          'setIsNew'
    ]),
    updateNoOfSessionsDay(e, i) {
      this.setNoOfSessionsDay({ value: e.target.value, i: i });
      this.$forceUpdate();
    },
    updateLimitedSessions(e) {
      this.setLimitedSessions(e.target.checked);
    },
    updateNoOfSessions(e) {
      this.setNoOfSessions(e.target.value);
    },
    getEndRoomBreakTimeConfig() {
      let minDate = this.item.break_start_time
        ? moment(this.item.break_start_time, 'HH:mm').add(1, 'minute')
        : moment();
      return Object.assign({}, 'HH:mm', {
        minDate: minDate.isValid() ? minDate : moment()
      });
    },
    updateChat(e) {
      this.setChat(e.target.checked);
    },
    updateShowText(e) {
      this.setShowText(e.target.checked);
    },
    updateDescription(e){
        this.setDescription(e.target.value);
      },
      
    updateBackgroundStripesColor(e) {
       this.setBackgroundStripesColor(e.target.value);
       this.$forceUpdate();
    },
    updateBackgroundColor(e) {
      this.setBackgroundColor(e.target.value);
      this.$forceUpdate();
    },
    updateIsBackgroundPicture(e) {
      this.setIsBackgroundPicture(e.target.checked);
    },
    updateIsLiveStream(e) {
      this.setIsLiveStream(e.target.checked);
    },
    updateScreenSharing(e) {
      this.setScreenSharing(e.target.checked);
    },
    updateLimitedSessionsDay(e, i) {
      this.setLimitedSessionsDay({ value: e.target.checked, i: i });
      this.$forceUpdate();
    },
    updateHasMeetings(e, day) {
      this.setHasMeetings({ value: e.target.checked, i: day });
      this.$forceUpdate();
    },
    changeNoBreak(e) {
      this.setIsNoBreak(e.target.checked);
      this.setBreak_Start_time(null);
      this.setBreak_End_time(null);
      this.$forceUpdate();
    },
    changeNoBreakDay(e, i) {
      this.setIsNoBreakDay({ value: e.target.checked, i: i });
      this.setBreak_Start_timeDay({ time: null, i: i });
      this.setBreak_End_timeDay({ time: null, i: i });
      this.$forceUpdate();
    },
    updateEnd_timeDay(e, i) {
      this.setEnd_timeDay({ time: e.displayTime, i: i });
      this.$forceUpdate();
    },
    updateBreak_Start_time(e) {
      this.setBreak_Start_time(e.displayTime);
    },
    updateBreak_Start_timeDay(e, i) {
      this.setBreak_Start_timeDay({ time: e.displayTime, i: i });
      this.$forceUpdate();
    },
    updateBreak_End_time(e) {
      this.setBreak_End_time(e.displayTime);
    },
    updateBreak_End_timeDay(e, i) {
      this.setBreak_End_timeDay({ time:e.displayTime, i: i });
      this.$forceUpdate();
    },
    updateStart_timeDay(e, i) {
      this.setStart_timeDay({ time: e.displayTime, i: i });
      this.$forceUpdate();
    },

    updateStart_date(e) {
      this.setStart_date(e.displayTime);
    },
    updateEnd_date(e) {
      this.setEnd_date(e.displayTime);

    },
    updateStart_time(e) {
      this.setStart_time(e.displayTime);
    },
    updateEnd_time(e) {
      this.setEnd_time(e.displayTime);
    },
    updateTime_slotDay(e, i) {
      this.setTime_slotDay({ value: e.target.value, i: i });
      this.$forceUpdate();
    },
    updateHours_per_day(e) {
      this.setHours_per_day(e.target.value);
    },
    updateTime_slot(e) {
      this.setTime_slot(e.target.value);
    },
    updateEvent(value) {
      this.setEvent(value);
    },
    getEndRoomTimeConfig() {
      let minDate = this.item.start_time
        ? moment(this.item.start_time, 'HH:mm').add(1, 'minute')
        : moment();

      return Object.assign({}, this.$root.dpconfigTime, {
        minDate: minDate.isValid() ? minDate : moment()
      });
    },
    
    updateBooth(value){
      this.setBooth(value.id);
    },
    updateLaguage(value) {
        this.err_translations = null;
        this.setLangKey(value);
        this.languageKey = value;
        this.setLangTitle(null);
        this.setLangDescirption(null);
        if (_.isUndefined(_.find(this.translation, {
            'key': value,
        }))) {
            let obj = {
                "key": `${value}`,
                "title": null,
                "description": null
            }
            this.translation.push(obj);
        } else {
            let selectedLang = _.find(this.item.translations, {
                'key': this.selectedLanguageKey,
            })
            if (!_.isUndefined(selectedLang)) {
                this.setLangTitle(selectedLang.title);
                this.setLangDescirption(selectedLang.description);
            }
        }
    },
    updateLanguageTitle(e) {
      this.err_translations = null;
      if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
        this.setTranslations({
        key: this.languageKey,
        update_key: 'title',
        update_value: e.target.value
        });
      }
      this.setLangTitle(e.target.value);
    },
    updateLanguageDescription(e) {
      if (!_.isNull(this.languageKey)) {
          this.setTranslations({
              key: this.languageKey,
              update_key: 'description',
              update_value: e.target.value
          });
      }
      this.setLangDescirption(e.target.value);
    },
    getLanguageTitle() {
      if (this.languageKey === null) {
        return ''
      }

      return _.get(_.find(this.item.translations, i => i.key === this.languageKey._id), 'title', '');
    },
    getLanguageDescription() {
      if (this.languageKey === null) {
        return ''
      }

      return _.get(_.find(this.item.translations, i => i.key === this.languageKey._id), 'description', '');
    },
    errCb(e) {
      this.$flashMessage.show({
        group: 'meetings_create',
        type: 'error',
        title: 'Error Invalid File Format',
        html: '<ul><li>Please updload the valid format.</li></ul>'
      });
    },
    updateLogo(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.videos, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeLogo() {
      this.setLogo(null);
    },
    updateBackgroundPicture(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.videos, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setBackgroundPicture({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeBackgroundPicture() {
      this.setBackgroundPicture(null);
    },
    updateBanner(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.banner, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setBanner({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeBanner() {
      this.setBanner(null);
    },
    updateRoomLogo(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.room_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setRoomLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeRoomLogo() {
      this.setRoomLogo(null);
    },

    updateDocument1(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.documents, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setDocument1({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeDocument1() {
      this.setDocument1(null)
    },
    updateDocument2(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.documents, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setDocument2({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeDocument2() {
      this.setDocument2(null)
    },
    updateDocument3(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.documents, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setDocument3({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeDocument3() {
      this.setDocument3(null)
    },
    updateStreamUrl(e) {
      this.setStreamUrl(e.target.value);
    },
    updateInterprefy(e) {
      this.interprefy_disabled = true;
      if (e.target.checked) {
          this.interprefy_disabled = false;
          this.setAwsInterpretation(false)
          this.aws_interprefy_disabled =true;
          this.setAwsEnableSpeechToSpeech(false);
          this.setAwsEnableSpeechToText(false);
      }
      this.setInterprefy(e.target.checked);
    },
    updateInterprefyToken(e) {
      this.setInterprefyToken(e.target.value);
    },
    updateAwsInterpretation(e) {
      this.aws_interprefy_disabled = true;
      if (e.target.checked) {
          this.aws_interprefy_disabled = false;
      }
      if(e.target.checked){
        this.setInterprefy(false);
        this.interprefy_disabled= true
      }
            //interprefy
      this.setAwsInterpretation(e.target.checked);
    },
    updateAwsEnableSpeechToSpeech(e) {
      this.setAwsEnableSpeechToSpeech(e.target.checked);
    },
    updateAwsEnableSpeechToText(e) {
      this.setAwsEnableSpeechToText(e.target.checked);
    },
    async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                      errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                      errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
    },
    cancelClick() {
      this.resetState()
    },

    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      let validateTranlation = await this.checkLanguageValidation();
      this.error_general_tab = false;
      this.error_translation_tab = false;
      this.error_theme_tab = false;
      this.error_interpretation_tab = false;
      this.setErrorGeneralTab(false)
      this.setErrorTranslationTab(false)
      let generalError = []; 
      _.map(this.v$.$errors, (item) => {
          if(item.$property == "logo"){
            generalError.push("<li>Screen Logo is requiured</li>");
          }
       });
       if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }
      if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
        if (!_.isEmpty(validateTranlation)) {
              this.error_translation_tab = true;
              this.setErrorTranslationTab(true);
              this.$flashMessage.show({
                  group: 'meetings_create',
                  type: 'error',
                  time:1000,
                  title: 'Failed',
                  html: `<ul>${validateTranlation.join("")}</ul>`
              });
            return ;
          }
        }   
      if (!isFormCorrect ) {
        if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'meetings_create',
                        type    : 'error',
                        time    : 1000,
                        title   : 'Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
          }
        }
         this.storeData()
          .then(async (resp) => {
            await this.fetchAllData(resp.data.data.event_id);
            this.$flashMessage.show({
                      group   : 'meetings_index',
                      type    : 'success',
                      title   : 'Success',
                      time    : 1000,
                      html    : '<ul><li>Meetings created successfully.</li></ul>',
                      });
            
          })
          .catch(error => {
            console.log("Error store",error);
              let html =  gettingErrorMessage(error);
              this.$flashMessage.show({
                  group: 'meetings_create',
                  type: 'error',
                  title: 'Failed',
                  text: html || 'Something went wrong',
                  html: html
              });
                    
          });
    },
    checkPermission(){
            this.checkMeetingSubscrptions(this.$route.params.event_id)
            .then((res)=>{
            }) .catch((error) => {
                console.log("Error",error);
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group : 'meetings_index',
                    type  : 'error',
                    title : 'Error',
                    time  : 1500,
                    text  : html,
                    html  : html
                });

                this.setIsNew(false);
                this.fetchAllData(this.$route.params.event_id);
            });
        },
  }
}
</script>

<style lang="scss">
.tab_error{
  color: #b94a48;
  font-weight: bold;
}
   .event_detail_wrapper {
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
  
    .event_ls_label {
      font-weight: bold;
    }
  }
  
  .setting_wrapper {
    float: right;
  
    a {
      color: #201e1eba;
      padding: 9px;
      margin-left: 5px;
      background: #c0cdd345;
      border-radius: 4px;
      border: 1px solid #80808054;
    }
  }
  
  .form-btn {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  
    &.save-btn {
      background: rgba(210, 206, 201, 0.27);
    }
  }
  .radio{
      padding:5px;
  }
  label > input{ /* HIDE RADIO */
    display:none;
  }
  label > input + img{ /* IMAGE STYLES */
    cursor:pointer;
    border: 1px dashed #444;
    width: 150px;
    height: 150px;
    transition: 500ms all;
  }
  label > input:checked + img{ /* (CHECKED) IMAGE STYLES */
    /* border:2px solid #f00; */
    opacity:1;
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #090;
    
  }
  .form-inline{
    display:flex;
  }
  .vue__time-picker-input{
    display       : block !important;
    width         : 150% !important;
    padding       : 0.375rem 0.75rem !important;
    font-size     : 0.8rem !important;
    font-weight   : 400 !important;
    line-height   : 1.5 !important;
    color         : #212529 !important ;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance    : none !important;
    border-radius : 0.375rem !important;
    transition    : border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    height        : 2.85em !important
  }
  
  
</style>
