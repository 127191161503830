<template>
  <div>
    <!--begin::Form-->
    <!--  <form @submit.prevent="submitForm" class="kt-form kt-form&#45;&#45;label-right" novalidate>-->
    <form class="kt-form kt-form--label-right" novalidate>
      <div class="kt-portlet__body">
        <tabs :options="{ defaultTabHash: 'tab-0' }">
          <tab name="Language Title">
            <div class="form-group row">
              <div class="col-lg-6">
                <label>Language *</label>
                <v-select name="language" class="" placeholder="Select language" label="language" @option:selected="updateLaguage" :options="languages" />
              </div>
              <div class="col-lg-6">
                <label>Title *</label>
                <div class="kt-input-icon">
                  <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="this.selectedLanguageTitle" @input="updateLanguageTitle" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <label>Description</label>
                  <div class="kt-input-icon">
                    <textarea rows="3" class="form-control" name="language_description" placeholder="Enter language description" :value="this.selectedLanguageDescirption" @input="updateLanguageDescription"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab name="General Settings">
            <div class="form-group row">
              <div class="col-lg-4">
                <label for="event_users">Moderator *</label>
                <div class="kt-input-icon">
                  <v-select
                      name="event_users"
                      label="first_name"
                      @input="updateEvent_users"
                      :value="item.event_users"
                      :options="eventusersAll"
                      class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <label for="event_users">Second Moderator</label>
                <div class="kt-input-icon">
                  <v-select
                      name="event_users"
                      label="first_name"
                      @input="updateEvent_userss"
                      :value="item.event_userss"
                      :options="eventusersAll"
                      class="form-control"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <label for="exhibitor">Exhibitor</label>
                <div class="kt-input-icon">
                  <v-select
                      name="exhibitor"
                      class="form-control"
                      placeholder="Select booth exhibitor"
                      label="name"
                      @input="updateExhibitor"
                      :value="item.exhibitor"
                      :options="exhibitorsAll"
                  />
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-6">
                <label for="start_time">Start Time *</label>

                <!--              <date-picker
                                  :value="item.start_time ? item.start_time : item.event_location && item.event_location.start_time ? item.event_location.start_time.split(' ')[0] : eventsAll && eventsAll.start_date ? eventsAll.start_date.split(' ')[0]: null"
                                  :config="$root.dpconfigDatetime"
                                  name="start_time"
                                  placeholder="Enter Start Time *"
                                  @dp-change="updateStart_time"
                                  ref="startDate"
                                  autocomplete="off"
                              ></date-picker>-->
              </div>
              <div class="col-lg-6">
                <label for="end_time">End Time *</label>
                <div>
                  <!--                <date-picker
                                      :value="item.end_time"
                                      :config="getDatePickerConfig()"
                                      name="end_time"
                                      placeholder="Enter End Time *"
                                      @dp-change="updateEnd_time"
                                      autocomplete="off"
                                  ></date-picker>-->
                </div>
              </div>
            </div>


            <div class="form-group row">
              <div class="col-lg-6">
                <label>Restric User to Join before session time ?</label>
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="is_restricted"
                      id="customEERR"
                      :checked="item.is_restricted"
                      :value="item.is_restricted"
                      class="custom-control-input"
                      @change="updateIs_restricted"
                  />
                  <label class="custom-control-label" for="customEERR"></label>
                </div>
              </div>
              <div class="col-lg-6" v-show="item.is_restricted">
                <label for="minutes">Minutes</label>
                <div class="kt-input-icon">
                  <input
                      type="number"
                      class="form-control"
                      min="0"
                      name="minutes"
                      placeholder="Enter minutes"
                      :value="item.minutes"
                      @input="updateMinutes"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-3">
                <label>Enable Rating ?</label>
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="is_rating"
                      id="customEER"
                      :checked="item.is_rating"
                      :value="item.is_rating"
                      class="custom-control-input"
                      @change="updateIs_rating"
                  />
                  <label class="custom-control-label" for="customEER"></label>
                </div>
              </div>
              <div class="col-lg-3">
                <label>Screen Sharing ?</label>
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="is_rating"
                      id="customEETT"
                      :checked="item.screen_sharing"
                      :value="item.screen_sharing"
                      class="custom-control-input"
                      @change="updateScreenSharing"
                  />
                  <label class="custom-control-label" for="customEETT"></label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                <DropFiles :file="item.videos" accept="video/mp4" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateVideos" @onRemoved="removeVideo" asset_type="panel_agenda" @onError="sesssionLogoErrCb">
                  <template #header>
                    <label>Presentation Video (Max size 500MB and format mp4.)</label>
                  </template>
                </DropFiles>
              </div>
              <div class="col-lg-12" v-show="item.agenda_type != 'pre-recorded'">
                <DropFiles :file="item.documents" accept="application/pdf" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateDocuments" @onRemoved="removeDocument" asset_type="documents" @onError="documentsErrCb">
                  <template #header>
                    <label for="documents">Presentation Document (Max size 30MB and format pdf.)</label>
                  </template>
                </DropFiles>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-6">
                <label>Live Streaming ?</label>
                <br />
                <div class="custom-control custom-switch">
                  <input
                      type="checkbox"
                      name="is_rating"
                      id="customEEL"
                      :checked="item.is_live_stream"
                      :value="item.is_live_stream"
                      class="custom-control-input"
                      @change="updateIsLiveStream"
                  />
                  <label class="custom-control-label" for="customEEL">{{item.is_live_stream ? "Enabled" : "Disabled"}}</label>
                </div>
              </div>
              <div class="col-lg-6" v-if="item.is_live_stream">
                <label for="title">Stream URL</label>
                <VTooltip style="display:inline-block">
                  <i class="fa fa-info-circle" style="font-size:16px"></i>
                  <template #popper>
                    <p style="color:#000">Please insert the embedded url from live stream url.</p>
                    <img src="/admin_assets/img/live_stream_url.jpg" />
                  </template>
                </VTooltip>
                <div class="kt-input-icon">
                  <input
                      type="text"
                      class="form-control"
                      name="stream_url"
                      placeholder="Enter Live Stream URL"
                      :value="item.stream_url"
                      @input="updateStreamUrl"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                <DropFiles :file="item.logo" accept="image/jpg" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateLogo" @onRemoved="removeLogo" asset_type="documents" @onError="documentsErrCb">
                  <template #header>
                    <label>Screen Logo* (Size should be 455x256 pixel and format jpg.)</label>
                  </template>
                </DropFiles>
              </div>
              <div class="col-lg-12">
                <DropFiles :file="item.session_theme"
                           accept="image/jpg"
                           :module_id="this.moduleId"
                           module_type="panel-agenda"
                           :dimmention="null"
                           @onUploaded="updateSessionTheme"
                           @onRemoved="removeSessionTheme"
                           asset_type="documents"
                           @onError="documentsErrCb">
                  <template #header>
                    <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                  </template>
                </DropFiles>
              </div>
            </div>
          </tab>
          <tab name="Theme Settings" >
            <tabs>
              <tab name="Theme Contents" :selected="true">
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Background Color or Picture?</label>
                    <div class="custom-control custom-switch">
                      <input
                          type="checkbox"
                          name="is_background_picture"
                          id="customR"
                          :checked="item.is_background_picture"
                          :value="item.is_background_picture"
                          class="custom-control-input"
                          @change="updateIsBackgroundPicture"
                      />
                      <label
                          class="custom-control-label"
                          for="customR"
                      >{{item.is_background_picture ? 'Picture' : 'Color'}}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group row" v-if="item.is_background_picture">
                  <div class="col-lg-12" >
                    <DropFiles :file="item.background_picture_agenda"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updateBackgroundPictureAgenda"
                               @onRemoved="removeBackgroundPictureAgenda"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label>Background Picture (Size should be 1920x470 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                </div>
                <div v-else class="form-group row">
                  <div class="col-lg-6">
                    <label>Background Color</label>
                    <VTooltip style="display:inline-block">
                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                      <template #popper>
                        <p style="color:#000">Select color for the background</p>
                      </template>
                    </VTooltip>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="background_color"
                          placeholder="Enter color code "
                          :value="item.background_color"
                          @input="updateBackgroundColor"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Background Stripes Color</label>
                    <VTooltip style="display:inline-block">
                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                      <template #popper>
                        <p style="color:#000">Select color for the background</p>
                      </template>
                    </VTooltip>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="background_stripes_color"
                          placeholder="Enter color code "
                          :value="item.background_stripes_color"
                          @input="updateBackgroundStripesColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Stage Color 1</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="stage_color1"
                          placeholder="Enter color code "
                          :value="item.stage_color1"
                          @input="updateStageColor1"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Stage Color 2</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="stage_color2"
                          placeholder="Enter color code "
                          :value="item.stage_color2"
                          @input="updateStageColor2"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Bottom Background Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="background_color_bottom"
                          placeholder="Enter color code "
                          :value="item.background_color_bottom"
                          @input="updateBackgroundColorBottom"
                      />
                    </div>
                  </div>
                </div>
              </tab>
              <tab name="Pillar Left 1">

                <div class="form-group row" >
                  <div class="col-lg-6">
                    <label>Pillar Header Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="left1_pillar_color"
                          placeholder="Enter color code "
                          :value="item.left1_pillar_color"
                          @input="updateLeft1PillarColor"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Pillar Bottom Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="left1_pillar_color"
                          placeholder="Enter color code "
                          :value="item.left1_pillar_color_bottom"
                          @input="updateLeft1PillarBottomColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left1_logo1"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft1Logo1"
                               @onRemoved="removePillarLeft1Logo1"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="Logo 1">Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left1_logo2"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft1Logo2"
                               @onRemoved="removePillarLeft1Logo2"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left1_logo3"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft1Logo3"
                               @onRemoved="removePillarLeft1Logo3"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                </div>
              </tab>
              <tab name="Pillar Left 2">
                <div class="form-group row" >
                  <div class="col-lg-6">
                    <label>Pillar Header Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="left2_pillar_color"
                          placeholder="Enter color code "
                          :value="item.left2_pillar_color"
                          @input="updateLeft2PillarColor"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Pillar Bottom Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="left2_pillar_color"
                          placeholder="Enter color code "
                          :value="item.left2_pillar_color_bottom"
                          @input="updateLeft2PillarBottomColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left2_logo1"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft2Logo1"
                               @onRemoved="removePillarLeft2Logo1"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left2_logo2"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft2Logo2"
                               @onRemoved="removePillarLeft2Logo2"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_left2_logo3"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarLeft2Logo3"
                               @onRemoved="removePillarLeft2Logo3"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                </div>
              </tab>
              <tab name="Pillar Right 1">
                <div class="form-group row" >
                  <div class="col-lg-6">
                    <label>Pillar Header Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="right1_pillar_color"
                          placeholder="Enter color code "
                          :value="item.right1_pillar_color"
                          @input="updateRight1PillarColor"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Pillar Bottom Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="right1_pillar_color"
                          placeholder="Enter color code "
                          :value="item.right1_pillar_color_bottom"
                          @input="updateRight1PillarBottomColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right1_logo1"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight1Logo1"
                               @onRemoved="removePillarRight1Logo1"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right1_logo2"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight1Logo2"
                               @onRemoved="removePillarRight1Logo2"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right1_logo3"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight1Logo3"
                               @onRemoved="removePillarRight1Logo3"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                </div>
              </tab>
              <tab name="Pillar Right 2">

                <div class="form-group row" >
                  <div class="col-lg-6">
                    <label>Pillar Header Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="Right2_pillar_color"
                          placeholder="Enter color code "
                          :value="item.right2_pillar_color"
                          @input="updateRight2PillarColor"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Pillar Bottom Color</label>
                    <a data-toggle="tooltip" title="Select color for the backgound">
                      <i class="fa fa-info-circle" style="font-size:16px"   ></i>
                    </a>
                    <div class="kt-input-icon">
                      <input
                          type="color"
                          class="form-control"
                          name="right2_pillar_color"
                          placeholder="Enter color code "
                          :value="item.right2_pillar_color_bottom"
                          @input="updateRight2PillarBottomColor"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right2_logo1"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight2Logo1"
                               @onRemoved="removePillarRight2Logo1"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right2_logo2"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight2Logo2"
                               @onRemoved="removePillarRight2Logo2"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                  <div class="col-lg-12">
                    <DropFiles :file="item.pillar_right2_logo3"
                               accept="image/jpg"
                               :module_id="this.moduleId"
                               module_type="panel-agenda"
                               :dimmention="null"
                               @onUploaded="updatePillarRight2Logo3"
                               @onRemoved="removePillarRight2Logo3"
                               asset_type="documents"
                               @onError="documentsErrCb">
                      <template #header>
                        <label for="background_picture">Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                      </template>
                      <template #tooltip>
                        <VTooltip style="display:inline-block">
                          <i class="fa fa-info-circle" style="font-size:16px"></i>
                          <template #popper>
                            <img src="/admin_assets/img/event_hall_latest.png" />
                          </template>
                        </VTooltip>
                      </template>
                    </DropFiles>
                  </div>
                </div>
              </tab>
            </tabs>
          </tab>
        </tabs>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "WebcastCreateForm",
  data() {
    return {
    }
  },
  beforeUnmount() {
    this.resetState()
    // delete this.$flashMessage.groups.webcast;
  },
  computed: {
    ...mapGetters('WebcastsIndex', ['all', 'event_type']),
    ...mapGetters('WebcastsSingle', ['item', 'loading']),
  },
  methods: {
    ...mapActions('WebcastsSingle', ['resetState'])
  }
}
</script>

<style lang="scss">
/*--------------- Tabs --------------- */
.tabs-component {
  margin: 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;

  a:hover {
    color: #000;
    text-decoration: none;
  }

  &:not(:last-child) {
    border-bottom: dotted 1px #ddd;
  }

  &:hover {
    color: #666;
  }
  &.is-active {
    margin-bottom: -2px;
    display:flex;

    a:hover{
      color: #fff;
      text-decoration: none;
    }

    .tabs-component-tab-a{
      background:#3b8be4;
      color:#fff;
    }
  }

  &.is-disabled * {
    color: #cdcdcd;
    cursor: not-allowed !important;
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: .75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 2em;
}

@media (min-width: 700px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: .5em;
    transform: translateY(2px);
    transition: transform .3s ease;

    &.is-active {
      border-bottom: solid 1px #fff;
      z-index: 2;
      transform: translateY(0);
    }
  }
}

@media (min-width: 700px) {
  .tabs-component-panels {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
  }
}
</style>