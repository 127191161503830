<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h2>Match Making</h2>
        <div>
      
          <button class="btn btn-outline-light" @click="submitForm">Save Changes</button>
          <button class="btn btn-outline-light" @click="cancelEdit">Cancel</button>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="event-config">
        
        <div class="right_content">
          <div class="container-fluid ">
            <div class="row event-config--row" id="general-information">
              <div class="col-md-3 event-config--description">
                <h3>Event Keywords</h3>
                <p>Main Information about the Match Making Keywords.</p>
                <p> Add More Match Making Keywords.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <div class="snippet event-domain-snippet">
                    <div class="form-group row">
                      <div class="col-lg-12">
                        
                          <h3>Events Keyword </h3>
                          

                            <div class="form-group row">
                              <div class="col-lg-12">
                                <label>Match Making Keywords</label>
                                <vue3-tags-input  :tags="item"  placeholder="Enter  keywords" @on-tags-changed="updateTag"  />
                               
                              </div>
                            </div>

                            </div>


                    </div>
                  </div>       
                </div>
              </div>
            
            
          </div>
        </div>
        

      </div>

    </template>
  </GeneralDashboardLayout>
  <FlashMessage group="match_making_index" />


</template>


<script>
import _ from "lodash";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import {mapActions, mapGetters} from "vuex";
import BackButton from "@/components/Commons/BackButton";
import Vue3TagsInput from 'vue3-tags-input';


export default {
  name: "EditMatchMaking",
  components: {
    BackButton,
    GeneralDashboardLayout,
    Vue3TagsInput
  },
  data() {
    return {
    //  item: [],
      newKeyword:[],
    }
  },
  mounted() {
    this.fetchData(this.$route.params);

  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.match_making_index;
  },
  computed: {
    ...mapGetters("EventKeywordsIndex", ['item'])
  },
  methods: {
    ...mapActions("EventKeywordsIndex", [
      "fetchData",
      "resetState",
      
    ]),
    ...mapActions("EventKeywordsSingle", [
      "storeData",
      "resetState",
      "setTag"
      
    ]),
    updateTag(tag) {
      this.keywords = tag;
    },
  
   
    submitForm() {
      if(!_.isUndefined(this.keywords)){
        this.setTag(this.keywords);
        this.storeData(this.$route.params)
          .then(() => {
            this.$flashMessage.show({
                group : 'match_making_index',
                type  : 'success',
                title : 'Success',
                time  : 1000,
                html  : '<ul><li>Keywords have been updated successfully.</li></ul>'
              });
         
              setTimeout(() =>  
                this.$router.push({ name: "event_dashboard.index"}), 2500);
          })
        .catch(error => {
          this.$flashMessage.show({
            group: 'match_making_index',
            type: 'error',
            title: 'Failed',
            text: 'Something went wrong.'
          });
        });
      }
    
    },
    cancelEdit() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .header--title {
    font-size: 1.6rem;
  }
}

.event-config {
  background-color: #eeeff2;
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  label {
    font-weight: bold;
    margin-left: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  &--description {
    background-color: #dee1e8;
    color: #9e9e9e;
    padding: 32px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background-color: #a4a4a4;
      bottom: 3px;
      width: 80%;
      height: 1px;
    }

    h3 {
      color: #797979;
    }
  }

  &--fields {
    padding: 5px 32px;
  }

  .leftmenu {
    position: relative;
    left: 0px;
    height: 100%;
    min-width: 50px;
    display: contents;

    aside {
      background: #3c61b2;
      min-width: 50px;

      ul {
        list-style: none;
        padding-left: 0;
        position: fixed;
        min-width: 50px;

        li {
          text-align: center;

          a {
            color: white;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            transition: all 0.3s ease-in;

            &:hover {
              color: white;
              background: lighten(#4093e3, 20%);
            }

            &.active {
              background: #4093e3;
            }
          }
        }
      }
    }
  }

  nav {
    padding: 0px;
  }

  .right_content {
    width: 100%;
  }
  .tag {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.tag span {
  margin-left: 5px;
  cursor: pointer;
}
}
</style>
