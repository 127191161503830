import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all             : [],
        loading         : false,
     
    }
}
const getters = {
    all             : state =>  state.all,
    loading         : state =>  state.loading,
  
}
const actions = {
    fetchData({ commit, state }) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(constants.API_URL + '/api/users/my-account/list', {headers: headers})
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        axios.delete(constants.API_URL + '/api/agenda_on_demand/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(error)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced      : true,
    state           : initialState,
    getters,
    actions,
    mutations
}