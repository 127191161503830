import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        all: [],
        loading: false,
        edit_id: null
    }
}

const getters = {
    all:         state => state.all,
    loading:       state => state.loading,
    edit_id:         state => state.edit_id,
}

const actions = {
    fetchData({ commit, state }, event_id) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

      

        axios.get(constants.API_URL + '/api/event-cms/footer/' + event_id, {headers: headers})
            .then(response => {
                commit('setAll', _.get(response, 'data.data', {}))
               // return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, {event_id, id}) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/events/' + event_id + '/sponsors/' + id, {headers: headers})
            .then(response => {
                return response;
            })
    },
    setEditId({commit}, id) {
        commit('setEditId', id)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEditId(state, id) {
        state.edit_id = id
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
