<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'panel_agenda.index' }" />
        <h1 class="title text-center">Edit Survey</h1>
        <div>
          <button class="btn btn-outline-primary" @click="saveClick">Save</button>
          <button class="btn btn-outline-primary" @click="cancelClick">Cancel</button>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid panel-container">
        @TODO: need to implement create form
        <FlashMessage group="surveys_create" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
// import TogglePrimary from "@/components/Commons/TogglePrimary";
// import PanelistCreate from "@/components/cruds/PanelAgenda/Panelists/Create";

export default {
  name: "PanelAgendaSurveysCreate",
  components: {
    // PanelistCreate,
    // TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.surveys_create;
  },
  methods: {
    saveClick() {

    },
    cancelClick() {

    },
  }
}
</script>

<style scoped>

</style>