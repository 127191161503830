<template>
  <div class="snippet general-event-config-snippet">
    <div class="form-group row">
      <div class="col-lg-12">
        <label>Event Title *</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="title"
              maxlength="200"
              placeholder="Enter event title"
              :value="item.title"
              @input="updateTitle"
              required
          />
          <ValidationMessages :element="v$.item.title"/>
  
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-12">
        <label>Company *</label>
        <v-select
            placeholder="Select company"
            label="company_name"
            @option:selected="updateCompany"
            :modelValue="item.company"
            :reduce="(option) => option._id"
            :options="companiesAll"
        />
        <ValidationMessages :element="v$.item.company"/>
  
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <label>Enable Listing at AVIAREPS Hub</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="external_listing"
                        :value="item.external_listing"
                        id="customSwitchesEx"
                        class="custom-control-input"
                        @change="UpdateExternalListing" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import $ from 'jquery';
import InputSwitch from "@/components/Commons/InputSwitch";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'

export default {
  name: "GeneralEventConfigSnippet",
  components: {
    InputSwitch,
    ValidationMessages
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },
  validations() {
        return {
            item: {
                title: {
                    required,
                },
                company: {
                    required,
                },
                
            }
        };
    },
 
  computed: {
    ...mapGetters("EventsSingle", [
      "item",
    ]),
    ...mapGetters("CompaniesIndex", { companiesAll: 'all' })
  },
  mounted() {
    const switchers = document.querySelectorAll('.general-event-config-snippet input[data-plugin-ios-switch]')
    switchers.forEach(item => {const elem = new window.theme.PluginIOS7Switch($(item)) })
  },
  methods: {
    ...mapActions("EventsSingle", [
      "setCompany",
      "setTitleEdit",
      "setTitle",
      "setExternalListing",
    ]),
    updateCompany(value) {
      this.setCompany(value._id);
    },
    updateTitle(e) {
      this.setTitle(e.target.value);
    },
    UpdateExternalListing(e) {
       this.setExternalListing(e.target.checked);
    },
  },
}
</script>

<style scoped>

</style>