import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            id: null,
            event_id: null,
            scrolling_text: null,
            exhibition_video: null,
            skybox_picture: null,
            environment_picture: null,
            exhibition_picture: null,
            max_count_booth: null,
            flag1: null,
            flag2: null,
            flag3: null,
            flag4: null,
            flag5: null,
            flag6: null,
            flag7: null,
            flag8: null,
            flag9: null,
            flag10: null,
            flag11: null,
            flag12: null,
            flag13: null,
            flag14: null,
            flag15: null,
            flag16: null,
            flag17: null,
            flag18: null,
            flag19: null,
            flag20: null,
            flag21: null,
            flag22: null,
            flag23: null,
            flag24: null,
            flag25: null,
            flag26: null,
            flag27: null,
            flag28: null,
            flag29: null,
            flag30: null,
            flag31: null,
            flag32: null,
            flag33: null,
            flag34: null,
            flag35: null,
            flag36: null,
            flag37: null,
            flag38: null,
            flag39: null,
            flag40: null,
            flag41: null,
            flag42: null,
            flag43: null,
            flag44: null,
            flag45: null,
            flag46: null,
            flag47: null,
            flag48: null,
            flag49: null,
            flag50: null,
            banner_long1: null,
            banner_long2: null,
            banner_long3: null,
            banner_long4: null,
            banner_long5: null,
            banner_long6: null,
            banner_long7: null,
            banner_long8: null,
            banner_long9: null,
            banner_long10: null,
            banner_long11: null,
            banner_long12: null,
            banner_long13: null,
            banner_long14: null,
            banner_medium1: null,
            banner_medium2: null,
            banner_medium3: null,
            banner_medium4: null,
            banner_medium5: null,
            banner_medium6: null,
            banner_medium7: null,
            banner_medium8: null,
            banner_small1: null,
            banner_small2: null,
            banner_small3: null,
            banner_small4: null,
            banner_small5: null,
            banner_small6: null,
            banner_small7: null,
            banner_small8: null,
            banner_small9: null,
            banner_small10: null,
            banner_small11: null,
            banner_small12: null,
            banner_small13: null,
            flag1_delete: 0,
            flag2_delete: 0,
            flag3_delete: 0,
            flag4_delete: 0,
            flag5_delete: 0,
            flag6_delete: 0,
            flag7_delete: 0,
            flag8_delete: 0,
            flag9_delete: 0,
            flag10_delete: 0,
            flag11_delete: 0,
            flag12_delete: 0,
            flag13_delete: 0,
            flag14_delete: 0,
            flag15_delete: 0,
            flag16_delete: 0,
            flag17_delete: 0,
            flag18_delete: 0,
            flag19_delete: 0,
            flag20_delete: 0,
            flag21_delete: 0,
            flag22_delete: 0,
            flag23_delete: 0,
            flag24_delete: 0,
            flag25_delete: 0,
            flag26_delete: 0,
            flag27_delete: 0,
            flag28_delete: 0,
            flag29_delete: 0,
            flag30_delete: 0,
            flag31_delete: 0,
            flag32_delete: 0,
            flag33_delete: 0,
            flag34_delete: 0,
            flag35_delete: 0,
            flag36_delete: 0,
            flag37_delete: 0,
            flag38_delete: 0,
            flag39_delete: 0,
            flag40_delete: 0,
            flag41_delete: 0,
            flag42_delete: 0,
            flag43_delete: 0,
            flag44_delete: 0,
            flag45_delete: 0,
            flag46_delete: 0,
            flag47_delete: 0,
            flag48_delete: 0,
            flag49_delete: 0,
            flag50_delete: 0,
            banner_long1_delete: 0,
            banner_long2_delete: 0,
            banner_long3_delete: 0,
            banner_long4_delete: 0,
            banner_long5_delete: 0,
            banner_long6_delete: 0,
            banner_long7_delete: 0,
            banner_long8_delete: 0,
            banner_long9_delete: 0,
            banner_long10_delete: 0,
            banner_long11_delete: 0,
            banner_long12_delete: 0,
            banner_long13_delete: 0,
            banner_long14_delete: 0,
            banner_medium1_delete: 0,
            banner_medium2_delete: 0,
            banner_medium3_delete: 0,
            banner_medium4_delete: 0,
            banner_medium5_delete: 0,
            banner_medium6_delete: 0,
            banner_medium7_delete: 0,
            banner_medium8_delete: 0,
            banner_small1_delete: 0,
            banner_small2_delete: 0,
            banner_small3_delete: 0,
            banner_small4_delete: 0,
            banner_small5_delete: 0,
            banner_small6_delete: 0,
            banner_small7_delete: 0,
            banner_small8_delete: 0,
            banner_small9_delete: 0,
            banner_small10_delete: 0,
            banner_small11_delete: 0,
            banner_small12_delete: 0,
            banner_small13_delete: 0,
            screen_video1_delete: 0,
            screen_video2_delete: 0,
            screen_video3_delete: 0,
            screen_video4_delete: 0,
            screen_video5_delete: 0,
            screen_video6_delete: 0,
            screen_video7_delete: 0,
            screen_document1_delete: 0,
            screen_document2_delete: 0,
            screen_document3_delete: 0,
            screen_document4_delete: 0,
            screen_document5_delete: 0,
            screen_document6_delete: 0,
            screen_document7_delete: 0,
            screen_document1: null,
            screen_document2: null,
            screen_document3: null,
            screen_document4: null,
            screen_document5: null,
            screen_document6: null,
            screen_document7: null,
            screen_video1: null,
            screen_video2: null,
            screen_video3: null,
            screen_video4: null,
            screen_video5: null,
            screen_video6: null,
            screen_video7: null,
            skybox: null,
            environment: null,
            skybox_is_custom: 1,
            environment_is_custom: 1,
            screen_1_description: 'Screen Description 1',
            screen_2_description: 'Screen Description 2',
        },
        // eventsAll: [],
        event_type: null,
        skyboxes: [
            { "id": 'Day Time', "title": "Day Time" },
            { "id": 'Night Time', "title": "Night Time" },
            { "id": 'Dusk', "title": "Dusk" },

        ],
        environments: [
            { "id": 'Mountains', "title": "Mountains" },
            { "id": 'City Landscape', "title": "City Landscape" },
            { "id": 'Forest', "title": "Forest" },

        ],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    //eventsAll: state => state.eventsAll,
    event_type: state => state.event_type,
    skyboxes: state => state.skyboxes,
    environments: state => state.environments,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            // if (_.isEmpty(state.item.event)) {
            //     params.set('event_id', '')
            // } else {
            //     params.set('event_id', state.item.event.id)
            // }
            if (state.item.auditorium_live === null) {
                params.delete('auditorium_live');
            }
            if (state.item.flag1 === null) {
                params.delete('flag1');
            }
            if (state.item.flag2 === null) {
                params.delete('flag2');
            }
            if (state.item.flag3 === null) {
                params.delete('flag3');
            }
            if (state.item.flag4 === null) {
                params.delete('flag4');
            }
            if (state.item.flag5 === null) {
                params.delete('flag5');
            }
            if (state.item.flag6 === null) {
                params.delete('flag6');
            }
            if (state.item.flag7 === null) {
                params.delete('flag7');
            }
            if (state.item.flag8 === null) {
                params.delete('flag8');
            }
            if (state.item.flag9 === null) {
                params.delete('flag9');
            }
            if (state.item.flag10 === null) {
                params.delete('flag10');
            }
            if (state.item.flag11 === null) {
                params.delete('flag11');
            }
            if (state.item.flag12 === null) {
                params.delete('flag12');
            }
            if (state.item.flag13 === null) {
                params.delete('flag13');
            }
            if (state.item.flag14 === null) {
                params.delete('flag14');
            }
            if (state.item.flag15 === null) {
                params.delete('flag15');
            }
            if (state.item.flag16 === null) {
                params.delete('flag16');
            }
            if (state.item.flag17 === null) {
                params.delete('flag17');
            }
            if (state.item.flag18 === null) {
                params.delete('flag18');
            }
            if (state.item.flag19 === null) {
                params.delete('flag19');
            }
            if (state.item.flag20 === null) {
                params.delete('flag20');
            }
            if (state.item.flag21 === null) {
                params.delete('flag21');
            }
            if (state.item.flag22 === null) {
                params.delete('flag22');
            }
            if (state.item.flag23 === null) {
                params.delete('flag23');
            }
            if (state.item.flag24 === null) {
                params.delete('flag24');
            }
            if (state.item.flag25 === null) {
                params.delete('flag25');
            }
            if (state.item.flag26 === null) {
                params.delete('flag26');
            }
            if (state.item.flag27 === null) {
                params.delete('flag27');
            }
            if (state.item.flag28 === null) {
                params.delete('flag28');
            }
            if (state.item.flag29 === null) {
                params.delete('flag29');
            }
            if (state.item.flag30 === null) {
                params.delete('flag30');
            }
            if (state.item.flag31 === null) {
                params.delete('flag31');
            }
            if (state.item.flag32 === null) {
                params.delete('flag32');
            }
            if (state.item.flag33 === null) {
                params.delete('flag33');
            }
            if (state.item.flag34 === null) {
                params.delete('flag34');
            }
            if (state.item.flag35 === null) {
                params.delete('flag35');
            }
            if (state.item.flag36 === null) {
                params.delete('flag36');
            }
            if (state.item.flag37 === null) {
                params.delete('flag37');
            }
            if (state.item.flag38 === null) {
                params.delete('flag38');
            }
            if (state.item.flag39 === null) {
                params.delete('flag39');
            }
            if (state.item.flag40 === null) {
                params.delete('flag40');
            }
            if (state.item.flag41 === null) {
                params.delete('flag41');
            }
            if (state.item.flag42 === null) {
                params.delete('flag42');
            }
            if (state.item.flag43 === null) {
                params.delete('flag43');
            }
            if (state.item.flag44 === null) {
                params.delete('flag44');
            }
            if (state.item.flag45 === null) {
                params.delete('flag45');
            }
            if (state.item.flag46 === null) {
                params.delete('flag46');
            }
            if (state.item.flag47 === null) {
                params.delete('flag47');
            }
            if (state.item.flag48 === null) {
                params.delete('flag48');
            }
            if (state.item.flag49 === null) {
                params.delete('flag49');
            }
            if (state.item.flag50 === null) {
                params.delete('flag50');
            }
            if (state.item.banner_long1 === null) {
                params.delete('banner_long1');
            }
            if (state.item.banner_long2 === null) {
                params.delete('banner_long2');
            }
            if (state.item.banner_long3 === null) {
                params.delete('banner_long3');
            }
            if (state.item.banner_long4 === null) {
                params.delete('banner_long4');
            }
            if (state.item.banner_long5 === null) {
                params.delete('banner_long5');
            }
            if (state.item.banner_long6 === null) {
                params.delete('banner_long6');
            }
            if (state.item.banner_long7 === null) {
                params.delete('banner_long7');
            }
            if (state.item.banner_long8 === null) {
                params.delete('banner_long8');
            }
            if (state.item.banner_medium1 === null) {
                params.delete('banner_medium1');
            }
            if (state.item.banner_medium2 === null) {
                params.delete('banner_medium2');
            }
            if (state.item.banner_medium3 === null) {
                params.delete('banner_medium3');
            }
            if (state.item.banner_medium4 === null) {
                params.delete('banner_medium4');
            }
            if (state.item.banner_medium5 === null) {
                params.delete('banner_medium5');
            }
            if (state.item.banner_medium6 === null) {
                params.delete('banner_medium6');
            }
            if (state.item.banner_medium7 === null) {
                params.delete('banner_medium7');
            }
            if (state.item.banner_medium8 === null) {
                params.delete('banner_medium8');
            }
            if (state.item.banner_small1 === null) {
                params.delete('banner_small1');
            }
            if (state.item.banner_small2 === null) {
                params.delete('banner_small2');
            }
            if (state.item.banner_small3 === null) {
                params.delete('banner_small3');
            }
            if (state.item.banner_small4 === null) {
                params.delete('banner_small4');
            }
            if (state.item.banner_small5 === null) {
                params.delete('banner_small5');
            }
            if (state.item.banner_small6 === null) {
                params.delete('banner_small6');
            }
            if (state.item.banner_small7 === null) {
                params.delete('banner_small7');
            }
            if (state.item.banner_small8 === null) {
                params.delete('banner_small8');
            }
            if (state.item.banner_small9 === null) {
                params.delete('banner_small9');
            }
            if (state.item.auditorium_pre_recorded === null) {
                params.delete('auditorium_pre_recorded');
            }
            if (state.item.auditorium_panel_discussion === null) {
                params.delete('auditorium_panel_discussion');
            }
            if (state.item.exhibition_area === null) {
                params.delete('exhibition_area');
            }
            if (state.item.virtual_training === null) {
                params.delete('virtual_training');
            }
            if (state.item.virtual_meeting === null) {
                params.delete('virtual_meeting');
            }
            if (state.item.exhibition_video === null) {
                params.delete('exhibition_video');
            }
            if (state.item.screen_video1 === null) {
                params.delete('screen_video1');
            }
            if (state.item.screen_video2 === null) {
                params.delete('screen_video2');
            }
            if (state.item.screen_video3 === null) {
                params.delete('screen_video3');
            }
            if (state.item.screen_video4 === null) {
                params.delete('screen_video4');
            }
            if (state.item.screen_video5 === null) {
                params.delete('screen_video5');
            }
            if (state.item.screen_video6 === null) {
                params.delete('screen_video6');
            }
            if (state.item.screen_video7 === null) {
                params.delete('screen_video7');
            }
            if (state.item.exhibition_document === null) {
                params.delete('exhibition_document');
            }
            if (state.item.screen_document1 === null) {
                params.delete('screen_document1');
            }
            if (state.item.screen_document2 === null) {
                params.delete('screen_document2');
            }
            if (state.item.screen_document3 === null) {
                params.delete('screen_document3');
            }
            if (state.item.screen_document4 === null) {
                params.delete('screen_document4');
            }
            if (state.item.screen_document5 === null) {
                params.delete('screen_document5');
            }
            if (state.item.screen_document6 === null) {
                params.delete('screen_document6');
            }
            if (state.item.screen_document7 === null) {
                params.delete('screen_document7');
            }
            if (state.item.logo_standee === null) {
                params.delete('logo_standee');
            }

            params.set('is_picture', state.item.is_picture ? 1 : 0)
            params.set('skybox_is_custom', state.item.skybox_is_custom ? 1 : 0)
            params.set('environment_is_custom', state.item.environment_is_custom ? 1 : 0)
            axios.post(constants.API_URL + '/api/v1/event-halls', params)
                .then(response => {
                    commit('resetState')
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            // if (_.isEmpty(state.item.event)) {
            //     params.set('event_id', '')
            // } else {
            //     params.set('event_id', state.item.event.id)
            // }


            if (_.isEmpty(state.item.skybox)) {
                params.delete('skybox')
            } else {
                for (let index in state.item.skybox) {
                    params.set('skybox[' + index + ']', state.item.skybox[index])
                }
            }
            if (_.isEmpty(state.item.environment)) {
                params.delete('environment')
            } else {
                for (let index in state.item.environment) {
                    params.set('environment[' + index + ']', state.item.environment[index])
                }
            }

            if (state.item.auditorium_live === null) {
                params.delete('auditorium_live');
            }

            if (state.item.auditorium_live && state.item.auditorium_live.id != undefined) {
                params.set('auditorium_live', '')
            }

            if (state.item.auditorium_pre_recorded === null) {
                params.delete('auditorium_pre_recorded');
            }

            if (state.item.auditorium_pre_recorded && state.item.auditorium_pre_recorded.id != undefined) {
                params.set('auditorium_pre_recorded', '')
            }

            if (state.item.auditorium_panel_discussion === null) {
                params.delete('auditorium_panel_discussion');
            }

            if (state.item.auditorium_panel_discussion && state.item.auditorium_panel_discussion.id != undefined) {
                params.set('auditorium_panel_discussion', '')
            }

            if (state.item.exhibition_area === null) {
                params.delete('exhibition_area');
            }
            if (state.item.exhibition_area && state.item.exhibition_area.id != undefined) {
                params.set('exhibition_area', '')
            }
            if (state.item.virtual_training === null) {
                params.delete('virtual_training');
            }

            if (state.item.virtual_training && state.item.virtual_training.id != undefined) {
                params.set('virtual_training', '')
            }

            if (state.item.roundtable === null) {
                params.delete('roundtable');
            }

            if (state.item.roundtable && state.item.roundtable.id != undefined) {
                params.set('roundtable', '')
            }

            if (state.item.virtual_meeting === null) {
                params.delete('virtual_meeting');
            }
            if (state.item.virtual_meeting && state.item.virtual_meeting.id != undefined) {
                params.set('virtual_meeting', '')
            }
            if (state.item.exhibition_video === null) {
                params.delete('exhibition_video');
            }
            if (state.item.exhibition_video && state.item.exhibition_video.id != undefined) {
                params.set('exhibition_video', '')
            }
            if (state.item.exhibition_document === null) {
                params.delete('exhibition_document');
            }
            if (state.item.exhibition_document && state.item.exhibition_document.id != undefined) {
                params.set('exhibition_document', '')
            }
            if (state.item.logo_standee === null) {
                params.delete('logo_standee');
            }
            if (state.item.logo_standee && state.item.logo_standee.id != undefined) {
                params.set('logo_standee', '')
            }
            if (state.item.background_picture && state.item.background_picture.id != undefined) {
                params.set('background_picture', '')
            }
            if (state.item.skybox_picture && state.item.skybox_picture.id != undefined) {
                params.set('skybox_picture', '')
            }
            if (state.item.environment_picture && state.item.environment_picture.id != undefined) {
                params.set('environment_picture', '')
            }


            if (state.item.exhibition_picture === null) {
                params.delete('exhibition_picture');
            }
            if (state.item.flag1 === null) {
                params.delete('flag1');
            }
            if (state.item.flag2 === null) {
                params.delete('flag2');
            }
            if (state.item.flag3 === null) {
                params.delete('flag3');
            }
            if (state.item.flag4 === null) {
                params.delete('flag4');
            }
            if (state.item.flag5 === null) {
                params.delete('flag5');
            }
            if (state.item.flag6 === null) {
                params.delete('flag6');
            }
            if (state.item.flag7 === null) {
                params.delete('flag7');
            }
            if (state.item.flag8 === null) {
                params.delete('flag8');
            }
            if (state.item.flag9 === null) {
                params.delete('flag9');
            }
            if (state.item.flag10 === null) {
                params.delete('flag10');
            }
            if (state.item.flag11 === null) {
                params.delete('flag11');
            }
            if (state.item.flag12 === null) {
                params.delete('flag12');
            }
            if (state.item.flag13 === null) {
                params.delete('flag13');
            }
            if (state.item.flag14 === null) {
                params.delete('flag14');
            }
            if (state.item.flag15 === null) {
                params.delete('flag15');
            }
            if (state.item.flag16 === null) {
                params.delete('flag16');
            }
            if (state.item.flag17 === null) {
                params.delete('flag17');
            }
            if (state.item.flag18 === null) {
                params.delete('flag18');
            }
            if (state.item.flag19 === null) {
                params.delete('flag19');
            }
            if (state.item.flag20 === null) {
                params.delete('flag20');
            }
            if (state.item.flag21 === null) {
                params.delete('flag21');
            }
            if (state.item.flag22 === null) {
                params.delete('flag22');
            }
            if (state.item.flag23 === null) {
                params.delete('flag23');
            }
            if (state.item.flag24 === null) {
                params.delete('flag24');
            }
            if (state.item.flag25 === null) {
                params.delete('flag25');
            }
            if (state.item.flag26 === null) {
                params.delete('flag26');
            }
            if (state.item.flag27 === null) {
                params.delete('flag27');
            }
            if (state.item.flag28 === null) {
                params.delete('flag28');
            }
            if (state.item.flag29 === null) {
                params.delete('flag29');
            }
            if (state.item.flag30 === null) {
                params.delete('flag30');
            }
            if (state.item.flag31 === null) {
                params.delete('flag31');
            }
            if (state.item.flag32 === null) {
                params.delete('flag32');
            }
            if (state.item.flag33 === null) {
                params.delete('flag33');
            }
            if (state.item.flag34 === null) {
                params.delete('flag34');
            }
            if (state.item.flag35 === null) {
                params.delete('flag35');
            }
            if (state.item.flag36 === null) {
                params.delete('flag36');
            }
            if (state.item.flag37 === null) {
                params.delete('flag37');
            }
            if (state.item.flag38 === null) {
                params.delete('flag38');
            }
            if (state.item.flag39 === null) {
                params.delete('flag39');
            }
            if (state.item.flag40 === null) {
                params.delete('flag40');
            }
            if (state.item.flag41 === null) {
                params.delete('flag41');
            }
            if (state.item.flag42 === null) {
                params.delete('flag42');
            }
            if (state.item.flag43 === null) {
                params.delete('flag43');
            }
            if (state.item.flag44 === null) {
                params.delete('flag44');
            }
            if (state.item.flag45 === null) {
                params.delete('flag45');
            }
            if (state.item.flag46 === null) {
                params.delete('flag46');
            }
            if (state.item.flag47 === null) {
                params.delete('flag47');
            }
            if (state.item.flag48 === null) {
                params.delete('flag48');
            }
            if (state.item.flag49 === null) {
                params.delete('flag49');
            }
            if (state.item.flag50 === null) {
                params.delete('flag50');
            }
            if (state.item.banner_long1 === null) {
                params.delete('banner_long1');
            }
            if (state.item.banner_long2 === null) {
                params.delete('banner_long2');
            }
            if (state.item.banner_long3 === null) {
                params.delete('banner_long3');
            }
            if (state.item.banner_long4 === null) {
                params.delete('banner_long4');
            }
            if (state.item.banner_long5 === null) {
                params.delete('banner_long5');
            }
            if (state.item.banner_long6 === null) {
                params.delete('banner_long6');
            }
            if (state.item.banner_long7 === null) {
                params.delete('banner_long7');
            }
            if (state.item.banner_long8 === null) {
                params.delete('banner_long8');
            }
            if (state.item.banner_long9 === null) {
                params.delete('banner_long9');
            }
            if (state.item.banner_long10 === null) {
                params.delete('banner_long10');
            }
            if (state.item.banner_long11 === null) {
                params.delete('banner_long11');
            }
            if (state.item.banner_long12 === null) {
                params.delete('banner_long12');
            }
            if (state.item.banner_long13 === null) {
                params.delete('banner_long13');
            }
            if (state.item.banner_long14 === null) {
                params.delete('banner_long14');
            }

            if (state.item.banner_small1 === null) {
                params.delete('banner_small1');
            }
            if (state.item.banner_small2 === null) {
                params.delete('banner_small2');
            }
            if (state.item.banner_small3 === null) {
                params.delete('banner_small3');
            }
            if (state.item.banner_small4 === null) {
                params.delete('banner_small4');
            }
            if (state.item.banner_small5 === null) {
                params.delete('banner_small5');
            }
            if (state.item.banner_small6 === null) {
                params.delete('banner_small6');
            }
            if (state.item.banner_small7 === null) {
                params.delete('banner_small7');
            }
            if (state.item.banner_small8 === null) {
                params.delete('banner_small8');
            }
            if (state.item.banner_small9 === null) {
                params.delete('banner_small9');
            }
            if (state.item.banner_small10 === null) {
                params.delete('banner_small10');
            }
            if (state.item.banner_small11 === null) {
                params.delete('banner_small11');
            }
            if (state.item.banner_small2 === null) {
                params.delete('banner_small2');
            }
            if (state.item.banner_small13 === null) {
                params.delete('banner_small13');
            }
            if (state.item.banner_small1 && state.item.banner_small1.id != undefined) {
                params.set('banner_small1', '')
            }
            if (state.item.banner_small2 && state.item.banner_small2.id != undefined) {
                params.set('banner_small2', '')
            }
            if (state.item.banner_small3 && state.item.banner_small3.id != undefined) {
                params.set('banner_small3', '')
            }
            if (state.item.banner_small4 && state.item.banner_small4.id != undefined) {
                params.set('banner_small4', '')
            }
            if (state.item.banner_small5 && state.item.banner_small5.id != undefined) {
                params.set('banner_small5', '')
            }
            if (state.item.banner_small6 && state.item.banner_small6.id != undefined) {
                params.set('banner_small6', '')
            }
            if (state.item.banner_small7 && state.item.banner_small7.id != undefined) {
                params.set('banner_small7', '')
            }
            if (state.item.banner_small8 && state.item.banner_small8.id != undefined) {
                params.set('banner_small8', '')
            }
            if (state.item.banner_small9 && state.item.banner_small9.id != undefined) {
                params.set('banner_small9', '')
            }
            if (state.item.banner_small10 && state.item.banner_small10.id != undefined) {
                params.set('banner_small10', '')
            }
            if (state.item.banner_small11 && state.item.banner_small11.id != undefined) {
                params.set('banner_small11', '')
            }
            if (state.item.banner_small12 && state.item.banner_small12.id != undefined) {
                params.set('banner_small12', '')
            }
            if (state.item.banner_small13 && state.item.banner_small13.id != undefined) {
                params.set('banner_small13', '')
            }

            if (state.item.banner_long1 && state.item.banner_long1.id != undefined) {
                params.set('banner_long1', '')
            }
            if (state.item.banner_long2 && state.item.banner_long2.id != undefined) {
                params.set('banner_long2', '')
            }
            if (state.item.banner_long3 && state.item.banner_long3.id != undefined) {
                params.set('banner_long3', '')
            }
            if (state.item.banner_long4 && state.item.banner_long4.id != undefined) {
                params.set('banner_long4', '')
            }
            if (state.item.banner_long5 && state.item.banner_long5.id != undefined) {
                params.set('banner_long5', '')
            }
            if (state.item.banner_long6 && state.item.banner_long6.id != undefined) {
                params.set('banner_long6', '')
            }
            if (state.item.banner_long7 && state.item.banner_long7.id != undefined) {
                params.set('banner_long7', '')
            }
            if (state.item.banner_long8 && state.item.banner_long8.id != undefined) {
                params.set('banner_long8', '')
            }
            if (state.item.banner_long9 && state.item.banner_long9.id != undefined) {
                params.set('banner_long9', '')
            }
            if (state.item.banner_long10 && state.item.banner_long10.id != undefined) {
                params.set('banner_long10', '')
            }
            if (state.item.banner_long11 && state.item.banner_long11.id != undefined) {
                params.set('banner_long11', '')
            }
            if (state.item.banner_long12 && state.item.banner_long12.id != undefined) {
                params.set('banner_long12', '')
            }
            if (state.item.banner_long13 && state.item.banner_long13.id != undefined) {
                params.set('banner_long13', '')
            }
            if (state.item.banner_long14 && state.item.banner_long14.id != undefined) {
                params.set('banner_long14', '')
            }
            if (state.item.flag1 && state.item.flag1.id != undefined) {
                params.set('flag1', '')
            }
            if (state.item.flag2 && state.item.flag2.id != undefined) {
                params.set('flag2', '')
            }
            if (state.item.flag3 && state.item.flag3.id != undefined) {
                params.set('flag3', '')
            }
            if (state.item.flag4 && state.item.flag4.id != undefined) {
                params.set('flag4', '')
            }
            if (state.item.flag5 && state.item.flag5.id != undefined) {
                params.set('flag5', '')
            }
            if (state.item.flag6 && state.item.flag6.id != undefined) {
                params.set('flag6', '')
            }
            if (state.item.flag7 && state.item.flag7.id != undefined) {
                params.set('flag7', '')
            }
            if (state.item.flag8 && state.item.flag8.id != undefined) {
                params.set('flag8', '')
            }
            if (state.item.flag9 && state.item.flag9.id != undefined) {
                params.set('flag9', '')
            }
            if (state.item.flag10 && state.item.flag10.id != undefined) {
                params.set('flag10', '')
            }
            if (state.item.flag11 && state.item.flag11.id != undefined) {
                params.set('flag11', '')
            }
            if (state.item.flag12 && state.item.flag12.id != undefined) {
                params.set('flag12', '')
            }
            if (state.item.flag13 && state.item.flag13.id != undefined) {
                params.set('flag13', '')
            }
            if (state.item.flag14 && state.item.flag14.id != undefined) {
                params.set('flag14', '')
            }
            if (state.item.flag15 && state.item.flag15.id != undefined) {
                params.set('flag15', '')
            }
            if (state.item.flag16 && state.item.flag16.id != undefined) {
                params.set('flag16', '')
            }


            if (state.item.screen_video1 === null) {
                params.delete('screen_video1');
            }
            if (state.item.screen_video1 && state.item.screen_video1.id != undefined) {
                params.set('screen_video1', '')
            }
            if (state.item.screen_video2 === null) {
                params.delete('screen_video2');
            }
            if (state.item.screen_video2 && state.item.screen_video2.id != undefined) {
                params.set('screen_video2', '')
            }

            if (state.item.screen_document1 === null) {
                params.delete('screen_document1');
            }
            if (state.item.screen_document1 && state.item.screen_document1.id != undefined) {
                params.set('screen_document1', '')
            }
            if (state.item.screen_document2 === null) {
                params.delete('screen_document2');
            }
            if (state.item.screen_document2 && state.item.screen_document2.id != undefined) {
                params.set('screen_document2', '')
            }
            if (state.item.exhibition_picture && state.item.exhibition_picture.id != undefined) {
                params.set('exhibition_picture', '')
            }


            params.set('is_picture', state.item.is_picture ? 1 : 0)
            params.set('skybox_is_custom', state.item.skybox_is_custom ? 1 : 0)
            params.set('environment_is_custom', state.item.environment_is_custom ? 1 : 0)
            axios.post(constants.API_URL + '/api/v1/exhibition-areas/' + state.item.event_id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message

                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    fetchData({ commit, dispatch }, params) {
        commit('setLoading', true)
        axios.get(constants.API_URL + '/api/v1/exhibition-areas/' + params.id)
            .then(response => {
                var skyboxes = [
                    { "id": 'Day Time', "title": "Day Time" },
                    { "id": 'Night Time', "title": "Night Time" },
                    { "id": 'Dusk', "title": "Dusk" },

                ];
                var environments = [
                    { "id": 'Mountains', "title": "Mountains" },
                    { "id": 'City Landscape', "title": "City Landscape" },
                    { "id": 'Forest', "title": "Forest" },

                ];
                var res = response.data.data;
                let selected_skybox = skyboxes.find(obj => {
                    return obj.id === res.skybox;
                })
                res.skybox = selected_skybox;
                let selected_env = environments.find(obj => {
                    return obj.id === res.environment;
                })
                res.environment = selected_env;
                commit('setItem', res)
                //commit('setItem', response.data.data)
            })

        dispatch('fetchEventsAll', params.id)
        dispatch('getEventTimeZone', params.id)
        commit('setLoading', false)
    },
    fetchEventsAll({ commit }, event_id) {
        commit('setEvent', event_id)
    },
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        axios.get(constants.API_URL + '/api/v1/event_details/' + event_id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type)
                state.event_type = response.data.data[0].event_type
            })

    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setSkybox({ commit }, value) {
        commit('setSkybox', value)
    },
    setEnvironment({ commit }, value) {
        commit('setEnvironment', value)
    },
    setSkybox_is_custom({ commit }, value) {
        commit('setSkybox_is_custom', value)
    },
    setEnvironment_is_custom({ commit }, value) {
        commit('setEnvironment_is_custom', value)
    },
    setAuditorium_live({ commit }, value) {
        commit('setAuditorium_live', value)
    },
    setExhibitionPicture({ commit }, value) {
        commit('setExhibitionPicture', value)
    },
    setFlag1({ commit }, value) {
        commit('setFlag1', value)
    },
    setFlag2({ commit }, value) {
        commit('setFlag2', value)
    },
    setFlag3({ commit }, value) {
        commit('setFlag3', value)
    },
    setFlag4({ commit }, value) {
        commit('setFlag4', value)
    },
    setFlag5({ commit }, value) {
        commit('setFlag5', value)
    },
    setFlag6({ commit }, value) {
        commit('setFlag6', value)
    },
    setFlag7({ commit }, value) {
        commit('setFlag7', value)
    },
    setFlag8({ commit }, value) {
        commit('setFlag8', value)
    },
    setFlag9({ commit }, value) {
        commit('setFlag9', value)
    },
    setFlag10({ commit }, value) {
        commit('setFlag10', value)
    },
    setFlag11({ commit }, value) {
        commit('setFlag11', value)
    },
    setFlag12({ commit }, value) {
        commit('setFlag12', value)
    },
    setFlag13({ commit }, value) {
        commit('setFlag13', value)
    },
    setFlag14({ commit }, value) {
        commit('setFlag14', value)
    },
    setFlag15({ commit }, value) {
        commit('setFlag15', value)
    },
    setFlag16({ commit }, value) {
        commit('setFlag16', value)
    },
    setFlag17({ commit }, value) {
        commit('setFlag17', value)
    },
    setFlag18({ commit }, value) {
        commit('setFlag18', value)
    },
    setFlag19({ commit }, value) {
        commit('setFlag19', value)
    },
    setFlag20({ commit }, value) {
        commit('setFlag20', value)
    },
    setFlag21({ commit }, value) {
        commit('setFlag21', value)
    },
    setFlag22({ commit }, value) {
        commit('setFlag22', value)
    },
    setFlag23({ commit }, value) {
        commit('setFlag23', value)
    },
    setFlag24({ commit }, value) {
        commit('setFlag24', value)
    },
    setFlag25({ commit }, value) {
        commit('setFlag25', value)
    },
    setFlag26({ commit }, value) {
        commit('setFlag26', value)
    },
    setFlag27({ commit }, value) {
        commit('setFlag27', value)
    },
    setFlag28({ commit }, value) {
        commit('setFlag28', value)
    },
    setFlag29({ commit }, value) {
        commit('setFlag29', value)
    },
    setFlag30({ commit }, value) {
        commit('setFlag30', value)
    },
    setFlag31({ commit }, value) {
        commit('setFlag31', value)
    },
    setFlag32({ commit }, value) {
        commit('setFlag32', value)
    },
    setFlag33({ commit }, value) {
        commit('setFlag33', value)
    },
    setFlag34({ commit }, value) {
        commit('setFlag34', value)
    },
    setFlag35({ commit }, value) {
        commit('setFlag35', value)
    },
    setFlag36({ commit }, value) {
        commit('setFlag36', value)
    },
    setFlag37({ commit }, value) {
        commit('setFlag37', value)
    },
    setFlag38({ commit }, value) {
        commit('setFlag38', value)
    },
    setFlag39({ commit }, value) {
        commit('setFlag39', value)
    },
    setFlag40({ commit }, value) {
        commit('setFlag40', value)
    },
    setFlag41({ commit }, value) {
        commit('setFlag41', value)
    },
    setFlag42({ commit }, value) {
        commit('setFlag42', value)
    },
    setFlag43({ commit }, value) {
        commit('setFlag43', value)
    },
    setFlag44({ commit }, value) {
        commit('setFlag44', value)
    },
    setFlag45({ commit }, value) {
        commit('setFlag45', value)
    },
    setFlag46({ commit }, value) {
        commit('setFlag46', value)
    },
    setFlag47({ commit }, value) {
        commit('setFlag47', value)
    },
    setFlag48({ commit }, value) {
        commit('setFlag48', value)
    },
    setFlag49({ commit }, value) {
        commit('setFlag49', value)
    },
    setFlag50({ commit }, value) {
        commit('setFlag50', value)
    },
    setBannerLong1({ commit }, value) {
        commit('setBannerLong1', value)
    },
    setBannerLong2({ commit }, value) {
        commit('setBannerLong2', value)
    },
    setBannerLong3({ commit }, value) {
        commit('setBannerLong3', value)
    },
    setBannerLong8({ commit }, value) {
        commit('setBannerLong8', value)
    },
    setBannerLong9({ commit }, value) {
        commit('setBannerLong9', value)
    },
    setBannerLong10({ commit }, value) {
        commit('setBannerLong10', value)
    },
    setBannerLong11({ commit }, value) {
        commit('setBannerLong11', value)
    },
    setBannerLong12({ commit }, value) {
        commit('setBannerLong12', value)
    },
    setBannerLong13({ commit }, value) {
        commit('setBannerLong13', value)
    },
    setBannerLong14({ commit }, value) {
        commit('setBannerLong14', value)
    },
    setBannerLong4({ commit }, value) {
        commit('setBannerLong4', value)
    },
    setBannerLong5({ commit }, value) {
        commit('setBannerLong5', value)
    },
    setBannerLong6({ commit }, value) {
        commit('setBannerLong6', value)
    },
    setBannerLong7({ commit }, value) {
        commit('setBannerLong7', value)
    },
    setBannerMedium1({ commit }, value) {
        commit('setBannerMedium1', value)
    },
    setBannerMedium2({ commit }, value) {
        commit('setBannerMedium2', value)
    },
    setBannerMedium3({ commit }, value) {
        commit('setBannerMedium3', value)
    },
    setBannerMedium4({ commit }, value) {
        commit('setBannerMedium4', value)
    },
    setBannerMedium5({ commit }, value) {
        commit('setBannerMedium5', value)
    },
    setBannerMedium6({ commit }, value) {
        commit('setBannerMedium6', value)
    },
    setBannerMedium7({ commit }, value) {
        commit('setBannerMedium7', value)
    },
    setBannerMedium8({ commit }, value) {
        commit('setBannerMedium8', value)
    },
    setBannerSmall1({ commit }, value) {
        commit('setBannerSmall1', value)
    },
    setBannerSmall2({ commit }, value) {
        commit('setBannerSmall2', value)
    },
    setBannerSmall3({ commit }, value) {
        commit('setBannerSmall3', value)
    },
    setBannerSmall4({ commit }, value) {
        commit('setBannerSmall4', value)
    },
    setBannerSmall5({ commit }, value) {
        commit('setBannerSmall5', value)
    },
    setBannerSmall6({ commit }, value) {
        commit('setBannerSmall6', value)
    },
    setBannerSmall7({ commit }, value) {
        commit('setBannerSmall7', value)
    },
    setBannerSmall8({ commit }, value) {
        commit('setBannerSmall8', value)
    },
    setBannerSmall9({ commit }, value) {
        commit('setBannerSmall9', value)
    },
    setBannerSmall10({ commit }, value) {
        commit('setBannerSmall10', value)
    },
    setBannerSmall11({ commit }, value) {
        commit('setBannerSmall11', value)
    },
    setBannerSmall12({ commit }, value) {
        commit('setBannerSmall12', value)
    },
    setBannerSmall13({ commit }, value) {
        commit('setBannerSmall13', value)
    },

    setAuditorium_pre_recorded({ commit }, value) {
        commit('setAuditorium_pre_recorded', value)
    },

    setAuditorium_panel_discussion({ commit }, value) {
        commit('setAuditorium_panel_discussion', value)
    },

    setExhibition_area({ commit }, value) {
        commit('setExhibition_area', value)
    },

    setVirtual_training({ commit }, value) {
        commit('setVirtual_training', value)
    },
    setRoundtable({ commit }, value) {
        commit('setRoundtable', value)
    },

    setVirtual_meeting({ commit }, value) {
        commit('setVirtual_meeting', value)
    },
    setAuditorium_live_label({ commit }, value) {
        commit('setAuditorium_live_label', value)
    },
    setScrollingText({ commit }, value) {
        commit('setScrollingText', value)
    },

    setAuditorium_pre_recorded_label({ commit }, value) {
        commit('setAuditorium_pre_recorded_label', value)
    },

    setAuditorium_panel_discussion_label({ commit }, value) {
        commit('setAuditorium_panel_discussion_label', value)
    },

    setExhibition_area_label({ commit }, value) {
        commit('setExhibition_area_label', value)
    },

    setVirtual_training_label({ commit }, value) {
        commit('setVirtual_training_label', value)
    },
    setRoundtable_label({ commit }, value) {
        commit('setRoundtable_label', value)
    },

    setVirtual_meeting_label({ commit }, value) {
        commit('setVirtual_meeting_label', value)
    },

    setExhibition_video({ commit }, value) {
        commit('setExhibition_video', value)
    },
    setScreen_video1({ commit }, value) {
        commit('setScreen_video1', value)
    },
    setScreen_video2({ commit }, value) {
        commit('setScreen_video2', value)
    },
    setScreen_video3({ commit }, value) {
        commit('setScreen_video3', value)
    },
    setScreen_video4({ commit }, value) {
        commit('setScreen_video4', value)
    },
    setScreen_video5({ commit }, value) {
        commit('setScreen_video5', value)
    },
    setScreen_video6({ commit }, value) {
        commit('setScreen_video6', value)
    },
    setScreen_video7({ commit }, value) {
        commit('setScreen_video7', value)
    },
    setScreenDesc1({ commit }, value) {
        commit('setScreenDesc1', value)
    },
    setScreenDesc2({ commit }, value) {
        commit('setScreenDesc2', value)
    },
    setExhibition_document({ commit }, value) {
        commit('setExhibition_document', value)
    },
    setScreen_document1({ commit }, value) {
        commit('setScreen_document1', value)
    },
    setScreen_document2({ commit }, value) {
        commit('setScreen_document2', value)
    },
    setScreen_document3({ commit }, value) {
        commit('setScreen_document3', value)
    },
    setScreen_document4({ commit }, value) {
        commit('setScreen_document4', value)
    },
    setScreen_document5({ commit }, value) {
        commit('setScreen_document5', value)
    },
    setScreen_document6({ commit }, value) {
        commit('setScreen_document6', value)
    },
    setScreen_document7({ commit }, value) {
        commit('setScreen_document7', value)
    },
    setLogo_standee({ commit }, value) {
        commit('setLogo_standee', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setSkyboxPicture({ commit }, value) {
        commit('setSkyboxPicture', value)
    },
    setEnvironmentPicture({ commit }, value) {
        commit('setEnvironmentPicture', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setMaxCountBooth({ commit }, value) {
        commit('setMaxCountBooth', value)
    },
    setIs_picture({ commit }, value) {
        commit('setIs_picture', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setEventType(state, value) {
        state.event_type = value
    },
    setItem(state, item) {
        state.item = item

    },
    setScreenDesc1(state, value) {
        state.item.screen_1_description = value
    },
    setScreenDesc2(state, value) {
        state.item.screen_2_description = value
    },
    setSkybox_is_custom(state, value) {
        state.item.skybox_is_custom = value
        if (value == '') {
            state.item.skybox_picture_delete = 1
        } else {
            state.item.skybox_picture_delete = 0
        }
    },
    setEnvironment_is_custom(state, value) {
        state.item.environment_is_custom = value
        if (value == '') {
            state.item.environment_picture_delete = 1
        } else {
            state.item.environment_picture_delete = 0
        }
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setSkybox(state, value) {
        state.item.skybox = value
    },
    setEnvironment(state, value) {
        state.item.environment = value
    },
    setAuditorium_live(state, value) {
        state.item.auditorium_live = value
        if (value == '') {
            state.item.live_delete = 1
        } else {
            state.item.live_delete = 0
        }
    },
    setExhibitionPicture(state, value) {
        state.item.exhibition_picture = value
        if (value == '') {
            state.item.exhibition_picture_delete = 1
        } else {
            state.item.exhibition_picture_delete = 0
        }
    },
    setFlag1(state, value) {
        state.item.flag1 = value
        if (value == '') {
            state.item.flag1_delete = 1
        } else {
            state.item.flag1_delete = 0
        }
    },
    setFlag2(state, value) {
        state.item.flag2 = value
        if (value == '') {
            state.item.flag2_delete = 1
        } else {
            state.item.flag2_delete = 0
        }
    },
    setFlag3(state, value) {
        state.item.flag3 = value
        if (value == '') {
            state.item.flag3_delete = 1
        } else {
            state.item.flag3_delete = 0
        }
    },
    setFlag4(state, value) {
        state.item.flag4 = value
        if (value == '') {
            state.item.flag4_delete = 1
        } else {
            state.item.flag4_delete = 0
        }
    },
    setFlag5(state, value) {
        state.item.flag5 = value
        if (value == '') {
            state.item.flag5_delete = 1
        } else {
            state.item.flag5_delete = 0
        }
    },
    setFlag6(state, value) {
        state.item.flag6 = value
        if (value == '') {
            state.item.flag6_delete = 1
        } else {
            state.item.flag6_delete = 0
        }
    },
    setFlag7(state, value) {
        state.item.flag7 = value
        if (value == '') {
            state.item.flag7_delete = 1
        } else {
            state.item.flag7_delete = 0
        }
    },
    setFlag8(state, value) {
        state.item.flag8 = value
        if (value == '') {
            state.item.flag8_delete = 1
        } else {
            state.item.flag8_delete = 0
        }
    },
    setFlag9(state, value) {
        state.item.flag9 = value
        if (value == '') {
            state.item.flag9_delete = 1
        } else {
            state.item.flag9_delete = 0
        }
    },
    setFlag10(state, value) {
        state.item.flag10 = value
        if (value == '') {
            state.item.flag10_delete = 1
        } else {
            state.item.flag10_delete = 0
        }
    },
    setFlag11(state, value) {
        state.item.flag11 = value
        if (value == '') {
            state.item.flag11_delete = 1
        } else {
            state.item.flag11_delete = 0
        }
    },
    setFlag12(state, value) {
        state.item.flag12 = value
        if (value == '') {
            state.item.flag12_delete = 1
        } else {
            state.item.flag12_delete = 0
        }
    },
    setFlag13(state, value) {
        state.item.flag13 = value
        if (value == '') {
            state.item.flag13_delete = 1
        } else {
            state.item.flag13_delete = 0
        }
    },
    setFlag14(state, value) {
        state.item.flag14 = value
        if (value == '') {
            state.item.flag14_delete = 1
        } else {
            state.item.flag14_delete = 0
        }
    },
    setFlag15(state, value) {
        state.item.flag15 = value
        if (value == '') {
            state.item.flag15_delete = 1
        } else {
            state.item.flag15_delete = 0
        }
    },
    setFlag16(state, value) {
        state.item.flag16 = value
        if (value == '') {
            state.item.flag16_delete = 1
        } else {
            state.item.flag16_delete = 0
        }
    },
    setFlag17(state, value) {
        state.item.flag17 = value
        if (value == '') {
            state.item.flag17_delete = 1
        } else {
            state.item.flag17_delete = 0
        }
    },
    setFlag18(state, value) {
        state.item.flag18 = value
        if (value == '') {
            state.item.flag18_delete = 1
        } else {
            state.item.flag18_delete = 0
        }
    },
    setFlag19(state, value) {
        state.item.flag19 = value
        if (value == '') {
            state.item.flag19_delete = 1
        } else {
            state.item.flag19_delete = 0
        }
    },
    setFlag20(state, value) {
        state.item.flag20 = value
        if (value == '') {
            state.item.flag20_delete = 1
        } else {
            state.item.flag20_delete = 0
        }
    },
    setFlag21(state, value) {
        state.item.flag21 = value
        if (value == '') {
            state.item.flag21_delete = 1
        } else {
            state.item.flag21_delete = 0
        }
    },
    setFlag22(state, value) {
        state.item.flag22 = value
        if (value == '') {
            state.item.flag22_delete = 1
        } else {
            state.item.flag22_delete = 0
        }
    },
    setFlag23(state, value) {
        state.item.flag23 = value
        if (value == '') {
            state.item.flag23_delete = 1
        } else {
            state.item.flag23_delete = 0
        }
    },
    setFlag24(state, value) {
        state.item.flag24 = value
        if (value == '') {
            state.item.flag24_delete = 1
        } else {
            state.item.flag24_delete = 0
        }
    },
    setFlag25(state, value) {
        state.item.flag25 = value
        if (value == '') {
            state.item.flag25_delete = 1
        } else {
            state.item.flag25_delete = 0
        }
    },
    setFlag26(state, value) {
        state.item.flag26 = value
        if (value == '') {
            state.item.flag26_delete = 1
        } else {
            state.item.flag26_delete = 0
        }
    },
    setFlag27(state, value) {
        state.item.flag27 = value
        if (value == '') {
            state.item.flag27_delete = 1
        } else {
            state.item.flag27_delete = 0
        }
    },
    setFlag28(state, value) {
        state.item.flag28 = value
        if (value == '') {
            state.item.flag28_delete = 1
        } else {
            state.item.flag28_delete = 0
        }
    },
    setFlag29(state, value) {
        state.item.flag29 = value
        if (value == '') {
            state.item.flag29_delete = 1
        } else {
            state.item.flag29_delete = 0
        }
    },
    setFlag30(state, value) {
        state.item.flag30 = value
        if (value == '') {
            state.item.flag30_delete = 1
        } else {
            state.item.flag30_delete = 0
        }
    },
    setFlag31(state, value) {
        state.item.flag31 = value
        if (value == '') {
            state.item.flag31_delete = 1
        } else {
            state.item.flag31_delete = 0
        }
    },
    setFlag32(state, value) {
        state.item.flag32 = value
        if (value == '') {
            state.item.flag32_delete = 1
        } else {
            state.item.flag32_delete = 0
        }
    },
    setFlag33(state, value) {
        state.item.flag33 = value
        if (value == '') {
            state.item.flag33_delete = 1
        } else {
            state.item.flag33_delete = 0
        }
    },
    setFlag34(state, value) {
        state.item.flag34 = value
        if (value == '') {
            state.item.flag34_delete = 1
        } else {
            state.item.flag34_delete = 0
        }
    },
    setFlag35(state, value) {
        state.item.flag35 = value
        if (value == '') {
            state.item.flag35_delete = 1
        } else {
            state.item.flag35_delete = 0
        }
    },
    setFlag36(state, value) {
        state.item.flag36 = value
        if (value == '') {
            state.item.flag36_delete = 1
        } else {
            state.item.flag36_delete = 0
        }
    },
    setFlag37(state, value) {
        state.item.flag37 = value
        if (value == '') {
            state.item.flag37_delete = 1
        } else {
            state.item.flag37_delete = 0
        }
    },
    setFlag38(state, value) {
        state.item.flag38 = value
        if (value == '') {
            state.item.flag38_delete = 1
        } else {
            state.item.flag38_delete = 0
        }
    },
    setFlag39(state, value) {
        state.item.flag39 = value
        if (value == '') {
            state.item.flag39_delete = 1
        } else {
            state.item.flag39_delete = 0
        }
    },
    setFlag40(state, value) {
        state.item.flag40 = value
        if (value == '') {
            state.item.flag40_delete = 1
        } else {
            state.item.flag40_delete = 0
        }
    },
    setFlag41(state, value) {
        state.item.flag41 = value
        if (value == '') {
            state.item.flag41_delete = 1
        } else {
            state.item.flag41_delete = 0
        }
    },
    setFlag42(state, value) {
        state.item.flag42 = value
        if (value == '') {
            state.item.flag42_delete = 1
        } else {
            state.item.flag42_delete = 0
        }
    },
    setFlag43(state, value) {
        state.item.flag43 = value
        if (value == '') {
            state.item.flag43_delete = 1
        } else {
            state.item.flag43_delete = 0
        }
    },
    setFlag44(state, value) {
        state.item.flag44 = value
        if (value == '') {
            state.item.flag44_delete = 1
        } else {
            state.item.flag44_delete = 0
        }
    },
    setFlag45(state, value) {
        state.item.flag45 = value
        if (value == '') {
            state.item.flag45_delete = 1
        } else {
            state.item.flag45_delete = 0
        }
    },
    setFlag46(state, value) {
        state.item.flag46 = value
        if (value == '') {
            state.item.flag46_delete = 1
        } else {
            state.item.flag46_delete = 0
        }
    },
    setFlag47(state, value) {
        state.item.flag47 = value
        if (value == '') {
            state.item.flag47_delete = 1
        } else {
            state.item.flag47_delete = 0
        }
    },
    setFlag48(state, value) {
        state.item.flag48 = value
        if (value == '') {
            state.item.flag48_delete = 1
        } else {
            state.item.flag48_delete = 0
        }
    },
    setFlag49(state, value) {
        state.item.flag49 = value
        if (value == '') {
            state.item.flag49_delete = 1
        } else {
            state.item.flag49_delete = 0
        }
    },
    setFlag50(state, value) {
        state.item.flag50 = value
        if (value == '') {
            state.item.flag50_delete = 1
        } else {
            state.item.flag50_delete = 0
        }
    },
    setBannerLong1(state, value) {
        state.item.banner_long1 = value
        if (value == '') {
            state.item.banner_long1_delete = 1
        } else {
            state.item.banner_long1_delete = 0
        }
    },
    setBannerLong2(state, value) {
        state.item.banner_long2 = value
        if (value == '') {
            state.item.banner_long2_delete = 1
        } else {
            state.item.banner_long2_delete = 0
        }
    },
    setBannerLong3(state, value) {
        state.item.banner_long3 = value
        if (value == '') {
            state.item.banner_long3_delete = 1
        } else {
            state.item.banner_long3_delete = 0
        }
    },
    setBannerLong4(state, value) {
        state.item.banner_long4 = value
        if (value == '') {
            state.item.banner_long4_delete = 1
        } else {
            state.item.banner_long4_delete = 0
        }
    },
    setBannerLong5(state, value) {
        state.item.banner_long5 = value
        if (value == '') {
            state.item.banner_long5_delete = 1
        } else {
            state.item.banner_long5_delete = 0
        }
    },
    setBannerLong6(state, value) {
        state.item.banner_long6 = value
        if (value == '') {
            state.item.banner_long6_delete = 1
        } else {
            state.item.banner_long6_delete = 0
        }
    },
    setBannerLong7(state, value) {
        state.item.banner_long7 = value
        if (value == '') {
            state.item.banner_long7_delete = 1
        } else {
            state.item.banner_long7_delete = 0
        }
    },
    setBannerLong8(state, value) {
        state.item.banner_long8 = value
        if (value == '') {
            state.item.banner_long8_delete = 1
        } else {
            state.item.banner_long8_delete = 0
        }
    },
    setBannerLong9(state, value) {
        state.item.banner_long9 = value
        if (value == '') {
            state.item.banner_long9_delete = 1
        } else {
            state.item.banner_long9_delete = 0
        }
    },
    setBannerLong10(state, value) {
        state.item.banner_long10 = value
        if (value == '') {
            state.item.banner_long10_delete = 1
        } else {
            state.item.banner_long10_delete = 0
        }
    },
    setBannerLong11(state, value) {
        state.item.banner_long11 = value
        if (value == '') {
            state.item.banner_long11_delete = 1
        } else {
            state.item.banner_long11_delete = 0
        }
    },
    setBannerLong12(state, value) {
        state.item.banner_long12 = value
        if (value == '') {
            state.item.banner_long12_delete = 1
        } else {
            state.item.banner_long12_delete = 0
        }
    },
    setBannerLong13(state, value) {
        state.item.banner_long13 = value
        if (value == '') {
            state.item.banner_long13_delete = 1
        } else {
            state.item.banner_long13_delete = 0
        }
    },
    setBannerLong14(state, value) {
        state.item.banner_long14 = value
        if (value == '') {
            state.item.banner_long14_delete = 1
        } else {
            state.item.banner_long14_delete = 0
        }
    },
    setBannerMedium1(state, value) {
        state.item.banner_medium1 = value
        if (value == '') {
            state.item.banner_medium1_delete = 1
        } else {
            state.item.banner_medium1_delete = 0
        }
    },
    setBannerMedium2(state, value) {
        state.item.banner_medium2 = value
        if (value == '') {
            state.item.banner_medium2_delete = 1
        } else {
            state.item.banner_medium2_delete = 0
        }
    },
    setBannerMedium3(state, value) {
        state.item.banner_medium3 = value
        if (value == '') {
            state.item.banner_medium3_delete = 1
        } else {
            state.item.banner_medium3_delete = 0
        }
    },
    setBannerMedium4(state, value) {
        state.item.banner_medium4 = value
        if (value == '') {
            state.item.banner_medium4_delete = 1
        } else {
            state.item.banner_medium4_delete = 0
        }
    },
    setBannerMedium5(state, value) {
        state.item.banner_medium5 = value
        if (value == '') {
            state.item.banner_medium5_delete = 1
        } else {
            state.item.banner_medium5_delete = 0
        }
    },
    setBannerMedium6(state, value) {
        state.item.banner_medium6 = value
        if (value == '') {
            state.item.banner_medium6_delete = 1
        } else {
            state.item.banner_medium6_delete = 0
        }
    },
    setBannerMedium7(state, value) {
        state.item.banner_medium7 = value
        if (value == '') {
            state.item.banner_medium7_delete = 1
        } else {
            state.item.banner_medium7_delete = 0
        }
    },
    setBannerMedium8(state, value) {
        state.item.banner_medium8 = value
        if (value == '') {
            state.item.banner_medium8_delete = 1
        } else {
            state.item.banner_medium8_delete = 0
        }
    },
    setBannerSmall1(state, value) {
        state.item.banner_small1 = value
        if (value == '') {
            state.item.banner_small1_delete = 1
        } else {
            state.item.banner_small1_delete = 0
        }
    },
    setBannerSmall2(state, value) {
        state.item.banner_small2 = value
        if (value == '') {
            state.item.banner_small2_delete = 1
        } else {
            state.item.banner_small2_delete = 0
        }
    },
    setBannerSmall3(state, value) {
        state.item.banner_small3 = value
        if (value == '') {
            state.item.banner_small3_delete = 1
        } else {
            state.item.banner_small3_delete = 0
        }
    },
    setBannerSmall4(state, value) {
        state.item.banner_small4 = value
        if (value == '') {
            state.item.banner_small4_delete = 1
        } else {
            state.item.banner_small4_delete = 0
        }
    },
    setBannerSmall5(state, value) {
        state.item.banner_small5 = value
        if (value == '') {
            state.item.banner_small5_delete = 1
        } else {
            state.item.banner_small5_delete = 0
        }
    },
    setBannerSmall6(state, value) {
        state.item.banner_small6 = value
        if (value == '') {
            state.item.banner_small6_delete = 1
        } else {
            state.item.banner_small6_delete = 0
        }
    },
    setBannerSmall7(state, value) {
        state.item.banner_small7 = value
        if (value == '') {
            state.item.banner_small7_delete = 1
        } else {
            state.item.banner_small7_delete = 0
        }
    },
    setBannerSmall8(state, value) {
        state.item.banner_small8 = value
        if (value == '') {
            state.item.banner_small8_delete = 1
        } else {
            state.item.banner_small8_delete = 0
        }
    },
    setBannerSmall9(state, value) {
        state.item.banner_small9 = value
        if (value == '') {
            state.item.banner_small9_delete = 1
        } else {
            state.item.banner_small9_delete = 0
        }
    },
    setBannerSmall10(state, value) {
        state.item.banner_small10 = value
        if (value == '') {
            state.item.banner_small10_delete = 1
        } else {
            state.item.banner_small10_delete = 0
        }
    },
    setBannerSmall11(state, value) {
        state.item.banner_small11 = value
        if (value == '') {
            state.item.banner_small11_delete = 1
        } else {
            state.item.banner_small11_delete = 0
        }
    },
    setBannerSmall12(state, value) {
        state.item.banner_small12 = value
        if (value == '') {
            state.item.banner_small12_delete = 1
        } else {
            state.item.banner_small12_delete = 0
        }
    },
    setBannerSmall13(state, value) {
        state.item.banner_small13 = value
        if (value == '') {
            state.item.banner_small13_delete = 1
        } else {
            state.item.banner_small13_delete = 0
        }
    },
    setAuditorium_pre_recorded(state, value) {
        state.item.auditorium_pre_recorded = value
        if (value == '') {
            state.item.pre_delete = 1
        } else {
            state.item.pre_delete = 0
        }
    },
    setAuditorium_panel_discussion(state, value) {
        state.item.auditorium_panel_discussion = value
        if (value == '') {
            state.item.panel_delete = 1
        } else {
            state.item.panel_delete = 0
        }
    },
    setExhibition_area(state, value) {
        state.item.exhibition_area = value
        if (value == '') {
            state.item.event_area_delete = 1
        } else {
            state.item.event_area_delete = 0
        }
    },
    setVirtual_training(state, value) {
        state.item.virtual_training = value
        if (value == '') {
            state.item.training_delete = 1
        } else {
            state.item.training_delete = 0
        }
    },
    setRoundtable(state, value) {
        state.item.roundtable = value
        if (value == '') {
            state.item.roundtable_delete = 1
        } else {
            state.item.roundtable_delete = 0
        }
    },
    setVirtual_meeting(state, value) {
        state.item.virtual_meeting = value
        if (value == '') {
            state.item.meeting_delete = 1
        } else {
            state.item.meeting_delete = 0
        }
    },
    setScrollingText(state, value) {
        state.item.scrolling_text = value

    },
    setAuditorium_live_label(state, value) {
        state.item.auditorium_live_label = value

    },
    setAuditorium_pre_recorded_label(state, value) {
        state.item.auditorium_pre_recorded_label = value

    },
    setAuditorium_panel_discussion_label(state, value) {
        state.item.auditorium_panel_discussion_label = value

    },
    setExhibition_area_label(state, value) {
        state.item.exhibition_area_label = value

    },
    setVirtual_training_label(state, value) {
        state.item.virtual_training_label = value

    },
    setRoundtable_label(state, value) {
        state.item.roundtable_label = value

    },
    setVirtual_meeting_label(state, value) {
        state.item.virtual_meeting_label = value

    },
    setExhibition_video(state, value) {
        state.item.exhibition_video = value
        if (value == '') {
            state.item.video_delete = 1
        } else {
            state.item.video_delete = 0
        }
    },
    setScreen_video1(state, value) {
        state.item.screen_video1 = value
        if (value == '') {
            state.item.screen_video1_delete = 1
        } else {
            state.item.screen_video1_delete = 0
        }
    },
    setScreen_video2(state, value) {
        state.item.screen_video2 = value
        if (value == '') {
            state.item.screen_video2_delete = 1
        } else {
            state.item.screen_video2_delete = 0
        }
    },
    setScreen_video3(state, value) {
        state.item.screen_video3 = value
        if (value == '') {
            state.item.screen_video3_delete = 1
        } else {
            state.item.screen_video3_delete = 0
        }
    },
    setScreen_video4(state, value) {
        state.item.screen_video4 = value
        if (value == '') {
            state.item.screen_video4_delete = 1
        } else {
            state.item.screen_video4_delete = 0
        }
    },
    setScreen_video5(state, value) {
        state.item.screen_video5 = value
        if (value == '') {
            state.item.screen_video5_delete = 1
        } else {
            state.item.screen_video5_delete = 0
        }
    },
    setScreen_video6(state, value) {
        state.item.screen_video6 = value
        if (value == '') {
            state.item.screen_video6_delete = 1
        } else {
            state.item.screen_video6_delete = 0
        }
    },
    setScreen_video7(state, value) {
        state.item.screen_video7 = value
        if (value == '') {
            state.item.screen_video7_delete = 1
        } else {
            state.item.screen_video7_delete = 0
        }
    },
    setExhibition_document(state, value) {
        state.item.exhibition_document = value
        if (value == '') {
            state.item.doc_delete = 1
        } else {
            state.item.doc_delete = 0
        }
    },
    setScreen_document1(state, value) {
        state.item.screen_document1 = value
        if (value == '') {
            state.item.screen_document1_delete = 1
        } else {
            state.item.screen_document1_delete = 0
        }
    },
    setScreen_document2(state, value) {
        state.item.screen_document2 = value
        if (value == '') {
            state.item.screen_document2_delete = 1
        } else {
            state.item.screen_document2_delete = 0
        }
    },
    setScreen_document3(state, value) {
        state.item.screen_document3 = value
        if (value == '') {
            state.item.screen_document3_delete = 1
        } else {
            state.item.screen_document3_delete = 0
        }
    },
    setScreen_document4(state, value) {
        state.item.screen_document4 = value
        if (value == '') {
            state.item.screen_document4_delete = 1
        } else {
            state.item.screen_document4_delete = 0
        }
    },
    setScreen_document5(state, value) {
        state.item.screen_document5 = value
        if (value == '') {
            state.item.screen_document5_delete = 1
        } else {
            state.item.screen_document5_delete = 0
        }
    },
    setScreen_document6(state, value) {
        state.item.screen_document6 = value
        if (value == '') {
            state.item.screen_document6_delete = 1
        } else {
            state.item.screen_document6_delete = 0
        }
    },
    setScreen_document7(state, value) {
        state.item.screen_document7 = value
        if (value == '') {
            state.item.screen_document7_delete = 1
        } else {
            state.item.screen_document7_delete = 0
        }
    },
    setLogo_standee(state, value) {
        state.item.logo_standee = value
        if (value == '') {
            state.item.standee_delete = 1
        } else {
            state.item.standee_delete = 0
        }
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
        if (value == '') {
            state.item.background_picture_delete = 1
        } else {
            state.item.background_picture_delete = 0
        }
    },
    setBackgroundColor(state, value) {
        state.item.background_color_top = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setMaxCountBooth(state, value) {
        state.item.max_count_booth = value
    },
    setIs_picture(state, value) {
        state.item.is_picture = value
        console.log(value)

    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
