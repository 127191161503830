<template>
  <header>
    <div class="logo">
      <a href="#">
        <img src="https://s3.eu-central-1.amazonaws.com/beta-assets.avianet.aero/8315/avianet_logo_white_web_res.png" alt="logo">
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "SimpleHeader"
}
</script>

<style scoped lang="scss">
header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 42px;
  .logo {
    img {
      max-width: 181px;
      margin: 5px 0 0 43px;
    }
  }
}
</style>