<template>
    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language
                    <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                    <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#page-content`" :href="`#page-content`" data-bs-toggle="tab">Page Contents
                    
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div :id="`language-settings`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Language *</label>
                            <v-select required :rules="requiredRule" name="language" class="" placeholder="Select language"
                            label="language" @option:selected="updateLaguage" :options="languages"
                            :modelValue="selectedLanguageKey" />
                            <ValidationMessages :element="v$.item.translations"/>
                              
    
                        </div>
                        <div class="col-lg-6">
                            <label>Title *</label>
                            
                            <div class="kt-input-icon">
                                <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="this.selectedLanguageTitle" @input="updateLanguageTitle" />
                            </div>
                            <ValidationMessages :element="v$.item.translations"/>
     
                        </div>
                        <div class="card-body text-right mt-3 mr-5">
                            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
                         </div>
    

                    </div>
                </div>
            </div>
            <div :id="`general-settings`" class="tab-pane">
                <div class="snippet general-event-config-snippet">
         
                    <div class="form-group row">
    
                        <div class="col-lg-6">
                            <label>Type *</label>
                            <v-select name="type"  placeholder="Select Type" label="label" @option:selected="updateType" :options="typesEnum"  :modelValue="item.type"/>
                          
                      
                            <ValidationMessages :element="v$.item.translations"/>
                              
                        </div>
                       
    
                    </div>
    
                    <div class="form-group row">
    
                        <div class="col-lg-6">
                            <label>Section Bar Color </label>
                            <div class="input-group color" data-plugin-colorpicker>
                                <input type="color" class="form-control" name="background_color" :value="item.section_color" @input="updateSectionColor" />
                            </div>
                                    
                        </div>
                    </div>
                    <div class="card-body text-right mt-3 mr-5">
                            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
                         </div>
    
    
                </div>
    
            </div>
          
        
        <div :id="`page-content`" class="tab-pane">
          <PageContentSection :item="item" :docId="item._id" :languages="languages" />
        </div>
        </div>
    
    </div>
    <FlashMessage group="doc_section_edit"/>
    
    </template>
    
    <script>
    import { gettingErrorMessage } from "@/services/GetValidationMessage";
    import PageContentSection  from "@/components/cruds/DocSections/PageContent/Index"

    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import _ from "lodash";
    import moment from 'moment';
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    import ObjectID from 'bson-objectid';

    export default {
        data() {
            return {
                moduleId: ObjectID(),
                dialog: false,
                translation: [],
                languageKey: '',
                languageTitle: '',
                languageDescription: '',
                error_translation_tab: false,
                error_general_tab: false,
          
                
            }
            
        },
       
      setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {
                item: {
                   
                    translations: {
                        required
                    },
                    type:{
                        required
                    }
                    
                   
                }
            };
        },
    
        props: {
            event: {
                type: Object,
                default () {
                    return {
                        name: null,
                        email: null,
                        company: null,
                        role: null,
                        langauge:null
                    }
                }
            },
            languages: {
                type: Array,
            }
    
        },
        watch: {
        
        },
        name: 'DocSectionEditForm',
        components: {
            
            ValidationMessages,
            PageContentSection
        },
       
        computed: {
            ...mapGetters('DocsSectionsSingle', ['error_translation_tab','error_general_tab','typesEnum','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading',]),
            ...mapGetters('DocsSectionsIndex', []),
        },
        beforeUnmount() {
            this.resetState()
            delete this.$flashMessage.groups.doc_section_edit;
        },
        created(){
        },
        mounted() {
        },
        methods: {
            ...mapActions('DocsSectionsIndex', {
                fetchAllData: 'fetchData',
            }),
            ...mapActions('DocsSectionsSingle', [
                'updateData',
                'resetState',
                'storeData',
                'setSectionColor',
                'setType',
                'setErrorTranslationTab',
                'setErrorGeneralTab',
                'setTranslations',
                'setLangTitle',
                'setLangKey',
                'setIsNew'
            ]),
            updateSectionColor(e) {
                this.setSectionColor(e.target.value);
            },
            updateType(value) {  
                this.setType(value.value)
            },
            updateLaguage(value) {

                this.err_translations = null;
                this.setLangKey(value);
                this.languageKey = value;
                this.setLangTitle(null);

                if (_.isUndefined(_.find(this.item.translations, {
                'key': value,
                }))) {
                let obj = {
                    "key": `${value}`,
                    "title": null,
                }
                this.item.translations.push(obj);
                } else {

                let selectedLang = _.find(this.item.translations, {
                    'key': this.selectedLanguageKey,
                })
                if (!_.isUndefined(selectedLang)) {
                    this.setLangTitle(selectedLang.title);
                }
                }

            },
            updateLaguageX(value) {
                this.err_translations = null;
                this.setLangKey(value);
                this.languageKey = value;
                this.setLangTitle(null);
                if (_.isUndefined(_.find(this.translation, {
                    'key': value,
                }))) {
                    let obj = {
                        "key": `${value}`,
                        "title": null,
                    }
                    this.translation.push(obj);
                } else {
                    let selectedLang = _.find(this.item.translations, {
                        'key': this.selectedLanguageKey,
                    })
                    if (!_.isUndefined(selectedLang)) {
                        this.setLangTitle(selectedLang.title);
                    }
                }

        },
            updateLanguageTitle(e) {
                this.err_translations = null;
                if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
                    this.setTranslations({
                    key: this.selectedLanguageKey,
                    update_key: 'title',
                    update_value: e.target.value
                    });

                }
                this.setLangTitle(e.target.value);
            },
            async checkLanguageValidation() {
                let errorsArr = [];
                let langArr = [];
                langArr.push(this.languages[0]);
          
                _.map(langArr, (item) => {
                    let langInfo = _.find(this.item.translations, { 'key': item });
                    if (langInfo) {
                        if(langInfo.title == null){
                            errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                        }
                        
                        if (langInfo.title!==null && langInfo.title.length < 3) {
                            errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
                        }
                        

                    } else {
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }

                });
                return errorsArr;
            },
            cancelClick() {
                this.resetState()
            },
            async  submitForm() {
                const isFormCorrect = await this.v$.$validate();
                let validateTranlation = await this.checkLanguageValidation();

                this.error_general_tab = false;
                this.error_translation_tab = false;
                this.error_theme_tab = false;
                this.error_interpretation_tab = false;
                this.setErrorGeneralTab(false)
                this.setErrorTranslationTab(false);

                let generalError = []; 
                    _.map(this.v$.$errors, (item) => {
                        if(item.$property == "type"){
                            generalError.push("<li>Type is required</li>");
                        }
                    });
                    if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                        this.error_general_tab      = true;
                        this.error_translation_tab  = true;
                        this.setErrorTranslationTab(true);
                        this.setErrorGeneralTab(true);
                        
                    }

                    if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
                
                        if (!_.isEmpty(validateTranlation)) {
                            this.error_translation_tab = true;
                            this.setErrorTranslationTab(true);
                            this.$flashMessage.show({
                                group: 'doc_section_edit',
                                type: 'error',
                                time:1000,
                                title: 'Failed',
                                html: `<ul>${validateTranlation.join("")}</ul>`
                            });
                            return ;
                    }          
                    } 
                    if (!isFormCorrect ) {
                        
                        if (!_.isEmpty(generalError)) {
                        this.error_general_tab = true;
                            this.setErrorGeneralTab(true);
                            this.$flashMessage.show({
                                group   : 'doc_section_edit',
                                type    : 'error',
                                time    : 1000,
                                title   : 'Validation Failed',
                                html    : `<ul>${generalError.join("")}</ul>`
                            });
                            return ;
                        }
                    }
            

                   // if (!isFormCorrect) return
                    this.updateData()
                        .then(async (resp) => {
                            await this.fetchAllData();

                            this.$flashMessage
                                .show({
                                        group   : 'doc_section_index',
                                        type    : 'success',
                                        title   : 'Success',
                                        time    : 1000,
                                        html    : '<ul><li>Manual Section has been updated successfully.</li></ul>',
                                    });
                           
                        }) 
                        .catch(error => {
                            console.log("Error",error);
                            let html =  gettingErrorMessage(error);
                            if (error.response.data.data.error) {
                                this.$flashMessage.show({
                                    group: 'doc_section_edit',
                                    type: 'error',
                                    title: 'Failed',
                                    time:1000,
                                    text: html || 'Something went wrong',
                                    html: html
                                });
                            }
                        });
            },
            
           
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .event_detail_wrapper {
        border-bottom: 0px solid #ccc;
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    }
    
    .setting_wrapper {
        float: right;
    
        a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
        }
    }
    
    .toggle label {
        color: #000 !important;
    }
    
    .drop-files {
        margin-top: 10px;
    }
    
    .newspeaker {
        margin-top: 20px;
    }
    
    .newspeaker button {
        width: 100%;
    }
    
    .add_spk_btn {
        background-color: #0088CC;
        color: #fff;
    }
    
    .mini_txt {
        font-size: 0.8em;
        color: #585252;
        font-style: italic;
        margin-bottom: 10px;
    }
    
    .v-dialog>.v-overlay__content>.v-card {
        border-radius: 15px;
        padding-top: 20px;
    }
    
    .tabs-left .nav-tabs>li .active {
        border-left-color: #0088CC;
    }
    
    .orangebtn {
        background-color: orange;
    }
    
    .wrapp {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    .flash-content {
        z-index: 10;
    }
    
    .dark{
      --popper-theme-text-color: #ffffff;
      --popper-theme-padding: 15px;
      --popper-theme-background-color: #333333;
      --popper-theme-border-radius: 6px;
      --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .tooltip_img img{    
        position: absolute;
        top: -53px;
        left: 50px;
        width: 300px;
        height: auto;
    }
    
    
    </style>
    