import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        all: [],
        loading: false,
        edit_id: null
    }
}

const getters = {
    all:         state => state.all,
    total:         state => state.all.length,
    edit_id:         state => state.edit_id,
    loading:       state => state.loading,
}

const actions = {
    fetchData({ commit, state }) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/company', {headers: headers})
            .then(response => {
                commit('setAll', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/company/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                console.log(message)
            })
    },

    resetState({ commit }) {
        commit('resetState')
    },
    setEditId({ commit }, id) {
        commit('setEditId', id)
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setEditId(state, id) {
        state.edit_id = id
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
