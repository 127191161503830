<template>
  <div class="snippet start-end-date-snippet">
    <div class="form-group row">
      <div class="col-lg-6">
        <label>Event Starts At *</label>
        <div class="input-daterange input-group">
          <span class="input-group-text">
            <i class="fas fa-calendar-alt"></i>
          </span>

          
          <input type="datetime-local"
                 class="form-control"
                 name="start_date"
                 :value="item.start_date"
                 @change="updateStartDate" />
            <ValidationMessages :element="v$.item.start_date"/>
  
          <span class="input-group-text rounded-0">from</span>
        </div>
      </div>
      <div class="col-lg-6">
        <label class>Event Ends At *</label>
        <div class="input-daterange input-group">
          <span class="input-group-text">
            <i class="fas fa-calendar-alt"></i>
          </span>
          <input type="datetime-local"
                 class="form-control"
                 name="end_date"
                 :value="item.end_date"
                 @change="updateEndDate" />
            <ValidationMessages :element="v$.item.end_date"/>
  
          <span class="input-group-text rounded-0">to</span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <div class="custom-control custom-switch">
          <input-switch name="is_restricted"
                        :value="item.is_restricted"
                        id="customSwitchesR"
                        class="custom-control-input"
                        @change="updateIs_restricted" />
          <label class="custom-control-label" for="customSwitchesR">Restrict Login Before Event Time</label>
        </div>
      </div>
      <div class="col-lg-4" v-if="item.is_restricted == 'true' || item.is_restricted == true">
        <label>Pre-Hours</label>
        <div class="kt-input-icon">
          <input
              type="number"
              class="form-control"
              name="login_hours"
              min="0"
              placeholder="Enter hours "
              :value="item.login_hours"
              @input="updateLoginHours"/>
        </div>
      </div>
      <div class="col-lg-4" v-if="item.is_restricted == 'true' || item.is_restricted == true">
        <label>Message Upon Login</label>
        <div class="kt-input-icon">
          <textarea
              rows="3"
              maxlength="255"
              class="form-control"
              name="login_message"
              placeholder="Enter Message Upon Login"
              :value="item.login_message"
              @input="updateLoginMessage"/>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Event Country *</label>
        <v-select
            name="country"
            label="name"
            @option:selected="updateCountry"
            :modelValue="item.country"
            :options="countriesAll"
        />
        <ValidationMessages :element="v$.item.country"/>
  
      </div>
      <div class="col-lg-3">
        <label class>Event City *</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="city"
              placeholder="Enter City"
              :value="item.city"
              @input="updateCity"
          />
          <ValidationMessages :element="v$.item.city"/>
  
        </div>
      </div>
      <div class="col-lg-6">
        <label>Event Timezone *</label>
        <v-select
            label="name"
            class=""
            @option:selected="updateTimezone"
            :modelValue="item.time_zone"
            :reduce="(option) => option._id"
            :options="timezonesAll"
        />
        <ValidationMessages :element="v$.item.time_zone"/>
  
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6">
        <label class>Event Description</label>
        <div class="kt-input-icon">
         
          <ckeditor
              :editor="editor"
              :model-value="item.description"
              @input="updateDescription"
              :config="editorConfig">
            </ckeditor>
              
        </div>
      </div>
      <div class="col-lg-6">
        <label>Event Address</label>
        <div class="kt-input-icon">
          <textarea
              rows="3"
              class="form-control"
              name="address"
              placeholder="Enter event address"
              :value="item.address"
              @input="updateAddress"
          />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6">
        <label class>Email *</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="email"
              placeholder="Enter Email"
              :value="item.email"
              @input="updateEmail"
          />
          <ValidationMessages :element="v$.item.email"/>
  
        </div>
      </div>
      <div class="col-lg-6">
        <label class>Phone</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="phone"
              placeholder="Enter Phone"
              :value="item.Phone"
              @input="updatePhone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    
import $ from "jquery";
import moment from "moment";
import InputSwitch from "@/components/Commons/InputSwitch";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'


export default {
  name: "StartEndDateSnippet",
  components: {
    InputSwitch,
    ValidationMessages,
    ckeditor: CKEditor.component,
  },
  
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
                    fontColor: {
                        colors: [
                            {
                                color: 'hsl(0, 0%, 0%)',
                                label: 'Black'
                            },
                            {
                                color: 'hsl(0, 0%, 30%)',
                                label: 'Dim grey'
                            },
                            {
                                color: 'hsl(0, 0%, 60%)',
                                label: 'Grey'
                            },
                            {
                                color: 'hsl(0, 0%, 90%)',
                                label: 'Light grey'
                            },
                            {
                                color: 'hsl(0, 0%, 100%)',
                                label: 'White',
                                hasBorder: true
                            },
                            // More colors.
                            // ...
                        ]
                    },
                    
                    
                    toolbar: ['heading','fontColor', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' | 'fontColor', 'fontBackgroundColor'],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                 //   plugins: [  Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage ],
                   // extraPlugins: [this.uploader],
                  
                    language: 'en'
                },
      
     // editorConfig: {
       // height: 300,
        //extraPlugins: 'base64image',
      //},
    }
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },
  validations() {
        return {
            item: {
                start_date: {
                    required,
                },
                end_date: {
                    required,
                },
                country: {
                    required,
                },
                city: {
                    required,
                },
                time_zone: {
                    required,
                },
                email: {
                    required,email
                },
                
            }
        };
    },

  computed: {
    ...mapGetters("EventsSingle", [
      "item",
      "countriesAll",
      "timezonesAll",
      "event_type",
    ])
  },
  methods: {
    ...mapActions("EventsSingle", [
      "setAddress",
      "setEmail",
      "setPhone",
      "setDescription",
      "setCountry",
      "setTimezone",
      "setCity",
      "setIs_restricted",
      "setLoginHours",
      "setLoginMessage",
      "setStartDate",
      "setEndDate"
    ]),
    updateStartDate(e) {
      console.log(e.target.value)
      this.setStartDate(e.target.value);
    },
    updateEndDate(e) {
      this.setEndDate(e.target.value);
    },
    updateCountry(value) {
      this.setCountry(value);
    },
    updateTimezone(value) {
      this.setTimezone(value);
    },
    updateCity(e) {
      this.setCity(e.target.value);
    },
    updateIs_restricted(e) {
      this.setIs_restricted(e.target.checked);
    },
    updateLoginHours(e) {
      this.setLoginHours(e.target.value);
    },
    updateLoginMessage(e) {
      this.setLoginMessage(e.target.value);
    },
    updateAddress(e) {
      this.setAddress(e.target.value);
    },
    updateEmail(e) {
      this.setEmail(e.target.value);
    },
    updatePhone(e) {
      this.setPhone(e.target.value);
    },
    updateDescription(value) {
      this.setDescription(value);
    },
    getDate(date) {
      console.log("DAte call",date);
      return moment(date).format('YYYY-MM-DD')
      
    }
  },
}
</script>

<style scoped>

</style>