<template>
    <div class="row">

        <div class="col-md-2">
      <div class="user_img">
        <img src="/assets/img/event_img.jpg" class="asset_img" alt="logo">
      </div>
    </div>
        <div class="col-md-5 event_detail_ls">

           <!--event_img.jpg--> 
           
                  
            <div class="event_detail_wrapper">

                <div class="row">

                    <div class="event_ls_label col-md-2">Page Name</div>
                    <div class="col-md-8">{{ presentation.page_name }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Language</div>
                    <div class="col-md-8">{{ presentation.language }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Status</div>
                    <div class="col-md-8">{{ presentation.status }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Visible</div>
                    <div class="col-md-8">{{ presentation.is_visible?"Yes":"No" }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Sorting Order </div>
                    <div class="col-md-8">{{ presentation.sorting_order }}</div>
                </div>
            </div>
        </div>   
        <div class="text-right mt-3 mr-5">
            <button v-if="can('event_page_edit',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"
                @click="editData(presentation)">Edit</button>
            <button v-if="can('event_page_delete',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger"
                @click="deleteData(presentation)">Delete</button>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import { isPermits} from "@/services/CheckPermissions";

export default {
    name: "ShowItem",
    data() {
        return {
           
        }
    },
    props: {
        event:null,
        presentation: {
            type: Object,
            default() {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                }
            }
        },
        id: {
            type: String
        },
        checkPermssions:null,

    },

    methods: {
        
        ...mapActions('EventPagesIndex', ['fetchData', 'destroyData']),
        ...mapActions('EventPagesSingle', ['setItem', 'setIsNew', 'setIsEditing']),
        deleteData(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then( result => {
                if (result.isConfirmed) {
                        this.destroyData(item._id).then((resp) => {
                        console.log("Resp",resp);
                          this.$flashMessage.show({
                              group : 'event_pages_index',
                              type  : 'success',
                              title : 'Success',
                              time  :1000,
                              html  : '<ul><li> Manual Section has been deleted successfully.</li></ul>'
                          });
                            this.setIsNew(false);
                            this.setIsEditing(false);
                            this.fetchData(this.$route.params.event_id);
                  
                      })
                  }
            });
        },
        editData(item) {
            this.setItem(item);
            this.setIsNew(false);
            this.setIsEditing(true);
            this.$forceUpdate();
        },
        can(permit,permissions){
            return isPermits(permit,permissions);
        }
       
    },

}
</script>

<style lang="scss" scoped>
.user_img {
    img {
        width: 100%;
    }
}

.event_detail_wrapper {
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }

    .row {
        .col-md-8 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }

        .col-md-3 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }
    }

    .card-body {
        box-shadow: 0px 0px #ffffff;
    }
}

.tabs-vertical {
    border-top: 0px;
}

.toggle-content {
    background: #fff;
}
</style>
