import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            event_id: null,
            exhibition_video: null,
            exhibition_document: null,
            schedule_description: null,
            left_standee_text_1: false,
            left_pics_no_1: 1,
            left_text_1: null,
            left1_picture_1: null,
            left1_picture_2: null,
            left1_picture_3: null,
            left_standee_text_2: false,
            left_text_2: null,
            left_pics_no_2: 1,
            left2_picture_1: null,
            left2_picture_2: null,
            left2_picture_3: null,
            left_standee_text_3: false,
            left_text_3: null,
            left_pics_no_3: 1,
            left3_picture_1: null,
            left3_picture_2: null,
            left3_picture_3: null,
            left_standee_text_4: false,
            left_text_4: null,
            left_pics_no_4: 1,
            left4_picture_1: null,
            left4_picture_2: null,
            left4_picture_3: null,
            left_standee_text_5: false,
            left_text_5: null,
            left_pics_no_5: 1,
            left5_picture_1: null,
            left5_picture_2: null,
            left5_picture_3: null,
            right_pics_no_1: 1,
            right1_picture_1: null,
            right1_picture_2: null,
            right_pics_no_2: 1,
            right2_picture_1: null,
            right2_picture_2: null,
            right_pics_no_3: 1,
            right3_picture_1: null,
            right3_picture_2: null,
            right_pics_no_4: 1,
            right4_picture_1: null,
            right4_picture_2: null,
            right_pics_no_5: 1,
            right5_picture_1: null,
            right5_picture_2: null,
            right_pics_no_6: 1,
            right6_picture_1: null,
            right6_picture_2: null,
        },
        // eventsAll: [],
        event_type: null,
        event_theme: {
            id: null,
            title: '',
            event_type: null
        },
        schedule_types_list: [
            { type: '1', label: 'Style Type 1' },
            { type: '2', label: 'Style Type 2' }
        ],
        leftpicsno: [
            { "id": 1, "title": "One" },
            { "id": 2, "title": "Two" },
            { "id": 3, "title": "Three" },
        ],
        rightpicsno: [
            { "id": 1, "title": "One" },
            { "id": 2, "title": "Two" },
        ],
        skyboxes: [
            { "id": 'Day Time', "title": "Day Time" },
            { "id": 'Night Time', "title": "Night Time" },
            { "id": 'Dusk', "title": "Dusk" },
        ],
        environments: [
            { "id": 'Mountains', "title": "Mountains" },
            { "id": 'City Landscape', "title": "City Landscape" },
            { "id": 'Forest', "title": "Forest" },
        ],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    //eventsAll: state => state.eventsAll,
    event_type: state => state.event_type,
    skyboxes: state => state.skyboxes,
    leftpicsno: state => state.leftpicsno,
    rightpicsno: state => state.rightpicsno,
    environments: state => state.environments,
    schedule_types_list: state => state.schedule_types_list,
}

const actions = {
    storeData({ commit, state }) {
        commit('setLoading', true)

        let params = _.cloneDeep(state.item);

        params = _.omitBy(params, _.isNil);

        return axios.post(constants.API_EVENT_HALL_URL + '/api/v1/event-halls', params)
            .catch(error => {
                console.log(error)
                return error;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state }) {
        commit('setLoading', true)

        let params = _.cloneDeep(state.item);
        // params = _.omitBy(params, _.isNil);

        delete params._id;
        delete params.event_id;
        delete params.event_id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;


        if (_.has(state.item.exhibition_video, 'key')) {
            let imgPath = _.replace(state.item.exhibition_video.key, '/draft', '');
            params.exhibition_video = {
                key: imgPath,
                old_key: (!_.isNull(state.item.exhibition_video.key) && !_.isNull(state.item.exhibition_video.key.match(/\/draft\//g))) ? state.item.exhibition_video.key : null,
                size: params.exhibition_video.size,
                extension: params.exhibition_video.extension,
                uploadedBy: params.exhibition_video.uploadedBy,
                uploadedAt: params.exhibition_video.uploadedAt,
            }
        }


        if (_.has(state.item.exhibition_document, 'key')) {
            let imgPath = _.replace(state.item.exhibition_document.key, '/draft', '');
            params.exhibition_document = {
                key: imgPath,
                old_key: (!_.isNull(state.item.exhibition_document.key) && !_.isNull(state.item.exhibition_document.key.match(/\/draft\//g))) ? state.item.exhibition_document.key : null,
                size: params.exhibition_document.size,
                extension: params.exhibition_document.extension,
                uploadedBy: params.exhibition_document.uploadedBy,
                uploadedAt: params.exhibition_document.uploadedAt,
            }
        }

        if (_.has(state.item.logo_standee, 'key')) {
            let imgPath = _.replace(state.item.logo_standee.key, '/draft', '');
            params.logo_standee = {
                key: imgPath,
                old_key: (!_.isNull(state.item.logo_standee.key) && !_.isNull(state.item.logo_standee.key.match(/\/draft\//g))) ? state.item.logo_standee.key : null,
                size: params.logo_standee.size,
                extension: params.logo_standee.extension,
                uploadedBy: params.logo_standee.uploadedBy,
                uploadedAt: params.logo_standee.uploadedAt,
            }
        }
 
        if (_.has(state.item.background_picture, 'key')) {
            let imgPath = _.replace(state.item.background_picture.key, '/draft', '');
            params.background_picture = {
                key: imgPath,
                old_key: (!_.isNull(state.item.background_picture.key) && !_.isNull(state.item.background_picture.key.match(/\/draft\//g))) ? state.item.background_picture.key : null,
                size: params.background_picture.size,
                extension: params.background_picture.extension,
                uploadedBy: params.background_picture.uploadedBy,
                uploadedAt: params.background_picture.uploadedAt,
            }
        }

        if (_.has(state.item.left1_picture_1, 'key')) {
            let imgPath = _.replace(state.item.left1_picture_1.key, '/draft', '');
            params.left1_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left1_picture_1.key) && !_.isNull(state.item.left1_picture_1.key.match(/\/draft\//g))) ? state.item.left1_picture_1.key : null,
                size: params.left1_picture_1.size,
                extension: params.left1_picture_1.extension,
                uploadedBy: params.left1_picture_1.uploadedBy,
                uploadedAt: params.left1_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.left1_picture_2, 'key')) {
            let imgPath = _.replace(state.item.left1_picture_2.key, '/draft', '');
            params.left1_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left1_picture_2.key) && !_.isNull(state.item.left1_picture_2.key.match(/\/draft\//g))) ? state.item.left1_picture_2.key : null,
                size: params.left1_picture_2.size,
                extension: params.left1_picture_2.extension,
                uploadedBy: params.left1_picture_2.uploadedBy,
                uploadedAt: params.left1_picture_2.uploadedAt,
            }
        }

        /*if (!_.has(params.left1_picture_3, 'key')) {
            delete params.left1_picture_3;
        }*/


        if (_.has(state.item.left1_picture_3, 'key')) {
            let imgPath = _.replace(state.item.left1_picture_3.key, '/draft', '');
            params.left1_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left1_picture_3.key) && !_.isNull(state.item.left1_picture_3.key.match(/\/draft\//g))) ? state.item.left1_picture_3.key : null,
                size: params.left1_picture_3.size,
                extension: params.left1_picture_3.extension,
                uploadedBy: params.left1_picture_3.uploadedBy,
                uploadedAt: params.left1_picture_3.uploadedAt,
            }
        }


        /*if (!_.has(params.left2_picture_1, 'key')) {
            delete params.left2_picture_1;
        }*/


        if (_.has(state.item.left2_picture_1, 'key')) {
            let imgPath = _.replace(state.item.left2_picture_1.key, '/draft', '');
            params.left2_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left2_picture_1.key) && !_.isNull(state.item.left2_picture_1.key.match(/\/draft\//g))) ? state.item.left2_picture_1.key : null,
                size: params.left2_picture_1.size,
                extension: params.left2_picture_1.extension,
                uploadedBy: params.left2_picture_1.uploadedBy,
                uploadedAt: params.left2_picture_1.uploadedAt,
            }
        }
        /*if (!_.has(params.left2_picture_2, 'key')) {
            delete params.left2_picture_2;
        }*/


        if (_.has(state.item.left2_picture_2, 'key')) {
            let imgPath = _.replace(state.item.left2_picture_2.key, '/draft', '');
            params.left2_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left2_picture_2.key) && !_.isNull(state.item.left2_picture_2.key.match(/\/draft\//g))) ? state.item.left2_picture_2.key : null,
                size: params.left2_picture_2.size,
                extension: params.left2_picture_2.extension,
                uploadedBy: params.left2_picture_2.uploadedBy,
                uploadedAt: params.left2_picture_2.uploadedAt,
            }
        }
        if (_.has(state.item.left2_picture_3, 'key')) {
            let imgPath = _.replace(state.item.left2_picture_3.key, '/draft', '');
            params.left2_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left2_picture_3.key) && !_.isNull(state.item.left2_picture_3.key.match(/\/draft\//g))) ? state.item.left2_picture_3.key : null,
                size: params.left2_picture_3.size,
                extension: params.left2_picture_3.extension,
                uploadedBy: params.left2_picture_3.uploadedBy,
                uploadedAt: params.left2_picture_3.uploadedAt,
            }
        }
        if (_.has(state.item.left3_picture_1, 'key')) {
            let imgPath = _.replace(state.item.left3_picture_1.key, '/draft', '');
            params.left3_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left3_picture_1.key) && !_.isNull(state.item.left3_picture_1.key.match(/\/draft\//g))) ? state.item.left3_picture_1.key : null,
                size: params.left3_picture_1.size,
                extension: params.left3_picture_1.extension,
                uploadedBy: params.left3_picture_1.uploadedBy,
                uploadedAt: params.left3_picture_1.uploadedAt,
            }
        }
        if (_.has(state.item.left3_picture_2, 'key')) {
            let imgPath = _.replace(state.item.left3_picture_2.key, '/draft', '');
            params.left3_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left3_picture_2.key) && !_.isNull(state.item.left3_picture_2.key.match(/\/draft\//g))) ? state.item.left3_picture_2.key : null,
                size: params.left3_picture_2.size,
                extension: params.left3_picture_2.extension,
                uploadedBy: params.left3_picture_2.uploadedBy,
                uploadedAt: params.left3_picture_2.uploadedAt,
            }
        }
        if (_.has(state.item.left3_picture_3, 'key')) {
            let imgPath = _.replace(state.item.left3_picture_3.key, '/draft', '');
            params.left3_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left3_picture_3.key) && !_.isNull(state.item.left3_picture_3.key.match(/\/draft\//g))) ? state.item.left3_picture_3.key : null,
                size: params.left3_picture_3.size,
                extension: params.left3_picture_3.extension,
                uploadedBy: params.left3_picture_3.uploadedBy,
                uploadedAt: params.left3_picture_3.uploadedAt,
            }
        }


        if (_.has(state.item.left4_picture_1, 'key')) {
            let imgPath = _.replace(state.item.left4_picture_1.key, '/draft', '');
            params.left4_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left4_picture_1.key) && !_.isNull(state.item.left4_picture_1.key.match(/\/draft\//g))) ? state.item.left4_picture_1.key : null,
                size: params.left4_picture_1.size,
                extension: params.left4_picture_1.extension,
                uploadedBy: params.left4_picture_1.uploadedBy,
                uploadedAt: params.left4_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.left4_picture_2, 'key')) {
            let imgPath = _.replace(state.item.left4_picture_2.key, '/draft', '');
            params.left4_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left4_picture_2.key) && !_.isNull(state.item.left4_picture_2.key.match(/\/draft\//g))) ? state.item.left4_picture_2.key : null,
                size: params.left4_picture_2.size,
                extension: params.left4_picture_2.extension,
                uploadedBy: params.left4_picture_2.uploadedBy,
                uploadedAt: params.left4_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.left4_picture_3, 'key')) {
            let imgPath = _.replace(state.item.left4_picture_3.key, '/draft', '');
            params.left4_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left4_picture_3.key) && !_.isNull(state.item.left4_picture_3.key.match(/\/draft\//g))) ? state.item.left4_picture_3.key : null,
                size: params.left4_picture_3.size,
                extension: params.left4_picture_3.extension,
                uploadedBy: params.left4_picture_3.uploadedBy,
                uploadedAt: params.left4_picture_3.uploadedAt,
            }
        }


        if (_.has(state.item.left5_picture_1, 'key')) {
            let imgPath = _.replace(state.item.left5_picture_1.key, '/draft', '');
            params.left5_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left5_picture_1.key) && !_.isNull(state.item.left5_picture_1.key.match(/\/draft\//g))) ? state.item.left5_picture_1.key : null,
                size: params.left5_picture_1.size,
                extension: params.left5_picture_1.extension,
                uploadedBy: params.left5_picture_1.uploadedBy,
                uploadedAt: params.left5_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.left5_picture_2, 'key')) {
            let imgPath = _.replace(state.item.left5_picture_2.key, '/draft', '');
            params.left5_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left5_picture_2.key) && !_.isNull(state.item.left5_picture_2.key.match(/\/draft\//g))) ? state.item.left5_picture_2.key : null,
                size: params.left5_picture_2.size,
                extension: params.left5_picture_2.extension,
                uploadedBy: params.left5_picture_2.uploadedBy,
                uploadedAt: params.left5_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.left5_picture_3, 'key')) {
            let imgPath = _.replace(state.item.left5_picture_3.key, '/draft', '');
            params.left5_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.left5_picture_3.key) && !_.isNull(state.item.left5_picture_3.key.match(/\/draft\//g))) ? state.item.left5_picture_3.key : null,
                size: params.left5_picture_3.size,
                extension: params.left5_picture_3.extension,
                uploadedBy: params.left5_picture_3.uploadedBy,
                uploadedAt: params.left5_picture_3.uploadedAt,
            }
        }
        /**Right */

        if (_.has(state.item.right1_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right1_picture_1.key, '/draft', '');
            params.right1_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right1_picture_1.key) && !_.isNull(state.item.right1_picture_1.key.match(/\/draft\//g))) ? state.item.right1_picture_1.key : null,
                size: params.right1_picture_1.size,
                extension: params.right1_picture_1.extension,
                uploadedBy: params.right1_picture_1.uploadedBy,
                uploadedAt: params.right1_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right1_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right1_picture_2.key, '/draft', '');
            params.right1_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right1_picture_2.key) && !_.isNull(state.item.right1_picture_2.key.match(/\/draft\//g))) ? state.item.right1_picture_2.key : null,
                size: params.right1_picture_2.size,
                extension: params.right1_picture_2.extension,
                uploadedBy: params.right1_picture_2.uploadedBy,
                uploadedAt: params.right1_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right1_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right1_picture_3.key, '/draft', '');
            params.right1_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right1_picture_3.key) && !_.isNull(state.item.right1_picture_3.key.match(/\/draft\//g))) ? state.item.right1_picture_3.key : null,
                size: params.right1_picture_3.size,
                extension: params.right1_picture_3.extension,
                uploadedBy: params.right1_picture_3.uploadedBy,
                uploadedAt: params.right1_picture_3.uploadedAt,
            }
        }

        if (_.has(state.item.right2_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right2_picture_1.key, '/draft', '');
            params.right2_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right2_picture_1.key) && !_.isNull(state.item.right2_picture_1.key.match(/\/draft\//g))) ? state.item.right2_picture_1.key : null,
                size: params.right2_picture_1.size,
                extension: params.right2_picture_1.extension,
                uploadedBy: params.right2_picture_1.uploadedBy,
                uploadedAt: params.right2_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right2_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right2_picture_2.key, '/draft', '');
            params.right2_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right2_picture_2.key) && !_.isNull(state.item.right2_picture_2.key.match(/\/draft\//g))) ? state.item.right2_picture_2.key : null,
                size: params.right2_picture_2.size,
                extension: params.right2_picture_2.extension,
                uploadedBy: params.right2_picture_2.uploadedBy,
                uploadedAt: params.right2_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right2_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right2_picture_3.key, '/draft', '');
            params.right2_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right2_picture_3.key) && !_.isNull(state.item.right2_picture_3.key.match(/\/draft\//g))) ? state.item.right2_picture_3.key : null,
                size: params.right2_picture_3.size,
                extension: params.right2_picture_3.extension,
                uploadedBy: params.right2_picture_3.uploadedBy,
                uploadedAt: params.right2_picture_3.uploadedAt,
            }
        }

        if (_.has(state.item.right3_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right3_picture_1.key, '/draft', '');
            params.right3_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right3_picture_1.key) && !_.isNull(state.item.right3_picture_1.key.match(/\/draft\//g))) ? state.item.right3_picture_1.key : null,
                size: params.right3_picture_1.size,
                extension: params.right3_picture_1.extension,
                uploadedBy: params.right3_picture_1.uploadedBy,
                uploadedAt: params.right3_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right3_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right3_picture_2.key, '/draft', '');
            params.right3_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right3_picture_2.key) && !_.isNull(state.item.right3_picture_2.key.match(/\/draft\//g))) ? state.item.right3_picture_2.key : null,
                size: params.right3_picture_2.size,
                extension: params.right3_picture_2.extension,
                uploadedBy: params.right3_picture_2.uploadedBy,
                uploadedAt: params.right3_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right3_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right3_picture_3.key, '/draft', '');
            params.right3_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right3_picture_3.key) && !_.isNull(state.item.right3_picture_3.key.match(/\/draft\//g))) ? state.item.right3_picture_3.key : null,
                size: params.right3_picture_3.size,
                extension: params.right3_picture_3.extension,
                uploadedBy: params.right3_picture_3.uploadedBy,
                uploadedAt: params.right3_picture_3.uploadedAt,
            }
        }

        if (_.has(state.item.right4_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right4_picture_1.key, '/draft', '');
            params.right4_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right4_picture_1.key) && !_.isNull(state.item.right4_picture_1.key.match(/\/draft\//g))) ? state.item.right4_picture_1.key : null,
                size: params.right4_picture_1.size,
                extension: params.right4_picture_1.extension,
                uploadedBy: params.right4_picture_1.uploadedBy,
                uploadedAt: params.right4_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right4_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right4_picture_2.key, '/draft', '');
            params.right4_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right4_picture_2.key) && !_.isNull(state.item.right4_picture_2.key.match(/\/draft\//g))) ? state.item.right4_picture_2.key : null,
                size: params.right4_picture_2.size,
                extension: params.right4_picture_2.extension,
                uploadedBy: params.right4_picture_2.uploadedBy,
                uploadedAt: params.right4_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right4_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right4_picture_3.key, '/draft', '');
            params.right4_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right4_picture_3.key) && !_.isNull(state.item.right4_picture_3.key.match(/\/draft\//g))) ? state.item.right4_picture_3.key : null,
                size: params.right4_picture_3.size,
                extension: params.right4_picture_3.extension,
                uploadedBy: params.right4_picture_3.uploadedBy,
                uploadedAt: params.right4_picture_3.uploadedAt,
            }
        }

        if (_.has(state.item.right5_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right5_picture_1.key, '/draft', '');
            params.right5_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right5_picture_1.key) && !_.isNull(state.item.right5_picture_1.key.match(/\/draft\//g))) ? state.item.right5_picture_1.key : null,
                size: params.right5_picture_1.size,
                extension: params.right5_picture_1.extension,
                uploadedBy: params.right5_picture_1.uploadedBy,
                uploadedAt: params.right5_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right5_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right5_picture_2.key, '/draft', '');
            params.right5_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right5_picture_2.key) && !_.isNull(state.item.right5_picture_2.key.match(/\/draft\//g))) ? state.item.right5_picture_2.key : null,
                size: params.right5_picture_2.size,
                extension: params.right5_picture_2.extension,
                uploadedBy: params.right5_picture_2.uploadedBy,
                uploadedAt: params.right5_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right5_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right5_picture_3.key, '/draft', '');
            params.right5_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right5_picture_3.key) && !_.isNull(state.item.right5_picture_3.key.match(/\/draft\//g))) ? state.item.right5_picture_3.key : null,
                size: params.right5_picture_3.size,
                extension: params.right5_picture_3.extension,
                uploadedBy: params.right5_picture_3.uploadedBy,
                uploadedAt: params.right5_picture_3.uploadedAt,
            }
        }

        if (_.has(state.item.right6_picture_1, 'key')) {
            let imgPath = _.replace(state.item.right6_picture_1.key, '/draft', '');
            params.right6_picture_1 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right6_picture_1.key) && !_.isNull(state.item.right6_picture_1.key.match(/\/draft\//g))) ? state.item.right6_picture_1.key : null,
                size: params.right6_picture_1.size,
                extension: params.right6_picture_1.extension,
                uploadedBy: params.right6_picture_1.uploadedBy,
                uploadedAt: params.right6_picture_1.uploadedAt,
            }
        }

        if (_.has(state.item.right6_picture_2, 'key')) {
            let imgPath = _.replace(state.item.right6_picture_2.key, '/draft', '');
            params.right6_picture_2 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right6_picture_2.key) && !_.isNull(state.item.right6_picture_2.key.match(/\/draft\//g))) ? state.item.right6_picture_2.key : null,
                size: params.right6_picture_2.size,
                extension: params.right6_picture_2.extension,
                uploadedBy: params.right6_picture_2.uploadedBy,
                uploadedAt: params.right6_picture_2.uploadedAt,
            }
        }

        if (_.has(state.item.right6_picture_3, 'key')) {
            let imgPath = _.replace(state.item.right6_picture_3.key, '/draft', '');
            params.right6_picture_3 = {
                key: imgPath,
                old_key: (!_.isNull(state.item.right6_picture_3.key) && !_.isNull(state.item.right6_picture_3.key.match(/\/draft\//g))) ? state.item.right6_picture_3.key : null,
                size: params.right6_picture_3.size,
                extension: params.right6_picture_3.extension,
                uploadedBy: params.right6_picture_3.uploadedBy,
                uploadedAt: params.right6_picture_3.uploadedAt,
            }
        }

        return axios.patch(constants.API_EVENT_HALL_URL + '/api/v1/event-hall/' + state.item.event_id + '/' + state.item._id, params)
            .then(response => {
                commit('setItem', response.data.data.value)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, params) {
        commit('setLoading', true)
        return axios.get(constants.API_EVENT_HALL_URL + `/api/v1/event-hall/${params.event_id}/${params.event_hall_id}`)
            .then(response => {
                commit('setItem', response.data.data)
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchEventsAll({ commit }, event_id) {
        commit('setEvent', event_id)
    },
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        axios.get(constants.API_EVENT_HALL_URL + '/api/v1/event_details/' + event_id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type)
                state.event_type = response.data.data[0].event_type
            })
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setSkybox({ commit }, value) {
        commit('setSkybox', value)
    },
    setLeftPicsNo1({ commit }, value) {
        commit('setLeftPicsNo1', value)
    },
    setLeftPicsNo2({ commit }, value) {
        commit('setLeftPicsNo2', value)
    },
    setLeftPicsNo3({ commit }, value) {
        commit('setLeftPicsNo3', value)
    },
    setLeftPicsNo4({ commit }, value) {
        commit('setLeftPicsNo4', value)
    },
    setLeftPicsNo5({ commit }, value) {
        commit('setLeftPicsNo5', value)
    },
    setRightPicsNo1({ commit }, value) {
        commit('setRightPicsNo1', value)
    },
    setRightPicsNo2({ commit }, value) {
        commit('setRightPicsNo2', value)
    },
    setRightPicsNo3({ commit }, value) {
        commit('setRightPicsNo3', value)
    },
    setRightPicsNo4({ commit }, value) {
        commit('setRightPicsNo4', value)
    },
    setRightPicsNo5({ commit }, value) {
        commit('setRightPicsNo5', value)
    },
    setRightPicsNo6({ commit }, value) {
        commit('setRightPicsNo6', value)
    },
    setEnvironment({ commit }, value) {
        commit('setEnvironment', value)
    },
    setAuditorium_live({ commit }, value) {
        commit('setAuditorium_live', value)
    },
    setRight1Picture1({ commit }, value) {
        commit('setRight1Picture1', value)
    },
    setRight1Picture2({ commit }, value) {
        commit('setRight1Picture2', value)
    },
    setRight2Picture1({ commit }, value) {
        commit('setRight2Picture1', value)
    },
    setRight2Picture2({ commit }, value) {
        commit('setRight2Picture2', value)
    },
    setRight3Picture1({ commit }, value) {
        commit('setRight3Picture1', value)
    },
    setRight3Picture2({ commit }, value) {
        commit('setRight3Picture2', value)
    },
    setRight4Picture1({ commit }, value) {
        commit('setRight4Picture1', value)
    },
    setRight4Picture2({ commit }, value) {
        commit('setRight4Picture2', value)
    },
    setRight5Picture1({ commit }, value) {
        commit('setRight5Picture1', value)
    },
    setRight5Picture2({ commit }, value) {
        commit('setRight5Picture2', value)
    },
    setRight6Picture1({ commit }, value) {
        commit('setRight6Picture1', value)
    },
    setRight6Picture2({ commit }, value) {
        commit('setRight6Picture2', value)
    },
    setLeft1Picture1({ commit }, value) {
        commit('setLeft1Picture1', value)
    },
    setLeft1Picture2({ commit }, value) {
        commit('setLeft1Picture2', value)
    },
    setLeft1Picture3({ commit }, value) {
        commit('setLeft1Picture3', value)
    },
    setLeft2Picture1({ commit }, value) {
        commit('setLeft2Picture1', value)
    },
    setLeft2Picture2({ commit }, value) {
        commit('setLeft2Picture2', value)
    },
    setLeft2Picture3({ commit }, value) {
        commit('setLeft2Picture3', value)
    },
    setLeft3Picture1({ commit }, value) {
        commit('setLeft3Picture1', value)
    },
    setLeft3Picture2({ commit }, value) {
        commit('setLeft3Picture2', value)
    },
    setLeft3Picture3({ commit }, value) {
        commit('setLeft3Picture3', value)
    },
    setLeft4Picture1({ commit }, value) {
        commit('setLeft4Picture1', value)
    },
    setLeft4Picture2({ commit }, value) {
        commit('setLeft4Picture2', value)
    },
    setLeft4Picture3({ commit }, value) {
        commit('setLeft4Picture3', value)
    },
    setLeft5Picture1({ commit }, value) {
        commit('setLeft5Picture1', value)
    },
    setLeft5Picture2({ commit }, value) {
        commit('setLeft5Picture2', value)
    },
    setLeft5Picture3({ commit }, value) {
        commit('setLeft5Picture3', value)
    },
    setBannerLong1({ commit }, value) {
        commit('setBannerLong1', value)
    },
    setBannerLong2({ commit }, value) {
        commit('setBannerLong2', value)
    },
    setBannerLong3({ commit }, value) {
        commit('setBannerLong3', value)
    },
    setBannerLong8({ commit }, value) {
        commit('setBannerLong8', value)
    },
    setBannerLong4({ commit }, value) {
        commit('setBannerLong4', value)
    },
    setBannerLong5({ commit }, value) {
        commit('setBannerLong5', value)
    },
    setBannerLong6({ commit }, value) {
        commit('setBannerLong6', value)
    },
    setBannerLong7({ commit }, value) {
        commit('setBannerLong7', value)
    },
    setBannerMedium1({ commit }, value) {
        commit('setBannerMedium1', value)
    },
    setBannerMedium2({ commit }, value) {
        commit('setBannerMedium2', value)
    },
    setBannerMedium3({ commit }, value) {
        commit('setBannerMedium3', value)
    },
    setBannerMedium4({ commit }, value) {
        commit('setBannerMedium4', value)
    },
    setBannerMedium5({ commit }, value) {
        commit('setBannerMedium5', value)
    },
    setBannerMedium6({ commit }, value) {
        commit('setBannerMedium6', value)
    },
    setBannerMedium7({ commit }, value) {
        commit('setBannerMedium7', value)
    },
    setBannerMedium8({ commit }, value) {
        commit('setBannerMedium8', value)
    },
    setBannerSmall1({ commit }, value) {
        commit('setBannerSmall1', value)
    },
    setBannerSmall2({ commit }, value) {
        commit('setBannerSmall2', value)
    },
    setBannerSmall3({ commit }, value) {
        commit('setBannerSmall3', value)
    },
    setBannerSmall4({ commit }, value) {
        commit('setBannerSmall4', value)
    },
    setBannerSmall5({ commit }, value) {
        commit('setBannerSmall5', value)
    },
    setBannerSmall6({ commit }, value) {
        commit('setBannerSmall6', value)
    },
    setBannerSmall7({ commit }, value) {
        commit('setBannerSmall7', value)
    },
    setBannerSmall8({ commit }, value) {
        commit('setBannerSmall8', value)
    },
    setBannerSmall9({ commit }, value) {
        commit('setBannerSmall9', value)
    },
    setAuditorium_pre_recorded({ commit }, value) {
        commit('setAuditorium_pre_recorded', value)
    },
    setAuditorium_panel_discussion({ commit }, value) {
        commit('setAuditorium_panel_discussion', value)
    },
    setExhibition_area({ commit }, value) {
        commit('setExhibition_area', value)
    },
    setVirtual_training({ commit }, value) {
        commit('setVirtual_training', value)
    },
    setRoundtable({ commit }, value) {
        commit('setRoundtable', value)
    },
    setVirtual_meeting({ commit }, value) {
        commit('setVirtual_meeting', value)
    },
    setAuditorium_live_label({ commit }, value) {
        commit('setAuditorium_live_label', value)
    },
    setScreenDesc1({ commit }, value) {
        commit('setScreenDesc1', value)
    },
    setScreenDesc2({ commit }, value) {
        commit('setScreenDesc2', value)
    },
    setScreenDesc3({ commit }, value) {
        commit('setScreenDesc3', value)
    },
    setScreenDesc4({ commit }, value) {
        commit('setScreenDesc4', value)
    },
    setScreenDesc5({ commit }, value) {
        commit('setScreenDesc5', value)
    },
    setScreenDesc6({ commit }, value) {
        commit('setScreenDesc6', value)
    },
    setScreenDesc7({ commit }, value) {
        commit('setScreenDesc7', value)
    },
    setScrollingText({ commit }, value) {
        commit('setScrollingText', value)
    },
    setAuditorium_pre_recorded_label({ commit }, value) {
        commit('setAuditorium_pre_recorded_label', value)
    },
    setAuditorium_panel_discussion_label({ commit }, value) {
        commit('setAuditorium_panel_discussion_label', value)
    },
    setExhibition_area_label({ commit }, value) {
        commit('setExhibition_area_label', value)
    },
    setVirtual_training_label({ commit }, value) {
        commit('setVirtual_training_label', value)
    },
    setRoundtable_label({ commit }, value) {
        commit('setRoundtable_label', value)
    },
    setVirtual_meeting_label({ commit }, value) {
        commit('setVirtual_meeting_label', value)
    },
    setExhibitionVideo({ commit }, value) {
        commit('setExhibitionVideo', value)
    },
    setScreen_video1({ commit }, value) {
        commit('setScreen_video1', value)
    },
    setScreen_video2({ commit }, value) {
        commit('setScreen_video2', value)
    },
    setScreen_video3({ commit }, value) {
        commit('setScreen_video3', value)
    },
    setScreen_video4({ commit }, value) {
        commit('setScreen_video4', value)
    },
    setScreen_video5({ commit }, value) {
        commit('setScreen_video5', value)
    },
    setScreen_video6({ commit }, value) {
        commit('setScreen_video6', value)
    },
    setScreen_video7({ commit }, value) {
        commit('setScreen_video7', value)
    },
    setExhibitionDocument({ commit }, value) {
        commit('setExhibitionDocument', value)
    },
    setScreen_document1({ commit }, value) {
        commit('setScreen_document1', value)
    },
    setScreen_document2({ commit }, value) {
        commit('setScreen_document2', value)
    },
    setScreen_document3({ commit }, value) {
        commit('setScreen_document3', value)
    },
    setScreen_document4({ commit }, value) {
        commit('setScreen_document4', value)
    },
    setScreen_document5({ commit }, value) {
        commit('setScreen_document5', value)
    },
    setScreen_document6({ commit }, value) {
        commit('setScreen_document6', value)
    },
    setScreen_document7({ commit }, value) {
        commit('setScreen_document7', value)
    },
    setLogo_standee({ commit }, value) {
        commit('setLogo_standee', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setSkyboxPicture({ commit }, value) {
        commit('setSkyboxPicture', value)
    },
    setEnvironmentPicture({ commit }, value) {
        commit('setEnvironmentPicture', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setIs_picture({ commit }, value) {
        commit('setIs_picture', value)
    },
    setSkybox_is_custom({ commit }, value) {
        commit('setSkybox_is_custom', value)
    },
    setEnvironment_is_custom({ commit }, value) {
        commit('setEnvironment_is_custom', value)
    },
    setLeftStandeeText1({ commit }, value) {
        commit('setLeftStandeeText1', value)
    },
    setLeftStandeeText2({ commit }, value) {
        commit('setLeftStandeeText2', value)
    },
    setLeftStandeeText3({ commit }, value) {
        commit('setLeftStandeeText3', value)
    },
    setLeftStandeeText4({ commit }, value) {
        commit('setLeftStandeeText4', value)
    },
    setLeftStandeeText5({ commit }, value) {
        commit('setLeftStandeeText5', value)
    },
    setLeftText1({ commit }, value) {
        commit('setLeftText1', value)
    },
    setLeftText2({ commit }, value) {
        commit('setLeftText2', value)
    },
    setLeftText3({ commit }, value) {
        commit('setLeftText3', value)
    },
    setLeftText4({ commit }, value) {
        commit('setLeftText4', value)
    },
    setLeftText5({ commit }, value) {
        commit('setLeftText5', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setEventPageDesign({ commit }, value) {
        commit('setEventPageDesign', value);
    },
    setScheduleType({ commit }, value) {
        commit('setScheduleType', value);
    },
    setScheduleDescription({ commit }, value) {
        commit('setScheduleDescription', value);
    },
}

const mutations = {
    setEventType(state, value) {
        state.event_type = value
    },
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setSkybox(state, value) {
        state.item.skybox = value
    },
    setLeftPicsNo1(state, value) {
        state.item.left_pics_no_1 = value
    },
    setLeftPicsNo2(state, value) {
        state.item.left_pics_no_2 = value
    },
    setLeftPicsNo3(state, value) {
        state.item.left_pics_no_3 = value
    },
    setLeftPicsNo4(state, value) {
        state.item.left_pics_no_4 = value
    },
    setLeftPicsNo5(state, value) {
        state.item.left_pics_no_5 = value
    },
    setRightPicsNo1(state, value) {
        state.item.right_pics_no_1 = value
    },
    setRightPicsNo2(state, value) {
        state.item.right_pics_no_2 = value
    },
    setRightPicsNo3(state, value) {
        state.item.right_pics_no_3 = value
    },
    setRightPicsNo4(state, value) {
        state.item.right_pics_no_4 = value
    },
    setRightPicsNo5(state, value) {
        state.item.right_pics_no_5 = value
    },
    setRightPicsNo6(state, value) {
        state.item.right_pics_no_6 = value
    },
    setEnvironment(state, value) {
        state.item.environment = value
    },
    setAuditorium_live(state, value) {
        state.item.auditorium_live = value
    },
    setRight1Picture1(state, value) {
        state.item.right1_picture_1 = value
    },
    setRight1Picture2(state, value) {
        state.item.right1_picture_2 = value
    },
    setRight2Picture1(state, value) {
        state.item.right2_picture_1 = value
    },
    setRight2Picture2(state, value) {
        state.item.right2_picture_2 = value
    },
    setRight3Picture1(state, value) {
        state.item.right3_picture_1 = value
    },
    setRight3Picture2(state, value) {
        state.item.right3_picture_2 = value
    },
    setRight4Picture1(state, value) {
        state.item.right4_picture_1 = value
    },
    setRight4Picture2(state, value) {
        state.item.right4_picture_2 = value
    },
    setRight5Picture1(state, value) {
        state.item.right5_picture_1 = value
    },
    setRight5Picture2(state, value) {
        state.item.right5_picture_2 = value
    },
    setRight6Picture1(state, value) {
        state.item.right6_picture_1 = value
    },
    setRight6Picture2(state, value) {
        state.item.right6_picture_2 = value
    },
    setLeft1Picture1(state, value) {
        state.item.left1_picture_1 = value
    },
    setLeft1Picture2(state, value) {
        state.item.left1_picture_2 = value
    },
    setLeft1Picture3(state, value) {
        state.item.left1_picture_3 = value
    },
    setLeft2Picture1(state, value) {
        state.item.left2_picture_1 = value
    },
    setLeft2Picture2(state, value) {
        state.item.left2_picture_2 = value
    },
    setLeft2Picture3(state, value) {
        state.item.left2_picture_3 = value
    },
    setLeft3Picture1(state, value) {
        state.item.left3_picture_1 = value
    },
    setLeft3Picture2(state, value) {
        state.item.left3_picture_2 = value
    },
    setLeft3Picture3(state, value) {
        state.item.left3_picture_3 = value
    },
    setLeft4Picture1(state, value) {
        state.item.left4_picture_1 = value
    },
    setLeft4Picture2(state, value) {
        state.item.left4_picture_2 = value
    },
    setLeft4Picture3(state, value) {
        state.item.left4_picture_3 = value
    },
    setLeft5Picture1(state, value) {
        state.item.left5_picture_1 = value
    },
    setLeft5Picture2(state, value) {
        state.item.left5_picture_2 = value
    },
    setLeft5Picture3(state, value) {
        state.item.left5_picture_3 = value
    },
    setFlag1(state, value) {
        state.item.flag1 = value
    },
    setFlag2(state, value) {
        state.item.flag2 = value
    },
    setFlag3(state, value) {
        state.item.flag3 = value
    },
    setFlag4(state, value) {
        state.item.flag4 = value
    },
    setFlag5(state, value) {
        state.item.flag5 = value
    },
    setFlag6(state, value) {
        state.item.flag6 = value
    },
    setFlag7(state, value) {
        state.item.flag7 = value
    },
    setFlag8(state, value) {
        state.item.flag8 = value
    },
    setFlag9(state, value) {
        state.item.flag9 = value
    },
    setFlag10(state, value) {
        state.item.flag10 = value
    },
    setFlag11(state, value) {
        state.item.flag11 = value
    },
    setFlag12(state, value) {
        state.item.flag12 = value
    },
    setFlag13(state, value) {
        state.item.flag13 = value
    },
    setFlag14(state, value) {
        state.item.flag14 = value
    },
    setFlag15(state, value) {
        state.item.flag15 = value
    },
    setFlag16(state, value) {
        state.item.flag16 = value
    },
    setFlag17(state, value) {
        state.item.flag17 = value
    },
    setFlag18(state, value) {
        state.item.flag18 = value
    },
    setFlag19(state, value) {
        state.item.flag19 = value
    },
    setFlag20(state, value) {
        state.item.flag20 = value
    },
    setFlag21(state, value) {
        state.item.flag21 = value
    },
    setFlag22(state, value) {
        state.item.flag22 = value
    },
    setFlag23(state, value) {
        state.item.flag23 = value
    },
    setFlag24(state, value) {
        state.item.flag24 = value
    },
    setFlag25(state, value) {
        state.item.flag25 = value
    },
    setFlag26(state, value) {
        state.item.flag26 = value
    },
    setFlag27(state, value) {
        state.item.flag27 = value
    },
    setFlag28(state, value) {
        state.item.flag28 = value
    },
    setFlag29(state, value) {
        state.item.flag29 = value
    },
    setFlag30(state, value) {
        state.item.flag30 = value
    },
    setFlag31(state, value) {
        state.item.flag31 = value
    },
    setFlag32(state, value) {
        state.item.flag32 = value
    },
    setFlag33(state, value) {
        state.item.flag33 = value
    },
    setFlag34(state, value) {
        state.item.flag34 = value
    },
    setFlag35(state, value) {
        state.item.flag35 = value
    },
    setFlag36(state, value) {
        state.item.flag36 = value
    },
    setFlag37(state, value) {
        state.item.flag37 = value
    },
    setFlag38(state, value) {
        state.item.flag38 = value
    },
    setFlag39(state, value) {
        state.item.flag39 = value
    },
    setFlag40(state, value) {
        state.item.flag40 = value
    },
    setFlag41(state, value) {
        state.item.flag41 = value
    },
    setFlag42(state, value) {
        state.item.flag42 = value
    },
    setFlag43(state, value) {
        state.item.flag43 = value
    },
    setFlag44(state, value) {
        state.item.flag44 = value
    },
    setFlag45(state, value) {
        state.item.flag45 = value

    },
    setFlag46(state, value) {
        state.item.flag46 = value

    },
    setFlag47(state, value) {
        state.item.flag47 = value

    },
    setFlag48(state, value) {
        state.item.flag48 = value

    },
    setFlag49(state, value) {
        state.item.flag49 = value

    },
    setFlag50(state, value) {
        state.item.flag50 = value

    },
    setBannerLong1(state, value) {
        state.item.banner_long1 = value

    },
    setBannerLong2(state, value) {
        state.item.banner_long2 = value

    },
    setBannerLong3(state, value) {
        state.item.banner_long3 = value

    },
    setBannerLong4(state, value) {
        state.item.banner_long4 = value

    },
    setBannerLong5(state, value) {
        state.item.banner_long5 = value

    },
    setBannerLong6(state, value) {
        state.item.banner_long6 = value

    },
    setBannerLong7(state, value) {
        state.item.banner_long7 = value

    },
    setBannerLong8(state, value) {
        state.item.banner_long8 = value

    },
    setBannerMedium1(state, value) {
        state.item.banner_medium1 = value

    },
    setBannerMedium2(state, value) {
        state.item.banner_medium2 = value

    },
    setBannerMedium3(state, value) {
        state.item.banner_medium3 = value

    },
    setBannerMedium4(state, value) {
        state.item.banner_medium4 = value

    },
    setBannerMedium5(state, value) {
        state.item.banner_medium5 = value

    },
    setBannerMedium6(state, value) {
        state.item.banner_medium6 = value
    },
    setBannerMedium7(state, value) {
        state.item.banner_medium7 = value

    },
    setBannerMedium8(state, value) {
        state.item.banner_medium8 = value

    },
    setBannerSmall1(state, value) {
        state.item.banner_small1 = value

    },
    setBannerSmall2(state, value) {
        state.item.banner_small2 = value

    },
    setBannerSmall3(state, value) {
        state.item.banner_small3 = value

    },
    setBannerSmall4(state, value) {
        state.item.banner_small4 = value

    },
    setBannerSmall5(state, value) {
        state.item.banner_small5 = value

    },
    setBannerSmall6(state, value) {
        state.item.banner_small6 = value

    },
    setBannerSmall7(state, value) {
        state.item.banner_small7 = value

    },
    setBannerSmall8(state, value) {
        state.item.banner_small8 = value

    },
    setBannerSmall9(state, value) {
        state.item.banner_small9 = value

    },
    setAuditorium_pre_recorded(state, value) {
        state.item.auditorium_pre_recorded = value

    },
    setAuditorium_panel_discussion(state, value) {
        state.item.auditorium_panel_discussion = value

    },
    setExhibition_area(state, value) {
        state.item.exhibition_area = value

    },
    setVirtual_training(state, value) {
        state.item.virtual_training = value

    },
    setRoundtable(state, value) {
        state.item.roundtable = value

    },
    setVirtual_meeting(state, value) {
        state.item.virtual_meeting = value

    },
    setScrollingText(state, value) {
        state.item.scrolling_text = value

    },
    setAuditorium_live_label(state, value) {
        state.item.auditorium_live_label = value

    },
    setScreenDesc1(state, value) {
        state.item.screen_1_description = value

    },
    setScreenDesc2(state, value) {
        state.item.screen_2_description = value

    },
    setScreenDesc3(state, value) {
        state.item.screen_3_description = value

    },
    setScreenDesc4(state, value) {
        state.item.screen_4_description = value

    },
    setScreenDesc5(state, value) {
        state.item.screen_5_description = value

    },
    setScreenDesc6(state, value) {
        state.item.screen_6_description = value

    },
    setScreenDesc7(state, value) {
        state.item.screen_7_description = value

    },
    setAuditorium_pre_recorded_label(state, value) {
        state.item.auditorium_pre_recorded_label = value

    },
    setAuditorium_panel_discussion_label(state, value) {
        state.item.auditorium_panel_discussion_label = value

    },
    setExhibition_area_label(state, value) {
        state.item.exhibition_area_label = value

    },
    setVirtual_training_label(state, value) {
        state.item.virtual_training_label = value

    },
    setRoundtable_label(state, value) {
        state.item.roundtable_label = value

    },
    setVirtual_meeting_label(state, value) {
        state.item.virtual_meeting_label = value

    },
    setExhibitionVideo(state, value) {
        state.item.exhibition_video = value

    },
    setScreen_video1(state, value) {
        state.item.screen_video1 = value
    },
    setScreen_video2(state, value) {
        state.item.screen_video2 = value

    },
    setScreen_video3(state, value) {
        state.item.screen_video3 = value

    },
    setScreen_video4(state, value) {
        state.item.screen_video4 = value

    },
    setScreen_video5(state, value) {
        state.item.screen_video5 = value

    },
    setScreen_video6(state, value) {
        state.item.screen_video6 = value

    },
    setScreen_video7(state, value) {
        state.item.screen_video7 = value

    },
    setExhibitionDocument(state, value) {
        state.item.exhibition_document = value
    },
    setScreen_document1(state, value) {
        state.item.screen_document1 = value

    },
    setScreen_document2(state, value) {
        state.item.screen_document2 = value

    },
    setScreen_document3(state, value) {
        state.item.screen_document3 = value

    },
    setScreen_document4(state, value) {
        state.item.screen_document4 = value

    },
    setScreen_document5(state, value) {
        state.item.screen_document5 = value

    },
    setScreen_document6(state, value) {
        state.item.screen_document6 = value

    },
    setScreen_document7(state, value) {
        state.item.screen_document7 = value

    },
    setLogo_standee(state, value) {
        state.item.logo_standee = value

    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value

    },
    setSkyboxPicture(state, value) {
        state.item.skybox_picture = value

    },
    setEnvironmentPicture(state, value) {
        state.item.environment_picture = value

    },
    setBackgroundColor(state, value) {
        state.item.background_color_top = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setIs_picture(state, value) {
        state.item.is_picture = value
        console.log(value)

    },
    setSkybox_is_custom(state, value) {
        state.item.skybox_is_custom = value


    },
    setEnvironment_is_custom(state, value) {
        state.item.environment_is_custom = value
    },
    setLeftStandeeText1(state, value) {
        state.item.left_standee_text_1 = value
    },
    setLeftStandeeText2(state, value) {
        state.item.left_standee_text_2 = value

    },
    setLeftStandeeText3(state, value) {
        state.item.left_standee_text_3 = value

    },
    setLeftStandeeText4(state, value) {
        state.item.left_standee_text_4 = value
    },
    setLeftStandeeText5(state, value) {
        state.item.left_standee_text_5 = value
    },
    setLeftText1(state, value) {
        state.item.left_text_1 = value
    },
    setLeftText2(state, value) {
        state.item.left_text_2 = value
    },
    setLeftText3(state, value) {
        state.item.left_text_3 = value
    },
    setLeftText4(state, value) {
        state.item.left_text_4 = value
    },
    setLeftText5(state, value) {
        state.item.left_text_5 = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEventPageDesign(state, value) {
        state.item.event_page_design = value;
    },
    setScheduleType(state, value) {
        state.item.schedule_type = value;
    },
    setScheduleDescription(state, value) {
        state.item.schedule_description = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
