<template>
  <div class="row">
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        
        <div class="row">
          <div class="event_ls_label col-md-2">Moderator</div>
          <div class="content_label_text col-md-10" v-if="item.moderator">
            {{ getUserFullName(item.moderator) }}
          </div>
        </div>
      </div>
      <div class="event_detail_wrapper" v-if="item.second_moderator">
        <div class="row">
          <div class="event_ls_label col-md-2">Second Moderator</div>
          <div class="content_label_text col-md-10" v-if="item.second_moderator">
            {{ getUserFullName(item.second_moderator) }}
          </div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Start Time</div>
          <div class="col-md-8 col-md-10">{{ formatPresentationDate(item.start_time) }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">End Time</div>
          <div class="col-md-8 col-md-10">{{ formatPresentationDate(item.end_time) }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Description</div>
          <div class="col-md-8 col-md-10">
            <div v-html="getDescription(item)" />
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mt-3 mr-5">
      <button v-if="can('panel_discussion_edit',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(item)">Edit</button>
      <button v-if="can('panel_discussion_delete',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(item)">Delete</button>
    </div>
    <div class="col-12">
<!--      <PanelistsShow :agenda="item" />-->
<!--      <SurveysShow :agenda="item" />-->
    </div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex';
import moment from 'moment';
import { isPermits} from "@/services/CheckPermissions";

// import PanelistsShow from "@/components/cruds/PanelAgenda/Panelists/Show"
// import SurveysShow from "@/components/cruds/PanelAgenda/Surveys/Show"
import _ from "lodash";

export default {
  name: "ShowItem",
  components: {
    // PanelistsShow,
    // SurveysShow,
  },
  data() {
    return {
      speaker_name: null,
    }
  },
  props: {
    checkPermssions : null,
    event           : null,
    item: {
      type: Object,
    }
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
  },

  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad"]),
      
    ...mapActions('PanelAgendasIndex', ['fetchData', 'destroyData']),
    ...mapActions('PanelAgendasSingle', ['setItem', 'setIsNew', 'setIsEditing','setLangKey','setLangTitle','setLangDescirption']),
    deleteData(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
       }).then(result => {
                if (result.isConfirmed) {
                    this.destroyData(item._id).then(() => {
                      this.setIsNew(false);
                      this.setIsEditing(false);
          
                        this.$flashMessage.show({
                        group : 'panel',
                        type  : 'success',
                        title : 'Success',
                        time  : 1000,
                        html  : '<ul><li>Panel Discussion has been deleted successfully.</li></ul>'
                      });
                      this.fetchData(this.$route.params.event_id);
                    })
                }
            });
    },



    editData(item) {

      if (!_.isEmpty(item.translations)) {
          let defaultLanguage = item.translations[0];
          if (_.has(defaultLanguage, 'key')) {
              this.setLangKey(defaultLanguage['key'])
          }
          if (_.has(defaultLanguage, 'title')) {
              this.setLangTitle(defaultLanguage['title'])
          }
          if (_.has(defaultLanguage, 'description')) {
              this.setLangDescirption(defaultLanguage['description'])
          }
        }
      this.setItem(item);
      this.setIsNew(false);
      this.setIsEditing(true);
      this.$forceUpdate();
    },


    formatPresentationDate(date) {
            //return moment(date).utc().format("LLLL");
            //return moment(date).utc().format("LLLL");
        // return moment.utc(date).tz(this.event.time_zone).format('LLLL')
      return moment(date).format("LLLL");
      
     
    },

    getUserFullName(user) {
      return [
        _.get(user, 'first_name', ''),
        _.get(user, 'last_name', ''),
      ].join(' ')
    },
    getDescription(agenda) {
      const first_lang = _.head(agenda.translations);
      return _.get(first_lang, 'description', '');
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  },

}
</script>

<style lang="scss" scoped>
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}

.tabs-vertical {
  border-top: 0px;
}

.toggle-content{
  background:#fff;
}
</style>
