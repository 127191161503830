import { indexOf } from "lodash";

function gettingErrorMessage(error) {

  let html = "Something went wrong";
  if (error) {

    let errors = ("error" in error.response.data.data) ? error.response.data.data.error : error.response.data.data;
    html = "<ul>"
    for (let err of errors) {
      html += "<li>" + err.message + "</li>";
    }
    html += "</ul>";
  }
  return html;
}


export { gettingErrorMessage };