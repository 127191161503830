import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        all: [],
        relationships: {
            'company': 'company_name',
            'theme': 'title',
            'language': 'language',
        },
        rows : [],
        event_type: '',
        permission_prefix: '',
        loading: false,
        page_title: "Events"
    }
}

const getters = {
    data: state => {
        let rows = state.all

        if (state.query.sort) {
            rows = _.orderBy(state.all, state.query.sort, state.query.order)
        }

        return rows.slice(state.query.offset, state.query.offset + state.query.limit)
    },
    all: state => state.all,
    total:         state => state.all.length,
    loading:       state => state.loading,
    event_type:       state => state.event_type,
    permission_prefix:       state => state.permission_prefix,
    relationships: state => state.relationships,
    page_title: state => state.page_title
}

const actions = {
    fetchData({ commit }, type) {
        commit('setLoading', true)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/events', {headers: headers})
            .then(response => {
                commit('setAll', response.data.data);
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
                return error
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/events/' + id, {headers: headers})
            .then((response) => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setEditId({commit}, id) {
        commit('setEditId', id)
    },
}

const mutations = {
    setAll(state, items) {
        state.all = items
        state.rows = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEventType(state, type) {
        state.event_type = type
    },
    setPermissionPrefix(state, type){
        state.permission_prefix = type
    },
    resetState(state) {
        // eslint-disable-next-line no-unused-vars
        state = Object.assign(state, initialState())
    }, 
    setEditId(state, id) {
        state.edit_id = id
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
