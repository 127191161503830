<template>
    <div class="row">
        <div class="col-md-2">
            <div class="user_img">
                <img v-if="item.image" :src="IMAGE_URL + item.image.key" alt="pic" />
            </div>
        </div>
        <div class="col-md-5 event_detail_ls">

            <div class="event_detail_wrapper">

                <div class="row">

                    <div class="event_ls_label col-md-2">Title</div>
                    <div class="col-md-8">{{ item.title }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Element Type</div>
                    <div class="col-md-8">{{ item.element_type }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Status</div>
                    <div class="col-md-8">{{ item.status }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Text</div>
                    <div class="col-md-8" v-html="item.text"></div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Sorting Order </div>
                    <div class="col-md-8">{{ item.sorting_order }}</div>
                </div>
            </div>

            <div class="event_detail_wrapper" v-if="item.video">
                <div class="row">
                    <div class="event_ls_label col-md-2">Element Video</div>
                    <div class="media_box col-md-8">
               
                        <a target="_blank" :href="'https://s3.eu-central-1.amazonaws.com/emp-assets.avianet.aero/'+item.video.key" >
                            <img src="/admin_assets/img/video_icon.png" />
                          </a>
        
                    </div>
                </div>
            </div>
    

        </div>   
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"
                @click="editData(item)">Edit</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger"
                @click="deleteData(item)">Delete</button>
        </div>
    </div>
</template>

<script>
import DropFilesShow   from "@/components/Commons/DropFilesShow";
import FileShow from "@/components/Commons/FilesShow";
    
import {
    mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
export default {
    name: "ShowItem",
    data() {
        return {
            IMAGE_URL: constants.IMAGE_URL
        }
    },
    props: {
        event:null,
        item: null,
        id: {
            type: String
        },
        pageId:null
    },

    methods: {
        ...mapActions('EventPageContentIndex', ['fetchData', 'destroyData']),
        ...mapActions('EventPageContentSingle', ['setItem', 'setIsNew', 'setIsEditing']),
        deleteData(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then( result => {
                if (result.isConfirmed) {
                    let delOb = {id:item._id,pageId:this.pageId }
                        this.destroyData(delOb).then((resp) => {
                        console.log("Resp",resp);
                          this.$flashMessage.show({
                              group : 'event_pages_index',
                              type  : 'success',
                              title : 'Success',
                              time  :1000,
                              html  : '<ul><li> Page Content Section has been deleted successfully.</li></ul>'
                          });
                            this.setIsNew(false);
                            this.setIsEditing(false);
                            this.fetchData(this.pageId);
                  
                      })
                  }
            });
        },
        editData(item) {
            this.setItem(item);
            this.setIsNew(false);
            this.setIsEditing(true);
            this.$forceUpdate();
        },
       
    },

}
</script>

<style lang="scss" scoped>
.user_img {
    img {
        width: 100%;
    }
}

.event_detail_wrapper {
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }

    .row {
        .col-md-8 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }

        .col-md-3 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }
    }

    .card-body {
        box-shadow: 0px 0px #ffffff;
    }
}

.tabs-vertical {
    border-top: 0px;
}

.toggle-content {
    background: #fff;
}
</style>
