<template>
  <section class="general-layout-container container-fluid">
    <div class="bg-wrapper-row row">
      <div class="col-md-4 left-element">
        <div class="top-block">
          <slot name="logo">
            <div class="logo">
              <img src="/assets/images/logo_white.png" alt="logo">
            </div>
            <h1>VIRTUAL EVENT</h1>
          </slot>
        </div>
      </div>
      <div class="col-md-8 right-element">
        <div class="menu">    
          <div class="menu-wrapper">
            <img src="/assets/images/temporary_pictures/user.png" class="menu--icon" alt="">
            <div class="dropdown">
              <button class="menu--label dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Me
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="/my-account">My Profile</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/change-password">Change Password</a>
                </li>

                <li>
                  <a class="dropdown-item" href="#" @click="logout">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 body-menu">
        <section class="main-content">
         <div class="header">
            <slot name="header"/>
         </div>
         
          <div class="dashboard-content">
            <slot name="content"/>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GeneralDashboardLayout",
  methods: {
    logout(e) {
      e.preventDefault();
      localStorage.removeItem('token');
      this.$router.push({name: 'login'});
    }
  }
}
</script>

<style scoped lang="scss">
.general-layout-container {
  .main-content {
    position: absolute;
    width: 94%;
    height: calc(98% - 105px);
    left: 3%;
    top: 100px;
    
    box-shadow: 4px 5px 15px -8px black;
    border-radius: 3px;
    overflow-x: visible;
    overflow-y: clip;

    .dashboard-content {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 65px;
      background-color: #ebecef;
      top:60px;
      padding: 20px 20px 90px;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #1896cb;
        outline: none;
        border: none;
      }
    }
  }

  .left-element {
    background-image: url("~@/../public/assets/images/layout/bg.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
  }
  .right-element {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(157,158,167,1) 0%, rgba(241,241,244,1) 18%, rgba(255,255,255,1) 26%);
  }

  .header {
    color: white;
    background: linear-gradient(90deg, #0058dd, #20abe6);
    min-height: 30px;
    padding: 0px 15px;
    text-align: center;
    border: none;
  }

  .top-block {
    display: flex;
    width: 100%;
    min-height: 105px;
    justify-content: space-evenly;
    align-items: center;

    .logo {
      margin: 0;
    }

    h1 {
      font-size: 1.6rem;
      color: white;
      margin: 0;
    }
  }

  .menu {
    position: absolute;
    right: 55px;
    top: 7px;
    width: auto;
    height: 45px;
    cursor: pointer;
    z-index: 9999;

    .menu-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
    }

    &--icon {
      border-radius: 50%;
      overflow: hidden;
      width: auto;
      height: 100%;
      img {
        width: auto;
        height: 100%;
      }
    }
    &--label {
      background-color: #1fa9e5;
      color: white;
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
      border-radius: 5px;
      padding:5px;
      border: none;
    }
  }
  
}






</style>