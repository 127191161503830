
import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";


function initialState() {
    return {   
        all: [],    
        surveyitems: {
            module_id : null,
            module_type : "survey",
            title : null,
            is_open : false,  
            questions_detail:[],

        },   
        newSurvey: false,

        isSurveyNew: false
       
        //setSurveyReport
    }
}

const getters = {      
    all         : state => state.all,
    surveyitems : state => state.surveyitems,
    newSurvey   : state => state.newSurvey,
    isSurveyNew : state => state.isSurveyNew
    
}

const actions = {
     setSurveyItemsData({ commit, state }, value){
        commit('setSurveyItemsData', value)
     },

    fetchSurveyData({ commit, state }, agenda_id) {     
        
        commit('setLoading', true)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       
        axios.get(`${constants.API_URL}/api/survey/${agenda_id}`, {headers: headers}) // questions data
             .then(response => {
               // debugger
                console.log("response.data.data",response.data.data);
                if(response.data.data!=null){
                 commit('setSureyItems', response.data.data); 
                 commit('setIsSurveyNew',false)
               
                }else{
                 commit('setNewSurvey',true);
                 commit('setIsSurveyNew',true)
                }
                
             })            
             .finally(() => {
                 commit('setLoading', false)
             })
    },


    fetchSurveyDataforFeedback({ commit, state }, agenda_id) {     
        
        commit('setLoading', true)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        axios.get(`${constants.API_URL}/api/survey/event-id/${agenda_id}`, {headers: headers}) // questions data
             .then(response => {
               // debugger                             
                 commit('setAll', response.data.data);
                console.log('behlooldata', response.data.data);
                
                
             })            
             .finally(() => {
                 commit('setLoading', false)
             })
    },


    storeSurveyData({ commit, state, dispatch }, moduleid) {
        commit('setLoading', true)         

        const params = _.cloneDeep(state.surveyitems);
        params.module_id = moduleid;
        delete params._id;
        delete params.questions_detail;


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
   
      
        return axios.post(constants.API_URL + '/api/survey', params, {headers: headers})
            .then(response => {
                console.log(response,'New survey submit response data');
              //  commit('resetState')
              commit('setSureyItems', response.data.data);
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },




    storeFeedbackSurveyData({ commit, state, dispatch }, moduleid) {
        commit('setLoading', true)         

        const params = _.cloneDeep(state.surveyitems);

        delete params._id;
        delete params.__v;
        delete params.is_deleted;      
        delete params.createdAt;
        delete params.updatedAt;




        params.module_id = moduleid;
        params.module_type = 'feedback';

        delete params.questions_detail;


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
   
      
        return axios.post(constants.API_URL + '/api/survey', params, {headers: headers})
            .then(response => {
                console.log(response,'New survey submit response data');
              //  commit('resetState')
              commit('setSureyItems', response.data.data);
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },


    fetchData({ commit, state }, agenda_id) {
        commit('setLoading', true)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        axios.get(`${constants.API_URL}/api/survey/${agenda_id}`, {headers: headers}) // questions data
            .then(response => {
               if(response.data.data!=null){
                commit('setSureyItems', response.data.data);
               }else{
                commit('setNewSurvey',true);
               }
                
            })            
            .finally(() => {
                commit('setLoading', false)
            })
    },

    async updateSurveytitleData({ commit, state, dispatch}, multidatasur) {  
                   
      // debugger;
         commit('setLoading', true)      
         let params = _.cloneDeep(state.surveyitems);
 
         params.module_id = multidatasur.module_id;
         delete params.data;
         delete params.status;
         params.module_type = 'survey';
         delete params.questions_detail;
         delete params._id;
         delete params.is_deleted;
         delete params.createdAt;
         delete params.updatedAt;
         delete params.__v;
 
 
        
         const headers = {
             'Authorization': 'Bearer ' + localStorage.getItem('token')
         };         
        
        await  axios.patch(constants.API_URL + '/api/survey/' + multidatasur.survey_id , params, {headers: headers})       
        .then((response) => {
 
                 commit('surveyitems', response.data.data);                            
                  return response;
             })
             .finally(() => {
                 commit('setLoading', false)
             })
     },



     async updateSurveytitleDataFeedback({ commit, state, dispatch}, multidatasur) {  
                   
        // debugger;
           commit('setLoading', true)      
           let params = _.cloneDeep(state.surveyitems);
   
           params.module_id = multidatasur.module_id;
           delete params.data;
           delete params.status;
           params.module_type = 'feedback';
           delete params.questions_detail;
           delete params._id;
           delete params.is_deleted;
           delete params.createdAt;
           delete params.updatedAt;
           delete params.__v;
   
   
          
           const headers = {
               'Authorization': 'Bearer ' + localStorage.getItem('token')
           };         
          
           await axios.patch(constants.API_URL + '/api/survey/' + multidatasur.survey_id , params, {headers: headers})       
          .then((response) => {   
                  
                   commit('surveyitems', response.data.data);              
                  return response;
               })
               .finally(() => {
                   commit('setLoading', false)
               })
       },







    getEventDetails({commit, state}, params){
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/'+params.id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type);
                return response;
            });

    },
    destroyData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/panel-agendas/' + id)
            .then(response => {
               
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            });
    },
    destroyQaData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/qas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.agenda_qas = item.agenda_qas.filter((qa) => {
                        return qa.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyPanelData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/agenda-panelists/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.panelists = item.panelists.filter((panel) => {
                        //debugger;
                        return panel.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },


     destroySurveyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };        
         axios.delete(constants.API_URL + '/api/survey/'+ id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    console.log("Item**",item);
                    return item._id != id
                }))
                return response;

                // commit('setSureyItems', state.surveyitems)
              //  commit('resetState');
               // commit('setNewSurvey', true)
                //commit('setIsSurveyNew',true)
               // commit('setSureyItems', state.surveyitems.filter((item) => {
                   // return item._id != id
               // }))
               
               // return response;

               // commit('setItem', response);               
            })
            .catch(error => {
                console.log(error)
            })
    },




    destroySurveyQuestion({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/survey-questions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.survey_questions = survey.survey_questions.filter((question)=>{
                            return question.id != id
                        })
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateAnswer({ commit, state, dispatch }, id) {
        commit('setLoading', true);
        const params = {

        }
        axios.put('/api/v1/qas/' + id, params)
            .then(response => {
                // commit('setAll', state.all.filter((item) => {
                //     return item.agenda_qas = item.agenda_qas.filter((qa) => {

                //         return qa.id != id
                //     })
                // }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIs_open({ commit }, value) {
        commit('setIs_open', value)
    },
     setSurveytitle({ commit }, value) {
        commit('setSurveytitle', value)
    }, 
    setNewSurvey({ commit }, value) {
        commit('setNewSurvey', value)
    }, 

    // setSureyItems({ commit }, item) {
    //     commit('setSureyItems', item)
    // },    
   
}

const mutations = {

    setSurveyItemsData(state, value) {        
        state.surveyitems = value
        
    },
    setIs_open(state, value) {        
        state.surveyitems.is_open = value
        
    },
    setNewSurvey(state, value) {        
        state.newSurvey = value
        
    },
    setSurveytitle(state, value) {  
        state.surveyitems.title = value
    },
   
    setLoading(state, loading) {
        state.loading = loading
    },
    setEventType(state, value){
        state.event_type = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEvent(state, items) {
        state.event = items
    },
    setLanguages(state, items) {
        state.languages = items
    },
    setModerator(state, items) {
        state.moderator = items
    },
    setExhibitor(state, items) {
        state.exhibitor = items
    },
    setSureyItems(state, items) {
        state.surveyitems = items
    },  
    setAll(state, items) {
        state.all = items
    },
    setIsSurveyNew(state,item){
        state.isSurveyNew = item
    }
   
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
