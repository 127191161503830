<template>
    <div class="row">

        <div class="col-md-5 event_detail_ls">
           
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Event Description</div>
                    <div class="col-md-8">{{ (presentation.description) }}</div>
                </div>
            </div>

            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Additional Features</div>
                    <div class="col-md-8">
                        <ul>
                            <li><span>No. Of Panel Discussions</span>:{{ (presentation.add_panel_discussions) }}</li>
                            <li><span>No. Of Presentations</span>:{{ (presentation.add_presentations) }}</li>
                            <li><span>No. Of On Demand</span>:{{ (presentation.add_presentations_on_demand) }}</li>
                            <li><span>No. Of Round Tables</span>:{{ (presentation.add_roundtable_session) }}</li>
                            <li><span>No. Of Meetings</span>:{{ (presentation.add_virtual_meeting) }}</li>
                            <li><span>No. Of Booths Type One</span>:{{ (presentation.booth_type_1) }}</li>
                            <li><span>No. Of Booths Type Two</span>:{{ (presentation.booth_type_2) }}</li>
                            <li><span>No. Of Booths Type Three</span>:{{ (presentation.booth_type_3) }}</li>
                        </ul></div>
                </div>
            </div>

            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Analytics and Reporting</div>
                    <div class="col-md-8">
                        {{presentation.event_analytics?"Yes":"No"}}
                        </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Reporting</div>
                    <div class="col-md-8">
                        {{presentation.event_analytics?"Yes":"No"}}
                        </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Is Active</div>
                    <div class="col-md-8">
                        {{presentation.status?"Yes":"No"}}
                        </div>
                </div>
            </div>


            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Subscribed On</div>
                    <div class="col-md-8">{{ formatPresentationDate(presentation.createdAt) }}</div>
                </div>
            </div>

            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-4">Valid until</div>
                    <div class="col-md-8">{{ formatPresentationDate(presentation.end_date) }}</div>
                </div>
            </div>
           


        </div>
       

      

    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
export default {
    name: "ShowItem",
    data() {
        return {
            speaker_name: null,
        }
    },
    props: {
       
        presentation: {
            type: Object,
            default() {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                }
            }
        },
       
       
    },

    methods: {
        ...mapActions('MyProfileIndex', ['fetchData', 'destroyData']),
        formatPresentationDate(date) {
            return moment(date).utc().format("LLLL");
            //return moment(date).format("LLLL");
        }
        
       
    },

}
</script>

<style lang="scss" scoped>
.user_img {
    img {
        width: 100%;
    }
}

.event_detail_wrapper {
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }

    .row {
        .col-md-8 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }

        .col-md-3 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }
    }

    .card-body {
        box-shadow: 0px 0px #ffffff;
    }
}

.tabs-vertical {
    border-top: 0px;
}

.toggle-content {
    background: #fff;
}
</style>
