<!-- src/components/BarChart.vue -->
<template>
    <div>
      <Bar id="chartRef" :chart-data="chartData" :options="chartOptions"></Bar>
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';
  
  ChartJS.register(BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'BarChart',
    props: {
      chartData: {
        type: Object,
        required: true
      },
      chartOptions: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    mounted() {
      
    }
  };
  </script>
  