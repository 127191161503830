import timeZoness from 'countries-and-timezones'
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import $ from "jquery";
import moment from "moment"

function initialState(id) {
    return {
        event_id    : null,
        create_dialog: false,
        exhibitors: [],
        lang_key: null,
        lang_title: null,
        lang_descirption: null,
        error_translation_tab :false,
        error_general_tab :false,
        time_zone:null,
      
    
        item: {
            time_zone:null,
            translations: [],
            speaker: null,
            exhibitor: null,
            start_time: null,
            end_time: null,
            is_restricted: false,
            minutes: 0,
            session_open: false,
            max_no_attendees: 0,
            attendees: [],
            is_rating: false,
            is_screen_sharing: false,
            is_live_streaming: false,
            is_schedule_status: false,
            stream_url: null,
            presentation_video: null,
            presentation_documents: null,
            screen_logo: null,
            sesssion_theme_logo: null,
            is_background_picture: false,
            background_color: '#ccc',
            background_picture: null,
            background_bottom_color: '#fff',
            notes_arc_color: '#fff',
            banner: null,
            interprefy: false,
            interprefy_token: null,
            aws_interpretation: false,
            aws_enable_speech_to_speech: null,
            aws_enable_speech_to_text: null,
            chat: false,
            created_by: null,
        },
        new_speaker: {
            salutation: null,
            email: null,
            first_name: null,
            last_name: null,
            event_id: "",
            is_verified:true
            
        },
        new_email_exists: null,
        create_speaker_btn_txt: 'Create NEW SPEAKER',
        loading: false,
        is_new: false,
        is_editing: false,

    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    newSpeaker: state => state.new_speaker,
    createSpeakerBtnTxt: state => state.create_speaker_btn_txt,
    newEmailExists: state => state.new_email_exists,
    eventID: state => state.event_id,
    createDialog: state => state.create_dialog,
    listExhibitors: state => state.exhibitors,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
    selectedLanguageKey: state => state.lang_key,
    selectedLanguageTitle: state => state.lang_title,
    selectedLanguageDescirption: state => state.lang_descirption,
    error_translation_tab       : state => state.error_translation_tab,
    error_general_tab           : state => state.error_general_tab,


}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    storeSpeakerData({ commit, state, dispatch }) {
        commit('setLoading', true)
        //dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            //By Default Create New speaker params set
            let url = `${constants.API_EVENT_USERS_URL}/api/v1/event-user/create/front`;
            //delete state.new_speaker.created_by;
            // state.new_speaker.event_id = `${state.event_id}`;
            let params = state.new_speaker;
            //If user already exists just need add event information in user
            if (state.new_email_exists === true) {
                url = `${constants.API_EVENT_USERS_URL}/api/v1/event-user/introduce/event`
                params = {
                    "email": `${state.new_speaker.email}`,
                    "event_id": `${state.event_id}`
                }
            }
            axios.post(`${url}`, params)
                .then(response => {
                    let UId = response.data.data._id
                    commit('setSpeaker', UId);
                    commit('setCreateDialog', false)
                    commit('setLoading', false)
                    //commit('resetState')
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },
    async storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');

        const params = _.cloneDeep(state.item);
        params.event_id = state.event_id
        delete params.time_zone;
     
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        console.log("PArams to save",params);

        return axios.post(constants.API_URL + '/api/round-table/', params, { headers: headers })
            .then((response) => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    checkEmailExists({ commit, state, dispatch }) {
        commit('setLoading', true)
        // dispatch('Alert/resetState', null, { root: true })
        return new Promise((resolve, reject) => {
            axios.post(`${constants.API_EVENT_USERS_URL}/api/v1/event-user/exists/email`, { email: state.new_speaker.email })
                .then(response => {
                    commit('setLoading', false)

                    let user = response.data.data;
                    if (_.isNull(user)) {
                        commit('setNewEmailExists', false);
                        commit('setSpeakerFirstName', null);
                        commit('setSpeakerLastName', null);
                        commit('setCreateSpeakerBtnTxt', "Create NEW SPEAKER");
                        resolve();
                    } else {
                        commit('setSpeakerSalutation', user.salutation);
                        commit('setSpeakerFirstName', user.first_name);
                        commit('setSpeakerLastName', user.last_name);
                        commit('setNewEmailExists', true);
                        commit('setCreateSpeakerBtnTxt', "ADD SPEAKER");
                        resolve();
                    }
                })
                .catch(error => {
                    commit('setLoading', false)

                    console.log("Error One", error);
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                });
        })
    },
    updateData({ commit, state, dispatch }) {


        commit('setLoading', true)
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');

        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.created_by;
        delete params.event_id;
        delete params.is_deleted;
        delete params.time_zone;
     
        _.map(params.translations, i => delete i._id);

        params.minutes = Number(params.minutes);

        if (_.isObject(params.speaker)) {
            params.speaker = _.get(params.speaker, '_id', null);

        }
        if (_.has(state.item.presentation_video, 'key')) {
            let imgPath = _.replace(state.item.presentation_video.key, '/draft', '');
            params.presentation_video = {
                key: imgPath,
                old_key: (!_.isNull(state.item.presentation_video.key) && !_.isNull(state.item.presentation_video.key.match(/\/draft\//g))) ? state.item.presentation_video.key : null,
                size: params.presentation_video.size,
                extension: params.presentation_video.extension,
                uploadedBy: params.presentation_video.uploadedBy,
                uploadedAt: params.presentation_video.uploadedAt,
            }
        }

        if (_.has(state.item.presentation_documents, 'key')) {
            let imgPath = _.replace(state.item.presentation_documents.key, '/draft', '');
            params.presentation_documents = {
                key: imgPath,
                old_key: (!_.isNull(state.item.presentation_documents.key) && !_.isNull(state.item.presentation_documents.key.match(/\/draft\//g))) ? state.item.presentation_documents.key : null,
                size: params.presentation_documents.size,
                extension: params.presentation_documents.extension,
                uploadedBy: params.presentation_documents.uploadedBy,
                uploadedAt: params.presentation_documents.uploadedAt,
            }
        }


        if (_.has(state.item.screen_logo, 'key')) {
            let imgPath = _.replace(state.item.screen_logo.key, '/draft', '');
            params.screen_logo = {
                key: imgPath,
                old_key: (!_.isNull(state.item.screen_logo.key) && !_.isNull(state.item.screen_logo.key.match(/\/draft\//g))) ? state.item.screen_logo.key : null,
                size: params.screen_logo.size,
                extension: params.screen_logo.extension,
                uploadedBy: params.screen_logo.uploadedBy,
                uploadedAt: params.screen_logo.uploadedAt,
            }
        }


        if (_.has(state.item.sesssion_theme_logo, 'key')) {
            let imgPath = _.replace(state.item.sesssion_theme_logo.key, '/draft', '');
            params.sesssion_theme_logo = {
                key: imgPath,
                old_key: (!_.isNull(state.item.sesssion_theme_logo.key) && !_.isNull(state.item.sesssion_theme_logo.key.match(/\/draft\//g))) ? state.item.sesssion_theme_logo.key : null,
                size: params.sesssion_theme_logo.size,
                extension: params.sesssion_theme_logo.extension,
                uploadedBy: params.sesssion_theme_logo.uploadedBy,
                uploadedAt: params.sesssion_theme_logo.uploadedAt,
            }
        }


        if (_.has(state.item.background_picture, 'key')) {
            let imgPath = _.replace(state.item.background_picture.key, '/draft', '');
            params.background_picture = {
                key: imgPath,
                old_key: (!_.isNull(state.item.background_picture.key) && !_.isNull(state.item.background_picture.key.match(/\/draft\//g))) ? state.item.background_picture.key : null,
                size: params.background_picture.size,
                extension: params.background_picture.extension,
                uploadedBy: params.background_picture.uploadedBy,
                uploadedAt: params.background_picture.uploadedAt,
            }
        }


        if (_.has(state.item.banner, 'key')) {
            let imgPath = _.replace(state.item.banner.key, '/draft', '');
            params.banner = {
                key: imgPath,
                old_key: (!_.isNull(state.item.banner.key) && !_.isNull(state.item.banner.key.match(/\/draft\//g))) ? state.item.banner.key : null,
                size: params.banner.size,
                extension: params.banner.extension,
                uploadedBy: params.banner.uploadedBy,
                uploadedAt: params.banner.uploadedAt,
            }
        }
      
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/round-table/' + state.item._id, params, { headers: headers })
            .then(response => {
                console.log(response)
                commit('setItem', response.data.data)
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, state, dispatch }) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        console.log("State", state.item);
        return axios.get(`${constants.API_URL}/api/events/${state.event_id}/exhibitors`, { headers: headers })
            .then(response => {
                let listExhibitors = [{ id: null, title: " Select Exhibitor" }];

                _.forEach(response.data.data, function (value, key) {
                    const translate = _.get(_.head(value.translations), 'name');
                    listExhibitors.push({ title: translate, id: value._id });
                });
                console.log("listExhibitors", listExhibitors);
                commit('setExhibitors', listExhibitors);
            })


    },
    setLocalTime({ commit, state, dispatch }){
        commit('setLocalTime', 'start_time');
        commit('setLocalTime', 'end_time');

    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setSpeaker({ commit }, value) {
        commit('setSpeaker', value)
    },
    setStartTime({ commit }, value) {
        commit('setStartTime', value)
    },
    setEndTime({ commit }, value) {
        commit('setEndTime', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    resetStateSpeaker({commit}){
        commit('resetStateSpeaker')
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },

    setSpeakerEmail({ commit }, value) {
        commit('setSpeakerEmail', value)
    },
    setSpeakerSalutation({ commit }, value) {
        commit('setSpeakerSalutation', value)
    },
    setSpeakerFirstName({ commit }, value) {
        commit('setSpeakerFirstName', value)
    },
    setSpeakerLastName({ commit }, value) {
        commit('setSpeakerLastName', value)
    },
    setCreateSpeakerBtnTxt({ commit }, value) {
        commit('setCreateSpeakerBtnTxt', value)
    },
    setEventTd({ commit }, value) {
        commit('setEventTd', value)
    },
    setNewEmailExists({ commit }, value) {
        commit('setNewEmailExists', value)
    },
    setSpeakerEventId({ commit }, value) {
        commit('setSpeakerEventId', value)
    },
    setSpeakerCretedBy({ commit }, value) {
        commit('setSpeakerCretedBy', value)
    },
    setCreateDialog({ commit }, value) {
        commit('setCreateDialog', value)
    },
    setExhibitors({ commit }, value) {
        commit('setExhibitors', value)
    },
    setExhibitor({ commit }, value) {
        commit('setExhibitor', value)
    },
    setIsRestricted({ commit }, value) {
        commit('setIsRestricted', value)
    },
    setMinutes({ commit }, value) {
        commit('setMinutes', value)
    },
    setIsRating({ commit }, value) {
        commit('setIsRating', value)
    },
    setIsScreenSharing({ commit }, value) {
        commit('setIsScreenSharing', value)
    },
    setIsLiveStreaming({ commit }, value) {
        commit('setIsLiveStreaming', value)
    },
    setStreamUrl({ commit }, value) {
        commit('setStreamUrl', value)
    },
    setScheduleStatus({ commit }, value) {
        commit('setScheduleStatus', value)
    },
    setPresentationVideo({ commit }, value) {
        commit('setPresentationVideo', value)
    },
    setPresentationDocuments({ commit }, value) {
        commit('setPresentationDocuments', value)
    },
    setScreenLogo({ commit }, value) {
        commit('setScreenLogo', value)
    },
    setSesssionThemeLogo({ commit }, value) {
        commit('setSesssionThemeLogo', value)
    },
    setIsBackgroundPicture({ commit }, value) {
        commit('setIsBackgroundPicture', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setNotesArcColor({ commit }, value) {
        commit('setNotesArcColor', value)
    },
    setSpeakerPicutre({ commit }, value) {
        commit('setSpeakerPicutre', value)
    },
    setPillarLeft1Logo1({ commit }, value) {
        commit('setPillarLeft1Logo1', value)
    },
    setBanner({ commit }, value) {
        commit('setBanner', value)
    },
    setInterprefy({ commit }, value) {
        commit('setInterprefy', value)
    },
    setInterprefyToken({ commit }, value) {
        commit('setInterprefyToken', value)
    },
    setAwsInterpretation({ commit }, value) {
        commit('setAwsInterpretation', value)
    },
    setAwsEnableSpeechToSpeech({ commit }, value) {
        commit('setAwsEnableSpeechToSpeech', value)
    },
    setAwsEnableSpeechToText({ commit }, value) {
        commit('setAwsEnableSpeechToText', value)
    },
    setChat({ commit }, value) {
        commit('setChat', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setCreatedBy({ commit }, value) {
        commit('setCreatedBy', value)
    },
    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescirption({ commit }, value) {
        commit('setLangDescirption', value)
    },
    setSessionOpen({ commit }, value) {
        commit('setSessionOpen', value)
    },
    setMaxNoAttendees({ commit }, value) {
        commit('setMaxNoAttendees', value)
    },
    setAttendees({ commit }, value) {
        commit('setAttendees', value)
    },
    setErrorTranslationTab({commit},value){
        commit('setErrorTranslationTab', value)
    },
    setErrorGeneralTab({commit},value){
        commit('setErrorGeneralTab', value)
    },
    setTimeZone({ commit }, value){
        commit('setTimeZone', value)
    },


}

const mutations = {
    setTimeZone(state,value) {
        console.log("valuevalue",value);
        state.item.time_zone = value
    },
    setErrorGeneralTab(state,value){
        state.error_general_tab = value
    },
    setErrorTranslationTab(state, value) {
        state.error_translation_tab = value
    },
    setItem(state, item) {
        state.item = item
    },
    setTitle(state, value) {
        state.item.agenda_title = value

    },
    setSpeaker(state, value) {
        state.item.speaker = value
    },
    setSlug(state, value) {
        state.item.event_slug = value

    },
    setStartTime(state, value) {
        state.item.start_time = value

    },
    setEndTime(state, value) {
        state.item.end_time = value

    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setTranslations(state, value) {
        console.log("tate.item.translations",state.item.translations,"value",value.key);
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;
    },
    resetStateSpeaker(state){
        state.new_speaker  = Object.assign(state.new_speaker, initialState());
    },
    setSpeakerEmail(state, value) {
        state.new_speaker.email = value;
    },
    setSpeakerSalutation(state, value) {
        state.new_speaker.salutation = value
    },
    setSpeakerFirstName(state, value) {
        state.new_speaker.first_name = value
    },
    setSpeakerLastName(state, value) {
        state.new_speaker.last_name = value
    },
    setNewEmailExists(state, value) {
        state.new_email_exists = value
    },
    setCreateSpeakerBtnTxt(state, value) {
        state.create_speaker_btn_txt = value
    },
    setEventTd(state, value) {
        state.event_id = value
    },
    setSpeakerEventId(state, value) {
        state.new_speaker.event_id = value
    },
    setSpeakerCretedBy(state, value) {
        state.new_speaker.created_by = value
    },
    setCreateDialog(state, value) {
        state.create_dialog = value
    },
    setExhibitors(state, value) {
        state.exhibitors = value
    },
    setExhibitor(state, value) {
        state.item.exhibitor = value
    },
    setIsRestricted(state, value) {
        state.item.is_restricted = value
    },
    setMinutes(state, value) {
        state.item.minutes = value
    },

    setIsRating(state, value) {
        state.item.is_rating = value
    },
    setIsScreenSharing(state, value) {
        state.item.is_screen_sharing = value
    },
    setIsLiveStreaming(state, value) {
        state.item.is_live_streaming = value
    },
    setStreamUrl(state, value) {
        state.item.stream_url = value
    },
    setScheduleStatus(state, value) {
        state.item.is_schedule_status = value
    },
    setIsBackgroundPicture(state, value) {
        state.item.is_background_picture = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setBackgroundColor(state, value) {
        state.item.background_color = value
    },
    setPresentationVideo(state, value) {
        state.item.presentation_video = value
    },
    setPresentationDocuments(state, value) {
        state.item.presentation_documents = value
    },
    setScreenLogo(state, value) {
        state.item.screen_logo = value
    },
    setSesssionThemeLogo(state, value) {
        state.item.sesssion_theme_logo = value
    },
    setSpeakerPicutre(state, value) {
        state.item.speaker_picutre = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_bottom_color = value
    },
    setNotesArcColor(state, value) {
        state.item.notes_arc_color = value
    },
    setBanner(state, value) {
        state.item.banner = value
    },
    setInterprefy(state, value) {
        state.item.interprefy = value
    },
    setInterprefyToken(state, value) {
        state.item.interprefy_token = value
    },
    setAwsInterpretation(state, value) {
        state.item.aws_interpretation = value
    },
    setAwsEnableSpeechToSpeech(state, value) {
        state.item.aws_enable_speech_to_speech = value
    },
    setAwsEnableSpeechToText(state, value) {
        state.item.aws_enable_speech_to_text = value
    },
    setChat(state, value) {
        state.item.chat = value
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setCreatedBy(state, value) {
        state.item.created_by = value
    },
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    },
    setLangDescirption(state, value) {
        state.lang_descirption = value
    },
    setLocation(state, value) {
        state.item.location = value
    },
    setSessionOpen(state, value) {
        state.item.session_open = value
    },
    setMaxNoAttendees(state, value) {
        state.item.max_no_attendees = value
    },
    setAttendees(state, value) {
        state.item.attendees = value
    },
    setUTCTime(state, type) {
        if (type == 'start_time') {
           // var m = moment.tz(state.item.start_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
            // convert it to utc
           // var utcTime = m.utc().format('YYYY-MM-DD HH:mm');

           let dateInTimezone = moment.tz(state.item.start_time, state.item.time_zone);

           // Step 2: Convert the moment object to UTC
           let dateInUTC = dateInTimezone.utc();
        
           // Step 3: Save the UTC time and the original timezone
           var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
    
        }
        if (type == 'end_time') {
         //  var m = moment.tz(state.item.end_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
          // var utcTime = m.utc().format('YYYY-MM-DD HH:mm');

          let dateInTimezone = moment.tz(state.item.end_time, state.item.time_zone);

          // Step 2: Convert the moment object to UTC
          let dateInUTC = dateInTimezone.utc();
    
          // Step 3: Save the UTC time and the original timezone
          var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
         
         
        }
        if (type == 'start_time') {
            state.item.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setUTCTimeX(state, type) {
        if (type == 'start_time') {
            var m = moment.tz(state.item.start_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
            // convert it to utc
            var utcTime = m.utc().format('YYYY-MM-DD HH:mm');
        }
        if (type == 'end_time') {
           var m = moment.tz(state.item.end_time, 'YYYY-MM-DD HH:mm', state.item.time_zone);
           var utcTime = m.utc().format('YYYY-MM-DD HH:mm');
         
        }
        if (type == 'start_time') {
            state.item.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setLocalTime(state, type) {
        console.log("state.item.time_zone",state.item.time_zone);
        console.log("state.item",state.item);
       
        if (type == 'end_time') {
           let dateFormat = moment.utc(state.item.end_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm:ss');
            state.item.end_time = dateFormat;
        }
        if (type == 'start_time') {
            let dateFormat = moment.utc(state.item.start_time).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
            state.item.start_time = dateFormat;
           
        }
    }

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
