<template>
<div class="container-fluid">
    <!--begin::Form-->

    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language
                    <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                    <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>

                </a>
            </li>
            <li class="nav-item">

                <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme
                    Settings {{ (this.error_theme_tab) ? 'error' : '' }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`" data-bs-toggle="tab">Interpretation Settings {{ (this.error_interpretation_tab) ? 'error' : '' }}</a>
            </li>
        </ul>

        <div class="tab-content">

            <div :id="`language-settings`" class="tab-pane active">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Language *</label>
                        <v-select name="language" class="" placeholder="Select language" label="language" @option:selected="updateLaguage" :options="languages" />
                        <ValidationMessages :element="v$.item.translations" />
                    </div>
                    <div class="col-lg-6">
                        <label>Title *</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="this.selectedLanguageTitle" @input="updateLanguageTitle" />
                        </div>
                        <ValidationMessages :element="v$.item.translations" />
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Description</label>
                            <div class="kt-input-icon">
                                <textarea rows="3" class="form-control" name="language_description" placeholder="Enter language description" :value="this.selectedLanguageDescirption" @input="updateLanguageDescription"></textarea>
                            </div>
                            <ValidationMessages :element="v$.item.translations" />
                        </div>
                    </div>
                </div>
            </div>
            <div :id="`general-settings`" class="tab-pane">
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label for="event_users">Moderator *</label>
                        <div class="kt-input-icon">
                            <v-select name="event_users" label="name" :options="moderator" track-by="id" :value="item.moderator" @option:selected="updateEventUserModerator" :reduce="(option) => option.id" class="" />
                            <ValidationMessages :element="v$.item.moderator" />

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="event_users">Second Moderator</label>
                        <div class="kt-input-icon">
                            <v-select name="event_users" label="name" :options="moderator" :modelValue="item.second_moderator" @option:selected="updateEventUserSecondModerator" :reduce="(option) => option.id" class="" />
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="exhibitor">Exhibitor</label>
                        <div class="kt-input-icon">
                            <v-select name="exhibitor" label="name" class="" :options="exhibitor" track-by="id" :value="item.exhibitor" @option:selected="updateExhibitor" :reduce="(option) => option.id" />

                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label for="start_time">Start Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            
                            <input
                            :min="getEventDatetime(event.start_date)" 
                            :max="getEventDatetime(event.end_date)" 
                            :value="getEventDatetime(item.start_time)" 
                            type="datetime-local"
                            class="form-control"
                             name="start_time" 
                             @change="updateStart_time" />
                            <span class="input-group-text rounded-0">from</span>
                        </div>
                        <ValidationMessages :element="v$.item.start_time" />
                    </div>
                    <div class="col-lg-6">
                        <label for="end_time">End Time *</label>
                        <div class="input-daterange input-group">
                            <span class="input-group-text">
                                <i class="fas fa-calendar-alt"></i>
                            </span>
                            <input 
                            type="datetime-local" 
                            class="form-control" 
                            name="end_time" 
                            :min="getEventDatetime(event.start_date)" 
                            :max="getEventDatetime(event.end_date)" 
                            :value="getEventDatetime(item.end_time)" 
                            @change="updateEnd_time" />
                            <span class="input-group-text rounded-0">to</span>
                        </div>
                        <ValidationMessages :element="v$.item.end_time" />
                        <div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Restrict User to Join before session time ?</label>
                        <div class="row">
                            <input-switch name="is_restricted" :value="item.is_restricted" class="custom-control-input" @change="updateIs_restricted" />
                        </div>
                    </div>
                    <div class="col-lg-6" v-show="item.is_restricted">
                        <label for="minutes">Minutes</label>
                        <div class="kt-input-icon">
                            <input type="number" class="form-control" min="0" name="minutes" placeholder="Enter minutes" :value="item.minutes" @input="updateMinutes" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-3">
                        <label>Enable Rating ?</label>
                        <div class="row">
                            <input-switch name="is_rating" :value="item.is_rating" class="custom-control-input" @change="updateIs_rating" />
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <label>Screen Sharing ?</label>
                        <div class="row">
                            <input-switch name="screen_sharing" :value="item.is_screen_sharing" class="custom-control-input" @change="updateScreenSharing" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <DropFiles :file="item.video" accept="video/mp4" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateVideos" @onRemoved="removeVideo" asset_type="video" @onError="videoErrCb">
                            <template #header>
                                <label>Presentation Video (Max size 500MB and format mp4.)</label>
                            </template>
                        </DropFiles>
                        <!-- <span class="error">{{ err_video }}</span> -->

                    </div>
                    <div class="col-lg-12" v-show="item.agenda_type != 'pre-recorded'">
                        <DropFiles :file="item.documents" accept="application/pdf" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateDocuments" @onRemoved="removeDocument" asset_type="documents" @onError="documentsErrCb">
                            <template #header>
                                <label for="documents">Presentation Document (Max size 30MB and format pdf.)</label>
                            </template>
                        </DropFiles>
                        <!-- <span class="error">{{ err_documents }}</span> -->
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Live Streaming ?</label>
                        <div class="row">
                            <input-switch name="is_live_stream" :value="item.is_live_streaming" class="custom-control-input" @change="updateIsLiveStream" />
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="item.is_live_streaming">
                        <label for="title">Stream URL</label>
                        <VTooltip style="display:inline-block">
                            <i class="fa fa-info-circle" />
                            <template #popper>
                                <p style="color:#000">Please insert the embedded url from live stream url.</p>
                                <img src="/admin_assets/img/live_stream_url.jpg" alt="img" />
                            </template>
                        </VTooltip>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="stream_url" placeholder="Enter Live Stream URL" :value="item.stream_url" @input="updateStreamUrl" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <DropFiles :file="item.screen_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="455X256" @onUploaded="updateLogo" @onRemoved="removeLogo" asset_type="documents" @onError="documentsErrCb">
                            <template #header>
                                <label>Screen Logo* (Size should be 455x256 pixel and format jpg.)</label>
                            </template>
                        </DropFiles>
                        <ValidationMessages :element="v$.item.screen_logo" class="mtb" />
                    </div>
                    <div class="col-lg-12">
                        <DropFiles :file="item.session_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="512X512" @onUploaded="updateSessionTheme" @onRemoved="removeSessionTheme" asset_type="documents" @onError="documentsErrCb">
                            <template #header>
                                <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                            </template>
                        </DropFiles>
                    </div>
                </div>
            </div>
            <div :id="`theme-settings`" class="tab-pane">

                <div class="row">

                    <div class="tabs tabs-vertical tabs-left">

                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item active" role="presentation">
                                <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`" data-bs-toggle="tab">Theme Contents</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" :data-bs-target="`#pillar_left_1`" :href="`#pillar_left_1`" data-bs-toggle="tab">Pillar Left 1</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" :data-bs-target="`#pillar_left_2`" :href="`#pillar_left_2`" data-bs-toggle="tab">Pillar Left 2</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" :data-bs-target="`#pillar_right_1`" :href="`#pillar_right_1`" data-bs-toggle="tab">Pillar Right 1</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" :data-bs-target="`#pillar_right_2`" :href="`#pillar_right_2`" data-bs-toggle="tab">Pillar Right 2</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div :id="`theme_content`" class="tab-pane active show" role="tabpanel">

                                <div class="snippet general-event-config-snippet">

                                    <div class="form-group row">
                                        <div class="col-lg-4">
                                            <label>Background Color or Picture ?</label>
                                            <br />
                                            <div class="switch switch-sm switch-primary">
                                                <!-- <input-switch name="is_background_picture" :value="item.is_background_picture" id="customSwitchesEx" class="custom-control-input" @change="UpdateIsBackgroundPicture" /> -->
                                                <input-switch name="is_background_picture" :value="item.is_background_picture" class="custom-control-input" @change="updateIsBackgroundPicture" />

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="item.is_background_picture">
                                        <div class="col-lg-12">

                                            <!--  New                     
                      <DropFiles :file="item.background_picture" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="1920X470" @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture" asset_type="background_picture" @onError="BackgroundPictureErrCb">
                            <template #header>
                                <label>Background Picture (Size should be 1920x470 pixel and format
                                    JPG.) </label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles> -->

                                            <DropFiles :file="validate_file(item.background_picture)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="1920X470" @onUploaded="updateBackgroundPictureAgenda" @onRemoved="removeBackgroundPictureAgenda" asset_type="background_picture" @onError="showCbError">
                                                <template #header>
                                                    <label>Background Picture (Size should be 1920x470 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/pd_bg.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>

                                            
                                        </div>
                                    </div>

                                    <div class="form-group row backgroundcolor_option" v-else>
                                        <div class="col-lg-6">
                                            <label>Background Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="background_color" :value="item.background_color" @input="updateBackgroundColor" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Background Stripes Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="background_stripes_color" :value="item.background_stripes_color" @input="updateBackgroundStripesColor" />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Stage Color 1</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="stage_color1" :value="item.stage_color1" @input="updateStageColor1" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Stage Color 2</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="stage_color2" :value="item.stage_color2" @input="updateStageColor2" />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Bottom Background Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="background_color_bottom" :value="item.background_color_bottom" @input="updateBackgroundColorBottom" />

                                            </div>
                                        </div>
                                        

                                    </div>

                                </div>

                            </div>
                            <div :id="`pillar_left_1`" class="tab-pane" role="tabpanel">
                                <p>Pillar Left 1</p>

                                <div class="snippet general-event-config-snippet">
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Pillar Header Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="left1_pillar_header_color" :value="item.left1_pillar_header_color" @input="updateLeft1PillarHeaderColor" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Pillar Bottom Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="left1_pillar_color_bottom" :value="item.left1_pillar_color_bottom" @input="updateLPillarColorBottom" />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">

                                            <DropFiles  @onError="showCbError" :file="item.pillar_left1_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo1" @onRemoved="removePillarLeft1Logo1" asset_type="pillar_left1_logo1" >
                                                <template #header>
                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_left1_logo1}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles   @onError="showCbError" :file="item.pillar_left1_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo2" @onRemoved="removePillarLeft1Logo2" asset_type="pillar_left1_logo2" >
                                                <template #header>
                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>

                                            <span class="error">{{ err_pillar_left1_logo2}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_left1_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo3" @onRemoved="removePillarLeft1Logo3" asset_type="pillar_left1_logo3" >
                                                <template #header>
                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_left1_logo3}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div :id="`pillar_left_2`" class="tab-pane" role="tabpanel">
                                <p>Pillar Left 2</p>

                                <div class="snippet general-event-config-snippet">
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Pillar Header Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="left2_pillar_color" :value="item.left2_pillar_color" @input="updateLeft2PillarColor" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Pillar Bottom Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="left2_pillar_color_bottom" :value="item.left2_pillar_color_bottom" @input="updateL2PCBottom" />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <DropFiles   @onError="showCbError" :file="item.pillar_left2_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo1" @onRemoved="removePillarLeft2Logo1" asset_type="pillar_left2_logo1" >
                                                <template #header>
                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_left2_logo1}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_left2_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo2" @onRemoved="removePillarLeft2Logo2" asset_type="pillar_left2_logo2" >
                                                <template #header>
                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_left2_logo2}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_left2_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo3" @onRemoved="removePillarLeft2Logo3" asset_type="pillar_left2_logo3" >
                                                <template #header>
                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_left2_logo3}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div :id="`pillar_right_1`" class="tab-pane" role="tabpanel">
                                <p>Pillar Right 1</p>

                                <div class="snippet general-event-config-snippet">
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Pillar Header Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="right1_pillar_color" :value="item.right1_pillar_color" @input="updateRight1PillarColor" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Pillar Bottom Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="right1_pillar_color_bottom" :value="item.right1_pillar_color_bottom" @input="updateRight1PillarColorBottom" />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_right1_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo1" @onRemoved="removePillarRight1Logo1" asset_type="pillar_right1_logo1" >
                                                <template #header>
                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right1_logo1}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_right1_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo2" @onRemoved="removePillarRight1Logo2" asset_type="pillar_right1_logo2" >
                                                <template #header>
                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right1_logo2}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_right1_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo3" @onRemoved="removePillarRight1Logo3" asset_type="pillar_right1_logo3" >
                                                <template #header>
                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right1_logo3}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div :id="`pillar_right_2`" class="tab-pane" role="tabpanel">
                                <p>Pillar Right 2</p>
                                <div class="snippet general-event-config-snippet">
                                    <div class="form-group row">
                                        <div class="col-lg-6">
                                            <label>Pillar Header Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="right2_pillar_color" :value="item.right2_pillar_color" @input="updateRight2PillarColor" />

                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>Pillar Bottom Color</label>
                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                            <div class="input-group color" data-plugin-colorpicker>
                                                <input type="color" class="form-control" name="right2_pillar_color_bottom" :value="item.right2_pillar_color_bottom" @input="updateRight2PillarColorBottom" />

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_right2_logo1" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo1" @onRemoved="removePillarRight2Logo1" asset_type="pillar_right2_logo1" >
                                                <template #header>
                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right2_logo1}}</span>
                                        </div>

                                        <div class="col-lg-12">
                                            <DropFiles  @onError="showCbError" :file="item.pillar_right2_logo2" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo2" @onRemoved="removePillarRight2Logo2" asset_type="pillar_right2_logo2" >
                                                <template #header>
                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right2_logo2}}</span>
                                        </div>

                                        <div class="col-lg-12">

                                            <DropFiles  @onError="showCbError" :file="item.pillar_right2_logo3" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo3" @onRemoved="removePillarRight2Logo3" asset_type="pillar_right2_logo3" >
                                                <template #header>
                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                </template>
                                                <template #tooltip>
                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                </template>
                                            </DropFiles>
                                            <span class="error">{{ err_pillar_right2_logo3}}</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div :id="`interpretify-settings`" class="tab-pane">
                <div class="form-group row">

                    <div class="col-lg-4">
                        <label>Enable "Interprefy" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.interpretation.is_interprefy_type_enabled" class="custom-control-input" @change="updateInterprefy" />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label>Interprefy token</label>
                        <div class="kt-input-icon">
                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter event title" :disabled="interprefy_disabled == true" @change="updateInterprefyToken" />
                        </div>
                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label>Enable "AWS" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.interpretation.aws_interpretation" id="customSwitchesEx" class="custom-control-input" @change="updateAwsInterpretation" />
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label>Enable "Speech to Speech"</label>
                        <div class="kt-input-icon">
                            <input-switch name="speech_to_speech" 
                            :value="item.interpretation.is_aws_speech_to_speech_enabled" id="enableSpeechToSpeech" 
                            class="custom-control-input" @change="updateAwsEnableSpeechToSpeech" 
                            :styleDisable="this.aws_interprefy_disabled" />
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label>Enable "Speech to Text"</label>
                        <div class="kt-input-icon">
                            <input-switch name="speech_to_text" :value="item.interpretation.is_aws_speech_to_text_enabled" id="enableSpeechToText" class="custom-control-input" @change="updateAwsEnableSpeechToText" :styleDisable="this.aws_interprefy_disabled" />
                        </div>
                    </div>
                </div>
                <div class="form-group rcontrollanguage_ow">

                    <div class="col-lg-4">
                        <label>Enable "Chat" Interpretation</label>
                        <br />
                        <div class="switch switch-sm switch-primary">
                            <input-switch name="interprefy" :value="item.chat" class="custom-control-input" @change="updateChat" />
                        </div>
                    </div>

                </div>

            </div>

            <div class="card-body text-right mt-3">
                <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>

            </div>

        </div>

    </div>

    <!-- <tabs :options="{ defaultTabHash: 'tab-0' }">
        <tab name="Language Title">

        </tab>

        <tab name="General Settings">

        </tab>

        <tab name="Theme Settings" >

        </tab>

        <tab name="Interpretation Settings">        

        </tab>

        <div class="card-body text-right mt-3">
          <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"  @click="submitForm()">Save</button>
        </div>
      </tabs> -->
      <FlashMessage group="pd_create" />
</div>

</template>

<script>
const maxVideoFileSize = 524288000; // Max file size
const maxPdfFileSize =  32096088;  // Max file size

import moment from 'moment';
import {
    gettingErrorMessage
} from "@/services/GetValidationMessage";
import {
    mapGetters,
    mapActions
} from "vuex";
import $ from 'jquery';
import InputSwitch from "@/components/Commons/InputSwitch";
import _ from "lodash";
import ObjectID from 'bson-objectid';
import DropFiles from "@/components/Commons/DropFiles";
import {
    v4 as uuidv4
} from 'uuid';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'
import {
    upload
} from "@/services/FileUploader";
export default {
    name: "PanelAgendaCreateForm",
    components: {
        InputSwitch,
        ValidationMessages
    },
    data() {
        return {
            moduleId: ObjectID(),
            translation: [],
            languageKey: '',
            languageTitle: '',
            languageDescription: '',
            interprefy_disabled: true,
            aws_interprefy_disabled: true,
            err_translations: null,
            error_translation_tab: false,
            error_general_tab: false,
            error_theme_tab: false,
            error_interpretation_tab: false,
            err_start_time: null,
            err_end_time: null,
            err_video: null,
            err_documents: null,
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            item: {

                translations: {
                    required
                },

                start_time: {
                    required,
                },
                end_time: {
                    required,
                },
                moderator: {
                    required,
                },
                screen_logo: {
                    required,
                }
            }
        };
    },

    props: {
        event: {
            type: Object
        },
        languages: {
            type: Array,
        }


    },
    created(){

    },
    mounted() {
        console.log("Event Logs*****",this.event);
        this.setEventTd(this.$route.params.event_id);
        this.checkPermission();
        let user = JSON.parse(localStorage.getItem('user'));
        this.setCreatedBy(`${user.first_name} ${user.last_name}`);
 
        this.updateTimeZone();
        this.setStart_time(this.event.start_date);
        this.setEnd_time(this.event.end_date);
    },
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.pd_create;
        // delete this.$flashMessage.groups.panel;
    },
    computed: {
        ...mapGetters('PanelAgendasIndex', [
            'all',
            'moderator',
            'exhibitor'
        ]),

       
        ...mapGetters('PanelAgendasSingle', [
            'item',
            'eventsAll',
            'loading',
            'selectedLanguageTitle',
            'selectedLanguageDescirption',
            'error_translation_tab',
            'error_general_tab',
            'selectedLanguageKey',
        ]),
        // ...mapGetters('EventLanguagesIndex', {languages: "all"}),
        ...mapGetters('EventUsersIndex', {
            eventUsers: "all"
        }),

    },
    methods: {
        ...mapActions("AuthenticationsIndex",["checkPanelDiscussionSubscrpitions"]),
   
        ...mapActions('PanelAgendasIndex', {
            fetchAllData: 'fetchData',
        }),

        ...mapActions('PanelAgendasSingle', [
            'setRight2PillarColorBottom',
            'setRight1PillarColorBottom',
            'setLeft2PillarColorBottom',
            'setLeft1PillarHeaderColor',
            'resetState',
            'storeData',
            'setErrorTranslationTab',
            'setErrorGeneralTab',
            'setTranslations',
            'setEvent_users',
            'setEvent_userss',
            'setExhibitor',
            'setStart_time',
            'setEnd_time',
            'setIs_restricted',
            'setMinutes',
            "setIs_rating",
            'setScreenSharing',
            'setVideos',
            'setDocuments',
            'setIsLiveStream',
            'setStreamUrl',
            'setLogo',
            'setSessionTheme',
            'setIsBackgroundPicture',
            'setBackgroundColor',
            'setBackgroundStripesColor',
            'setStageColor1',
            'setStageColor2',
            'setBackgroundColorBottom',
            'setLeft1PillarColor',
            'setLeft1PillarBottomColor',
            'setPillarLeft1Logo1',
            'setPillarLeft1Logo2',
            'setPillarLeft1Logo3',
            'setLeft2PillarColor',
            'setLeft2PillarBottomColor',
            'setPillarLeft2Logo1',
            'setPillarLeft2Logo2',
            'setPillarLeft2Logo3',
            'setRight1PillarColor',
            'setRight1PillarBottomColor',
            'setPillarRight1Logo1',
            'setPillarRight1Logo2',
            'setPillarRight1Logo3',
            'setRight2PillarColor',
            'setRight2PillarBottomColor',
            'setPillarRight2Logo1',
            'setPillarRight2Logo2',
            'setPillarRight2Logo3',
            'setEventTd',
            'setCreatedBy',
            'setInterprefy',
            'setInterprefyToken',
            'setAwsInterpretation',
            'setAwsEnableSpeechToSpeech',
            'setAwsEnableSpeechToText',
            'setChat',
            'fetchData',
            'setLangKey',
            'setLangTitle',
            'setLangDescirption',
            'setBackgroundPicture',
            'setIsNew',
            'setTimeZone'
        ]),
        updateTimeZone(){
           this.setTimeZone(this.event.time_zone)
        },

        validate_file(fileInfo) {
            if (_.has(fileInfo, 'key'))
                return fileInfo;
            return null;
        },

        cancelClick() {
            this.resetState();
        },

        showFlashMessage(message) {
            this.flashMessage = message;
            this.snackbar = true;
        },

        fileLoad(e) {
            const file = e.target.files[0];
            const id = uuidv4();
            const ff = upload(file, id, "users", null, file.type);
            ff.then((resp) => {
                console.log(resp)
            }).catch((e) => {
                console.error(e)
                this.$flashMessage.show({
                    group: 'pd_create',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong.'
                });
            })
        },

        updateLaguage(value) {
            this.err_translations = null;
            this.setLangKey(value);
            this.languageKey = value;
            this.setLangTitle(null);
            this.setLangDescirption(null);
            if (_.isUndefined(_.find(this.translation, {
                    'key': value,
                }))) {
                let obj = {
                    "key": `${value}`,
                    "title": null,
                    "description": null
                }
                this.translation.push(obj);
            } else {
                let selectedLang = _.find(this.item.translations, {
                    'key': this.selectedLanguageKey,
                })
                if (!_.isUndefined(selectedLang)) {
                    this.setLangTitle(selectedLang.title);
                    this.setLangDescirption(selectedLang.description);
                }
            }

        },

        updateLanguageTitle(e) {
            this.err_translations = null;
            if (!_.isNull(this.languageKey) && !_.isEmpty(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'title',
                    update_value: e.target.value
                });

            }
            this.setLangTitle(e.target.value);
        },
        updateLanguageDescription(e) {
            if (!_.isNull(this.languageKey)) {
                this.setTranslations({
                    key: this.languageKey,
                    update_key: 'description',
                    update_value: e.target.value
                });
            }
            this.setLangDescirption(e.target.value);
        },
        getLanguageTitle() {
            if (this.languageKey === null) {
                return ''
            }

            return _.get(_.find(this.item.translations, i => i.key === this.languageKey._id), 'title', '');
        },
        getLanguageDescription() {
            if (this.languageKey === null) {
                return ''
            }

            return _.get(_.find(this.item.translations, i => i.key === this.languageKey._id), 'description', '');
        },
        updateEventUserModerator(value) {
            this.setEvent_users(value.id);
        },
        updateExhibitor(value) {
            console.log(value.id, 'exhibitor id')
            this.setExhibitor(value.id);
        },
        updateEventUserSecondModerator(value) {
            this.setEvent_userss(value.id);
        },
        documentsErrCb(errList) {
            this.err_documents = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },

        updateStart_time(e) {
            this.err_start_time = null;
            this.setStart_time(e.target.value)
        },
        updateEnd_time(e) {
            this.err_end_time = null;
            this.setEnd_time(e.target.value)
        },
        updateIs_restricted(e) {
            this.setIs_restricted(e.target.checked)
        },
        updateMinutes(e) {
            this.setMinutes(e.target.value)
        },
        updateIs_rating(e) {
            this.setIs_rating(e.target.checked)
        },
        updateScreenSharing(e) {
            this.setScreenSharing(e.target.checked)
        },
        updateVideos(info, data) {
            this.err_video = null;
            const extension = data.data.Key.split('.').pop()
            const key = _.get(this.item.videos, 'key', null);
            const size = _.get(info, 'file.size', 0)
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
              }

            this.setVideos({
                old_key : data.data.Key,
                temp_key: data.data.Key,
                key     : data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeVideo() {
            this.err_video = null;
            this.setVideos(null);
        },
        updateDocuments(info, data) {
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key   = _.get(this.item.documents, 'key', null);
            const size  = _.get(info, 'file.size', 0);
           
           if(size>maxPdfFileSize){
                this.showCbError();
                return;
            }

            this.setDocuments({
                old_key     : data.data.key?data.data.key:data.data.Key,
                temp_key    : data.data.key?data.data.key:data.data.Key,
                key         : data.data.key?data.data.key:data.data.Key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeDocument() {
            this.err_documents = null;
            this.setDocuments(null)
        },
        updateIsLiveStream(e) {
            this.setIsLiveStream(e.target.checked)
        },
        updateStreamUrl(e) {
            this.setStreamUrl(e.target.value)
        },
        updateLogo(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.screen_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setLogo({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeLogo() {
            this.setLogo(null)
        },
        updateSessionTheme(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.session_theme, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setSessionTheme({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: data.data.key,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeSessionTheme() {
            this.setSessionTheme(null)
        },
        updateIsBackgroundPicture(e) {
            this.setIsBackgroundPicture(e.target.checked)
        },
        updateBackgroundPictureAgenda(info, data) {
           
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.background_picture, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setBackgroundPicture({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeBackgroundPictureAgenda(e) {
            this.setBackgroundPicture(null)
        },
        updateBackgroundColor(e) {
            this.setBackgroundColor(e.target.value)
        },
        updateBackgroundStripesColor(e) {
            this.setBackgroundStripesColor(e.target.value)
        },
        updateStageColor1(e) {
            this.setStageColor1(e.target.value)
        },
        updateStageColor2(e) {
            this.setStageColor2(e.target.value)
        },
        updateBackgroundColorBottom(e) {
            this.setBackgroundColorBottom(e.target.value)
        },
        updateLeft1PillarColor(e) {
            this.setLeft1PillarColor(e.target.value)
        },
        updateLeft1PillarBottomColor(e) {
            this.setLeft1PillarBottomColor(e.target.value)
        },
        updatePillarLeft1Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo1({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo1() {
            this.setPillarLeft1Logo1(null)
        },
        updatePillarLeft1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo2({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo2() {
            this.setPillarLeft1Logo2(null)
        },
        updatePillarLeft1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo3({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo3() {
            this.setPillarLeft1Logo3(null)
        },
        updateLeft2PillarColor(e) {
            this.setLeft2PillarColor(e.target.value)
        },
        updateLeft2PillarBottomColor(e) {
            this.setLeft2PillarBottomColor(e.target.value)
        },
        updatePillarLeft2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo1({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo1() {
            this.setPillarLeft2Logo1(null)
        },
        updatePillarLeft2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo2({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo2() {
            this.setPillarLeft2Logo2(null)
        },
        updatePillarLeft2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo3({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo3() {
            this.setPillarLeft2Logo3(null)
        },
        updateRight1PillarColor(e) {
            this.setRight1PillarColor(e.target.value)
        },
        updateRight1PillarBottomColor(e) {
            this.setRight1PillarBottomColor(e.target.value)
        },
        updatePillarRight1Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo1({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo1() {
            this.setPillarRight1Logo1(null)
        },
        updatePillarRight1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo2({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo2() {
            this.setPillarRight1Logo2(null)
        },
        updatePillarRight1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo3({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo3() {
            this.setPillarRight1Logo3(null)
        },
        updateRight2PillarColor(e) {
            this.setRight2PillarColor(e.target.value)
        },
        updateRight2PillarBottomColor(e) {
            this.setRight2PillarBottomColor(e.target.value)
        },

        updateRight2PillarColorBottom(e) {
            this.setRight2PillarColorBottom(e.target.value);
        },
        updateInterprefy(e) {
            this.interprefy_disabled = true;
            if (e.target.checked) {
                this.interprefy_disabled = false;
                this.setAwsInterpretation(false)
                this.aws_interprefy_disabled =true;
                this.setAwsEnableSpeechToSpeech(false);
                this.setAwsEnableSpeechToText(false);
            }
            this.setInterprefy(e.target.checked);
        },
        
        updateInterprefyToken(e) {
            this.setInterprefyToken(e.target.value);
        },
        updateAwsInterpretation(e) {

           this.setInterprefy(false);//
           this.interprefy_disabled = true;//
          

            this.aws_interprefy_disabled = true;
            if (e.target.checked) {
                this.aws_interprefy_disabled = false;
            }

            this.setAwsInterpretation(e.target.checked);
        },
        updateAwsEnableSpeechToSpeech(e) {
            this.setAwsEnableSpeechToSpeech(e.target.checked);
        },
        updateAwsEnableSpeechToText(e) {
            this.setAwsEnableSpeechToText(e.target.checked);
        },

        updateL2PCBottom(e) {
            this.setLeft2PillarColorBottom(e.target.value);
        },

        updateChat(e) {
            this.setChat(e.target.checked);
        },
        updatePillarRight2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo1({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo1() {
            this.setPillarRight2Logo1(null)
        },
        updatePillarRight2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo2({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo2() {
            this.setPillarRight2Logo2(null)
        },
        updatePillarRight2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo3({
                old_key: data.data.key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo3() {
            this.setPillarRight2Logo3(null)
        },

        getModeratorId(moderator) {
            return _.has(moderator, '_id') ? moderator._id : moderator;
        },

        getExhibitorId(exhibitor) {

            return _.has(exhibitor, '_id') ? exhibitor._id : exhibitor;
        },

        getLanguageName(exhibitor) {

            const first_lang = _.head(exhibitor.translations);
            return _.get(first_lang, 'name', '');
        },
        showCbError() {
            this.$flashMessage.show({
                group   : 'pd_create',
                type    : 'error',
                title   : 'Failed',
                time    : 1000,
                html    : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
            });
        },

        videoErrCb(errList) {
            this.err_video = _.toString(_.map(errList[0]['errors'], 'message'));
            this.showCbError();
        },

        updateLeft1PillarHeaderColor(e) {
            console.log('header color', e.target.value)
            this.setLeft1PillarHeaderColor(e.target.value);
        },


        updateLPillarColorBottom(e) {
            // console.log('coooooloooor',e.target.value)
            this.setLeft1PillarBottomColor(e.target.value)
        },

        updateRight1PillarColorBottom(e) {
            this.setRight1PillarColorBottom(e.target.value);
        },

        async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 character long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 character long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },

        async submitForm() {

            const isFormCorrect = await this.v$.$validate();
            let validateTranlation = await this.checkLanguageValidation();
            this.error_general_tab = false;
            this.error_translation_tab = false;
            this.error_theme_tab = false;
            this.error_interpretation_tab = false;

            let generalError = []; 
            _.map(this.v$.$errors, (item) => {
                console.log(item);
                
              if(item.$property == "moderator"){
                generalError.push("<li>Moderator is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }
            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
           if (!_.isEmpty(validateTranlation)) {
      
             this.error_translation_tab = true;
             this.setErrorTranslationTab(true);
             this.$flashMessage.show({
                 group: 'pd_create',
                 type: 'error',
                 time:1000,
                 title: 'Failed',
                 html: `<ul>${validateTranlation.join("")}</ul>`
             });
                return ;
             }          
            } 

            if (!isFormCorrect ) {
                
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'pd_create',
                        type    : 'error',
                        time    : 1000,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }

            

            this.storeData()
                .then(async (resp) => {  
                   

                   
                    this.fetchAllData(this.$route.params.event_id);
                     this.resetState();
                    this.$flashMessage
                        .show({
                            group   : 'panel',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,                                           
                            html    : '<ul><li>Panel Discussion has been created successfully.</li></ul>',
                        });
                       
                   // console.log('behlool',this.$route.params.event_id);
                        
                       
                       
                        
                    

                }).catch(error => {                   
                    let html = gettingErrorMessage(error);
                    // if (error.response.data.data.error) {                       
                        this.$flashMessage.show({
                            group: 'pd_create',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    // }

                });
        },
        checkPermission(){
            this.checkPanelDiscussionSubscrpitions(this.$route.params.event_id)
            .then((res)=>{
            }) .catch((error) => {
                console.log("Error",error);
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group : 'panel',
                    type  : 'error',
                    title : 'Error',
                    time  : 1500,
                    text  : html,
                    html  : html
                });

                this.setIsNew(false);
                this.fetchAllData(this.$route.params.event_id);
            
                    
            });
        },
        getEventDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm');
            //return moment.utc(datetime).tz(this.event.time_zone).format('YYYY-MM-DD HH:mm');
   
        }
    }
}
</script>

<style lang="scss">
.tab_error {
    color: red;
}

.mtb {
    margin-top: -20px;
    margin-bottom: 20px;
}

._vue-flash-msg-body._vue-flash-msg-body_error{
    z-index: 1000;
}

.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
    position: absolute;
    top: -53px;
    left: 50px;
    width: 300px;
    height: auto;
}
</style>
