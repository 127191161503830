import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            type:"tag",                       
            title: null,
            url: null,
            description: null,          
        },       
        is_new: false,
        is_editing: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
  
}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },

    async storeData({ commit, state }, event_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

       
        delete params.image;
      
       
        params.event_id = event_id;

       
        console.log("params after",params);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       
        return axios.post(constants.API_URL + '/api/event-cms', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
               
            }).finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state }, event_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.is_open_new_window;

       

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        return axios.patch(constants.API_URL + '/api/event-cms/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       
        return axios.delete(constants.API_URL + '/api/event-cms/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                commit('setItem', state.item.filter((item) => {
                    return item.id != id
                }))



                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    fetchData({ commit, dispatch }, {id}) {
        console.log("Working hit");
       
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
       
        return axios.get(constants.API_URL + '/api/event-cms/tag/' + id, {headers: headers})
        //return axios.get(constants.API_URL + '/api/events/' + event_id + '/sponsors/' + id, {headers: headers})
            .then(response => {
                commit('setItem', _.get(response, 'data.data', {}));
                return response;
            })
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setLink({ commit }, value) {
        commit('setLink', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setLogo({ commit }, value) {
        commit('setLogo', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setCategory({ commit }, value) {
        commit('setCategory', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setName(state, value) {
        state.item.title = value
    },
    setLink(state, value) {
        state.item.url = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setLogo(state, value) {
        state.item.image = value
    },
    setCategory(state, value) {
        state.item.category = value
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    setCategoryEnum(state, value) {
        state.categoryEnum = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
