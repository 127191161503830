<template>
  <div class="roles-form-create">
    <form autocomplete="off" novalidate @submit.prevent="submitForm">
      <div class="header_edit">
        <h1>Create Role
          <span @click="modalClose" class="btn" style="float: right;">X</span>
        </h1>
      </div>
      <div class="form-group">
        <div class="col-lg-12">
          <label for="permission">Title *</label>
          <input type="text" name="permission" id="permission" class="form-control" @change="changeName">
          <ValidationMessages :element="v$.item.title" />
        </div>
      </div>
      <div class="form-group">
        <div class="col-lg-12">
          <label for="permission">Access Level *</label>
          <select name="role" @change="changeAccessLevel" class="form-control">
            <option value="platform">Platform</option>
            <option value="event">Event</option>
          </select>
        </div>
      </div>
      <div class="form-group mt-4">
        <div class="col-lg-12">
          <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
        </div>
      </div>
    </form>
    <FlashMessage group="roles_create" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
  strin
} from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
export default {
  name: "RoleCreateForm",
  components: {
    ValidationMessages
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      item: {
        title: {
          required,
        }
      }
    };
  },
  created() {
    this.fetchAllPermissions()
      .catch(() => {
        this.$flashMessage.show({
          group: 'roles_create',
          type: 'error',
          title: 'Failed',
          text: 'Something went wrong'
        });
      });
  },
  computed: {
    ...mapGetters('RolesSingle', ['item']),
    ...mapGetters('PermissionsIndex', ['all']),
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.roles_create;
  },
  methods: {
    ...mapActions('RolesSingle', ['storeData', 'setName', 'setPermission', 'setAccessLevel']),
    ...mapActions('RolesIndex', { fetchAllData: 'fetchData' }),
    ...mapActions('PermissionsIndex', { fetchAllPermissions: "fetchData" }),
    changeName(e) {
      this.setName(e.target.value)
    },
    changeAccessLevel(e) {
      this.setAccessLevel(e.target.value);
    },

    modalClose: function (isNew) {
      this.$emit('close-modal');
    },

    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) return

      this.storeData().then(() => {
        this.fetchAllData();
        this.$flashMessage.show({
          group : 'roles',
          type  : 'success',
          title : 'Success',
          time  : 1000,
          html  : '<ul><li>Role created successfully.<li></ul>'
        });
        this.$emit('onChanged');
      }).catch((error) => {
        let errorsHtml = '';
        if (!_.has(error.response.data.data, 'error')) {
          errorsHtml += `<li>Role already exists</li>`
        } else {
          let errorsArr = error.response.data.data.error;
          _.forEach(errorsArr, function (value, key) {
            errorsHtml += `<li>${value.message.replace(/_/g, " ")}</li>`
          });
        }

        this.$flashMessage.show({
          group : 'roles_create',
          type  : 'error',
          time  : 1000,
          title : 'Error',
          html  : '<ol>' + errorsHtml + '</ol>'
        });
      })


    },
  },
}
</script>

<style scoped lang="scss">
.roles-form-create {
  margin: 70px auto;
  width: 100%;
  max-width: 400px;

  form {
    background: #fff;
    min-height: 100%;
    box-shadow: -1px 1px 5px 1px #bdbaba;
    padding-bottom: 18px;
    min-width: 400px;

    button[type=submit],
    .remove-btn {
      width: 100%;
      margin: 10px 0;
    }

    .form-group {
      padding: 5px 25px;
    }

    label {
      font-weight: bold;
    }

    h1 {
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid #ebecef;
      background: #158fe3;
      color: #fff;
    }
  }
}
</style>