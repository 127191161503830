<template>
  <div class="row">
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-4">Meeting Name</div>
          <div class="content_label_text col-md-10" >
            
            {{ item.translations[0]['title'] }}  
          </div>
        </div>
      </div>
     
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Description</div>
          <div class="col-md-8 col-md-10">
            <div v-html="getDescription(item)" />
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mt-3 mr-5">
      <button type="button" v-if="can('meetings_edit',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(item)">Edit</button>
      <button type="button" v-if="can('meetings_delete',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(item)">Delete</button>
    </div>
    <div class="col-12">
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import { isPermits} from "@/services/CheckPermissions";

import _ from "lodash";
export default {
  name: "ShowItem",
  components: {},
  data() {
    return {
      
    }
  },
  props: {
    item: {
      type: Object,
    },
    checkPermssions:null
  },

  methods: {
    ...mapActions('MeetingRoomsIndex', ['fetchData', 'destroyData']),
    ...mapActions('MeetingRoomsSingle', ['setItem', 'setIsNew', 'setIsEditing','setMeetingId', 'setLangKey', 'setLangTitle', 'setLangDescirption']),
    deleteData(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
       }).then(result => {
                if (result.isConfirmed) {
                    this.destroyData(item._id).then(() => {
                        this.$flashMessage.show({
                        group: 'meetings_index',
                        type: 'success',
                        title: 'Success',
                        text: 'Meeting Discussion has been deleted successfully.'
                      });

                      this.fetchData(this.$route.params.event_id);
                      this.setIsNew(false);
                      this.setIsEditing(false);
                    })
                }
            });
    },



    editData(item) {
      if (!_.isEmpty(item.translations)) {
                let defaultLanguage = item.translations[0];
                if (_.has(defaultLanguage, 'key')) {
                    this.setLangKey(defaultLanguage['key'])
                }
                if (_.has(defaultLanguage, 'title')) {
                    this.setLangTitle(defaultLanguage['title'])
                }
                if (_.has(defaultLanguage, 'description')) {
                    this.setLangDescirption(defaultLanguage['description'])
                }
            }
      this.setItem(item);
      this.setIsNew(false);
      this.setIsEditing(true);
     // this.$forceUpdate();

    },
    getDescription(agenda) {
      const first_lang = _.head(agenda.translations);
      return _.get(first_lang, 'description', '');
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  },

}
</script>

<style lang="scss" scoped>
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}

.tabs-vertical {
  border-top: 0px;
}

.toggle-content{
  background:#fff;
}
</style>
