<template>
    <GeneralDashboardLayout>
        <template v-slot:header>
            <div class="header-template">
                <BackButton :to="{ name: 'dashboard.index' }" />
                <h1 class="title text-center">Update Password</h1>
                
            </div>
        </template>
        <template v-slot:content>
            <div class="container-fluid user-container">
                <div class="row">
                    <div class="col-lg-12">
                        <div id="round-tables">
                            <OverlayLoading :isLoading="loading" />
                            <div class="snippet general-event-config-snippet">
                                <div class="form-group row">
                                    
                                    <div class="col-lg-6">
                                        <label>Current Password *</label>

                                        <div class="kt-input-icon">
                                            <input :value="item.current_password" type="password" class="form-control" name="current_password" maxlength="200"
                                                placeholder="Enter Current Password" 
                                                @input="updateCurrentPassword" />
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="form-group row">
                                    
                                    <div class="col-lg-6">
                                        <label>New Password *</label>

                                        <div class="kt-input-icon">
                                            <input type="password" class="form-control" name="new_password" maxlength="200" :value="item.new_password"
                                                placeholder="Enter New Password" 
                                                @input="updateNewPassword" />
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="form-group row">
                                    
                                    <div class="col-lg-6">
                                        <label>Confirm New Password *</label>

                                        <div class="kt-input-icon">
                                            <input :value="item.confirm_password" type="password" class="form-control" name="confirm_new_password" maxlength="200"
                                                placeholder="Enter Confirm New Password" 
                                                @input="updateNewConfirmPassword" />
                                        </div>
                                       
                                    </div>
                                </div>

                                <div class="card-body text-right mt-3 mr-5">
                                    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Update Password</button>
                                    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      
                                </div>


                            </div>
                           
                        </div>

                    </div>
                </div>
                <FlashMessage group="password_change_index" />
            </div>
        </template>
    </GeneralDashboardLayout>
</template>

<script>
import { mapGetters,mapActions} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/MyProfile/Forms/ShowProfile";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { useVuelidate } from '@vuelidate/core'
import { required, email} from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import { gettingErrorMessage } from "@/services/GetValidationMessage";


export default {
    name: 'MyProfileIndex',
    components: {
        BackButton,
        GeneralDashboardLayout
    },
    data() {
        return {
            search: null,
            perPage: 5,
            system_users: [],
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    watch: {
        all(items) {
            this.system_users = _.filter(items, i => i.role === 'admin');
        }
    },
    validations() {
        return {
            item: {
                current_password: {required},
                new_password: {required},
                confirm_password: {required}
               
            }
        };
    },
    created() {
       
    },
    beforeUnmount() {
        this.resetState();
        delete this.$flashMessage.groups.password_change_index;
    },
    computed: {
        ...mapGetters('ChangePasswordIndex', ['loading','item']),
    },
    methods: {
        ...mapActions('ChangePasswordIndex', [
            'storeData',
            'setCurrentPassword',
            'setNewPassword',
            'setConfirmNewPassword',
            'resetState'
        ]),
        updateCurrentPassword(e) {
            this.setCurrentPassword(e.target.value);
        },
        updateNewPassword(e) {
            this.setNewPassword(e.target.value);
        },
        updateNewConfirmPassword(e) {
            this.setConfirmNewPassword(e.target.value);
        },
        async cancelClick(){
            this.$router.back()
        },
        async submitForm() {
            const isFormCorrect = await this.v$.$validate();
            let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "current_password"){
                generalError.push("<li>Current Password is required</li>");
              }
              if(item.$property == "new_password"){
                generalError.push("<li>New Password is required</li>");
              }
              if(item.$property == "confirm_password"){
                generalError.push("<li>Confirm New Password is required</li>");
              }
            });
            if(generalError.length>0){
                this.$flashMessage.show({
                      group : 'password_change_index',
                      type  : 'error',
                      time  :1000,
                      title : 'Failed',
                      html  : `<ul>${generalError.join("")}</ul>`
                  });
                return ;
            }

            this.storeData()
                .then(() => {
                    this.$flashMessage.show({
                        group   :   'password_change_index',
                        type    :   'success',
                        title   :   'Successfully Updated Password',
                        time    :   1200,
                        html    :   '<ul><li>New password has been updated successfully.</li></ul>'
                    });
                    this.resetState();
                })
                .catch((error) => {
                    console.log("Error***",error);
                    let html =  gettingErrorMessage(error);
                    this.$flashMessage.show({
                        group: 'password_change_index',
                        type: 'error',
                        title: 'Error',
                        html: '<ul>' + html + '</ul>'
                    });
                })
        },
        
    }
     
        
}
</script>

<style lang="scss" scoped>
.header-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title {
        font-size: 1.6rem;
    }
}

.user-container {
    .toggle label {
        color: #000 !important;
        border-radius: 0px;

    }

    .toggle.active>label {
        color: #fff !important;
    }

    .toggle section {
        // box-shadow: -1px 0px 5px 2px #ccc;
        border-bottom: 1px solid #ccc;
    }

    .toggle-content {
        padding: 35px;

    }

    .form-control {
        font-family: 'Poppins';
    }

    .desc_btn_wrapper {
        margin-top: 20px;
    }

    .snippet {
        border-radius: 0.2rem;
        border: 1px solid #c9c9c9;
        padding: 24px 22px;
        margin: 15px 0;
        background-color: white;
    }

    .snippet .btn-light {
        color: #000 !important;
    }

    .edit_panel {
        background: #d5e3eb;
        padding: 15px;
        margin-top: 10px;
    }

    .snippet {
        margin-top: 0px;
    }

    .note-editor .btn-light {
        color: #000 !important;
    }

    .setting_wrapper {
        float: right;
    }

    .setting_wrapper a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;

    }

    .labelwrapper {
        width: 100%;
    }

    .labelhdwrapper {
        width: 100%;
        margin-left: 0px;
        border: 1px solid #ccc;
        padding: 10px;
        background: #e1e1e1;
    }

    .labelhdwrapper span {
        font-weight: bold;
    }

    .col1 {
        width: 25%;
        display: inline-flex;
    }

    .col2 {
        width: 25%;
        display: inline-flex;
    }

    .col3 {
        width: 25%;
        display: inline-flex;
    }

    .col4 {
        width: 20%;
        display: inline-flex;
    }

    .search_bar {
        margin-bottom: 20px;
    }

    .eventlabel {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .event_item {
        color: #fff;
        padding: 8px 15px;
        border-radius: 8px;
        background: #afbcc3;
        min-width: 50px;
        display: inline-flex;
        margin: 5px;
    }

    .nav-link.active {
        border-top-color: #0088CC;
    }

    .toggle.active>label {
        background: #0a72e0;
    }

    .nav-tabs li.active a {
        color: #fff !important;
        background: #0a72e0 !important;
        font-size: 1.2em;
    }

    .nav-tabs li a {
        font-size: 1.2em;
        color: #333;
    }

    .toggle>label {
        background: none !important;
        font-size: 1em !important;
    }

    .toggle .toggle {
        margin: 0px;
    }

    .toggle {
        margin: 0px;
    }

    .toggle_odd {
        background: #f5f5f5;
    }

    .btn-primary {
        color: #fff !important;
        background-color: #0c78e0 !important;

    }

    .nav-tabs li .nav-link,
    .nav-tabs li .nav-link:hover {
        background: #cccccc94 !important;
    }

    .pageview {
        font-family: 'Poppins';
        font-size: 1em !important;
    }

    .w-70 {
        width: 30%;
        display: inline-flex;
    }

    .rpg_txt {
        display: inline-block;
        margin-left: 10px;
    }

    .nav-tabs li .nav-link,
    .nav-tabs li {
        border-right: 1px solid #EEE;
    }

    .toggle_odd {
        .event_detail_wrapper {
            .row {
                .col-md-8 {
                    border-bottom: 0px dotted #ccc;
                    padding-bottom: 5px;
                }
            }
        }
    }

    .text-right {
        text-align: right !important;
        margin-right: 100px;
        background: none;
    }

    .toggle_odd {
        .text-right {
            margin-right: 120px;
        }
    }

    .btn-info {
        background: #6c757d !important;
    }

    .profile_info {
        text-align: center;
        margin-top: 40px;
    }

    .card-box {
        background: #cccccc4d;
        border-radius: 10px;
        padding: 30px;
        min-height: 300px;
        font-size: 1.1em;

        h2 {
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: 0.01em;
            margin-bottom: 20px;
        }
    }
}
</style>
