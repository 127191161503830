<template>
  <div class="snippet event-domain-snippet">
    <div class="form-group row">
      <div class="col-lg-12">
        <label class="" for="language">Language *</label>
        <div>
          <v-select
              name="language"
              label="language"
              @option:selected="updateLanguage"
              @option:deselected="removeLanguage"
              :modelValue="item.languages"
              :options="languages"
              multiple />
              <ValidationMessages :element="v$.item.languages"/>
  
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <label>Dedicated Event Domain</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_domain"
                        :value="event_domain_custom === true"
                        class="custom-control-input"
                        @change="updateIsEventCustomDomain" />
          
        </div>
      </div>
      <div class="col-lg-8">
        <label v-if="event_domain_custom">Event Website Link *  <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper></label>
        
        <label v-else>
          Event Website Link *
          <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Only Alphanumeric characters are allowed including hyphen
                                      </template>
                                    </VPopper>

         
        </label>

        <div class="kt-input-icon" v-if="event_domain_custom">
          <input
              type="text"
              class="form-control"
              name="event_domain"
              placeholder="Enter Website Link"
              :value="item.event_domain"
              @input="updateEventDomain" />
        </div>
        <div class="kt-input-icon" v-else>
          <span>https://</span>
          <input
              type="text"
              style="display: inline; width: auto;"
              class="form-control"
              name="event_domain"
              placeholder="Enter Prefix"
              :value="item.event_domain"
              @input="updateEventDomain" />
          <span>.avianet-vep.com</span>
          <ValidationMessages :element="v$.item.event_domain"/>
 
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-12">
        <DropFiles :file="validate_file(item.website_logo)"
                   :module_id="this.moduleId"
                   dimmention="200X53"
                   @onUploaded="updateWebsiteLogo"
                   @onRemoved="removeWebsiteLogo"
                   @onError="errCb"
                   :accept="'image/jpeg'"
                   module_type="events"
                   asset_type="event_website_logo"
                  
                   
                   >
          <template #header>
            <label>Event Website Logo (Size should be 200X53 pixel and format JPG.)</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
      <div class="col-lg-12">
        <DropFiles :file="item.user_guide"
                   :module_id="this.moduleId"
                   @onUploaded="updateUserGuide"
                   @onRemoved="removeUserGuide"
                   @onError="errCb"
                   :accept="'application/pdf'"
                   :module_type="'events'"
                   :asset_type="'event_user_guide'">
          <template #header>
            <label>User Guid (PDF)</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
      <div class="col-lg-12">
        <DropFiles :file="item.background_picture"
                   :module_id="this.moduleId"
                   @onUploaded="updateBackgroundPicture"
                   @onRemoved="removeBackgroundPicture"
                   dimmention="1400X800"
                   @onError="errCb"
                   :accept="'image/jpeg'"
                   module_type="events"
                   asset_type="event_background_picture">
          <template #header>
            <label>Background picture file(Size should be 1400X800 pixel and format JPG.)</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
      <div class="col-lg-12">
        <DropFiles :file="item.additional_logo"
                   :module_id="this.moduleId"
                   @onUploaded="updateAdditionalLogo"
                   @onRemoved="removeAdditionalLogo"
                   dimmention="234X94"
                   @onError="errCb"
                   :accept="'image/jpeg'"
                   module_type="events"
                   asset_type="event_additional_logo">
          <template #header>
            <label>Additional Logo(Size should be 234X94 pixel and format JPG.)</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6">
        <label>Paid Event</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_domain"
                        :value="item.is_paid"
                        class="custom-control-input"
                        @change="updateIs_paid" />
        </div>
      </div>
      <div class="col-lg-6" v-if="item.is_paid == 'true' || item.is_paid == true">
        <label>Promo Code </label>
        <div class="kt-input-icon">
          <input type="text" class="form-control" name="promo_code" placeholder="Enter Promo Code" :value="item.promo_code" @input="updatePromoCode" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6" v-if="item.is_paid == 'true' || item.is_paid == true">
        <div class="custom-control custom-switch">
          <input-switch name="is_sandbox"
                        :value="item.is_sandbox"
                        id="customSwitchess"
                        class="custom-control-input"
                        @change="update_sandbox" />
          <label class="custom-control-label" for="customSwitchess">Enable PayPal sandbox</label>
        </div>
      </div>
      <div class="col-lg-6" v-if="(item.is_paid == 'true' || item.is_paid == true) && (item.is_sandbox == 'true' || item.is_sandbox == true)">
        <label>PayPal Sandbox App Id *</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="paypal_app_id"
              placeholder="Enter PayPal Sandbox App Id"
              :value="item.paypal_app_id"
              @input="updatePayPalAppId" />
        </div>
      </div>
      <div class="col-lg-6" v-if="(item.is_paid == 'true' || item.is_paid == true) && (item.is_sandbox == 0 || item.is_sandbox === null || item.is_sandbox == '')">
        <label>PayPal Production App Id *</label>
        <div class="kt-input-icon">
          <input
              type="text"
              class="form-control"
              name="paypal_production_app_id"
              placeholder="Enter PayPal Production App Id"
              :value="item.paypal_production_app_id"
              @input="updatePayPalProductionAppId" />
        </div>
      </div>
    </div>
  </div>
  <FlashMessage group="events_create_detail"/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery';
import _ from 'lodash';
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'



export default {
  name: "EventDomainSnippet",
  data() {
    return {
      moduleId: ObjectID(),
    }
  },
  components: {
    InputSwitch,
    ValidationMessages
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },
  validations() {
        return {
            item: {
              languages: {
                    required,
                },
                event_domain: {
                    required,
                },
                
                
            }
        };
    },

  computed: {
    ...mapGetters("EventsSingle", [
      "item",
      "loading",
      "event_domain_custom",
    ]),
    ...mapGetters("LanguagesIndex", {languages: "all"})
  },
  mounted() {
    const switchers = document.querySelectorAll('.event-domain-snippet input[data-plugin-ios-switch]')
    switchers.forEach(item => {const elem = new window.theme.PluginIOS7Switch($(item)) })
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.events_create_detail;
    
  },
  methods: {
    ...mapActions("EventsSingle", [
      "setIs_paid",
      "setIs_sandbox",
      "setEventDomain",
      "setPromoCode",
      "setLanguages",
      "setWebsiteLogo",
      "setPayPalAppId",
      "setPayPalProductionAppId",
      "setUserGuide",
      "setBackgroundPicture",
      "setAdditionalLogo",
      "setPrivacyUrl",
      "setEventDomainCustom"
    ]),
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    update_sandbox(e) {
      this.setIs_sandbox(e.target.checked);
    },
    updateLanguage(langs) {
      this.setLanguages(langs);
    },
    removeLanguage(lang) {
      const langs = _.filter(this.item.languages, i => i._id !== lang._id)
      this.setLanguages(langs);
    },
    updateIs_paid(e) {
      this.setIs_paid(e.target.checked);
    },
    updatePromoCode(e) {
      this.setPromoCode(e.target.value);
    },
    updatePayPalAppId(e) {
      this.setPayPalAppId(e.target.value);
    },
    updatePayPalProductionAppId(e) {
      this.setPayPalProductionAppId(e.target.value);
    },
    updateEventDomain(e) {
      this.setEventDomain(e.target.value);
    },
    updateIsEventCustomDomain(e) {
      this.setEventDomainCustom(e.target.checked)
    },
    updatePrivacyUrl(e) {
      this.setPrivacyUrl(e.target.value);
    },
    removeWebsiteLogo(e) {
      this.setWebsiteLogo(null);
    },
    removeUserGuide(e) {
      this.setUserGuide(null);
    },
    errCb(e) {
      this.$flashMessage.show({
                    group: 'events_create_detail',
                    type: 'error',
                    title: 'Failed',
                    text: "Invalid File/Doc format.Please use described valid format"
                  });
      
    },
    updateWebsiteLogo(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.website_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      console.log("Size####,",info, data)
      this.setWebsiteLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    updateUserGuide(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }

      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.user_guide, 'key', 'empty.pdf');
      const size = _.get(info, 'file.size', 0);
      this.setUserGuide({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },

    /* @TODO: this features for 3D need to remove it. */
    updateBackgroundPicture(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.background_picture, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setBackgroundPicture({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    /* @TODO: this features for 3D need to remove it. */
    updateAdditionalLogo(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.additional_logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setAdditionalLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeBackgroundPicture(e) {
      /* @TODO: this features for 3D need to remove it. */
      this.setBackgroundPicture(null)
    },
    removeAdditionalLogo(e) {
      /* @TODO: this features for 3D need to remove it. */
      this.setAdditionalLogo(null)
    },
  },
}
</script>

<style scoped>
.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>