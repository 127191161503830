import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function upload(file, module_id, module_type, dimension, asset_type, headers = {}) {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('module_id', module_id);
        formData.append('module_type', module_type);
        formData.append('asset_type', asset_type);

        if (null !== dimension) {
                formData.append('dimension', dimension);
        }

        const headersDafault = {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        const reqHeaders = _.merge(headersDafault, headers);
        return axios.post(constants.UPLOAD_MEDIA_URL + '/api/v1/assets', formData, {headers: reqHeaders})
            .then((res) => {
                return res;
            }).catch((err) => {
                console.error('Upload file error: ', err)
                return err;
        });
}

function remove(key) {
        const arr = _.split(key, '/')
        let file = arr[0] + '/' + arr[1] + '/' + arr[3];
        if(!arr[3] || _.isNil(arr[3])){
                file = arr[0] + '/' + arr[1] + '/' + arr[2];
                
        }
        const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.UPLOAD_MEDIA_URL + '/api/v1/assets/' + file, {headers: headers})
            .then((res) => {
                    console.log('Success', res)
                    return res;
            })
            .catch((err) => {
                    console.error('Delete file error: ', err)
                    return err;
            });
}

function emptyDraft() {
        /* @TODO: need to implement it. */
}

export {upload, remove, emptyDraft};