<template>
    <div class="tabs-component">
               <ul class="nav nav-tabs nav-justified">
                 <li class="nav-item">
                   <a class="nav-link active" data-bs-target="#tab_event_hall" href="#tab_event_hall" data-bs-toggle="tab">Event Hall</a>
                 </li>
                 <li class="nav-item">
                   <a class="nav-link" data-bs-target="#tab_event_hall_screen" href="#tab_event_hall_screen" data-bs-toggle="tab">Event Hall Screen</a>
                 </li>

                 <li class="nav-item">
                   <a class="nav-link" data-bs-target="#tab_information_desk" href="#tab_information_desk" data-bs-toggle="tab">Information Desk</a>
                 </li>
               </ul>
       </div>
       <div class="tab-content">
               <div id="tab_event_hall" class="tab-pane active">
                   <div class="snippet general-event-config-snippet" v-if="tabEventHall">
                       <TogglePrimary v-for="(data, index) in tabEventHall.attendees" :key="index" >
                       <template v-slot:label >
                           <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}} {{data.last_name}}</span>
                           </span>
                       </template>
                       <div id ="edit-content">
                           <TabShow  :user_data="data"/>
                 
                       </div><!-- End Content-->
           

               </TogglePrimary>
                       
               </div><!-- End of snippet-->
      
               </div>

               <div id="tab_event_hall_screen" class="tab-pane">

                   <div class="snippet general-event-config-snippet" v-if="tabEventHallScreen">
                       <TogglePrimary v-for="(data, index) in tabEventHallScreen.attendees" :key="index" >
                       <template v-slot:label >
                           <span class="labelwrapper" >
                               <span class="col1"> {{data.first_name}} {{data.last_name}}</span>
                           </span>
                       </template>
                       <div id ="edit-content">
                        <TabShow :user_data="data"/>
                       </div><!-- End Content-->
           

               </TogglePrimary>
                       
               </div><!-- End of snippet-->
      

               </div>

               <div id="tab_information_desk" class="tab-pane">
                   <div class="snippet general-event-config-snippet" v-if="tabInformationnDesk">
                       <TogglePrimary v-for="(data, index) in tabInformationnDesk.attendees" :key="index" >
                       <template v-slot:label >
                           <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}} {{data.last_name}}</span>
                           </span>
                       </template>
                       <div id ="edit-content">
                        <TabShow :user_data="data"/>
                           
                       </div><!-- End Content-->
           

               </TogglePrimary>
                       
               </div><!-- End of snippet-->
      
               </div>

       </div>
                

               
</template>

<script>
import {
mapGetters,
mapActions
} from 'vuex'
import _ from "lodash";

import SearchableContainer  from "@/components/Commons/SearchableContainer";
import TogglePrimary        from "@/components/Commons/TogglePrimary";
import TabShow              from "@/components/cruds/EventAnalytics/Forms/ShowSingle";


export default {
name: 'AnalyticsTab',
components: {
    TogglePrimary,
    TabShow
},
data() {
return {
   search: null,
   perPage: 5,
   system_users: [],
}
},
props:{
    headers:null
},
watch: {
},
created() {
// this.fetchPermissions()

},
beforeUnmount() {
//this.resetState();

},
computed: {
       ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
       ...mapGetters('EventAnalyticsIndex', ['tabEventHall','tabEventHallScreen','tabInformationDesk', 'loading', 'all', 'headers','enumModules','loaded','tabAttendence','tabQa','tabPolling','tabRating','tabChat']),
},
methods: {
    ...mapActions('EventAnalyticsIndex', ['fetchData','resetState']),

}
}
</script>

