<template>
    <GeneralDashboardLayout>
        <template v-slot:header>
            <div class="header-template">
                <BackButton :to="{ name: 'event_dashboard.index' }" />
              <h1 class="title text-center">Event Analytics</h1>
            </div>
        </template>
        <template v-slot:content>
            <div class="container-fluid user-container">
                <div class="row">
                        <div id="live-presentations">
                          <OverlayLoading :isLoading="loading" />
                        </div>
                        <headerForm :headers="headers"></headerForm>
                </div>

                <div class="row">
                        <div class="tabs tabs-vertical tabs-left" >
                                <ul class="nav nav-tabs" role="tablist" style="width:20%;">
                                    <li :class="activeTab=='live-presentation'?' nav-item active':'nav-item is-outlined' " role="presentation" @click.prevent="setActiveTabName('live-presentation')">
                                        <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`" data-bs-toggle="tab">Live Presentation Analytics</a>
                                    </li>
                                    <li  :class="activeTab=='pd'?' nav-item active':'nav-item is-outlined' " role="pd" @click.prevent="setActiveTabName('pd')">
                                        <a class="nav-link" :data-bs-target="`#panel`" :href="`#panel`" data-bs-toggle="tab">Panel Discussion Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='roundtable'?' nav-item active':'nav-item is-outlined' " role="pd" @click.prevent="setActiveTabName('roundtable')">
                                        <a class="nav-link" :data-bs-target="`#roundtable`" :href="`#roundtable`" data-bs-toggle="tab">Round-Table Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='ondemand'?' nav-item active':'nav-item is-outlined' " role="pd" @click.prevent="setActiveTabName('ondemand')">
                                        <a class="nav-link" :data-bs-target="`#ondemand`" :href="`#ondemand`" data-bs-toggle="tab">On Demand Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='exhibition'?' nav-item active':'nav-item is-outlined' " role="pd" @click.prevent="setActiveTabName('exhibition')">
                                        <a class="nav-link" :data-bs-target="`#exhibition`" :href="`#exhibition`" data-bs-toggle="tab">Exhibiton Area Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='event_assets'?' nav-item active':'nav-item is-outlined' " role="pd" @click.prevent="setActiveTabName('event_assets')">
                                        <a class="nav-link" :data-bs-target="`#event_assets`" :href="`#event_assets`" data-bs-toggle="tab">Events Download Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='chat'?' nav-item active':'nav-item is-outlined' " role="chat" @click.prevent="setActiveTabName('chat')">
                                        <a class="nav-link" :data-bs-target="`#chat`" :href="`#chat`" data-bs-toggle="tab">Events Chat Analytics</a>
                                    </li>

                                    <li  :class="activeTab=='event_hall'?' nav-item active':'nav-item is-outlined' " role="event_hall" @click.prevent="setActiveTabName('event_hall')">
                                        <a class="nav-link" :data-bs-target="`#event_hall`" :href="`#event_hall`" data-bs-toggle="tab">Event Hall</a>
                                    </li>
                                    <li  :class="activeTab=='event_user'?' nav-item active':'nav-item is-outlined' " role="event_user" @click.prevent="setActiveTabName('event_user')">
                                        <a class="nav-link" :data-bs-target="`#event_user`" :href="`#event_user`" data-bs-toggle="tab">Event User</a>
                                    </li>
                                    
                                </ul>
                                <div class="tab-content">
                                    <div :id="`theme_content`" class="tab-pane active show" role="presentation" v-if="activeTab=='live-presentation'">    
                                        <div class="snippet general-event-config-snippet">
                                            <LpForm ></LpForm>
            
                                        </div>
                                    </div>



                                    <div :id="`panel`" class="tab-pane  show active" role="pd" v-if="activeTab=='pd'">
                                        <div class="snippet general-event-config-snippet" >
                                            <PdForm ></PdForm>

                                        </div>
                                    </div>



                                    <div :id="`roundtable`" class="tab-pane  show active" role="roundtable" v-if="activeTab=='roundtable'">
                                        <div class="snippet general-event-config-snippet" >
                                            <RoundtableForm ></RoundtableForm>

                                        </div>
                                    </div>

                                    <div :id="`ondemand`" class="tab-pane  show active" role="ondemand" v-if="activeTab=='ondemand'">
                                        <div class="snippet general-event-config-snippet" >
                                            <OnDemandForm ></OnDemandForm>

                                        </div>
                                    </div>

                                    <div :id="`exhibition`" class="tab-pane  show active" role="exhibition" v-if="activeTab=='exhibition'">
                                        <div class="snippet general-event-config-snippet" >
                                            <ExhibitionAreaForm ></ExhibitionAreaForm>

                                        </div>
                                    </div>


                                    <div :id="`event_assets`" class="tab-pane  show active" role="event_assets" v-if="activeTab=='event_assets'">
                                        <div class="snippet general-event-config-snippet" >
                                            <EventAssetsForm ></EventAssetsForm>

                                        </div>
                                    </div>

                                    <div :id="`chat`" class="tab-pane  show active" role="chat" v-if="activeTab=='chat'">
                                        <div class="snippet general-event-config-snippet" >
                                            <ChatForm ></ChatForm>

                                        </div>
                                    </div>

                                    <div :id="`event_hall`" class="tab-pane  show active" role="chat" v-if="activeTab=='event_hall'">
                                        <div class="snippet general-event-config-snippet" >
                                            <EventHallForm ></EventHallForm>

                                        </div>
                                    </div>

                                    <div :id="`event_user`" class="tab-pane  show active" role="chat" v-if="activeTab=='event_user'">
                                        <div class="snippet general-event-config-snippet" >
                                            <EventUserForm ></EventUserForm>

                                        </div>
                                    </div>

                                </div>
                        </div>
                </div> 
            </div>   
       

        </template>
    </GeneralDashboardLayout>
    </template>
    
    <script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
    import TogglePrimary from "@/components/Commons/TogglePrimary";
    import _ from "lodash";
    import BackButton from "@/components/Commons/BackButton";
   
    import { isPermits} from "@/services/CheckPermissions";
    import headerForm from "@/components/cruds/EventAnalytics/Forms/Header";
    import TabForm from "@/components/cruds/EventAnalytics/Forms/Tabes";
    import { ref } from "vue";
    import { utils, writeFileXLSX } from 'xlsx';
    import LpForm from "@/components/cruds/EventAnalytics/Forms/Lp";
    import PdForm from "@/components/cruds/EventAnalytics/Forms/Pd";
    import RoundtableForm from "@/components/cruds/EventAnalytics/Forms/Roundtable";
    import OnDemandForm from "@/components/cruds/EventAnalytics/Forms/OnDemand";
    import ExhibitionAreaForm from "@/components/cruds/EventAnalytics/Forms/ExhibitionArea";
    import EventAssetsForm from "@/components/cruds/EventAnalytics/Forms/EeventAssets";
    import ChatForm from "@/components/cruds/EventAnalytics/Forms/Chat";
    import EventHallForm from "@/components/cruds/EventAnalytics/Forms/EventHall";
    import EventUserForm from "@/components/cruds/EventAnalytics/Forms/EventUser";
    
    export default {
        name: 'EventAssetsIndex',
        components: {
            BackButton,
            GeneralDashboardLayout,
            headerForm,
            LpForm,
            PdForm,
            RoundtableForm,
            OnDemandForm,
            ExhibitionAreaForm,
            EventAssetsForm,
            ChatForm,
            EventHallForm,
            EventUserForm
        },
        data() {
            return {
               
                search: null,
                perPage: 5,
                system_users: [],
                
            }
        },
        watch: {
        },
        created() {
           // this.fetchPermissions()
            this.verifyPermissions(this.$route.params.event_id)
            /*this.fetchData(this.$route.params.event_id)
            .catch(() => {
                this.$flashMessage.show({
                    group: 'event_assets_index',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong.'
                });
            })*/

        },
        beforeUnmount() {
            this.resetState();
            delete this.$flashMessage.groups.event_analytics_index;
      
        },
        computed: {
            ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
            ...mapGetters('EventAnalyticsIndex', ['activeTab','loading', 'all', 'headers','enumModules', "chartDataMain",'loaded','chartQa','chartPolling','chartRating','chartChat']),
        },
        methods: {
            ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
            ...mapActions('EventAnalyticsIndex', ['fetchData','resetState','generateLivePresentationsSheet','setActiveTab']),
            createClick() {
                this.setIsNew(true);
                this.setIsEditing(false);
            },
            cancelClick() {
                this.resetState();
            },
            perRowChange(e) {
                this.perPage = Number(e.target.value);
            },
            can(permit,permissions){
                return isPermits(permit,permissions);
            },
            setActiveTabName(tab_name){
                this.setActiveTab(tab_name)
            }
            
    
           
        }
    }
    </script>
    
    <style lang="scss" scoped>
    .header-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    
        .title {
            font-size: 1.6rem;
        }
    }
    
    .user-container {
        .toggle label {
            color: #000 !important;
            border-radius: 0px;
    
        }
    
        .toggle.active>label {
            color: #fff !important;
        }
    
        .toggle section {
            // box-shadow: -1px 0px 5px 2px #ccc;
            border-bottom: 1px solid #ccc;
        }
    
        .toggle-content {
            padding: 35px;
    
        }
    
        .form-control {
            font-family: 'Poppins';
        }
    
        .desc_btn_wrapper {
            margin-top: 20px;
        }
    
        .snippet {
            border-radius: 0.2rem;
            border: 1px solid #c9c9c9;
            padding: 24px 22px;
            margin: 15px 0;
            background-color: white;
        }
    
        .snippet .btn-light {
            color: #000 !important;
        }
    
        .edit_panel {
            background: #d5e3eb;
            padding: 15px;
            margin-top: 10px;
        }
    
        .snippet {
            margin-top: 0px;
        }
    
        .note-editor .btn-light {
            color: #000 !important;
        }
    
        .setting_wrapper {
            float: right;
        }
    
        .setting_wrapper a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
    
        }
    
        .labelwrapper {
            width: 100%;
        }
    
        .labelhdwrapper {
            width: 100%;
            margin-left: 0px;
            border: 1px solid #ccc;
            padding: 10px;
            background: #e1e1e1;
        }
    
        .labelhdwrapper span {
            font-weight: bold;
        }
    
        .col1 {
            width: 25%;
            display: inline-flex;
        }
    
        .col2 {
            width: 25%;
            display: inline-flex;
        }
    
        .col3 {
            width: 25%;
            display: inline-flex;
        }
    
        .col4 {
            width: 20%;
            display: inline-flex;
        }
    
        .search_bar {
            margin-bottom: 20px;
        }
    
        .eventlabel {
            font-size: 1.3em;
            font-weight: bold;
            margin-bottom: 10px;
        }
    
        .event_item {
            color: #fff;
            padding: 8px 15px;
            border-radius: 8px;
            background: #afbcc3;
            min-width: 50px;
            display: inline-flex;
            margin: 5px;
        }
    
        .nav-link.active {
            border-top-color: #0088CC;
        }
    
        .toggle.active>label {
            background: #0a72e0;
        }
    
        .nav-tabs li.active a {
            color: #fff !important;
            background: #0a72e0 !important;
            font-size: 1.2em;
        }
    
        .nav-tabs li a {
            font-size: 1.2em;
            color: #333;
        }
    
        .toggle>label {
            background: none !important;
            font-size: 1em !important;
        }
    
        .toggle .toggle {
            margin: 0px;
        }
    
        .toggle {
            margin: 0px;
        }
    
        .toggle_odd {
            background: #f5f5f5;
        }
    
        .btn-primary {
            color: #fff !important;
            background-color: #0c78e0 !important;
    
        }
    
        .nav-tabs li .nav-link,
        .nav-tabs li .nav-link:hover {
            background: #cccccc94 !important;
        }
    
        .pageview {
            font-family: 'Poppins';
            font-size: 1em !important;
        }
    
        .w-70 {
            width: 30%;
            display: inline-flex;
        }
    
        .rpg_txt {
            display: inline-block;
            margin-left: 10px;
        }
    
        .nav-tabs li .nav-link,
        .nav-tabs li {
            border-right: 1px solid #EEE;
        }
    
        .toggle_odd {
            .event_detail_wrapper {
                .row {
                    .col-md-8 {
                        border-bottom: 0px dotted #ccc;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    
        .text-right {
            text-align: right !important;
            margin-right: 100px;
            background: none;
        }
    
        .toggle_odd {
            .text-right {
                margin-right: 120px;
            }
        }
    
        .btn-info {
            background: #6c757d !important;
        }
    
        .profile_info {
            text-align: center;
            margin-top: 40px;
        }
    
        .card-box {
            background: #cccccc4d;
            border-radius: 10px;
            padding: 30px;
            min-height: 300px;
            font-size: 1.1em;
    
            h2 {
                font-size: 1.5em;
                font-weight: bold;
                letter-spacing: 0.01em;
                margin-bottom: 20px;
            }
        }
    }
    </style>
    