import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: [],
        loading: false,
        page_title: "Event Keywords"
    }
}

const getters = {
    
    item            : state => state.item,
    loading         : state => state.loading,
    page_title      : state => state.page_title
}

const actions = {
    fetchData({ commit, state ,dispatch}, eventId) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

      
        return axios.get(constants.API_URL + `/api/events/${eventId.event_id}/keywords`, {headers: headers})
            .then(response => {
                commit('setAll', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.item = items
    
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
