<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{name: 'dashboard.index'}" />
        <h1 class="title text-center">Companies</h1>
        <div>
          <span v-if="can('company_create',checkPermssions)">
            <button class="btn btn-outline-primary" @click="createClick" v-if="!is_new">Create</button>
            <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <OverlayLoading :isLoading="loading" />
      <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="5" v-if="can('company_view',checkPermssions)" >
        <div class="container-fluid company-container">
          <div class="row search_bar">
            <div class="col-md-4 offset-md-4 ">
              <div class="input-group">
                <input type="text" class="form-control input-rounded" placeholder="Right icon" v-model="search">
                <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
              </div>
            </div>
          </div>
          <div class="labelhdwrapper row">
            <span class="col1">Company Name</span>
            <span class="col2">Company Email</span>
            <span class="col3">Company Phone</span>
            <span class="col4">Company Address</span>
          </div>
          <TogglePrimaryActive v-if="is_new">
            <template v-slot:default>
              <CompaniesCreateForm />
            </template>
          </TogglePrimaryActive>
          <TogglePrimary v-for="(company, index) in props.all" v-bind:key="index">
            <template v-slot:label>
          <span class="labelwrapper">
            <span class="col1">{{ company.company_name }}</span>
            <span class="col2">{{ company.company_email }}</span>
            <span class="col3">{{ company.phone }}</span>
            <span class="col4">{{ company.address }}</span>
          </span>
            </template>
            <template v-slot:default>
              <CompaniesEditForm :company_id="company._id" v-if="edit_id === company._id"  />
              <ShowItem :company="company"  :checkPermssions="checkPermssions" v-else />
            </template>
          </TogglePrimary>
        </div>
      </SearchableContainer>
      <FlashMessage group="company" />
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GeneralDashboardLayout from '../../layouts/GeneralDashboardLayout';
import BackButton from "@/components/Commons/BackButton";
import CompaniesEditForm from "@/components/cruds/Companies/Forms/Edit";
import CompaniesCreateForm from "@/components/cruds/Companies/Forms/Create";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/Companies/Forms/Show";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
  name: 'CompaniesIndex',
  components: {SearchableContainer, CompaniesCreateForm, ShowItem, TogglePrimary, CompaniesEditForm, BackButton, GeneralDashboardLayout,TogglePrimaryActive},
  data() {
    return {
      search: null,
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.company;
  },
  created() {
    const access_level = localStorage.getItem('access_level');
    if(access_level =='event'){
      this.$router.push({name: 'notFound'});
    }
    this.verifyPermissions();
    this.fetchData()
  },
  unmounted() {
    this.resetIndexState()
    this.resetState()
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters('CompaniesIndex', ['all', 'total', 'loading', 'edit_id']),
    ...mapGetters('CompaniesSingle', ['item', "is_new"]),
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('CompaniesIndex', {fetchData: "fetchData", resetIndexState: "resetState"}),
    ...mapActions('CompaniesSingle', [ 'resetState', 'setIsNew']),
    createClick() {
      this.setIsNew(true);
    },
    cancelClick() {
      this.resetState();
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.company-container {
  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .input-group-text {
    border-radius: 1.25rem;
  }

  .search_bar {
    margin-bottom: 20px;
  }
}
</style>
