import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        all: [],
        loading: false,
        page_title: "Booth Presets",
        exhibitor:[],
        boothRepresentative:[],
        boothLocation:[],
        boothPresets:[],
        boothReservedLocation:[],
        edit_id: null,
        eventPresets:[]
     

    }
}

const getters = {
    all                     :   state   => state.all,
    total                   :   state   => state.all.length,
    edit_id                 :   state   => state.edit_id,
    loading                 :   state   => state.loading,
    exhibitor               :   state   => state.exhibitor,
    boothRepresentative     :   state   => state.boothRepresentative,
    boothLocation           :   state   => state.boothLocation,
    boothPresets            :   state   => state.boothPresets,
    boothReservedLocation   :   state    => state.boothReservedLocation,


}

const actions = {

    fetchData({ commit, state ,dispatch}, eventId) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/booth/event-id/'+eventId, {headers: headers})
            .then(response => {
                commit('setAll', response.data.data)
                dispatch('fetchExhibitorsAll',eventId)
                dispatch('fetchBoothRepresentative',eventId)
                dispatch('fetchBoothLocationAll')
                dispatch('fetchBoothPresetsAll',{type:"booth",eventId:eventId})
                dispatch("fetchBoothReserverdLocation",eventId)
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
                return error
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    /*
    destroySurveyData({ commit, state }, id) {
        //commit('setLoading', true)
        axios.delete('/api/v1/surveys/' + id)
            .then(response => {
                //console.log(state.all.agenda_qas)
                
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        //debugger;
                        return survey.id != id
                    })
                }))
            })
            .catch(error => {
                message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            }).finally(() => {
                //commit('setLoading', false)
            })
    },
    destroySurveyQuestion({ commit, state }, id) {
        //commit('setLoading', true)
        axios.delete('/api/v1/survey-questions/' + id)
            .then(response => {
                //console.log(state.all.agenda_qas)
                
                commit('setAll', state.all.filter((item) => {

                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.survey_questions = survey.survey_questions.filter((question)=>{
                            return question.id != id
                        })
                        
                    })
                }))
            })
            .catch(error => {
                message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            }).finally(() => {
                //commit('setLoading', false)
            })
    },
    */
    resetState({ commit }) {
        commit('resetState')
    },
    fetchExhibitorsAll({ commit }, event_id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
         axios.get(constants.API_URL + '/api/events/' + event_id + '/exhibitors',   {headers: headers})
        .then(response => {
            let ExhibitorArr =[];
            let items = _.get(response, 'data.data', {})
            _.forEach(items, function (value) {
                ExhibitorArr.push({ id: `${value._id}`, name: `${value.translations[0].name}` })
            }); 
            
            commit('setExhibitorsAll',ExhibitorArr)
            
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
        })
    },
    fetchBoothRepresentative({ commit }, event_id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(constants.API_URL + '/api/event-user/event-id/' + event_id, {headers: headers})
        .then(response => {
            let boothUser =[];
            let items = _.get(response, 'data.data', {})
            _.forEach(items, function (value) {
                boothUser.push({ id: `${value._id}`, name: `${value.first_name} ${value.last_name}` })
            }); 
            commit('setBoothRepresentative',boothUser)
            
        })
        .catch(error => {
            console.log(error)
            
        })
        .finally(() => {
           
        })
    },
    fetchBoothLocationAll({commit}){
        let boothLocationArr= [];
        for(let i=1;i<22;i++){
            boothLocationArr.push({id:i,name:i});
        }
        commit('setBoothLocation',boothLocationArr)
    },
    fetchBoothReserverdLocation({commit},event_id){
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/booth/booth-location/' + event_id,{headers:headers})
        .then(response => {
            let boothUser =[];
            let items = _.get(response, 'data.data', {})
            _.forEach(items, function (value) {
                boothUser.push({ id: `${value.id}`, name: `${value.name}` })
            }); 
            commit('setBoothReservedLocation',boothUser)
            
        })
        .catch(error => {
            console.log(error)
            
        })
        .finally(() => {
           
        })

    },
    fetchBoothPresetsAll({commit},data){
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        axios.post(constants.API_URL + '/api/presets/preset_type/virtual_exhibition_2d',data, {headers: headers})
        .then(response => {
            let presetType = [];
            let items = _.get(response, 'data.data', {})
            _.forEach(items, function (value) {
                presetType.push({ id: `${value._id}`, name: `${value.name}`,preset_image:value.preset_image })
            }); 
            commit('setBoothPreset',presetType)
            
        })
        .catch(error => {
            console.log(error)
            return error
        })
        .finally(() => {
           
        })
    },
    setEditId({ commit }, id) {
        console.log("set Edit Id",id);
        commit('setEditId', id)
    }    
}
const mutations = {
    setExhibitorsAll(state, value) {
        state.exhibitor  = value
    },
    setBoothRepresentative(state,value){
        state.boothRepresentative  = value
    },
    setBoothLocation(state,value){
        state.boothLocation = value
    },
    setBoothPreset(state,value){
        state.boothPresets = value
    },
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEditId(state, id) {
        state.edit_id = id
    },
    setBoothReservedLocation(state,value){
        state.boothReservedLocation = value
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
