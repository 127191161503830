<template>
    <div class="container-fluid">
        <div class="row">
    
                                          <div class="col-lg-12 mb-3">
                                                <label>Title*</label>
                                                <div class="kt-input-icon">
                                                    <input type="text" class="form-control" :value="surveyitems.title"  name="title" maxlength="200" placeholder="Title" @input="updatesurvey_title" />
    
                                                </div>
                                                <ValidationMessages :element="v$.surveyitems.title" />
                                            </div>
    
                                            <div class="col-lg-10 mb-3">
                                                <label>Result public?</label>
                                                <br />
                                                <div class="switch switch-sm switch-primary">
                                                    <input-switch name="is_open" :value="surveyitems.is_open" id="customSwitchesEx" class="custom-control-input" @change="updateIs_open" />
                                                </div>
                                            </div>
    
                                            <div class="col-lg-2 text-right">                                            
                                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitSurveyTitleForm()">Submit</button>
    
                                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelSurveyEdit">Cancel</button>
                                            </div>
    
    
        </div>
    </div>
    <FlashMessage group="feedback_survey_create" />
    
    </template>
    
    <script>
    
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    import ObjectID from 'bson-objectid';
    import _, {
        clone
    } from "lodash";
    
    
    import InputSwitch from "@/components/Commons/InputSwitch";
    
    import {
        gettingErrorMessage
    } from "@/services/GetValidationMessage";
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    
    
    
    export default {
        data() {
           
        },
        name: "FeedbackSurveyCreate",
    
        
        components: {
            InputSwitch,
            ValidationMessages,
        },
    
        computed: {
            ...mapGetters('FeedbackIndex', ['surveyitems', 'newSurvey', 'all']),
            ...mapGetters('FeedbackSingle', [
                'sitems',
                'surveytemp',
                'is_survey_editing',
                'is_new_question',
                'datanum',
            ]),
        },
    
    
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {
                surveyitems: {
                    title: {
                        required,
                    }
                }
               
            };
        },
    
        props: {
            eventid: String
      },
    
    
        beforeUnmount() {
            this.resetState();
            delete this.$flashMessage.groups.feedback_survey_create;
     
            
        },
    
        mounted() {
            this.fetchSurveyDataforFeedback(this.$route.params.event_id); //questions data   
            //var surv_que = {questionid:this.questionid,surveyid:this.surveyid};
            // this.fetchsurveydatainfo(surv_que);  
            //console.log('Question id is ',this.questionindex);   
    
        },
    
        methods: {
    
            ...mapActions('FeedbackIndex', {
                fetchSurveyDataforFeedback: "fetchSurveyDataforFeedback"
            }), //questions data
           
    
            ...mapActions('FeedbackIndex', ["setIs_open", "setSurveytitle", "updateSurveytitleDataFeedback", "storeFeedbackSurveyData", "destroySurveyData", "setNewSurvey"]),
    
            ...mapActions('FeedbackSingle', [
                'setIs_required',
                'setselectoptions',
                'setQuestionTitle',
                'resetState',
            ]),
    
    
            updatesurvey_title(e) {
                this.setSurveytitle(e.target.value);
            },
    
            updateIs_open(e) {
                this.setIs_open(e.target.checked);
            },
    
           
           
    
            async submitSurveyTitleForm() {
                const isFormCorrect = await this.v$.$validate();
    
                if (!isFormCorrect) {
    
                    _.map(this.v$.$errors, (item) => {
                        if (item.$property.indexOf(['title'])) {
                            this.error_general_tab = true;
                        }
                    });
    
                }
                this.storeFeedbackSurveyData(this.eventid)
                    .then(async (resp) => {
                        this.$flashMessage
                            .show({
                                group   : 'feedback_index',
                                type    : 'success',
                                title   : 'Success',
                                time    : 1000,
                                html    : '<ul><li>Feedback Survey created successfully.</li></ul>',
                            });
    
                        this.setIsSurveyEdit = false
                        this.setNewSurvey(false)
                        await this.fetchSurveyDataforFeedback(this.$route.params.event_id);
                        this.setSurveytitle(null);
                        this.setIs_open(false);
                        //await this.fetchAllData(resp.data.data.event_id);
                    }).catch(error => {
                        let html = gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'feedback_survey_create',
                                type: 'error',
                                title: 'Failed',
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
    
                    });
            },
    
            cancelSurveyEdit() {
                this.setIsSurveyEdit = false;
                this.setNewSurvey(false);
            },
    
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .button {
        border: 1px solid white;
        border-radius: 2px;
        color: white;
        text-decoration: none;
        padding: 8px 15px;
        transition: all 0.3s ease;
        cursor: pointer;
    
        &--transparent {
            background-color: transparent;
        }
    
        &:hover {
            background-color: white;
            border: 1px solid #1fa9e5;
            color: #1fa9e5;
        }
    }
    
    .taskItems li {
        list-style: none;
        margin-bottom: 10px;
    }
    
    .taskItems ul {
        padding-left: 0px;
        margin-left: 0px;
    }
    
    .textcenter {
        text-align: center;
        margin-bottom: 20px;
        display: block;
    }
    </style>
    