<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'events.index' }" />
        <h1 class="header--title">Create a New Event</h1>
        <div>
          <button class="btn btn-outline-light" @click="submitForm">Save</button>
          <button class="btn btn-outline-light" @click="cancelForm">Cancel</button>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="event-config">
        <div class="leftmenu">
          <aside>
            <ul>
              <li>
                <a href="#general-information">
                  <i class="bx bx-home-alt" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#color-settings">
                  <i class="bx bxs-palette" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#theme-settings">
                  <i class='bx bx-cog'></i>
                </a>
              </li>
            </ul>
          </aside>
        </div>
        <div class="right_content">
          <div class="container-fluid ">
            <div class="row event-config--row" id="event-owner">
              <div class="col-md-3 event-config--description">
                <h3>Event Owner</h3>
                <p>Main owner of the event. Who can controle all the  settings/Configurations</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <EventOwner />
              </div>
            </div>
            <div class="row event-config--row" id="general-information">
              <div class="col-md-3 event-config--description">
                <h3>General Information</h3>
                <p>Main Information about the Event. Edit event start/stop date.</p>
                <p>Add event Location. Time zone and Address.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <GeneralEventConfigSnippet />
                <StartEndDateSnippet />
                <EventDomainSnippet />
              </div>
            </div>
            <div class="row event-config--row" id="color-settings">
              <div class="col-md-3 event-config--description">
                <h3>Color Settings</h3>
                <p>Theme Color settings. Event Theme settings.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <AdditionalConfigSnippet />
                <ThemeColorSnippet />
              </div>
            </div>
            <div class="row event-config--row" id="theme-settings">
              <div class="col-md-3 event-config--description">
                <h3>Settings</h3>
                <p>Theme settings. Event Theme settings.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <EventSubscriptionConfigSnippet />
              </div>
            </div>
          </div>
        </div>
        <FlashMessage group="events_create" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import { mapActions, mapGetters } from "vuex";
import BackButton from "@/components/Commons/BackButton";
import EventOwner from "@/components/cruds/Events/Forms/Elements/EventOwner";
import GeneralEventConfigSnippet from "@/components/cruds/Events/Forms/Elements/GeneralEventConfigSnippet";
import StartEndDateSnippet from "@/components/cruds/Events/Forms/Elements/StartEndDateSnippet";
import EventDomainSnippet from "@/components/cruds/Events/Forms/Elements/EventDomainSnippet";
import AdditionalConfigSnippet from "@/components/cruds/Events/Forms/Elements/AdditionalConfigSnippet";
import ThemeColorSnippet from "@/components/cruds/Events/Forms/Elements/ThemeColorSnippet";
import EventSubscriptionConfigSnippet from "@/components/cruds/Events/Forms/Elements/EventSubscriptionConfigSnippet";
import _ from "lodash";
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {
  useVuelidate
} from '@vuelidate/core'
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "EventCreate",
  components: {
    EventSubscriptionConfigSnippet,
    ThemeColorSnippet,
    AdditionalConfigSnippet,
    EventDomainSnippet,
    StartEndDateSnippet,
    GeneralEventConfigSnippet,
    EventOwner,
    BackButton,
    GeneralDashboardLayout

  },

  data() {
    return {
      picked: new Date(),
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
  },
  created() {
    this.fetchCompaniesAll();
    this.fetchCountriesAll();
    this.fetchTimezonesAll();
    this.fetchLanguagesAll();
  },
  beforeUnmount() {
    this.resetState();
    this.resetCompaniesState();
    delete this.$flashMessage.groups.events_create;

  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad"]),
  
    ...mapActions("EventsSingle", [
      "storeData",
      "fetchCountriesAll",
      "fetchTimezonesAll",
      "resetState",
    ]),
    ...mapActions("LanguagesIndex", {
      fetchLanguagesAll: "fetchData"
    }),
    ...mapActions("CompaniesIndex", { fetchCompaniesAll: 'fetchData', resetCompaniesState: "resetState" }),
    ...mapActions('EventsIndex', {
      setEditId: "setEditId"
    }),
    async submitForm(e) {
      e.preventDefault();
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.storeData()
        .then(async (res) => {
          if (res.data.status === "OK") {
            await this.$router.push({ name: "events.index" });
            setTimeout(() => this.cancelEdit(), 1500);
          }
        })
        .catch(error => {
          let html = gettingErrorMessage(error);
          if (error.response.data.data.error) {
            this.$flashMessage.show({
              group: 'events_create',
              type: 'error',
              title: 'Failed',
              text: html || 'Something went wrong',
              html: html
            });
          }
        });

    },
    cancelEdit() {
      this.$flashMessage.show({
        group : 'events_index',
        type  : 'success',
        title : 'Event Success',
        time  : 1000,
        html  : '<ul><li>Event has been created successfully</li></ul>',
      });
      console.log("restate state");
      this.resetState();
      this.setEditId(null)

    },
    cancelForm() {
      this.$router.push({ name: "events.index" });
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }

  },
}
</script>

<style scoped lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .header--title {
    font-size: 1.6rem;
  }
}

.event-config {
  background-color: #eeeff2;
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  label {
    font-weight: bold;
    margin-left: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  &--description {
    background-color: #dee1e8;
    color: #9e9e9e;
    padding: 32px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background-color: #a4a4a4;
      bottom: 3px;
      width: 80%;
      height: 1px;
    }

    h3 {
      color: #797979;
    }
  }

  &--fields {
    padding: 5px 32px;
  }

  .leftmenu {
    position: relative;
    left: 0px;
    height: 100%;
    min-width: 50px;
    display: contents;

    aside {
      background: #3c61b2;
      min-width: 50px;

      ul {
        list-style: none;
        padding-left: 0;
        position: fixed;
        min-width: 50px;

        li {
          text-align: center;

          a {
            color: white;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            transition: all 0.3s ease-in;

            &:hover {
              color: white;
              background: lighten(#4093e3, 20%);
            }

            &.active {
              background: #4093e3;
            }
          }
        }
      }
    }
  }

  nav {
    padding: 0px;
  }

  .right_content {
    width: 100%;
  }
}</style>