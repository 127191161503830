<template>
<section class="error-page-index">
    <SimpleHeader />
    <div class="error-page-container">
     <h1>Page Not Found</h1>
    </div>
</section>
</template>

<script>
export default {
    name: "PageNotFound",
}
</script>
<style lang="scss" scoped>
.error-page-index {
    background-image: url("~@/../public/assets/images/login/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .error-page-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;
    }
}
</style>
