<template>
  <GeneralDashboardLayout>
    <template v-slot:header><h1>Event Dashboard</h1></template>
    <template v-slot:content>
      <div class="dashboard-content">
        <div class="container-fluid">
          <section class="card mt-2">
            <header class="card-header">
              <h3 class="mt-0 mt-0 text-center">Event Modules</h3>
            </header>
            <div class="card-body">
              <div class="row show-grid justify-content-md-center">
                <div class="event_features" v-if="item._id">
                  <RouterLink :to="{name: 'live_presentations.index', params: {event_id: item._id}}" v-if="can('live_presentation_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Live_Presentation.png" class="asset_img" alt="logo">
                      <span>Live Presentation</span>
                    </span> <!---->
                  </RouterLink>
                  <RouterLink :to="{name: 'panel_agenda.index', params: {event_id: item._id}}" v-if="can('panel_discussion_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Panel_Discussion.png" class="asset_img" alt="logo">
                      <span>Panel</span>
                    </span>
                  </RouterLink>
                 <!-- <RouterLink :to="{name: 'webcast.index', params: {event_id: item._id}}">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Webcast.png" class="asset_img" alt="logo">
                      <span>Webcast</span>
                    </span>
                  </RouterLink>
                -->
                  <RouterLink :to="{name: 'on_demand.index', params: {event_id: item._id}}" v-if="can('on_demand_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/On_Demand.png" class="asset_img" alt="logo">
                      <span>On-Demand</span>
                    </span>
                  </RouterLink>
                  <RouterLink :to="{name: 'roundtable.index', params: {event_id: item._id}}" v-if="can('roundtable_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Roundtable.png" class="asset_img" alt="logo">
                      <span>Roundtable</span>
                    </span>
                  </RouterLink>
                  <RouterLink :to="{name: 'meetings.index', params: {event_id: item._id}}" v-if="can('meetings_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Meetings.png" class="asset_img" alt="logo">
                      <span>Meetings</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'booth.index', params: {event_id: item._id}}" v-if="can('booth_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Booth_Presets.png" class="asset_img" alt="Booth">
                      <span>Booths</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'match_making.edit', params: {event_id: item._id}}" v-if="can('event_match_making_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Match_Making.png" class="asset_img" alt="Match Making">
                      <span>Match Making</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'blogs.index', params: {event_id: item._id}}" v-if="can('event_blog_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Blog.png" class="asset_img" alt="Blogs">
                      <span>Blogs</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'footer_link.index', params: {event_id: item._id}}" v-if="can('event_footer_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/footer_link.png" class="asset_img" alt="Blogs">
                      <span>Footer Link</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'tags.index', params: {event_id: item._id}}" v-if="can('event_tag_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Tags.png" class="asset_img" alt="Blogs">
                      <span>Tags</span>
                    </span>
                  </RouterLink>

                  <RouterLink :to="{name: 'feedbacks.index', params: {event_id: item._id}}" v-if="can('event_feedback_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/feedback_icon.png" class="asset_img" alt="Blogs">
                      <span>Feedback</span>
                    </span>
                  </RouterLink>
                  <RouterLink :to="{name: 'pages.index', params: {event_id: item._id}}"  v-if="can('event_page_access',checkPermssions)">
                  <span class="feature_box">
                    <img src="/assets/images/dashboard_event/Pages.png" class="asset_img" alt="logo">
                    <span>Pages</span>
                  </span>
                  </RouterLink> <!---->
                  <RouterLink :to="{name: 'leader_board.index', params: {event_id: item._id}}" v-if="can('event_leaderboard_access',checkPermssions)">
                   <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Leader_Board.png" class="asset_img" alt="Blogs">
                      <span>Leader Board</span>
                    </span>
                  </RouterLink>
               
                  <RouterLink :to="{name: 'event-assets.index', params: {event_id: item._id}}" v-if="can('event_assets_access',checkPermssions)">
                  <span class="feature_box">
                    <img src="/assets/images/dashboard_event/Assets.png" class="asset_img" alt="logo">
                    <span>Event Assets</span>
                  </span>
                  </RouterLink>
                  
                  
                  <RouterLink :to="{name: 'event-analytics.index', params: {event_id: item._id}}"  v-if="can('event_analytic_access',checkPermssions)">
                  <span class="feature_box">
                    <img src="/assets/images/dashboard_event/Analytics_Feedback.png" class="asset_img" alt="Event Analytics">
                    <span>Event Analytics</span>
                  </span>
                  </RouterLink>
                  <!---->
                   <!---->
                </div>
              </div>
            </div>
          </section>
          <section class="card mt-2">
            <header class="card-header">
              <h3 class="mt-0 mt-0 text-center">General Settings</h3>
            </header>
            <div class="card-body">
              <div class="row show-grid justify-content-md-center">
                <div class="event_features" v-if="item._id">
                  <RouterLink :to="{name: 'event_languages.index', params: {event_id: item._id}}">
                    <span class="feature_box">
                      <i class='bx bx-font-family'></i>
                      <span>Event Languages</span>
                    </span> <!---->
                  </RouterLink>

                  <span v-if="can('event_hall_access',checkPermssions)" style="margin-right:15px;">
                  <RouterLink v-if="item.event_hall_id" :to="{name: 'event_halls.edit', params: {event_id: item._id , event_hall_id:item.event_hall_id}}"  >
                    <span class="feature_box">
                      <i class='bx bx-buildings'></i>
                      <span>Event Hall</span>
                    </span>
                  </RouterLink>
                  </span>
                  <span v-if="can('exhibition_hall_access',checkPermssions)" style="margin-right:15px;">
                  <RouterLink v-if="item.event_exhibition_hall_id" :to="{name: 'event_exhibition_hall.edit', params: {event_id: item._id , event_exhibition_hall_id:item.event_exhibition_hall_id}}">
                    <span class="feature_box">
                      <i class='bx bx-buildings' />
                      <span>Exhibition Area</span>
                    </span>
                  </RouterLink>
                </span>

                  <RouterLink :to="{name: 'exhibitors.index', params: {event_id: item._id}}" v-if="can('event_exhibitor_access',checkPermssions)">
                    <span class="feature_box">
                      <i class='bx bxs-user-badge' />
                      <span>Event Exhibitors</span>
                    </span>
                  </RouterLink>
                  <RouterLink :to="{name: 'sponsors.index', params: {event_id: item._id}}" v-if="can('event_sponsor_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Sponsors.png" class="asset_img" alt="logo">
                      <span>Event Sponsors</span>
                    </span>
                  </RouterLink>
                  <RouterLink :to="{name: 'event_users.index', params: {event_id: item._id}}" v-if="can('event_user_access',checkPermssions)">
                    <span class="feature_box">
                      <img src="/assets/images/dashboard_event/Event_Users.png" class="asset_img" alt="logo">
                      <span>Event Users</span>
                    </span>
                  </RouterLink>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "OrganizerDashboardIndex",
  components: {GeneralDashboardLayout},
  data() {
    return {
    }
  },
  beforeUnmount() {
 
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role"}),
 
    ...mapGetters("EventsSingle", [
      "item",
      "loading",
    ])
  },
  created() {
    //this.fetchPermissions();
    this.verifyPermissions(this.$route.params.event_id)
    this.fetchData(this.$route.params.event_id)
    console.log('process.env', process.env)
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","verifyPermissions"]),
    ...mapActions("EventsSingle", ['fetchData']),
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  color: white;
  background: linear-gradient(90deg, #0058dd, #20abe6);
  min-height: 30px;

  h1 {
    text-align: center;
    font-size: 22px;
    padding: 0;
    margin: 16px 0 0 0;
  }
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event_features {
  max-width: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  a {
    text-decoration: none;
    margin-right: 15px;
  }

  .feature_box {
    padding: 15px 5px;
    box-shadow: 0px 0px 4px #ccc;
    width: 135px;
    display: grid;
    text-align: center;
    margin-bottom: 15px;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    word-break: break-word;
    background: linear-gradient(300deg, #094ac0, #1faae6);
    color: white;
    font-weight: 500;
    border-radius: 10px;

    span:nth-child(2) {
      line-height: 20px;
      vertical-align: middle;
    }

    a:nth-child(5) > .feature_box {
      margin-right: 0px;
    }
  }

  .feature_box:hover {
    background: linear-gradient(300deg, #34496f, #8ea9b4);
  }
}

.asset_img {
  display: block;
  margin: 0 auto;
  max-width: 84px;
}

.events-select-main {
  position: relative;

  button {
    border: none;
    color: white;
    background: linear-gradient(300deg, #135cba, #5aa0c2);
    padding: 8px 24px;
  }

  .list {
    top: 22px;
    width: 100%;
    min-width: 100px;
    background-color: white;
    border: 10px;
    box-shadow: 0px 0px 4px #ccc;

    .item {
      background-color: white;
      padding: 3px 12px;

      a {
        color: black;
        font-size: 16px;
        font-weight: bold;
      }

      &:hover {
        background-color: #ebebeb;
      }
    }

    .item:nth-child(2n) {
      background-color: #d5d5d5;
    }
  }
}

.bx {
  font-size: 5em;
  display: block;
  height: 80px;
}

.sel_event_type {
  background: linear-gradient(300deg, #094ac0, #1faae6);
  color: #ffffff;
  width: 200px;
  option {
    color: black
  }
}

.sel_event_type:after {
  content: '\f078';
  font: normal normal normal 17px/1 FontAwesome;
  color: #0ebeff;
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  border-left: 1px solid #0ebeff;
  position: absolute;
  pointer-events: none;
}
</style>