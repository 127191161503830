<template>
  <div class="switch switch-sm switch-primary" :style="(this.styleDisable)?'pointer-events:none;':''">
    <div :class="getClass()" @click="onClick">
      <div class="on-background background-fill"></div>
      <div class="state-background background-fill"></div>
      <div class="handle"></div>
    </div>
    <input type="checkbox" :name="name" :value="value" ref="htmlElement" style="display: none;">
  </div>
</template>

<script>
export default {
  name: "input-switch",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    styleDisable: {
      type: Boolean,
      default: false
    },
  },
  emits: ['change'],
  methods: {
    onClick(e) {
      this.$refs.htmlElement.checked = !this.value
      this.$emit('change', {target: this.$refs.htmlElement});
    },
    getClass() {
      let className = 'ios-switch ';

      if (this.value === true) {
        className += 'on'
      } else {
        className += 'off'
      }

      return className;
    }
  }
}
</script>

<style scoped>

</style>