import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all             : {},
        loaded          : false,
        loading         : false,
        userList        : {},
        graphReport     : {}
       

    }
}

const getters = {
    total               :   state   => state.all,
    loading             :   state   => state.loading,
    all                 :   state   => state.all,
    userList            :   state   => state.userList,
    graphReport         :   state   => state.graphReport
    

}

const actions = {

    fetchGraphData({ commit, state },surveyId) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/survey/feedback/'+surveyId+'/export', {headers: headers})
            .then(response => {
                commit('setGraphReport', response.data.data);
                return response;
            })
            .finally(() => {
             
                commit('setLoading', false)
            })
    },

    fetchData({ commit, state },surveyId) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/survey/feedback/'+surveyId+'/result', {headers: headers})
            .then(response => {
                commit('setLoaded',true)
                commit('setAll', response.data.data);
           
                return response;
            })
            .finally(() => {
                commit('setLoaded',true)
             
                commit('setLoading', false)
            })
    },

    fetchUserDetail({ commit, state },multiData) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let surveyId    = multiData.surveyId;
        let questionId  = multiData.questionId;
        return axios.get(constants.API_URL + '/api/survey/'+surveyId+'/user-detail/'+questionId, {headers: headers})
            .then(response => {
                if(response.data.data){
                    commit('setUserList', response.data.data);
                }else{
                    commit('setUserList', {});
                }
            
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

   
   
    resetState({ commit }) {
        commit('resetState')
    }
}
   

const mutations = {
    setGraphReport(state,value){
        state.graphReport = value
    },
    setUserList(state,value){
        state.userList  = value;
    },
    setAll(state, value){
        state.all = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setLoaded(state,value){
        state.loaded = value;
    },
    setChartMainChat(state,item){
        let dataChartQa     =   {}
        let lableQa         =   ['Chat', 'Video Call', 'Business Card', 'Business Card View'];
        let dataSetQa       =   {};
        let dataSetLableQa  =   [item.module_chat_total_attendees,item.module_video_call_attendees,item.module_busines_card_attendees,item.module_business_card_view_total_attendees];
        dataChartQa.labels  =   lableQa;
        dataSetQa.label     =   'People Participated in Chat , Video Call , Business Card , Bussiness Card View' ;
        dataSetQa.backgroundColor = '#003f5c';
        dataSetQa.data      =   dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartMainChat = dataChartQa;
        
    }   

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
