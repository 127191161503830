import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
function initialState() {
    return {
        all: [],
        relationships: {
            'role': 'title',
            'company': 'company_name',
        },
        loading: false,
        type: 'admin',
        countryCodesAll: []
    }
}

const getters = {
    all: state => state.all,
    total: state => state.all.length,
    loading: state => state.loading,
    relationships: state => state.relationships,
    type: state => state.type,
    countryCodesAll: state => state.countryCodesAll,
}

const actions = {
    fetchData({ commit, state, dispatch }, event_id) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/event-user/event-id/' + event_id, { headers: headers })
            .then(response => {
                commit('setAll', response.data.data)
                dispatch("fetchCountryCodesAll")

                return response;
            })
            .catch(error => {
                console.log(error)
                return error;
            })
            .finally(() => {
                commit('setLoading', false)
            })



    },

    fetchCountryCodesAll({ commit }) {
        axios.get(constants.API_URL + '/api/events/country-code')
            .then(response => {
                let agendaData = response.data.data;
                if (agendaData) {
                    agendaData.map((obj, i) => {
                        agendaData[i].name = obj.name + " (" + "+" + obj.id + ")";
                    })
                }
                commit('setCountryCodesAll', agendaData)

            })
    },
    destroyData({ commit, state }, data) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/event-user/' + data.event_id + '/' + data.user_id, { headers: headers })
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item._id != data.user_id
                }))

                return response
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                console.log(message)
            })
    },
    restoreData({ commit, state }, data) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        let params = {
            event_id: data.event_id,
            user_id: data.user_id
        };

        return axios.post(constants.API_URL + '/api/event-user/un-delete-event-from-user', params, { headers: headers })
            .then(response => {
                return response
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                console.log(message)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setType({ commit }, type) {
        commit('setType', type)
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setType(state, type) {
        state.type = type;
    },
    setCountryCodesAll(state, value) {
        state.countryCodesAll = value
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
