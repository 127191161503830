<template>
    <div class="row">

        <div class="col-md-5 event_detail_ls">
            <div class="event_detail_wrapper">
                <div class="row">

                    <div class="event_ls_label col-md-2">Module Type</div>
                    <div class="col-md-8">{{ item.module_type }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Title</div>
                    <div class="col-md-8">{{ item.translations[0]['title'] }}</div>
                </div>
            </div>
        </div>   
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"
                @click="editData(item)">Edit</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger"
                @click="deleteData(item)">Delete</button>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
export default {
    name: "ShowItem",
    data() {
        return {
           
        }
    },
    props: {
       item:null,
       docId:null,
       id:null
        
    },
    methods: {
     
        ...mapActions('SectioncontentsIndex', [ 'destroyData','fetchData']),
        ...mapActions('SectioncontentsSingle', ['setItem', 'setIsNew', 'setIsEditing', 'setLangKey', 'setLangTitle', 'setLangDescirption']),
        deleteData(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then( result => {
                if (result.isConfirmed) {
                        this.destroyData({docId:this.docId, id:item._id}).then((resp) => {
                        console.log("Resp",resp);
                          this.$flashMessage.show({
                              group : 'page_content_index',
                              type  : 'success',
                              title : 'Success',
                              html  : '<ul><li> Page Content Section has been deleted successfully.</li></ul>'
                          });
                            this.setIsNew(false);
                            this.setIsEditing(false);
                            this.fetchData(this.docId);
                  
                      })
                  }
            });
        },
        editData(item) {
            if (!_.isEmpty(item.translations)) {
                let defaultLanguage = item.translations[0];
                if (_.has(defaultLanguage, 'key')) {
                    this.setLangKey(defaultLanguage['key'])
                }
                if (_.has(defaultLanguage, 'title')) {
                    this.setLangTitle(defaultLanguage['title'])
                }
                if (_.has(defaultLanguage, 'description')) {
                    this.setLangDescirption(defaultLanguage['description'])
                }
            }
            this.setItem(item);
            this.setIsNew(false);
            this.setIsEditing(true);
            this.$forceUpdate();
        },
       
    },

}
</script>

<style lang="scss" scoped>
.user_img {
    img {
        width: 100%;
    }
}

.event_detail_wrapper {
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }

    .row {
        .col-md-8 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }

        .col-md-3 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }
    }

    .card-body {
        box-shadow: 0px 0px #ffffff;
    }
}

.tabs-vertical {
    border-top: 0px;
}

.toggle-content {
    background: #fff;
}
</style>
