<template>
  <div class="row">
    <div class="col-md-2">
      <div class="user_img">
        <img  :src="IMAGE_URL + sponsor.logo.key" alt="pic" />
      </div>
    </div>
    <div class="col-md-10 event_detail_ls">
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Sponsor Name</div>
          <div class="col-md-10">{{getName()}}</div>
        </div>
      </div>

      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Sponsor Link</div>
          <div class="col-md-10">{{getLink()}}</div>
        </div>
      </div>

      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Sponsor Category</div>
          <div class="col-md-10">{{getCategory()}}</div>
        </div>
      </div>
    
      </div>
      <div class="text-right mt-3 mr-5">
      <button type="button" v-if="can('event_sponsor_edit',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(sponsor._id)">Edit</button>
      <button type="button" v-if="can('event_sponsor_delete',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="removeCompany(sponsor._id)">Delete</button>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from "lodash";
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "EventSponsorsShow",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    sponsor: {
      type: Object,
      default: () => {}
    },
    checkPermssions:null,
  },
  methods: {
    ...mapActions('SponsorsIndex', ['fetchData', 'setEditId']),
    ...mapActions('SponsorsSingle', ['setIsNew', 'destroyData']),

    getName() {
      return _.get(this.sponsor, 'name', '');
    },
    getLink() {
      return _.get(this.sponsor, 'link', '');
    },
    getCategory() {
      return _.get(this.sponsor, 'category', '');
    },

    editData(id) {
      console.log(id)
      this.setEditId(id);
      this.setIsNew(false);
    },
    removeCompany(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData({ event_id: this.$route.params.event_id, id: id })
              .then(() => {
                this.$flashMessage.show({
                        group: 'event_sponsors',
                        type: 'success',
                        title: 'Success',
                        text: 'Event sponsor deleted successfully.'
                      });
                this.fetchData(this.$route.params.event_id);
              });
        }
      });
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>


<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
}
</style>