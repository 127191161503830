import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id: null,
            company: null,
            first_name: null,
            last_name: null,
            salutation: null,
            email: null,
            password: null,
            role: null,
            access_level: null,
            job_title: null,
            phone: null,
            Address: null,
            city: null,
            country: null,
            picture: null,
            event_access: [],
            links: [],
        },
        subscriptionplansAll: [],
        is_new: false,
        is_editing: false,
        userAccount: [],
        rolesAll: [],
        companiesAll: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    rolesAll: state => state.rolesAll,
    companiesAll: state => state.companiesAll,
    userAccount: state => state.userAccount,
    subscriptionplansAll: state => state.subscriptionplansAll,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        //   dispatch('Alert/resetState', null, { root: true })

        let params = _.cloneDeep(state.item);

        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;

        if (!_.has(params.picture, 'key')) {
            delete params.picture;
        }

        if (params.picture) {
            params.picture = {
                old_key: state.item.picture.key,
                temp_key: state.item.picture.temp_key,
                size: state.item.picture.size,
                extension: state.item.picture.extension,
                uploadedBy: state.item.picture.uploadedBy,
                uploadedAt: state.item.picture.uploadedAt,
            }
        }

        return axios.post(constants.API_AUTH_URL + '/api/v1/users', params)
            .then(response => {
                commit('resetState')
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {

        commit('setLoading', true)
        //dispatch('Alert/resetState', null, { root: true });

        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;

        delete params.picture;

        if (_.has(state.item.picture, 'key')) {
            let imgPath = _.replace(state.item.picture.key, '/draft', '');
            params.picture = {
                old_key: (!_.isNull(state.item.picture.key)) ? state.item.picture.key : null,
                key: imgPath,
                size: state.item.picture.size,
                extension: state.item.picture.extension,
                uploadedBy: state.item.picture.uploadedBy,
                uploadedAt: state.item.picture.uploadedAt,
            }

        } else {
            params.picture = {}
        }

        return axios.patch(constants.API_AUTH_URL + '/api/v1/users/' + state.item._id, params)
            .then(response => {
                commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchData({ commit, dispatch }, id) {
        return axios.get(constants.API_AUTH_URL + '/api/v1/users/' + id)
            .then(response => {
                const item = _.cloneDeep(response.data.data);

                if (item.username) {
                    delete item.username;
                }

                commit('setItem', item);
            })
    },
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    setFirstName({ commit, dispatch }, value) {
        commit('setFirstName', value)
    },
    setLastName({ commit, dispatch }, value) {
        commit('setLastName', value)
    },
    setCompany({ commit }, value) {
        commit('setCompany', value)
    },
    setEmail({ commit }, value) {
        commit('setEmail', value)
    },
    setPassword({ commit }, value) {
        commit('setPassword', value)
    },
    setRole({ commit }, value) {
        commit('setRole', value)
    },
    setSalutation({ commit }, value) {
        commit('setSalutation', value)
    },
    setPicture({ commit }, value) {

        commit('setPicture', value)
    },
    setJobTitle({ commit }, value) {
        commit('setJobTitle', value)
    },
    setPhone({ commit }, value) {
        commit('setPhone', value)
    },
    setAddress({ commit }, value) {
        commit('setAddress', value)
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setAccessLevel({ commit }, value) {
        commit('setAccessLevel', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setFirstName(state, value) {
        state.item.first_name = value
    },
    setLastName(state, value) {
        state.item.last_name = value
    },
    setEmail(state, value) {
        state.item.email = value
    },
    setPassword(state, value) {
        state.item.password = value
    },
    setRole(state, value) {
        state.item.role = value
    },
    setCompany(state, value) {
        state.item.company = value
    },
    setSalutation(state, value) {
        state.item.salutation = value;
    },
    setPicture(state, value) {
        state.item.picture = value;
    },
    setJobTitle(state, value) {
        state.item.job_title = value;
    },
    setPhone(state, value) {
        state.item.phone = value;
    },
    setAddress(state, value) {
        state.item.Address = value;
    },
    setCity(state, value) {
        state.item.city = value;
    },
    setCountry(state, value) {
        state.item.country = value;
    },
    setAccessLevel(state, value) {
        state.item.access_level = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
