import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
function initialState() {
    return {
        all: [],
        allContent:{},
        loading: false,
        leaderboardReport:[]
    }
}

const getters = {
    all                 :       state => state.all,
    loading             :       state => state.loading,
    allContent          :       state => state.allContent,
    leaderboardReport   :       state => state.leaderboardReport,
}
  

const actions = {

    fetchLeaderboardReport({commit,state},event_id){
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(constants.API_URL + '/api/events/leader_board/'+event_id+'/report',{headers:headers})
            .then(response => {
                console.log("response.data.data",response.data.data);
                commit('setLeaderboardReport', response.data.data)
         
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, state },event_id) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(constants.API_URL + '/api/events/'+event_id+'/leader_board',{headers:headers})
            .then(response => {
                console.log("response.data.data",response.data.data.reward_modules);
                commit('setAll', response.data.data.reward_modules)
                commit('setAllContent',response.data.data.contents)
        
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
     
       /* axios.delete(constants.API_URL + '/api/events/'+event_id+'/leader_board' + id,{headers:headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })*/
    },
    
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setLeaderboardReport(state,item){
        state.leaderboardReport = item;
    },
    setAllContent(state,item){
        state.allContent    = item;
    },
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
