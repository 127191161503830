import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        all: [],
        loading: false,
        event: {},
        event_timezone: null,
        event_type:null,
        languages: [],
        moderator:[],
        exhibitor:[],
    }
}

const getters = {
    all: state=> state.all,
    event: state => state.event,
    loading: state => state.loading,
    event_timezone: state => state.event_timezone,
    event_type: state => state.event_type,
    languages: state => state.languages,
    moderator: state => state.moderator,
    exhibitor: state => state.exhibitor,
    

}

const actions = {
    fetchData({ dispatch,commit, state }, event_id) {


        
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let panelreq = axios.get(`${constants.API_URL}/api/panel-discussions/event-id/${event_id}`, {headers: headers});
        let eventReq = axios.get(`${constants.API_URL}/api/events/event-id/${event_id}`, {headers: headers});        
        let totalpanelistReq = axios.get(`${constants.API_URL}/api/event-user/event-id/${event_id}`, {headers: headers});
        let exhibitorlistreq = axios.get(`${constants.API_URL}/api/events/${event_id}/exhibitors`, {headers: headers});

      

        Promise.all([panelreq, eventReq, totalpanelistReq, exhibitorlistreq]).then((response) => {          

            let panelreqdata = response[0].data.data;
            let eventInfo = response[1].data.data;           
            let moderatorinfo = response[2].data.data;
            let exhibitorlist = response[3].data.data;
            let canBeModerator = [];
            let canBeExhibitor = [];              

         

           
            _.forEach(moderatorinfo, function (value) {
                canBeModerator.push({ id: `${value._id}`, name: `${value.first_name} ${value.last_name}` })
            });   
                
            _.forEach(exhibitorlist, function (value) {
               
               
                canBeExhibitor.push({ id: `${value._id}`, name: `${value.translations[0].name}` })

            }); 
           
           

           
                commit('setAll', panelreqdata);
                commit('setEvent', eventInfo);
               // commit('setLanguages', eventLanguages);
                commit('setModerator', canBeModerator);
                commit('setExhibitor', canBeExhibitor);
               // return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    getEventDetails({commit, state}, params){
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/'+params.id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type);
                return response;
            });

    },
    destroyData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/panel-agendas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            });
    },
    destroyQaData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/qas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.agenda_qas = item.agenda_qas.filter((qa) => {
                        return qa.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyPanelData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/agenda-panelists/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.panelists = item.panelists.filter((panel) => {
                        //debugger;
                        return panel.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/surveys/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        //debugger;
                        return survey.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyQuestion({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/survey-questions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.survey_questions = survey.survey_questions.filter((question)=>{
                            return question.id != id
                        })
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateAnswer({ commit, state, dispatch }, id) {
        commit('setLoading', true);
        const params = {

        }
        axios.put('/api/v1/qas/' + id, params)
            .then(response => {
                // commit('setAll', state.all.filter((item) => {
                //     return item.agenda_qas = item.agenda_qas.filter((qa) => {

                //         return qa.id != id
                //     })
                // }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEventType(state, value){
        state.event_type = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEvent(state, items) {
        state.event = items
    },
    setLanguages(state, items) {
        state.languages = items
    },
    setModerator(state, items) {
        state.moderator = items
    },
    setExhibitor(state, items) {
        state.exhibitor = items
    },

    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
