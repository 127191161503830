<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'dashboard.index' }" />
        <h1 class="title text-center">Booth Presets</h1>
        <button class="btn btn-primary" @click="createClick" v-if="is_new === false">Create a New Presets</button>
        <button class="btn btn-primary" @click="cancelClick" v-else>Cancel</button>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid company-container">
        <div class="row">
          <div class="col-lg-12">
            <div id="live-presentations">
              <OverlayLoading :isLoading="loading" />
              <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row search_bar">
                      <div class="col-md-2">
                        <div class="input-group w-70">
                          <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <span class="ml-5 rpg_txt">records per page</span>
                      </div>
                      <div class="col-md-2 offset-md-8 ">
                        <div class="input-group">
                          <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                          <span class="input-group-text">
                            <i class="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="labelhdwrapper row">
                  <span class="col1">Preset Type</span>
                  <span class="col2">Preset Name</span>
                </div>
                <TogglePrimaryActive v-if="is_new">
                  <template v-slot:default>
                    <BoothPresetsCreateForm />
                  </template>
                </TogglePrimaryActive>
                <TogglePrimary v-for="(booth_presets, index) in props.all" v-bind:key="index">
                  <template v-slot:label>
                      <span class="labelwrapper">
                        <span class="col1">{{ getPresetType(booth_presets.preset_type) }}</span>
                        <span class="col2">{{ booth_presets.name }}</span>
                      </span>
                </template>
   
                  <template v-slot:default>
                     <BoothPresetsEditForm :preset_id="booth_presets._id" v-if="edit_id === booth_presets._id" />
                     <ShowItem :booth_presets="booth_presets" v-else />
                  </template>
                </TogglePrimary>
              </SearchableContainer>
            </div>
          </div>
             <FlashMessage group="booth_presets" />
     
        </div>
      </div>
    </template>
  </GeneralDashboardLayout>
</template>


<script>
import _ from "lodash";
import { mapGetters, mapActions } from 'vuex'
import GeneralDashboardLayout from '../../layouts/GeneralDashboardLayout';
import BackButton from "@/components/Commons/BackButton";
import BoothPresetsEditForm from "@/components/cruds/BoothPresets/Forms/Edit";
import BoothPresetsCreateForm from "@/components/cruds/BoothPresets/Forms/Create";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/BoothPresets/Forms/Show";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import { reactive } from 'vue'
import $ from 'jquery';
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

let isActive = reactive({})
export default {
  name: 'BoothPresetsIndex',
  components: {SearchableContainer, ShowItem, TogglePrimary, BackButton, GeneralDashboardLayout,BoothPresetsEditForm,BoothPresetsCreateForm,TogglePrimaryActive},
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.booth_presets;
  },
  created() {
    const access_level = localStorage.getItem('access_level');
    if(access_level =='event'){
      this.$router.push({name: 'notFound'});
    }
    this.fetchData()
  },
  unmounted() {
    this.resetIndexState()
    this.resetState()
  },
  computed: {
    ...mapGetters('BoothPresetsIndex', ['all', 'total', 'loading', 'edit_id']),
    ...mapGetters('BoothPresetsSingle', ['item', "is_new","eventTypes"]),
  },
  methods: {
    ...mapActions('BoothPresetsIndex', {fetchData: "fetchData", resetIndexState: "resetState"}),
    ...mapActions('BoothPresetsSingle', [ 'resetState', 'setIsNew']),
    createClick() {
      this.setIsNew(true);
      //$('.toggle-content').addClass('makeBold')
      $('.active').css('opacity', 0); 
    },
    cancelClick() {
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
    getPresetType(type){
      const getSelectedPreset = _.find(this.eventTypes, i => i.id ===type);
      return _.get(getSelectedPreset, 'title', '');
    }
  }
}
</script>

<style scoped lang="scss">



.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.company-container {
  .form-control {
    font-family: 'Poppins';
  }
  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
