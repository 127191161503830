<template>
    <div class="tabs-component">
               <ul class="nav nav-tabs nav-justified">
                 <li class="nav-item">
                   <a class="nav-link active" data-bs-target="#tab_chat" href="#tab_chat" data-bs-toggle="tab">Chat</a>
                 </li>
                 <li class="nav-item">
                   <a class="nav-link" data-bs-target="#tab_video_call" href="#tab_video_call" data-bs-toggle="tab">Video Call</a>
                 </li>

                 <li class="nav-item">
                   <a class="nav-link" data-bs-target="#tab_business_card" href="#tab_business_card" data-bs-toggle="tab">Business Card</a>
                 </li>

                 <li class="nav-item">
                   <a class="nav-link" data-bs-target="#tab_business_card_view" href="#tab_business_card_view" data-bs-toggle="tab">Business Card View</a>
                 </li>

                 
               </ul>
       </div>
       <div class="tab-content">
            <div id="tab_chat" class="tab-pane active">
                <div class="snippet general-event-config-snippet">
                    <TogglePrimary v-for="(data, index) in tabChat2" :key="index">
                    <template v-slot:label >
                        <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}}  {{data.last_name}}   {{data.company}}  {{data.job_title}} </span>
                         
                        </span>
                    </template>
                    <div id ="edit-content">
                        <TabShow :data="data"></TabShow>
                    </div><!-- End Content-->
        

            </TogglePrimary>
                    
            </div><!-- End of snippet-->

            </div>

            <div id="tab_video_call" class="tab-pane">

                <div class="snippet general-event-config-snippet">
                    <TogglePrimary v-for="(data, index) in tabVideoCall" :key="index">
                    <template v-slot:label >
                        <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}}  {{data.last_name}}</span>

                        </span>
                    </template>
                    <div id ="edit-content">
                        <TabShow :data="data"></TabShow>
              
                        
                    </div><!-- End Content-->
        

            </TogglePrimary>
                    
            </div><!-- End of snippet-->


            </div>

            <div id="tab_business_card" class="tab-pane">
                <div class="snippet general-event-config-snippet">
                    <TogglePrimary v-for="(data, index) in tabBusinessCard" :key="index">
                    <template v-slot:label >
                        <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}}  {{data.last_name}}</span>
                     
                        </span>
                    </template>
                    <div id ="edit-content">
                        <TabShow :data="data"></TabShow>
              
                        
                    </div><!-- End Content-->
        

            </TogglePrimary>
                    
            </div><!-- End of snippet-->

            </div>

            <div id="tab_business_card_view" class="tab-pane">
                <div class="snippet general-event-config-snippet">
                    <TogglePrimary v-for="(data, index) in tabBusinessCardView" :key="index">
                    <template v-slot:label >
                        <span class="labelwrapper" >
                            <span class="col1"> {{data.first_name}}  {{data.last_name}}</span>
                     
                        </span>
                    </template>
                    <div id ="edit-content">
                        <TabShow :data="data"></TabShow>
                    </div><!-- End Content-->
        

            </TogglePrimary>
                    
            </div><!-- End of snippet-->

            </div>
        </div>
                

               
</template>

<script>
import {
mapGetters,
mapActions
} from 'vuex'
import _ from "lodash";

import SearchableContainer  from "@/components/Commons/SearchableContainer";
import TogglePrimary        from "@/components/Commons/TogglePrimary";
import TabShow              from "@/components/cruds/EventAnalytics/Forms/ShowChat";


export default {
name: 'AnalyticsTab',
components: {
    TogglePrimary,
    TabShow
},
data() {
return {
   search: null,
   perPage: 5,
   system_users: [],
}
},
props:{
headers:null
},
watch: {
},
created() {
// this.fetchPermissions()

},
beforeUnmount() {
//this.resetState();

},
computed: {
       ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
       ...mapGetters('EventAnalyticsIndex', ['tabVideoCall','tabBusinessCard','tabBusinessCardView','tabChat2','chartMainTab','loading', 'all', 'headers','enumModules','loaded','tabAttendence','tabQa','tabPolling','tabRating','tabChat']),
},
methods: {
...mapActions('EventAnalyticsIndex', ['fetchData','resetState']),

}
}
</script>