import constants from "@/config/constants";
import axios from "axios";
import _ from "lodash";

function initialState() {
    return {
        all: [],
        loading: false,
    }
}

const getters = {
    all:         state => state.all,
    loading:       state => state.loading,
}

const actions = {
    fetchData({ commit, state }, id) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/events/event-id/' + id + '/languages', {headers: headers})
            .then(response => {
                const languageIds = _.get(response, 'data.data.languages')
                const languages = _.map(languageIds, 'language_id')

                commit('setAll', languages)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
