<template>
  <router-view></router-view>
</template>

<style>

#app {
  font-family: 'Roboto Condensed', sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  letter-spacing: 0.4px;
}

nav {
  padding: 30px;
}

nav a {
  color: #525252;
}

nav a.router-link-exact-active {
  color: #1e1e1e;
}

.header {
   
    border-top: 0px;
  }
</style>
