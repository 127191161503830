<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Webcast</h1>
        <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create New</button>
        <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid webcast-container">
        <div class="row">
          <div class="col-lg-12">
            <OverlayLoading :isLoading="loading" />
            <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="perPage">
              <div class="row">
                <div class="col-md-12">
                  <div class="row search_bar">
                    <div class="col-md-2">
                      <div class="input-group w-70">
                        <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span class="ml-5 rpg_txt">records per page</span>
                    </div>
                    <div class="col-md-2 offset-md-8 ">
                      <div class="input-group">
                        <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                        <span class="input-group-text">
                          <i class="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelhdwrapper row">
                <span class="col1">Title</span>
                <span class="col2">Speaker</span>
                <span class="col3">Support User</span>
                <span class="col4">Start Time</span>
                <span class="col5">End Time</span>
              </div>
              <TogglePrimary v-if="is_new">
                <template v-slot:default>
                  <WebcastCreateForm />
                </template>
              </TogglePrimary>
              <TogglePrimary v-for="(agenda, index) in props.all" v-bind:key="index">
                <template v-slot:label>
                  <span class="labelwrapper">
                      <span class="col1">{{agenda.event.language.length && agenda['title_'+agenda.event.language[0].language] }}</span>
                      <span class="col2"></span>
                      <span class="col3"></span>
                      <span class="col4"></span>
                  </span>
                </template>
                <template v-slot:default>
                  <WebcastShow :item="agenda" />
                  <!--                  <LivePresentationEditForm :id="livePresentation._id" v-if="is_new === false && is_editing === true && livePresentation._id === item._id" :event="livePresentation" :languages="languages"/>-->
                  <!--                  <ShowItem :presentation="livePresentation" :id="livePresentation._id"  :speaker="loadSpeaker(livePresentation.speaker)" v-else />-->
                </template>
              </TogglePrimary>
            </SearchableContainer>
          </div>
        </div>
        <FlashMessage group="webcast" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import WebcastCreateForm from "@/components/cruds/Webcasts/Forms/Create";
import WebcastShow from "@/components/cruds/Webcasts/Forms/Show";

export default {
  name: "WebcastsIndex",
  components: {
    WebcastCreateForm,
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    WebcastShow
  },
  computed: {
    ...mapGetters('WebcastsIndex', ['loading', 'all']),
    ...mapGetters('WebcastsSingle', ['item', 'is_new', "is_editing"]),
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.webcast;
  },
  created() {
    this.fetchData(this.$route.params.event_id).catch(() => {
      this.$flashMessage.show({
        group: 'webcast',
        type: 'error',
        title: 'Error',
        text: 'Something went wrong.'
      });
    })
  },
  methods: {
    ...mapActions('WebcastsIndex', ['fetchData']),
    ...mapActions('WebcastsSingle', ['resetState', "setIsNew", "setIsEditing"]),
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
  }
}
</script>

<style scoped lang="scss">
.webcast-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 30%;
    display: inline-flex;
  }

  .col2 {
    width: 20%;
    display: inline-flex;
  }

  .col3 {
    width: 20%;
    display: inline-flex;
  }

  .col4 {
    width: 15%;
    display: inline-flex;
  }
  .col5 {
    width: 15%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }

  .card-box {
    background: #cccccc4d;
    border-radius: 10px;
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
  }
}
</style>