<template>
  <div class="drop-files container-fluid" :class="{'opened': isOpened}">
    <div class="header-container row align-center" @click="dragOpen">
      <div class="col-5">
        <div class="drop-files--header">
          <slot name="header" />
        </div>
      </div>
      <div class="col-2 pa-1">
        <div class="drop-files--tooltip">
          <slot name="tooltip" />
        </div>
        <div class="drop-files--file-title">
          <img src="/assets/images/drop-files/file.png" alt="img">
          <div class="drop-files--file-info">
            <p v-if="file">{{ getFileName() }}</p>
            <p v-else>File Name </p>
            <a href="#" class="btn-preview" @click.prevent="previewClick" v-if="file">Preview</a>
          </div>
        </div>
      </div>
      <div class="col-1 pa-1">
        <div class="drop-files--file-dimmention">
          <span>{{ getAcceptString() }}</span>
        </div>
      </div>
      <div class="col-1 pa-1">
        <div class="drop-files--file-size">
          <span>{{ getFileSizeString() }} MB</span>
        </div>
      </div>
      <div class="col-2 pa-1">
        <div class="drop-files--file-author">
          <span class="user-icon">UN</span>
          <p class="user-name">{{ getAuthorFullNameString() }}</p>
        </div>
      </div>
      <div class="col-1 pa-1" v-if="!isOpened">
        <button class="btn-remove" @click.prevent="deleteFile" :disabled="!file">
          <img src="/assets/images/drop-files/del.png" alt="del" />
        </button>
      </div>
    </div>
    <div class="drop-container row">
      <div class="file--wrapper">
          <button @click.prevent="open" :disabled="!!file">Choose file</button>
          <div v-bind="getRootProps()">
            <input v-bind="getInputProps()" />
            <p v-if="isDragActive" class="file--empty-text">or drop the files here ...</p>
            <p v-else class="file--empty-text">Drag 'n' drop some files here, or click to select files</p>
          </div>
          <button class="file--delete" @click.prevent="deleteFile" :disabled="!file">Delete</button>
        </div>
    </div>
    <vue-final-modal v-model="isPreviewOpened" v-if="isPreviewOpened && file" classes="modal-container" content-class="modal-content">
      <iframe :src="'https://s3.eu-central-1.amazonaws.com/emp-assets.avianet.aero/' + file.key" frameborder="0"></iframe>
      <span class="modal-close" @click.prevent="isPreviewOpened = false" />
    </vue-final-modal>
  </div>
 <div>

 </div>
</template>
  
<script>
import { useDropzone } from "vue3-dropzone";
import { upload, remove } from "@/services/FileUploader";
import ObjectID from 'bson-objectid';
import _ from 'lodash';

export default {
  name: "DropFiles",
  data() {
    return {
      isOpened: false,
      isPreviewOpened: false,
    }
  },
  emits: ['onUploaded', 'onRemoved', 'onError'],
  props: {
    file: {
      type: [Object, null],
      default: null
    },
    accept: String,
    module_type: String,
    module_id: ObjectID,
    dimmention: {
      type: [String, null],
      default: null
    },
    asset_type: String,
    requirements: {
      type: String,
      default: '1920x1080 pixels, max 200mb, mp4',
    }
  },
  setup(props, { emit }) {
    const onDrop = (acceptFiles, rejectReasons) => {
      if (rejectReasons.length > 0) {
        console.log('rejectReasons', rejectReasons);
        emit('onError', rejectReasons)
      } else {
        const file = acceptFiles[0];
        const id = props.module_id;

        upload(file, id, props.module_type, props.dimmention, props.asset_type)
            .then((resp) => {
              emit('onUploaded', {id, user, file}, resp.data)
            })
            .catch((err) => {
              console.error(err);
              emit('onError', err)
            });
      }
    };

    const accept = _.clone(props.accept);

    const { getRootProps, getInputProps, ...rest } = useDropzone({ accept, onDrop });

    const user = JSON.parse(localStorage.getItem('user'));

    return {
      getRootProps,
      getInputProps,
      user,
      ...rest,
    };
  },
  methods: {
    previewClick(e) {
      this.isPreviewOpened = true;
    },
    dragOpen(event) {
      if (event.target.className === 'btn-preview') {
        return;
      }

      if (event.target.className === 'btn-remove') {
        return;
      }

      this.isOpened = !this.isOpened;
    },
    getFileName() {
      if (this.file && this.file.new_key) {
        const fileExtension = this.file.new_key.split('/').pop();
        return fileExtension ? fileExtension : '';
      }
      if (this.file && this.file.key) {
        const fileExtension = this.file.key.split('/').pop();
        return fileExtension ? fileExtension : '';
      }
      if (this.file && this.file.old_key) {
        const fileExtension = this.file.old_key.split('/').pop();
        return fileExtension ? fileExtension : '';
      }

      return '';
    },

    getAcceptString() {
      if (this.accept) {
        const extension = this.accept.split('/').pop();
        return extension ? extension : '';
      }

      return '';
    },
    getFileSizeString() {
      if (this.file === null || this.file === undefined) {
        return '';
      }

      if (this.file.size) {
        return (Number(this.file.size) / (1024*1024)).toFixed(3);
      }
    },
    getAuthorFullNameString() {
      return this.file ? this.file.uploadedBy : '';
    },
    deleteFile(event) {
      this.$swal({
        title: 'Are you sure?',
        text: "To fully delete the file submit the form.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dd4b39',
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (typeof result.dismiss === "undefined") {
          remove(this.file.key)
              .then((e) => {
                if (e.data.status === 'OK') {
                  this.$emit('onRemoved', e);
                } else {
                  this.$emit('onError', e)
                }
              })
              .catch((err) => {
                console.log('remove err', err)
                this.$emit('onError', err)
              })
        }
      })
    },
  }
};
</script>


<style lang="scss">
.drop-files {
  padding: 6px 26px;
  background-color: white;
  color: #7d848e;
  font-size: 1.2rem;
  box-shadow: 1px 0px 6px 0px #32323259;
  
  margin-bottom: 24px;

  &:not(.opened) {
    cursor: pointer;
    border-left: 4px solid #0970df;
  }

  &.opened {
    background-color: #ededef;

    .drop-container {
      transition: all ease 0.3s;
      display: block;
      opacity: 1;
    }
  }

  .header-container {
    .btn-remove {
      border: 1px solid #eeeeee;
      border-radius: 3px;
      background: transparent;
      width: 26px;
      height: 26px;
      padding: 2px 2px;
      transition: all ease 0.2s;

      &:hover {
        background: #eeeeee;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .drop-container {
    transition: all ease 0.3s;
    display: none;
    opacity: 0;
    padding: 9px 24px;
  }

  .align-center {
    align-items: center;
  }

  p {
    margin: 0;
  }

  &--requirements,
  &--file-title,
  &--file-dimmention,
  &--file-size,
  &--file-author {
    font-size: 12px;
    margin: 6px 0;
    word-wrap: break-word;
  }

  &--header {
    * {
      font-size: 0.8rem;
      margin: 0;
    }
    h1 {font-size: 1.2rem;}
    h2 {font-size: 1.1rem;}
    h3 {font-size: 1rem;}
    h4 {font-size: 0.9rem;}
    h5 {font-size: 0.8rem;}
    h6 {font-size: 0.7rem;}
  }

  &--tooltip {
    float: left;
    margin-top: 0px;
    margin-right: 6px;
  }

  &--requirements {
    font-size: 10px;
  }

  &--title {
    font-size: 12px;
    font-weight: bold;
    color: black;
  }

  &--file-dimmention {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5em;
    white-space: nowrap;
    >span {
      color: #3a7bef;
      background: #e5e2eb;
      padding: 2px 6px;
      border-radius: 10px;
    }
  }

  &--file-title {
    font-size: 10px;
    display: flex;
    align-items: center;

    .drop-files--file-info {
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .btn-preview {
        margin: 0;
        padding: 0;
        background-color: #1b9ee4;
        color: white;
        text-decoration: none;
        padding: 2px 6px;
        display: inline-block;
        border-radius: 3px;
        font-size: 9px;
      }
    }

    img {
      width: 20%;
    }

    p {
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 1.2em;
      white-space: nowrap;
    }
  }

  &--file-author {
    .user-icon {
        border-radius: 50%;
        color: #5a5d68;
        background-color: #d1d1dc;
        float: left;
        padding: 5px;
        margin-right: 12px;
        font-weight: bold;
      }
    .user-name {
      font-weight: bold;
      font-size: 12px;
      line-height: 1.2;
    }
  }

  .file--wrapper {
    background: #e4e1ec;
    padding: 15px 35px;
    border-radius: 5px;
    text-align: center;
    border: 2px dashed #cabbe6a1;
    position: relative;

    button {
      background-color: #3a7bef;
      padding: 4px 37px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 14px;

      &:disabled {
        background-color: #c7c7c7;
        cursor: not-allowed;
      }
    }
  }

  .file--empty-text {
    margin-top: 11px;
    color: #7d848e;
    font-size: 12px;
  }

  button.file--delete {
    position: absolute;
    right: 10px;
    top: 16px;
    background-color: #3a7bef;
    border: 2px solid #bfbfbf;

    &:disabled {
      background-color: #c7c7c7;
      cursor: not-allowed;
    }
  }

  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    position: relative;
    width: 50%;
    min-height: 300px;
    height: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .modal-close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
</style>