<template>
  <div class="snippet event-domain-snippet">
    <div class="form-group row">
      <div class="col-lg-12">
        <DropFiles :file="validate_file(item.exhibition_video)" :module_id="item._id"
          @onUploaded="updateExhibitionVideo" @onRemoved="removeExhibitionVideo" :accept="'video/mp4'"
          module_type="event-hall" asset_type="'exhibition_video'" @onError="errCb">
          <template #header>
            <label>Exhibition Video</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
      <div class="col-lg-12">
        <DropFiles :file="validate_file(item.exhibition_document)" :module_id="item._id"
          @onUploaded="updateExhibitionDocument" @onRemoved="removeExhibitionDocument" :accept="'application/pdf'"
          :module_type="'event-hall'" :asset_type="'exhibition_document'" @onError="errCb">
          <template #header>
            <label>Exhibition Document</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-1">
        <label>Version 2.0.0</label>
        <br />
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_page_design" :value="getEventPageDesign(item.event_page_design)"
            class="custom-control-input" @change="updateEventPageDesign" />
        </div>
      </div>
      <div class="col-lg-10">
        <label>Schedule type</label>
        <v-select placeholder="Schedule Type" label="label" @option:selected="updateScheduleType"
          :modelValue="getScheduleTypeObject(item.schedule_type)" :options="schedule_types_list" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-12">
        <label>Schedule description</label>
        <div class="kt-input-icon">
          <textarea rows="3" maxlength="255" class="form-control" name="login_message"
            placeholder="Enter Schedule Descriptions" :value="item.schedule_description"
            @input="updateScheduleDescription"></textarea>
        </div>
      </div>
    </div>
  </div>
  <FlashMessage group="event_hall_theme" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from 'lodash';
import InputSwitch from "@/components/Commons/InputSwitch";
import ObjectID from 'bson-objectid';

export default {
  name: "EventDomainSnippet",
  data() {
    return {
      moduleId: ObjectID(),
    }
  },
  components: {
    InputSwitch
  },

  beforeUnmount() {
    delete this.$flashMessage.groups.event_hall_theme;
  },
  computed: {
    ...mapGetters("EventHallsSingle", [
      "item",
      "loading",
      "schedule_types_list"
    ]),
  },
  methods: {
    ...mapActions("EventHallsSingle", [
      "setEventPageDesign",
      "setExhibitionVideo",
      "setExhibitionDocument",
      "setScheduleType",
      "setScheduleDescription",
    ]),
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    updateEventPageDesign(e) {
      this.setEventPageDesign(e.target.checked ? '2.0.0' : '1.0.0');
      this.$forceUpdate();
    },
    getEventPageDesign(version) {
      return version === '2.0.0';
    },
    updateScheduleType(e) {
      this.setScheduleType(e.type);
    },
    getScheduleTypeObject(type) {
      return _.filter(this.schedule_types_list, i => i.type == type);
    },
    updateScheduleDescription(e) {
      this.setScheduleDescription(e.target.value);
    },
    removeExhibitionVideo(e) {
      this.setExhibitionVideo(null);
    },
    removeExhibitionDocument(e) {
      this.setExhibitionDocument(null);
    },
    updateExhibitionVideo(info, data) {
      try {
        if (!_.has(data, 'data.Key')) {
          return;
        }
        const extension = data.data.Key.split('.').pop();
        const key = _.get(this.item.exhibition_video, 'Key', null);
        const size = _.get(info, 'file.size', 0);
        this.setExhibitionVideo({
          old_key: data.data.Key,
          key: data.data.Key,
          temp_key: info.id,
          size: size.toString(),
          extension: extension,
          uploadedBy: info.user.first_name + ' ' + info.user.last_name,
          uploadedAt: '2023.01.01',
        });
      } catch (e) {
        console.log("error updateExhibitionVideo", e);
      }
    },
    updateExhibitionDocument(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.exhibition_document, 'key', null);
      const size = _.get(info, 'file.size', 0);

      this.setExhibitionDocument({
        old_key: data.data.key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    errCb(e) {
      this.$flashMessage.show({
        group: 'event_hall_index',
        type: 'error',
        title: 'Failed',
        text: "Invalid File/Doc format.Please use described valid format"
      });

    }
  },
}
</script>

<style scoped></style>
