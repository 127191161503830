import axios from "axios";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            id: null,
            title: null,
        },

        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        let params = {
            title: state.item.title
        };

        return axios.post(constants.API_AUTH_URL + '/api/v1/permissions', params)
            .then((params) => {
                commit('resetState')
                return params;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        let params = {
            title: state.item.title
        };

        return axios.post(constants.API_AUTH_URL + '/api/v1/permissions/' + state.item.id, params)
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    fetchData({ commit }, id) {
        return axios.get(constants.API_AUTH_URL + '/api/v1/permissions/' + id)
            .then(response => {
                commit('setItem', response.data.data)
            })
    },

    setId({ commit }, value) {
        commit('setId', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setId(state, value) {
        state.item.id = value
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        // eslint-disable-next-line no-unused-vars
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
