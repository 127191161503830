<template>



<div class="modal-backdrop">
      <div class="modals">
 
<body>
       <div class="fluid-container event_hall">
        <header class="modal-header">
          <slot name="header">Event Hall</slot>
          <button type="button" class="btn-close" @click="close">X</button>
        </header>
  
        <div class="standywrapper">
            <div class="standy_left_1">
                <div class="left_standy_1_photo_1" v-if="!item.left_standee_text_1">
                  <img :src="IMAGE_URL + item.left1_picture_1.key" alt="Img" v-if="item.left1_picture_1 " />
                  <img :src="IMAGE_URL + item.left1_picture_2.key" alt="Img" v-if="item.left1_picture_2 " />
                  <img :src="IMAGE_URL + item.left1_picture_3.key" alt="Img" v-if="item.left1_picture_3 " />
                 </div>
                 <div class="left_standy_1_photo_1" v-else>
                  {{ item.left_text_1 }}
                 </div>
              </div>
              <div class="standy_left_2">
                  <div class="left_standy_2_photo_1" v-if="!item.left_standee_text_2">
                    <img :src="IMAGE_URL + item.left2_picture_1.key" alt="Img" v-if="item.left2_picture_1" />
                    <img :src="IMAGE_URL + item.left2_picture_2.key" alt="Img" v-if="item.left2_picture_2" />
                    <img :src="IMAGE_URL + item.left2_picture_3.key" alt="Img" v-if="item.left2_picture_3" />
                  </div>
                  <div class="left_standy_2_photo_1" v-else>
                  {{ item.left_text_2 }}
                 </div>

              </div>               
                <div class="standy_left_3" v-if="!item.left_standee_text_3">
                    <div class="left_standy_3_photo_1">
                      <img :src="IMAGE_URL + item.left3_picture_1.key" alt="Img" v-if="item.left3_picture_1" />
                    </div>
                    <div class="left_standy_3_photo_2">
                      <img :src="IMAGE_URL + item.left3_picture_2.key" alt="Img" v-if="item.left3_picture_2" />
                    </div>
                    <div class="left_standy_3_photo_3">
                      <img :src="IMAGE_URL + item.left3_picture_3.key" alt="Img" v-if="item.left3_picture_3" />
                    </div>
                </div>
                <div class="standy_left_3" v-else>
                  {{ item.left_text_3 }}
                </div>
                

                <div class="standy_left_4" v-if="!item.left_standee_text_4">
                      <div class="left_standy_4_photo_1">
                        <img :src="IMAGE_URL + item.left4_picture_1.key" alt="Img" v-if="item.left4_picture_1" />
                      </div>
                      <div class="left_standy_4_photo_2">
                          <img :src="IMAGE_URL + item.left4_picture_2.key" alt="Img" v-if="item.left4_picture_2" />
                      </div>
                      <div class="left_standy_4_photo_3">
                          <img :src="IMAGE_URL + item.left4_picture_3.key" alt="Img" v-if="item.left4_picture_3" />
                      </div>
                </div>
                <div class="standy_left_4" v-else>
                  {{ item.left_text_4 }}
                </div>
                <div class="standy_left_5" v-if="!item.left_standee_text_5">
                      <div class="left_standy_5_photo_1">
                        <img :src="IMAGE_URL + item.left5_picture_1.key" alt="Img" v-if="item.left5_picture_1" />
                      </div>
                      <div class="left_standy_5_photo_2">
                          <img :src="IMAGE_URL + item.left5_picture_2.key" alt="Img" v-if="item.left5_picture_2" />
                      </div>
                      <div class="left_standy_5_photo_3">
                          <img :src="IMAGE_URL + item.left5_picture_3.key" alt="Img" v-if="item.left5_picture_3" />
                      </div>
                </div>
                <div class="standy_left_5" v-else>
                  {{ item.left_text_5 }}
                </div>
          </div>




                <div class="standywrapper_right" >
                    <div class="standy_right_1">
                        <div class="right_standy_1_photo_1">
                            <img :src="IMAGE_URL + item.right1_picture_1.key" alt="Img" v-if="item.right1_picture_1" />
                            <img :src="IMAGE_URL + item.right1_picture_2.key" alt="Img" v-if="item.right1_picture_2" />
                        </div>
                        </div>
                        <div class="standy_right_2">
                          <div class="right_standy_2_photo_1">
                            <img :src="IMAGE_URL + item.right2_picture_1.key" alt="Img" v-if="item.right2_picture_1" />
                            <img :src="IMAGE_URL + item.right2_picture_2.key" alt="Img" v-if="item.right2_picture_2" />

                          </div>
                        </div>
                        <div class="standy_right_3">
                          <div class="right_standy_3_photo_1">
                            <img :src="IMAGE_URL + item.right3_picture_1.key" alt="Img" v-if="item.right3_picture_1" />
                            <img :src="IMAGE_URL + item.right3_picture_2.key" alt="Img" v-if="item.right3_picture_2" />

                          </div></div>
                          <div class="standy_right_4">
                            <div class="right_standy_4_photo_1">
                            <img :src="IMAGE_URL + item.right4_picture_1.key" alt="Img" v-if="item.right4_picture_1" />
                            <img :src="IMAGE_URL + item.right4_picture_2.key" alt="Img" v-if="item.right4_picture_2" />

                            </div>
                          </div>

                          <div class="standy_right_5">
                            <div class="right_standy_5_photo_1">
                            <img :src="IMAGE_URL + item.right5_picture_1.key" alt="Img" v-if="item.right5_picture_1" />
                            <img :src="IMAGE_URL + item.right5_picture_2.key" alt="Img" v-if="item.right5_picture_2" />

                            </div>
                          </div>
                          <div class="standy_right_6">
                            <div class="right_standy_6_photo_1">
                            <img :src="IMAGE_URL + item.right6_picture_1.key" alt="Img" v-if="item.right6_picture_1" />
                            <img :src="IMAGE_URL + item.right6_picture_2.key" alt="Img" v-if="item.right6_picture_2" />
                
                            </div>
                          </div>
                          
                          
                        </div>



       </div>
    </body>


</div>
</div>
</template>






<style>

    .event_hall {
    background: url("~@/../public/assets/images/eventhall_aqua_bg_new.jpg")#f1f0eb;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    height: 100vh;
}


.standy_left_1 {
    background-image: url("~@/../public/assets/images/standy_banner_v2.png");    
    width: 108px;
    height: 282px;
    position: relative;
    top: 500px;
    left: 30px;
}

.standy_left_2 {
    background-image: url("~@/../public/assets/images/standy_banner_v2.png");    
    width: 108px;
    height: 282px;
    position: absolute;
    top: 500px;
    left: 155px;
}


.standy_left_3 {
    background-image: url("~@/../public/assets/images/standy_banner_v2.png");      
    width: 108px;
    height: 282px;
    position: absolute;
    top: 680px;
    left: 543px;
    zoom: 0.67;
    z-index: 18;
}

.standy_left_4 {
    background-image: url("~@/../public/assets/images/standy_banner_v2.png");     
    width: 108px;
    height: 282px;
    position: absolute;
    top: 680px;
    left: 664px;
    zoom: 0.67;
    z-index: 18;
}

.standy_left_5 {
    background-image: url("~@/../public/assets/images/standy_banner_v2.png");     
    width: 108px;
    height: 282px;
    position: absolute;
    top: 639px;
    left: 807px;
    zoom: 0.67;
    z-index: 18;
}



.standy_right_1 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");  
    background-repeat: no-repeat; 
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top: 400px;
    right: 130px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.right_standy_1_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.standy_right_2 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");
    background-repeat: no-repeat;
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top: 480px;
    right: 280px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    zoom: 0.87;
}

.right_standy_2_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.standy_right_3 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");
    background-repeat: no-repeat;
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top: 530px;
    right: 444px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    zoom: 0.78;
}

.right_standy_3_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.standy_right_4 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");
    background-repeat: no-repeat;
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top: 600px;
    right: 625px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    zoom: 0.70;
}

.right_standy_4_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.standy_right_5 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");
    background-repeat: no-repeat;
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top: 680px;
    right: 834px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    zoom: 0.62;
}

.right_standy_5_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.standy_right_6 {
    background-image: url("~@/../public/assets/images/new_standy_3.png");
    background-repeat: no-repeat;
    background-position: 20px -10px;
    width: 157px;
    height: 480px;
    position: absolute;
    top:780px;
    right: 1072px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    zoom: 0.55;
    z-index: 12;
}

.right_standy_6_photo_1 {
    width: 86px;
    left: 71px;
    position: absolute;
    background: #666;
    height: 130px;
    top: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}


  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modals {
    background: #FFFFFF !important;
    box-shadow: 2px 2px 20px 1px !important;
    overflow-x: auto !important;
    display: flex !important;
    flex-direction: column !important;
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
  
</style>

<script>

import constants from "@/config/constants";

import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: 'previewModel',
  methods: {
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  computed: {
    ...mapGetters("EventHallsSingle", [
      "item",
      "leftpicsno",
      "rightpicsno"
    ])
  }
};
</script>