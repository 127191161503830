<template>
  <div class="dashboard-content">
    <div class="container-fluid">
      <section class="card mt-2">
        <header class="card-header">
          <h3 class="mt-0 mt-0 text-center">General settings</h3>
        </header>
        <div class="card-body">
          <div class="row show-grid justify-content-md-center">
            <div class="event_features">
              <RouterLink :to="{name: 'configurations.index'}" v-if="can('configuration_access',checkPermssions)">
                <span class="feature_box">
                  <!-- <img src="/assets/images/dashboard_event/Project_Configuration.png" class="asset_img" alt="logo"> -->
                  <i class="bx bx-cog"></i>
                  <span>Configuration</span>
                </span> <!---->
              </RouterLink>
              <RouterLink :to="{ name: 'roles.index' }" v-if="can('role_access',checkPermssions)">
                <span class="feature_box">
                  <!-- <img src="/assets/images/dashboard_event/Roles.png" class="asset_img" alt="logo"> -->
                  <i class="bx bxs-user-detail"></i>
                  <span>Roles</span>
                </span> <!---->
              </RouterLink>
              <RouterLink :to="{ name: 'companies.index' }" v-if="can('company_access',checkPermssions)">
                <span class="feature_box">
                  <!-- <img src="/assets/images/dashboard_event/Companies.png" class="asset_img" alt="logo"> -->
                  <i class="bx bx-buildings"></i>
                  <span>Companies</span>
                </span> <!---->
              </RouterLink>
              <RouterLink :to="{ name: 'users.index' }" v-if="can('user_management_access',checkPermssions)">
                <span class="feature_box">
                  <!-- <img src="/assets/images/dashboard_event/Event_Users.png" class="asset_img" alt="logo"> -->
                <i class="bx bxs-group"></i>
                  <span>Users</span>
                </span> <!---->
              </RouterLink>
            
              <RouterLink :to="{ name: 'booth_presets.index' }" v-if="can('booth_preset_access',checkPermssions)">
                <span class="feature_box">
                  <!-- <img src="/assets/images/dashboard_event/Event_Theme_Settings.png" class="asset_img" alt="logo"> -->
                  <i class="bx bx-palette"></i>
                  <span>Booth Presets</span>
                </span> <!---->
              </RouterLink>
              <RouterLink :to="{name: 'languages.index'}" v-if="can('languages_access',checkPermssions)">
                    <span class="feature_box">
                      <i class='bx bx-font-family'></i>
                      <span>Languages</span>
                    </span> <!---->
              </RouterLink>
              <RouterLink :to="{name: 'DocSections.index'}" v-if="can('manual_access',checkPermssions)">
                    <span class="feature_box">
                      <i class='bx bx-file'></i>
                      <span>Manual</span>
                    </span> <!---->
              </RouterLink>
            </div>
          </div>
        </div>
      </section>
      <section class="card mt-2" v-if="can('virtual_event_access',checkPermssions)" >
        <header class="card-header">
          <h3 class="mt-0 mt-0 text-center">Event List</h3>
        </header>
        <div class="card-body">
          <div class="row show-grid justify-content-md-center">
            <div class="event_features">
              <select name="sel" class="form-control form-control-lg mb-3 sel_event_type" @change="eventListCallback">
                <option disabled selected>Choose events</option>
                <option value="events.index">
                  2D
                </option>
              </select>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <FlashMessage group="event_dashboard_index" />

</template>

<script>
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AdminDashboardIndex",
  data() {
    return {
      event_2d_3d_link: constants.EVENT_2D_3D_LINK,
      event_3d_link: constants.EVENT_3D_LINK,
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.event_dashboard_index;
  },
  created() {
    const access_level = localStorage.getItem('access_level');
    if(access_level =='event'){
      this.$router.push({name: 'notFound'});
    }
    this.verifyPermissions()
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role"}),
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","verifyPermissions"]),
    eventListCallback(e) {
      const checkUrl = (string) => {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }

      if (checkUrl(e.target.value)) {
        window.location.href = e.target.value;
      } else {
        this.$router.push({name: e.target.value});
      }
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  color: white;
  background: linear-gradient(90deg, #0058dd, #20abe6);
  min-height: 30px;

  h1 {
    text-align: center;
    font-size: 22px;
    padding: 0;
    margin: 0;
  }
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--title {
    text-align: center;
    color: black;
    margin-bottom: 22px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: xx-large;
  }

  &--container {
    display: flex;
    flex-direction: column;
    max-width: 250px;
  }
}

.event_features {
  max-width: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  a {
    text-decoration: none;
    margin-right: 15px;
  }

  .feature_box {
    padding: 15px 5px;
    box-shadow: 0px 0px 4px #ccc;
    width: 135px;
    display: grid;
    text-align: center;
    margin-bottom: 15px;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    word-break: break-word;
    background: linear-gradient(300deg, #094ac0, #1faae6);
    color: white;
    font-weight: 500;
    border-radius: 10px;

    span:nth-child(2) {
      line-height: 20px;
      vertical-align: middle;
    }

    a:nth-child(5) > .feature_box {
      margin-right: 0px;
    }

  }

  .feature_box:hover {
    background: linear-gradient(300deg, #34496f, #8ea9b4);
  }
}

.asset_img {
  display: block;
  max-height: 70px;
  min-height: 70px;
  padding-top: 10px;
  width: 65px;
  height: 65px;
  margin: 0 auto;
}

.events-select-main {
  position: relative;

  button {
    border: none;
    color: white;
    background: linear-gradient(300deg, #135cba, #5aa0c2);
    padding: 8px 24px;
  }

  .list {
    // position: absolute;
    top: 22px;
    width: 100%;
    min-width: 100px;
    background-color: white;
    border: 10px;
    box-shadow: 0px 0px 4px #ccc;

    .item {
      background-color: white;
      padding: 3px 12px;

      a {
        color: black;
        font-size: 16px;
        font-weight: bold;
      }

      &:hover {
        background-color: #ebebeb;
      }
    }

    .item:nth-child(2n) {
      background-color: #d5d5d5;
    }
  }
}

.bx {
  font-size: 5em;
  display: block;
  height: 80px;
}

.sel_event_type {
  background: linear-gradient(300deg, #094ac0, #1faae6);
  color: #ffffff;
  width: 200px;
  option {
    color: black
  }
}

.sel_event_type:after {
  content: '\f078';
  font: normal normal normal 17px/1 FontAwesome;
  color: #0ebeff;
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  border-left: 1px solid #0ebeff;
  position: absolute;
  pointer-events: none;
}
</style>