<template>
    <div class="row">
    
  
<div class="col-md-5 event_detail_ls">
    <div class="event_detail_wrapper">
        <div class="row">

            <div class="event_ls_label col-md-2">Speaker</div>
            <div class="col-md-8">{{host}}</div>
        </div>
    </div>
    <div class="event_detail_wrapper">
        <div class="row">
            <div class="event_ls_label col-md-2">Is Owner</div>
            <div class="col-md-8">{{ presentation.is_owner?"Yes":"No" }}</div>
        </div>
    </div>
    <div class="event_detail_wrapper">
        <div class="row">
            <div class="event_ls_label col-md-2">Moderator</div>
            <div class="col-md-8">{{ presentation.is_moderator?"Yes":"No" }}</div>
        </div>
    </div>
    <div class="event_detail_wrapper">
        <div class="row">
            <div class="event_ls_label col-md-2">Presentation File</div>
            <div class="col-md-8">
                <DropFilesShow  :file="presentation.presentation_file?presentation.presentation_file:null"  asset_type="application/pdf">
                </DropFilesShow>
            </div>
                
        </div>
                 

    </div>
    <div class="event_detail_wrapper">
        <div class="row">
            <div class="event_ls_label col-md-2">Presentation Video</div>
            <div class="col-md-8">
                <DropFilesShow :file="presentation.presentation_video?presentation.presentation_video:null"  asset_type="video/mp4"></DropFilesShow>
   
            </div>
        </div>
                 

    </div>
    
</div>
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(presentation)">Edit</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(presentation._id)">Delete</button>
        </div>
</div>
    
      
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex';
    import moment from 'moment';
    import axios from "axios";
    import constants from "@/config/constants";
    import DropFilesShow   from "@/components/Commons/DropFilesShow";
    export default {
        name: "ShowItem",
        data() {
            return {
                host_name: null,
            }
        },
        props: {
            presentation: {
                type: Object,
                default () {
                    return {
                        
                    }
                }
            },
            id: {
                type: String
            },
            host : {
                type: String
            },
            meetingId:null
        },
        components: {
            DropFilesShow
        },
    
        methods: {
            ...mapActions('MeetingHostsSingle',
        [
            "setIsNew",
            "setIsEditing",
            "updateData",
            "resetState",
            "destroyData",
            "setItem",
            "setHostId",
            "setIsOwner",
            "setIsModerator",
            "setPresentationFile",
            "setPresentationVideo",
            "fetchSingleHost",
            "setEventId"
        ]),
        ...mapActions('MeetingHostsIndex', {fetchMeetingById:"fetchMeetingById"}),
      
        deleteData(host_id){
            let multiData = {meeting_id:this.meetingId,host_id:host_id}
            console.log("multiData",multiData);
            this.$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#dd4b39",
            focusCancel: true,
            reverseButtons: true
            }).then(result => {
                    if (result.isConfirmed) {
                        this.destroyData(multiData).then(() => {
                            this.$flashMessage.show({
                            group: 'meetings_index',
                            type: 'success',
                            title: 'Success',
                            time    : 1000,
                            text: 'Meeting Room has been deleted successfully.'
                            });
                            this.fetchMeetingById(this.meetingId);
                            this.setIsNew(false);
                            this.setIsEditing(false);
                        })
                    }
                });
            },

            editData(item) {
                console.log("editDataeditData",item)
                this.setItem(item);
                this.setIsNew(false);
                this.setIsEditing(true);
                this.$forceUpdate();
            },
           
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .user_img {
        img {
            width: 100%;
        }
    }
    
    .event_detail_wrapper {
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    
        .row {
            .col-md-8 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
    
            .col-md-3 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
        }
    
        .card-body {
            box-shadow: 0px 0px #ffffff;
        }
    }
    
    .tabs-vertical {
        border-top: 0px;
    }
    
    .toggle-content{
      background:#fff;
    }
    </style>
    