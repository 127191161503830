import axios from "axios";

function initialState() {
    return {
        item: {
            id: null,
            agenda_id: null,
            event_users: null,
            presentation_duration: null,
            presentation_order: null,
            video: null,
            document: null,
            document_deleted: 0,
            video_deleted: 0
        },
        agendasAll: [],
        eventusersAll: [],
        ordersAll: [],
        ordersEnum:[ { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' },{ value: 4, label: '4' },{ value: 5, label: '5' },{ value: 6, label: '6' },{ value: 7, label: '7' },{ value: 8, label: '8' },{ value: 9, label: '9' },{ value: 10, label: '10' },{ value: 11, label: '11' },{ value: 12, label: '12' },{ value: 13, label: '13' },{ value: 14, label: '14' },{ value: 15, label: '15' },{ value: 16, label: '16' },{ value: 17, label: '17' },{ value: 18, label: '18' },{ value: 19, label: '19' },{ value: 20, label: '20' },{ value: 21, label: '21' },{ value: 22, label: '22' },{ value: 23, label: '23' },{ value: 24, label: '24' },{ value: 25, label: '25' },{ value: 26, label: '26' },{ value: 27, label: '27' },{ value: 28, label: '28' },{ value: 29, label: '29' }, ],
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    agendasAll: state => state.agendasAll,
    eventusersAll: state => state.eventusersAll,
    ordersAll: state => state.ordersAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = {}

        return axios.post('/api/v1/agenda-panelists', params)
            .then(response => {
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = {};

        return axios.post('/api/v1/agenda-panelists/' + state.item.id, params)
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, params) {
        return axios.get('/api/v1/agenda-panelists/' + params.id)
            .then(response => {

                let agendaData = response.data.data
                if(agendaData.event_users){
                    agendaData.event_users.first_name = agendaData.event_users.first_name + " " + agendaData.event_users.last_name;
                } 
                
                commit('setItem', agendaData)
                
               // commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchAgendasAll({ commit }, agenda_id) {
        commit('setAgenda', agenda_id)
        return axios.get('/api/v1/panel-agendas/'+agenda_id)
            .then(response => {
                commit('setAgendasAll', response.data.data)
                return response;
            })
    },
    fetchOrdersAll({ commit }, agenda_id) {
        return axios.get('/api/v1/panel-agendas/orders/'+agenda_id)
            .then(response => {
                commit('setOrdersAll', response.data.data);
                return response;
            })
    },
    fetchEventusersAll({ commit }, params) {
        return axios.get('/api/v1/get_event_panelists/'+params.event_id+'/'+params.agenda_id)
            .then(response => {

                let eventUsers = response.data.data
                //debugger;
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })
                console.log('??????????')
                console.log(eventUsers)
                //debugger;
                //res.event_type = usersFullname;
                commit('setEventusersAll', eventUsers)
                //commit('setEventusersAll', response.data.data)
                return response;
            })
    },
    setAgenda({ commit }, value) {
        commit('setAgenda', value)
    },
    setEvent_users({ commit }, value) {
        commit('setEvent_users', value)
    },
    setPresentation_duration({ commit }, value) {
        commit('setPresentation_duration', value)
    },
    setVideo({ commit }, value) {
        commit('setVideo', value)
    },
    setPresentation_order({ commit }, value) {
        commit('setPresentation_order', value)
    },
    setDocument({ commit }, value) {
        commit('setDocument', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setAgenda(state, value) {
        state.item.agenda_id = value
    },
    setEvent_users(state, value) {
        state.item.event_users = value
    },
    setPresentation_duration(state, value) {
        state.item.presentation_duration = value
    },
    setDocument(state, value) {
        state.item.document = value
    },
    setVideo(state, value) {
        state.item.video = value
    },
    setAgendasAll(state, value) {
        state.agendasAll = value
    },
    setPresentation_order(state, value) {
        state.item.presentation_order = value
    },
    setOrdersAll(state, value) {
        console.log('>>>>>>>>>>>>>'+value);
        console.log(value);
        console.log('orderEnum');
        console.log(state.ordersEnum);
        let available_orders = []
        if(value.length){

            value.map((setOrder, i)=>
            state.ordersEnum = state.ordersEnum.filter(a => a.value !== setOrder.presentation_order)
            )
            
        }else{
            available_orders = state.ordersEnum
        }
        


        state.ordersAll = state.ordersEnum
    },
    setEventusersAll(state, value) {
        state.eventusersAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
