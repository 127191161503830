<template>
    <form @submit.prevent="submitForm" autocomplete="off" novalidate>
      <div class="row">
        <div class="col-md-2">
          <div class="user_img">
            <img v-if="item.profile_picture" :src="IMAGE_URL + item.profile_picture.key" alt="pic">
          </div>
          <div class="upload_user_profile text-center">
            <div v-if="item.profile_picture">
              <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="removePicture">Remove</button>
            </div>
            <div v-else>
              <input type="file" ref="file" style="display: none;" @change="uploadFile"/>
              <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="$refs.file.click()">Browse</button>
            </div>
          </div>
          <label for="profile_picture">Profile picture (Size should be 512x512 and jpeg,jpg in format allowed)</label>
          
        </div>
        <div class="col-md-5 event_detail_ls">
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Salutation *</div>
              <div class="col-md-8">
                <select class="form-control" @change="updateSalutation">
                  <option value="Mr" :selected="item.salutation === 'Mr'">Mr</option>
                  <option value="Mrs" :selected="item.salutation === 'Mrs'">Mrs</option>
                </select>
                <ValidationMessages :element="v$.item.salutation"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">First Name *</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.first_name" @input="updateFirstName">
                <ValidationMessages :element="v$.item.first_name"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Last Name </div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.last_name" @input="updateLastName">
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Email *</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.email" @input="updateEmail">
                <ValidationMessages :element="v$.item.email"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Password</div>
              <div class="col-md-8">
                <input type="password" class="form-control" name="password" placeholder="Enter Password" @input="updatePassword"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Confirm Password</div>
            <div class="col-md-8">
              <input 
              type="password" class="form-control" name="cpassword" placeholder="Enter Confirm Password" :value="item.cpassword" @input="updateConfirmPassword"/>
              <ValidationMessages :element="v$.item.cpassword"/>
            </div>
          </div>
        </div>

          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Company</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.company_name" @input="updateCompany"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Facebook Profile</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="facebook_profile" placeholder="Enter Facebook Profile Link" :value="item.facebook_profile" @input="updateFacebookProfile"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Linkedin Profile</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="linkedin_profile" placeholder="Enter Linkedin Profile Link" :value="item.linkedin_profile" @input="updateLinkedinProfile"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Is Paid?</div>
              <div class="col-md-8">
                <input-switch name="is_paid" :value="item.is_paid" @change="changeIsPaid"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Status</div>
              <div class="col-md-8">
                <input-switch name="status" :value="item.status" @change="changeStatus"/>
              </div>
            </div>
          </div>
  
  
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Personal profile</div>
              <div class="col-md-8">
                <textarea rows="3" class="form-control" name="biography" placeholder="Enter  profile description" :value="item.biography" @input="updateBiography"></textarea>
            
              </div>
                                 
            </div>
          </div>
        </div>
  
        <div class="col-md-5 event_detail_wrapper">
         
         <!-- <div class="row">
            <div class="event_ls_label col-md-2">User Type *</div>
            <div class="col-md-8">
              <v-select
                  class="form-control"
                  name="user_type"
                  :modelValue="getCurrentSelectedRole()"
                  @option:selected="updateRole"
                  :options="user_typeEnum"
              />
            </div>
          </div>-->
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Job Title</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.job_title" @input="updateJobTitle"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Phone</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.Phone" @input="updatePhone"/>
              </div>
            </div>
          </div>
  
  
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Country Code</div>
              <div class="col-md-8">
                <!--  <v-select   
                  class="form-control" 
                  placeholder="Select Country Code" 
                  label="name" 
                  :options="countryCodesAll" 
                  track-by="id" 
                  :modelValue="item.country_code"
                  @option:selected="updateCountryCode" />-->
                    
                
                  <select class="form-control" @change="updateCountryCode">
                    <option value="" selected>Select</option>
                    <option v-for="option in countryCodesAll" :value="option.id" :key="option.id"
                      :selected="(item.country_code == option.id)">
                      {{ option.name }}
                    </option>
                </select>

                               
              </div>
            </div>
          </div>
  
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Allow notification</div>
              <div class="col-md-8">
                <input-switch name="allow_notification" :value="item.allow_notification" @change="changeAllowNotification"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Porfolio Link</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="portfolio_link" placeholder="Portfolio Link" :value="item.portfolio_link" @input="updatePortfolioLink"/>
              </div>
            </div>
          </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="event_ls_label col-md-2">Twitter Link</div>
              <div class="col-md-8">
                <input type="text" class="form-control" name="portfolio_link" placeholder="Twitter Link" :value="item.twitter_profile" @input="updateTwitterLink"/>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body text-right mt-3 mr-5">
          <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
          <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
        </div>
      </div>
      <FlashMessage group="user_event_edit_admin"/>
    </form>
  </template>
  
  <script>
  import {
      mapGetters,
      mapActions
  } from 'vuex';
  import _ from "lodash";
  import {
      useVuelidate
  } from '@vuelidate/core'
  import {
      required,
      email,
  } from '@vuelidate/validators'
  import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
  import { upload, remove } from "@/services/FileUploader";
  import ObjectID from 'bson-objectid';
  import constants from "@/config/constants";
  import InputSwitch from "@/components/Commons/InputSwitch";
  
  export default {
    name: 'UserEditForm',
    data() {
      return {
        IMAGE_URL: constants.IMAGE_URL 
      }
     
    },
    components: {
      InputSwitch,
      ValidationMessages
    },
    setup() {
      return {
        v$: useVuelidate()
      }
    },
    validations() {
      return {
        item: {
          salutation: {
            required,
          },
          first_name: {
            required,
  
          },
          email: {
            required,
            email
          },
        }
      };
    },
    props: {
      id: [String, null]
    },
    watch: {
      id(value) {
        if (value) {
          this.fetchData(value)
              .catch(() => {
                this.$flashMessage.show({
                  group: 'user_event_edit_admin',
                  type: 'error',
                  title: 'Error',
                  text: 'Something went wrong.'
                });
              });
        }
      }
    },
    computed: {
      ...mapGetters('EventUsersSingle', ['item', 'loading', "is_editing", "is_new",'user_typeEnum']),
      ...mapGetters('EventUsersIndex', ['countryCodesAll']),
    },
    created() {
      this.fetchData(this.id)
          .catch(() => {
            this.$flashMessage.show({
              group: 'user_event_edit_admin',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          });
          this.fetchCountryCodesAll();
    },
    beforeUnmount() {
      delete this.$flashMessage.groups.user_event_edit_admin;
      this.resetState();
      this.resetStateMain();
    },
    methods: {
      ...mapActions('UsersIndex', {
        fetchAllUserData: 'fetchData',
    }),

      ...mapActions('EventUsersIndex', {
        fetchAllData                : 'fetchData', 
        fetchCountryCodesAll        :'fetchCountryCodesAll',
      }),
      ...mapActions('UsersSingle', {
        resetStateMain: 'resetState'
      }
     
),
      ...mapActions('EventUsersSingle', [
       
        'fetchData',
        'updateData',
        'resetState',
        'setFirstName',
        'setLastName',
        'setCompany',
        'setEmail',
        'setPassword',
        'setSalutation',
        'setPicture',
        'setJobTitle',
        'setPhone',
        'setCountry',
        'setAllowNotification',
        'setFacebookProfile',
        'setLinkedinProfile',
        'setPortfolioLink',
        'setTwitterProfile',
        'setIsPaid',
        'setStatus',
        "setUser_type",
        "setEvents",
         "setCountryCode",
         "setBiography",
         "setUpdateConfirmPassword"
      ]),
       gettingErrorMessage(error){
            let html = "Something went wrong";
            if(error){
              let errors = error.response.data.data.error;
              html = "<ul>"
              for(let err of errors){
                html+= "<li>"+err.message+"</li>";
              }
              html+="</ul>";
            }
            return html;
      },
      updateConfirmPassword(e){
        this.setUpdateConfirmPassword(e.target.value)
      },
      updateSalutation(e) {
        this.setSalutation(e.target.value);
      },
      updateFirstName(e) {
        this.setFirstName(e.target.value);
      },
      updateLastName(e) {
        this.setLastName(e.target.value);
      },
      updateEmail(e) {
        this.setEmail(e.target.value);
      },
      updatePassword(e) {
        this.setPassword(e.target.value);
      },
      updateCompany(e) {
        this.setCompany(e.target.value);
      },
      updateJobTitle(e) {
        this.setJobTitle(e.target.value);
      },
      updatePhone(e) {
        this.setPhone(e.target.value);
      },
      updateCountry(e) {
        this.setCountry(e.target.value);
      },
      updateCountryCode(e){
        this.setCountryCode(e.target.value);
      },
      updateBiography(e){
        this.setBiography(e.target.value);
      },
      async uploadFile(e) {
        try {
          const file = e.target.files[0];
          const id = ObjectID();
          const resp = await upload(file, id, "event-users", "512X512", 'user_logo');
          if(resp.response && resp.response.data.status ==="FAILED"){
                  this.$flashMessage.show({
                  group : 'user_event_edit_admin',
                  time  : 1000,
                  type  : 'error',
                  title : 'Error',
                  text  : "Invalid File/Doc format.Please use described valid format."
                });
                return;
          }
          let extension = resp.data.data.key.split('.').pop();
          if(!(extension =="jpg" || extension =="jpeg")){
                this.$flashMessage.show({
                  group : 'user_event_edit_admin',
                  type  : 'error',
                  time  :  1000,
                  title : 'Error',
                  text  : "Invalid File/Doc format.Please use described valid format."
                });
                return;
  
          }
  
          this.setPicture(resp.data.data);
        } catch (e) {
          this.$flashMessage.show({
            group: 'user_event_edit_admin',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        }
      },
      removePicture() {
        this.$swal({
          title: 'Are you sure?',
          text: "To fully delete the file submit the form.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          focusCancel: true,
          reverseButtons: true
        }).then(result => {
          if (typeof result.dismiss === "undefined") {
            remove(this.item.profile_picture.key).then(() => {
              debugger
              this.setPicture(null);
            })
          }
        })
      },
      changeIsPaid(e) {
        this.setIsPaid(e.target.checked);
      },
      changeStatus(e) {
        this.setStatus(e.target.checked)
      },
      changeAllowNotification(e) {
        this.setAllowNotification(e.target.checked)
      },
      updateFacebookProfile(e) {
        this.setFacebookProfile(e.target.value);
      },
      updateLinkedinProfile(e) {
        this.setLinkedinProfile(e.target.value);
      },
      updatePortfolioLink(e) {
        this.setPortfolioLink(e.target.value);
      },
      updateTwitterLink(e) {
        this.setTwitterProfile(e.target.value);
      },
      checkPwd(str) {
        if (str.length < 6) {
            return("Password is too short, Its length should be greater than 6");
        } else if (str.length > 50) {
            return("Password is too long");
        } else if (str.search(/\d/) == -1) {
            return("Passwords length should be greater than 6 , Contain one number ,one special character");
        } else if (str.search(/[a-zA-Z]/) == -1) {
            return("Passwords length should be greater than 6 , Contain one number ,one special character");
        } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) != -1) {
            return("Passwords length should be greater than 6 , Contain one number ,one special character");
       }
        return("ok"); 
    },
     
    
      async submitForm() {
        const isFormCorrect = await this.v$.$validate()
        // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
        if (!isFormCorrect) return
        if(this.item.password){
        let resp = this.checkPwd(this.item.password)
        if(resp!=='ok'){
          this.$flashMessage.show({
                  group : 'user_event_edit_admin',
                  type  : 'error',
                  title : 'Error',
                  time  : 1300,
                  html  : "<ul></li>"+resp+"</li></ul>"
              });
          return;  
        }
            
        if(this.item.password!== this.item.cpassword){
          this.$flashMessage.show({
                    group : 'user_event_edit_admin',
                    type  : 'error',
                    title : 'Error',
                    time  : 1000,
                    html  : "<ul></li>Confirm Password do not match with Password </li></ul>"
                });
            return;
        }
      }


        this.updateData("65954e9277ca68e2c5ea7a12")
            .then(() => {
              this.fetchAllUserData();
              this.$flashMessage.show({
                group   :   'users',
                type    :   'success',
                title   :   'Success',
                time    :   1000,
                text    :   'Event user updated successfully.'
              });
              this.resetStateMain();
            })
            .catch((error) => {
              console.error(error)
              let html =   this.gettingErrorMessage(error);
              this.$flashMessage.show({
                group   : 'user_event_edit_admin',
                type    : 'error',
                title   : 'Error',
                time    : 1000,
                text    : html,
                html    : html
              });
            })
      },
      cancelClick() {
        this.resetState();
        this.resetStateMain();
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .user_img {
      img {
          width: 100%;
      }
  }
  
  .event_detail_wrapper {
      padding: 10px 0px;
  
      .event_ls_label {
          font-weight: bold;
      }
  
      .row {
          .col-md-8 {
              border-bottom: 1px dotted #ccc;
              padding-bottom: 5px;
          }
  
          .col-md-3 {
              border-bottom: 1px dotted #ccc;
              padding-bottom: 5px;
          }
      }
  
      .card-body {
          box-shadow: 0px 0px #ffffff;
      }
  }
  </style>
  