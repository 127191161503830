import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {

        item:{
            module_label    : null,
            module_points   : null,
            module_disable  : false
        },
        content:{
            points_contents         :   '',
            leaderboard_contents    :   '',
            rewards_image           :   null
        },
       /* item: {
            contents: {
                points_contents: null,
                leaderboard_contents: null,
                rewards_image: null
            },
            reward_modules: [],
            event_id: null,
        },*/
        loading: false,
        is_new: false,
        is_editing: false,
        }
}

const getters = {
    item                        : state => state.item,
    event_id                    : state => state.event_id,
    loading                     : state => state.loading,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
    content                     : state => state.contnet
}

const actions = {
    async updateData({ commit, state, dispatch },postData) {
        commit('setLoading', true)
        let eventId             = postData.eventId;
        let leaderboardId       = postData.leaderboardId;
        const params = _.cloneDeep(state.item);
        delete params._id;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/events/'+eventId+'/leader_board/'+leaderboardId, params, {headers: headers})
        .then((response) => {
           // commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    async updateContentData({ commit, state, dispatch },event_id) {
        commit('setLoading', true)
        const params = _.cloneDeep(state.content);
        delete params._id;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        if (params.rewards_image) {
            params.rewards_image = {
                key: params.rewards_image.key,
                old_key: params.rewards_image.key,
                size: params.rewards_image.size,
                extension: params.rewards_image.extension,
                uploadedBy: params.rewards_image.uploadedBy,
                uploadedAt: params.rewards_image.uploadedAt,
            }
        }
        return axios.patch(constants.API_URL + '/api/events/leader_board/'+event_id, params, {headers: headers})
        .then((response) => {
           // commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    setModuleLabel({commit},value){
        commit('setModuleLabel',value);
    },
    setModuleName({ commit }, value) {
        commit('setModuleName', value);
    },
    setModulePoints({ commit }, value) {
        commit('setModulePoints', value);
    },
    setModuleDisable({ commit }, value) {
        commit('setModuleDisable', value);
    },

    setContent({ commit }, value) {
        commit('setContent', value);
    },
    setRewardModules({ commit }, value) {
        commit('setRewardModules', value);
    },
    setEventId({ commit }, value) {
        commit('setEventId', value);
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setEditId({commit},value){
        commit('setEditId',value)
    },
    setItem({commit},value){
        commit('setItem',value)
    },
    setPointsContents({commit},value){
        commit('setPointsContents', value)
    },
    setLeaderboardContents({commit},value){
        commit('setLeaderboardContents',value)
    },
    setRewardsImage({commit},value){
        commit('setRewardsImage', value)
    }
}

const mutations = {
    setPointsContents(state,value){
        state.content.points_contents = value;
    },
    setLeaderboardContents(state,value){
        state.content.leaderboard_contents = value;
    },
    setRewardsImage(state,value){
        state.content.rewards_image = value;
    },
    setModuleLabel(state,value){
        state.item.module_label = value;
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setModuleDisable(state, value){
        state.item.module_disable = value;
    },
    setModulePoints(state,value){
        state.item.module_points = value;
    },
    setModuleName(state,value){
        state.item.module_name = value;
    },
    setItem(state, value) {
        state.item = value
    },
    setContent(state, value) {
        state.content = value
    },
    setRewardModules(state, value) {
        state.item.reward_modules = value
    },
    setEventId(state, value) {
        state.item.event_id = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEditId(state, value) {
        state.edit_id = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
