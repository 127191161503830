<template>
      <button class="newbtn" @click="createClick" v-if="is_new === false">Add Room Session </button>
    <button class="newbtn" @click="cancelClick" v-else>Cancel</button>

                <div class="snippet general-event-config-snippet">
                <TogglePrimary v-if="is_new">
                        <template v-slot:default>
                            <div class="container-fluid">
                                <div class="form-group row">
                                        <div class="col-lg-12">
                                            <label>Meeting Attendee
                                            </label>

                                            <v-select
                                            placeholder="Select Session Attendee"
                                            label="name" 
                                            :options="attendeeList" 
                                            @option:selected="updateAttendees"
                                            @option:deselected="removeAttendee" multiple persistent-hint />
                      
                                            
                                        </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Start Time *</label>
                                        <div class="input-daterange input-group">
                                            <span class="input-group-text">
                                                <i class="fas fa-calendar-alt"></i>
                                            </span>
                                            <input disbaled type="datetime-local" class="form-control" name="start_date" :value="new_room_session.start_time?new_room_session.start_time:''"  @change="updateStart_time" />
                                            <span class="input-group-text rounded-0">from</span>
                         
                                        </div>
                                    
                                    </div>
                                <div class="col-lg-6">
                                    <label class>End Time *</label>
                                    <div class="input-daterange input-group">
                                        <span class="input-group-text">
                                            <i class="fas fa-calendar-alt"></i>
                                        </span>
                                        <input disbaled type="datetime-local" class="form-control" name="start_date" :value="new_room_session.end_time?new_room_session.end_time:''"  @change="updateEnd_time" />
                                        <span class="input-group-text rounded-0">to</span>
                                    </div>
                                </div>
                                <div class="card-body text-right mt-3 mr-5">
                                        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="createNewRoomSession()">Save</button>
                                    </div>
                                                
                                    
                                </div>
                               


                            </div>

                           
                        </template>

                    </TogglePrimary>

                    <div>

                    <TogglePrimary v-for="(day,day_index) in all.session_days" :key="day_index">

                        <template v-slot:label>
                            <span class="">
                                <span class="col1">{{ formatDateMeeting(day)}} 
                                <span class="glyphicon glyphicon-chevron-down pointer pull-right"></span>
                                </span>
                            </span>
                        </template>
                        <div  v-for="(session,session_index) in all.room_ses" :key="session_index">
                            <TogglePrimary v-for="(session,session_index) in session[day]" :key="session_index" >
                                <template v-slot:label>
                                    <span class="">
                                <span class="col1">Session Time  {{formatMeetingSlot(session.start_time)}}
                                                              -
                                    {{formatMeetingSlot(session.end_time)}} 
                                </span>
                            </span>
                                </template>
                            <div class="row main">
                               
                        
                                 <div class="row">
                                    <div class="col-lg-3 content_left_panel pull-left">
                                        <div id="style-3" class="scrollbar">
                                            <div class="force-overflow">
                                            <ul>
                                                <li>
                                                <span>Attendees:</span>
                                               {{ getAttendee(session.attendees,attendeeList)}}
                                                </li>
                                                <li>
                                                <span>Start Time</span>
                                                {{session.start_time}}
                                                </li>
                                                <li>
                                                <span>End Time</span>
                                                {{session.end_time}}
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                </div>



                             <div class="card-body text-right mt-3 mr-5">
                            <a class="mb-1 mt-1 me-1 btn btn-lg btn-primary"  :href="'/events/' +all.event_id +'/meetings/' + all._id+'/room_session/'+session._id"
                                                                >
                                                                 Edit
                                                                </a>
                           
                            <button class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteRoom(all,session)">Delete</button>
                            </div>
                            
                      
                            
                            </div> <!--From main div end-->                            
                       
                        </TogglePrimary>

                        </div>
                       
                    </TogglePrimary>


                    </div>

                </div>

            <FlashMessage group="room_session_create" />
            

  </template>
<script>

import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters,mapActions,mapState} from 'vuex'
import TogglePrimary from "@/components/Commons/TogglePrimary";
import InputSwitch from "@/components/Commons/InputSwitch";
import moment from 'moment';
import _ from "lodash";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";

import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'

export default {
  name: "MeetingsIndex",
  components: {
   // ValidationMessages,
    TogglePrimary 
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },
   
  data() {
    return {
        timeFormat: 'HH:mm',
        newRoomSession:{
            start_time:null,end_time:null,attendees:[]
        }
    }
  },
    props: {
        
        item:{
            type: Object,
        },
        event_timezone:null,
        meetingId:null

    },
    validations() {
        return {
            item: {
                start_time: {
                    required
                },                
                end_time: {
                    required,
                }
            }
        }
    },
    mounted() {
        this.fetchAttendeeList(this.$route.params.event_id);
        this.setEventId(this.$route.params.event_id);
        this.fetchMeetingById(this.meetingId);
        
    },
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.room_session_create;
    },
    computed:{
        ...mapGetters('RoomSessionsSingle', ['is_new', "is_editing","item","new_room_session"]),
        ...mapGetters('RoomSessionsIndex', ["attendeeList","all"]),
  
    },
    methods: {
        ...mapActions('MeetingRoomsIndex', {
            fetchAllData: 'fetchData',
      }),
        ...mapActions('MeetingRoomsSingle', ['fetchEventsAllEdit']),
        ...mapActions('MeetingRoomsSingle', {setRoomItem:'setItem'}),
        ...mapGetters('RoomSessionsSingle', ["item"]),
        ...mapActions('RoomSessionsIndex', {fetchAllData: 'fetchData',fetchAttendeeList:"fetchAttendeeList",fetchMeetingById:"fetchMeetingById"}),
       ...mapActions('RoomSessionsIndex', ['fetchData']),
        ...mapActions('RoomSessionsSingle', [
                "createNewRoom",
                "destroyData",
                "storeData",
                'resetState',
                "setIsNew",
                "setIsEditing",
               
                "setAttendees",
                "setStart_time_new",
                "setEnd_time_new",
                "setEnd_time",
                'setItem',
                "setAttendeesNew",
                "setEventId"
        ]),
   
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    updateStart_time(e) {
        this.setStart_time_new(e.target.value);
    },
    updateEnd_time(e) {
        this.setEnd_time_new(e.target.value);
    },
    formatTime(value) {
        if (value && this.event_timezone) {
            var date = moment
            .utc(value)
            .tz(this.event_timezone)
            .format("H:mm");
            return date;
        }
    },
    formatMeetingSlot(value) {
        if (value && this.event_timezone) {
            var date = moment.tz(value, this.event_timezone).format('H:mm');
            return date;
        }
    },
    formatDateMeeting(value) {
        console.log("formatDateMeetingformatDateMeeting",value)
        if (value && this.event_timezone) {
            var date = moment
           // .utc(value+" 10:00:00")
            .utc(value)
            .tz(this.event_timezone)
            .format("MMM D, YYYY ");

            return date;
        }
    },
    updateAttendees(e) {
        let attendeeArr = []
      _.forEach(e, function (value) {
        attendeeArr.push(value.id)
      });
      if(attendeeArr.length>5){
        alert(' you can only select 5 attendee')
        attendeeArr.pop()
      }
        this.setAttendeesNew(attendeeArr);
        
    },
    removeAttendee(e) {
        let cloneOwners = _.cloneDeep(this.new_room_session.attendees);
        const index = cloneOwners.indexOf(e.id);
        if (index > -1) { // only splice array when item is found
            cloneOwners.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setAttendeesNew(cloneOwners);
    },
   
    deleteRoom(item,room_session) {
    let multiData = {meeting_id:item._id,room_session_id:room_session._id,room_day:room_session.room_day}
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
       }).then(result => {
                if (result.isConfirmed) {
                    this.destroyData(multiData).then(() => {
                        this.$flashMessage.show({
                        group: 'room_session_create',
                        type: 'success',
                        title: 'Success',
                        time    : 1000,
                        text: 'Meeting Room has been deleted successfully.'
                      });

                      this.fetchMeetingById(item._id)
         
                    })
                }
            });
    },
    
    getAttendee(data,item){
        let selectedData="";
        const contains = item.some(element => {
            if(data.includes(element.id)){
                selectedData += "";
                selectedData+=element.name+","
            }
            
        });
      return selectedData;
    },
   
    
    async createNewRoomSession(){
        if(!this.new_room_session.end_time || !this.new_room_session.start_time){
            this.$flashMessage.show({
                group: 'room_session_create',
                type: 'error',
                title: 'Failed',
                text: "Start/End time required",
               
            });
            return;

        }
        this.createNewRoom(this.item._id)
          .then(async (resp) => {
            this.$flashMessage.show({
                      group   : 'room_session_create',
                      type    : 'success',
                      title   : 'Success',
                      time    : 1000,
                      text    : 'Meeting Room updated successfully.',
                      });
           // this.fetchEventsAllEdit(this.$route.params.event_id);
            this.resetState();
            this.fetchMeetingById(this.item._id)
            
          })
          .catch(error => {
            console.log("Error store",error);
            let html =  gettingErrorMessage(error);
            this.$flashMessage.show({
                group: 'room_session_create',
                type: 'error',
                title: 'Failed',
                text: html || 'Something went wrong',
                html: html
            });
                    
          });
    }
  }
    
    

}
</script>
<style lang="scss" scoped>
.labelwrapper {
    display: flex;
    justify-content: space-around;
}

.labelwrapper label {
    display: block;
}

.toggle.active i.fa-minus {
    display: none !important;
}

.toggle>label {
    background: #fff !important;
}

.fas .fa-minus {
    display: none !important;
}

.v-dialog>.v-overlay__content>.v-card {
    border-radius: 15px;
    padding-top: 20px;
}

html .toggle-primary .toggle.active>label .labelwrapper span label {
    color: #ffffff !important;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}
.force-overflow {
  min-height: 260px;
}

.booth_data_wrapper {
  border: 1px solid #efefef;
  background: #efefef85;
  padding: 0 15px;
}

.content_left_panel {
  position: relative;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
      text-decoration: none;
      color: inherit;
      display: block;
      font-size: 15px;

      &:first-child {
        padding-top: 0;
      }

      span {
        color: inherit;
        font-weight: bold;
      }
    }
  }
}
</style>
