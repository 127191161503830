<template>
  <form @submit.prevent="submitForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-md-2">
        <div class="user_img">
          <img v-if="item.picture && item.picture.key" :src="IMAGE_URL + item.picture.key" alt="pic">
        </div>
        <div class="upload_user_profile text-center">
          <div v-if="item.picture && item.picture.key">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="removePicture">Remove</button>
          </div>
          <div v-else>
            <input type="file" ref="file" style="display: none;" @change="uploadFile" />
            <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="$refs.file.click()">Browse</button>
          </div>
        </div>
      </div>
      <div class="col-md-5 event_detail_ls">
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Salutation *
            </div>
            <div class="col-md-8">
              <select class="form-control" @change="updateSalutation">
                <option value="Mr" :selected="item.salutation === 'Mr'">Mr</option>
                <option value="Mrs" :selected="item.salutation === 'Mrs'">Mrs</option>
              </select>
              <ValidationMessages :element="v$.item.salutation" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Roles *</div>
            <div class="col-md-8">
              <select class="form-control" @change="updateAccessLevel">
                <option disabled selected>Select</option>
                <option v-for="option in all" :value="option._id" :key="option._id"
                  :selected="(item.access_level == option._id)">
                  {{ this.capitalizeFirstCh(option.title) }}
                </option>
              </select>
              <ValidationMessages :element="v$.item.access_level" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">First Name *</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.first_name"
                @input="updateFirstName">
              <ValidationMessages :element="v$.item.first_name" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Last Name</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.last_name"
                @input="updateLastName">
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Email *</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.email"
                @input="updateEmail">
              <ValidationMessages :element="v$.item.email" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Password</div>
            <div class="col-md-8">
              <input type="password" class="form-control" name="password" placeholder="Enter Password"
                @input="updatePassword" />
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-5 event_detail_ls">
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Company *</div>
            <div class="col-md-8">
              <select class="form-control" @change="updateCompany">
                <option disabled selected>Select</option>
                <option v-for="option in allCompanies" :value="option.company_name" :key="option._id"
                  :selected="(item.company == option.company_name)">
                  {{ this.capitalizeFirstCh(option.company_name) }}
                </option>
              </select>
              <ValidationMessages :element="v$.item.company" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Job Title</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.job_title"
                @input="updateJobTitle" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Phone</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.phone"
                @input="updatePhone" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Address</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.Address"
                @input="updateAddress" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">City</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.city"
                @input="updateCity" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Country</div>
            <div class="col-md-8">
              <select class="form-control" @change="updateCountry">
                <option disabled selected>Select</option>
                <option v-for="option in countriesAll" :value="option.name" :key="option.id"
                  :selected="(item.country == option.name)">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body text-right mt-3 mr-5">
        <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      </div>
    </div>
    <FlashMessage group="user_edit" />
  </form>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import _ from "lodash";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import { upload, remove } from "@/services/FileUploader";
import ObjectID from 'bson-objectid';
import constants from "@/config/constants";

export default {
  name: 'UserEditForm',
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  components: {
    ValidationMessages
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      item: {
        salutation: {
          required,
        },
        access_level: {
          required,
        },
        first_name: {
          required,
        },
        company: {
          required,
        },
        email: {
          required,
          email
        },

      }
    };
  },
  props: {
    id: [String, null]
  },
  watch: {
    id(value) {
      if (value) {
        this.fetchData(value)
          .catch(() => {
            this.$flashMessage.show({
              group: 'user_edit',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          });
      }
    }
  },
  computed: {
    ...mapGetters('UsersSingle', ['item', 'loading', "is_editing", "is_new"]),
    ...mapGetters('RolesIndex', ['all']),
    ...mapGetters('CompaniesIndex', { allCompanies: 'all' }),
    ...mapGetters('UsersIndex', ['countriesAll']),
  },
  created() {
    //Load All Roles
    this.fetchAllRoles();

    //Load all companies
    this.fetchAllCompanies();

    //Load all countries
    this.fetchCountriesAll();

    this.fetchData(this.id)
      .catch(() => {
        this.$flashMessage.show({
          group: 'users',
          type: 'error',
          title: 'Error',
          text: 'Something went wrong.'
        });
      });
  },
  beforeUnmount() {

    delete this.$flashMessage.groups.user_edit;
    this.resetState()
  },
  methods: {

    ...mapActions('CompaniesIndex', { fetchAllCompanies: 'fetchData' }),

    ...mapActions('UsersIndex', {
      fetchAllData: 'fetchData',
      fetchCountriesAll: 'fetchCountriesAll'
    }),
    ...mapActions('RolesIndex', {
      fetchAllRoles: 'fetchData'
    }),
    ...mapActions('UsersSingle', [
      'fetchData',
      'updateData',
      'resetState',
      'setFirstName',
      'setLastName',
      'setCompany',
      'setEmail',
      'setPassword',
      'setSalutation',
      'setPicture',
      'setJobTitle',
      'setPhone',
      'setAddress',
      'setCity',
      'setCountry',
      'setAccessLevel',
    ]),
    updateSalutation(e) {
      this.setSalutation(e.target.value);
    },
    updateAccessLevel(e) {
      this.setAccessLevel(e.target.value);
    },
    updateFirstName(e) {
      this.setFirstName(e.target.value);
    },
    updateLastName(e) {
      this.setLastName(e.target.value);
      if (e.target.value == "")
        this.setLastName(null);
    },
    updateEmail(e) {
      this.setEmail(e.target.value);
    },
    updatePassword(e) {
      this.setPassword(e.target.value);
    },
    updateCompany(e) {
      this.setCompany(e.target.value);
      if (e.target.value == "")
        this.setCompany(null);
    },
    updateJobTitle(e) {
      this.setJobTitle(e.target.value);
      if (e.target.value == "")
        this.setJobTitle(null);

    },
    updatePhone(e) {
      this.setPhone(e.target.value);
      if (e.target.value == "")
        this.setPhone(null);
    },
    updateAddress(e) {
      this.setAddress(e.target.value);
      if (e.target.value == "")
        this.setAddress(null);
    },
    updateCity(e) {
      this.setCity(e.target.value);
      if (e.target.value == "")
        this.setCity(null);
    },
    updateCountry(e) {
      this.setCountry(e.target.value);
      if (e.target.value == "")
        this.setCountry(null);
    },
    getFileType(filePath) {
      return filePath.substr(filePath.lastIndexOf('\\') + 1).split('.')[0];
    },

    async uploadFile(e) {
      try {
        const file = e.target.files[0];

        let exten = file.type;
        let allowedExt = ['image/jpeg', 'image/jpg']

        if (allowedExt.indexOf(this.getFileType(exten)) == -1) {
          this.$flashMessage.show({
            group: 'users',
            type: 'error',
            title: 'Error',
            html: '<ul>' + `<li>Uploaded images should be jpg | jpeg` + '</ul>'
          });
          return false;
        }

        const id = ObjectID();
        const resp = await upload(file, this.item._id, "event-users", null, 'user_logo');

        if (_.has(resp, 'response') && _.has(resp.response.data, 'data') && _.has(resp.response.data.data, 'error')) {
          throw resp;
        }
        const user = JSON.parse(localStorage.getItem('user'));
        let extension = resp.data.data.key.split('.').pop();

        resp.data.data.size = '1';
        resp.data.data.extension = extension;
        resp.data.data.uploadedBy = user.first_name + ' ' + user.last_name;
        resp.data.data.uploadedAt = '2023.01.01';

        this.setPicture(resp.data.data);
      } catch (error) {

        let errorsArr = (_.has(error.response.data.data, 'error')) ? error.response.data.data.error : error.response.data.data;
        let errorsHtml = '';
        _.forEach(errorsArr, function (value, key) {
          errorsHtml += `<li>${value.message.replace(/_/g, " ")}</li>`
        });
        this.$flashMessage.show({
          group: 'user_create',
          type: 'error',
          title: 'Error',
          html: '<ul>' + errorsHtml + '</ul>'
        });
      }
    },
    removePicture() {
      this.$swal({
        title: 'Are you sure?',
        text: "To fully delete the file submit the form.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dd4b39',
        focusCancel: true,
        reverseButtons: true
      }).then(result => {

        if (typeof result.dismiss === "undefined") {
          remove(this.item.picture.key).then(() => {
            debugger
            this.setPicture({});
          })
        }
      })
    },
    async submitForm() {

      const isFormCorrect = await this.v$.$validate()

      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) return
      this.updateData()
        .then(() => {
          this.fetchAllData();
          this.$flashMessage.show({
            group: 'users',
            type: 'success',
            title: 'Success',
            text: 'User has been updated successfully.'
          });
        })
        .catch((error) => {

          let errorsArr = (_.has(error.response.data.data, 'error')) ? error.response.data.data.error : error.response.data.data;
          let errorsHtml = '';
          _.forEach(errorsArr, function (value, key) {
            errorsHtml += `<li>${value.message.replace(/_/g, " ")}</li>`
          });
          this.$flashMessage.show({
            group: 'user_edit',
            type: 'error',
            title: 'Error',
            html: '<ul>' + errorsHtml + '</ul>'
          });
        })
    },
    cancelClick() {
      this.resetState();
    },
    //Capitalize first character
    capitalizeFirstCh(sVal) {
      return _.startCase(_.toLower(sVal));

    }
  }
}
</script>

<style lang="scss" scoped>
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}
</style>
