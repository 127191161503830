<template>
        <div class="col-lg-2 col-xl-3">
                            <section class="card card-featured-left card-featured-quaternary mb-4">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-quaternary">
                                                <i class="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">REGISTERED USERS</h4>
                                                <div class="info">
                                                    <strong class="amount">{{headers.registeredUser}}</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
						    </section>
        </div>



        <div class="col-lg-2 col-xl-3">
                            <section class="card card-featured-left card-featured-quaternary mb-4">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-quaternary">
                                                <i class="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">LOGGED IN USERS</h4>
                                                <div class="info">
                                                    <strong class="amount">{{headers.totalLoggedInUser}}</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
						    </section>
        </div>



        <div class="col-lg-2 col-xl-3">
                            <section class="card card-featured-left card-featured-quaternary mb-4">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-quaternary">
                                                <i class="fa fa-building"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">BOOTHS</h4>
                                                <div class="info">
                                                    <strong class="amount">{{headers.booths}}</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
						    </section>
        </div>


        <div class="col-lg-2 col-xl-3">
                            <section class="card card-featured-left card-featured-quaternary mb-4">
                                <div class="card-body">
                                    <div class="widget-summary">
                                        <div class="widget-summary-col widget-summary-col-icon">
                                            <div class="summary-icon bg-quaternary">
                                                <i class="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <div class="widget-summary-col">
                                            <div class="summary">
                                                <h4 class="title">BOOTHS VISITORS</h4>
                                                <div class="info">
                                                    <strong class="amount">{{headers.booth_visitors}}</strong>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
						    </section>
        </div>


                        
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import _ from "lodash";


export default {
    name: 'EventAssetsIndex',
    components: {
    },
    data() {
        return {
            search: null,
            perPage: 5,
            system_users: [],
        }
    },
    props:{
        headers:null
    },
    watch: {
    },
    created() {
       // this.fetchPermissions()
    
    },
    beforeUnmount() {
       // this.resetState();
  
    },
    computed: {
    },
    methods: {
        ...mapActions('EventAnalyticsIndex', ['fetchData','resetState']),
        
    }
}
</script>