<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />

        <div>

          <button class="btn btn-outline-light" @click="showModal">Preview Changes</button>
      
          <button v-if="can('event_hall_edit',checkPermssions)" class="btn btn-outline-light" @click="submitForm">Save Changes</button>
          <button  class="btn btn-outline-light" @click="cancelEdit">Cancel</button>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="event-config">
        <div class="leftmenu">
          <aside>
            <ul>
              <li>
                <a href="#general-information">
                  <i class="bx bx-home-alt" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#color-settings">
                  <i class="bx bxs-palette" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#theme-settings">
                  <i class='bx bx-cog'></i>
                </a>
              </li>
            </ul>
          </aside>
        </div>
        <div class="right_content">
          <div class="container-fluid ">
            <div class="row event-config--row" id="general-information">
              <div class="col-md-3 event-config--description">
                <h3>Theme Content</h3>
                <p>Main Information about the Event. Edit event start/stop date.</p>
                <p>Add event Location. Time zone and Address.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <ThemeContentSnippet />
              </div>
            </div>
            <div class="row event-config--row" id="color-settings">
              <div class="col-md-3 event-config--description">
                <h3>Branding</h3>
                <p>Theme Color settings. Event Theme settings.</p>
              </div>
              <div class="col-md-9 event-config--fields">
                <BrandSnippet />
              </div>
            </div>
          </div>
        </div>
      
<FlashMessage group="event_hall_index" />

      </div>
    </template>
  </GeneralDashboardLayout>

    <previewModel v-show="isModalVisible" @close="closeModal" >
     
  </previewModel>



</template>


<script>
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import {mapActions, mapGetters} from "vuex";
import BackButton from "@/components/Commons/BackButton";
import BrandSnippet from "@/components/cruds/EventHalls/Forms/Elements/BrandSnippet";
import ThemeContentSnippet from "@/components/cruds/EventHalls/Forms/Elements/ThemeContentSnippet";
import previewModel from "@/components/cruds/EventHalls/Forms/Elements/previewModel"
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "EditEvent",
  components: {
    ThemeContentSnippet,
    BrandSnippet,
    BackButton,
    GeneralDashboardLayout,
    previewModel
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters("EventHallsSingle", [
      "item",
    ])
  },
  async mounted(){
    await this.verifyPermissions(this.$route.params.event_id)  
  },
  async created() {
   // this.fetchPermissions();
  
    await this.fetchData(this.$route.params);
  
  
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.event_hall_index;
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
   
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },

    ...mapActions("EventHallsSingle", [
      "fetchData",
      "updateData",
      "resetState",
      
    ]),

    submitForm() {
      this.updateData()
        .then(() => {
          
           this.$flashMessage.show({
               group  : 'event_hall_index',
               type   : 'success',
               title  : 'Success',
               time   : 1000,
               html   : '<ul><li>Event Hall has been updated successfully.</li></ul>'
             });
             setTimeout(() =>  this.$router.push({ name: "event_dashboard.index"}), 2000);
        })
        .catch(error => {
          console.log("Error Event Hall",error)
          this.$flashMessage.show({
            group: 'event_hall_index',
            type: 'error',
            title: 'Failed',
            text: 'Something went wrong.'
          });
        });
    },
    cancelEdit() {
      this.$router.back()
    },
    errCb(e) {
      this.$flashMessage.show({
                    group : 'event_hall_index',
                    type  : 'error',
                    title : 'Failed',
                    time  : 1000,
                    html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
                  });
      
    },
     can(permit,permissions){
      return isPermits(permit,permissions);
    }
    
  },
}
</script>

<style lang="scss" scoped>
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .header--title {
    font-size: 1.6rem;
  }
}

.event-config {
  background-color: #eeeff2;
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  label {
    font-weight: bold;
    margin-left: 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  &--description {
    background-color: #dee1e8;
    color: #9e9e9e;
    padding: 32px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background-color: #a4a4a4;
      bottom: 3px;
      width: 80%;
      height: 1px;
    }

    h3 {
      color: #797979;
    }
  }

  &--fields {
    padding: 5px 32px;
  }

  .leftmenu {
    position: relative;
    left: 0px;
    height: 100%;
    min-width: 50px;
    display: contents;

    aside {
      background: #3c61b2;
      min-width: 50px;

      ul {
        list-style: none;
        padding-left: 0;
        position: fixed;
        min-width: 50px;

        li {
          text-align: center;

          a {
            color: white;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            transition: all 0.3s ease-in;

            &:hover {
              color: white;
              background: lighten(#4093e3, 20%);
            }

            &.active {
              background: #4093e3;
            }
          }
        }
      }
    }
  }

  nav {
    padding: 0px;
  }

  .right_content {
    width: 100%;
  }
}
</style>
