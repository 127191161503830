<!--<template v-slot:label>
    <div class="row">
      <div class="col-md-10">
                 
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </template> -->

<template>
    <div class="row">
    
        <div class="col-md-5 event_detail_ls">
            <div class="event_detail_wrapper">
                <div class="row">
    
                    <div class="event_ls_label col-md-4">Language Title </div>
                    <div class="col-md-8">{{langauge.language}}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Logo</div>
                    <div class="col-md-8">
                        <img :src="IMAGE_URL + langauge.language_logo.key" alt="Img" v-if="langauge.language_logo !== null">

                    </div>
                </div>
            </div>
           
        </div>
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(langauge)">Edit</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(langauge)">Delete</button>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex';
   
    import constants from "@/config/constants";
    
    export default {
        name: "ShowItem",
        data() {
            return {
                IMAGE_URL: constants.IMAGE_URL
            }
        },
        props: {
            langauge: {type: Object},
            id: {type: String}
        },
        methods: {
            ...mapActions('LanguagesIndex', ['fetchData', 'destroyData']),
            ...mapActions('LanguagesSingle', ['setItem', 'setIsNew', 'setIsEditing']),
            deleteData(item) {
                this.$swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#dd4b39",
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.isConfirmed) {
                        this.destroyData(item._id).then(() => {
    
                            this.$flashMessage.show({
                            group: 'mainlanguages_index',
                            type: 'success',
                            title: 'Success',
                            text: 'Language has been deleted successfully.'
                          });
    
                            this.fetchData();
                            this.setIsNew(false);
                            this.setIsEditing(false);
                        })
                    }
                });
            },
            editData(item) {
            
                this.setItem(item);
                this.setIsNew(false);
                this.setIsEditing(true);
                this.$forceUpdate();
            },
            
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .user_img {
        img {
            width: 100%;
        }
    }
    
    .event_detail_wrapper {
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    
        .row {
            .col-md-8 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
    
            .col-md-3 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
        }
    
        .card-body {
            box-shadow: 0px 0px #ffffff;
        }
    }
    
    .tabs-vertical {
        border-top: 0px;
    }
    
    .toggle-content{
      background:#fff;
    }
    </style>
    