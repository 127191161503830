import axios from "axios";

function initialState() {
    return {
        item: {
            _id: null,
            language: null,
        },
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = {};

        /*@TODO: need to collect all data */

        return axios.post('/api/v1/languages', params)
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = {};

        /*@TODO: need to collect all data */

        return  axios.post('/api/v1/languages/' + state.item._id, params)
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, id) {
        axios.get('/api/v1/languages/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
    },
    
    setLanguage({ commit }, value) {
        commit('setLanguage', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setLanguage(state, value) {
        state.item.language = value
    },
    
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
