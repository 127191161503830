<template>



    <div class="">
          <div class="modals" style="width:52%; height:80%;left:26%;top:10%">
     
    <body>
      <div class="fluid-container">
            <header class="modal-header">
              <slot name="header">Users Answers</slot>
              <button type="button" class="btn" @click="close">X</button>
            </header>
      
            <div class="container-fluid">
              <div class="leaderboard_list">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td scope="col" width="25%">Name</td>
                    <td scope="col" width="25%">Answer</td>
                    <td scope="col" width="25%">Company</td>
                    <td scope="col" width="25%">Job Title</td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(user, k) in userList" :key="k">
                    <td >{{user.name}}</td>
                    <td >{{user.answer_text}}</td>
                    <td >{{user.company_name}}</td>
                    <td >{{user.job_title}}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

               

               
   
                   
            </div>
    
      </div>
    </body>
    
    
    </div>
    </div>
    </template>
    <style>
      .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .modals {
        background: #FFFFFF !important;
        box-shadow: 2px 2px 20px 1px !important;
        overflow-x: auto !important;
        display: flex !important;
        flex-direction: column !important;
        --bs-modal-zindex: 1055;
        --bs-modal-width: 500px;
        --bs-modal-padding: 1rem;
        --bs-modal-margin: 0.5rem;
        --bs-modal-color: ;
        --bs-modal-bg: #fff;
        --bs-modal-border-color: var(--bs-border-color-translucent);
        --bs-modal-border-width: 1px;
        --bs-modal-border-radius: 0.5rem;
        --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        --bs-modal-inner-border-radius: calc(0.5rem - 1px);
        --bs-modal-header-padding-x: 1rem;
        --bs-modal-header-padding-y: 1rem;
        --bs-modal-header-padding: 1rem 1rem;
        --bs-modal-header-border-color: var(--bs-border-color);
        --bs-modal-header-border-width: 1px;
        --bs-modal-title-line-height: 1.5;
        --bs-modal-footer-gap: 0.5rem;
        --bs-modal-footer-bg: ;
        --bs-modal-footer-border-color: var(--bs-border-color);
        --bs-modal-footer-border-width: 1px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-modal-zindex);
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
      }
    
      .modal-header,
      .modal-footer {
        padding: 15px;
        display: flex;
      }
    
      .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
      }
    
      .modal-footer {
        border-top: 1px solid #eeeeee;
        flex-direction: column;
        justify-content: flex-end;
      }
    
      .modal-body {
        position: relative;
        padding: 20px 10px;
      }
    
      .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
      }
    
      .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
      }
      .toprank_img{
        width: 40px;
        height: 40px;
        background-color: #ccc;
        float: left;
        margin-right: 10px;
      }
      .toprank_user{
        display: block;
        font-size: 14px;
      }
      .toprank_user_desg{
        font-weight: normal;
        font-size: 14px;
      }
      
    </style>
    
    <script>
    
    import constants from "@/config/constants";
   
    import {
      mapActions,
      mapGetters
    } from "vuex";
    export default {
      name: 'eventRole',
      components: {
       
      },
      computed: {
        ...mapGetters('SurveyReportIndex', ['all','userList'])
      },
      methods: {
        ...mapActions('SurveyReportIndex', {
             fetchData: "fetchData",
            fetchUserDetail:"fetchUserDetail"
        }),
        close() {
          this.$emit('close');
          
        },
      
      },
      created() {
    
      },
      data() {
        return {
          IMAGE_URL: constants.IMAGE_URL
        }
      }
      
      
    };
    </script>