export default {
    MOCK_API_URL: 'https://acc3ad09-d8bb-401d-860f-0e42642c1e4c.mock.pstmn.io',

    API_URL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
    API_AUTH_URL: process.env.VUE_APP_API_AUTH_URL ? process.env.VUE_APP_API_AUTH_URL : '',
    UPLOAD_MEDIA_URL: process.env.VUE_APP_UPLOAD_MEDIA_URL ? process.env.VUE_APP_UPLOAD_MEDIA_URL : '',
    API_EVENT_URL: process.env.VUE_APP_API_EVENT_URL ? process.env.VUE_APP_API_EVENT_URL : '',
    API_EVENT_HALL_URL: process.env.VUE_APP_API_EVENT_HALL_URL ? process.env.VUE_APP_API_EVENT_HALL_URL : '',
    API_COMPANY_URL: process.env.VUE_APP_API_COMPANY_URL ? process.env.VUE_APP_API_COMPANY_URL : '',

    EVENT_2D_3D_LINK: process.env.VUE_APP_EVENT_2D_3D_LINK ? process.env.VUE_APP_EVENT_2D_3D_LINK : '',
    EVENT_3D_LINK: process.env.VUE_APP_EVENT_3D_LINK ? process.env.VUE_APP_EVENT_3D_LINK : '',

    API_PANEL_DISCUSSION_URL: process.env.VUE_APP_API_PANEL_DISCUSSION_URL ? process.env.VUE_APP_API_PANEL_DISCUSSION_URL : '',
    API_EVENT_USERS_URL: process.env.VUE_APP_API_EVENT_USERS_URL ? process.env.VUE_APP_API_EVENT_USERS_URL : '',

    IMAGE_URL: process.env.VUE_APP_IMAGE_URL ? process.env.VUE_APP_IMAGE_URL : '',

    DASHBOARD_URL: process.env.VUE_APP_DASHBOARD_URL ? process.env.VUE_APP_DASHBOARD_URL : '',
}
