import { createRouter, createWebHistory } from 'vue-router'
import VueRouteMiddleware from 'vue-route-middleware';

// Components
import LoginIndex from '../components/cruds/Login/Index'
import ForgotPasswordIndex from '../components/cruds/ForgotPassword/Index'
import ResetPasswordIndex from '../components/cruds/ResetPassword/Index'
import EventsIndex from '../components/cruds/Events/Index'
import EventEdit from '../components/cruds/Events/Edit'
import EventCreate from '../components/cruds/Events/Create'
import RolesIndex from '../components/cruds/Roles/Index'
import DashboardIndex from '../components/cruds/Dashboard/DashboardIndex'
import UsersIndex from '../components/cruds/Users/Index'
import CompaniesIndex from '@/components/cruds/Companies/Index'
import ConfigurationsIndex from '@/components/cruds/Configurations/Index';
import LeaderBoardIndex from '@/components/cruds/LeaderBoards/Index';
import LivePresentationsIndex from '../components/cruds/LivePresentations/Index'
import RoundTablesIndex from '../components/cruds/RoundTables/Index'
import PageNotFound from '@/components/cruds/PageNotFound/Index'
import OrganizerDashboardIndex from "@/components/cruds/Dashboard/OrganizerDashboardIndex";
import EventLanguagesIndex from "@/components/cruds/EventLanguages/Index";
import EventLanguagesEdit from "@/components/cruds/EventLanguages/Edit";
import EventHallEdit from "@/components/cruds/EventHalls/Edit";
import EventExhibitorsIndex from "@/components/cruds/EventExhibitors/Index";
import PanelAgendaIndex from "@/components/cruds/PanelAgenda/Index";
import PanelAgendaPanelistCreate from "@/components/cruds/PanelAgenda/PanelAgendaPanelistCreate";
import PanelAgendaPanelistEdit from "@/components/cruds/PanelAgenda/PanelAgendaPanelistEdit";
import PanelAgendaSurveysCreate from "@/components/cruds/PanelAgenda/PanelAgendaSurveysCreate";
import PanelAgendaSurveysEdit from "@/components/cruds/PanelAgenda/PanelAgendaSurveysEdit";
import WebcastsIndex from "@/components/cruds/Webcasts/Index";
import OnDemandAgendaIndex from "@/components/cruds/OnDemand/Index";
import MeetingsIndex from "@/components/cruds/Meetings/Index";
import EventUsersIndex from "@/components/cruds/EventUsers/Index";
import EventSponsorsIndex from "@/components/cruds/EventSponsors/Index";
import ExhibitionHallEdit from "@/components/cruds/ExhibitionHall/Edit"
import BoothPresetsIndex from '@/components/cruds/BoothPresets/Index';
import BoothIndex from '@/components/cruds/Booth/Index';
import MatchMakingEdit from '@/components/cruds/MatchMaking/Edit';
import RoomSessionEdit from '@/components/cruds/Meetings/RoomSessions/Edit';
import LanguagesIndex from '@/components/cruds/Languages/Index';
import BlogsIndex from '@/components/cruds/Blogs/Index';
import FooterLinkIndex from '@/components/cruds/FooterLink/Index';
import TagsIndex from '@/components/cruds/Tags/Index';
import FeedbacksIndex from '@/components/cruds/Feedbacks/Index';

import EventPagesIndex from '@/components/cruds/EventPages/Index';
import DocSections from '@/components/cruds/DocSections/Index';
import EventAssetsIndex from '@/components/cruds/EventAssets/Index'

import Myprofile from '@/components/cruds/MyProfile/Index';
import UpdatePassword from '@/components/cruds/MyProfile/UpdatePassword';

import EventAnalyticsIndex from '@/components/cruds/EventAnalytics/Index';
// Middlewares
import auth from "@/middlewares/auth";
import auth_success_redirect from "@/middlewares/auth_success_redirect";

const routes = [
    {path: '/', name: 'home', component: LoginIndex, meta: {middleware: [auth_success_redirect]}},
    {path: '/login', name: 'login', component: LoginIndex, meta: {middleware: [auth_success_redirect]}},
    {path: '/forgot-password', name: 'forgot-password', component: ForgotPasswordIndex, meta: {middleware: [auth_success_redirect]}},
    {path: '/reset-password', name: 'reset-password', component: ResetPasswordIndex, meta: {middleware: [auth_success_redirect]}},
    {path: '/roles', name: 'roles.index', component: RolesIndex, meta: {middleware: [auth]}},
    {path: '/dashboard', name: 'dashboard.index', component: DashboardIndex, meta: {middleware: [auth]}},
    {path: '/users', name: 'users.index', component: UsersIndex, meta: {middleware: [auth]}},
    {path: '/companies', name: 'companies.index', component: CompaniesIndex, meta: {middleware: [auth]}},
    {path: '/configurations', name: 'configurations.index', component: ConfigurationsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/leader_board', name: 'leader_board.index', component: LeaderBoardIndex, meta: {middleware: [auth]}},
    {path: '/events', name: 'events.index', component: EventsIndex, meta: {middleware: [auth]}},
    {path: '/event/create', name: 'events.create', component: EventCreate, meta: {middleware: [auth]}},
    {path: '/event/:event_id/edit', name: 'events.edit', component: EventEdit, meta: {middleware: [auth]}},

    {path: '/event/:event_id/dashboard', name: 'event_dashboard.index', component: OrganizerDashboardIndex, meta: {middleware: [auth]}},
    {path: '/events/live-presentations/:event_id', name: 'live_presentations.index', component: LivePresentationsIndex, meta: {middleware: [auth]}},
    {path: '/events/languages/:event_id', name: 'event_languages.index', component: EventLanguagesIndex, meta: {middleware: [auth]}},
    {path: '/events/languages/:event_id/language/:language_id/edit', name: 'event_languages.edit', component: EventLanguagesEdit, meta: {middleware: [auth]}},
    {path: '/events/:event_id/event-hall/:event_hall_id/edit', name: 'event_halls.edit', component: EventHallEdit, meta: {middleware: [auth]}},
    {path: '/events/:event_id/exhibitors', name: 'exhibitors.index', component: EventExhibitorsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/sponsors', name: 'sponsors.index', component: EventSponsorsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/panel-agenda', name: 'panel_agenda.index', component: PanelAgendaIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/panel-agenda/panelist/create', name: 'panel_agenda.create_panelist', component: PanelAgendaPanelistCreate, meta: {middleware: [auth]}},
    {path: '/events/:event_id/panel-agenda/panelist/:panelist_id/edit', name: 'panel_agenda.edit_panelist', component: PanelAgendaPanelistEdit, meta: {middleware: [auth]}},
    {path: '/events/:event_id/panel-agenda/surveys/create', name: 'panel_agenda.create_surveys', component: PanelAgendaSurveysCreate, meta: {middleware: [auth]}},
    {path: '/events/:event_id/panel-agenda/surveys/:survey_id/edit', name: 'panel_agenda.edit_surveys', component: PanelAgendaSurveysEdit, meta: {middleware: [auth]}},
    {path: '/events/:event_id/webcast', name: 'webcast.index', component: WebcastsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/on-demand', name: 'on_demand.index', component: OnDemandAgendaIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/round-table', name: 'roundtable.index', component: RoundTablesIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/meetings', name: 'meetings.index', component: MeetingsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/users', name: 'event_users.index', component: EventUsersIndex, meta: {middleware: [auth]}},

    {path: '/events/:event_id/event-exhibition-hall/:event_exhibition_hall_id/edit', name: 'event_exhibition_hall.edit', component: ExhibitionHallEdit, meta: {middleware: [auth]}},
    {path: '/booth-presets', name: 'booth_presets.index', component: BoothPresetsIndex, meta: {middleware: [auth]}},
    {path: '/events/booth/:event_id', name: 'booth.index', component: BoothIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/match_making', name: 'match_making.edit', component: MatchMakingEdit, meta: {middleware: [auth]}},
    {path: '/events/:event_id/meetings/:room_id/room_session/:room_session_id/', name: 'meetings.room_sessions.edit', component: RoomSessionEdit, meta: {middleware: [auth]}},
    {path: '/languages', name: 'languages.index', component: LanguagesIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/blogs', name: 'blogs.index', component: BlogsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/footer_link', name: 'footer_link.index', component: FooterLinkIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/tags', name: 'tags.index', component: TagsIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/feedbacks', name: 'feedbacks.index', component: FeedbacksIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/pages', name: 'pages.index', component: EventPagesIndex, meta: {middleware: [auth]}},
    {path: '/events/:event_id/event-assets', name: 'event-assets.index', component: EventAssetsIndex, meta: {middleware: [auth]}},
    {path: '/docs-sections', name: 'DocSections.index', component: DocSections, meta: {middleware: [auth]}},
    {path: '/my-account', name: 'Myprofile.index', component: Myprofile, meta: {middleware: [auth]}},
    {path: '/change-password', name: 'UpdatePassword', component: UpdatePassword, meta: {middleware: [auth]}},
    {path: '/events/:event_id/analytics', name: 'event-analytics.index', component: EventAnalyticsIndex, meta: {middleware: [auth]}},
    
    
       
    
    //This 404 path must be in last
    {path: '/:pathMatch(.*)*', name: 'notFound', component: PageNotFound},
];

const router = createRouter({
  history: createWebHistory(),
    routes: routes
})

router.beforeEach(VueRouteMiddleware())
export default router
