import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id: null,
            company_name: '',
            first_name: null,
            last_name: null,
            salutation: null,
            email: null,
            password: null,
            job_title: null,
            Phone: null,
            country_code: null,
            profile_picture: null,
            allow_notification: false,
            facebook_profile: null,
            linkedin_profile: null,
            portfolio_link: null,
            twitter_profile: null,
            is_paid: false,
            status: true,
            introduce: {},
            events: {},
            user_type:'',
            biography:'',
            cpassword:null,
            is_verified:true,
            support_user:false
        },
        is_new: false,
        is_editing: false,
        user_typeEnum: [{ value: 'Speaker', label: 'Speaker' }, { value: 'Supplier', label: 'Supplier' }, { value: 'Exhibitor', label: 'Exhibitor' }, { value: 'Attendee', label: 'Attendee' }, { value: 'Organizer', label: 'Organizer' }, { value: 'Moderator', label: 'Moderator' }, { value: 'Panelist', label: 'Panelist' }, { value: 'Host', label: 'Host' }, { value: 'Support', label: 'Support' }],
        loading: false,
      
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
    user_typeEnum: state => state.user_typeEnum,
    
   
}

const actions = {
    async storeData({ commit, state, dispatch }, event_id) {
        commit('setLoading', true)
        dispatch('AlertMessage/resetState', null, { root: true })

        let params = _.cloneDeep(state.item);

        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;
        delete params.cpassword;
        delete params.favorites;
        if (!_.has(params.profile_picture, 'key')) {
            delete params.profile_picture;
        }

        params.username = params.email;

        params.support = [{
            event_id: event_id
        }];

        if (params.profile_picture) {
            params.profile_picture = {
                old_key: state.item.profile_picture.key,
                temp_key:  state.item.profile_picture.temp_key,
                size: state.item.profile_picture.size,
                extension: state.item.profile_picture.extension,
                uploadedBy: state.item.profile_picture.uploadedBy,
                uploadedAt: state.item.profile_picture.uploadedAt,
            }
        }

        const user = JSON.parse(localStorage.getItem('user'));

        params.introduce = {
            in_event: event_id,
            by: _.get(user, '_id', null)
        }

            // @TODO: need to update it in future

        params.events = [{
                event_id: event_id,
                role: "Attendee",
                assigned_by: _.get(user, '_id', null),
                status: true
            }];
        if(params.support_user){
            params.events.push({
                event_id: event_id,
                role: "Support",
                assigned_by: _.get(user, '_id', null),
                status: true
            })
        }    

        delete params.user_type;


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        try {
            const response = await axios.post(constants.API_URL + '/api/event-user/', params, { headers: headers });
            commit('resetState');
        } finally {
            commit('setLoading', false);
        }
           
    },
    updateData({ commit, state, dispatch }, event_id) {
        commit('setLoading', true)
        //dispatch('Alert/resetState', null, { root: true })

        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;
        delete params.cpassword;
     
        params.username = params.email;
        delete params.favorites;
        delete params.profile_picture;

        if (state.item.profile_picture) {
            params.profile_picture = {
                key: state.item.profile_picture.key,
                old_key: state.item.profile_picture.key,
                size: state.item.profile_picture.size,
                extension: state.item.profile_picture.extension,
                uploadedBy: state.item.profile_picture.uploadedBy,
                uploadedAt: state.item.profile_picture.uploadedAt,
            }
        }

        let country_code = state.item.country_code;
       /* if (!country_code) {
            params.country_code = '';
        } else {
            if (state.item.country_code.id != undefined) {
                params.country_code = state.item.country_code.id
            } else {
                delete params.country_code
            }

        }*/
        console.log("country_codecountry_code",country_code,params);
      
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/event-user/' + event_id + '/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            });
    },
    fetchData({ commit, dispatch }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/event-user/user-id/' + id, {headers: headers})
            .then(response => {
                console.log("All event hit");
                const item = _.cloneDeep(response.data.data);
                if (item.username) {
                   delete item.username;
                }

                commit('setItem', item);
                
           
            })
       
    },
   
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    setFirstName({ commit, dispatch }, value) {
        commit('setFirstName', value)
    },
    setLastName({ commit, dispatch }, value) {
        commit('setLastName', value)
    },
    setCompany({ commit }, value) {
        commit('setCompany', value)
    },
    setEmail({ commit }, value) {
        commit('setEmail', value)
    },
    setPassword({ commit }, value) {
        commit('setPassword', value)
    },
    setSalutation({ commit }, value) {
        commit('setSalutation', value)
    },
    setPicture({ commit }, value) {
        commit('setPicture', value)
    },
    setJobTitle({ commit }, value) {
        commit('setJobTitle', value)
    },
    setPhone({ commit }, value) {
        commit('setPhone', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
    },
    setAllowNotification({ commit }, value) {
        commit('setAllowNotification', value)
    },
    setFacebookProfile({ commit }, value) {
        commit('setFacebookProfile', value)
    },
    setLinkedinProfile({ commit }, value) {
        commit('setLinkedinProfile', value)
    },
    setPortfolioLink({ commit }, value) {
        commit('setPortfolioLink', value)
    },
    setTwitterProfile({ commit }, value) {
        commit('setTwitterProfile', value)
    },
    setIsPaid({ commit }, value) {
        commit('setIsPaid', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setUser_type({ commit }, value) {
        commit('setUser_type', value)
    },
    setEvents({ commit }, value) {
        commit('setEvents', value)
    },
    setCountryCode({ commit }, value) {
        commit('setCountryCode', value)
    },
   
     setMessage({ commit }, message) {
        commit('setMessage', message)
    },
    setErrors({ commit }, errors) {
        commit('setErrors', errors)
    },
    setColor({ commit }, color) {
        commit('setColor', color)
    },
    setBiography({commit},value){
        commit('setBiography', value)
    },
    setUpdateConfirmPassword({commit},value){
        commit('setUpdateConfirmPassword',value)
    },
    setSupportUser({commit},value){
        commit('setSupportUser',value)
    }
    
}

const mutations = {
    setSupportUser(state, value){
        state.item.support_user = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setFirstName(state, value) {
        state.item.first_name = value
    },
    setLastName(state, value) {
        state.item.last_name = value
    },
    setEmail(state, value) {
        state.item.email = value
    },
    setPassword(state, value) {
        state.item.password = value
    },
    setCompany(state, value) {
        state.item.company_name = value
    },
    setSalutation(state, value) {
        state.item.salutation = value;
    },
    setPicture(state, value) {
        state.item.profile_picture = value;
    },
    setJobTitle(state, value) {
        state.item.job_title = value;
    },
    setPhone(state, value) {
        state.item.Phone = value;
    },
    setCountry(state, value) {
        state.item.country_code = value;
    },
    setAllowNotification(state, value) {
        state.item.allow_notification = value;
    },
    setFacebookProfile(state, value) {
        state.item.facebook_profile = value;
    },
    setLinkedinProfile(state, value) {
        state.item.linkedin_profile = value;
    },
    setPortfolioLink(state, value) {
        state.item.portfolio_link = value;
    },
    setTwitterProfile(state, value) {
        state.item.twitter_profile = value;
    },
    setIsPaid(state, value) {
        state.item.is_paid = value;
    },
    setStatus(state, value) {
        state.item.status = value;
    },
    setIsDeleted(state, value) {
        state.item.is_deleted_by_admin = value;
    },
    setIsBanned(state, value) {
        state.item.is_banned_by_admin = value;
    },
    setIsRemoved(state, value) {
        state.item.is_removed_by_admin = value;
    },
    setIntroduce(state, value) {
        state.item.introduce = value;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setUser_typeEnum(state, value) {
        state.user_typeEnum = value
    },
    setUser_type(state, value) {
        state.item.user_type = value
    },
    setEvents(state, value) {
        state.item.events = value;
    },
    setCountryCode(state, value) {
        state.item.country_code = value
    },
    setBiography(state, value) {
        state.item.biography = value;
    },
    setUpdateConfirmPassword(state,value){
        state.item.cpassword = value
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
