<template>
  <GeneralDashboardLayout>
    <template v-slot:header><h1>Event Dashboard</h1></template>
    <template v-slot:content>
      <AdminDashboardIndexVue v-if="true"/> <!--@TODO: need to fix it -->
      <OrganizerDashboardIndexVue v-if="false"/>  <!--@TODO: need to fix it -->
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import AdminDashboardIndexVue from "./AdminDashboardIndex.vue";
import OrganizerDashboardIndexVue from "./OrganizerDashboardIndex.vue";

export default {
  name: "DashboardIndex",
  components: {GeneralDashboardLayout, AdminDashboardIndexVue, OrganizerDashboardIndexVue},
  data() {
    return {}
  },

  beforeMount() {
    // debugger
  }
}
</script>

<style scoped lang="scss">
h1 {
  padding: 10px;
  font-size: 22px;
}
</style>