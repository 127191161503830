<template>
  <div class="snippet event-domain-snippet">
    <div class="form-group row">
      <div class="col-lg-12">
        <DropFiles :file="validate_file(item.background_picture)" 
                    :module_id="this.moduleId" 
                    dimmention="2725X519"
                    @onUploaded="updateBackgroundPicture" 
                    @onRemoved="removeBackgroundPicture"
                    :accept="'image/jpeg'"
                    module_type="events" 
                    asset_type="exhibition_hall_background_image"  
                     @onError="errCb">

          <template #header>
            <label>Exhibition Background Picture (Size should be 2725x519 pixel and format JPG.)</label>
          </template>
          <template #tooltip>
            <VTooltip style="display:inline-block">
              <i class="fa fa-info-circle" style="font-size:16px"></i>
              <template #popper>
                <img src="/admin_assets/img/event_garden_smallbanner_5.png"/>
              </template>
            </VTooltip>
          </template>
        </DropFiles>
      </div>
      
    </div>
  </div>
  <FlashMessage group="event_exhibition_hall_theme" />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash';
import ObjectID from 'bson-objectid';

export default {
  name: "EventDomainSnippet",
  data() {
    return {
      moduleId: ObjectID(),
    }
  },
  components: {
  },

  beforeUnmount() {
    delete this.$flashMessage.groups.event_exhibition_hall_theme;
  },
  computed: {
    ...mapGetters("ExhibitionHallsSingle", [
      "item",
      "loading"
    ]),
  },
  methods: {
    ...mapActions("ExhibitionHallsSingle", [
      "setBackgroundPicture",
     
    ]),
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    
    updateBackgroundPicture(info, data) {
      try{
      if (!_.has(data, 'data.key')) {
        return;
      }
        const extension = data.data.Key.split('.').pop();
        const key = _.get(this.item.background_picture, 'Key', null);
        const size = _.get(info, 'file.size', 0);
        this.setBackgroundPicture({
        old_key: data.data.Key,
        key: data.data.Key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy: info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
        });
      }catch(e){
        console.log("error updateExhibitionVideo",e);
      }
    },
    removeBackgroundPicture(e) {
      this.setBackgroundPicture(null);
    },
   
    errCb(e) {
      this.$flashMessage.show({
                    group: 'exhibition_hall_index',
                    type: 'error',
                    title: 'Failed',
                    text: "Invalid File/Doc format.Please use described valid format"
                  });
      
    }
  },
}
</script>

<style scoped>

</style>
