import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all: {},
        loading: false,
        attendeeList:[]
    }
}

const getters = {
   
    all                         : state => state.all,
    loading                     : state => state.loading,
    attendeeList                : state => state.attendeeList
    
}

const actions = {
    fetchAttendeeList({ commit, state ,dispatch}, eventId) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(`${constants.API_URL}/api/event-user/event-id/${eventId}`, {headers: headers})
        .then(response => {
            let attendeeList = [];
            _.forEach(response.data.data, function (value) {
                attendeeList.push({ id: `${value._id}`, name: `${value.salutation} ${value.first_name} ${value.last_name}` })
            });
             commit('setAttendeeList', attendeeList)
        })
        .catch(error => {
            console.log("Error",error);
        })
    },
    fetchMeetingById({ commit, state ,dispatch}, meetingId) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
       return axios.get(constants.API_URL + '/api/meetings/'+meetingId, {headers: headers})
        .then(response => {
            commit('setAll', response.data.data)
        })
        .catch(error => {
            console.log("Error",error);
        })

    },
    fetchData({ commit, state }) {
        commit('setLoading', true)

        axios.get('/api/v1/room-sessions')
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        axios.delete('/api/v1/room-sessions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setAttendeeList({ commit }, value) {
        commit('setAttendeeList', value)
    }
}

const mutations = {
    setAll(state, items) {
        console.log("set all*****************",items);
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    
    setAttendeeList(state,value){
        state.attendeeList = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
