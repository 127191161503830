<template>
<GeneralDashboardLayout>
    <template v-slot:header>
        <div class="header-template">
            <BackButton :to="{ name: 'event_dashboard.index' }" />
            <h1 class="title text-center">Live Presentation</h1>
            <span v-if="can('live_presentation_create',checkPermssions)">
                <button class="btn btn-outline-primary"  @click="createClick" v-if="is_new === false">Create a New Live Presentation</button>
                <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
     
      
            </span>
        </div>
    </template>
    <template v-slot:content>
        <div class="container-fluid user-container">
            <div class="row">
                <div class="col-lg-12">
                    <div id="live-presentations">
                      <OverlayLoading :isLoading="loading" />
                        <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage" v-if="can('live_presentation_view',checkPermssions)">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row search_bar">
                                        <div class="col-md-2">
                                            <div class="input-group w-70">
                                                <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                                                    <option value="5">5</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <span class="ml-5 rpg_txt">records per page</span>
                                        </div>
                                        <div class="col-md-2 offset-md-8 ">
                                            <div class="input-group">
                                                <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                                                <span class="input-group-text">
                                                    <i class="fas fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="labelhdwrapper row">
                                <span class="col1">Title</span>
                                <span class="col2"></span>
                                <span class="col3"></span>
                                <span class="col4">Start/End Time</span>
                            </div>
                            <TogglePrimaryActive v-if="is_new">
                                <template v-slot:default>
                                    <LivePresentationCreateForm :event="event" :languages="languages"/>
                                </template>
                            </TogglePrimaryActive>
                            <TogglePrimary v-for="(livePresentation, index) in props.all" v-bind:key="index">
                                <template v-slot:label>
                                    <span class="labelwrapper">
                                        <span class="col1">{{ livePresentation.translations[0]['title'] }}</span>
                                        <span class="col2"></span>
                                        <span class="col3"></span>
                                        <span class="col4">
                                            <svg style="width: 6%; height: auto; margin-right: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> {{ getTimeFromDate(livePresentation.start_time)}} - {{ getTimeFromDate(livePresentation.end_time)}}
                                        </span>
                                    </span>
                                </template>
                                <template v-slot:default>
                                    <LivePresentationEditForm v-if="is_new === false && is_editing === true && livePresentation._id === item._id" :event="livePresentation" :languages="languages" :eventDetail="event" />
                                    <ShowItem :presentation="livePresentation" :id="livePresentation._id"  :speaker="loadSpeaker(livePresentation.speaker)" :event="event" :checkPermssions="checkPermssions" v-else />
                                </template>

                            </TogglePrimary>
                        </SearchableContainer>
                    </div>

                </div>
            </div>
            <FlashMessage group="lp_index" />
        </div>
    </template>
</GeneralDashboardLayout>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/LivePresentations/Forms/Show";
import LivePresentationEditForm from "@/components/cruds/LivePresentations/Forms/Edit";
import LivePresentationCreateForm from "@/components/cruds/LivePresentations/Forms/Create";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import _ from "lodash";
import moment from 'moment';
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
    name: 'LivePresentationsIndex',
    components: {
        SearchableContainer,
        LivePresentationCreateForm,
        LivePresentationEditForm,
        ShowItem,
        TogglePrimary,
        BackButton,
        GeneralDashboardLayout,
        TogglePrimaryActive
    },
    data() {
        return {
            search: null,
            perPage: 5,
            system_users: [],
        }
    },
    watch: {
        all(items) {
            this.system_users = _.filter(items, i => i.role === 'admin');
        }
    },
    created() {
        this.verifyPermissions(this.$route.params.event_id)
        //this.fetchPermissions();
        this.fetchData(this.$route.params.event_id).catch(() => {
            this.$flashMessage.show({
                group: 'users',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong.'
            });
        })
    },
    beforeUnmount() {
        this.resetState();
        delete this.$flashMessage.groups.lp_index;
        delete this.$flashMessage.groups.lp_create_speaker;
  
    },
    computed: {
        ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
        ...mapGetters('LivePresentationsIndex', ['loading', 'all', 'speakers', 'event' , 'languages','event_timezone']),
        ...mapGetters('LivePresentationsSingle', ['item', 'is_new', "is_editing"]),
    },
    methods: {
        ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
        ...mapActions('LivePresentationsIndex', ['fetchData']),
        ...mapActions('LivePresentationsSingle', ['resetState', "setIsNew", "setIsEditing"]),
        createClick() {
            this.setIsNew(true);
            this.setIsEditing(false);
        },
        cancelClick() {
            this.resetState();
        },
        perRowChange(e) {
            this.perPage = Number(e.target.value);
        },
        getTimeFromDate(date) {
            console.log("this.is_editing",this.is_editing);
            console.log("this.new",this.is_new);
            return moment(date).format("hh:mm A");
            
            //if(this.is_editing){
                //return moment(date).format("hh:mm A");
           // }
          // return moment(date).utc().format("hh:mm A");
          // return moment.utc(date).tz(this.event.time_zone).format('hh:mm A')
    
            //return moment(date).format("hh:mm A");
        },
       
        loadSpeaker(speaker){
            if(_.isObject(speaker)){
              return `${speaker.salutation} ${speaker.first_name} ${speaker.last_name}`;
            }

            if (_.isString(speaker)) {
              let speakerObj = _.find(this.speakers, { 'id': speaker });
              return _.get(speakerObj, 'name', 'No Speaker Assigned')
            }

          return "No Speaker Assigned";
        },
        can(permit,permissions){
            return isPermits(permit,permissions);
        }
    }
}
</script>

<style lang="scss" scoped>
.header-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title {
        font-size: 1.6rem;
    }
}

.user-container {
    .toggle label {
        color: #000 !important;
        border-radius: 0px;

    }

    .toggle.active>label {
        color: #fff !important;
    }

    .toggle section {
        // box-shadow: -1px 0px 5px 2px #ccc;
        border-bottom: 1px solid #ccc;
    }

    .toggle-content {
        padding: 35px;

    }

    .form-control {
        font-family: 'Poppins';
    }

    .desc_btn_wrapper {
        margin-top: 20px;
    }

    .snippet {
        border-radius: 0.2rem;
        border: 1px solid #c9c9c9;
        padding: 24px 22px;
        margin: 15px 0;
        background-color: white;
    }

    .snippet .btn-light {
        color: #000 !important;
    }

    .edit_panel {
        background: #d5e3eb;
        padding: 15px;
        margin-top: 10px;
    }

    .snippet {
        margin-top: 0px;
    }

    .note-editor .btn-light {
        color: #000 !important;
    }

    .setting_wrapper {
        float: right;
    }

    .setting_wrapper a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;

    }

    .labelwrapper {
        width: 100%;
    }

    .labelhdwrapper {
        width: 100%;
        margin-left: 0px;
        border: 1px solid #ccc;
        padding: 10px;
        background: #e1e1e1;
    }

    .labelhdwrapper span {
        font-weight: bold;
    }

    .col1 {
        width: 25%;
        display: inline-flex;
    }

    .col2 {
        width: 25%;
        display: inline-flex;
    }

    .col3 {
        width: 25%;
        display: inline-flex;
    }

    .col4 {
        width: 20%;
        display: inline-flex;
    }

    .search_bar {
        margin-bottom: 20px;
    }

    .eventlabel {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .event_item {
        color: #fff;
        padding: 8px 15px;
        border-radius: 8px;
        background: #afbcc3;
        min-width: 50px;
        display: inline-flex;
        margin: 5px;
    }

    .nav-link.active {
        border-top-color: #0088CC;
    }

    .toggle.active>label {
        background: #0a72e0;
    }

    .nav-tabs li.active a {
        color: #fff !important;
        background: #0a72e0 !important;
        font-size: 1.2em;
    }

    .nav-tabs li a {
        font-size: 1.2em;
        color: #333;
    }

    .toggle>label {
        background: none !important;
        font-size: 1em !important;
    }

    .toggle .toggle {
        margin: 0px;
    }

    .toggle {
        margin: 0px;
    }

    .toggle_odd {
        background: #f5f5f5;
    }

    .btn-primary {
        color: #fff !important;
        background-color: #0c78e0 !important;

    }

    .nav-tabs li .nav-link,
    .nav-tabs li .nav-link:hover {
        background: #cccccc94 !important;
    }

    .pageview {
        font-family: 'Poppins';
        font-size: 1em !important;
    }

    .w-70 {
        width: 30%;
        display: inline-flex;
    }

    .rpg_txt {
        display: inline-block;
        margin-left: 10px;
    }

    .nav-tabs li .nav-link,
    .nav-tabs li {
        border-right: 1px solid #EEE;
    }

    .toggle_odd {
        .event_detail_wrapper {
            .row {
                .col-md-8 {
                    border-bottom: 0px dotted #ccc;
                    padding-bottom: 5px;
                }
            }
        }
    }

    .text-right {
        text-align: right !important;
        margin-right: 100px;
        background: none;
    }

    .toggle_odd {
        .text-right {
            margin-right: 120px;
        }
    }

    .btn-info {
        background: #6c757d !important;
    }

    .profile_info {
        text-align: center;
        margin-top: 40px;
    }

    .card-box {
        background: #cccccc4d;
        border-radius: 10px;
        padding: 30px;
        min-height: 300px;
        font-size: 1.1em;

        h2 {
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: 0.01em;
            margin-bottom: 20px;
        }
    }
}
</style>
