import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";
import ObjectID from 'bson-objectid';
    
class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

 upload() {
        
    return this.loader.file
    .then( uploadedFile => {
        return new Promise( ( resolve, reject ) => {
            console.log("Upload file",uploadedFile)
            const formData = new FormData();

            formData.append('file', uploadedFile);
            formData.append('module_id', ObjectID());
            formData.append('module_type', "events");
            formData.append('asset_type', "images");
            const headersDafault = {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        const reqHeaders = _.merge(headersDafault, {});
        return axios.post(constants.UPLOAD_MEDIA_URL + '/api/v1/assets', formData, {headers: reqHeaders})
        .then((res) => {
            console.log("resss",res);
            if ( res ) {
                resolve( {
                    default: res.data.data.Location
                } );
            } else {
                reject( res.data.message );
            }
        }).catch((err) => {
            console.error('Upload file error: ', err)
            return err;
        });
     

    


    } );
} );

}
}
export {UploadAdapter};

/*upload() {
    return this.loader.file
        .then( uploadedFile => {
            return new Promise( ( resolve, reject ) => {
            const data = new FormData();
            data.append( 'upload', uploadedFile );

            axios( {
                url: '/index/uploadimage',
                method: 'post',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data;'
                },
                withCredentials: false
            } ).then( response => {
                if ( response.data.result == 'success' ) {
                    resolve( {
                        default: response.data.url
                    } );
                } else {
                    reject( response.data.message );
                }
            } ).catch( response => {
                reject( 'Upload failed' );
            } );

        } );
    } );
}*/