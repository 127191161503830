<template>
  <div class="row">
    <div class="col-md-10 event_detail_ls">
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company Name</div>
          <div class="col-md-10">{{company.company_name}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company Email</div>
          <div class="col-md-10">{{company.company_email}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Contact Email</div>
          <div class="col-md-10">{{company.contactus_email}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Phone Number</div>
          <div class="col-md-10">{{company.phone}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Address</div>
          <div class="col-md-10">{{company.address}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company Description</div>
          <div class="col-md-10">{{company.company_description}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company Logo</div>
          <div class="col-md-10">
            <img :src="IMAGE_URL + company.company_logo.key" alt="Img" v-if="company.company_logo" class="small-picture">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="setting_wrapper">
        <a class="" @click="editData(company._id)" href="#" v-if="can('company_edit',checkPermssions)">
          <i class="fa fa-edit"></i>
        </a>
        <a class="" @click="removeCompany(company._id)" href="#" v-if="can('company_delete',checkPermssions)">
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapActions } from 'vuex';
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "ShowItem",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  
  props: {
    checkPermssions:null,    
    company: {
      type: Object,
      default() {
        return {
          company_name: null,
          company_email: null,
          contactus_email: null,
          phone: null,
          address: null,
          company_description: null,
          company_logo: null,
        }
      }
    }
  },
  
  methods: {
    ...mapActions('CompaniesIndex', ['fetchData', 'setEditId']),
    ...mapActions('CompaniesSingle', ['setIsNew', 'destroyData']),
    editData(id) {
      this.setEditId(id);
      this.setIsNew(false);
    },

    removeCompany(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {

        if (result.isConfirmed) {
          console.log(id);

          this.$flashMessage.show({
          group : 'company',
          type  : 'success',
          title : 'Success',
          time  : 1000,
          html  : '<ul><li>Data has been deleted successfully.</li></ul>',
         
        })

          this.destroyData(id)
              .then(() => {
                this.fetchData();
              });
        }
      });
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
  
}
</style>