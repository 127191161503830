import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all: [],
        loading: false,
        languages:[]
    }
}

const getters = {
   
    all         :   state => state.all,
    total       :   state => state.all.length,
    loading     :   state => state.loading,
    languages   :   state => state.languages
}

const actions = {
    fetchData({ commit, state ,dispatch}, params) {

        commit('setLoading', true)
     
        axios.get(`${constants.API_URL}/api/doc-section`)
            .then(response => {
                commit('setAll', response.data.data)
                dispatch('setAllLanguages')

            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/doc-section/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(error)
            }).finally(() => {
                commit('setLoading', false)
            })
    },
    destroyPageData({ commit, state }, id) {
        axios.delete('/api/v1/docs-modules/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    //debugger;
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },

    setAllLanguages({commit,state}){
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(`${constants.API_URL}/api/language`,{headers:headers})
        .then(response => {
            let langArr = [];
            _.forEach(response.data.data, function (value) {
                langArr.push(`${value.language}`)
            });
            commit('setLanguagesAll', langArr)
        })
    },
    
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setLanguagesAll(state,item){
        state.languages = item
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
