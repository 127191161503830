import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";
import moment from "moment"

function initialState() {
    return {
        all: [],
        loading: false,
        event_timezone: null,
        event_type:null,
        languages: [],
        moderator:[],
        exhibitor:[],
    }
}

const getters = {
    all: state=> state.all,
    event: state => state.event,
    loading: state => state.loading,
    event_timezone: state => state.event_timezone,
    event_type: state => state.event_type,
    languages: state => state.languages,
    moderator: state => state.moderator,
    exhibitor: state => state.exhibitor,
    

}

const actions = {
    fetchData({ state, commit, dispatch }, eventId) {      
        console.log("Eventd****",eventId)
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let panelreq = axios.get(`${constants.API_URL}/api/panel-discussions/event-id/${eventId}`, {headers: headers});
        let eventReq = axios.get(`${constants.API_URL}/api/events/event-id/${eventId}`, {headers: headers});
        let totalpanelistReq = axios.get(`${constants.API_URL}/api/event-user/event-id/${eventId}`, {headers: headers});
        let exhibitorlistreq = axios.get(`${constants.API_URL}/api/events/${eventId}/exhibitors`, {headers: headers});

       

       

        Promise.all([panelreq, eventReq, totalpanelistReq, exhibitorlistreq]).then((response) => {

            let panelreqdata = response[0].data.data; //database panelist data can get from this 
            let eventInfo = response[1].data.data;  
            let moderatorinfo = response[2].data.data;  //all users data can get from this 
            let exhibitorlist = response[3].data.data;
            
            let canBeModerator = [];   
            let canBeExhibitor = [];         

            let eventLanguages = _.map(_.map(eventInfo.languages, 'language_id'), 'language');

           
            _.forEach(moderatorinfo, function (value) {
                canBeModerator.push({ id: `${value._id}`, name: `${value.first_name} ${value.last_name}` })
            });   
                
            _.forEach(exhibitorlist, function (value) {
               
                //let first_name = value.translations;
                canBeExhibitor.push({ id: `${value._id}`, name: `${value.translations[0].name}` })
            }); 
            commit('setEventTimezone', eventInfo.time_zone);
            moment.tz.setDefault(state.event_timezone);

            if(panelreqdata){
                for(let lp of panelreqdata){
                    let dateFormatSt = moment.utc(lp.start_time).tz(state.event_timezone).format('YYYY-MM-DD HH:mm');
                    lp.start_time = dateFormatSt;
                    let dateFormat = moment.utc(lp.end_time).tz(state.event_timezone).format('YYYY-MM-DD HH:mm');
                    lp.end_time = dateFormat;
                
                }
            }

           
                commit('setAll', panelreqdata);
                commit('setEvent', eventInfo);
                commit('setLanguages', eventLanguages);
                commit('setModerator', canBeModerator);
                commit('setExhibitor', canBeExhibitor);
                commit('setEventTimezone', eventInfo.time_zone);
           
                //moment.tz.setDefault(state.event_timezone);
            
              
               // return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    getEventDetails({commit, state}, params){
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/'+params.id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type);
                return response;
            });

    },


    // destroyData({ commit, state }, id) {
    //     commit('setLoading', true)
    //     return axios.delete(constants.MOCK_API_URL + '/api/v1/panel-agendas/' + id)
    //         .then(response => {
    //             commit('setAll', state.all.filter((item) => {
    //                 return item.id != id
    //             }))
    //             return response;
    //         });
    // },



    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

       return  axios.delete(constants.API_URL + '/api/panel-discussions/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(error)
            })
    },



    
    destroyQaData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/qas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.agenda_qas = item.agenda_qas.filter((qa) => {
                        return qa.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyPanelData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/agenda-panelists/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.panelists = item.panelists.filter((panel) => {
                        //debugger;
                        return panel.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/surveys/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        //debugger;
                        return survey.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyQuestion({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/survey-questions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.survey_questions = survey.survey_questions.filter((question)=>{
                            return question.id != id
                        })
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateAnswer({ commit, state, dispatch }, id) {
        commit('setLoading', true);
        const params = {

        }
        axios.put('/api/v1/qas/' + id, params)
            .then(response => {
                // commit('setAll', state.all.filter((item) => {
                //     return item.agenda_qas = item.agenda_qas.filter((qa) => {

                //         return qa.id != id
                //     })
                // }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setEventTimezone(state, item) {
        state.event_timezone = item;
    },
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEventType(state, value){
        state.event_type = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEvent(state, items) {
        state.event = items
    },
    setLanguages(state, items) {
        state.languages = items
    },
    setModerator(state, items) {
        state.moderator = items
    },
    setExhibitor(state, items) {
        state.exhibitor = items
    },

    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}

export function getTimeZone(){
    return this.getters.event_timezone;
}
