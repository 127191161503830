import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all: [],
        headers:{},
        query: {},
        loading: false,
        event_name: null,
        event_language: null,
        event_type: null,
        registered_users: [],
        logged_in_users: [],
        booth_attendies: [],
        presentation_attendies: [],
        total_booths: [],
        total_presentations: [],
        modules: null,
        enumModules: [
            { value: 'Live-Presentation', label: 'Live Presentations' },
            { value: 'Panel-Discussions', label: 'Panel Discussions' },
            { value: 'RoundTable', label: 'Round Table' },
            { value: 'OnDemand', label: 'Agenda On Demand' },
            { value: 'Exhibition-Area', label: 'Exhibition Area' },
            { value: 'Downloads', label: 'Event Downloads' },
            { value: 'Chats', label: 'Chats' },
            { value: 'Event-Hall', label: 'Event Hall' },
        ],
        chartDataMain   : {},
        chartQa         : {},
        chartPolling    : {},
        chartRating     : {},
        chartChat       : {},
        loaded          : false,
        tabAttendence   : [],
        tabQa           : [],
        tabPolling      : [],
        tabRating       : [],
        tabChat         : [],
        activeTab       : 'live-presentation' ,

        chartMainChat   : {},

        tabChat2            :   [],
        tabVideoCall        :   [],
        tabBusinessCard     :   [],
        tabBusinessCardView :   [],

        chartEventHall      :   {},

        tabEventHall        : [],
        tabEventHallScreen  : [],
        tabInformationDesk  : []


    }
}

const getters = {
    total               :   state   => state.all,
    loading             :   state   => state.loading,
    all                 :   state   => state.all,
    registered_users    :   state   => state.all.all_users,
    logged_in_users     :   state   => state.all.logged_in,
    booth_attendies     :   state   => state.all.booth_attendies,
    presentation_attendies: state   => state.all.presentation_attendies,
    total_booths        :   state   => state.all.total_booths,
    total_presentations :   state   => state.all.total_presentations,
    page_title          :   state   => state.page_title,
    event_name          :   state   => state.all.event_name,
    event_language      :   state   => state.all.event_language,
    modules             :   state   => state.modules,
    event_type          :   state   => state.all.event_type,
    enumModules         :   state   => state.enumModules,
    headers             :   state   => state.headers,
    chartDataMain       :   state   => state.chartDataMain,
    loaded              :   state   => state.loaded,
    chartQa             :   state   => state.chartQa,
    chartPolling        :   state   => state.chartPolling,
    chartRating         :   state   => state.chartRating,
    chartChat           :   state   => state.chartChat,
    tabAttendence       :   state   => state.tabAttendence,
    tabQa               :   state   => state.tabQa,
    tabPolling          :   state   => state.tabPolling,
    tabRating           :   state   => state.tabRating,
    tabChat             :   state   => state.tabChat,
    activeTab           :   state   => state.activeTab,

    chartMainChat       :   state   => state.chartMainChat,
    chartMainTab        :   state   => state.chartMainTab,
    tabChat2            :   state   => state.tabChat2,
    tabVideoCall        :   state   => state.tabVideoCall,
    tabBusinessCard     :   state   => state.tabBusinessCard,
    tabBusinessCardView :   state   => state.tabBusinessCardView,

    chartEventHall          : state=> state.chartEventHall,
    chartEventHallScreen    : state=> state.chartEventHallScreen,
    chartEventHallInfoDesk  : state=> state.chartEventHallInfoDesk,
    tabEventHall            : state=> state.tabEventHall,
    tabEventHallScreen      : state=> state.tabEventHallScreen,
    tabInformationDesk      : state=> state.tabInformationDesk
    

}

const actions = {

    fetchData({ commit, state },eventId) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/analytics/'+eventId, {headers: headers})
            .then(response => {
                commit('setLoaded',true)
                commit('setAll', response.data.data);
                commit('setHeaders',response.data.data.header);
                commit('setChartMain',response.data.data);
                commit('setChartQa',response.data.data);
                commit('setChartPolling',response.data.data);
                commit('setChartRating',response.data.data);
                commit('setChartChat',response.data.data);
                commit('setChartChat',response.data.data);
                commit('setTabAttendence',response.data.data);
                commit('setTabQa',response.data.data);
                commit('setTabPolling',response.data.data);
                commit('setTabRating',response.data.data);
                commit('setTabChat',response.data.data);

                return response;
            })
            .finally(() => {
                commit('setLoaded',true)
             
                commit('setLoading', false)
            })
    },

    onChangeEventHallData({commit,state},postData){
        commit('setLoading', true)
        commit('setLoaded',false)
             
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let eventId     = postData.event_id;
        let eventType   = postData.event_type;
        return axios.get(constants.API_URL + '/api/analytics/'+eventId+'/'+eventType, {headers: headers})
            .then(response => {
                commit('setLoaded',true)
                commit('setChartEventHall',response.data.data);
                commit('setTabEventHall',response.data.data);
                commit('setTabEventHallScreen',response.data.data);
                commit('setTabInformationDesk',response.data.data);
                return response;
            })
            .finally(() => {
                commit('setLoaded',true)
             
                commit('setLoading', false)
            })
    },
    onChangeChatfetchData({commit,state}, postData){
        commit('setLoading', true)
        commit('setLoaded',false)
             
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let eventId     = postData.event_id;
        let eventType   = postData.event_type;
        return axios.get(constants.API_URL + '/api/analytics/'+eventId+'/'+eventType, {headers: headers})
            .then(response => {
                commit('setLoaded',true)
                commit('setChartMainChat',response.data.data);
                commit('setChartMainTab',response.data.data);
                commit('setTabChat2',response.data.data);
                commit('setTabVideoCall',response.data.data);
                commit('setTabBusinessCard',response.data.data);
                commit('setTabBusinessCardView',response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoaded',true)
             
                commit('setLoading', false)
            })
    },
    onChangefetchData({ commit, state },postData) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let eventId     = postData.event_id;
        let eventType   = postData.event_type;
        return axios.get(constants.API_URL + '/api/analytics/'+eventId+'/'+eventType, {headers: headers})
            .then(response => {
                commit('setLoaded',true)

                if(eventType == 'Exhibition-Area' || eventType == 'Event-Downloads'){
                    commit('setChartMain',response.data.data);
                    commit('setTabAttendence',response.data.data);
                }else{
                    commit('setChartMain',response.data.data);

                    if(eventType == 'RoundTable'){
                        commit('setChartNotes',response.data.data);
                    }else{
                        commit('setChartQa',response.data.data);
                    }
                    
                    commit('setChartPolling',response.data.data);
                    commit('setChartRating',response.data.data);
                    commit('setChartChat',response.data.data);
                    commit('setChartChat',response.data.data);
                    commit('setTabAttendence',response.data.data);
                    commit('setTabQa',response.data.data);
                    commit('setTabPolling',response.data.data);
                    commit('setTabRating',response.data.data);
                    commit('setTabChat',response.data.data);
    
                }
                
                return response;
            })
            .finally(() => {
                commit('setLoaded',true)
             
                commit('setLoading', false)
            })
    },




    async generateSheet({    commit, state},postData){
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let eventId = postData.eventId;
        let event_type = postData.event_type
        return axios.get(constants.API_URL + '/api/analytics/report/'+eventId+'/'+event_type, {headers: headers})
            .then(response => {
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    async generateLivePresentationsSheet({    commit, state},eventId){
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/analytics/report/'+eventId+'/'+'live-presentation', {headers: headers})
            .then(response => {
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },



    generateDownloads({ commit, state }, params) {
        const event_name = state.all.event_name
        axios({
            url: '/api/v1/event/' + params.id + '/download-event-analytics',
            method: 'GET',
            // responseType: 'blob'
        }).then(response => {
            console.log(response.data)
            if (response.data.file_name) {
                var file_path = '/' + response.data.file_name
                //var file_path = 'host/path/file.ext';
                var a = document.createElement('A');
                a.href = file_path;
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

        })
            .catch(error => {

            })
            .finally(() => {

            })

    },
    destroyData({ commit, state }, id) {
        axios.delete('/api/v1/event-analytics/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    
    setModule({ commit }, value) {
        commit('setModule', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setActiveTab({commit},value){
        commit('setActiveTab', value);
    }
}

const mutations = {
    setActiveTab(state,item){
        state.activeTab = item
    },
    setHeaders(state,item){
        state.headers = item
    },
    setAll(state, items) {
        state.all = items
    },
   
    setModule(state, value) {
        //console.log(value)
        state.modules = value
    },
    downloadPDF() {
        alert(1)

    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setLoaded(state,value){
        state.loaded = value;
    },
    getChartData(item, message, color){
        let dataChartQa = {}
        let lableQa =[];
        let dataSetQa = {};
        let dataSetLableQa = [];
        for(let data of item){
            lableQa.push(data.title);
            dataSetLableQa.push(data.usersCount)
        }
        dataChartQa.labels = lableQa;
        dataSetQa.label = message ;
        dataSetQa.backgroundColor = color;
        dataSetQa.data = dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        return dataChartQa;
    },
    setTabEventHall(state,item){
        state.tabEventHall = item.module;
    },
    setTabEventHallScreen(state,item){
        state.tabEventHallScreen = item.module_hall_screen;
    },
    setTabInformationDesk(state,item){
        state.tabInformationDesk = item.module_info_desk;
    },
    setTabBusinessCardView(state,item){
        state.tabBusinessCardView =  item.module_business_card_view;
    },
    setTabBusinessCard(state,item){
        state.tabBusinessCard = item.module_busines_card;
    },
    setTabVideoCall(state,item){
        state.tabVideoCall = item.module_video_call;
    },
    setTabChat2(state,item){
        state.tabChat2 = item.module_chat;
    },
    setChartMainTab(state,item){
        state.chartMainTab = item;
    },

    setChartEventHall(state,item){
        let dataChartQa     =   {}
        let lableQa         =   ['Event Hall', 'Event Hall Screen', 'Information Desk'];
        let dataSetQa       =   {};
        let dataSetLableQa  =   [item.module_attendies,item.module_hall_screen_attendies,item.module_info_desk_attendies];
        dataChartQa.labels  =   lableQa;
        dataSetQa.label     =   'People Attended in Event Hall, Event Hall Screen, Information Desk' ;
        dataSetQa.backgroundColor = '#003f5c';
        dataSetQa.data      =   dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartEventHall = dataChartQa;
    },
    setChartMainChat(state,item){
        let dataChartQa     =   {}
        let lableQa         =   ['Chat', 'Video Call', 'Business Card', 'Business Card View'];
        let dataSetQa       =   {};
        let dataSetLableQa  =   [item.module_chat_total_attendees,item.module_video_call_attendees,item.module_busines_card_attendees,item.module_business_card_view_total_attendees];
        dataChartQa.labels  =   lableQa;
        dataSetQa.label     =   'People Participated in Chat , Video Call , Business Card , Bussiness Card View' ;
        dataSetQa.backgroundColor = '#003f5c';
        dataSetQa.data      =   dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartMainChat = dataChartQa;
        
    },
    setChartChat(state,item){
        let dataChartQa = {}
        let lableQa =[];
        let dataSetQa = {};
        let dataSetLableQa = [];
        for(let data of item.module_chat){
            lableQa.push(data.title);
            dataSetLableQa.push(data.usersCount)
        }
        dataChartQa.labels = lableQa;
        dataSetQa.label = 'People Participated in Chat' ;
        dataSetQa.backgroundColor = '#003f5c';
        dataSetQa.data = dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartChat = dataChartQa;
    },
    setChartRating(state,item){
        let dataChartQa = {}
        let lableQa =[];
        let dataSetQa = {};
        let dataSetLableQa = [];
        for(let data of item.module_rating){
            lableQa.push(data.title);
            dataSetLableQa.push(data.usersCount)
        }
        dataChartQa.labels = lableQa;
        dataSetQa.label = 'People Participated in Rating' ;
        dataSetQa.backgroundColor = '#bc5090';
        dataSetQa.data = dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
  
        state.chartRating = dataChartQa;

    },
    setChartPolling(state,item){
        let dataChartQa = {}
        let lableQa =[];
        let dataSetQa = {};
        let dataSetLableQa = [];
        for(let data of item.module_polling){
            lableQa.push(data.title);
            dataSetLableQa.push(data.usersCount)
        }
        dataChartQa.labels = lableQa;
        dataSetQa.label = 'People Participated in Polling' ;
        dataSetQa.backgroundColor = '#58508d';
        dataSetQa.data = dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartPolling = dataChartQa;
    },

    setChartNotes(state,item){
        // Chart Qa
      let dataChartQa = {}
      let lableQa =[];
      let dataSetQa = {};
      let dataSetLableQa = [];
      for(let data of item.module_qa){
          lableQa.push(data.title);
          dataSetLableQa.push(data.usersCount)
      }
      dataChartQa.labels = lableQa;
      dataSetQa.label = "People Participated in Notes" ;
      dataSetQa.backgroundColor = "#f87979";
      dataSetQa.data = dataSetLableQa
      dataChartQa.datasets = [dataSetQa];
      state.chartQa = dataChartQa;
        //
        /*state.chartQa,
        state.chartPolling,
        state.chartRating,
        state.chartChat,*/
  },
    setChartQa(state,item){
          // Chart Qa
        let dataChartQa = {}
        let lableQa =[];
        let dataSetQa = {};
        let dataSetLableQa = [];
        for(let data of item.module_qa){
            lableQa.push(data.title);
            dataSetLableQa.push(data.usersCount)
        }
        dataChartQa.labels = lableQa;
        dataSetQa.label = "People Participated in Q&A" ;
        dataSetQa.backgroundColor = "#f87979";
        dataSetQa.data = dataSetLableQa
        dataChartQa.datasets = [dataSetQa];
        state.chartQa = dataChartQa;
         
          //
          /*state.chartQa,
          state.chartPolling,
          state.chartRating,
          state.chartChat,*/
    },
    setChartMain(state,item){
         let dataChart ={};
        let lable =[];
        let dataSet = {};
        let dataSetLable = [];
        for(let data of item.module_detail){
            lable.push(data.title);
            dataSetLable.push(data.usersCount)
        }
        dataChart.labels = lable;
        dataSet.label = "People Attended " +item.module_name;
        dataSet.backgroundColor = "#003f5c";
        dataSet.data = dataSetLable
        dataChart.datasets = [dataSet];
        state.chartDataMain = dataChart;
    },
    setTabAttendence(state,item){
        state.tabAttendence = item.module_detail;
    },
    setTabQa(state,item){
        state.tabQa         = item.module_qa;
    },
    setTabPolling(state,item){
        state.tabPolling    = item.module_polling;
    },
    setTabRating(state,item){
        state.tabRating     = item.module_rating;
    },
    setTabChat(state,item){
        state.tabChat       = item.module_chat
    }

    

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
