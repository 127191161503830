import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import SimpleHeader from '@/components/Header/SimpleHeader';
import OverlayLoading from "@/components/Commons/OverlayLoading";
import DropFiles from "@/components/Commons/DropFiles";
import store from '@/store/index';
import FlashMessage from '@smartweb/vue-flash-message';
import { VueReCaptcha } from 'vue-recaptcha-v3';
//import CKEditor from '@ckeditor/ckeditor5-vue';
import vSelect from "vue-select";
import VueSweetalert2 from 'vue-sweetalert2';
import ElementPlus from 'element-plus';
import Tabs from 'vue3-tabs-component';
import {VTooltip, Tooltip} from 'v-tooltip';
import { vfmPlugin } from 'vue-final-modal'
import { useVuelidate } from '@vuelidate/core'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Import css libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'element-plus/dist/index.css';
import 'v-tooltip/dist/v-tooltip.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import VueTimepicker from 'vue3-timepicker'

// CSS
import 'vue3-timepicker/dist/VueTimepicker.css'
import Popper from "vue3-popper";

const vuetify = createVuetify({
    components,
    directives,
  })

const app = createApp(App);

/* Global components */

app.component('SimpleHeader', SimpleHeader)
app.component('OverlayLoading', OverlayLoading)
app.component('v-select', vSelect)
app.component('DropFiles', DropFiles)
app.directive('tooltip', VTooltip)
app.component('VTooltip', Tooltip)

//app.use(CKEditor)
app.use(vfmPlugin)
app.use(Tabs)
app.use(VueSweetalert2)
app.use(ElementPlus)
app.use(FlashMessage,{time:1000});
app.use(VueReCaptcha, { siteKey: '6LcEEsYhAAAAAFa8ff9ptJmorvm1ePcrnPUtWbQh' })
app.use(vuetify)
app.component('VueDatePicker', VueDatePicker);
app.component('VueTimepicker', VueTimepicker);
app.component('VPopper',Popper);
/* Global variables */
app.config.globalProperties.v$ = useVuelidate();

/* Routing */
app.use(router)

/* Redux store */
app.use(store)

/* App */
app.mount('#app')
