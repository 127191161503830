import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"
function initialState() {
    return {
        item: {
            _id          : null,
            event_id    : null,
            title       : null,
            description : null,
            asset_type  : '',
            asset_file  : []
           
        },
        asset_typeEnum: [ { value: 'video', label: 'Video' },{ value: 'video_gallary', label: 'Video Gallary' }, { value: 'image', label: 'Image' },{ value: 'image_gallary', label: 'Image Gallary' }, { value: 'document', label: 'Document' }, ],
        loading         : false,
        is_new          : false,
        is_editing      : false,
    }
}

const getters = {
    item            : state => state.item,
    loading         : state => state.loading,
    asset_typeEnum  : state => state.asset_typeEnum,
    is_new          : state => state.is_new,
    is_editing      : state => state.is_editing,
}

const actions = {
    setItem({commit,state,dispatch},value){
        commit('setItem',value)
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.content_section;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/event-assets', params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
      
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params.__v;
        delete params._id;
        delete params.__v;
        delete params.created_at;
        delete params.updated_at;
        delete params.created_by;
        delete params.page_content;
        delete params.is_deleted;
        delete params.updatedAt;
        delete params.createdAt;
        delete params.status;
       
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/event-assets/'+state.item._id, params, { headers: headers })
        .then(response => {
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
     
    },
    
    setEvent({commit}, value){
        commit('setEvent',value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setAsset_type({ commit }, value) {
        commit('setAsset_type', value)
    },
    setImages({ commit }, value) {
        commit('setImages', value)
    },
    destroyImages({ commit }, value) {
        commit('destroyImages', value)
    },
    destroyUploadedImages({ commit }, value) {
        commit('destroyUploadedImages', value)
    },
    setVideos({ commit }, value) {
        commit('setVideos', value)
    },
    destroyVideos({ commit }, value) {
        commit('destroyVideos', value)
    },
    destroyUploadedVideos({ commit }, value) {
        commit('destroyUploadedVideos', value)
    },
    setDocuments({ commit }, value) {
        commit('setDocuments', value)
    },
    destroyDocuments({ commit }, value) {
        commit('destroyDocuments', value)
    },
    destroyUploadedDocuments({ commit }, value) {
        commit('destroyUploadedDocuments', value)
    },
    setAssetFile({commit},value){
        commit('setAssetFile',value);
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    removeAssetFile({ commit }, value) {
        commit('removeAssetFile', value)
    },
    setAssetType({commit},value){
        commit('setAsset_type',value)
    },
    setLoading({commit},value){
        commit('setLoading',value)
    }
}

const mutations = {
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setEventId(state,value){
        state.item.event_id = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
   },
    setTitle(state, value) {
        state.item.title = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setAsset_type(state, value) {
        state.item.asset_type   = value
        state.item.asset_file   = [];
    },
    setAssetFile(state,value){
        state.item.asset_file.push(value);
    },
    setImages(state, value) {
        for (let i in value) {
            let images = value[i];
            if (typeof images === "object") {
                state.item.images.push(images);
            }
        }
    },
    removeAssetFile(state, value) {
        for (let i of state.item.asset_file) {
            if (i.key == value) {
                state.item.asset_file.splice(i.key, 1);
            }
        }
    },
    destroyUploadedImages(state, value) {
        for (let i in state.item.uploaded_images) {
            let data = state.item.uploaded_images[i];
            if (data.id === value) {
                state.item.uploaded_images.splice(i, 1);
            }
        }
    },
    setVideos(state, value) {
        for (let i in value) {
            let videos = value[i];
            if (typeof videos === "object") {
                state.item.videos.push(videos);
            }
        }
    },
    destroyVideos(state, value) {
        for (let i in state.item.videos) {
            if (i == value) {
                state.item.videos.splice(i, 1);
            }
        }
    },
    destroyUploadedVideos(state, value) {
        for (let i in state.item.uploaded_videos) {
            let data = state.item.uploaded_videos[i];
            if (data.id === value) {
                state.item.uploaded_videos.splice(i, 1);
            }
        }
    },
    setDocuments(state, value) {
        for (let i in value) {
            let documents = value[i];
            if (typeof documents === "object") {
                state.item.documents.push(documents);
            }
        }
    },
    destroyDocuments(state, value) {
        for (let i in state.item.documents) {
            if (i == value) {
                state.item.documents.splice(i, 1);
            }
        }
    },
    destroyUploadedDocuments(state, value) {
        for (let i in state.item.uploaded_documents) {
            let data = state.item.uploaded_documents[i];
            if (data.id === value) {
                state.item.uploaded_documents.splice(i, 1);
            }
        }
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    setAsset_typeEnum(state, value) {
        state.asset_typeEnum = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
