<template>
    <div class="container-fluid">
        <div class="row">
    
           <!-- {{ questionid }}---question id
           {{ surveyid }}---survey id -->
    
            <div class="col-lg-12 mb-3">
                <label>Question Title</label>
                <div class="kt-input-icon">
                    <input type="text" class="form-control" :value="sitems.question_title" name="language_title" maxlength="200" placeholder="Question Title" @input="updateQuestionTitle" />
                </div>
            </div>
    
            <div class="col-lg-12 mb-3">
                <label>Required</label>
                <br />
                <div class="switch switch-sm switch-primary">
                    <input-switch name="require" :value="sitems.require" id="customSwitchesEx" class="custom-control-input" @change="updateIs_required" />
                </div>
            </div>
    
            <div class="col-lg-12 mb-3">
                <label>Question Type *</label>
                <!-- :modelValue="getQuestionType(surveyList.question_type)" -->
                <v-select name="language" class="" :modelValue="sitems.question_type" placeholder="Question Type" label="type" track-by="id" :reduce="(option) => option.id" :options="questiontype" @option:selected="selectoptions" />
            </div>
    
            <!-- {{ sitems }} -->
    
            <div class="col-lg-12 mt-3" v-if="showdiv==true && sitems.question_type!='textbox'">
                <label>Options</label>
                <input type="text" class="form-control" name="dfdf" maxlength="200" placeholder="" v-model="newTask" @keyup.enter="addtask" />
                <span class="textcenter">To add multiple options please enter.</span>
            </div>
    
            <div class="col-lg-12 taskItems" v-if="showdiv==true && sitems.question_type!='textbox'">
                <label>Options</label>
                <ul>
                    <!-- v-for="(task,index) in tasks" :key="index" -->
    
    
    
                    <li v-for="(answer, index2) in sitems.answers" :key="index2">
    
                        <div class="input-group input-group-4 mb-3">
    
                            <input @input="updateOptions" :target_id="answer._id" type="text" class="form-control" :value="answer.answer_title" name={{answer.answer_title}} maxlength="200" placeholder="" />
                            <span class="input-group-text" @click="removeItem(answer._id)"><i class="fa fa-trash-alt"></i></span>
                        </div>
    
                    </li>
    
    
                    
                </ul>
    
            </div>
    
            
    
            <div class="text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="updateSurveyquestions()">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelSurveyEdit">Cancel</button>
            </div>
    
        </div>
    </div>
    </template>
    
    <script>
    import InputSwitch from "@/components/Commons/InputSwitch";
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import ObjectID from 'bson-objectid';
    import _, {
        clone
    } from "lodash";
    
    
    import {
            gettingErrorMessage
        } from "@/services/GetValidationMessage";
        //import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
        import {
            useVuelidate
        } from '@vuelidate/core'
        import {
            required,
            email,
        } from '@vuelidate/validators'
    
    
    
    export default {
        data() {
            return {
                questiontype: [{
                    id: 1,
                    type: 'radio'
                }, {
                    id: 2,
                    type: 'checkbox'
                }, {
                    id: 3,
                    type: 'textbox'
                }],
                tasks: [],
                newTask: "",
                showdiv: true,
                optionKey: null,
    
            }
        },
        name: "SurveyFormFeedback",
    
        props: {
            agenda_id: {
                type: [String, null],
                default () {
                    return null;
                }
            },
    
            surveyList: {
                type: Object,
                default () {
                    return null;
                }
            },
            questionid: String,
            surveyid: String,
            questionindex: String,
        },
    
        components: {
            InputSwitch
        },
    
        computed: {
            ...mapGetters('FeedbackSingle', [
                'sitems',
                
            ]),
    
            ...mapGetters('FeedbackIndex', ['surveyitems']),/////////
        },
    
        setup() {
                return {
                    v$: useVuelidate()
                }
            },
    
    
            validations() {
                return {           
                   
                    sitems: {
                       
                        question_title: {
                            required,
                        }
                    },
        
                  
        
                };
            },   
    
        mounted() {
           
    
            var surv_que = {
                questionid: this.questionid,
                surveyid: this.surveyid
            };
            // this.fetchsurveydatainfo(surv_que);
            //console.log('Question id is ',this.questionindex);   
    
        },
    
        methods: {
            ...mapActions('FeedbackIndex', {
                fetchSurveyDataforFeedback: "fetchSurveyDataforFeedback"
            }), //questions data

            ...mapActions('FeedbackSingle', {
                //fetchsurveydatainfo: "fetchData",
                setQItem: "setQItem",
                updateSurveyqData: "updateSurveyqData"
                
            }),
    
            ...mapActions('FeedbackSingle', [
                'setIs_required',
                'setselectoptions',
                'setQuestionTitle',
                "setIsSubEditing",
                'setOptionFunction',
                "resetSItemState"
    
            ]),
            getQuestionType(question_type) {
                return _.has(question_type, '_id') ? question_type._id : question_type;
            },
    
            updateIs_required(e) {
                this.setIs_required(e.target.checked)
            },
    
            selectoptions(value) {            
                if (value.type == 'checkbox' || value.type == 'radio') {
                    this.showdiv = true;
                }else{
                    this.showdiv = false;
                }
              // console.log(value.type);
                this.setselectoptions(value.type);
            },
    
            updateOptions(e) {
        
                let multidata = {
                    corevalue: e.target.attributes.target_id.nodeValue,
                    evalue: e.target.value
                };
    
    
                this.setOptionFunction(multidata);
            },
    
    
            addtask() {
                if (this.newTask) {
                    var shallow = _.clone(this.sitems.answers);
                    shallow.push({
                        answer_title: this.newTask,
                        description: this.newTask,
                        _id: ObjectID(),
                    });
    
                    this.setQItem(shallow);
                }
    
                this.newTask = "";
            },
    
            removeItem(rmid, info) {
                console.log(info);
    
                var asnRemove = _.clone(this.sitems.answers);
                asnRemove = _.remove(asnRemove, (currentObject) => currentObject._id !== rmid);
                this.setQItem(asnRemove);
    
            },
    
            async updateSurveyquestions() {
    
                const isFormCorrect = await this.v$.$validate()
    
                 let generalError = []; 
                    _.map(this.v$.$errors, (item) => {
                      if(item.$property == "question_title"){
                        generalError.push("<li>Question title is required</li>");
                      }                  
                    });
    
    
                    if (!_.isEmpty(generalError)) {
                            this.$flashMessage.show({
                                group   : 'feedback_index',
                                type    : 'error',
                                time    : 1000,
                                title   : 'Validation Failed',
                                html    : `<ul>${generalError.join("")}</ul>`
                            });
                        return;
                    }
    
    
    
                var surv_qued = {
                    questionid: this.questionid,
                    surveyid: this.surveyid,
                };
    
                
                this.updateSurveyqData(surv_qued)
    
                    .then(async () => {
                          
                        this.$flashMessage.show({
                            group   : 'feedback_index',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Question has been Updated successfully</li></ul>'
    
                        });
    
                        this.fetchSurveyDataforFeedback(this.$route.params.event_id); //questions data   
                        this.resetSItemState();

                        
                       
                       
                        // await this.fetchEventUsersAll(this.$route.params.event_id);
                        //this.resetState();
                    })
                    .catch((error) => {
                        console.error("Error update panel discussion", error)
                        let html = gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'feedback_index',
                                type: 'error',
                                title: 'Failed',
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                    })
            },
    
            updateQuestionTitle(e) {
                //console.log(e.target.value,'targetttttt')
                this.setQuestionTitle(e.target.value);
            },
    
    
            cancelSurveyEdit(){
                this.setIsSubEditing(false);
                this.fetchSurveyDataforFeedback(this.$route.params.event_id); //questions data   
                this.resetSItemState();
                this.showdiv =true;

               
            },
    
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .button {
        border: 1px solid white;
        border-radius: 2px;
        color: white;
        text-decoration: none;
        padding: 8px 15px;
        transition: all 0.3s ease;
        cursor: pointer;
    
        &--transparent {
            background-color: transparent;
        }
    
        &:hover {
            background-color: white;
            border: 1px solid #1fa9e5;
            color: #1fa9e5;
        }
    }
    
    .taskItems li {
        list-style: none;
        margin-bottom: 10px;
    }
    
    .taskItems ul {
        padding-left: 0px;
        margin-left: 0px;
    }
    
    .textcenter {
        text-align: center;
        margin-bottom: 20px;
        display: block;
    }
    </style>
    