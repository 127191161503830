import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";
import ObjectID from 'bson-objectid';

function initialState() {
    return { 
          loading: false,
          surveytemp:false, 
          is_survey_editing: false,
          is_new_question: false,
          datanum:null,
          questionstatus:true,
         // questionAdded: false,        
          sitems:{    
                
                
         //   questions_detail:[            
           //     {
                    question_title : null,
                    question_type: null,
                    require: false,
                    answers:[  
                                {                     
                                answer_title: null,
                                description: null,    
                                }
                     ]
                       
               
              //  }
           // ],

           
           
        },
        
    }
}

const getters = {   
    sitems: state => state.sitems,       
    loading: state => state.loading,
    surveytemp: state => state.surveytemp,
   // questionAdded: state => state.questionAdded,
    // surveyitems: state => state.surveyitems,
    question_type: state => state.sitems.question_type,
    is_survey_editing: state => state.is_survey_editing,
    is_new_question: state => state.is_new_question,
    datanum: state => state.datanum,
    questionstatus: state => state.questionstatus,
    

}

const actions = {
    setAnswersOptions({commit,state},multidata){
        let answerText  = multidata.answerText;
        let answerId    = multidata.answerId;

        const params = _.cloneDeep(state.sitems.answers); 
        
        let index = params.findIndex(x => x.tempID===answerId);
        params[index].answer_title = answerText;
        commit('setQItem',params);
       // params[resultm].answer_title = answerText;         
        
        //commit('setQItem',params);
        /*var shallow = _.clone(this.sitems.answers); 
        console.log("shallow--->",shallow);
        let currentObject = _.find(shallow, function(item) {
            return item._id == e.target.attributes.target_id.nodeValue;
        });     
        currentObject.answer_title = e.target.value;
        console.log("current val",currentObject);*/


    },

    setOptionFunction({ commit, state},multidata){
       
        var ansid = multidata.corevalue; // answer id
        var anstxt = multidata.evalue; //edit text    

        console.log(ansid,anstxt);
        const params = _.cloneDeep(state.sitems.answers);  
       
       

        var result = _.size(params);
        var resultm = result-1;   

        var index = _.findIndex(params, ['_id', ansid]); 
        console.log(index,'keeye');           
       if(index!==-1){
          console.log('params',params);
          params[index].answer_title = anstxt;
          
        }else{
         params[resultm].answer_title = anstxt;         
        } 
        commit('setQItem',params);



        
        //else{
    //       var nop = params[resultm]["answer_title"] = anstxt;          
    //    } 

      
    },



    storeData({ commit, state, dispatch }, sur_id) {
        commit('setLoading', true)
        
        const params = _.cloneDeep(state.sitems); 


        delete params._id;
        delete params.__v;
        delete params.is_deleted;      
        delete params.createdAt;
        delete params.updatedAt;


        if(params.question_type=='textbox'){
            delete params.answers;
            //console.log('abbsss',params.answers);
              /*  params.answers={
                    answer_title: 'textbox', 
                    description: null,            
                    _id: ObjectID(),       
                }*/
            }

       const mainanswers = _.drop(params.answers);
       params.answers = mainanswers;


       // delete params.data;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };   
       

        return axios.post(constants.API_URL + '/api/survey/'+sur_id.surveyid+'/question', params, {headers: headers})
            .then(response => {
                commit('setSurveytemp', true);
                commit('setIsNewQuestion', false);
                commit('setItem', response.data);
              //  console.log('question has been added',response.data.data.questions_detail);  
                commit('setDataNum',response.data.data.questions_detail.length)
                
                //commit('resetState')  
                        
                return response
               // commit('setItem', state.sitems)   
            })
            .finally(() => {
                
                commit('setLoading', false)
               // commit('setQuestionadded', true)
            })
        
    },


    async destroyData({ commit, state }, data) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };        

        await axios.delete(constants.API_URL + '/api/survey/'+ data.surveyid + '/question/' + data.questionid, {headers: headers})
            .then(response => {
                
               // commit('setItem', state.sitems.filter((item) => {
                 //   return item.questionid != data.questionid
                //}))
                return response;
       
            }).finally(() => {
                commit('setLoading', false)
            })
            
    },
   


    updateSurveyqData({ commit, state, dispatch },surv_qued) {
        

        commit('setLoading', true)

        let params = _.cloneDeep(state.sitems);  
        
        if(params.question_type!='textbox'){
           
             params.answers = params.answers.filter(param => param.answer_title != '');    

        }    
     
        if(params.question_type=='textbox'){
        //console.log('abbsss',params.answers);

        console.log('uuuuuu',params)
       
            /*params.answers=
            [{
                answer_title: 'textbox', 
                description: null,            
                _id: ObjectID(),       
            }]*/
           delete params.answers;
        }
        console.log("paramsparams",params)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        

       return axios.patch(constants.API_URL + '/api/survey/' + surv_qued.surveyid + '/question/' + surv_qued.questionid , params, {headers: headers})       
       .then((response) => {

            console.log("response.data",response.data);
            commit('setIsSubEditing', false);
            if(response.data.data){
                let question_detail  =response.data.data;
                var questionsData  = _.find(question_detail.questions_detail, {_id:surv_qued.questionid});
                console.log("questionsDataquestionsDataquestionsData",questionsData);
                commit('setItem', questionsData); 
       
            }
                 
               // return surv_qued.agenda_id;             
               return response;
               
            })
            .finally(() => {
                commit('setLoading', false)
                commit('setSurveytemp', true)
            })
       
    },


    
    // fetchData({ commit, dispatch }, surv_que) {
             
       
    //     commit('setLoading', true);      

    //     const headers = {
    //         'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     };
       

    //     let surveyquestionsReq = axios.get(constants.API_URL +'/api/survey/'+surv_que.surveyid+'/question/'+surv_que.questionid, {headers: headers});

    //    // let surveyReq = axios.get(constants.API_URL +'/api/survey/'+surv_que.agendaid, {headers: headers});

    //     Promise.all([surveyquestionsReq]).then((response) => {

    //         let sur_q = response[0].data; 
    //        console.log(sur_q,'behhhhtareeeeeeeeeen');

            
    //              commit('setItem', sur_q); 
    //             // commit('setItem', mainsurvey);                
                
                
    //              return response;
    //          })
    //          .finally(() => {
    //              commit('setLoading', false)
    //             //  commit('resetSurveyState');
    //          })   
                  
    // },
    
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setModule({ commit }, value) {
        commit('setModule', value)
    },
    setModule_id({ commit }, value) {
        commit('setModule_id', value)
    },
    setIs_open({ commit }, value) {
        commit('setIs_open', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setselectoptions({ commit }, value) {
        commit('setselectoptions', value)
    },
    setIs_required({ commit }, value) {
        commit('setIs_required', value)
    },
    setIsOpen({ commit }, value) {
        commit('setIsOpen', value)
    },
    // setSurveytitle({ commit }, value) {
    //     commit('setSurveytitle', value)
    // },   
    setQuestionTitle({ commit }, value) {
        commit('setQuestionTitle', value)
    },
    setItem({ commit },value) {
        commit('setItem',value)
    },
    setQItem({ commit },value) {
        commit('setQItem',value)
    },   
    setIsSubEditing({ commit }, value) {
        commit('setIsSubEditing', value)
    }, 
    setIsNewQuestion({ commit }, value) {
        commit('setIsNewQuestion', value)
    },
    setSurveytemp({ commit }, value) {
        commit('setSurveytemp', value)
    }, 
    setDataNum({ commit }, value) {
        commit('setDataNum', value)
    }, 

    setQuestionstatus({ commit }, value) {
        commit('setQuestionstatus', value)
    }, 
    resetSItemState({ commit }) {
        commit('resetState')
    },

    
    

}

const mutations = { 
    resetSItemState(state,value){
        state.sitems = Object.assign(state.sitems, initialState())

    } ,
    // setSurveytitle(state, value) {
    //     state.sitems.title = value
    // },
    setQuestionstatus(state, value) {
        state.questionstatus = value
    },

    setIsOpen(state, value) {
        state.sitems.is_open = value
    },
    setItem(state, item) {  
        console.log("********SEtItem*****",item);      
        state.sitems = item
    },   
    setQItem(state, item) {        
        state.sitems.answers = item
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setModule(state, value) {
        state.item.module = value
    },
    setModule_id(state, value) {
        state.item.module_id = value
    },
   
    setModuleEnum(state, value) {
        state.moduleEnum = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setselectoptions(state, value) {       
        state.sitems.question_type = value
    },
    setIs_required(state, value) {
        state.sitems.require = value
    },
    setQuestionTitle(state, value) {        
        state.sitems.question_title = value       
    },
    setSurveytemp(state, value) {
        state.surveytemp = value
    },
    setQuestionadded(state, loading) {
        state.questionAdded = loading
    },
    setIsSubEditing(state, value) {        
        state.is_survey_editing = value
        
    },
    setIsNewQuestion(state, value) {        
        state.is_new_question = value
        
    },
    setDataNum(state, value) {        
        state.datanum = value
        
    }, 


    


    


    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
