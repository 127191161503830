<template>

<div v-if="can('virtual_event_access',checkPermssions)">

  <GeneralDashboardLayout>
      <template v-slot:header>
        <div class="header-roles header-roles--event">
          <BackButton :to="{name: 'dashboard.index'}" />
          <h1 class="title text-center">Event List</h1>
          <RouterLink :to="{ name: 'events.create' }" v-if="can('virtual_event_create',checkPermssions)" class="btn btn-outline-light">New Event</RouterLink>
        </div>
      </template>
      <template v-slot:content>
        
        <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="5">
          <div id="assets_content" class="event_main">
            <div class="container-fluid">
              <div class="row search_bar">
                <div class="col-md-4 offset-md-4 ">
                  <div class="input-group">
                    <input type="text" class="form-control input-rounded" placeholder="Right icon" v-model="search">
                    <span class="input-group-text">
                      <i class="fas fa-search" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="labelhdwrapper row">
                <div class="offset-lg-1 col-lg-7 col-md-8">
                  <span>Event Name</span>
                </div>
                <div class="col-md-4 text-right">
                  <span>Start/End Time</span>
                </div>
              </div>
              <TogglePrimary v-for="(event, index) in props.all" v-bind:key="index">
                <template v-slot:label>
                  <div class="container-fluid">
                    <div class="row">
                      <div class="offset-lg-1 col-lg-7 col-md-8">
                        <h2 class="event_main--title">{{event.title}}</h2>
                      </div>
                      <div class="col-md-4">
                        <div class="event_main--info">
                          <span>{{getStartEventDate(event)}} / {{getEndEventDate(event)}}</span>
                          <a  v-if="can('virtual_event_delete',checkPermssions)" href="#" class="btn btn-outline-secondary" @click="removeEvent($event, event)">
                            <i class="bx bx-trash" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:default>
                  <div class="event_main--wrapper container-fluid">
                    <div class="row event_main--row">
                      <div class="offset-lg-1 col-lg-5 col-md-6">
                        <div class="title">
                          <h2>{{event.title}}</h2>
                        </div>
                        <div class="sceduled-time">
                          <span> {{getEventScheduledTime(event)}}</span>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="description" v-html="event.description" />
                      </div>
                      <div class="col-3">
                        <div class="options">
                          
                          <a :href="getEventDomainUrl(event)" class="btn btn-primary" target="_blank">Open Event</a>
                          <RouterLink :to="{name: 'event_dashboard.index', params: {event_id: event._id}}" class="btn btn-outline-secondary" v-if="can('virtual_event_view',checkPermssions)">Dashboard</RouterLink>
                          <RouterLink :to="{name: 'events.edit', params: {event_id: event._id}}"  v-if="can('virtual_event_edit',checkPermssions)" class="btn btn-outline-secondary">Edit Event</RouterLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </TogglePrimary>
            </div>
          </div>
        </SearchableContainer>
      </template>
  </GeneralDashboardLayout>
</div>
<div v-else>
  <default-layout>
      <div class="no-event" >
        <h2>No Event Found.</h2>  
      </div>
    </default-layout>
</div> 

<FlashMessage group="events_index"/>
    
   
</template>

<script>

import DefaultLayout from "@/components/layouts/Default";
import {mapActions, mapGetters} from "vuex";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import BackButton from "@/components/Commons/BackButton";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import constants from "@/config/constants";
import moment from "moment";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "EventsIndex",
  components: {TogglePrimary, BackButton, GeneralDashboardLayout, DefaultLayout, SearchableContainer},
  data() {
    return {
      search: ''
    };
  },
  setup() {
    

  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role"}),
    ...mapGetters("EventsIndex", [
      "data",
      "all",
      "total",
      "loading",
      "event_type",
      "relationships",
      "permission_prefix",
      "page_title"
    ])
  },
  created() {
    this.fetchPermissions();
    //this.verifyPermissions()
    this.fetchData();
  },
  beforeMount(){

  },
  unmounted() {
    this.resetState();
    delete this.$flashMessage.groups.events_index;

  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","verifyPermissions"]),
    ...mapActions("EventsIndex", ["fetchData", "resetState", "destroyData"]),
    getEventScheduledTime(event) {
      let start_date  = event.start_date;
      let end_date    = event.end_date;
     // return moment(String(start_date)).format('hh:mm A') +"-"+ moment(String(end_date)).format('hh:mm A')
      return moment.utc(start_date).tz(event.time_zone).format('hh:mm A') + "-"+moment.utc(end_date).tz(event.time_zone).format('hh:mm A');
    
      
    },
    getStartEventDate(event){
      // return moment(String(dateTime)).format('D-MMMM')
       return moment.utc(event.start_date).tz(event.time_zone).format('D-MMMM')
    
    },
    getEndEventDate(event){
      return moment.utc(event.end_date).tz(event.time_zone).format('D-MMMM')
   
    },
    removeEvent(event, obj) {
      event.preventDefault();
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData(obj._id)
              .then(() => {
                this.$flashMessage.show({
                  group:  'events_index',
                  type:   'success',
                  title:  'Success',
                  html:   '<ul><li>Event has been deleted successfully</li></ul>'
                  
                });

                this.fetchData();
              });
        }
      });
    },
    getEventDomainUrl(event) {
      const isValidHttpUrl = (string) => {
        let url;

        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }

       if (isValidHttpUrl(event.event_domain)) {
         return event.event_domain
       }

      const domainRegExp = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/i);

      if (domainRegExp.test(event.event_domain)) {
        return 'https://' + event.event_domain;
      }

      return 'https://' + event.event_domain + '.' + constants.DASHBOARD_URL;
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style lang="scss">
.header-roles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  &--event {
    .title {
      font-size: 1.8rem;
    }
  }
  &--info {
    .btn {
      font-size: 1.6rem;
    }
  }
}
.no-event {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  a {
   color: #ffffff;
    text-decoration: none;
    font-size: 3.2rem;
    background: #0671cfd9;
    border-radius: 80px;
    padding: 30px;
    text-shadow: 1px 2px 3px #000;
    box-shadow: 1px 2px 2px #000;
  }

  a:hover{
     background:#0b73cf8a;
  }
  .plus-icon {
    width: 70px;
    height: 70px;
    margin-left: 25px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: #016ecd;
    position: absolute;
    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: white;
    }

    &:before {
      width: 45px;
      height: 4px;
      background-color: white;
      top: 35px;
      left: 13px
    }

    &:after {
      width: 45px;
      height: 4px;
      background-color: white;
      top: 35px;
      left: 13px;
      transform: rotate(90deg);
    }
  }
}
.toggle.active {
  .event_main--info {
    a {
      color: white;
      border-color: white;
    }
  }
}
.event_main {
  .toggle > label i.fa-minus,
  .toggle > label i.fa-plus {
    display: none;
  }
  .toggle .toggle-content {
    padding: 0;
  }
  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
    span {
      font-weight: bold;
    }
  }

  &--title {
    display: inline-block;
    margin: 0;
    font-size: 22px;
    padding: 6px 0;
  }
  &--info {
    display: flex;
    justify-content: right;
    align-items: center;

    a.btn {
      font-size: 1.6rem;
      padding: 0 3px;
      line-height: 1;
      margin-left: 10px;
      i {
        margin: 0;
      }
    }
  }
  &--wrapper {
    min-height: 520px;
    padding: 25px 0;
    background: url("~@/../public/assets/images/layout/Event_List_Detail_BG_Image.jpg") no-repeat center center / cover;

    .event_main--row {
      align-items: center;
    }
    .title {
      h2 {
        font-size: 3.4rem;
        color: #0a6aa1;
      }
    }
    .sceduled-time {
      border-top: 1px solid #0a6aa1;
      border-bottom: 1px solid #0a6aa1;
      padding: 10px 0;
      display: inline-block;
      margin: 10px 0 23px;
      font-size: 1.7rem;
    }
    .speakers {
      &--title {
        font-size: 1.7rem;
        color: #0a6aa1;
      }
    }
    .options {
      a {
        margin: 5px 8px;
      }
    }
  }

  .search_bar {
    margin-bottom: 20px;
    .input-group-text {
      border-radius: 1.25rem;
    }
  }
}
</style>