import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function isPermits(isPermits, permissions) {
    let isExists = _.indexOf(permissions, isPermits);
    if(isExists ==  -1){
        return false;
    }
    return true;
    //return permissions.includes(isPermits);
}



export {isPermits};