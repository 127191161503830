<template>
    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                         </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#page-content`" :href="`#page-content`" data-bs-toggle="tab">Page Contents
                    
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div :id="`general-settings`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">

                <div class="form-group row">

                    <div class="col-lg-6">
                        <label>Page Name *</label>
                        <input type="text" name="page_name" class="form-control"  placeholder="Enter Page Name" label="label" :value="item.page_name" @input="updatePageName" />
                            
                    </div>
                    <div class="col-lg-6">
                        <label>Page Type *</label>
                        <select class="form-control" @change="updatePageType">
                                <option value="" selected>Select Page Type</option>
                                <option v-for="option in pageTypesEnum" :value="option.value" :key="option.value" :selected="(item.page_type == option.value)">
                                    {{ option.label }}
                                </option>
                        </select>
                    </div>
                    

                </div>

            <div class="form-group row">

                <div class="col-lg-6">
                    <label>Language</label>
                        
                    <select class="form-control" @change="updateLaguage">
                        <option value="" selected>Select Language</option>
                        <option v-for="option in languages" :value="option" :key="option" :selected="(item.language == option)">
                                {{ option }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6">
                    <label>Sorting Order </label>
                    <input type="number" name="sortingOrder" min="0" class="form-control"  placeholder="Enter Sorting Order" label="label" :value="item.sorting_order" @input="updateSortingOrder" />

                </div>


            </div>

            <div class="form-group row">

                <div class="col-lg-6">
                    <label>Visible</label>
                        
                    <div class="switch switch-sm switch-primary">
                        <input-switch name="is_visible" :value="item.is_visible" id="customSwitchesEx" class="custom-control-input" @change="updateIsVisible" />
                    </div>
                    
                </div>
                <div class="col-lg-6">
                    <label>Status </label>
                    <input-switch name="status" :value="item.status" id="customSwitchesEx" class="custom-control-input" @change="updateStatus" />
                
                </div>
            </div>
            </div>

            <div class="form-group row">
            <div class=" text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>

            </div>
            </div>
            </div><!-- End of General Settings -->
          
        
            <div :id="`page-content`" class="tab-pane">
                <PageContentSection :item="item" :pageId="item._id" />
            </div>
        </div>
    
    </div>
    <FlashMessage group="create_page_edit"/>
    
    </template>
    
    <script>
    import $ from 'jquery';
    
    import { gettingErrorMessage } from "@/services/GetValidationMessage";
    import PageContentSection  from "@/components/cruds/EventPages/PageContent/Index"
    import InputSwitch from "@/components/Commons/InputSwitch";

    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import _ from "lodash";
    import moment from 'moment';
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    import ObjectID from 'bson-objectid';

    export default {
        data() {
            return {
                moduleId: ObjectID(),
                dialog: false,
            }
            
        },
       
      setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {
                item: {
                   
                   page_name: {
                       required
                   },
                   page_type:{
                       required
                   }
                   
                  
               }
            };
        },
    
        props: {
            event:null,
            languages: {
                type: Array,
            }
    
        },
        watch: {
        
        },
        name: 'PageEdit',
        components: {
            InputSwitch,
            PageContentSection
        },
       
        computed: {
            ...mapGetters('EventPagesSingle', ['pageTypesEnum','error_general_tab','typesEnum','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading',]),
            ...mapGetters('EventPagesIndex', []),
        },
        beforeUnmount() {
            this.resetState()
            delete this.$flashMessage.groups.create_page_edit;
           // delete this.$flashMessage.groups.event_pages_index;
            
        },
        created(){
        },
        mounted() {
            this.setEvent(this.$route.params.event_id);
            const switchers = document.querySelectorAll('.general-event-config-snippet input[data-plugin-ios-switch]')
            switchers.forEach(item => {
                const elem = new window.theme.PluginIOS7Switch($(item))
            })
        },
        methods: {
            ...mapActions('EventPagesIndex', {
                fetchAllData: 'fetchData',
            }),
            ...mapActions('EventPagesSingle', [
                'updateData',
                'resetState',
                'storeData',
                'setPageName',
                'setSort_order',
                'setVisibile',
                'setStatus',
                'setEvent',
                'setPageType',
                'setLanguage',
                'setIsNew'
            ]),
            updatePageName(e) {
                this.setPageName(e.target.value);
            },
            updatePageType(e) {  
                this.setPageType(e.target.value)
            },
            updateLaguage(e) {
                this.setLanguage(e.target.value)
            },
            updateSortingOrder(e){
                this.setSort_order(e.target.value)
            },
            updateIsVisible(e){
                this.setVisibile(e.target.checked);
            },
            updateStatus(e){
                this.setStatus(e.target.checked);
            },
            cancelClick() {
                this.resetState()
            },    
            async  submitForm() {
                const isFormCorrect = await this.v$.$validate();
                let generalError = []; 
                _.map(this.v$.$errors, (item) => {
                    if(item.$property == "page_name"){
                        generalError.push("<li>Page Name is required</li>");
                    }
                    if(item.$property == "page_type"){
                        generalError.push("<li>Page Type is required</li>");
                    }
                });
                if (!isFormCorrect ) {
                    if (!_.isEmpty(generalError)) {
                        this.$flashMessage.show({
                            group   : 'create_page_edit',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul>${generalError.join("")}</ul>`
                        });
                        return ;
                    }
                }
               
                this.updateData()
                    .then(async (resp) => {
                        await  this.fetchAllData(this.$route.params.event_id);
                        this.$flashMessage
                            .show({
                                    group   : 'event_pages_index',
                                    type    : 'success',
                                    title   : 'Success',
                                    time    : 1000,
                                    html    : '<ul><li>Event Page updated successfully.</li></ul>',
                                });
                    }) 
                    .catch(error => {
                        console.log("Error",error);
                        let html =  gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'create_page_edit',
                                type: 'error',
                                title: 'Failed',
                                time:1000,
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                    });
            },
            
           
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .event_detail_wrapper {
        border-bottom: 0px solid #ccc;
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    }
    
    .setting_wrapper {
        float: right;
    
        a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
        }
    }
    
    .toggle label {
        color: #000 !important;
    }
    
    .drop-files {
        margin-top: 10px;
    }
    
    .newspeaker {
        margin-top: 20px;
    }
    
    .newspeaker button {
        width: 100%;
    }
    
    .add_spk_btn {
        background-color: #0088CC;
        color: #fff;
    }
    
    .mini_txt {
        font-size: 0.8em;
        color: #585252;
        font-style: italic;
        margin-bottom: 10px;
    }
    
    .v-dialog>.v-overlay__content>.v-card {
        border-radius: 15px;
        padding-top: 20px;
    }
    
    .tabs-left .nav-tabs>li .active {
        border-left-color: #0088CC;
    }
    
    .orangebtn {
        background-color: orange;
    }
    
    .wrapp {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    .flash-content {
        z-index: 10;
    }
    
    .dark{
      --popper-theme-text-color: #ffffff;
      --popper-theme-padding: 15px;
      --popper-theme-background-color: #333333;
      --popper-theme-border-radius: 6px;
      --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .tooltip_img img{    
        position: absolute;
        top: -53px;
        left: 50px;
        width: 300px;
        height: auto;
    }
    
    
    </style>
    