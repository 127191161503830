<template>
    <h3> Exhibition Area/ Booth `s Analytics & Reports <i class="btn bx bx-file"  @click="exportFile">Download Report </i></h3>
                
    <div class = "row" id="graph" >
        <div class="col-md-12">    
             <Bar v-if="loaded" :options="chartOptions" :data="chartDataMain"> </Bar>
        </div>
        
    </div>
    
    <br/>
    <div id="tab" class="tabs tabs-vertical tabs-left">
        <TabOne></TabOne> 
    </div><!--End Of Tab-->

</template>

<script>

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


import {
mapGetters,
mapActions
} from 'vuex'
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import _ from "lodash";
import BackButton from "@/components/Commons/BackButton";

import { isPermits} from "@/services/CheckPermissions";
import headerForm from "@/components/cruds/EventAnalytics/Forms/Header";
import TabOne from "@/components/cruds/EventAnalytics/Forms/tabOne";
import { ref } from "vue";
import { utils, writeFileXLSX } from 'xlsx';

export default {
    name: 'LpIndex',
    components: {
        TabOne,
        Bar
    },
data() {
return {
    search: null,
    perPage: 5,
    system_users: [],
   }
},
watch: {
},
created() {
    // this.fetchPermissions()
    let postData = {event_id:this.$route.params.event_id,event_type:"Exhibition-Area"}
    console.log("Post Data",postData);
    this.onChangefetchData(postData)
    .catch(() => {
        this.$flashMessage.show({
            group: 'event_assets_index',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
        });
    })

},
beforeUnmount() {
//this.resetState();
//delete this.$flashMessage.groups.event_assets_index;

},
computed: {
    ...mapGetters('EventAnalyticsIndex', ['loading', 'all', 'headers','enumModules', "chartDataMain",'loaded','chartQa','chartPolling','chartRating','chartChat']),
},
methods: {
    ...mapActions('EventAnalyticsIndex', ['onChangefetchData','fetchData','resetState','generateSheet']),
createClick() {
    this.setIsNew(true);
    this.setIsEditing(false);
},
cancelClick() {
    this.resetState();
},
perRowChange(e) {
    this.perPage = Number(e.target.value);
},

async exportFile() {
    let sheet_session   = [];
    let postData = {eventId :this.$route.params.event_id, event_type:'Exhibition-Area'}
    let response = await this.generateSheet(postData);
    if(response.data.data){
        sheet_session       = response.data.data.session_analytics;
  
    }
    /* generate worksheet from state */
     const ws = utils.json_to_sheet(sheet_session);
  
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Booth Session Analytics");
        /* export to XLSX */
    writeFileXLSX(wb, "ExhibitionArea_Analytics.xlsx");
}



}
}
</script>

<style lang="scss" scoped>
.header-template {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;

.title {
font-size: 1.6rem;
}
}

.user-container {
.toggle label {
color: #000 !important;
border-radius: 0px;

}

.toggle.active>label {
color: #fff !important;
}

.toggle section {
// box-shadow: -1px 0px 5px 2px #ccc;
border-bottom: 1px solid #ccc;
}

.toggle-content {
padding: 35px;

}

.form-control {
font-family: 'Poppins';
}

.desc_btn_wrapper {
margin-top: 20px;
}

.snippet {
border-radius: 0.2rem;
border: 1px solid #c9c9c9;
padding: 24px 22px;
margin: 15px 0;
background-color: white;
}

.snippet .btn-light {
color: #000 !important;
}

.edit_panel {
background: #d5e3eb;
padding: 15px;
margin-top: 10px;
}

.snippet {
margin-top: 0px;
}

.note-editor .btn-light {
color: #000 !important;
}

.setting_wrapper {
float: right;
}

.setting_wrapper a {
color: #201e1eba;
padding: 9px;
margin-left: 5px;
background: #c0cdd345;
border-radius: 4px;
border: 1px solid #80808054;

}

.labelwrapper {
width: 100%;
}

.labelhdwrapper {
width: 100%;
margin-left: 0px;
border: 1px solid #ccc;
padding: 10px;
background: #e1e1e1;
}

.labelhdwrapper span {
font-weight: bold;
}

.col1 {
width: 25%;
display: inline-flex;
}

.col2 {
width: 25%;
display: inline-flex;
}

.col3 {
width: 25%;
display: inline-flex;
}

.col4 {
width: 20%;
display: inline-flex;
}

.search_bar {
margin-bottom: 20px;
}

.eventlabel {
font-size: 1.3em;
font-weight: bold;
margin-bottom: 10px;
}

.event_item {
color: #fff;
padding: 8px 15px;
border-radius: 8px;
background: #afbcc3;
min-width: 50px;
display: inline-flex;
margin: 5px;
}

.nav-link.active {
border-top-color: #0088CC;
}

.toggle.active>label {
background: #0a72e0;
}

.nav-tabs li.active a {
color: #fff !important;
background: #0a72e0 !important;
font-size: 1.2em;
}

.nav-tabs li a {
font-size: 1.2em;
color: #333;
}

.toggle>label {
background: none !important;
font-size: 1em !important;
}

.toggle .toggle {
margin: 0px;
}

.toggle {
margin: 0px;
}

.toggle_odd {
background: #f5f5f5;
}

.btn-primary {
color: #fff !important;
background-color: #0c78e0 !important;

}

.nav-tabs li .nav-link,
.nav-tabs li .nav-link:hover {
background: #cccccc94 !important;
}

.pageview {
font-family: 'Poppins';
font-size: 1em !important;
}

.w-70 {
width: 30%;
display: inline-flex;
}

.rpg_txt {
display: inline-block;
margin-left: 10px;
}

.nav-tabs li .nav-link,
.nav-tabs li {
border-right: 1px solid #EEE;
}

.toggle_odd {
.event_detail_wrapper {
    .row {
        .col-md-8 {
            border-bottom: 0px dotted #ccc;
            padding-bottom: 5px;
        }
    }
}
}

.text-right {
text-align: right !important;
margin-right: 100px;
background: none;
}

.toggle_odd {
.text-right {
    margin-right: 120px;
}
}

.btn-info {
background: #6c757d !important;
}

.profile_info {
text-align: center;
margin-top: 40px;
}

.card-box {
background: #cccccc4d;
border-radius: 10px;
padding: 30px;
min-height: 300px;
font-size: 1.1em;

h2 {
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
}
}
}
</style>
