import _ from "lodash"
import axios from "axios";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            language: null,
            language_logo:null
        },


        loading: false,
        is_new: false,
        is_editing: false,
  
    }
}

const getters = {
    item        : state => state.item,
    loading     : state => state.loading,
    is_new      : state => state.is_new,
    is_editing  : state => state.is_editing,
   
}

const actions = {
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.clone(state.item)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        if (params.language_logo) {
            params.language_logo = {
                key         : params.language_logo.key,
                old_key     : params.language_logo.key,
                temp_key    : params.language_logo.temp_key,
                size        : params.language_logo.size,
                extension   : params.language_logo.extension,
                uploadedBy  : params.language_logo.uploadedBy,
                uploadedAt  : params.language_logo.uploadedAt,
            }
        }
        return axios.post(constants.API_URL + '/api/language', params, {headers: headers})
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch },id) {
        commit('setLoading', true)
        const params = _.clone(state.item)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        delete params.translates;
        delete params.is_deleted;
        delete params.createdAt;
        delete params.status;
        delete params.updatedAt;
        delete params._id;
        delete params.__v;
        delete params.email_text;

        if (params.language_logo) {
            params.language_logo = {
                key: params.language_logo.key,
                old_key: params.language_logo.key,
                size: params.language_logo.size,
                extension: params.language_logo.extension,
                uploadedBy: params.language_logo.uploadedBy,
                uploadedAt: params.language_logo.uploadedAt,
            }
        }
        return  axios.patch(constants.API_URL + '/api/language/' + id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data);
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/language/' + id, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data);
                return response;
            })
    },

    setFlag({ commit }, value) {
        commit('setFlag', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setLanguage({commit},value){
        commit('setLanguage', value);
    },
    setLanguageLogo({commit},value){
        commit('setLanguageLogo', value);
    },
    setItem({commit},value){
        commit('setItem', value);
    }

   
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setLanguage(state, value) {
        state.item.language = value
    },
    setLanguageLogo(state,value){
        state.item.language_logo = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
