<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="row">
      <div class="col-md-12 event_detail_ls">



        <div class="form-group row">  
         
            <div class="col-md-6">
              <label for="company_name">Preset For *</label>
              <select name = "title" class="form-control" @change="updatePresetType" placeholder="Select preset event type *">
                                <option disabled selected>Select</option>
                                <option v-for="option in eventTypes" :value="option.id" :key="option.id"
                                    :selected="(item.preset_type == option.id)">
                                    {{ option.title }}
                                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label for="preset_name">Preset Name *</label>
              <input type="text" class="form-control" name="name" placeholder="Enter Preset Name *"
                :value="item.name" @input="updateName" />
            </div>          
          
        </div>


        <div class="form-group row">

          <div class="col-md-3">
            <label for="no_of_videos">Number of videos *</label>
            <input type="number" min="0"  max="2" class="form-control" name="no_of_videos" placeholder="Enter Number of Videos"
                :value="item.no_of_videos" @input="updateNoOfVideos" />
          </div>

          <div class="col-md-3">
            <label for="no_of_presentations">Number of presentations *</label>
            <input type="number" min="0" max="2" class="form-control" name="no_of_presentations" placeholder="Enter Number of Presentations"
                :value="item.no_of_presentations" @input="updateNoOfPresentations" />
          </div>

          <div class="col-md-3">
            <label for="no_of_documents">Number of documents *</label>
            <input type="number" min="0" max="10" class="form-control" name="no_of_documents" placeholder="Enter Number of Documents"
                :value="item.no_of_documents" @input="updateNoOfDocuments" />
          </div>

          <div class="col-md-3">
            <label for="no_of_logos">Number of logos *</label>
            <input type="number" min="0" max="6" class="form-control" name="no_of_logos" placeholder="Enter Number of Logos"
                :value="item.no_of_logos" @input="updateNoOfLogos" />
          </div>


        </div>



        <div class="form-group row">
                  <div class="col-md-6">
                        <label for="preset_image">Preset Image*</label>
                        <DropFiles :file="item.preset_image"
                                 :module_id="this.moduleId"
                                 @onUploaded="updatePresetImage"
                                 @onRemoved="removePresetImage"
                                 @onError="errCb"
                                 :accept="'.png'"
                                 :module_type="'events'"
                                 :asset_type="'booth_presets'">
                        <template #header>
                          <p>Booth Preset Picture(Png in format allowed)</p>
                        </template>
                      </DropFiles>
                    </div>


                    <div class="col-md-6">
                      <label for="preset_image">Preset Image(SVG)*</label>
                      <DropFiles :file="item.preset_image_svg"
                                 :module_id="this.moduleId"
                                 @onUploaded="updatePresetImageSVG"
                                 @onRemoved="removePresetImageSVG"
                                 @onError="errCb"
                                 :accept="'.svg'"
                                 :module_type="'events'"
                                 :asset_type="'booth_presets'">
                        <template #header>
                          <p>Booth Preset Picture(Svg in format allowed)</p>
                        </template>
                      </DropFiles>
                    </div>

          </div>



        

      </div>
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancelClick">Cancel</button>
          
          </div>
        </div>
      </div>
    </div>
    <FlashMessage group="bothpresets_create" />
  </form>
</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters, mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import _ from "lodash";
import { upload, remove } from "@/services/FileUploader";
import ObjectID from 'bson-objectid';

export default {
  name: 'BoothPresetsEditForm',
  components: {},
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
      return {
        selectValue:"",
        moduleId: ObjectID().toHexString()
      }
    },
  validations() {
    return {
      item: {
        preset_type         : {required},
        name                : {required},
        no_of_videos        : {required},
        no_of_presentations : {required},
        no_of_documents     : {required},
        no_of_logos         : {required},
        preset_image        : {required},
        preset_image_svg    : {required}
      }
    };
  },
  computed: {
    ...mapGetters('BoothPresetsSingle', ['item', 'loading', 'is_new','eventTypes']),
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.bothpresets_create;
  },
  methods: {
    ...mapActions('BoothPresetsIndex', ['fetchData']),
    ...mapActions('BoothPresetsSingle', [
      'storeData',
      'resetState',
      'setPresetType',
      'setName',
      'setNoOfVideos',
      'setNoOfPresentations',
      'setNoOfDocuments',
      'setNoOfLogos',
      'setPresetImage',
      'setIsNew',
      'setPresetImageSVG'

    ]),
    resetPresetType(e){
      this.setPresetType(null);
    },
    updateName(e) {
      this.setName(e.target.value);
    },
    updatePresetType(e) {
      this.setPresetType(e.target.value);
    },
    updateNoOfVideos(e){
      this.setNoOfVideos(e.target.value);
    },
    updateNoOfPresentations(e){
      this.setNoOfPresentations(e.target.value);
    },
    updateNoOfDocuments(e){
      this.setNoOfDocuments(e.target.value);
    },
    updateNoOfLogos(e){
      this.setNoOfLogos(e.target.value);
    },
    removePresetImage(e, id) {
        this.setPresetImage(null);
    },

    removePresetImageSVG(e, id) {
        this.setPresetImageSVG(null);
    },
    async updatePresetImageSVG(info, data) {
    if (!_.has(data.data, 'key')) {
              return;
          }
          const extension = data.data.key.split('.').pop();
          const key = _.get(this.item.preset_image_svg, 'key', null);
          const size = _.get(info, 'file.size', 0);
          this.setPresetImageSVG({
              old_key   : key,
              key       : data.data.key,
              temp_key  : info.id,
              size      : size.toString(),
              extension : extension,
              uploadedBy: info.user.first_name + ' ' + info.user.last_name,
              uploadedAt: '2026.01.01',
          });      
  },
  async updatePresetImage(info, data) {
    if (!_.has(data.data, 'key')) {
              return;
          }
          const extension = data.data.key.split('.').pop();
          const key = _.get(this.item.preset_image, 'key', null);
          const size = _.get(info, 'file.size', 0);
          this.setPresetImage({
              old_key   : key,
              key       : data.data.key,
              temp_key  : info.id,
              size      : size.toString(),
              extension : extension,
              uploadedBy: info.user.first_name + ' ' + info.user.last_name,
              uploadedAt: '2026.01.01',
          });      
  },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect){
        let clientHtml = "<ul>";
        for(let error of this.v$.item.$errors){
            clientHtml+="<li> The "+ error.$property +" - "+ error.$message+"</li>";
        } 
        clientHtml+="</ul>";
        if(this.v$.item.$errors){
            this.$flashMessage.show({group: 'bothpresets_create',type: 'error',title: 'Form Validations Errors',html: clientHtml});
      
        }
        return false;
      }
      //if (!isFormCorrect) return
      this.storeData()
        .then(() => {
          this.setIsNew(false);
          this.fetchData();
          this.$flashMessage.show({
          group : 'booth_presets',
          type  : 'success',
          title : 'Success!',
          html  : '<ul><li>Presets has been created successfully!</li></ul>',
         
        })

        })
        .catch((error) => {
          let html =  gettingErrorMessage(error);
          this.$flashMessage.show({
                group : 'bothpresets_create',
                type  : 'error',
                title : 'Error',
                text  : html,
                html  : html
            });
        });
    },
    errCb(e) {
      this.$flashMessage.show({
          group : 'bothpresets_create',
          type  : 'error',
          title : 'Failed',
          html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
        });
    },
    cancelClick() {
      this.resetState();
    }
  }
}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
}

.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>
