import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"

function initialState() {
    return {
        item: {
            _id         :   null,
            start_time  :   null,
            end_time    :   null,
            attendees   :   [],
        },
        new_room_session: {
            start_time  : "",
            end_time    : "",
            attendees   : [],
        },
        
        meetingroomsAll : [],
        event_timezone  : null,
        roomData        : {},
        loading         : false,
        is_new          : false,
        is_editing      : false,
        room_day        : null,
        is_new_room_session:true
    }
}

const getters = {
    item                        : state => state.item,
    meetingroomsAll             : state => state.meetingroomsAll,
    event_timezone              : state => state.event_timezone,
    eventUsersAll               : state => state.eventUsersAll,
    roomData                    : state => state.roomData,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
    loading                     : state => state.loading,
    new_room_session            : state => state.new_room_session,
    event_id                    : state => state.event_id
   

}

const actions = {
    destroyData({ commit, state ,dispatch},postData){
        commit('setLoading', true);
     
        let meetingId = postData.meeting_id;
        let roomSessionId = postData.room_session_id;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/meetings/room_session/'+meetingId+'/'+roomSessionId, {headers: headers})
        .then((response) => {
            commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    async fetchRoomSession({ commit, state ,dispatch}, postData){
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        let meetingId = postData.room_id;
        let roomSessionId = postData.room_session_id;
        return axios.get(constants.API_URL + '/api/meetings/room_session/'+meetingId+'/'+roomSessionId, {headers: headers})
        .then(response => {
           
            commit('setItem', response.data.data[0]);
            commit('setLocalTime', 'start_time');
            commit('setLocalTime', 'end_time');
        
            
        })
        .catch(error => {
            console.log("Error",error);
        })

    },
   
   async getTimeZone({ commit, state, dispatch }, event_id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/events/event-id/'+event_id, {headers: headers})
        .then(response => {
            console.log("response.data.data.time_zone",response.data.data.time_zone)
          //  state.event_timezone = response.data.data.time_zone;
            commit('setEventTimezone',response.data.data.time_zone);
            moment.tz.setDefault(response.data.data.time_zone);
            
        })
        .catch(error => {
            console.log("Error",error);
        })
       

    },
    createNewRoom({ commit, state, dispatch },meetingId) {

        commit('setLoading', true);
        const params = _.cloneDeep(state.new_room_session);
        commit('setUTCTimeNew', 'start_time');
        commit('setUTCTimeNew', 'end_time');
        params.start_time =  state.new_room_session.start_time;
        params.end_time =   state.new_room_session.end_time;

        
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/meetings/room_session/'+meetingId, params, {headers: headers})
        .then((response) => {
           commit('resetState')
          // dispatch('fetchAttendeeList',this.state.event_id)
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    storeData({ commit, state, dispatch },postData) {

        commit('setLoading', true);
        console.log("postDatapostData",postData);
        const params = _.cloneDeep(state.item);
     
        let meetingId = postData.meeting_id;
        let roomSessionId = postData.room_session_id;
        commit('setUTCTime', 'start_time');
        commit('setUTCTime', 'end_time');
        params.start_time =  state.item.start_time;
        params.end_time =   state.item.end_time;

        
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/meetings/room_session/'+meetingId+'/'+roomSessionId, params, {headers: headers})
        .then((response) => {
            commit('resetState')
           return response;
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        dispatch('Alert/resetState', null, { root: true })

        return new Promise((resolve, reject) => {
            let params = new FormData();
            params.set('_method', 'PUT')

            for (let fieldName in state.item) {
                let fieldValue = state.item[fieldName];
                if (typeof fieldValue !== 'object') {
                    params.set(fieldName, fieldValue);
                } else {
                    if (fieldValue && typeof fieldValue[0] !== 'object') {
                        params.set(fieldName, fieldValue);
                    } else {
                        for (let index in fieldValue) {
                            params.set(fieldName + '[' + index + ']', fieldValue[index]);
                        }
                    }
                }
            }

            if (_.isEmpty(state.item.room)) {
                params.set('room_id', '')
            } else {
                params.set('room_id', state.item.room.id)
            }

            if (_.isEmpty(state.item.attendee)) {
                params.set('attendee_id', '')
            } else {
                params.set('attendee_id', state.item.attendee.id)
            }

            if (_.isEmpty(state.item.attendees)) {
                params.delete('attendees')
            } else {
                for (let index in state.item.attendees) {
                    params.set('attendees[' + index + ']', state.item.attendees[index].id)
                }
            }
            if (_.isEmpty(state.item.hosts)) {
                params.delete('hosts')
            } else {
                for (let index in state.item.hosts) {
                    params.set('hosts[' + index + ']', state.item.hosts[index].event_users_id)
                }
            }
            commit('setUTCTime', 'start_time');
            commit('setUTCTime', 'end_time');

            params.set('start_time', state.item.start_time)
            params.set('end_time', state.item.end_time)

            axios.post('/api/v1/room-sessions/' + state.item.id, params)
                .then(response => {
                    commit('setItem', response.data.data)
                    resolve()
                })
                .catch(error => {
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        'Alert/setAlert',
                        { message: message, errors: errors, color: 'danger' },
                        { root: true })

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', false)
                })
        })
    },

    
    fetchData({ state, commit, dispatch }, params) {
        commit('setLoading', true)
        axios.get('/api/v1/room-sessions/' + params.id)
            .then(response => {
                state.event_timezone = response.data.data.room.event.time_zone
                moment.tz.setDefault(state.event_timezone);
                let sessionData = response.data.data
                //agendaData.map((obj, i) => {
                if (sessionData.attendee) {
                    sessionData.attendee.first_name = sessionData.attendee.first_name + " " + sessionData.attendee.last_name;
                }



                if (sessionData.attendees) {
                    sessionData.attendees.map((obj, i) => {
                        sessionData.attendees[i].first_name = obj.first_name + " " + obj.last_name;
                    })
                    //agendaData.attendees.first_name = agendaData.attendees.first_name + " " + agendaData.attendees.last_name;
                }
                if (sessionData.hosts) {
                    sessionData.hosts.map((obj, i) => {
                        sessionData.hosts[i].first_name = obj.first_name + " " + obj.last_name;
                        sessionData.hosts[i].event_users_id = obj.id;
                    })

                }

                if (sessionData.room.hosts) {
                    let roomHosts = sessionData.room.hosts
                    roomHosts.map((obj, i) => {
                        roomHosts[i].first_name = obj.user.first_name + " " + obj.user.last_name;
                    })

                    sessionData.room.hosts = roomHosts
                }


                //})
                commit('setItem', sessionData)
                commit('setLocalTime', 'start_time')
                commit('setLocalTime', 'end_time')
                //commit('setItem', response.data.data)
            })

        //dispatch('fetchMeetingroomsAll', params.event_id)
        dispatch('fetchEventUsersAll', params.event_id)
        commit('setLoading', false)
    },
    fetchRoomData({ state, commit, dispatch }, params) {
        axios.get('/api/v1/meeting-rooms/' + params.room_id)
            .then(response => {
                let roomData = response.data.data


                if (roomData.hosts) {
                    let roomHosts = roomData.hosts
                    roomHosts.map((obj, i) => {
                        roomHosts[i].first_name = obj.user.first_name + " " + obj.user.last_name;
                    })
                    roomData.hosts = roomHosts

                }

                commit('setRoomData', roomData)
            })
        //dispatch('fetchEventUsersAll', params.event_id)
        //commit('setLoading', false)
    },
    fetchMeetingroomsAll({ commit }, room_id) {
        commit('setRoomID', room_id)
    },
    fetchEventUsersAll({ commit }, event_id) {
        //debugger;
        axios.get('/api/v1/getEventUsers/' + event_id)
            .then(response => {

                let eventUsers = response.data.data
                //debugger;
                eventUsers.map((obj, i) => {
                    eventUsers[i].first_name = obj.first_name + " " + obj.last_name;
                })

                commit('setEventUsersAll', eventUsers)
            })
    },
    setAttendee({ commit }, value) {
        commit('setAttendee', value)
    },
    setAttendees({ commit }, value) {
        commit('setAttendees', value)
    },
    setAttendeesNew({ commit }, value) {
        commit('setAttendeesNew', value)
    },
    setHosts({ commit }, value) {
        commit('setHosts', value)
    },
    setRoom({ commit }, value) {
        commit('setRoom', value)
    },
    setStart_time({ commit }, value) {
        commit('setStart_time', value)
    },
    setEnd_time({ commit }, value) {
        commit('setEnd_time', value)
    },

    setStart_time_new({ commit }, value) {
        commit('setStart_time_new', value)
    },
    setEnd_time_new({ commit }, value) {
        commit('setEnd_time_new', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setAttendeeList({ commit }, value) {
        commit('setAttendeeList', value)
    },
    setRoomDay({commit},value){
        commit('setRoomDay',value)
    },
    setSession({commit},value){
        commit('setSession',value)
    },
    setItem({commit},value){
        console.log("vaa",value);
        commit('setItem',value)
    },
    setEventTimezone({commit},value){
        console.log("setEventTimezone",value);
        commit('setEventTimezone',value)
    },
    setEventId({commit},value){
        console.log("eventid",value);
        commit('setEventId',value)
    }
    
}

const mutations = {
    setEventTimezone(state,value){
        state.event_timezone = value
    },
    setItem(state, value) {
        state.item =  value;
        console.log("stateitem",state.item);
   
    },
    setRoom(state, value) {
        state.item.room = value
    },
    setRoomID(state, value) {
        state.item.room_id = value
    },
    setRoomData(state, value) {
        state.roomData = value
    },
    setStart_time(state, value) {
        state.item.start_time = value
    },
    setEnd_time(state, value) {
        state.item.end_time = value
    },
    setStart_time_new(state, value) {
        state.new_room_session.start_time = value
    },
    setEnd_time_new(state, value) {
        state.new_room_session.end_time = value
    },
    setAttendee(state, value) {
        state.item.attendee = value
    },
    setAttendees(state, value) {
        state.item.attendees = value
    },
    setAttendeesNew(state, value) {
        state.new_room_session.attendees = value
    },
    setHosts(state, value) {
        state.item.hosts = value
    },
    setMeetingroomsAll(state, value) {
        state.meetingroomsAll = value
    },
    setEventUsersAll(state, value) {
        state.eventUsersAll = value
    },
    setLocalTime(state, type) {
        console.log("state.event_timezone",state.event_timezone)
        if (type == 'start_time') {
            var value = state.item.start_time;
        }
        if (type == 'end_time') {
            var value = state.item.end_time;
        }
        var date = moment.utc(value).tz(state.event_timezone).format('YYYY-MM-DD HH:mm');
        var formatedDate = moment((date)).format('YYYY-MM-DD HH:mm')
        if (type == 'start_time') {
            state.item.start_time = formatedDate;
        }
        if (type == 'end_time') {
            state.item.end_time = formatedDate;
        }
    },
    setUTCTime(state, type) {
        if (type == 'start_time') {
            var start_time = state.item.start_time
            console.log("Start Time",start_time);
            var localTime   = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
            var utcTime     = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm');
        }
        if (type == 'end_time') {
            var end_time    = state.item.end_time
            var localTime   = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
            var utcTime     = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm');
        }

        if (type == 'start_time') {
            console.log(state.item.start_time);
            state.item.start_time = utcTime;
            console.log(state.item.start_time);
        }
        if (type == 'end_time') {
            state.item.end_time = utcTime;
        }
    },
    setUTCTimeNew(state, type) {
        if (type == 'start_time') {
            var start_time = state.new_room_session.start_time
            console.log("Start Time",start_time);
            var localTime   = moment.tz(start_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
            var utcTime     = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm');
        }
        if (type == 'end_time') {
            var end_time    = state.new_room_session.end_time
            var localTime   = moment.tz(end_time, state.event_timezone).format('YYYY-MM-DD HH:mm');
            var utcTime     = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm');
        }

        if (type == 'start_time') {
            state.new_room_session.start_time = utcTime;
        }
        if (type == 'end_time') {
            state.new_room_session.end_time = utcTime;
        }
    },

    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setAttendeeList(state,value){
        state.attendeeList = value;
    },
    setRoomDay(state,value){
        state.room_day = value;
    },
    setSession(state, value) {
        state.session =  _.cloneDeep(value);
    },
    setEventId(state,value){
        state.event_id = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
