import axios from "axios";
import constants from "@/config/constants";

function initialState() {
    return {
        all             :   [],
        accessLevelRole :   [],
        loading         :   false,
        relationships: {
            'permission': 'title',
        },
    }
}

const getters = {
    all             :   state => state.all,
    accessLevelRole :   state=>state.accessLevelRole,
    loading         :   state => state.loading,
    relationships   :   state => state.relationships
}

const actions = {

    fetchDataByAccessLevel({commit},access_level){
        commit('setLoading', true)

        return axios.get(constants.API_AUTH_URL + '/api/v1/roles/access-level/'+access_level)
            .then(response => {
                commit('setAccessLevelRole', response.data.data)
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })

    },
    fetchData({ commit }) {
       // commit('setLoading', true)

        return axios.get(constants.API_AUTH_URL + '/api/v1/roles')
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        return axios.delete(constants.API_AUTH_URL + '/api/v1/roles/' + id)
            .then(() => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },

    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAccessLevelRole(state,items){
        state.accessLevelRole = items
    },
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        // eslint-disable-next-line no-unused-vars
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
