import timeZoness from 'countries-and-timezones'
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        all: [],
        all_participants: [],
        relationships: {
            'role': 'title',
            'company': 'company_name',
        },
        loading: false,
        type: 'admin',
        countriesAll: [],
        timezonesAll: [],
    }
}

const getters = {
    all: state => state.all,
    total: state => state.all.length,
    loading: state => state.loading,
    relationships: state => state.relationships,
    type: state => state.type,
    countriesAll: state => state.countriesAll,
    timezonesAll: state => state.timezonesAll,
    participants: state => state.all_participants,
}

const actions = {
    fetchData({ commit, state }) {
        commit('setLoading', true)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        let systemUsers = axios.get(constants.API_AUTH_URL + '/api/v1/users', { headers: headers });
        let participants = axios.get(constants.API_URL + '/api/event-user/all-participants', { headers: headers });
        return Promise.all([systemUsers, participants])
            .then(response => {

                commit('setAll', response[0].data.data);
                commit('setAllParticipants', response[1].data.data);
                return response;
            })
            .catch(error => {
                console.log(error)
                return error;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        let url = constants.API_AUTH_URL + '/api/v1/users/' + id;
        if(state.type == "event-participant"){
            url = constants.API_URL + '/api/event-user/' + id ;
       
        }
        return axios.delete(url,{ headers: headers })
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                console.log(message)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setType({ commit }, type) {
        commit('setType', type)
    },
    fetchCountriesAll({ commit }) {


        const zones = timeZoness.getAllCountries();
        commit('setCountriesAll', zones)
    },
    fetchTimezonesAll({ commit }) {
        const zones = timeZoness.getAllTimezones();
        commit('setTimezonesAll', zones)
    },
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setType(state, type) {
        state.type = type;
    },
    setCountriesAll(state, value) {
        const countries = Object.values(value)
        countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
        state.countriesAll = countries
    },
    setTimezonesAll(state, value) {
        state.timezonesAll = Object.values(value)
    },
    setAllParticipants(state, items) {
        state.all_participants = items
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
