<template>
  <div class="validation-error" v-if="element && element.$invalid">
   <p v-for="(error, i) in element.$errors" :key="i" class="alert alert-danger">{{error.$message}}</p>
  </div>
</template>

<script>

export default {
  name: "ValidationMessages",
  props: {
    element: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="scss">
.validation-error {
  .alert {
   padding: 4px 10px;
    border-radius: 3px;
    margin-top: 3px;
    background: #b72f2fb8;
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px black;
    margin-bottom: 0px;
  }
}
</style>