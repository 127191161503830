<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">On-Demand</h1>
        <span  v-if="can('on_demand_create',checkPermssions)">
        <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create New</button>
        <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
        </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid on-demand-container">
        <div class="row">
          <div class="col-lg-12">
            <OverlayLoading :isLoading="loading" />
            <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="perPage" v-if="can('on_demand_view',checkPermssions)">
              <div class="row">
                <div class="col-md-12">
                  <div class="row search_bar">
                    <div class="col-md-2">
                      <div class="input-group w-70">
                        <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span class="ml-5 rpg_txt">records per page</span>
                    </div>
                    <div class="col-md-2 offset-md-8 ">
                      <div class="input-group">
                        <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                        <span class="input-group-text">
                          <i class="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelhdwrapper row">
                <span class="col1">Title</span>
                <span class="col2"></span>
                <span class="col3"></span>
                <span class="col4">Start/End Time</span>
              </div>
              <TogglePrimaryActive v-if="is_new">
                <template v-slot:default>
                  <CreateForm :event="event" :languages="languages"/>
                </template>
              </TogglePrimaryActive>
              <TogglePrimary v-for="(livePresentation, index) in props.all" v-bind:key="index">
                <template v-slot:label>
                  <span class="labelwrapper">
                      <span class="col1">{{ livePresentation.translations[0]['title'] }}</span>
                      <span class="col2"></span>
                      <span class="col3"></span>
                      <span class="col4">
                        <svg style="width: 6%; height: auto; margin-right: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> {{ getTimeFromDate(livePresentation.start_time)}} - {{ getTimeFromDate(livePresentation.end_time)}}
                     </span>          
                  </span>
                </template>
                <template v-slot:default>
                      <EditFrom :id="livePresentation._id" v-if="is_new === false && is_editing === true && livePresentation._id === item._id" :event="event" :languages="languages"/>
                      <ShowItem :event="event" :presentation="livePresentation" :id="livePresentation._id"  :speaker="loadSpeaker(livePresentation.speaker)"  :checkPermssions="checkPermssions" v-else />
                </template>
              </TogglePrimary>
            </SearchableContainer>
          </div>
        </div>
        <FlashMessage group="on_demand" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import _ from "lodash";
import moment from 'moment';
import ShowItem from "@/components/cruds/OnDemand/Forms/Show";
import EditFrom from "@/components/cruds/OnDemand/Forms/Edit";
import CreateForm from "@/components/cruds/OnDemand/Forms/Create";
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";


export default {
  name: "OnDemandAgendaIndex",
  components: {
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    ShowItem,
    EditFrom,
    CreateForm,
    TogglePrimaryActive
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  created() {
      //this.fetchPermissions();
      this.verifyPermissions(this.$route.params.event_id)
      this.fetchData(this.$route.params.event_id).catch(() => {
          this.$flashMessage.show({
              group: 'users',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
          });
      })
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.on_demand;
  },
  computed: {
        ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
        ...mapGetters('OnDemandIndex', ['loading', 'all', 'speakers', 'event' , 'languages','event_timezone']),
        ...mapGetters('OnDemandSingle', ['item', 'is_new', "is_editing"]),
  },
  methods: {
        ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
        ...mapActions('OnDemandIndex', ['fetchData']),
        ...mapActions('OnDemandSingle', ['resetState', "setIsNew", "setIsEditing"]),
        createClick() {
            this.setIsNew(true);
            this.setIsEditing(false);
        },
        cancelClick() {
            this.resetState();
        },
        perRowChange(e) {
            this.perPage = Number(e.target.value);
        },
        getTimeFromDate(value) {
          //return moment.utc(value).tz(this.event.time_zone).format('hh:mm A')
          return moment(value).format("hh:mm A");
         
        },

        loadSpeaker(speaker){
            if(_.isObject(speaker)){
              return `${speaker.salutation} ${speaker.first_name} ${speaker.last_name}`;
            }

            if (_.isString(speaker)) {
              let speakerObj = _.find(this.speakers, { 'id': speaker });
              return _.get(speakerObj, 'name', 'No Speaker Assigned')
            }

          return "No Speaker Assigned";
        },
        can(permit,permissions){
            return isPermits(permit,permissions);
        }
    }
}
</script>

<style scoped lang="scss">
.on-demand-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }

  .card-box {
    background: #cccccc4d;
    border-radius: 10px;
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
  }
}
</style>