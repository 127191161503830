<template>
<GeneralDashboardLayout>
    <template v-slot:header>
        <div class="header-template">
            <BackButton :to="{ name: 'event_dashboard.index' }" />
            <h1 class="title text-center">Feedbacks</h1>
            <span>
                <button class="btn btn-primary" @click="createClick" >Create a New
                    Feedback</button>
                <!--<button class="btn btn-primary" @click="cancelClick" >Cancel</button>-->
            </span>
        </div>
    </template>
    <template v-slot:content>
        <div class="container-fluid user-container">
            <div class="row">
                <div class="col-lg-12">
                    <div id="round-tables">
                        <OverlayLoading :isLoading="loading" />
                        <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage">


                           
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row search_bar">
                                        <div class="col-md-2">
                                            <div class="input-group w-70">
                                                <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                                                    <option value="5">5</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <span class="ml-5 rpg_txt">records per page</span>
                                        </div>
                                        <div class="col-md-2 offset-md-8">
                                            <div class="input-group">
                                                <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                                                <span class="input-group-text">
                                                    <i class="fas fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="labelhdwrapper row">
                                <span class="col1">Title</span>
                                <span class="col2"></span>
                                <span class="col3"></span>
                                <span class="col4"></span>
                            </div>
                            
                            <TogglePrimary v-if="newSurvey===true" >
                                <template v-slot:default>
                                    <FeedbackSurveyCreate :eventid = "this.$route.params.event_id" />
                                    
                                </template>
                            </TogglePrimary>

                            <TogglePrimary v-for="(feedback, index) in props.all" v-bind:key="index">
                              
                                <template v-slot:label>
                                    
                                    <span class="labelwrapper">
                                        <span class="col1">{{ feedback.title}}</span>
                                        <span class="col2"></span>
                                        <span class="col3"></span>
                                        <span class="col4">

                                        </span>
                                    </span>
                                </template>

                                <template v-slot:default>

                               
                                    <div class="btn-group settingroup">
												<button type="button" class="mb-1 mt-1 me-1 btn btn-default dropdown-toggle" data-bs-toggle="dropdown"><i data-v-484bfbf9="" class="fa fa-cog"></i><span class="caret"></span></button>
												<div class="dropdown-menu" role="menu">
													<a class="dropdown-item text-1" href="#"> <span href="" class="" @click="showSurveyEditform(feedback._id,feedback)"><i data-v-484bfbf9="" class="fas fa-pen"></i></span></a>
													<a  class="dropdown-item text-1" href="#"><span href="" class="" @click="createQuestionClick(feedback._id)"><i data-v-484bfbf9="" class="fas fa-plus"></i></span></a>
													<a class="dropdown-item text-1" href="#"><span href="" class="" @click="deleteSurvey(feedback._id)"><i data-v-484bfbf9="" class="fas fa-trash"></i></span></a>
													<a class="dropdown-item text-1" href="#"><span href="" class="" @click="showSurveyReport(feedback._id)"><i data-v-484bfbf9="" class="fas fa-chart-pie"></i></span></a>
												</div>
											</div>

                                    <!--Survey box -->

                                    <div class="survey_form" v-if="setIsSurveyEdit===true && feedback._id === surv_edit_id">

                                       

                                        <div class="row">
                                            <div class="col-lg-12 mb-3">
                                                <label>Title*</label>
                                                <div class="kt-input-icon">
                                                    <input type="text" class="form-control" :value="surveyitems.title" name="title" maxlength="200" placeholder="Title" @input="updatesurvey_title" />

                                                </div>
                                                <ValidationMessages :element="v$.feedback.title" />
                                            </div>

                                            <div class="col-lg-10 mb-3">
                                                <label>Result public?</label>
                                                <br />
                                                <div class="switch switch-sm switch-primary">
                                                    <input-switch name="is_open" :value="surveyitems.is_open" id="customSwitchesEx" class="custom-control-input" @change="updateIs_open" />
                                                </div>
                                            </div>

                                            <div class="col-lg-2 text-right mr-0">
                                                <!-- <button v-if="newSurvey===true" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitSurveyTitleForm()">Submit</button> -->
                                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="udpateSurveyTitleForm(feedback._id)">Save</button>
                                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelSurveyEdit">Cancel</button>
                                                <!-- <button v-if="setIsSurveyEdit===true" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteSurvey(feedback._id)">Delete</button> -->
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Survey Box end-->


                                    <!-- <div v-if="newSurvey!==true">
                                        <button class="newbtn" @click="createQuestionClick">Add Question</button>
                                    </div> -->

                                   
                                    <div v-show="is_survey_report ==false && is_new_question!=true || feedback._id !== add_id">
                                       
                                    <div class="question_wrapper" v-for="(survey, index) in feedback.questions_detail" :key="index">

                                        <div v-show="survey._id !== edit_id || feedback._id !== add_id || surveytemp ==true ||  is_survey_editing === false">
                                            
                                            
                                            
                                            
                                            <div v-show="setIsSurveyEdit!==true">
                                            <div class="question">Question: {{ survey.question_title }}</div>

                                            <ul v-if="survey.question_type!='textbox'">
                                                <li v-for="(ans, index2) in survey.answers" :key="index2">
                                                    <span v-if="ans.answer_title!='textbox'">Option {{ index2+1 }}: {{ ans.answer_title }}</span>
                                                    <span v-else>Question type is text.</span>
                                                </li>
                                            </ul>

                                            <ul v-else>
                                                <li>
                                                    <span>Question type is text.</span>
                                                </li>
                                            </ul>

                                            <div class="question_action" v-show=" is_survey_editing === false">
                                                <span href="" class="question_edit" v-on:click="editSurvey(survey._id,survey)">Edit</span>
                                                <span href="" class="question_delete" v-on:click="deleteData(feedback._id,survey._id)">Delete</span>
                                            </div>
                                        </div>

                                        </div>


                                        <SurveyFormFeedback :agenda_id="this.agenda_id" v-show="is_survey_editing === true && survey._id === edit_id" :surveyid=feedback._id :questionid=survey._id></SurveyFormFeedback>
                                        <SurveyShow :panelsurvey="survey" :id="survey._id"></SurveyShow>
                                    </div>
                                </div>

                                <SurveyCreate v-if="is_new_question && feedback._id === add_id && questionstatus===true" :surveyid=feedback._id></SurveyCreate>
                                <hr>
                                <SurveyReport  v-if="is_survey_report == true && feeback_current_id == feedback._id" 
                                :agenda_id="feedback._id" :surveyId="feedback._id" surveyNameFile="FeedbackReport" :is_survey_report="is_survey_report"></SurveyReport>
                                
                               
                               
                                </template>

                            </TogglePrimary>
                        </SearchableContainer>

                    </div>

                </div>
            </div>
            <FlashMessage group="feedback_index" />
        </div>
    </template>
</GeneralDashboardLayout>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary        from "@/components/Commons/TogglePrimary";
// import ShowItem from "@/components/cruds/Feedbacks/Forms/Show";
import InputSwitch          from "@/components/Commons/InputSwitch";
import SurveyFormFeedback   from "@/components/cruds/Feedbacks/Forms/SurveyFormFeedback";
import SurveyCreate         from "@/components/cruds/Feedbacks/Forms/SurveyCreate";
import FeedbackSurveyCreate from "@/components/cruds/Feedbacks/Forms/FeedbackCreate";
import SurveyReport         from "@/components/cruds/Feedbacks/Forms/FeedbackReport";
import SearchableContainer  from "@/components/Commons/SearchableContainer";
import _ from "lodash";
import moment from 'moment';

import {
    gettingErrorMessage
} from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'

export default {
    name: 'FeedbacksIndex',
    components: {
        SearchableContainer,
        SurveyFormFeedback,
        SurveyReport,
        // ShowItem,
        TogglePrimary,
       SurveyCreate,
        BackButton,
        GeneralDashboardLayout,
        InputSwitch,
        FeedbackSurveyCreate,
    },
    data() {
        return {
            search: null,
            perPage: 5,
            system_users: [],
            questionList: true,
            setIsSurveyEdit: false,
            is_survey_report:false,
            feeback_current_id:0
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },

    validations() {
        return {
            feedback: {
                title: {
                    required,
                }
            },
        };
    },
    created() {
        
    },
    beforeUnmount() {
        // this.resetState();
        delete this.$flashMessage.groups.feedback_index;
        delete this.$flashMessage.groups.feedback_survey_create;

    },

    mounted() {
        this.fetchSurveyDataforFeedback(this.$route.params.event_id); //questions data   

    },

    computed: {
        ...mapGetters('FeedbackIndex', ['surveyitems', 'newSurvey', 'all']),
        ...mapGetters('FeedbackSingle', [
            'sitems',
            'surveytemp',
            'is_survey_editing',
            'is_new_question',
            'datanum',
            'questionstatus',
            
        ]),

    },
    methods: {

        ...mapActions('FeedbackIndex', {
            fetchSurveyDataforFeedback: "fetchSurveyDataforFeedback"
        }), //questions data

        ...mapActions('FeedbackIndex', ["setSurveyItemsData", "setIs_open", "setSurveytitle", "updateSurveytitleDataFeedback", "storeSurveyData", "destroySurveyData", "setNewSurvey"]),

        ...mapActions('FeedbackSingle', [
            "setItem",
            "destroyData",
            "setIsSubEditing",
            "setIsNewQuestion",
            "setSurveytemp",
            "setDataNum",           
            
        ]),


        deleteData(surid, qid) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.isConfirmed) {
                    const data = {
                        surveyid: surid,
                        questionid: qid,
                    }
                    this.destroyData(data).then(() => {
                        this.$flashMessage.show({
                            group   : 'feedback_index',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Feedback question deleted successfully.</li></ul>'
                        });

                        this.setIsSurveyEdit = false;
                        this.setNewSurvey(false);
                        this.fetchSurveyDataforFeedback(this.$route.params.event_id);
                        //this.setDataNum(0);
                        //this.fetchsurveydata(this.agenda_id);
                        
                    })
                }
            });
        },
        
        showSurveyReport(feedbackId){
            console.log("feedbackId",feedbackId);
            console.log("this.feedback_current_id",this.feeback_current_id);
            this.feeback_current_id = feedbackId;
            console.log("this.feedback_current_id",this.feeback_current_id);
        
            if(this.is_survey_report === true){
                this.is_survey_report = false;
        
            }else{
                this.is_survey_report = true;  
            }
            this.setIsNewQuestion(false);
            this.setIsSurveyEdit = false;
   
        },

        cancelSurveyEdit() {
            this.setIsSurveyEdit = false;
            this.setNewSurvey(false);
            this.fetchSurveyDataforFeedback(this.$route.params.event_id)

           // this.resetState();
        },

        createClick() {
            this.setNewSurvey(true);
           
           // this.setIsNew(true);
           // this.setIsEditing(false);
        },
        cancelClick() {
          //  this.resetState();
            this.setIsNewQuestion(false);
        },
        perRowChange(e) {
            this.perPage = Number(e.target.value);
        },

        updateIs_open(e) {
            console.log('777777777777777777777', e.target.checked);
            this.setIs_open(e.target.checked);
        },

        updatesurvey_title(e) {
            this.setSurveytitle(e.target.value);
        },

        async submitSurveyTitleForm() {
            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) {

                _.map(this.v$.$errors, (item) => {
                    if (item.$property.indexOf(['title'])) {
                        this.error_general_tab = true;
                    }
                });

            }
            this.storeSurveyData(this.event._id)
                .then(async (resp) => {
                    this.$flashMessage
                        .show({
                            group: 'feedback',
                            type: 'success',
                            title: 'Success',
                            text: 'Survey created successfully.',
                        });

                    this.setIsSurveyEdit = false
                    this.setNewSurvey(false)
                    await this.fetchsurveydata(this.event._id);
                    //await this.fetchAllData(resp.data.data.event_id);
                }).catch(error => {
                    let html = gettingErrorMessage(error);
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'feedback',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }

                });
        },

        createQuestionClick(ques_id) { 
            console.log("ques_id",ques_id)
            console.log("this add id",this.add_id);
            this.add_id = ques_id;           
            this.setIsNewQuestion(true);
            this.setSurveytemp(false);
            this.questionList = false;
            this.is_survey_report = false;
            this.setIsSurveyEdit = false;
        },

        editSurvey(item, survey) {
            //  console.log(survey,'333333333'); 
            this.edit_id = item;
            this.setIsSubEditing(true);
            this.questionList = false;
            this.updateSItems(survey);

        },
        showSurveyEditform(surv_id,feedback) {
            this.surv_edit_id = surv_id; 
            this.setIsSurveyEdit = true;
            this.setIsNewQuestion(false);
            this.is_survey_report = false;
            this.setSurveyItemsData(feedback);
            
            
        },

        updateSItems(items) {
            // console.log('single object', items);
            this.setItem(items);

        },

        async udpateSurveyTitleForm(survey_id) {

           // const isFormCorrect = await this.v$.$validate();

            // if (!isFormCorrect) {

            //     _.map(this.v$.$errors, (item) => {
            //         if (item.$property.indexOf(['title'])) {
            //             // this.error_general_tab = true;
            //         }
            //     });
            //     return

            // }

            let multidatasur = {
                module_id: this.$route.params.event_id,
                survey_id: survey_id
            };
            this.updateSurveytitleDataFeedback(multidatasur)
                .then(async () => {
                    this.$flashMessage
                        .show({
                            group: 'feedback_index',
                            type: 'success',
                            title: 'Success',
                            text: 'Survey updated successfully.',
                        });

                    this.setIsSurveyEdit = false
                    await this.fetchSurveyDataforFeedback(this.$route.params.event_id);
                    //await this.fetchAllData(resp.data.data.event_id);
                }).catch(error => {
                    
                    let html = gettingErrorMessage(error);
                    console.log('errrrrorr html',html)
                    // if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'feedback_index',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    // }
                    this.setIsSurveyEdit = true

                });
        },

        deleteSurvey(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.isConfirmed) {
                    this.destroySurveyData(item).then(async(res) => {
                        console.log("Responseneee",res);

                        this.$flashMessage.show({
                            group   : 'feedback_index',
                            type    : 'success',
                            title   : 'Success',
                            time    : 1000,
                            html    : '<ul><li>Feedback has been deleted successfully.</li></ul>'
                        });
                     
                        this.fetchSurveyDataforFeedback(this.$route.params.event_id);
                        this.setNewSurvey(false);
                        //this.setIsEditing(false);
                       // this.setNewSurvey(false);
                        

                        // this.fetchData(this.$route.params.event_id);
                        // this.setIsNew(false);
                        // this.setIsEditing(false);

                    })
                }
            });
        },

    }
}
</script>

<style lang="scss" scoped>
.header-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title {
        font-size: 1.6rem;
    }
}

.user-container {
    .toggle label {
        color: #000 !important;
        border-radius: 0px;

    }

    .toggle.active>label {
        color: #fff !important;
    }

    .toggle section {
        // box-shadow: -1px 0px 5px 2px #ccc;
        border-bottom: 1px solid #ccc;
    }

    .toggle-content {
        padding: 35px;

    }

    .form-control {
        font-family: 'Poppins';
    }

    .desc_btn_wrapper {
        margin-top: 20px;
    }

    .snippet {
        border-radius: 0.2rem;
        border: 1px solid #c9c9c9;
        padding: 24px 22px;
        margin: 15px 0;
        background-color: white;
    }

    .snippet .btn-light {
        color: #000 !important;
    }

    .edit_panel {
        background: #d5e3eb;
        padding: 15px;
        margin-top: 10px;
    }

    .snippet {
        margin-top: 0px;
    }

    .note-editor .btn-light {
        color: #000 !important;
    }

    .setting_wrapper {
        float: right;
    }

    .setting_wrapper a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;

    }

    .labelwrapper {
        width: 100%;
    }

    .labelhdwrapper {
        width: 100%;
        margin-left: 0px;
        border: 1px solid #ccc;
        padding: 10px;
        background: #e1e1e1;
    }

    .labelhdwrapper span {
        font-weight: bold;
    }

    .col1 {
        width: 25%;
        display: inline-flex;
    }

    .col2 {
        width: 25%;
        display: inline-flex;
    }

    .col3 {
        width: 25%;
        display: inline-flex;
    }

    .col4 {
        width: 20%;
        display: inline-flex;
    }

    .search_bar {
        margin-bottom: 20px;
    }

    .eventlabel {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .event_item {
        color: #fff;
        padding: 8px 15px;
        border-radius: 8px;
        background: #afbcc3;
        min-width: 50px;
        display: inline-flex;
        margin: 5px;
    }

    .nav-link.active {
        border-top-color: #0088CC;
    }

    .toggle.active>label {
        background: #0a72e0;
    }

    .nav-tabs li.active a {
        color: #fff !important;
        background: #0a72e0 !important;
        font-size: 1.2em;
    }

    .nav-tabs li a {
        font-size: 1.2em;
        color: #333;
    }

    .toggle>label {
        background: none !important;
        font-size: 1em !important;
    }

    .toggle .toggle {
        margin: 0px;
    }

    .toggle {
        margin: 0px;
    }

    .toggle_odd {
        background: #f5f5f5;
    }

    .btn-primary {
        color: #fff !important;
        background-color: #0c78e0 !important;

    }

    .nav-tabs li .nav-link,
    .nav-tabs li .nav-link:hover {
        background: #cccccc94 !important;
    }

    .pageview {
        font-family: 'Poppins';
        font-size: 1em !important;
    }

    .w-70 {
        width: 30%;
        display: inline-flex;
    }

    .rpg_txt {
        display: inline-block;
        margin-left: 10px;
    }

    .nav-tabs li .nav-link,
    .nav-tabs li {
        border-right: 1px solid #EEE;
    }

    .toggle_odd {
        .event_detail_wrapper {
            .row {
                .col-md-8 {
                    border-bottom: 0px dotted #ccc;
                    padding-bottom: 5px;
                }
            }
        }
    }

    .text-right {
        text-align: right !important;
        margin-right: 100px;
        background: none;
    }

    .toggle_odd {
        .text-right {
            margin-right: 120px;
        }
    }

    .btn-info {
        background: #6c757d !important;
    }

    .profile_info {
        text-align: center;
        margin-top: 40px;
    }

    .card-box {
        background: #cccccc4d;
        border-radius: 10px;
        padding: 30px;
        min-height: 300px;
        font-size: 1.1em;

        h2 {
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: 0.01em;
            margin-bottom: 20px;
        }
    }
}




.surveytitlehd {
    background: #0088CC;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.surveytitlehd label {
    color: #fff !important;
    font-weight: bold
}

.surveytitlehd span {
    margin-left: 20px;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}

.dropdown-menu.show { 
    max-width: 47px;
    min-width: 47px;
}

.settingroup{
    display: flex;
    margin-left: auto;
    width: 70px;
    text-align: right;
}
</style>
