import axios from "axios";
import constants from "@/config/constants";
import moment from "moment";

function initialState() {
    return {
        all: [],
        loading: false,
        event_timezone: null,
        event_type: null,
    }
}

const getters = {
    all: state => state.all,
    loading: state => state.loading,
    event_timezone: state => state.event_timezone,
    event_type: state => state.event_type,
}

const actions = {
    fetchData({ dispatch, commit, state }, event_id) {
        commit('setLoading', true)

        return axios.get(constants.MOCK_API_URL + '/api/v1/event/' + event_id + '/webcasts')
            .then(response => {
                commit('setAll', response.data.data)

                if (response.data.data[0].event) {
                    commit('setEventTimezone', response.data.data[0].event.time_zone)
                    moment.tz.setDefault(response.data.data[0].event.time_zone);
                }

                return response;
            })
            .catch(error => {
                console.log(error)
                return error
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    getEventDetails({ commit, state }, params) {
        return axios.get(constants.MOCK_API_URL + '/api/v1/event_details/' + params.id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type);
                return response;
            })
    },
    destroyData({ commit, state }, id) {
        return axios.delete(constants.MOCK_API_URL + '/api/v1/agendas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                console.log(error)
                return error;
            })
    },
    destroyQaData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/qas/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.agenda_qas = item.agenda_qas.filter((qa) => {
                        return qa.id != id
                    })
                }))

                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyPanelData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/agenda-speakers/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.webcast_speakers = item.webcast_speakers.filter((panel) => {
                        //debugger;
                        return panel.id != id
                    })
                }))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyData({ commit, state }, id) {
        commit('setLoading', true)
        return axios.delete(constants.MOCK_API_URL + '/api/v1/surveys/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.id != id
                    })
                }))
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroySurveyQuestion({ commit, state }, id) {
        commit('setLoading', true);

        return axios.delete(constants.MOCK_API_URL + '/api/v1/survey-questions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.surveys = item.surveys.filter((survey) => {
                        return survey.survey_questions = survey.survey_questions.filter((question) => {
                            return question.id != id
                        })
                    })
                }))

                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateAnswer({ commit, state, dispatch }, id) {
        commit('setLoading', true);

        const params = {}

        return axios.post(constants.MOCK_API_URL + '/api/v1/qas/' + id, params)
            .then(response => {
                // commit('setAll', state.all.filter((item) => {
                //     return item.agenda_qas = item.agenda_qas.filter((qa) => {

                //         return qa.id != id
                //     })
                // }))
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setEventTimezone(state, value) {
        state.event_timezone = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setEventType(state, value) {
        state.event_type = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
