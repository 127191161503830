import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";


function initialState() {
    return {
        item: {
            _id             :null,
            event_id        : null,
            page_name       : null,
            page_type       : null,
            language        : null,
            sorting_order   : null,
            is_visible      : false,
            status          : false
        },
        languagesAll    : [],
        pageTypesEnum   : [ { value: 'Static', label: 'Static' }, { value: 'Dynamic', label: 'Dynamic' } ],
        loading         : false,
        is_new          : false,
        is_editing      : false,
    }
}

const getters = {
    item            : state => state.item,
    loading         : state => state.loading,
    languagesAll    : state => state.languagesAll,
    pageTypesEnum   : state => state.pageTypesEnum,
    is_new          : state => state.is_new,
    is_editing      : state => state.is_editing,
}

const actions = {
    setItem({commit,state,dispatch},value){
        commit('setItem',value)
    },
    async storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.content_section;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/event-page', params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            commit('resetState');
            //commit('resetState')
           
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
      
    },
    async updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params.__v;
        delete params._id;
        delete params.__v;
        delete params.created_at;
        delete params.updated_at;
        delete params.created_by;
        delete params.page_content;
        delete params.is_deleted;
        delete params.updatedAt;
        delete params.createdAt;
       
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/event-page/'+state.item._id, params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
           commit('resetState')
           
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
     
    },
    fetchData({ commit, dispatch }, params) {
        axios.get('/api/v1/pages/' + params.id)
            .then(response => {
                commit('setItem', response.data.data)
            })

        dispatch('fetchEventsAll', params.event_id)
        dispatch('fetchLanguagesAll', params.event_id)
    },
    fetchLanguagesAll({ commit }, event_id) {
        axios.get('/api/v1/languages/event_languages/'+event_id)
            .then(response => {
                commit('setLanguagesAll', response.data.data)
            })
    },
    fetchEventsAll({ commit }, id) {
        //debugger;
        //axios.get('/api/v1/event_details/'+id)
           // .then(response => {
                commit('setEvent', id)
          //  })
    },
    setEventId({commit}, value){
        commit('setEventId',value)
    },
    setPageName({ commit }, value) {
        commit('setPageName', value)
    },
    setLanguage({ commit }, value) {
        commit('setLanguage', value)
    },
    setPageType({ commit }, value) {
        commit('setPageType', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setVisibile({ commit }, value) {
        commit('setVisibile', value)
    },
    setSort_order({ commit }, value) {
        commit('setSort_order', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
   
}

const mutations = {

    setEventId(state,value){
        state.item.event_id = value;
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setPageName(state, value) {
        state.item.page_name = value
    },
    setStatus(state, value) {
        state.item.status = value
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setVisibile(state, value) {
        state.item.is_visible = value
    },
    setSort_order(state, value) {
        state.item.sorting_order = value
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    setPageType(state, value) {
        state.item.page_type = value
    },
    setLanguage(state, value) {
        state.item.language = value
    },
    setLanguagesAll(state, value) {
        state.languagesAll = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
