<template>
  <div class="container-fluid">
    <div class="report_wrapper">  
      <div class="panel-title">
        <h2 style="text-align: center; padding-bottom: 10px;">{{ all.title }}  
           <button class="mb-1 mt-1 me-1 btn btn-small btn-primary" @click="exportData(all._id)" v-if="all.title">Export </button>
            </h2>

      </div>  
      <div class="row">

        <div class="col-md-6 col-sm-6 col-lg-6" v-for="(questions_detail,index) in all.questionDetail" :key="index">
          <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">{{questions_detail.question_title}}</h3>
              </div>
          <div class="panel-body" v-if="questions_detail.graphData">
            <div class="col-md-12 col-lg-12 col-sm-12" v-if="questions_detail.question_type == 'radio' || questions_detail.question_type == 'checkbox'">
              <div class="small" v-if="questions_detail.graphData">
                <Pie :data="questions_detail.graphData" :options="chartOption" />
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12" v-if="questions_detail.question_type == 'textbox'">
              <div class="panel-heading" v-for="(questions_detail,index) in questions_detail.answerText" :key="index">
                <span>Answer: {{ questions_detail }}</span>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 text-center">
              <span>Total Answers:</span><span>{{questions_detail.totalAnswersCount}}</span> &nbsp;&nbsp;
              <button class="mb-1 mt-1 me-1 btn btn-small btn-primary" @click="showModal(all._id,questions_detail._id)">View Users</button>
              
            </div>
          </div>
          <div v-else><span style="display: flex; justify-content: center;align-items: center;"> Data not found</span></div>
        </div>
       
        </div>
    
      </div>  <!--Row End-->      
    </div>
    <div class="row" style="left:0;width:100%;background: cornflowerblue;text-align: center;" >
    <span v-if="all.title">Number of peoples answered  in Survey per question.</span>
    <span v-else>Survey Analytics not found.</span>
    </div>
      <div class="col-md-6 col-sm-6 col-lg-6"  id="barchart"  v-for="(chartData,index) in graphReport" :key="index" >
        <div v-if="chartData.question_type !=='textbox'" style="width:400px; height:300px;">
          <Bar :id="chartData.question_id" :data="chartData.graphData" :options="chartOptions" ref="barChart"  v-if="chartData.graphData"></Bar>
        </div>
      
    </div><!-- End of Bar Chart-->
   
    
  
   
  </div>
  <LeaderBoardsModel v-show="isModalVisible" @close="closeModal" ></LeaderBoardsModel>
        
</template>

<script>
import BarChart from '@/components/cruds/Survey/BarChart.vue';

import LeaderBoardsModel from "@/components/cruds/Survey/surveyUserModel";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Bar,Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)
import {mapGetters, mapActions} from "vuex";
export default {
  data() {
    return {
        chartOption:{
            responsive: true,
            maintainAspectRatio: false
      },
      isModalVisible:false,
      graphData : {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'Data One',
                backgroundColor: '#f87979',
                data: [40, 20, 12, 39, 10, 40, 39]
              }
            ]
      }
    }
   
 
  },
  name: "SurveyReport",
  components: {   
    Pie ,
    Bar,
    LeaderBoardsModel,
    
  },

  props: {
        agenda_id: {
            type: String
        },
        surveyId: {
            type: String
        },
        surveyNameFile:null

  },
  async created() {
  
  },
  mounted() {       
    this.fetchData(this.agenda_id); 
    this.fetchGraphData(this.agenda_id);    
       
  },

  computed: {     
    ...mapGetters('SurveyReportIndex', ['all','userList','graphReport'])
  },

  methods:{
    ...mapActions('SurveyReportIndex', {
          fetchData: "fetchData",
          fetchUserDetail:"fetchUserDetail",
          fetchGraphData:"fetchGraphData"
        }),
        setChartCanvas(canvas) {
          this.chartCanvas = canvas;
        },
        showModal(surveyId,questionId){
          let multiData = {surveyId:surveyId,questionId:questionId};
          this.fetchUserDetail(multiData)
                .then(async (resp) => {
                  this.isModalVisible = true;
         
                }).catch(error => {
                   // let html = gettingErrorMessage(error);
                    

                });
        },
        closeModal() {
          this.isModalVisible = false;
        },
        async exportData(surveyId){
      
                let chartData = this.graphReport;
                const workbook = new ExcelJS.Workbook();
                for(let data of chartData){
                  if(data.question_type =="radio" || data.question_type =="checkbox" ){
                    let sheetTitle =  data.title.match(/[a-z]|[0-9]|-|\s/gi).join('')
                    sheetTitle.replace("?","");
                    let canvas ;
                    let imageURL;
                    if(data.graphData){
                      canvas = document.getElementById(data.question_id);
                      imageURL = canvas.toDataURL('image/png');
                    }
                    const worksheet = workbook.addWorksheet(sheetTitle);
                    worksheet.getCell('A1').value = data.title;
                    worksheet.getCell('A2').value ="Answers Choices";
                    worksheet.mergeCells('B2:C2');
                    worksheet.getCell('B2').value ="Responses";
                    let cellPosition = 4;
                    for(let answer of data.answers){
                      let percentage  = answer.answerPercentage?answer.answerPercentage:0
                      percentage      = parseFloat(percentage+"%");
                      worksheet.addRow([answer.answerTitle,answer.totalCount,answer.answerPercentage+"%"]); 
                      cellPosition++;
                    }

                    if(data.graphData){
                      const imageId = workbook.addImage({
                        base64: imageURL,
                        extension: 'png'
                      });
                       worksheet.addImage(imageId, `B${cellPosition}:G${cellPosition+15}`); // Adjust the range as necessary

                    }
                    

                  }

                  if(data.question_type =="textbox"  ){
                    let sheetTitle =  data.title.match(/[a-z]|[0-9]|-|\s/gi).join('')
                    sheetTitle.replace("?","");

                    const worksheet = workbook.addWorksheet(sheetTitle);
                    worksheet.getCell('A1').value = data.title;
                    worksheet.getCell('A2').value ="Answers Text";
                  
                    for(let answer of data.answerText){
                      worksheet.addRow([answer]); 
                    }

                  }
                
                }
                const fileName = `${this.surveyNameFile}.xlsx`;
                //await workbook.write(fileName);
                const buffer = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buffer]), fileName);
     
          
    }


  },
  


}
</script>

<style scoped lang="scss">
.button {
  border: 1px solid white;
  border-radius: 2px;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  transition: all 0.3s ease;
  cursor: pointer;

  &--transparent {
    background-color: transparent;
  }
  &:hover {
    background-color: white;
    border: 1px solid #1fa9e5;
    color: #1fa9e5;
  }
}

.taskItems li{
  list-style:none;
  margin-bottom:10px;
}

.taskItems ul{
  padding-left: 0px;
  margin-left:0px;
}

.textcenter{
  text-align: center;
  margin-bottom: 20px;
  display: block;
}

.question_wrapper{
  background:#efefef;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.question{
  font-weight: bold;
  margin-bottom: 10px;
} 

.question_action{
  position:absolute;
  top:50%;
  right:50px;
}

.question_edit{
background:#1896e3;
color: #fff;
padding:8px 20px;
border-radius: 5px;
text-decoration: none;
margin-right: 5px;
box-shadow: 0px 1px 2px 0px #999;
cursor: pointer;
}

.question_delete{
background:#fff;
color: #5a5757;
padding:8px 20px;
border-radius: 5px;
text-decoration: none;
box-shadow: 0px 1px 2px 0px #999;
cursor: pointer;

}
.panel-primary>.panel-heading{
  color: #fff;
  background-color: #e43c5c;
  border-color: #e43c5c;
}
.panel-heading{
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-body {
    padding: 15px;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
}

</style>