<template>
  <section class="events-list container-fluid">
    <div class="row">
      <div class="col-md-4 left-menu">
        <div class="column-right-header">
          <h2>VIRTUAL EVENT 2D</h2>
        </div>
        <div class="menu-wrapper">
          <nav class="menu">
            <h4>General settings</h4>
            <ul>
              <li><a href="#" @click="logout">Logout</a></li>
            </ul>
          </nav>
        </div>
        <div class="logo">
          <img src="/assets/images/logo_white.png" alt="logo">
          <span class="subtitle-logo">EVENT ADMIN PANEL</span>
        </div>
      </div>
      <div class="col-md-8 body-menu">
        <slot/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DefaultLayout",
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push({name: 'login'});
    }
  }
}
</script>

<style scoped lang="scss">
.column-right-header {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
  background-color: rgba(8, 8, 8, 0.406);
  width: 100%;

  h2 {
    font-size: 2.6rem;
    margin: 10px 0;
    letter-spacing: 2px;
  }
}
.events-list {
  .left-menu {
    background-image: url("~@/../public/assets/images/layout/bg.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    box-shadow: 4px -2px 16px 0px #3333338a;
   
  }
  .body-menu {
    background-image: url("~@/../public/assets/images/layout/bg_right.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
}
.menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  .menu {
    color: white;
    a {
      text-decoration: none;
      color: white;     
      margin-bottom: 2px;
      padding: 5px;
      display: flex;
      border-bottom: 1px dotted;
    }
     a:hover {      
      color: white;     
      background:rgba(0, 0, 0, 0.309)
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    h4 {
      margin-bottom: 32px;
      font-size: 1.8rem;
    }
  }
}

.logo {
  position: absolute;
  bottom: 25px;
  left: 0;
  text-align: center;
  width: 100%;

  .subtitle-logo {
    color: white;
    font-size: 0.8rem;
    display: block;
  }
}
</style>