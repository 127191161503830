<template>
  <div class="row">
    <div class="col-md-2">
      <div class="user_img">
        <img v-if="user.picture && user.picture.key" :src="IMAGE_URL + user.picture.key" alt="pic">
        <img v-if="user.profile_picture && user.profile_picture.key" :src="IMAGE_URL + user.profile_picture.key" alt="pic">
      
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Full Name</div>
          <div class="col-md-8">{{ user.first_name }} {{ user.last_name }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Email</div>
          <div class="col-md-8">{{ user.email }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company</div>
          <div class="col-md-8">{{ user.company?user.company:"" }} {{ user.company_name?user.company_name:"" }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Job Title</div>
          <div class="col-md-8">{{ user.job_title }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Phone</div>
          <div class="col-md-8">{{user.country_code?"("+user.country_code+")":'' }}  {{ user.phone?user.phone:"" }} {{ user.Phone?user.Phone:"" }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Address</div>
          <div class="col-md-8">{{ user.Address }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">City</div>
          <div class="col-md-8">{{ user.city }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Country</div>
          <div class="col-md-8">{{ user.country }}</div>
        </div>
      </div>
    </div>
    <div class="card-body text-right mt-3 mr-5">
      <button v-if="can('user_edit',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(user)">Edit</button>
      <button v-if="can('user_delete',checkPermssions)" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(user)">Delete</button>
    </div>
  </div>
</template>

<script>
import { mapActions ,mapGetters} from 'vuex'
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "ShowItem",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {
          name: null,
          email: null,
          company: null,
          role: null,
        }
      }
    },
    checkPermssions:null
  },
  computed: {
    ...mapGetters('UsersIndex', [ 'type']),
  },
  methods: {
    ...mapActions('UsersIndex', ['fetchData', 'destroyData']),
    ...mapActions('UsersSingle', ['setItem', 'setIsNew', 'setIsEditing']),
    
    
    deleteData(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
        
            this.destroyData(item._id).then(() => {
            this.fetchData();
            this.setIsNew(false);
            this.setIsEditing(false);

              this.$flashMessage.show({
                group: 'users',
                type: 'success',
                title: 'Success',
                text: 'User has been deleted successfully.'
              });
            })
          
         
        }
      });
    },
    editData(item) {
      this.setItem(item);
      this.setIsNew(false);
      this.setIsEditing(true);
      this.$forceUpdate();
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  },

}
</script>

<style scoped lang="scss">
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}
</style>