<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'dashboard.index' }" />
        <h1 class="title text-center">Users</h1>
        <span v-if="can('user_create',checkPermssions)">
          <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create a New User</button>
          <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
      </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid user-container">
        <div class="row">
          <div class="col-lg-12">
            <div class="tabs">
              <ul class="nav nav-tabs nav-justified">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-target="#system-users" href="#system-users" data-bs-toggle="tab"
                    @click="clickTab('admin')">System Users</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-target="#event-organizer" href="#event-organizer" data-bs-toggle="tab"
                    @click="clickTab('event-organizer')">Event Organizer</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-target="#event-participant" href="#event-participant" data-bs-toggle="tab"
                    @click="clickTab('event-participant')">Event Participants</a>
                </li>
              </ul>
              <div class="tab-content">
                <div id="system-users" class="tab-pane active">
                  <SearchableContainer :all="system_users" :search="search" v-slot="props" :perPage="this.perPage" v-if="can('user_view',checkPermssions)">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row search_bar">
                          <div class="col-md-2">
                            <div class="input-group w-70">
                              <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <span class="ml-5 rpg_txt">records per page</span>
                          </div>
                          <div class="col-md-2 offset-md-8 ">
                            <div class="input-group">
                              <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                              <span class="input-group-text">
                                <i class="fas fa-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="labelhdwrapper row">
                      <span class="col1">Name</span>
                      <span class="col2">Email</span>
                      <span class="col3">Company</span>
                      <span class="col4">Role</span>
                    </div>
                    <TogglePrimary v-if="is_new && type === 'admin'">
                      <template v-slot:default>
                        <UserCreateForm />
                      </template>
     
                    </TogglePrimary>
                    <TogglePrimary v-for="(user, index) in props.all" v-bind:key="index">
                      <template v-slot:label>
                        <span class="labelwrapper">
                          <span class="col1">{{ user.first_name }} {{ user.last_name }}</span>
                          <span class="col2">{{ user.email }}</span>
                          <span class="col3">{{ user.company }}</span>
                          <span class="col4">{{ getUserRole(user.access_level) }}</span>
                        </span>
                      </template>
                      <template v-slot:default>
                        <UserEditForm :id="user._id"
                          v-if="is_new === false && is_editing === true && user._id === item._id" />
                        <ShowItem :user="user" :checkPermssions="checkPermssions" v-else />
                      </template>
                    </TogglePrimary>
                  </SearchableContainer>
                </div>
                <div id="event-organizer" class="tab-pane">
                  <SearchableContainer :all="event_organizer_user" :search="search" v-slot="props"
                    :perPage="this.perPage" v-if="can('user_view',checkPermssions)">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row search_bar">
                          <div class="col-md-2">
                            <div class="input-group w-70">
                              <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <span class="ml-5 rpg_txt">records per page</span>
                          </div>
                          <div class="col-md-2 offset-md-8 ">
                            <div class="input-group">
                              <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                              <span class="input-group-text">
                                <i class="fas fa-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="labelhdwrapper row">
                      <span class="col1">Name</span>
                      <span class="col2">Email</span>
                      <span class="col3">Company</span>
                      <span class="col4">Role</span>
                    </div>
                    <TogglePrimary v-if="is_new && type === 'event-organizer'">
                      <template v-slot:default>
                        <UserCreateForm />
                      </template>
                    </TogglePrimary>
                    <TogglePrimary v-for="(user, index) in props.all" v-bind:key="index">
                      <template v-slot:label>
                        <span class="labelwrapper">
                          <span class="col1">{{ user.first_name }} {{ user.last_name }}</span>
                          <span class="col2">{{ user.email }}</span>
                          <span class="col3">{{ user.company }}</span>
                          <span class="col4">{{ getUserRole(user.access_level) }}</span>
                        </span>
                      </template>
                      <template v-slot:default>
                        <UserEditForm :id="user._id"
                          v-if="is_new === false && is_editing === true && user._id === item._id" />
                        <ShowItem :user="user" :checkPermssions="checkPermssions" v-else />
                      </template>
                    </TogglePrimary>
                  </SearchableContainer>
                </div>
                <div id="event-participant" class="tab-pane">
                  <SearchableContainer :all="event_participant_users" :search="search" v-slot="props"
                    :perPage="this.perPage" v-if="can('user_view',checkPermssions)">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row search_bar">
                          <div class="col-md-2">
                            <div class="input-group w-70">
                              <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                                <option value="5">5</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <span class="ml-5 rpg_txt">records per page</span>
                          </div>
                          <div class="col-md-2 offset-md-8 ">
                            <div class="input-group">
                              <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                              <span class="input-group-text">
                                <i class="fas fa-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="labelhdwrapper row">
                      <span class="col1">Name</span>
                      <span class="col2">Email</span>
                      <span class="col3">Company</span>
                      <span class="col4">Role</span>
                    </div>
                  <!--  <TogglePrimary v-if="is_new && type === 'event-participant'">
                      <template v-slot:default>
                        <UserCreateForm />
                      </template>
                    </TogglePrimary>-->
                    <TogglePrimary v-for="(user, index) in props.all" v-bind:key="index">
                      <template v-slot:label>
                        <span class="labelwrapper">
                          <span class="col1">{{ user.first_name }} {{ user.last_name }}</span>
                          <span class="col2">{{ user.email }}</span>
                          <span class="col3">{{ user.company }}</span>
                          <span class="col4">{{ getUserRole(user.access_level) }}</span>
                        </span>
                      </template>
                      <template v-slot:default>
                        <EventUserEditForm :id="user._id"
                          v-if="is_new === false && is_editing === true && user._id === item._id" />
                        <ShowItem :user="user" :checkPermssions="checkPermssions" v-else />
                      </template>
                    </TogglePrimary>
                  </SearchableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FlashMessage group="users" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/Users/Forms/Show";
import UserEditForm from "@/components/cruds/Users/Forms/Edit";
import EventUserEditForm from "@/components/cruds/Users/Forms/EditEventUser";

import UserCreateForm from "@/components/cruds/Users/Forms/Create";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import _ from "lodash";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: 'UsersIndex',
  components: {
    SearchableContainer,
    UserCreateForm, UserEditForm, ShowItem, TogglePrimary, BackButton, GeneralDashboardLayout,
    EventUserEditForm
  },
  data() {
    return {
      search: null,
      perPage: 5,
      system_users: [],
      event_organizer_user: [],
      event_participant_users: [],
    }
  },
  watch: {
    all(items) {
      this.system_users = _.filter(items, i => _.findKey(this.allRoles, function (o) { return (o._id == i.access_level && o.access_level == 'platform') }));
      this.event_organizer_user = _.filter(items, i => _.findKey(this.allRoles, function (o) { return (o._id == i.access_level && o.access_level == 'event') }));
    },
    participants(items) {
      this.event_participant_users = items;
    },

  },
  async created() {
    const access_level = localStorage.getItem('access_level');
    if(access_level =='event'){
      this.$router.push({name: 'notFound'});
    }
    await this.fetchAllRoles();
    this.fetchData().catch(() => {
      this.$flashMessage.show({
        group: 'users',
        type: 'error',
        title: 'Error',
        text: 'Something went wrong.'
      });
    })
    this.verifyPermissions();
   
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.users;
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", current_role:"role",isCan:"isCan"}),
    ...mapGetters('UsersIndex', ['total', 'loading', 'relationships', 'all', 'type', 'participants']),
    ...mapGetters('UsersSingle', ['item', 'is_new', "is_editing"]),
    ...mapGetters('RolesIndex', { allRoles: 'all' }),
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('UsersIndex', ['fetchData', 'setType']),
    ...mapActions('UsersSingle', ['setRole', 'resetState', "setIsNew", "setIsEditing"]),
    ...mapActions('RolesIndex', { fetchAllRoles: 'fetchData' }),

    createClick() {
      this.setRole(this.type);
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    clickTab(type) {
      this.setType(type);
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
    getUserRole(level) {
      let info = _.find(this.allRoles, function (o) { return (o._id == level) })
      return (_.isObject(info)) ? info.title : "";
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.user-container {
  .toggle label {
    color: #000 !important;
    border-radius: 0px;

  }

  .toggle.active>label {
    color: #fff !important;
  }

  .toggle section {
    // box-shadow: -1px 0px 5px 2px #ccc;
    border-bottom: 1px solid #ccc;
  }

  .toggle-content {
    padding: 35px;

  }

  .form-control {
    font-family: 'Poppins';
  }

  .desc_btn_wrapper {
    margin-top: 20px;
  }

  .snippet {
    border-radius: 0.2rem;
    border: 1px solid #c9c9c9;
    padding: 24px 22px;
    margin: 15px 0;
    background-color: white;
  }

  .snippet .btn-light {
    color: #000 !important;
  }

  .edit_panel {
    background: #d5e3eb;
    padding: 15px;
    margin-top: 10px;
  }

  .snippet {
    margin-top: 0px;
  }

  .note-editor .btn-light {
    color: #000 !important;
  }

  .setting_wrapper {
    float: right;
  }

  .setting_wrapper a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;

  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .eventlabel {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .event_item {
    color: #fff;
    padding: 8px 15px;
    border-radius: 8px;
    background: #afbcc3;
    min-width: 50px;
    display: inline-flex;
    margin: 5px;
  }

  .nav-link.active {
    border-top-color: #0088CC;
  }

  .toggle.active>label {
    background: #0a72e0;
  }

  .nav-tabs li.active a {
    color: #fff !important;
    background: #0a72e0 !important;
    font-size: 1.2em;
  }

  .nav-tabs li a {
    font-size: 1.2em;
    color: #333;
  }

  .toggle>label {
    background: none !important;
    font-size: 1em !important;
  }

  .toggle .toggle {
    margin: 0px;
  }

  .toggle {
    margin: 0px;
  }

  .toggle_odd {
    background: #f5f5f5;
  }

  .btn-primary {
    color: #fff !important;
    background-color: #0c78e0 !important;

  }

  .nav-tabs li .nav-link,
  .nav-tabs li .nav-link:hover {
    background: #cccccc94 !important;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }

  .nav-tabs li .nav-link,
  .nav-tabs li {
    border-right: 1px solid #EEE;
  }

  .toggle_odd {
    .event_detail_wrapper {
      .row {
        .col-md-8 {
          border-bottom: 0px dotted #ccc;
          padding-bottom: 5px;
        }
      }
    }
  }

  .text-right {
    text-align: right !important;
    margin-right: 100px;
    background: none;
  }

  .toggle_odd {
    .text-right {
      margin-right: 120px;
    }
  }

  .btn-info {
    background: #6c757d !important;
  }

  .profile_info {
    text-align: center;
    margin-top: 40px;
  }

  .card-box {
    background: #cccccc4d;
    border-radius: 10px;
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
  }
}
</style>
