<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Tags</h1>
        <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create a New Tag</button>
        <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid sponsor-container">
        <div class="row">
          <div class="col-lg-12">
            <div id="live-presentations">
              <OverlayLoading :isLoading="loading" />
              <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row search_bar">
                      <div class="col-md-2">
                        <div class="input-group w-70">
                          <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <span class="ml-5 rpg_txt">records per page</span>
                      </div>
                      <div class="col-md-2 offset-md-8 ">
                        <div class="input-group">
                          <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                          <span class="input-group-text">
                            <i class="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="labelhdwrapper row">
                  <span class="col1">Title</span>
                  <!-- <span class="col2">Link(URL)</span> -->
                </div>
                <TogglePrimary v-if="is_new">
                  <template v-slot:default>
                    <TagsCreate />
                  </template>
                </TogglePrimary>
               
                <TogglePrimary v-for="(tag, index) in props.all" v-bind:key="index">
                  <template v-slot:label>
                      <span class="labelwrapper">
                        <span class="col1">{{ tag.title }}</span>
                        <!-- <span class="col2">{{ tag.link }}</span> -->
                      </span>
                </template>
   
                  <template v-slot:default>
                    <TagsEdit :tag_id="tag._id" v-if="item._id === tag._id" />
                    <TagsShow :tag="tag" v-else />
                  </template>
                </TogglePrimary>
              </SearchableContainer>
            </div>
          </div>
             <FlashMessage group="tags" />
     
        </div>
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import TagsCreate from "@/components/cruds/Tags/Forms/Create";
import TagsShow from "@/components/cruds/Tags/Forms/Show";
import TagsEdit from "@/components/cruds/Tags/Forms/Edit";

export default {
  name: "TagsIndex",
  components: {
    TagsEdit,
    TagsShow,
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    TagsCreate,
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  computed: {
    ...mapGetters('TagsIndex', ['loading', 'all', 'edit_id']),
    ...mapGetters('TagsSingle', ['item', 'is_new', "is_editing"]),
  },
  created() {
    this.fetchData(this.$route.params.event_id)
        .catch(() => {
          this.$flashMessage.show({
            group: 'users',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        })
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.tags;
  },
  methods: {
    ...mapActions('TagsIndex', ['fetchData']),
    ...mapActions('TagsSingle', ['resetState', "setIsNew", "setIsEditing"]),
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
  }
}
</script>

<style lang="scss" scoped>
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.sponsor-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }
}

</style>


