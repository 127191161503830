
<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="row">
      <div class="col-md-10 event_detail_ls">
        <div class="grace_period">
          <div class="row">
            <div class="col-md-2">
              <label for="grace_period">grace_period *</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="grace_period"
                placeholder="Enter grace_period *"
                :value="item.grace_period"
                @input="updateGracePeriod"
              />
              <ValidationMessages :element=" v$.item.grace_period" />
            </div>
          </div>
        </div>

        <div class="max_cap_training_room">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_training_room">max_cap_training_room *</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_training_room"
                placeholder="Enter max_cap_training_room *"
                :value="item.max_cap_training_room"
                @input="updateMaxCapTrainingRoom"
              />
              <ValidationMessages :element=" v$.item.max_cap_training_room" />
            </div>
          </div>
        </div>

        <div class="max_cap_roundtable_room">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_roundtable_room"
                >max_cap_roundtable_room *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_roundtable_room"
                placeholder="Enter max_cap_roundtable_room *"
                :value="item.max_cap_roundtable_room"
                @input="updateMaxCapRoundtableRoom"
              />
              <ValidationMessages :element=" v$.item.max_cap_roundtable_room" />
            </div>
          </div>
        </div>

        <div class="max_cap_auditorium_live">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_auditorium_live"
                >max_cap_auditorium_live *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_auditorium_live"
                placeholder="Enter max_cap_auditorium_live *"
                :value="item.max_cap_auditorium_live"
                @input="updateMaxCapAuditoriumLive"
              /><ValidationMessages :element=" v$.item.max_cap_auditorium_live" />
            </div>
          </div>
        </div>

        <div class="max_cap_exhibitoin_area">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_exhibitoin_area"
                >max_cap_exhibitoin_area *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_exhibitoin_area"
                placeholder="Enter max_cap_exhibitoin_area *"
                :value="item.max_cap_exhibitoin_area"
                @input="updateMaxCapExhibitoinArea"
              /><ValidationMessages :element=" v$.item.max_cap_exhibitoin_area" />
            </div>
          </div>
        </div>

        <div class="max_cap_panel_discussion">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_panel_discussion"
                >max_cap_panel_discussion *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_panel_discussion"
                placeholder="Enter max_cap_panel_discussion *"
                :value="item.max_cap_panel_discussion"
                @input="updateMaxCapPanelDiscussion"
              /><ValidationMessages :element=" v$.item.max_cap_panel_discussion" />
            </div>
          </div>
        </div>

        <div class="socket_server_address">
          <div class="row">
            <div class="col-md-2">
              <label for="socket_server_address">socket_server_address *</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="socket_server_address"
                placeholder="Enter socket_server_address *"
                :value="item.socket_server_address"
                @input="updateSocketServerAddress"
              /><ValidationMessages :element=" v$.item.socket_server_address" />
            </div>
          </div>
        </div>

        <div class="socket_server_port">
          <div class="row">
            <div class="col-md-2">
              <label for="socket_server_port">socket_server_port *</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="socket_server_port"
                placeholder="Enter socket_server_port *"
                :value="item.socket_server_port"
                @input="updateSocketServerPort"
              /><ValidationMessages :element=" v$.item.socket_server_port" />
            </div>
          </div>
        </div>
        <div class="max_cap_panel_discussion_2d">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_panel_discussion_2d"
                >max_cap_panel_discussion_2d *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_panel_discussion_2d"
                placeholder="Enter max_cap_panel_discussion_2d *"
                :value="item.max_cap_panel_discussion_2d"
                @input="updateMaxCapPanelDiscussion2d"
              /><ValidationMessages :element=" v$.item.max_cap_panel_discussion_2d" />
            </div>
          </div>
        </div>
        <div class="max_cap_roundtable_room_2d">
          <div class="row">
            <div class="col-md-2">
              <label for="max_cap_roundtable_room_2d"
                >max_cap_roundtable_room_2d *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                name="max_cap_roundtable_room_2d"
                placeholder="Enter max_cap_roundtable_room_2d *"
                :value="item.max_cap_roundtable_room_2d"
                @input="updateMaxCapRoundtableRoom2d"
              /><ValidationMessages :element=" v$.item.updateMaxCapRoundtableRoom2d" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="btn btn-primary">Save</button>

            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelClick"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <FlashMessage group="configurations_edit" />
  </form>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, ipAddress } from "@vuelidate/validators";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import _ from "lodash";
import ObjectID from "bson-objectid";

export default {
  name: "ConfigurationEditForm",
  components: {ValidationMessages},
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      moduleId: ObjectID().toHexString(),
    };
  },
  validations() {
    return {
      item: {
        grace_period: { required, numeric },
        max_cap_training_room: { required, numeric },
        max_cap_roundtable_room: { required, numeric },
        max_cap_auditorium_live: { required, numeric },
        max_cap_exhibitoin_area: { required, numeric },
        max_cap_panel_discussion: { required, numeric },
        socket_server_address: { ipAddress },
        socket_server_port: { required, numeric },
        max_cap_panel_discussion_2d: { required, numeric },
        max_cap_roundtable_room_2d: { required, numeric },
      },
    };
  },
  created() {
    this.fetchData();
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.configurations_edit;
  },
  computed: {
    ...mapGetters("ConfigurationsSingle", ["item", "is_edit", "loading"]),
  },

  methods: {
    cancelClick() {      
      this.setIsEdit(false);
    },
    ...mapActions("ConfigurationsSingle", [
      "fetchData",
      "updateData",
      "setGracePeriod",
      "setMaxCapTrainingRoom",
      "setMaxCapRoundtableRoom",
      "setMaxCapAuditoriumLive",
      "setMaxCapExhibitoinArea",
      "setMaxCapPanelDiscussion",
      "setSocketServerAddress",
      "setSocketServerPort",
      "setMaxCapPanelDiscussion2d",
      "setMaxCapRoundtableRoom2d",
      "setIsEdit",
      "resetState",
    ]),
    updateGracePeriod(e) {
      this.setGracePeriod(e.target.value);
    },
    updateMaxCapTrainingRoom(e) {
      this.setMaxCapTrainingRoom(e.target.value);
    },
    updateMaxCapRoundtableRoom(e) {
      this.setMaxCapRoundtableRoom(e.target.value);
    },
    updateMaxCapAuditoriumLive(e) {
      this.setMaxCapAuditoriumLive(e.target.value);
    },
    updateMaxCapExhibitoinArea(e) {
      this.setMaxCapExhibitoinArea(e.target.value);
    },
    updateMaxCapPanelDiscussion(e) {
      this.setMaxCapPanelDiscussion(e.target.value);
    },
    updateSocketServerAddress(e) {
      this.setSocketServerAddress(e.target.value);
    },
    updateSocketServerPort(e) {
      this.setSocketServerPort(e.target.value);
    },
    updateMaxCapPanelDiscussion2d(e) {
      this.setMaxCapPanelDiscussion2d(e.target.value);
    },
    updateMaxCapRoundtableRoom2d(e) {
      this.setMaxCapRoundtableRoom2d(e.target.value);
    },
    gettingErrorMessage(error) {
      let html = "Something went wrong";
      if (error) {
        let errors = error.response.data.data.error;
        console.log("errors", errors);
        html = "<ul>";
        for (let err of errors) {
          html += "<li>" + err.message + "</li>";
        }
        html += "</ul>";
      }

      return html;
    },
    onError() {
      this.$flashMessage.show({
        group: "company_edit",
        type: "error",
        title: "Error",
        text: "Something went wrong!",
      });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) return;

      this.updateData()
        .then(() => {
          this.setIsEdit(false);
          this.$flashMessage.show({
            group: "configurations",
            type: "success",
            title: "Success!",
            time: 1000,
            html: "<ul><li>Configurations has been updated successfully!</li></ul>",
          });
          this.fetchData();
        })

        .catch((error) => {
          let html = this.gettingErrorMessage(error);
          this.$flashMessage.show({
            group: "company_edit",
            type: "error",
            title: "Error",
            text: html,
            html: html,
          });
        });
    },
  },
};
</script>
  
  