import _ from "lodash"
import axios from "axios";
import constants from "@/config/constants";


function initialState() {
    return {
        item        : {},
        all         : [],
        loading     : false,
        is_new      : false,
        is_editing  : false,
        language_id : null
    }
}

const getters = {
    
    item        : state => state.item,
    loading     : state => state.loading,
    languagesAll: state => state.languagesAll,
    all         : state => state.all,
    is_new      : state => state.is_new,
    is_editing  : state => state.is_editing,
    language_id : state => state.language_id
  
}

const actions = {
    storeData({ commit, state, dispatch }) {
        
        
        
    },
    updateData({ commit, state, dispatch },id) {
        commit('setLoading', true)
        const params = _.clone(state.item)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        console.log("params",params);
       
        return  axios.patch(constants.API_URL + '/api/language/' + id+'/translate', params, {headers: headers})
            .then(response => {
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/language/' + id, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data.translates);
                return response;
            })
    },
    
    setLanguage({ commit }, value) {
        commit('setLanguage', value)
    },
    setLanguageId({ commit }, value) {
        commit('setLanguageId', value)
    },
    setTranslation({ commit }, value) {
        commit('setTranslation', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setItem({ commit }, value) {
        commit('setItem', value);
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setItem(state, item) {
        state.item = item
    },
    setLanguage(state, value) {
        state.item.language = value
    },
    setLanguageId(state, value) {
        state.language_id = value
    },
    setTranslation(state, value) {
        state.item.translation = value
    },
    setLanguagesAll(state, value) {
        state.languagesAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },

}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
