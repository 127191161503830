import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import { inject } from 'vue';
import { AbilityBuilder, Ability } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';


function initialState() {
    return {
        allPermissions: {},
        role:null,
        loading: false,
        isCan:false,
     

    }
}

const getters = {
    all                     :   state   => state.allPermissions,
    role                    :   state   => state.role,       
    total                   :   state   => state.all.length,
    edit_id                 :   state   => state.edit_id,
    loading                 :   state   => state.loading,
    isCan                   :   state   => state.isCan
 
}

const actions = {

    async verifyPermissions({commit,state,dispatch},eventId){
        let access_level = localStorage.getItem('access_level');
        console.log("access_level",access_level)
        if(access_level ==='event'){
           dispatch('fetchPermissionsEventId',eventId) 
        }else{
            dispatch("fetchPermissions")
        }   

    },

    async fetchPermissionsEventId({ commit, state ,dispatch}, eventId){
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/users/get-event-base-permissions/'+eventId, {headers: headers})
            .then(response => {
                commit('setAll', response.data.permissions);
                commit('setRole', response.data.role)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchPermissions({ commit, state ,dispatch}) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/users/permission/get', {headers: headers})
            .then(response => {
                commit('setAll', response.data.permissions);
                commit('setRole', response.data.role)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    checkLivePresentationsSubscrptions({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/live-presentation/check-subscription/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    checkPanelDiscussionSubscrpitions({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/panel-discussions/check-subscription/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    checkRoundTableSubscrptions({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/round-table/check-subscription/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    checkOnDemandSubscrptions({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/agenda_on_demand/check-subscription/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    checkMeetingSubscrptions({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/meetings/check-subscription/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    fetchPermissionsOnLoad({ commit, state ,dispatch},permission_type) {
        commit('setLoading', true)
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/users/permission/get', {headers: headers})
            .then(response => {
                commit('setAll', response.data.permissions);
                dispatch("verifyPermission",permission_type);
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },

    resetState({ commit }) {
        commit('resetState')
    },
    setEditId({ commit }, id) {
        commit('setEditId', id)
    },
    verifyPermission({commit},permission_type){
        commit('setisCan',permission_type)
        //this.setisCan(permission_type);
      //console.log("all",this.state.allPermissions,"permission_type",permission_type)   
    } 
}
const mutations = {
    setisCan(state,item){
        let isExists = _.indexOf(state.allPermissions, item);
        if(isExists ==  -1){
            state.isCan = false;
        }
        state.isCan = true;
    },
    setRole(state,item){
        state.role = item;
    },
    setAll(state, items) {
        state.allPermissions = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setEditId(state, id) {
        state.edit_id = id
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
