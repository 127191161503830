<template>
  <div class="snippet theme-color-snippet">
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Theme Color For Landing Page</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="primary_landing_color"
              :value="item.primary_landing_color"
              @input="updatePrimaryLMColor"/>
        </div>
      </div>
      <div class="col-lg-3">
        <label>Text Color "{{(item.primary_text_color == 'true' || item.primary_text_color == true) ? "White" : "Black" }}"</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="primary_text_color"
                        :value="item.primary_text_color"
                        id="customSwitchesRRR"
                        class="custom-control-input"
                        @change="updatePrimaryTextColor" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Theme Footer Color*</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="theme_footer_color"
              :value="item.theme_footer_color"
              @input="updateThemeFooterColor"/>
        </div>
      </div>
      <div class="col-lg-3">
        <label>Text Color "{{(item.footer_text_color == 'true' || item.footer_text_color == true) ? "White" : "Black" }}"</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="footer_text_color"
                        :value="item.footer_text_color"
                        id="customSwitchesFooter"
                        class="custom-control-input"
                        @change="updateFooterTextColor" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Time Counter Background Color*</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="counter_background_color"
              :value="item.counter_background_color"
              @input="updateCounterColor"/>
        </div>
      </div>
      <div class="col-lg-3">
        <label>Text Color "{{(item.counter_text_color == 'true' || item.counter_text_color == true) ? "White" : "Black" }}"</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="counter_text_color"
                        :value="item.counter_text_color"
                        id="customSwitchesCounter"
                        class="custom-control-input"
                        @change="updateCounterTextColor" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Common General Color*</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="common_color"
              :value="item.common_color"
              @input="updateCommonColor"/>
        </div>
      </div>
      <div class="col-lg-3">
        <label>Text Color "{{(item.common_text_color == 'true' || item.common_text_color == true) ? "White" : "Black" }}"</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="common_text_color"
                        :value="item.common_text_color"
                        id="customSwitchesCommon"
                        class="custom-control-input"
                        @change="updateCommonTextColor" />
        </div>
      </div>
    </div>
  </div>
  <div class="snippet theme-color-snippet">
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Theme Logo Header Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="theme_header_color"
              placeholder="Enter color code "
              :value="item.theme_header_color"
              @input="updateThemeHeaderColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Theme Background Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="theme_background_color"
              placeholder="Enter color code "
              :value="item.theme_background_color"
              @input="updateThemeBackgroundColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Theme Primary Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="primary_color"
              placeholder="Enter color code"
              :value="item.primary_color"
              @input="updatePrimaryColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Menu Text Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="menu_text_color"
              placeholder="Enter color code"
              :value="item.menu_text_color"
              @input="updateMenuTextColor" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Booth Box Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="theme_booth_box_color"
              placeholder="Enter color code"
              :value="item.theme_booth_box_color"
              @input="updateThemeBoothBoxColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Schedule Box Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="theme_schedule_box_color"
              placeholder="Enter color code"
              :value="item.theme_schedule_box_color"
              @input="updateThemeScheduleBoxColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Logo Background Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="logo_background_color"
              placeholder="Enter color code"
              :value="item.logo_background_color"
              @input="updateLogoBackgroundColor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Register/Login Button Color</label>
        <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                        Enter hexa color code e.g #abc234
                                      </template>
                                    </VPopper>
        <div class="input-group color" data-plugin-colorpicker>
          <input
              type="color"
              class="form-control"
              name="register_button_color"
              placeholder="Enter color code"
              :value="item.register_button_color"
              @input="updateRegisterButtonColor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
import InputSwitch from "@/components/Commons/InputSwitch";

export default {
  name: "ThemeColorSnippet",
  components: {InputSwitch},
  computed: {
    ...mapGetters("EventsSingle", [
      "item",
      "loading",
    ]),
 
  },
  mounted() {
    const switchers = document.querySelectorAll('.theme-color-snippet input[data-plugin-ios-switch]')
    switchers.forEach(item => {const elem = new window.theme.PluginIOS7Switch($(item)) })

    // const switchers = document.querySelectorAll('.theme-color-snippet div[data-plugin-colorpicker]')
    // switchers.forEach(item => {const elem = new window.theme.PluginColorPicker($(item)) })
  },
  methods: {
    ...mapActions("EventsSingle", [
      "setTheme",
      "setPrimaryColor",
      "setPrimaryLandingColor",
      "setThemeScheduleBoxColor",
      "setLogoBackgroundColor",
      "setMenuTextColor",
      "setThemeBoothBoxColor",
      "setThemeHeaderColor",
      "setThemeFooterColor",
      "setThemeBackgroundColor",
      "setCounterColor",
      "setRegisterButtonColor",
      "setCommonColor",
      "setPrimaryTextColor",
      "setFooterTextColor",
      "setCounterTextColor",
      "setCommonTextColor",
    ]),
    updatePrimaryLMColor(e) {
      this.setPrimaryLandingColor(e.target.value);
    },
    updatePrimaryTextColor(e) {
      this.setPrimaryTextColor(e.target.checked)
    },
    updateThemeHeaderColor(e) {
      this.setThemeHeaderColor(e.target.value);
    },
    updateThemeFooterColor(e) {
      this.setThemeFooterColor(e.target.value);
    },
    updateFooterTextColor(e) {
      this.setFooterTextColor(e.target.checked);
    },
    updateCounterTextColor(e) {
      this.setCounterTextColor(e.target.checked)
    },
    updateCommonTextColor(e) {
      this.setCommonTextColor(e.target.checked)
    },
    updateThemeBackgroundColor(e) {
      this.setThemeBackgroundColor(e.target.value);
    },
    updateThemeBoothBoxColor(e) {
      this.setThemeBoothBoxColor(e.target.value);
    },
    updateThemeScheduleBoxColor(e) {
      this.setThemeScheduleBoxColor(e.target.value);
    },
    updateLogoBackgroundColor(e) {
      this.setLogoBackgroundColor(e.target.value);
    },
    updateMenuTextColor(e) {
      this.setMenuTextColor(e.target.value);
    },
    updateCounterColor(e) {
      this.setCounterColor(e.target.value);
    },
    updateCommonColor(e) {
      this.setCommonColor(e.target.value);
    },
    updateRegisterButtonColor(e) {
      this.setRegisterButtonColor(e.target.value);
    },
    updatePrimaryColor(e) {
      this.setPrimaryColor(e.target.value);
    },
  },
}
</script>

<style scoped>

.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

</style>