<template>
    <button class="newbtn" @click="createClick" v-if="is_new === false">Add Additional Host</button>
    <button class="newbtn" @click="cancelClick" v-else>Cancel</button>
          <div class="snippet general-event-config-snippet">
              <TogglePrimary v-if="is_new">
                <MeetingHostCreate   :meetingId="meetingId" />
              
                </TogglePrimary>
                <TogglePrimary v-for="(host, index) in all" :key="index">
                <template v-slot:label >
                    <span class="labelwrapper" >
                        <span class="col1"><label>Host</label> {{host.host_id.first_name}} {{host.host_id.last_name}}</span>
                        <span class="col2"><label>Owner</label> {{host.is_owner?"Yes":"No"}}</span>
                        <span class="col3"><label>Moderator</label> {{host.is_moderator?"Yes":"No"}}</span>
                    </span>
                </template>
                <div id ="edit-content">
                    <MeetingHostEdit   v-if="is_new === false && is_editing === true && host._id === item._id" :host="host" :meetingId="meetingId" />
                    <MeetingHostShow    :presentation="host" :id="host._id"  :host=" loadHost(host.host_id)"  :meetingId="meetingId" v-else/>
      
                </div><!-- End Content-->
      

        </TogglePrimary>
                
            </div><!-- End of snippet-->
 
              
    <FlashMessage group="meeting_host_index" />
</template>
<script>
import MeetingHostEdit      from "@/components/cruds/Meetings/Hosts/Edit";
import MeetingHostShow      from "@/components/cruds/Meetings/Hosts/Show";
import MeetingHostCreate    from "@/components/cruds/Meetings/Hosts/Create";
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters,mapActions,mapState} from 'vuex'
import TogglePrimary from "@/components/Commons/TogglePrimary";
import InputSwitch from "@/components/Commons/InputSwitch";
import moment from 'moment';
import _ from "lodash";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import constants from "@/config/constants";
import ObjectID from 'bson-objectid';

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
name: "MeetingsIndex",
components: {
 // ValidationMessages,
  TogglePrimary,
  //InputSwitch,
  MeetingHostEdit,
  MeetingHostCreate,
  MeetingHostShow
  
},
setup() {
      return {
          v$: useVuelidate()
      }
  },
 
data() {
  return {
    moduleId: ObjectID(),
    IMAGE_URL: constants.IMAGE_URL
  
  }
},
  props: {
      
      event_timezone:null,
      meetingId:null

  },
  validations() {
      return {
          item: {
             
          }
      }
  },
  mounted() {
      this.fetchAttendeeList(this.$route.params.event_id);
      this.fetchMeetingById(this.meetingId);
    },
  beforeUnmount() {
      this.resetState()
      delete this.$flashMessage.groups.meeting_host_index;
      delete this.$flashMessage.groups.meeting_host_create;
      
  },
  computed:{
   
    ...mapGetters('MeetingHostsSingle', ['is_new', "is_editing","item"]),
    ...mapGetters('RoomSessionsIndex', ["attendeeList"]),
    ...mapGetters('MeetingHostsIndex', ["all"]),

  },
  methods: {
            ...mapActions('RoomSessionsIndex', {fetchAttendeeList:"fetchAttendeeList"}),
            ...mapActions('MeetingHostsIndex', {fetchMeetingById:"fetchMeetingById"}),
            ...mapActions('MeetingHostsSingle',
        [
            "setIsNew",
            "setIsEditing",
            "updateData",
            "resetState",
            "destroyData",
        ]),
        
       
        createClick() {
            this.setIsNew(true);
            this.setIsEditing(false);
        },
        cancelClick() {
            this.resetState();
        },
        loadHost(speaker){
            if(_.isObject(speaker)){
              return ` ${speaker.first_name} ${speaker.last_name}`;
            }

            if (_.isString(speaker)) {
              let speakerObj = _.find(this.speakers, { 'id': speaker });
              return _.get(speakerObj, 'name', 'No Host Assigned')
            }

          return "No Host Assigned";
        }
       
  }
 
}
</script>
<style lang="scss" scoped>
.booth_hd {
  color: white;
  background: linear-gradient(90deg, #0058dd, #20abe6);
  padding: 9px 15px;
  text-align: center;
}

.scrollbar_booth {
  width: 100%;
  height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1896cb;
    outline: none;
    border: none;
  }
}

.force-overflow {
  min-height: 260px;
}

.booth_data_wrapper {
  border: 1px solid #efefef;
  background: #efefef85;
  padding: 0 15px;
}
.labelwrapper {
  display: flex;
  justify-content: space-around;
}

.labelwrapper label {
  display: block;
}

.toggle.active i.fa-minus {
  display: none !important;
}

.toggle>label {
  background: #fff !important;
}

.fas .fa-minus {
  display: none !important;
}

.v-dialog>.v-overlay__content>.v-card {
  border-radius: 15px;
  padding-top: 20px;
}

html .toggle-primary .toggle.active>label .labelwrapper span label {
  color: #ffffff !important;
}

.question_wrapper {
  background: #efefef;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.question {
  font-weight: bold;
  margin-bottom: 10px;
}

.question_action {
  position: absolute;
  top: 50%;
  right: 50px;
}

.question_edit {
  background: #1896e3;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;
}

.question_delete {
  background: #fff;
  color: #5a5757;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;

}

.newbtn {
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 5px;
}
.force-overflow {
min-height: 260px;
}

.booth_data_wrapper {
border: 1px solid #efefef;
background: #efefef85;
padding: 0 15px;
}

.content_left_panel {
  position: relative;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
      text-decoration: none;
      color: inherit;
      display: block;
      font-size: 15px;

      &:first-child {
        padding-top: 0;
      }

      span {
        color: inherit;
        font-weight: bold;
      }
    }
  }
}
</style>
