import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
    
        item: {
            _id: null,
            show_videos:false,
            booth_id: null,
            logo1: null,
            logo2: null,
            logo3: null,
            logo4: null,
            logo5: null,
            logo6: null,
            logo7: null,
            logo8: null,
            logo9: null,
            logo10: null,
            booth_logo_delete: 0,
            logo1_delete: 0,
            logo2_delete: 0,
            logo3_delete: 0,
            logo4_delete: 0,
            logo5_delete: 0,
            logo6_delete: 0,
            logo7_delete: 0,
            logo8_delete: 0,
            logo9_delete: 0,
            logo10_delete: 0,
            screen_video1_delete: 0,
            screen_video2_delete: 0,
            screen_document1_delete: 0,
            screen_document2_delete: 0,
            screen_document1: null,
            screen_video1: null,
            screen_video2: null,
            document1: null,
            document2: null,
            document3: null,
            document4: null,
            document5: null,
            document6: null,
            document7: null,
            document8: null,
            document9: null,
            document10: null,
            document11: null,
            document12: null,
            document13: null,
            document14: null,
            document15: null,
            presentation1: null,
            presentation2: null,
            presentation1_delete: 0,
            presentation2_delete: 0,
            document1_delete: 0,
            document2_delete: 0,
            document3_delete: 0,
            document4_delete: 0,
            document5_delete: 0,
            document6_delete: 0,
            document7_delete: 0,
            document8_delete: 0,
            document9_delete: 0,
            document10_delete: 0,
            document11_delete: 0,
            document12_delete: 0,
            document13_delete: 0,
            document14_delete: 0,
            document15_delete: 0,
            show_text: false,
            disable_download_video1: false,
            disable_download_screen_document1: false,
            disable_download_video2: false,
            disable_download_screen_document2: false,
            disable_download_presentation1: false,
            disable_download_presentation2: false,
            disable_download_document1: false,
            disable_download_document2: false,
            disable_download_document3: false,
            disable_download_document4: false,
            disable_download_document5: false,
            disable_download_document6: false,
            disable_download_document7: false,
            disable_download_document8: false,
            disable_download_document9: false,
            disable_download_document10: false,
            disable_download_document11: false,
            disable_download_document12: false,
            disable_download_document13: false,
            disable_download_document14: false,
            disable_download_document15: false,
            booth_logo:null,
            description: null,
            presets:{
                no_of_documents:null,
                no_of_logos:null,
                no_of_presentations:null,
                no_of_videos:null

            }

        },
        
        
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
 
}

const actions = {

    updateData({ commit, state, dispatch }, booth_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.presets;
        delete params.status;
        if (params.screen_video1) {
            params.screen_video1 = {
                old_key     : state.item.screen_video1.key,
                key         : state.item.screen_video1.key,
                temp_key    :  state.item.screen_video1.temp_key,
                size        : state.item.screen_video1.size,
                extension   : state.item.screen_video1.extension,
                uploadedBy  : state.item.screen_video1.uploadedBy,
                uploadedAt  : state.item.screen_video1.uploadedAt,
            }
        }

        if (params.screen_video2) {
            params.screen_video2 = {
                old_key     : state.item.screen_video2.key,
                key         : state.item.screen_video2.key,
                temp_key    :  state.item.screen_video2.temp_key,
                size        : state.item.screen_video2.size,
                extension   : state.item.screen_video2.extension,
                uploadedBy  : state.item.screen_video2.uploadedBy,
                uploadedAt  : state.item.screen_video2.uploadedAt,
            }
        }


        if (params.screen_document1) {
            params.screen_document1 = {
                old_key     : state.item.screen_document1.key,
                key         : state.item.screen_document1.key,
                temp_key    :  state.item.screen_document1.temp_key,
                size        : state.item.screen_document1.size,
                extension   : state.item.screen_document1.extension,
                uploadedBy  : state.item.screen_document1.uploadedBy,
                uploadedAt  : state.item.screen_document1.uploadedAt,
            }
        }

        if (params.screen_document2) {
            params.screen_document2 = {
                old_key     : state.item.screen_document2.key,
                key         : state.item.screen_document2.key,
                temp_key    : state.item.screen_document2.temp_key,
                size        : state.item.screen_document2.size,
                extension   : state.item.screen_document2.extension,
                uploadedBy  : state.item.screen_document2.uploadedBy,
                uploadedAt  : state.item.screen_document2.uploadedAt,
            }
        }

        if (params.document1) {
            params.document1 = {
                old_key     : state.item.document1.key,
                key         : state.item.document1.key,
                temp_key    :  state.item.document1.temp_key,
                size        : state.item.document1.size,
                extension   : state.item.document1.extension,
                uploadedBy  : state.item.document1.uploadedBy,
                uploadedAt  : state.item.document1.uploadedAt,
            }
        }

        if (params.document2) {
            params.document2 = {
                old_key     : state.item.document2.key,
                key         : state.item.document2.key,
                temp_key    :  state.item.document2.temp_key,
                size        : state.item.document2.size,
                extension   : state.item.document2.extension,
                uploadedBy  : state.item.document2.uploadedBy,
                uploadedAt  : state.item.document2.uploadedAt,
            }
        }


        if (params.document3) {
            params.document3 = {
                old_key     : state.item.document3.key,
                key         : state.item.document3.key,
                temp_key    :  state.item.document3.temp_key,
                size        : state.item.document3.size,
                extension   : state.item.document3.extension,
                uploadedBy  : state.item.document3.uploadedBy,
                uploadedAt  : state.item.document3.uploadedAt,
            }
        }

        if (params.document4) {
            params.document4 = {
                old_key     : state.item.document4.key,
                key         : state.item.document4.key,
                temp_key    :  state.item.document4.temp_key,
                size        : state.item.document4.size,
                extension   : state.item.document4.extension,
                uploadedBy  : state.item.document4.uploadedBy,
                uploadedAt  : state.item.document4.uploadedAt,
            }
        }

        if (params.document5) {
            params.document5 = {
                old_key     : state.item.document5.key,
                key         : state.item.document5.key,
                temp_key    :  state.item.document5.temp_key,
                size        : state.item.document5.size,
                extension   : state.item.document5.extension,
                uploadedBy  : state.item.document5.uploadedBy,
                uploadedAt  : state.item.document5.uploadedAt,
            }
        }

        if (params.document6) {
            params.document6 = {
                old_key     : state.item.document6.key,
                key         : state.item.document6.key,
                temp_key    :  state.item.document6.temp_key,
                size        : state.item.document6.size,
                extension   : state.item.document6.extension,
                uploadedBy  : state.item.document6.uploadedBy,
                uploadedAt  : state.item.document6.uploadedAt,
            }
        }

        if (params.document7) {
            params.document7 = {
                old_key     : state.item.document7.key,
                key         : state.item.document7.key,
                temp_key    :  state.item.document7.temp_key,
                size        : state.item.document7.size,
                extension   : state.item.document7.extension,
                uploadedBy  : state.item.document7.uploadedBy,
                uploadedAt  : state.item.document7.uploadedAt,
            }
        }

        if (params.document8) {
            params.document8 = {
                old_key     : state.item.document8.key,
                key         : state.item.document8.key,
                temp_key    :  state.item.document8.temp_key,
                size        : state.item.document8.size,
                extension   : state.item.document8.extension,
                uploadedBy  : state.item.document8.uploadedBy,
                uploadedAt  : state.item.document8.uploadedAt,
            }
        }

        if (params.document9) {
            params.document9 = {
                old_key     : state.item.document9.key,
                key         : state.item.document9.key,
                temp_key    :  state.item.document9.temp_key,
                size        : state.item.document9.size,
                extension   : state.item.document9.extension,
                uploadedBy  : state.item.document9.uploadedBy,
                uploadedAt  : state.item.document9.uploadedAt,
            }
        }

        if (params.document10) {
            params.document10 = {
                old_key     : state.item.document10.key,
                key         : state.item.document10.key,
                temp_key    :  state.item.document10.temp_key,
                size        : state.item.document10.size,
                extension   : state.item.document10.extension,
                uploadedBy  : state.item.document10.uploadedBy,
                uploadedAt  : state.item.document10.uploadedAt,
            }
        }
        if (params.document11) {
            params.document11 = {
                old_key     : state.item.document11.key,
                key         : state.item.document11.key,
                temp_key    :  state.item.document11.temp_key,
                size        : state.item.document11.size,
                extension   : state.item.document11.extension,
                uploadedBy  : state.item.document11.uploadedBy,
                uploadedAt  : state.item.document11.uploadedAt,
            }
        }
        if (params.document12) {
            params.document12 = {
                old_key     : state.item.document12.key,
                key         : state.item.document12.key,
                temp_key    :  state.item.document12.temp_key,
                size        : state.item.document12.size,
                extension   : state.item.document12.extension,
                uploadedBy  : state.item.document12.uploadedBy,
                uploadedAt  : state.item.document12.uploadedAt,
            }
        }

        if (params.document13) {
            params.document13 = {
                old_key     : state.item.document13.key,
                key         : state.item.document13.key,
                temp_key    :  state.item.document13.temp_key,
                size        : state.item.document13.size,
                extension   : state.item.document13.extension,
                uploadedBy  : state.item.document13.uploadedBy,
                uploadedAt  : state.item.document13.uploadedAt,
            }
        }

        if (params.document14) {
            params.document14 = {
                old_key     : state.item.document14.key,
                key         : state.item.document14.key,
                temp_key    :  state.item.document14.temp_key,
                size        : state.item.document14.size,
                extension   : state.item.document14.extension,
                uploadedBy  : state.item.document14.uploadedBy,
                uploadedAt  : state.item.document14.uploadedAt,
            }
        }

        if (params.document15) {
            params.document15 = {
                old_key     : state.item.document15.key,
                key         : state.item.document15.key,
                temp_key    :  state.item.document15.temp_key,
                size        : state.item.document15.size,
                extension   : state.item.document15.extension,
                uploadedBy  : state.item.document15.uploadedBy,
                uploadedAt  : state.item.document15.uploadedAt,
            }
        }

        if (params.booth_logo) {
            params.booth_logo = {
                old_key     : state.item.booth_logo.key,
                key         : state.item.booth_logo.key,
                temp_key    :  state.item.booth_logo.temp_key,
                size        : state.item.booth_logo.size,
                extension   : state.item.booth_logo.extension,
                uploadedBy  : state.item.booth_logo.uploadedBy,
                uploadedAt  : state.item.booth_logo.uploadedAt,
            }
        }

        if (params.logo1) {
            params.logo1 = {
                old_key     : state.item.logo1.key,
                key         : state.item.logo1.key,
                temp_key    :  state.item.logo1.temp_key,
                size        : state.item.logo1.size,
                extension   : state.item.logo1.extension,
                uploadedBy  : state.item.logo1.uploadedBy,
                uploadedAt  : state.item.logo1.uploadedAt,
            }
        }

        if (params.logo2) {
            params.logo2 = {
                old_key     : state.item.logo2.key,
                key         : state.item.logo2.key,
                temp_key    :  state.item.logo2.temp_key,
                size        : state.item.logo2.size,
                extension   : state.item.logo2.extension,
                uploadedBy  : state.item.logo2.uploadedBy,
                uploadedAt  : state.item.logo2.uploadedAt,
            }
        }

        if (params.logo3) {
            params.logo3 = {
                old_key     : state.item.logo3.key,
                key         : state.item.logo3.key,
                temp_key    :  state.item.logo3.temp_key,
                size        : state.item.logo3.size,
                extension   : state.item.logo3.extension,
                uploadedBy  : state.item.logo3.uploadedBy,
                uploadedAt  : state.item.logo3.uploadedAt,
            }
        }

        if (params.logo4) {
            params.logo4 = {
                old_key     : state.item.logo4.key,
                key         : state.item.logo4.key,
                temp_key    :  state.item.logo4.temp_key,
                size        : state.item.logo4.size,
                extension   : state.item.logo4.extension,
                uploadedBy  : state.item.logo4.uploadedBy,
                uploadedAt  : state.item.logo4.uploadedAt,
            }
        }

        if (params.logo5) {
            params.logo5 = {
                old_key     : state.item.logo5.key,
                key         : state.item.logo5.key,
                temp_key    :  state.item.logo5.temp_key,
                size        : state.item.logo5.size,
                extension   : state.item.logo5.extension,
                uploadedBy  : state.item.logo5.uploadedBy,
                uploadedAt  : state.item.logo5.uploadedAt,
            }
        }

        if (params.logo6) {
            params.logo6 = {
                old_key     : state.item.logo6.key,
                key         : state.item.logo6.key,
                temp_key    :  state.item.logo6.temp_key,
                size        : state.item.logo6.size,
                extension   : state.item.logo6.extension,
                uploadedBy  : state.item.logo6.uploadedBy,
                uploadedAt  : state.item.logo6.uploadedAt,
            }
        }

        if (params.logo7) {
            params.logo7 = {
                old_key     : state.item.logo7.key,
                key         : state.item.logo7.key,
                temp_key    :  state.item.logo7.temp_key,
                size        : state.item.logo7.size,
                extension   : state.item.logo7.extension,
                uploadedBy  : state.item.logo7.uploadedBy,
                uploadedAt  : state.item.logo7.uploadedAt,
            }
        }

        if (params.logo8) {
            params.logo8 = {
                old_key     : state.item.logo8.key,
                key         : state.item.logo8.key,
                temp_key    :  state.item.logo8.temp_key,
                size        : state.item.logo8.size,
                extension   : state.item.logo8.extension,
                uploadedBy  : state.item.logo8.uploadedBy,
                uploadedAt  : state.item.logo8.uploadedAt,
            }
        }

        if (params.logo9) {
            params.logo9 = {
                old_key     : state.item.logo9.key,
                key         : state.item.logo9.key,
                temp_key    :  state.item.logo9.temp_key,
                size        : state.item.logo9.size,
                extension   : state.item.logo9.extension,
                uploadedBy  : state.item.logo9.uploadedBy,
                uploadedAt  : state.item.logo9.uploadedAt,
            }
        }

        if (params.logo10) {
            params.logo10 = {
                old_key     : state.item.logo10.key,
                key         : state.item.logo10.key,
                temp_key    :  state.item.logo10.temp_key,
                size        : state.item.logo10.size,
                extension   : state.item.logo10.extension,
                uploadedBy  : state.item.logo10.uploadedBy,
                uploadedAt  : state.item.logo10.uploadedAt,
            }
        }

        if (params.presentation1) {
            params.presentation1 = {
                old_key     : state.item.presentation1.key,
                key         : state.item.presentation1.key,
                temp_key    :  state.item.presentation1.temp_key,
                size        : state.item.presentation1.size,
                extension   : state.item.presentation1.extension,
                uploadedBy  : state.item.presentation1.uploadedBy,
                uploadedAt  : state.item.presentation1.uploadedAt,
            }
        }

        if (params.presentation2) {
            params.presentation2 = {
                old_key     : state.item.presentation2.key,
                key         : state.item.presentation2.key,
                temp_key    :  state.item.presentation2.temp_key,
                size        : state.item.presentation2.size,
                extension   : state.item.presentation2.extension,
                uploadedBy  : state.item.presentation2.uploadedBy,
                uploadedAt  : state.item.presentation2.uploadedAt,
            }
        }

        

        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        console.log("params",params);
        return axios.patch(constants.API_URL + '/api/booth/' + booth_id , params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            })
            
        
    },
    fetchData({ commit, dispatch },id) {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/booth/' + id, {headers: headers})
        .then(response => {
            commit('setItem', response.data.data)
            return response;
        })
        .catch(error => {
            console.log("Error",error);
            
        })
    },
    fetchEventsAll({ commit }, event_id) {
        //debugger;
        commit('setEvent', event_id)
    },
    getEventTimeZone({ commit, state, dispatch }, event_id) {
        axios.get('/api/v1/event_details/' + event_id)
            .then(response => {
                //state.event_timezone = response.data.data[0].time_zone
                commit('setEventType', response.data.data[0].event_type)
                state.event_type = response.data.data[0].event_type
                // if(state.event_type == 'virtual_exhibition' && state.item.is_live && state.item.location == "" ){
                //     state.item.location = 'Auditorium Live'
                // }
                // moment.tz.setDefault(state.event_timezone);
                // console.log(state.event_timezone)

            })

    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setShowText({ commit }, value) {
        commit('setShowText', value)
    },
    setShowVideos({ commit }, value) {
        commit('setShowVideos', value)
    },
    setDisableDownloadVideo1({ commit }, value) {
        commit('setDisableDownloadVideo1', value)
    },
    setDisableDownloadScreenDocument1({ commit }, value) {
        commit('setDisableDownloadScreenDocument1', value)
    },
    setDisableDownloadVideo2({ commit }, value) {
        commit('setDisableDownloadVideo2', value)
    },
    setDisableDownloadScreenDocument2({ commit }, value) {
        commit('setDisableDownloadScreenDocument2', value)
    },
    setDisableDownloadPresentation1({ commit }, value) {
        commit('setDisableDownloadPresentation1', value)
    },
    setDisableDownloadPresentation2({ commit }, value) {
        commit('setDisableDownloadPresentation2', value)
    },
    setDisableDownloadDocument1({ commit }, value) {
        commit('setDisableDownloadDocument1', value)
    },
    setDisableDownloadDocument2({ commit }, value) {
        commit('setDisableDownloadDocument2', value)
    },
    setDisableDownloadDocument3({ commit }, value) {
        commit('setDisableDownloadDocument3', value)
    },
    setDisableDownloadDocument4({ commit }, value) {
        commit('setDisableDownloadDocument4', value)
    },
    setDisableDownloadDocument5({ commit }, value) {
        commit('setDisableDownloadDocument5', value)
    },
    setDisableDownloadDocument6({ commit }, value) {
        commit('setDisableDownloadDocument6', value)
    },
    setDisableDownloadDocument7({ commit }, value) {
        commit('setDisableDownloadDocument7', value)
    },
    setDisableDownloadDocument8({ commit }, value) {
        commit('setDisableDownloadDocument8', value)
    },
    setDisableDownloadDocument9({ commit }, value) {
        commit('setDisableDownloadDocument9', value)
    },
    setDisableDownloadDocument10({ commit }, value) {
        commit('setDisableDownloadDocument10', value)
    },
    setDisableDownloadDocument11({ commit }, value) {
        commit('setDisableDownloadDocument11', value)
    },
    setDisableDownloadDocument12({ commit }, value) {
        commit('setDisableDownloadDocument12', value)
    },
    setDisableDownloadDocument13({ commit }, value) {
        commit('setDisableDownloadDocument13', value)
    },
    setDisableDownloadDocument14({ commit }, value) {
        commit('setDisableDownloadDocument14', value)
    },
    setDisableDownloadDocument15({ commit }, value) {
        commit('setDisableDownloadDocument15', value)
    },
    setEvent({ commit }, value) {

        commit('setEvent', value)
    },
    setBooth({ commit }, value) {

        commit('setBooth', value)
    },
    setSkybox({ commit }, value) {
        commit('setSkybox', value)
    },
    setEnvironment({ commit }, value) {
        commit('setEnvironment', value)
    },
    setAuditorium_live({ commit }, value) {
        commit('setAuditorium_live', value)
    },
    setFlag1({ commit }, value) {
        commit('setFlag1', value)
    },
    setFlag2({ commit }, value) {
        commit('setFlag2', value)
    },
    setFlag3({ commit }, value) {
        commit('setFlag3', value)
    },
    setFlag4({ commit }, value) {
        commit('setFlag4', value)
    },
    setFlag5({ commit }, value) {
        commit('setFlag5', value)
    },
    setFlag6({ commit }, value) {
        commit('setFlag6', value)
    },
    setFlag7({ commit }, value) {
        commit('setFlag7', value)
    },
    setFlag8({ commit }, value) {
        commit('setFlag8', value)
    },
    setFlag9({ commit }, value) {
        commit('setFlag9', value)
    },
    setFlag10({ commit }, value) {
        commit('setFlag10', value)
    },
    setFlag11({ commit }, value) {
        commit('setFlag11', value)
    },
    setFlag12({ commit }, value) {
        commit('setFlag12', value)
    },
    setFlag13({ commit }, value) {
        commit('setFlag13', value)
    },
    setFlag14({ commit }, value) {
        commit('setFlag14', value)
    },
    setFlag15({ commit }, value) {
        commit('setFlag15', value)
    },
    setFlag16({ commit }, value) {
        commit('setFlag16', value)
    },
    setFlag17({ commit }, value) {
        commit('setFlag17', value)
    },
    setFlag18({ commit }, value) {
        commit('setFlag18', value)
    },
    setFlag19({ commit }, value) {
        commit('setFlag19', value)
    },
    setFlag20({ commit }, value) {
        commit('setFlag20', value)
    },
    setFlag21({ commit }, value) {
        commit('setFlag21', value)
    },
    setFlag22({ commit }, value) {
        commit('setFlag22', value)
    },
    setFlag23({ commit }, value) {
        commit('setFlag23', value)
    },
    setFlag24({ commit }, value) {
        commit('setFlag24', value)
    },
    setFlag25({ commit }, value) {
        commit('setFlag25', value)
    },
    setFlag26({ commit }, value) {
        commit('setFlag26', value)
    },
    setFlag27({ commit }, value) {
        commit('setFlag27', value)
    },
    setFlag28({ commit }, value) {
        commit('setFlag28', value)
    },
    setFlag29({ commit }, value) {
        commit('setFlag29', value)
    },
    setFlag30({ commit }, value) {
        commit('setFlag30', value)
    },
    setFlag31({ commit }, value) {
        commit('setFlag31', value)
    },
    setFlag32({ commit }, value) {
        commit('setFlag32', value)
    },
    setFlag33({ commit }, value) {
        commit('setFlag33', value)
    },
    setFlag34({ commit }, value) {
        commit('setFlag34', value)
    },
    setFlag35({ commit }, value) {
        commit('setFlag35', value)
    },
    setFlag36({ commit }, value) {
        commit('setFlag36', value)
    },
    setFlag37({ commit }, value) {
        commit('setFlag37', value)
    },
    setFlag38({ commit }, value) {
        commit('setFlag38', value)
    },
    setFlag39({ commit }, value) {
        commit('setFlag39', value)
    },
    setFlag40({ commit }, value) {
        commit('setFlag40', value)
    },
    setFlag41({ commit }, value) {
        commit('setFlag41', value)
    },
    setFlag42({ commit }, value) {
        commit('setFlag42', value)
    },
    setFlag43({ commit }, value) {
        commit('setFlag43', value)
    },
    setFlag44({ commit }, value) {
        commit('setFlag44', value)
    },
    setFlag45({ commit }, value) {
        commit('setFlag45', value)
    },
    setFlag46({ commit }, value) {
        commit('setFlag46', value)
    },
    setFlag47({ commit }, value) {
        commit('setFlag47', value)
    },
    setFlag48({ commit }, value) {
        commit('setFlag48', value)
    },
    setFlag49({ commit }, value) {
        commit('setFlag49', value)
    },
    setFlag50({ commit }, value) {
        commit('setFlag50', value)
    },
    setBannerLong1({ commit }, value) {
        commit('setBannerLong1', value)
    },
    setBannerLong2({ commit }, value) {
        commit('setBannerLong2', value)
    },
    setBannerLong3({ commit }, value) {
        commit('setBannerLong3', value)
    },
    setBannerLong8({ commit }, value) {
        commit('setBannerLong8', value)
    },
    setBannerLong4({ commit }, value) {
        commit('setBannerLong4', value)
    },
    setBannerLong5({ commit }, value) {
        commit('setBannerLong5', value)
    },
    setBannerLong6({ commit }, value) {
        commit('setBannerLong6', value)
    },
    setBannerLong7({ commit }, value) {
        commit('setBannerLong7', value)
    },
    setBannerMedium1({ commit }, value) {
        commit('setBannerMedium1', value)
    },
    setBannerMedium2({ commit }, value) {
        commit('setBannerMedium2', value)
    },
    setBannerMedium3({ commit }, value) {
        commit('setBannerMedium3', value)
    },
    setBannerMedium4({ commit }, value) {
        commit('setBannerMedium4', value)
    },
    setBannerMedium5({ commit }, value) {
        commit('setBannerMedium5', value)
    },
    setBannerMedium6({ commit }, value) {
        commit('setBannerMedium6', value)
    },
    setBannerMedium7({ commit }, value) {
        commit('setBannerMedium7', value)
    },
    setBannerMedium8({ commit }, value) {
        commit('setBannerMedium8', value)
    },
    setBoothLogo({ commit }, value) {
        commit('setBoothLogo', value)
    },
    setLogo1({ commit }, value) {
        commit('setLogo1', value)
    },
    setLogo2({ commit }, value) {
        commit('setLogo2', value)
    },
    setLogo3({ commit }, value) {
        commit('setLogo3', value)
    },
    setLogo4({ commit }, value) {
        commit('setLogo4', value)
    },
    setLogo5({ commit }, value) {
        commit('setLogo5', value)
    },
    setLogo6({ commit }, value) {
        commit('setLogo6', value)
    },
    setLogo7({ commit }, value) {
        commit('setLogo7', value)
    },
    setLogo8({ commit }, value) {
        commit('setLogo8', value)
    },
    setLogo9({ commit }, value) {
        commit('setLogo9', value)
    },
    setLogo10({ commit }, value) {
        commit('setLogo10', value)
    },

    setAuditorium_pre_recorded({ commit }, value) {
        commit('setAuditorium_pre_recorded', value)
    },

    setAuditorium_panel_discussion({ commit }, value) {
        commit('setAuditorium_panel_discussion', value)
    },

    setExhibition_area({ commit }, value) {
        commit('setExhibition_area', value)
    },

    setVirtual_training({ commit }, value) {
        commit('setVirtual_training', value)
    },
    setRoundtable({ commit }, value) {
        commit('setRoundtable', value)
    },

    setVirtual_meeting({ commit }, value) {
        commit('setVirtual_meeting', value)
    },
    setAuditorium_live_label({ commit }, value) {
        commit('setAuditorium_live_label', value)
    },
    setScrollingText({ commit }, value) {
        commit('setScrollingText', value)
    },

    setAuditorium_pre_recorded_label({ commit }, value) {
        commit('setAuditorium_pre_recorded_label', value)
    },

    setAuditorium_panel_discussion_label({ commit }, value) {
        commit('setAuditorium_panel_discussion_label', value)
    },

    setExhibition_area_label({ commit }, value) {
        commit('setExhibition_area_label', value)
    },

    setVirtual_training_label({ commit }, value) {
        commit('setVirtual_training_label', value)
    },
    setRoundtable_label({ commit }, value) {
        commit('setRoundtable_label', value)
    },

    setVirtual_meeting_label({ commit }, value) {
        commit('setVirtual_meeting_label', value)
    },

    setExhibition_video({ commit }, value) {
        commit('setExhibition_video', value)
    },
    setScreen_video1({ commit }, value) {
        commit('setScreen_video1', value)
    },
    setScreen_video2({ commit }, value) {
        commit('setScreen_video2', value)
    },
    setScreen_video3({ commit }, value) {
        commit('setScreen_video3', value)
    },
    setScreen_video4({ commit }, value) {
        commit('setScreen_video4', value)
    },
    setScreen_video5({ commit }, value) {
        commit('setScreen_video5', value)
    },
    setScreen_video6({ commit }, value) {
        commit('setScreen_video6', value)
    },
    setScreen_video7({ commit }, value) {
        commit('setScreen_video7', value)
    },

    setExhibition_document({ commit }, value) {
        commit('setExhibition_document', value)
    },
    setScreen_document1({ commit }, value) {
        commit('setScreen_document1', value)
    },
    setPresentation1({ commit }, value) {
        commit('setPresentation1', value)
    },
    setPresentation2({ commit }, value) {
        commit('setPresentation2', value)
    },
    setDocument1({ commit }, value) {
        commit('setDocument1', value)
    },
    setDocument2({ commit }, value) {
        commit('setDocument2', value)
    },
    setDocument3({ commit }, value) {
        commit('setDocument3', value)
    },
    setDocument4({ commit }, value) {
        commit('setDocument4', value)
    },
    setDocument5({ commit }, value) {
        commit('setDocument5', value)
    },
    setDocument6({ commit }, value) {
        commit('setDocument6', value)
    },
    setDocument7({ commit }, value) {
        commit('setDocument7', value)
    },
    setDocument8({ commit }, value) {
        commit('setDocument8', value)
    },
    setDocument9({ commit }, value) {
        commit('setDocument9', value)
    },
    setDocument10({ commit }, value) {
        commit('setDocument10', value)
    },
    setDocument11({ commit }, value) {
        commit('setDocument11', value)
    },
    setDocument12({ commit }, value) {
        commit('setDocument12', value)
    },
    setDocument13({ commit }, value) {
        commit('setDocument13', value)
    },
    setDocument14({ commit }, value) {
        commit('setDocument14', value)
    },
    setDocument15({ commit }, value) {
        commit('setDocument15', value)
    },
    setScreen_document2({ commit }, value) {
        commit('setScreen_document2', value)
    },
    setScreen_document3({ commit }, value) {
        commit('setScreen_document3', value)
    },
    setScreen_document4({ commit }, value) {
        commit('setScreen_document4', value)
    },
    setScreen_document5({ commit }, value) {
        commit('setScreen_document5', value)
    },
    setScreen_document6({ commit }, value) {
        commit('setScreen_document6', value)
    },
    setScreen_document7({ commit }, value) {
        commit('setScreen_document7', value)
    },
    setLogo_standee({ commit }, value) {
        commit('setLogo_standee', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setSkyboxPicture({ commit }, value) {
        commit('setSkyboxPicture', value)
    },
    setEnvironmentPicture({ commit }, value) {
        commit('setEnvironmentPicture', value)
    },
    setBackgroundColor({ commit }, value) {
        commit('setBackgroundColor', value)
    },
    setBackgroundColorBottom({ commit }, value) {
        commit('setBackgroundColorBottom', value)
    },
    setIs_picture({ commit }, value) {
        commit('setIs_picture', value)
    },
    setSkybox_is_custom({ commit }, value) {
        commit('setSkybox_is_custom', value)
    },
    setEnvironment_is_custom({ commit }, value) {
        commit('setEnvironment_is_custom', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setEventType(state, value) {
        state.event_type = value
    },
    setItem(state, item) {
        state.item = item

    },
    setEvent(state, value) {

        state.item.event_id = value
    },
    setBooth(state, value) {

        state.item.booth_id = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setShowText(state, value) {
        state.item.show_text = value

    },
    setShowVideos(state, value) {
        state.item.show_videos = value

    },
    setDisableDownloadVideo1(state, value) {
        state.item.disable_download_video1 = value

    },
    setDisableDownloadScreenDocument1(state, value) {
        state.item.disable_download_screen_document1 = value

    },
    setDisableDownloadVideo2(state, value) {
        state.item.disable_download_video2 = value

    },
    setDisableDownloadScreenDocument2(state, value) {
        state.item.disable_download_screen_document2 = value

    },
    setDisableDownloadPresentation1(state, value) {
        state.item.disable_download_presentation1 = value

    },
    setDisableDownloadPresentation2(state, value) {
        state.item.disable_download_presentation2 = value

    },
    setDisableDownloadDocument1(state, value) {
        state.item.disable_download_document1 = value

    },
    setDisableDownloadDocument2(state, value) {
        state.item.disable_download_document2 = value

    },
    setDisableDownloadDocument3(state, value) {
        state.item.disable_download_document3 = value

    },
    setDisableDownloadDocument4(state, value) {
        state.item.disable_download_document4 = value

    },
    setDisableDownloadDocument5(state, value) {
        state.item.disable_download_document5 = value

    },
    setDisableDownloadDocument6(state, value) {
        state.item.disable_download_document6 = value

    },
    setDisableDownloadDocument7(state, value) {
        state.item.disable_download_document7 = value

    },
    setDisableDownloadDocument8(state, value) {
        state.item.disable_download_document8 = value

    },
    setDisableDownloadDocument9(state, value) {
        state.item.disable_download_document9 = value

    },
    setDisableDownloadDocument10(state, value) {
        state.item.disable_download_document10 = value

    },
    setDisableDownloadDocument11(state, value) {
        state.item.disable_download_document11 = value

    },
    setDisableDownloadDocument12(state, value) {
        state.item.disable_download_document12 = value

    },
    setDisableDownloadDocument13(state, value) {
        state.item.disable_download_document13 = value

    },
    setDisableDownloadDocument14(state, value) {
        state.item.disable_download_document14 = value

    },
    setDisableDownloadDocument15(state, value) {
        state.item.disable_download_document15 = value

    },
    setBoothLogo(state, value) {
        state.item.booth_logo = value
        if (value == '') {
            state.item.booth_logo_delete = 1
        } else {
            state.item.booth_logo_delete = 0
        }
    },
    setLogo1(state, value) {
        state.item.logo1 = value
        if (value == '') {
            state.item.logo1_delete = 1
        } else {
            state.item.logo1_delete = 0
        }
    },
    setLogo2(state, value) {
        state.item.logo2 = value
        if (value == '') {
            state.item.logo2_delete = 1
        } else {
            state.item.logo2_delete = 0
        }
    },
    setLogo3(state, value) {
        state.item.logo3 = value
        if (value == '') {
            state.item.logo3_delete = 1
        } else {
            state.item.logo3_delete = 0
        }
    },
    setLogo4(state, value) {
        state.item.logo4 = value
        if (value == '') {
            state.item.logo4_delete = 1
        } else {
            state.item.logo4_delete = 0
        }
    },
    setLogo5(state, value) {
        state.item.logo5 = value
        if (value == '') {
            state.item.logo5_delete = 1
        } else {
            state.item.logo5_delete = 0
        }
    },
    setLogo6(state, value) {
        state.item.logo6 = value
        if (value == '') {
            state.item.logo6_delete = 1
        } else {
            state.item.logo6_delete = 0
        }
    },
    setLogo7(state, value) {
        state.item.logo7 = value
        if (value == '') {
            state.item.logo7_delete = 1
        } else {
            state.item.logo7_delete = 0
        }
    },
    setLogo8(state, value) {
        state.item.logo8 = value
        if (value == '') {
            state.item.logo8_delete = 1
        } else {
            state.item.logo8_delete = 0
        }
    },
    setLogo9(state, value) {
        state.item.logo9 = value
        if (value == '') {
            state.item.logo9_delete = 1
        } else {
            state.item.logo9_delete = 0
        }
    },
    setLogo10(state, value) {
        state.item.logo10 = value
        if (value == '') {
            state.item.logo10_delete = 1
        } else {
            state.item.logo10_delete = 0
        }
    },
    setAuditorium_pre_recorded(state, value) {
        state.item.auditorium_pre_recorded = value
        if (value == '') {
            state.item.pre_delete = 1
        } else {
            state.item.pre_delete = 0
        }
    },
    setAuditorium_panel_discussion(state, value) {
        state.item.auditorium_panel_discussion = value
        if (value == '') {
            state.item.panel_delete = 1
        } else {
            state.item.panel_delete = 0
        }
    },
    setExhibition_area(state, value) {
        state.item.exhibition_area = value
        if (value == '') {
            state.item.event_area_delete = 1
        } else {
            state.item.event_area_delete = 0
        }
    },
    setVirtual_training(state, value) {
        state.item.virtual_training = value
        if (value == '') {
            state.item.training_delete = 1
        } else {
            state.item.training_delete = 0
        }
    },
    setRoundtable(state, value) {
        state.item.roundtable = value
        if (value == '') {
            state.item.roundtable_delete = 1
        } else {
            state.item.roundtable_delete = 0
        }
    },
    setVirtual_meeting(state, value) {
        state.item.virtual_meeting = value
        if (value == '') {
            state.item.meeting_delete = 1
        } else {
            state.item.meeting_delete = 0
        }
    },
    setScrollingText(state, value) {
        state.item.scrolling_text = value

    },
    setAuditorium_live_label(state, value) {
        state.item.auditorium_live_label = value

    },
    setAuditorium_pre_recorded_label(state, value) {
        state.item.auditorium_pre_recorded_label = value

    },
    setAuditorium_panel_discussion_label(state, value) {
        state.item.auditorium_panel_discussion_label = value

    },
    setExhibition_area_label(state, value) {
        state.item.exhibition_area_label = value

    },
    setVirtual_training_label(state, value) {
        state.item.virtual_training_label = value

    },
    setRoundtable_label(state, value) {
        state.item.roundtable_label = value

    },
    setVirtual_meeting_label(state, value) {
        state.item.virtual_meeting_label = value

    },
    setExhibition_video(state, value) {
        state.item.exhibition_video = value
        if (value == '') {
            state.item.video_delete = 1
        } else {
            state.item.video_delete = 0
        }
    },
    setScreen_video1(state, value) {
        console.log("Screen Set",value);
        state.item.screen_video1 = value
        if (value == '') {
            state.item.screen_video1_delete = 1
        } else {
            state.item.screen_video1_delete = 0
        }
    },
    setScreen_video2(state, value) {
        state.item.screen_video2 = value
        if (value == '') {
            state.item.screen_video2_delete = 1
        } else {
            state.item.screen_video2_delete = 0
        }
    },
    setScreen_video3(state, value) {
        state.item.screen_video3 = value
        if (value == '') {
            state.item.screen_video3_delete = 1
        } else {
            state.item.screen_video3_delete = 0
        }
    },
    setScreen_video4(state, value) {
        state.item.screen_video4 = value
        if (value == '') {
            state.item.screen_video4_delete = 1
        } else {
            state.item.screen_video4_delete = 0
        }
    },
    setScreen_video5(state, value) {
        state.item.screen_video5 = value
        if (value == '') {
            state.item.screen_video5_delete = 1
        } else {
            state.item.screen_video5_delete = 0
        }
    },
    setScreen_video6(state, value) {
        state.item.screen_video6 = value
        if (value == '') {
            state.item.screen_video6_delete = 1
        } else {
            state.item.screen_video6_delete = 0
        }
    },
    setScreen_video7(state, value) {
        state.item.screen_video7 = value
        if (value == '') {
            state.item.screen_video7_delete = 1
        } else {
            state.item.screen_video7_delete = 0
        }
    },
    setExhibition_document(state, value) {
        state.item.exhibition_document = value
        if (value == '') {
            state.item.doc_delete = 1
        } else {
            state.item.doc_delete = 0
        }
    },
    setPresentation1(state, value) {
        state.item.presentation1 = value
        if (value == '') {
            state.item.presentation1_delete = 1
        } else {
            state.item.presentation1_delete = 0
        }
    },
    setPresentation2(state, value) {
        state.item.presentation2 = value
        if (value == '') {
            state.item.presentation2_delete = 1
        } else {
            state.item.presentation2_delete = 0
        }
    },
    setDocument1(state, value) {
        state.item.document1 = value
        if (value == '') {
            state.item.document1_delete = 1
        } else {
            state.item.document1_delete = 0
        }
    },
    setDocument2(state, value) {
        state.item.document2 = value
        if (value == '') {
            state.item.document2_delete = 1
        } else {
            state.item.document2_delete = 0
        }
    },
    setDocument3(state, value) {
        state.item.document3 = value
        if (value == '') {
            state.item.document3_delete = 1
        } else {
            state.item.document3_delete = 0
        }
    },
    setDocument4(state, value) {
        state.item.document4 = value
        if (value == '') {
            state.item.document4_delete = 1
        } else {
            state.item.document4_delete = 0
        }
    },
    setDocument5(state, value) {
        state.item.document5 = value
        if (value == '') {
            state.item.document5_delete = 1
        } else {
            state.item.document5_delete = 0
        }
    },
    setDocument6(state, value) {
        state.item.document6 = value
        if (value == '') {
            state.item.document6_delete = 1
        } else {
            state.item.document6_delete = 0
        }
    },
    setDocument7(state, value) {
        state.item.document7 = value
        if (value == '') {
            state.item.document7_delete = 1
        } else {
            state.item.document7_delete = 0
        }
    },
    setDocument8(state, value) {
        state.item.document8 = value
        if (value == '') {
            state.item.document8_delete = 1
        } else {
            state.item.document8_delete = 0
        }
    },
    setDocument9(state, value) {
        state.item.document9 = value
        if (value == '') {
            state.item.document9_delete = 1
        } else {
            state.item.document9_delete = 0
        }
    },
    setDocument10(state, value) {
        state.item.document10 = value
        if (value == '') {
            state.item.document10_delete = 1
        } else {
            state.item.document10_delete = 0
        }
    },
    setDocument11(state, value) {
        state.item.document11 = value
        if (value == '') {
            state.item.document11_delete = 1
        } else {
            state.item.document11_delete = 0
        }
    },
    setDocument12(state, value) {
        state.item.document12 = value
        if (value == '') {
            state.item.document12_delete = 1
        } else {
            state.item.document12_delete = 0
        }
    },
    setDocument13(state, value) {
        state.item.document13 = value
        if (value == '') {
            state.item.document13_delete = 1
        } else {
            state.item.document13_delete = 0
        }
    },
    setDocument14(state, value) {
        state.item.document14 = value
        if (value == '') {
            state.item.document14_delete = 1
        } else {
            state.item.document14_delete = 0
        }
    },
    setDocument15(state, value) {
        state.item.document15 = value
        if (value == '') {
            state.item.document15_delete = 1
        } else {
            state.item.document15_delete = 0
        }
    },
    setScreen_document1(state, value) {
        state.item.screen_document1 = value
        if (value == '') {
            state.item.screen_document1_delete = 1
        } else {
            state.item.screen_document1_delete = 0
        }
    },
    setScreen_document2(state, value) {
        state.item.screen_document2 = value
        if (value == '') {
            state.item.screen_document2_delete = 1
        } else {
            state.item.screen_document2_delete = 0
        }
    },
    setScreen_document3(state, value) {
        state.item.screen_document3 = value
        if (value == '') {
            state.item.screen_document3_delete = 1
        } else {
            state.item.screen_document3_delete = 0
        }
    },
    setScreen_document4(state, value) {
        state.item.screen_document4 = value
        if (value == '') {
            state.item.screen_document4_delete = 1
        } else {
            state.item.screen_document4_delete = 0
        }
    },
    setScreen_document5(state, value) {
        state.item.screen_document5 = value
        if (value == '') {
            state.item.screen_document5_delete = 1
        } else {
            state.item.screen_document5_delete = 0
        }
    },
    setScreen_document6(state, value) {
        state.item.screen_document6 = value
        if (value == '') {
            state.item.screen_document6_delete = 1
        } else {
            state.item.screen_document6_delete = 0
        }
    },
    setScreen_document7(state, value) {
        state.item.screen_document7 = value
        if (value == '') {
            state.item.screen_document7_delete = 1
        } else {
            state.item.screen_document7_delete = 0
        }
    },
    setLogo_standee(state, value) {
        state.item.logo_standee = value
        if (value == '') {
            state.item.standee_delete = 1
        } else {
            state.item.standee_delete = 0
        }
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
        if (value == '') {
            state.item.background_picture_delete = 1
        } else {
            state.item.background_picture_delete = 0
        }
    },
    setSkyboxPicture(state, value) {
        state.item.skybox_picture = value
        if (value == '') {
            state.item.skybox_picture_delete = 1
        } else {
            state.item.skybox_picture_delete = 0
        }
    },
    setEnvironmentPicture(state, value) {
        state.item.environment_picture = value
        if (value == '') {
            state.item.environment_picture_delete = 1
        } else {
            state.item.environment_picture_delete = 0
        }
    },
    setBackgroundColor(state, value) {
        state.item.background_color_top = value
    },
    setBackgroundColorBottom(state, value) {
        state.item.background_color_bottom = value
    },
    setIs_picture(state, value) {
        state.item.is_picture = value
        console.log(value)

    },
    setSkybox_is_custom(state, value) {
        state.item.skybox_is_custom = value
        console.log(value)

    },
    setEnvironment_is_custom(state, value) {
        state.item.environment_is_custom = value
        //console.log(value)

    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
