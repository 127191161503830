<template>
  <div class="snippet general-event-config-snippet">

    <div class="form-group row">
      <div class="col-lg-12" v-if="item.owners">
        <label>Event Role/Permissions </label>
        <ul class="list-group">
          <template v-for="item in item.owners" v-bind:key="item">
            <li class="list-group-item list-group-item-primary">{{ item.userEmail }}  <span class="btn btn-xs btn-info">{{ item.roleTitle }} </span>
              <button
                          class="btn btn-xs btn-danger"
                          type="button"
                          @click="removeUserRole($event, item.userEmail)">Remove</button>
              </li>
          </template>
        </ul>

       <!-- <v-select placeholder="Select Owners" label="email" :options="allUsers" :reduce="(option) => option._id"  :modelValue="item.owners"
          @option:selected="UpdateOwners" @option:deselected="RemoveOwners" multiple persistent-hint />-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-12">
        <button class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="showModal">Add Event Role/Permissions</button>
      
      </div>
    </div>


  </div>
  <previewModel v-show="isModalVisible" @close="closeModal" >
     
    </previewModel>
  
</template>

<script>
import previewModel from "@/components/cruds/Events/Forms/Elements/EventRoleModel";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "EventOwner",
  data() {
    return {
      isModalVisible: false
    }
  },
  components: {
    previewModel
  },
  computed: {
    ...mapGetters("UsersIndex", { allUsers: 'all' }),
    ...mapGetters("EventsSingle", ['item'])
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions("UsersIndex", [
      "fetchData",
    ]),
    ...mapActions("EventsSingle", [
      "setOwners",
      'setOwners2'
    ]),
    UpdateOwners(e) {
      let ownerArr = [];
      _.forEach(e, function (value) {
        ownerArr.push(value._id)
      });
      this.setOwners(ownerArr);
    },
    RemoveOwners(e) {
      let cloneOwners = _.cloneDeep(this.item.owners);
      const index = cloneOwners.indexOf(e._id);
      if (index > -1) { // only splice array when item is found
        cloneOwners.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setOwners(cloneOwners);
    },
    removeUserRole(e,userEmail){
      console.log("User Email**",userEmail);
      let cloneOwners = _.cloneDeep(this.item.owners);
      //const index = cloneOwners.indexOf(userEmail);
      const index = cloneOwners.findIndex(item => item.userEmail === userEmail);
      console.log("indexindex",index);
      if (index > -1) { // only splice array when item is found
        cloneOwners.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log("cloneOwners",cloneOwners)
      this.setOwners2(cloneOwners);
 
    },
    showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
  },
}
</script>

<style scoped></style>