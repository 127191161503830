<template>
  <form @submit.prevent="" novalidate ref="form">
    <div class="container-fluid">
      <div class="snippet general-event-config-snippet">
        <div class="form-group row mb-4">
          <div class="col-lg-6">
            <label>Title*</label>
            <div class="kt-input-icon">
              <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter Title" :value="item.title"  @input="updateTitle" />
              <ValidationMessages :element="v$.item.title" />
            </div>
          </div>
          <div class="col-lg-6">
            <label>Link</label>
            <!-- <v-select name="category" class="" placeholder="Select Category" label="label" @option:selected="updateCategory" :options="categoryEnum" /> -->
           <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Link http://test.com"  :value="item.url" @input="updateLink" />
           <ValidationMessages :element="v$.item.url" />
          </div>
        </div>
        
      

        <div class="form-group row mb-4">
          <div class="col-lg-12">
            <label>Open in new window</label>

            <br />
            <div class="switch switch-sm switch-primary">
                <input-switch name="is_open_new_window" :value="item.is_open_new_window" id="customSwitchesEx" class="custom-control-input"
                  @change="UpdateIsNewWindow" />
              </div>


            
          </div>
        </div>
      </div>

      
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="button" class="btn btn-primary" @click="submitForm">Save</button>
          </div>
        </div>
      </div>

      <FlashMessage group="footerlink_create" />
    </div>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import InputSwitch from "@/components/Commons/InputSwitch";
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
  name: "FooterLinkCreate",
  components: {       
        ValidationMessages,
        InputSwitch
    },
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: []
    }
  },

  //   components: {
  //   InputSwitch,    
  // }, 


  setup() {
        return {
            v$: useVuelidate()
        }
    },

    validations() {
        return {
            item: {
               
                title: {
                    required,
                },
                url: {
                    required,
                },
            }
        };
    },

  computed: {
   // ...mapGetters("EventsSingle", {event: "item"}),
    ...mapGetters("FooterLinkSingle", ["item"])
  },
  

  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.footerlink_create;
    },
  mounted() {
    // this.fetchEvent(this.$route.params.event_id)
  },
 
  methods: {
    // ...mapActions('FooterLinkSingle', {
    //   fetchEvent: 'fetchData',

    // }),
    ...mapActions('FooterLinkSingle', [      
      "setName",
      "setLink",      
      "storeData",
      "resetState",
      "setIsNewWindow"
    ]),

    ...mapActions('FooterLinkIndex', {
      fetchAllSponsors: "fetchData",
      setEditId:"setEditId"
    }),



    
    updateCategory(e) {
      this.setCategory(e.value);
    },
    updateTitle(e) {
      this.setName(e.target.value)
    },
    updateLink(e) {
      this.setLink(e.target.value);
    },       
   

    UpdateIsNewWindow(e) {     
      this.setIsNewWindow(e.target.checked);
    },




    async submitForm(e) {
      e.preventDefault();

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {

              _.map(this.v$.$errors, (item) => {
                  if (item.$property.indexOf(['title','url'])) {
                    // this.error_general_tab = true;
                    // this.setErrorGeneralTab = true;
                  } else {
                    // this.setErrorGeneralTab = false;
                  }
              });
            

              return
        }




      this.storeData(this.$route.params.event_id)
          .then(async () => {
            console.log('ppppppp')
            this.$flashMessage.show({
              group: 'footerlink',
              type: 'success',
              title: 'Success',
              text: "Footer link has been created successfully",
            });
            await this.fetchAllSponsors(this.$route.params.event_id);
            //  await this.$router.push({name: 'blogs.index', params: {event_id: this.$route.params.event_id}});
              //setTimeout(() =>  this.cancelEdit(), 2000);
                
          })
          .catch((error) => {
                    let html = gettingErrorMessage(error);
                    
                    if (error.response.data.error) {
                        this.$flashMessage.show({
                            group: 'footerlink_create',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }
                })
    },
     cancelEdit() {
    
    }
  }
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>