import _ from "lodash"
import axios from "axios";
import constants from "@/config/constants";

function initialState() {
    return {
        all: [],
        loading: false,
    }
}


const getters = {
    loading     :   state => state.loading,
    all         :   state => state.all
}

const actions = {
    fetchData({ commit, state }, params) {
        commit('setLoading', true)

        axios.get('/api/v1/lang/'+params.lang_id+'/lang-translates')
            .then(response => {
                commit('setAll', response.data.data)
            })
            .catch(error => {
               let  message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        axios.delete('/api/v1/lang-translates/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
                let message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
   
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setQuery(state, query) {
        state.query = query
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
