import constants from "@/config/constants";
import axios from "axios";
import _ from "lodash";

function initialState() {
    return {
        item: {},
        loading: false
    }
}

const getters = {
    loading:       state => state.loading,
    item:         state => state.item
}

const actions = {
    fetchData({ commit, state }, {event_id, lang_id}) {
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        /* @TODO: gateway url is not ready for that. need to replace it after */
        return axios.get(constants.API_EVENT_URL + '/api/v1/events/event-id/' + event_id + '/language/' + lang_id)
        // return axios.get(constants.API_URL + '/api/events/event-id/' + event_id + '/languages/' + lang_id + '/translates')
            .then(response => {
                commit('setItem', _.get(response, 'data.data.languages[0]'))
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state }, {event_id, lang_id}) {
        commit('setLoading', true)
        const params = {
            translates: state.item.translates
        };
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/events/event-id/' + event_id + '/languages/' + lang_id + '/translates', params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateEmailTranslation({ commit, state }, {event_id, lang_id}){
        commit('setLoading', true)
        const params = {
            email_text: state.item.email_text
        };
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/events/event-id/' + event_id + '/languages/' + lang_id + '/emailText', params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })

    },
    setItem({commit}, value) {
      commit('setItem', value);
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
