import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"


function initialState() {
    return {
        all: [],
        speakers: [],
        loading: false,
        event: {},
        languages: [],
    }
}

const getters = {
    all             : state => state.all,
    speakers        : state => state.speakers,
    event           : state => state.event,
    languages       : state => state.languages,
    total           : state => state.all.length,
    loading         : state => state.loading,
    event_timezone  : state =>  state.event_timezone

}

const actions = {
    fetchData({ commit, state }, event_id) {
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        let roundTablesReq = axios.get(`${constants.API_URL}/api/round-table/event-id/${event_id}`, {headers: headers});
        let roundtablesSpeakersReq = axios.get(`${constants.API_URL}/api/event-user/event-id/${event_id}`, {headers: headers});
        let eventReq = axios.get(`${constants.API_URL}/api/events/event-id/${event_id}`, {headers: headers});
        
        Promise.all([roundTablesReq, roundtablesSpeakersReq, eventReq]).then((response) => {
            let livePresentationsArr = response[0].data.data;
            let eventSpeakerArr = response[1].data.data;
            let eventInfo = response[2].data.data;
            let eventLanguages = _.map(_.map(eventInfo.languages, 'language_id'), 'language');
            let canBeSpeaker = [];
            _.forEach(eventSpeakerArr, function (value) {
                canBeSpeaker.push({ id: `${value._id}`, name: `${value.salutation} ${value.first_name} ${value.last_name}`, speaker_email: `${value.email}` })
            });
            if(livePresentationsArr){
                for(let lp of livePresentationsArr){
                    let dateFormatSt = moment.utc(lp.start_time).tz(eventInfo.time_zone).format('YYYY-MM-DD HH:mm');
                    lp.start_time = dateFormatSt;
                    let dateFormat = moment.utc(lp.end_time).tz(eventInfo.time_zone).format('YYYY-MM-DD HH:mm');
                    lp.end_time = dateFormat;
                
                }
            }

            commit('setAll', livePresentationsArr);
            commit('setSpeakers', canBeSpeaker);
            commit('setEvent', eventInfo);
            commit('setLanguages', eventLanguages);
            commit('setEventTimezone', eventInfo.time_zone);
            moment.tz.setDefault(state.event_timezone);
          

        }).catch(error => {
            console.log("Error****",error);
            const message = error.response.data.message || error.message
            commit('setError', message)
            console.log(error)
        }).finally(() => {
            commit('setLoading', false)
        })


    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/round-table/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                commit('setError', message)
                console.log(error)
            }).finally(() => {
                commit('setLoading', false)
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setEventTimezone(state, item) {
        state.event_timezone = item;
    },
    setAll(state, items) {
        state.all = items
    },
    setSpeakers(state, items) {
        state.speakers = items
    },
    setEvent(state, items) {
        state.event = items
    },
    setLanguages(state, items) {
        state.languages = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
