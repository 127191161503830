<template>
        <form autocomplete="off" novalidate @submit.prevent="submitForm">
                
            <div class="container-fluid translates-container">
            <div class="row">
                <div class="col-lg-12">
                <OverlayLoading :isLoading="loading" />
                <div v-if="item">
                    <div v-for="(content, index) in item" :key="index" class="row mb-3">
                    <div class="col-lg-2 v-center">
                        <label>{{ index.toUpperCase().replace(/_/g, " ") }}</label>
                    </div>
                    <div class="col-lg-10">
                        <label for="blog_link">Translation</label>
                        <div class="kt-input-icon">
                        <input
                            type="text"
                            class="form-control"
                            name="translation[]"
                            placeholder="Enter translation"
                            @input="updateTranslation($event, index)"
                            :value="content"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                    <div class="form-group mt-4">
                        <div class="col-lg-12">
                            <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
                        </div>
                    </div>
            </div>
            </div>
        </form>
    <FlashMessage group="languages_translate_edit" />
  </template>
  
  <script>
  import BackButton from "@/components/Commons/BackButton";
  import {mapGetters, mapActions} from "vuex";
  import _ from "lodash";
  import { gettingErrorMessage } from "@/services/GetValidationMessage";
  export default {
    name: "EventTranslateLanguagesEdit",
    components: {},
    props: {
        id: null
    },
    created() {
      this.fetchData(this.id)
          .catch(() => {
            this.$flashMessage.show({
              group: 'languages_translate_edit',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          })
    },
    beforeUnmount() {
      this.resetState();
      delete this.$flashMessage.groups.languages_translate_edit;
    },
    computed: {
      ...mapGetters('LangTranslatesSingle', {item: "item", loading: "loading"}),
      translate: function() {
        return _.get(this.item, 'language_id', null);
      }
    },
    methods: {
        ...mapActions('LanguagesIndex', {fetchAll:"fetchData"}),
        ...mapActions('LanguagesSingle',{mainResetState: "resetState"}),
        ...mapActions('LangTranslatesSingle', {
            fetchData: "fetchData",
            updateData: "updateData",
            setItem: "setItem",
            resetState: "resetState"
        }),
        updateTranslation($event, index) {
            const value = $event.target.value;
            const newItem = _.cloneDeep(this.item);
            newItem[index] = value;
            this.setItem(newItem)
        },
        async submitForm(e) {
            e.preventDefault();
            this.updateData(this.id).then(() => {
                this.resetState();
                this.mainResetState();
                this.fetchAll();
                this.$flashMessage.show({
                group : 'mainlanguages_index',
                type  : 'success',
                title : 'Success',
                time  : 1000,
                html  : '<ul><li>Langauge created successfully.</li></ul>'
                });
            }).catch((error) => {
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                      group   : 'languages_translate_edit',
                      type    : 'error',
                      title   : 'Error',
                      time    : 1200,
                      html    : html
                    });
            })
        }
  }
}
  </script>
  
  <style lang="scss">
  .header-template {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  
    .title {
      font-size: 1.6rem;
    }
  }
  
  
  .translates-container {
    .v-center {
      display: flex;
      align-self: center;
    }
    /*--------------- Tabs --------------- */
    .tabs-component {
      margin: 0;
    }
  
    .tabs-component-tabs {
      border: solid 1px #ddd;
      border-radius: 6px;
      margin-bottom: 5px;
    }
  
    @media (min-width: 700px) {
      .tabs-component-tabs {
        border: 0;
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1px;
      }
    }
  
    .tabs-component-tab {
      color: #999;
      font-size: 14px;
      font-weight: 600;
      margin-right: 0;
      list-style: none;
  
      a:hover {
        color: #000;
        text-decoration: none;
      }
  
      &:not(:last-child) {
        border-bottom: dotted 1px #ddd;
      }
  
      &:hover {
        color: #666;
      }
      &.is-active {
        margin-bottom: -2px;
        display:flex;
  
        a:hover{
          color: #fff;
          text-decoration: none;
        }
  
        .tabs-component-tab-a{
          background:#3b8be4;
          color:#fff;
        }
      }
  
      &.is-disabled * {
        color: #cdcdcd;
        cursor: not-allowed !important;
      }
    }
  
    .tabs-component-tab-a {
      align-items: center;
      color: inherit;
      display: flex;
      padding: .75em 1em;
      text-decoration: none;
    }
  
    .tabs-component-panels {
      padding: 2em;
    }
  
    @media (min-width: 700px) {
      .tabs-component-tab {
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 3px 3px 0 0;
        margin-right: .5em;
        transform: translateY(2px);
        transition: transform .3s ease;
  
        &.is-active {
          border-bottom: solid 1px #fff;
          z-index: 2;
          transform: translateY(0);
        }
      }
    }
  
    @media (min-width: 700px) {
      .tabs-component-panels {
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 0 6px 6px 6px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);
      }
    }
  }
  </style>