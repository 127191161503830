<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="snippet general-event-config-snippet">
    
      <div class="form-group row mb-4">
        <div class="col-lg-6">
          <label>Title*</label>
          <div class="kt-input-icon">
            <input type="text" class="form-control" name="name" maxlength="200" placeholder="Enter Tag Title" :value="item.title" @input="updateName" />
            <ValidationMessages :element="v$.item.title" />
          </div>
        </div>
        <div class="col-lg-6">
          <label>Link</label>
          <!-- <v-select name="category" class="" placeholder="Select Category" label="label" @option:selected="updateCategory" :options="categoryEnum" :modelValue="sponsor.category" /> -->
          <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Link http://www.test.com" :value="item.url" @input="updateLink" />
        </div>
      </div>
      <div class="form-group row mb-4">
        <div class="col-lg-12">
          <label>Description</label>
          <div class="kt-input-icon">
            <textarea rows="3" class="form-control" name="description" placeholder="Enter description"  :value="item.description" @input="updateDescription"></textarea>
          </div>
        </div>
      </div>
      
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <FlashMessage group="tag_edit"/>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
  name: "TagsEdit",
  components: {       
        ValidationMessages
    },
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: [],
    }
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },

    validations() {
        return {
            item: {
               
                title: {
                    required,
                }
            }
        };
    },
  props: {
   tag_id: String
  },
  computed: {
   // ...mapGetters("BlogsSingle", {event: "item"}),
    ...mapGetters("TagsSingle", [
      'item',
      'loading',
      // categoryEnum: "categoryEnum",
      ])
  },
  
  mounted() {
   // this.fetchEvent(this.$route.params.event_id)

    this.fetchBlog(this.tag_id);
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.tag_edit;
  },
  methods: {
    // ...mapActions('EventsSingle', {
    //   fetchEvent: 'fetchData',
    // }),
    ...mapActions('TagsSingle', {
      fetchBlog: "fetchData",      
      setName: "setName",
      setLink: "setLink",     
      updateData: "updateData",
      resetSponsorItem: "resetState",
      setDescription: "setDescription"
    }),
    ...mapActions('TagsIndex', {
      fetchAllSponsors: "fetchData",
      setEditId: "setEditId",
    }),
    
    updateName(e) {
      this.setName(e.target.value)
    },
    updateLink(e) {
      this.setLink(e.target.value);
    },   

    updateDescription(e) {
      this.setDescription(e.target.value);
    },

    logoErrCb(e) {
      console.log(e)
      this.$flashMessage.show({
              group: 'tag_edit',
              type: 'error',
              title: 'Failed',
              text: "Invalid File/Doc format.Please use described valid format"
              
            })
    },
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    getLanguageName() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'name', '');
    },
    getLanguageDescription() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'description', '');
    },

    async submitForm(e) {

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {

      _.map(this.v$.$errors, (item) => {
        if (item.$property.indexOf(['title'])) {
          // this.error_general_tab = true;
          // this.setErrorGeneralTab = true;
        } else {
          // this.setErrorGeneralTab = false;
        }
      });


      return
      }



      this.updateData(this.$route.params.event_id)
          .then(async(res) => {
            this.$flashMessage.show({
              group: 'tags',
              type: 'success',
              title: 'Success',
              text: "Tag updated successfully",
            });
           
            await this.fetchAllSponsors(this.$route.params.event_id);
            await this.$router.push({name: 'tags.index', params: {event_id: this.$route.params.event_id}});
            
           // setTimeout(() =>  this.cancelEdit(), 2000);
            
          })
          .catch(error => {
            let html = gettingErrorMessage(error);
            this.$flashMessage.show({
              group: 'tag_edit',
              type: 'error',
              title: 'Failed',
              text: html,
              html:html
            });
          });
    },
    cancelEdit() {
      this.resetSponsorItem();
      this.setEditId(null);
    }
  }
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>