<template>
<section class="reset-password">
  <SimpleHeader/>
  <div class="form-wrapper">
    <h1>Reset Password</h1>
    <form action="#" method="post" @submit.prevent="onSubmit" novalidate>
      <div class="form-control form-control--password">
        <input type="password" placeholder="New Password" v-model="password" :disabled="disabled"/>
      </div>
      <div class="form-control form-control--password">
        <input type="password" placeholder="Confirm New Password" v-model="confirm_password" :disabled="disabled"/>
      </div>
      <div class="form-control form-control--submit">
        <button type="submit" :disabled="disabled">Continue</button>
      </div>
    </form>
  </div>
</section>
</template>

<script>
import axios from 'axios';
import constants from "@/config/constants";

export default {
  name: "ResetPasswordIndex",
  data() {
    return {
      password: null,
      confirm_password: null,
      disabled: false
    }
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.disabled = true;
      
      try {
        const resp = await axios.post(`${constants.API_URL}/api/reset-password`, {
          token: this.$route.query.token,
          password: this.password,
        });

        this.email = null;

        console.log(resp.data)

        
        // this.$flashMessage.show({
        //   group: 'reset-password',
        //   type: 'success',
        //   title: 'Email sent.',
        //   text: resp.data.message
        // });
        this.$router.push({name: 'login'});
      } catch (e) {
        // this.$flashMessage.show({
        //   group: 'reset-password',
        //   type: 'error',
        //   title: 'Error',
        //   text: e.response.data.message ? e.response.data.message : e.message
        // });
      } finally {
        this.disabled = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.reset-password {
  background-image: url("~@/../public/assets/images/login/forgot-password-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    flex-direction: column;
    color: white;
    max-width: 410px;
    margin: 0 auto;
    
    h1 {
      color: white;
      font-size: 36px;
      word-spacing: 1px;
      margin-bottom: 78px
    }

    .subtitle {
      margin-bottom: 66px;
    }
    
    form {
      width: 100%;
    }

    .form-control {
      background: transparent;
      border: none;
      &--password {
        margin: 10px 0;
      }
      
      input[type=password] {
        border: none;
        padding: 12px;
        text-align: center;
        font-size: 18px;
        width: 100%;
      }
      
      button[type=submit] {
        border: none;
        background-color: #2266d5;
        width: 100%;
        color: white;
        padding: 16px;
      }
    }
  }
}
</style>