import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"

function initialState() {
    return {
        all: [],
        loading: false,
        event_timezone: null,
        event: {},
        booths:[]
    }
}

const getters = {
    all             : state => state.all,
    event           : state => state.event,
    languages       : state => state.languages,
    total           : state => state.all.length,
    loading         : state => state.loading,
    event_timezone  : state => state.event_timezone,
    booths          : state => state.booths,

}

const actions = {
    fetchData({ commit, state ,dispatch}, event_id) {
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        let MeetingReq  = axios.get(`${constants.API_URL}/api/meetings/event-id/${event_id}`, {headers: headers});
        let eventReq    = axios.get(`${constants.API_URL}/api/events/event-id/${event_id}`, {headers: headers});
        Promise.all([MeetingReq, eventReq]).then((response) => {
            let MeetingArr      = response[0].data.data;
            let eventInfo       = response[1].data.data;
            let eventLanguages  = _.map(_.map(eventInfo.languages, 'language_id'), 'language');
            commit('setEventTimezone', eventInfo.time_zone);
            moment.tz.setDefault(state.event_timezone);


            commit('setAll', MeetingArr);
            commit('setEvent', eventInfo);
            commit('setLanguages', eventLanguages);
            dispatch('fetchBoothData',event_id)
         

        }).catch(error => {
            console.log("Error****",error);
            const message = error.response.data.message || error.message
            commit('setError', message)
            console.log(error)
        }).finally(() => {
            commit('setLoading', false)
        })
    },
    fetchBoothData({ commit }, event_id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        axios.get(constants.API_URL + '/api/booth/event-id/'+event_id, {headers: headers})
        .then(response => {
            let booth =[];
            let items = _.get(response, 'data.data', {})
            console.log("data**********",items);
            _.forEach(items, function (value) {
                booth.push({ id: `${value._id}`, title: `${value.title} ` })
            }); 
            commit('setBooths',booth)
            
        })
        .catch(error => {
            console.log(error)
            
        })
        .finally(() => {
           
        })
    },
    getEventDetails({ commit, state }, params) {
        axios.get('/api/v1/event_details/' + params.id)
            .then(response => {
                commit('setEventType', response.data.data[0].event_type);
            })

    },
    destroyRoom({ commit, state }, id) {
        axios.delete('/api/v1/meeting-rooms/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
            })
            .catch(error => {
               let  message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    destroySession({ commit, state }, id) {
        axios.delete('/api/v1/room-sessions/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.room_sessions = item.room_sessions.filter((session) => {
                        //debugger;
                        return session.id != id
                    })
                }))
            })
            .catch(error => {
               let  message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    destroyHost({ commit, state }, id) {
        axios.delete('/api/v1/meeting-room-hosts/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.hosts = item.hosts.filter((host) => {
                        //debugger;
                        return host.id != id
                    })
                }))
            })
            .catch(error => {
               let  message = error.response.data.message || error.message
                commit('setError', message)
                console.log(message)
            })
    },
    destroyData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        axios.delete(constants.API_URL + '/api/meetings/' + id, {headers: headers})
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }))
                return response;
            })
            .catch(error => {
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setEventTimezone(state, item) {
        state.event_timezone = item;
    },
    setAll(state, items) {
        state.all = items
    },
    setEvent(state, items) {
        state.event = items
    },
    setLanguages(state, items) {
        state.languages = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setBooths(state,items){
        state.booths = items
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
