<template>
    <GeneralDashboardLayout>
        <template v-slot:header>
      <div class="header-roles">
        <BackButton :to="{ name: 'dashboard.index' }" />
        <h1 class="title text-center">Languages</h1>
        <div>
          <button class="btn btn-outline-primary" @click="createClick">Create a New </button>
        </div>
      </div>
    </template>
      <template v-slot:content>
        <div class="container-fluid user-container">
          <div class="row">
            <div class="col-lg-12">
              <OverlayLoading :isLoading="loading" />
                <TogglePrimary v-if="is_new">
                    <template v-slot:default>
                        <LanguagesCreateForm :item="item" />
                    </template>
                </TogglePrimary>
                            
              <TogglePrimary v-for="(language, index) in languages" v-bind:key="index">
                <template v-slot:label>
                  <span class="labelwrapper">
                    <span class="col1">{{ language.language }}</span>
                  </span>
                </template>
               
                <template v-slot:default>
                    <LanguagesEditForm v-if="is_new === false && is_editing === true && language._id === item._id" :item="language" :id="language._id"/>
                    <ShowItem :langauge="language" :id="language._id"    v-else />
                </template>

                
              </TogglePrimary>
            </div>
          </div>
          <FlashMessage group="mainlanguages_index" />
        </div>
      </template>
    </GeneralDashboardLayout>
  </template>
  
  <script>
  import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
  import BackButton from "@/components/Commons/BackButton";
  import {mapGetters, mapActions} from "vuex";
  import TogglePrimary from "@/components/Commons/TogglePrimary";
  import constants from "@/config/constants";
  import ShowItem from "@/components/cruds/Languages/Show";
  import LanguagesEditForm from "@/components/cruds/Languages/Edit";
  import LanguagesCreateForm from "@/components/cruds/Languages/Create";

  export default {
    name: "LanguagesIndex",
    components: {GeneralDashboardLayout,BackButton, TogglePrimary,ShowItem,LanguagesCreateForm,LanguagesEditForm},
    data() {
      return {
        IMAGE_URL: constants.IMAGE_URL
      }
    },
    created() {
      this.fetchLanguages()
          .catch(() => {
            this.$flashMessage.show({
              group: 'mainlanguages_index',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          })
    },
    beforeUnmount() {
      this.resetState();
      delete this.$flashMessage.groups.mainlanguages_index;
    },
    computed: {
      ...mapGetters('LanguagesIndex', {languages: "all", loading: "loading"}),
      ...mapGetters('LanguagesSingle', ['item', 'is_new', "is_editing"]),
  
    },
    methods: {
      ...mapActions('LanguagesIndex', {fetchLanguages: "fetchData", resetState: "resetState"}),
      ...mapActions('LanguagesSingle', ['setIsNew','setIsEditing']),
      createClick() {
            this.setIsNew(true);
            this.setIsEditing(false);
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .header-template {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
  
    .title {
      font-size: 1.6rem;
      margin-left: 40%;
    }
  }
  </style>