<template>
    <div class="row">

        <div class="col-md-5 event_detail_ls">
            <div class="event_detail_wrapper">
                <div class="row">

                    <div class="event_ls_label col-md-2">Page Name</div>
                    <div class="col-md-8">{{ presentation.type }}</div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="event_ls_label col-md-2">Title</div>
                    <div class="col-md-8">{{ presentation.translations[0]['title'] }}</div>
                </div>
            </div>
        </div>   
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary"
                @click="editData(presentation)">Edit</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger"
                @click="deleteData(presentation)">Delete</button>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
export default {
    name: "ShowItem",
    data() {
        return {
           
        }
    },
    props: {
        event:null,
        presentation: {
            type: Object,
            default() {
                return {
                    name: null,
                    email: null,
                    company: null,
                    role: null,
                }
            }
        },
        id: {
            type: String
        },
        speaker: {
            type: String
        }
    },

    methods: {
        ...mapActions('DocsSectionsIndex', ['fetchData', 'destroyData']),
        ...mapActions('DocsSectionsSingle', ['setItem', 'setIsNew', 'setIsEditing', 'setLangKey', 'setLangTitle', 'setLangDescirption']),
        deleteData(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then( result => {
                if (result.isConfirmed) {
                        this.destroyData(item._id).then((resp) => {
                        console.log("Resp",resp);
                          this.$flashMessage.show({
                              group : 'doc_section_index',
                              type  : 'success',
                              title : 'Success',
                              html  : '<ul><li> Manual Section has been deleted successfully.</li></ul>'
                          });
                            this.setIsNew(false);
                            this.setIsEditing(false);
                            this.fetchData();
                  
                      })
                  }
            });
        },
        editData(item) {

            if (!_.isEmpty(item.translations)) {
                let defaultLanguage = item.translations[0];
                if (_.has(defaultLanguage, 'key')) {
                    this.setLangKey(defaultLanguage['key'])
                }
                if (_.has(defaultLanguage, 'title')) {
                    this.setLangTitle(defaultLanguage['title'])
                }
                if (_.has(defaultLanguage, 'description')) {
                    this.setLangDescirption(defaultLanguage['description'])
                }
            }
            this.setItem(item);
            this.setIsNew(false);
            this.setIsEditing(true);
            this.$forceUpdate();
        },
       
    },

}
</script>

<style lang="scss" scoped>
.user_img {
    img {
        width: 100%;
    }
}

.event_detail_wrapper {
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }

    .row {
        .col-md-8 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }

        .col-md-3 {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 5px;
        }
    }

    .card-body {
        box-shadow: 0px 0px #ffffff;
    }
}

.tabs-vertical {
    border-top: 0px;
}

.toggle-content {
    background: #fff;
}
</style>
