import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        keywords: [],
        loading: false,
    
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    
}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    storeData({ commit, state, dispatch },event) {
        console.log("eventevent",event);
        commit('setLoading', true)

        const params = _.cloneDeep(state.keywords);
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;
      
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        console.log("Update,",params);
        return axios.post(constants.API_URL + `/api/events/${event.event_id}/keywords`, params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    setTag({ commit }, value) {
        console.log("VAlue--",value);
        
        commit('setTag', value)
    },
   
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setTag(state, value) {
        state.keywords = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
