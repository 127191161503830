<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'dashboard.index' }" />
        <h1 class="title text-center">Configurations</h1>
        <div></div>
      </div>
    </template>
    <template v-slot:content>
      <OverlayLoading :isLoading="loading" />
      <ShowItem :configurations="this.item"  v-if="!this.is_edit"/>
      <ConfigurationsEditForm v-else/>

      <FlashMessage group="configurations" />
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GeneralDashboardLayout from "../../layouts/GeneralDashboardLayout";
import BackButton from "@/components/Commons/BackButton";
import ShowItem from "@/components/cruds/Configurations/Forms/Show";
import ConfigurationsEditForm from "@/components/cruds/Configurations/Forms/Edit";

export default {
  name: "ConfigurationIndex",
  components: { BackButton, GeneralDashboardLayout, ShowItem, ConfigurationsEditForm },  
  beforeUnmount() {
    delete this.$flashMessage.groups.configurations;
  },
  created() {
    const access_level = localStorage.getItem('access_level');
    if(access_level =='event'){
      this.$router.push({name: 'notFound'});
    }
    this.fetchData()
  },
  computed: {
    ...mapGetters('ConfigurationsSingle', ['item', 'is_edit', 'loading']),
  },
  methods: {
    ...mapActions('ConfigurationsSingle', [ 'fetchData']),    
  }
};
</script>

<style scoped lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.company-container {
  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .input-group-text {
    border-radius: 1.25rem;
  }

  .search_bar {
    margin-bottom: 20px;
  }
}
</style>
