import Vuex from 'vuex';
import PermissionsIndex from './modules/Permissions';
import PermissionsSingle from './modules/Permissions/single';
import RolesIndex from './modules/Roles';
import RolesSingle from './modules/Roles/single';
import EventsIndex from './modules/Events';
import EventsSingle from './modules/Events/single';
import EventHallsIndex from './modules/EventHalls/index';
import EventHallsSingle from './modules/EventHalls/single';
import CompaniesIndex from './modules/Companies/index';
import CompaniesSingle from './modules/Companies/single';
import ConfigurationsSingle from './modules/Configurations/single';
import UsersIndex from './modules/Users/index';
import UsersSingle from './modules/Users/single';
import EventUsersIndex from './modules/EventUsers/index';
import EventUsersSingle from './modules/EventUsers/single';
import ExhibitionAreasIndex from './modules/ExhibitionAreas/index';
import ExhibitionAreasSingle from './modules/ExhibitionAreas/single';
import LanguagesIndex from './modules/Languages/index';
import LanguagesSingle from './modules/Languages/single';

import LivePresentationsIndex from './modules/LivePresentations';
import LivePresentationsSingle from './modules/LivePresentations/single';
import EventLanguagesIndex from './modules/EventLanguages/index';
import EventLanguagesSingle from './modules/EventLanguages/single';
import EventLangTranslatesSingle from './modules/EventLangTranslates/single';
import ExhibitorsIndex from './modules/Exhibitors/index';
import ExhibitorsSingle from './modules/Exhibitors/single';
import PanelAgendasIndex from './modules/PanelAgendas/index';
import PanelAgendasSingle from './modules/PanelAgendas/single';
import AgendaPanelistsIndex from './modules/AgendaPanelists/index';
import AgendaPanelistsSingle from './modules/AgendaPanelists/single';
import WebcastsIndex from './modules/Webcasts/index';
import WebcastsSingle from './modules/Webcasts/single';
import OnDemandIndex from './modules/OnDemand/index';
import OnDemandSingle from './modules/OnDemand/single';
import SponsorsIndex from './modules/Sponsors/index';
import SponsorsSingle from './modules/Sponsors/single';
import RoundTablesIndex from './modules/RoundTables/index';
import RoundTablesSingle from './modules/RoundTables/single';
import ExhibitionHallsIndex from './modules/ExhibitionHalls/index';
import ExhibitionHallsSingle from './modules/ExhibitionHalls/single';
import PanelistIndex from './modules/Panelist/index';
import PanelistSingle from './modules/Panelist/single';

import BoothPresetsIndex from './modules/BoothPresets/index';
import BoothPresetsSingle from './modules/BoothPresets/single';

import BoothsIndex          from './modules/Booths/index';
import BoothsSingle         from './modules/Booths/single';
import SurveysIndex         from './modules/Surveys/index';
import SurveysSingle        from './modules/Surveys/single';
import BoothContentsIndex   from './modules/BoothContents/index';
import BoothContentsSingle  from './modules/BoothContents/single';

import EventKeywordsIndex   from './modules/EventKeywords/index';
import EventKeywordsSingle  from './modules/EventKeywords/single';

import MeetingRoomsIndex    from './modules/MeetingRooms/index';
import MeetingRoomsSingle   from './modules/MeetingRooms/single';

import RoomSessionsIndex            from './modules/RoomSessions/index';
import RoomSessionsSingle           from './modules/RoomSessions/single';
import MeetingHostsIndex            from './modules/MeetingHosts/index';
import MeetingHostsSingle           from './modules/MeetingHosts/single';
import LangTranslatesIndex          from './modules/LangTranslates/index';
import LangTranslatesSingle         from './modules/LangTranslates/single';

import DocsSectionsIndex            from "./modules/DocsSections/index";
import DocsSectionsSingle           from './modules/DocsSections/single';

import SectioncontentsIndex         from './modules/Sectioncontents/index';
import SectioncontentsSingle        from './modules/Sectioncontents/single';

import BlogsIndex from './modules/Blogs/index';
import BlogsSingle from './modules/Blogs/single';

import FooterLinkIndex from './modules/FooterLink/index';
import FooterLinkSingle from './modules/FooterLink/single';

import TagsIndex from './modules/Tags/index';
import TagsSingle from './modules/Tags/single';

import EventPagesIndex from './modules/EventPages/index';
import EventPagesSingle from './modules/EventPages/single';

import EventPageContentIndex    from './modules/EventPageContent/index';
import EventPageContentSingle   from './modules/EventPageContent/single';

import EventAssetsIndex    from './modules/EventAssets/index';
import EventAssetsSingle   from './modules/EventAssets/single';
import MyProfileIndex       from './modules/MyProfile/index';
import ChangePasswordIndex  from './modules/ChangePassword/index';

import AuthenticationsIndex from './modules/Authentications/index';


import LeaderboardIndex         from './modules/LeaderBoards/index';
import LeaderboardSingle        from './modules/LeaderBoards/single';

import EventAnalyticsIndex      from './modules/EventAnalytics/index';
import EventAnalyticsSingle     from './modules/EventAnalytics/single';

import SurveyReportIndex        from './modules/SurveyReport/index';
import FeedbackIndex            from './modules/Feedback/index';
import FeedbackSingle           from './modules/Feedback/single';
import FeedbackReport           from './modules/Feedback/report';


const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        PermissionsIndex,
        PermissionsSingle,
        RolesIndex,
        RolesSingle,
        EventsIndex,
        EventsSingle,
        EventHallsIndex,
        EventHallsSingle,
        CompaniesIndex,
        CompaniesSingle,
        ConfigurationsSingle,
        UsersIndex,
        UsersSingle,
        ExhibitionAreasIndex,
        ExhibitionAreasSingle,
        LanguagesIndex,
        LivePresentationsIndex,
        LivePresentationsSingle,
        EventLanguagesIndex,
        EventLanguagesSingle,
        EventLangTranslatesSingle,
        ExhibitorsIndex,
        ExhibitorsSingle,
        PanelAgendasIndex,
        PanelAgendasSingle,
        AgendaPanelistsIndex,
        AgendaPanelistsSingle,
        WebcastsIndex,
        WebcastsSingle,
        OnDemandIndex,
        OnDemandSingle,
        EventUsersIndex,
        EventUsersSingle,
        SponsorsIndex,
        SponsorsSingle,
        RoundTablesIndex,
        RoundTablesSingle,
        ExhibitionHallsIndex,
        ExhibitionHallsSingle,
        PanelistIndex,
        PanelistSingle,
        BoothPresetsIndex,
        BoothPresetsSingle,
        BoothsIndex,
        BoothsSingle,
        SurveysIndex,
        SurveysSingle,
        BoothContentsIndex,
        BoothContentsSingle,
        EventKeywordsIndex,
        EventKeywordsSingle,
        MeetingRoomsIndex,
        MeetingRoomsSingle,
        RoomSessionsIndex,
        RoomSessionsSingle,
        MeetingHostsIndex,
        MeetingHostsSingle,
        LanguagesSingle,
        LangTranslatesIndex,
        LangTranslatesSingle,
        BlogsIndex,
        BlogsSingle,
        FooterLinkIndex,
        FooterLinkSingle,
        TagsIndex,
        TagsSingle,
        DocsSectionsIndex,
        DocsSectionsSingle,
        SectioncontentsIndex,
        SectioncontentsSingle,
        EventPagesIndex,
        EventPagesSingle,
        EventPageContentIndex,
        EventPageContentSingle,
        EventAssetsIndex,
        EventAssetsSingle,
        MyProfileIndex,
        ChangePasswordIndex,
        AuthenticationsIndex,
        LeaderboardIndex,
        LeaderboardSingle,
        EventAnalyticsIndex,
        EventAnalyticsSingle,
        SurveyReportIndex,
        FeedbackIndex,
        FeedbackSingle,
        FeedbackReport
    },
    strict: debug,
});
