<template>
    <div class="tabs-component">
               <ul class="nav nav-tabs nav-justified">
                 <li class="nav-item">
                   <a class="nav-link active" data-bs-target="#tab_attendence" href="#tab_attendence" data-bs-toggle="tab">Attendance</a>
                 </li>
                 
               </ul>
       </div>
       <div class="tab-content">
               <div id="tab_attendence" class="tab-pane active">
                   <div class="snippet general-event-config-snippet">
                       <TogglePrimary v-for="(data, index) in tabAttendence" :key="index">
                       <template v-slot:label >
                           <span class="labelwrapper" >
                               <span class="col1"> {{data.title}}</span>
                           </span>
                       </template>
                       <div id ="edit-content">
                           <TabShow    :data="data"/>
                 
                       </div><!-- End Content-->
           

               </TogglePrimary>
                       
               </div><!-- End of snippet-->
      
               </div>
     </div>
                

               
</template>

<script>
import {
mapGetters,
mapActions
} from 'vuex'
import _ from "lodash";

import SearchableContainer  from "@/components/Commons/SearchableContainer";
import TogglePrimary        from "@/components/Commons/TogglePrimary";
import TabShow              from "@/components/cruds/EventAnalytics/Forms/Show";


export default {
    name: 'AnalyticsTab',
    components: {
    TogglePrimary,
    TabShow
},
data() {
return {
   search: null,
   perPage: 5,
   system_users: [],
}
},
props:{
    headers:null
},
watch: {
},
created() {
// this.fetchPermissions()

},
beforeUnmount() {
//this.resetState();

},
computed: {
       ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
       ...mapGetters('EventAnalyticsIndex', ['loading', 'all', 'headers','enumModules','loaded','tabAttendence','tabQa','tabPolling','tabRating','tabChat']),
},
methods: {
...mapActions('EventAnalyticsIndex', ['fetchData','resetState']),

}
}
</script>