import _ from "lodash";
import axios from "axios";

function initialState() {
    return {
        all: [],
        relationships: {
            'agenda': 'title',
            'event_users': 'first_name',
        },
        loading: false
    }
}

const getters = {
    data: state => state.all,
    total:         state => state.all.length,
    loading:       state => state.loading,
}

const actions = {
    fetchData({ commit, state }) {
        commit('setLoading', true)

        return axios.get('/api/v1/agenda-panelists')
            .then(response => {
                commit('setAll', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit, state }, id) {
        return axios.delete('/api/v1/agenda-panelists/' + id)
            .then(response => {
                commit('setAll', state.all.filter((item) => {
                    return item.id != id
                }));
                return response;
            })
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
