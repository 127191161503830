<template>
  <div class="snippet additional-config-snippet">
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Search Exhibitor/Booth</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="search_exhibitor"
                        id="customEETET"
                        :value="item.search_exhibitor"
                        class="custom-control-input"
                        @change="updateSearchExhibitor" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Search Company</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="search_company"
                        id="customEETET"
                        :value="item.search_company"
                        class="custom-control-input"
                        @change="updateSearchCompany" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Search Attendee</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="search_attendee"
                        :value="item.search_attendee"
                        class="custom-control-input"
                        @change="updateSearchAttendee" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Search Match Making</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="search_match_making"
                        :value="item.search_match_making"
                        class="custom-control-input"
                        @change="updateSearchMatchMaking" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-3">
        <label>Video Call Screen Sharing ?</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="screen_sharing"
                        :value="item.screen_sharing"
                        class="custom-control-input"
                        @change="updateScreenSharing" />
        </div>
      </div>
      <div class="col-lg-3">
        <label>Enable Google Map ?</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="enable_map"
                        :value="item.enable_map"
                        class="custom-control-input"
                        @change="updateEnableMap" />
        </div>
      </div>
      <div class="col-lg-6">
        <label>Meetings with all or only with attendee ?</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="meeting_with_attendee"
                        :value="item.meeting_with_attendee"
                        class="custom-control-input"
                        @change="updateMeetingWithAttendee" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery';
import InputSwitch from "@/components/Commons/InputSwitch";

export default {
  name: "AdditionalConfigSnippet",
  components: {
    InputSwitch
  },
  computed: {
    ...mapGetters("EventsSingle", [
      "item",
      "loading",
    ])
  },
  mounted() {
    const switchers = document.querySelectorAll('.additional-config-snippet input[data-plugin-ios-switch]')
    switchers.forEach(item => {const elem = new window.theme.PluginIOS7Switch($(item)) })
  },
  methods: {
    ...mapActions("EventsSingle", [
      "setScreenSharing",
      "setSearchExhibitor",
      "setSearchCompany",
      "setSearchAttendee",
      "setSearchMatchMaking",
      "setEnableMap",
      "setMeetingWithAttendee",
    ]),
    updateSearchExhibitor(e) {
      this.setSearchExhibitor(e.target.checked);
    },
    updateSearchCompany(e) {
      this.setSearchCompany(e.target.checked);
    },
    updateSearchAttendee(e) {
      this.setSearchAttendee(e.target.checked);
    },
    updateSearchMatchMaking(e) {
      this.setSearchMatchMaking(e.target.checked);
    },
    updateScreenSharing(e) {
      this.setScreenSharing(e.target.checked);
    },
    updateEnableMap(e) {
      this.setEnableMap(e.target.checked);
    },
    updateMeetingWithAttendee(e) {
      this.setMeetingWithAttendee(e.target.checked);
    },
  },
}
</script>

<style scoped>

</style>