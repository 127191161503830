<template>
    <div class="container-fluid">
              <div class="leaderboard_list">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td scope="col" >First Name</td>
                    <td scope="col" >Last Name</td>
                    <td scope="col">Email</td>
                    <td scope="col">Company</td>
                    <td scope="col">Job Title</td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(user, k) in data.attendees" :key="k">
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.company_name }}</td>
                    <td>{{ user.job_title }}</td> 
                </tr>
                </tbody>
              </table>
            </div>
                   
    </div>
    

</template>
    
    <script>
    import {
        mapActions
    } from 'vuex';
    import axios from "axios";
    import constants from "@/config/constants";
    export default {
        name: "ShowItem",
        data() {
            
        },
        props: {
            data: {
                type: Object,
                default () {
                    return {
                        
                    }
                }
            },
            
        },
        components: {
            
        },
    
        methods: {
        },
    
    }
    </script>
    
    <style lang="scss" scoped>
    .user_img {
        img {
            width: 100%;
        }
    }
    
    .event_detail_wrapper {
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    
        .row {
            .col-md-8 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
    
            .col-md-3 {
                border-bottom: 1px dotted #ccc;
                padding-bottom: 5px;
            }
        }
    
        .card-body {
            box-shadow: 0px 0px #ffffff;
        }
    }
    
    .tabs-vertical {
        border-top: 0px;
    }
    
    .toggle-content{
      background:#fff;
    }
    </style>
    