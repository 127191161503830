<template>
    <div class="row">
      
      <div class="col-md-5 event_detail_ls">
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-6">Module Name</div>
            <div class="col-md-6">{{leaderboard.module_label}}</div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-6">Module Points</div>
            <div class="col-md-4">{{leaderboard.module_points}}</div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-6">Disable</div>
            <div class="col-md-4" >
                {{leaderboard.module_disable?"Yes":"No"}}
            </div>
          </div>
        </div>
        
  
    
      </div>
    
      <div class="event_detail_ls text-right mt-3 mr-5">
        <button v-if="can('event_leaderboard_edit',checkPermssions)" type="button"  class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(leaderboard)">Edit</button>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import constants from "@/config/constants";
  import { isPermits} from "@/services/CheckPermissions";
  
  export default {
    name: "ShowItem",
    data() {
      return {
        IMAGE_URL: constants.IMAGE_URL
      }
    },
    props: {
        leaderboard: {
        type: Object,
        default() {
          return {
            title: null,
            location: null,
           
          }
        }
      },
      checkPermssions:null
    },
    methods: {
      ...mapActions('LeaderboardIndex', ['fetchData', ]),
      ...mapActions('LeaderboardSingle', ['setIsNew', 'setEditId','setIsEditing','setItem']),
      deleteData(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#dd4b39",
          focusCancel: true,
          reverseButtons: true
        }).then(result => {
          if (result.isConfirmed) {
            this.destroyData(id).then(() => {
              
              this.$flashMessage.show({
                          group : 'leader_board_index',
                          type  : 'success',
                          title : 'Success',
                          time  :1000,
                          html  : '<ul><li>Module disabled successfully.</li></ul>'
                        });
  
              this.fetchData(this.$route.params.event_id);
              this.setIsNew(false);
              this.setEditId(false);
            })
          }
        });
      },
      editData(item) {
        this.setItem(item);
        this.setIsNew(false);
        this.setIsEditing(true);
        this.$forceUpdate();
      },
      can(permit,permissions){
        return isPermits(permit,permissions);
      }
    },
  
  }
  </script>
  
  <style scoped lang="scss">
  .user_img{
    img {
      width: 100%;
    }
  }
  
  .event_detail_wrapper{
    padding:10px 0px;
  
    .event_ls_label{
      font-weight: bold;
    }
  
    .row{
      .col-md-8{
        border-bottom:1px dotted #ccc;
        padding-bottom:5px;
      }
      .col-md-3{
        border-bottom:1px dotted #ccc;
        padding-bottom:5px;
      }
    }
  
    .card-body{
      box-shadow: 0px 0px #ffffff;
    }
  }
  </style>