<template>
  <span class="back-button">
    <RouterLink :to="to" />
  </span>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    to: Object,
  }
}
</script>

<style scoped lang="scss">
.back-button {
  margin-right: 10px;
  a {
    background-color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 70%;
      height: 70%;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCgYKNTmTsdoqAAAAwElEQVRo3u3XIQ7CMBiG4RcES0m4BQKJJyQD7jjBCTAYBAZOgeAEJCQIFgIokhXbGiZI+098VU0qnret+iHVKqh4cGaZTGjh93g8njsjS97jmdryV4aW/JsyLz9gF/Er8eLFixcvXrx48eLFi/93bSI++6Q7Ts/3f572gn3DJ/f9AbbBGzyZ5w9wHKJvWChBCUpQghK6mVBaJ7yUoAQlKEEJSuhWwjGapmbWCRcK64RJ/oAw4YazCADHmppT20T9BaF0pomRQnpnAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTA2VDEwOjUzOjU3KzAwOjAwnhI7DQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0wNlQxMDo1Mzo1NyswMDowMO9Pg7EAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMDZUMTA6NTM6NTcrMDA6MDC4WqJuAAAAAElFTkSuQmCC);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      left: 8%;
      top: 15%;
    }
  }
}
</style>