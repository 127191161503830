import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
           current_password : null,
           new_password     : null,
           confirm_password : null
           
        },
        loading     : false,
        is_new      : false,
        is_editing  : false,
    }
}

const getters = {
    item: state         => state.item,
    loading: state      => state.loading,
    is_new: state       => state.is_new,
    is_editing: state   => state.is_editing,
}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;
      
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/users/change-password', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    
    setCurrentPassword({ commit }, value) {
        commit('setCurrentPassword', value)
    },
    setNewPassword({ commit }, value) {
        commit('setNewPassword', value)
    },
    setConfirmNewPassword({ commit }, value) {
        commit('setConfirmNewPassword', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setEditId({ commit }, id) {
        commit('setEditId', id)
    }
    
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setCurrentPassword(state,value){
        state.item.current_password = value;
    },
    setNewPassword(state,value){
        state.item.new_password = value;
    },
    setConfirmNewPassword(state,value){
        state.item.confirm_password = value;
    },
    setIsNew(state, value) {
        state.is_new = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
