<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Meetings</h1>
        <span v-if="can('meetings_create',checkPermssions)">
          <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create New Meeting</button>
          <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
        </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid meeting-container">
        <div class="row 21212">
          <div class="col-lg-12">
            <OverlayLoading :isLoading="loading" />
            <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="perPage" v-if="can('meetings_view',checkPermssions)">
              <div class="row">
                <div class="col-md-12">
                  <div class="row search_bar">
                    <div class="col-md-2">
                      <div class="input-group w-70">
                        <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span class="ml-5 rpg_txt">records per page</span>
                    </div>
                    <div class="col-md-2 offset-md-8 ">
                      <div class="input-group">
                        <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                        <span class="input-group-text">
                          <i class="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelhdwrapper row">
                <span class="col1">Title</span>
              </div>
              <TogglePrimaryActive v-if="is_new">
                <template v-slot:default>
                 <MeetingsCreateForm  :languages="languages" :event="event"/>
                </template>
              </TogglePrimaryActive>
              <TogglePrimary v-for="(livePresentation, index) in props.all" v-bind:key="index">
                <template v-slot:label>
                  <span class="labelwrapper">
                      <span class="col1">{{ livePresentation.translations[0]['title'] }}</span>
                      <span class="col2"></span>
                      <span class="col3"></span>
                      <span class="col4"></span>
                  </span>
                </template>
                <template v-slot:default>
                 <MeetingEditForm :id="livePresentation._id" v-if="is_new === false && is_editing === true && livePresentation._id === item._id" :event="livePresentation" :languages="languages"/>
                 <ShowItem :item="livePresentation" :id="livePresentation._id"  :checkPermssions="checkPermssions" v-else />
                </template>
              </TogglePrimary>
            </SearchableContainer>
          </div>
        </div>
        <FlashMessage group="meetings_index" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import MeetingsCreateForm  from "@/components/cruds/Meetings/Create"
import ShowItem from "@/components/cruds/Meetings/Show";
import MeetingEditForm from "@/components/cruds/Meetings/Edit";
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
  name: "MeetingsIndex",
  components: {
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    MeetingsCreateForm,
    ShowItem,
    MeetingEditForm,
    TogglePrimaryActive
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.meetings_index;
  },
  created() {
    //this.fetchPermissions();
    this.verifyPermissions(this.$route.params.event_id)
     
    this.fetchData(this.$route.params.event_id).catch(() => {
        this.$flashMessage.show({
            group: 'meetings_index',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
        });
    })
  },
  computed:{
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters('MeetingRoomsIndex', ['loading', 'all', 'event' , 'languages','event_timezone']),
    ...mapGetters('MeetingRoomsSingle', ['item', 'is_new', "is_editing"]),

  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('MeetingRoomsIndex', ['fetchData']),
    ...mapActions('MeetingRoomsSingle', ['resetState', "setIsNew", "setIsEditing"]),
   
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }

  .card-box {
    background: #cccccc4d;
    border-radius: 10px;
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
  }
}
</style>