import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            id: null,
            title: null,
            permissions: [],
            access_level: 'platform',
        },
        permissionsAll: [],
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    permissionsAll: state => state.permissionsAll,
    
}

const actions = {
    storeData({ commit, state, dispatch }) {
       // commit('setLoading', true)

        const params = {
            title: state.item.title,
            permissions: state.item.permissions,
            access_level: state.item.access_level,
        };
        return axios.post(constants.API_AUTH_URL + '/api/v1/roles', params)
            .then(response => {
                commit('resetState')
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
       // commit('setLoading', true)

        const params = {
            title: state.item.title,
            permissions: state.item.permissions,
            access_level: state.item.access_level,
        };

        return axios.patch(constants.API_AUTH_URL + '/api/v1/roles/' + state.item._id, params)
            .then(response => {
                commit('setItem', response.data.data);
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, id) {
        return axios.get(constants.API_AUTH_URL + '/api/v1/roles/' + id)
            .then(response => {
                commit('setItem', response.data.data)
                dispatch('fetchPermissionsAll')

                return response;
            })
    },
    removeData({ commit, dispatch }, id) {
        return axios.delete(constants.API_AUTH_URL + '/api/v1/roles/' + id)
            .then(response => {
                commit('resetState');
                return response;
            });
    },
    fetchPermissionsAll({ commit }) {
        return axios.get(constants.API_AUTH_URL + '/api/v1/permissions')
            .then(response => {
                commit('setPermissionsAll', response.data.data)
                return response;
            })
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setAccessLevel({ commit }, value) {
        commit('setAccessLevel', value)
    },
    setPermission({ commit }, value) {
        commit('setPermission', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setItem({ commit }, value) {
        commit('setItem', value)
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setName(state, value) {
        state.item.title = value
    },
    setAccessLevel(state, value) {
        state.item.access_level = value;
    },
    setPermission(state, value) {
        state.item.permissions = value
    },
    setPermissionsAll(state, value) {
        state.permissionsAll = value
    },
    
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
