<template>
  <GeneralDashboardLayout>
   

      <template v-slot:header>
          <div class="header-template">
                <BackButton :to="{ name: 'event_dashboard.index' }" />
                <h1 class="title text-center">Leader Board</h1>
                <button class="btn btn-outline-primary" @click="showModal" style="margin-left:1057px;" v-if="can('event_leaderboard_view',checkPermssions)">Leaderboard</button>
                <span v-if="can('event_leaderboard_edit',checkPermssions)">
                    <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Edit Reward Settings</button>
                    <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
                </span>
          </div>
      </template>
      <template v-slot:content>
          <div class="container-fluid user-container">
              <div class="row">
                  <div class="col-lg-12">

                    <TogglePrimaryActive v-if="is_new" class="editReward">
                                <template v-slot:default >
                                    <LeaderBoardsEditContentForm :allContent="allContent"  />
                                </template>
                            </TogglePrimaryActive>
                        
                      <div id="leader-board"  v-if="is_new == false">
                        <OverlayLoading :isLoading="loading" />

                            

                        <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage" v-if="can('event_leaderboard_view',checkPermssions)" >
                        <div class="labelhdwrapper row">
                                  <span class="col1">Module</span>
                                  <span class="col2"></span>
                                  <span class="col3"></span>
                                  <span class="col4">Ponts</span>
                              </div>
                           
                            
                              <TogglePrimary v-for="(leaderboard, index) in props.all" v-bind:key="index" >
                           
                                  <template v-slot:label>
                                      <span class="labelwrapper">
                                          <span class="col1">{{ leaderboard.module_label }}</span>
                                          <span class="col2"></span>
                                          <span class="col3"></span>
                                          <span class="col4">{{ leaderboard.module_points }}</span>
                                      </span>
                                  </template>
                                  <template v-slot:default>
                                    <LeaderBoardsEditForm v-if="is_new === false && is_editing === true && leaderboard._id === item._id" :leaderboard="leaderboard" :leaderboardId="leaderboard._id" />
                                    <LeaderBoardsShowForm :leaderboard="leaderboard" :checkPermssions="checkPermssions" v-else />
                                </template>
       
                              </TogglePrimary>
                          </SearchableContainer>
                      </div>
  
                  </div>
              </div>
              <FlashMessage group="leader_board_index" />
          </div>
 <!--Modal-->
 <LeaderBoardsModel v-show="isModalVisible" @close="closeModal" ></LeaderBoardsModel>
  </template>
  </GeneralDashboardLayout>
  </template>
  
  <script>
  import {
      mapGetters,
      mapActions
  } from 'vuex'
  import BackButton from "@/components/Commons/BackButton";
  import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
  import TogglePrimary from "@/components/Commons/TogglePrimary";
  import SearchableContainer from "@/components/Commons/SearchableContainer";
  import LeaderBoardsEditForm from "@/components/cruds/LeaderBoards/Forms/Edit";
  import LeaderBoardsShowForm from "@/components/cruds/LeaderBoards/Forms/Show";
  import LeaderBoardsEditContentForm from "@/components/cruds/LeaderBoards/Forms/EditLeaderBoardContent";
  import LeaderBoardsModel from "@/components/cruds/LeaderBoards/Forms/reportModel";
  import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";
  
  import _ from "lodash";
  import { isPermits} from "@/services/CheckPermissions";

  export default {
      name: 'LeaderBoardIndex',
      components: {
          SearchableContainer,
          TogglePrimary,
          BackButton,
          GeneralDashboardLayout,
          LeaderBoardsEditForm,
          LeaderBoardsShowForm,
          LeaderBoardsEditContentForm,
          LeaderBoardsModel
      },
      data() {
          return {
              search: null,
              perPage: 15,
              system_users: [],
              isModalVisible:false,

          }
      },
      created() {
        this.verifyPermissions(this.$route.params.event_id)
        this.fetchData(this.$route.params.event_id).catch(() => {
            this.$flashMessage.show({
                group: 'leader_board_index',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong.'
            });
        })
      },
      beforeUnmount() {
          this.resetState();
          delete this.$flashMessage.groups.leader_board_index;
    
      },
      computed: {
            ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
            ...mapGetters('LeaderboardIndex', ['loading', 'all', 'allContent']),
            ...mapGetters('LeaderboardSingle', ['item', 'is_new', "is_editing"]),
          
      },
      methods: {
            ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
            ...mapActions('LeaderboardIndex', ['fetchData','fetchLeaderboardReport']),
            ...mapActions('LeaderboardSingle', ['resetState', "setIsNew", "setIsEditing","setContent"]),
            createClick() {
                this.setIsNew(true);
                this.setIsEditing(false);
                this.setContent(this.allContent);
            },
            cancelClick() {
                this.resetState();
                this.fetchData(this.$route.params.event_id);
            },
            perRowChange(e) {
                this.perPage = Number(e.target.value);
            },
            can(permit,permissions){
                return isPermits(permit,permissions);
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .header-template {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
  
      .title {
          font-size: 1.6rem;
      }
  }
  
  .user-container {
      .toggle label {
          color: #000 !important;
          border-radius: 0px;
  
      }
  
      .toggle.active>label {
          color: #fff !important;
      }
  
      .toggle section {
          // box-shadow: -1px 0px 5px 2px #ccc;
          border-bottom: 1px solid #ccc;
      }
  
      .toggle-content {
          padding: 35px;
  
      }
  
      .form-control {
          font-family: 'Poppins';
      }
  
      .desc_btn_wrapper {
          margin-top: 20px;
      }
  
      .snippet {
          border-radius: 0.2rem;
          border: 1px solid #c9c9c9;
          padding: 24px 22px;
          margin: 15px 0;
          background-color: white;
      }
  
      .snippet .btn-light {
          color: #000 !important;
      }
  
      .edit_panel {
          background: #d5e3eb;
          padding: 15px;
          margin-top: 10px;
      }
  
      .snippet {
          margin-top: 0px;
      }
  
      .note-editor .btn-light {
          color: #000 !important;
      }
  
      .setting_wrapper {
          float: right;
      }
  
      .setting_wrapper a {
          color: #201e1eba;
          padding: 9px;
          margin-left: 5px;
          background: #c0cdd345;
          border-radius: 4px;
          border: 1px solid #80808054;
  
      }
  
      .labelwrapper {
          width: 100%;
      }
  
      .labelhdwrapper {
          width: 100%;
          margin-left: 0px;
          border: 1px solid #ccc;
          padding: 10px;
          background: #e1e1e1;
      }
  
      .labelhdwrapper span {
          font-weight: bold;
      }
  
      .col1 {
          width: 25%;
          display: inline-flex;
      }
  
      .col2 {
          width: 25%;
          display: inline-flex;
      }
  
      .col3 {
          width: 25%;
          display: inline-flex;
      }
  
      .col4 {
          width: 20%;
          display: inline-flex;
      }
  
      .search_bar {
          margin-bottom: 20px;
      }
  
      .eventlabel {
          font-size: 1.3em;
          font-weight: bold;
          margin-bottom: 10px;
      }
  
      .event_item {
          color: #fff;
          padding: 8px 15px;
          border-radius: 8px;
          background: #afbcc3;
          min-width: 50px;
          display: inline-flex;
          margin: 5px;
      }
  
      .nav-link.active {
          border-top-color: #0088CC;
      }
  
      .toggle.active>label {
          background: #0a72e0;
      }
  
      .nav-tabs li.active a {
          color: #fff !important;
          background: #0a72e0 !important;
          font-size: 1.2em;
      }
  
      .nav-tabs li a {
          font-size: 1.2em;
          color: #333;
      }
  
      .toggle>label {
          background: none !important;
          font-size: 1em !important;
      }
  
      .toggle .toggle {
          margin: 0px;
      }
  
      .toggle {
          margin: 0px;
      }
  
      .toggle_odd {
          background: #f5f5f5;
      }
  
      .btn-primary {
          color: #fff !important;
          background-color: #0c78e0 !important;
  
      }
  
      .nav-tabs li .nav-link,
      .nav-tabs li .nav-link:hover {
          background: #cccccc94 !important;
      }
  
      .pageview {
          font-family: 'Poppins';
          font-size: 1em !important;
      }
  
      .w-70 {
          width: 30%;
          display: inline-flex;
      }
  
      .rpg_txt {
          display: inline-block;
          margin-left: 10px;
      }
  
      .nav-tabs li .nav-link,
      .nav-tabs li {
          border-right: 1px solid #EEE;
      }
  
      .toggle_odd {
          .event_detail_wrapper {
              .row {
                  .col-md-8 {
                      border-bottom: 0px dotted #ccc;
                      padding-bottom: 5px;
                  }
              }
          }
      }
  
      .text-right {
          text-align: right !important;
          margin-right: 100px;
          background: none;
      }
  
      .toggle_odd {
          .text-right {
              margin-right: 120px;
          }
      }
  
      .btn-info {
          background: #6c757d !important;
      }
  
      .profile_info {
          text-align: center;
          margin-top: 40px;
      }
  
      .card-box {
          background: #cccccc4d;
          border-radius: 10px;
          padding: 30px;
          min-height: 300px;
          font-size: 1.1em;
  
          h2 {
              font-size: 1.5em;
              font-weight: bold;
              letter-spacing: 0.01em;
              margin-bottom: 20px;
          }
      }
  }
  
  </style>
  