import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"

function initialState() {
    return {
        all: [],
        loading: false
    }
}

const getters = {
    loading     :   state => state.loading,
    all         :   state => state.all    
}

const actions = {

    fetchMeetingById({ commit, state ,dispatch}, meetingId) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
       return axios.get(constants.API_URL + '/api/meetings/'+meetingId, {headers: headers})
        .then(response => {
            commit('setAll', response.data.data.hosts)
        })
        .catch(error => {
            console.log("Error",error);
        })

    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAll(state, items) {
        state.all = items
    },
    setLoading(state, loading) {
        state.loading = loading
    },
   
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
