<template>
    <div class="toggle toggle-primary" data-plugin-toggle ref="toggle">
      <section class="toggle active">
        <label>
          <slot name="label"/>
        </label>
        <div class="toggle-content">
          <slot name="default"/>
        </div>
      </section>
    </div>
    </template>
    
    <script>
    export default {
      name: "TogglePrimary",
      props: {
        options: {
          type: Object,
          default() {
            return {'isAccordion': false};
          }
        }
      },
      mounted() {
        const element = this.$refs.toggle;
        const $element = window.$(element);
        $element.themePluginToggle(this.options);
      },
    }
    </script>
    
    <style scoped lang="scss">
    .toggle {
      label{
        color:#000!important;
        border-radius: 0px;
      }
      &.active > label{
        color:#fff!important;
      }
    
      section{
        box-shadow: -1px 0px 5px 2px #ccc;
      }
      .toggle-content{
        padding:15px;
      }
      
    }
    .toggle.active i.fa-minus{
      display: none!important;
    }
    </style>