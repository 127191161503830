<template>
  <div class="overlay-loader" v-if="isLoading">
    <div class="overlay-loader--container">
      <div class="overlay-loader--element">
        <div class="overlay-loader--item">
          <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="200" height="200" stroke="#20abe6">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".25" cx="18" cy="18" r="18"/>
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite"/>
                </path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayLoading",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

.overlay-loader {
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: #ffffffe8;
}

.overlay-loader .overlay-loader--container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-loader .overlay-loader--element {
  width: auto;
}
</style>