<template>
  <div class="row">
  
    <div class="col-md-12 event_detail_ls">
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Title</div>
          <div class="col-md-10">{{getName()}}</div>
        </div>
      </div>

      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Link</div>
          <div class="col-md-10">{{getLink()}}</div>
        </div>
      </div>

      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Open in New Window</div>
          <div class="col-md-10">{{getNewWindow()}}</div>
        </div>
      </div>
    
      </div>
      <div class="text-right mt-3 mr-5">
      <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(footerlink)">Edit</button>
      <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="removeBlog(footerlink)">Delete</button>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from "lodash";
import constants from "@/config/constants";

export default {
  name: "FooterLinkShow",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    footerlink: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('FooterLinkIndex', ['fetchData', 'setEditId']),
    ...mapActions('FooterLinkSingle', ['setIsNew', 'destroyData','setItem','setIsEditing']),

    getName() {
      return _.get(this.footerlink, 'title', '');
    },
    getLink() {
      return _.get(this.footerlink, 'url', '');
    },
    getNewWindow() {
      return _.get(this.footerlink, 'is_open_new_window', '');
    },

    editData(item) {
      this.setItem(item);
      this.setIsEditing(true);
      //console.log(id)
      //this.setEditId(id);
      this.setIsNew(false);
    },
    removeBlog(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData(item._id)
              .then(() => {
                this.$flashMessage.show({
                        group: 'footerlink',
                        type: 'success',
                        title: 'Success',
                        text: 'Footer Link deleted successfully.'
                      });
                this.fetchData(this.$route.params.event_id);
              });
        }
      });
    },
  }
}
</script>


<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
}
</style>