import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id: null,
            grace_period: 0,
            max_cap_training_room: 0,
            max_cap_roundtable_room: 0,
            max_cap_auditorium_live: 0,
            max_cap_exhibitoin_area: 0,
            max_cap_panel_discussion: 0,
            socket_server_address: 0,
            socket_server_port: null,
            max_cap_panel_discussion_2d: 0,
            max_cap_roundtable_room_2d: 0,
        },
        is_edit: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    is_edit: state => state.is_edit,
    loading: state => state.loading,
}

const actions = {
    fetchData({ commit, state }) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/project_configuration/', { headers: headers })
            .then(response => {
                commit('setItem', response.data.data[0])
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                console.error(message)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true);

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.is_deleted;
        delete params.createdAt;
        delete params.updatedAt;
        
        commit('setLoading', false)
        
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        return axios.patch(constants.API_URL + '/api/project_configuration/' + state.item._id, params, { headers: headers })
            .then(response => {
                commit('setItem', response.data.data.value);                
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })

        
    },

    setGracePeriod({ commit }, value) {        
        commit('setGracePeriod', value)
    },

    setMaxCapTrainingRoom({ commit }, value) {
        commit('setMaxCapTrainingRoom', value)
    },

    setMaxCapRoundtableRoom({ commit }, value) {
        commit('setMaxCapRoundtableRoom', value)
    },

    setMaxCapAuditoriumLive({ commit }, value) {
        commit('setMaxCapAuditoriumLive', value)
    },

    setMaxCapExhibitoinArea({ commit }, value) {
        commit('setMaxCapExhibitoinArea', value)
    },

    setMaxCapPanelDiscussion({ commit }, value) {
        commit('setMaxCapPanelDiscussion', value)
    },

    setSocketServerAddress({ commit }, value) {
        commit('setSocketServerAddress', value)
    },

    setSocketServerPort({ commit }, value) {
        commit('setSocketServerPort', value)
    },

    setMaxCapPanelDiscussion2d({ commit }, value) {
        commit('setMaxCapPanelDiscussion2d', value)
    },

    setMaxCapRoundtableRoom2d({ commit }, value) {
        commit('setMaxCapRoundtableRoom2d', value)
    },

    setIsEdit({ commit }, value) {
        commit('setIsEdit', value);
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item               
    },

    setGracePeriod(state, value) {
        state.item.grace_period = value        
    },

    setMaxCapTrainingRoom(state, value) {
        state.item.max_cap_training_room = value
    },

    setMaxCapRoundtableRoom(state, value) {
        state.item.max_cap_roundtable_room = value
    },

    setMaxCapAuditoriumLive(state, value) {
        state.item.max_cap_auditorium_live = value
    },

    setMaxCapExhibitoinArea(state, value) {
        state.item.max_cap_exhibitoin_area = value        
    },

    setMaxCapPanelDiscussion(state, value) {
        state.item.max_cap_panel_discussion = value
    },

    setSocketServerAddress(state, value) {
        state.item.socket_server_address = value
    },

    setSocketServerPort(state, value) {
        state.item.socket_server_port = value
    },

    setMaxCapPanelDiscussion2d(state, value) {
        state.item.max_cap_panel_discussion_2d = value        
    },

    setMaxCapRoundtableRoom2d(state, value) {
        state.item.max_cap_roundtable_room_2d = value
    },

    setLoading(state, loading) {
        state.loading = loading
    },
    setIsEdit(state, value) {
        state.is_edit = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
        console.log('reset')
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
