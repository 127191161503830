import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"

function initialState() {
    return {
        item: {
            translations: [],
            type: null,
            section_color: '#222222',
            content_section:[],
        },
        languagesAll: [],
        error_translation_tab :false,
        error_general_tab :false,
       
        typesEnum: [{ value: 'Attendee', label: 'Attendee' }, { value: 'Speaker', label: 'Speaker' }, { value: 'Exhibitor', label: 'Exhibitor' }, { value: 'Panelist', label: 'Panelist' }, { value: 'Faq', label: 'Faq' }],
        loading: false,
        is_new: false,
        is_editing: false,
    }
}

const getters = {
    item                        : state => state.item,
    loading                     : state => state.loading,
    languagesAll                : state => state.languagesAll,
    typesEnum                   : state => state.typesEnum,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
    error_translation_tab       : state => state.error_translation_tab,
    error_general_tab           : state => state.error_general_tab,
    selectedLanguageKey         : state => state.lang_key,
    selectedLanguageTitle       : state => state.lang_title,
   

}

const actions = {
    async storeData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.content_section;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/doc-section', params, { headers: headers })
        .then(response => {
            commit('resetState')
         
            //commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
    },



    async updateData({ commit, state, dispatch }) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params.__v;
        delete params._id;
        delete params.__v;
        delete params.created_at;
        delete params.updated_at;
        delete params.created_by;
        delete params.content_section;
        delete params.is_deleted;
        delete params.status;
        delete params.updatedAt;
        delete params.createdAt;
        _.map(params.translations, i => delete i._id);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/doc-section/'+state.item._id, params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('resetState')
            //commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
    },

    fetchData({ commit, dispatch }, params) {
        axios.get('/api/v1/docs-sections/' + params.id)
            .then(response => {
                commit('setItem', response.data.data)
            })
    },

    fetchLanguagesAll({ commit,dispatch }) {
        axios.get(`${constants.API_URL}/api/language`)
            .then(response => {
                commit('setLanguagesAll', response.data.data)
            })
    }, 
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setType({ commit }, value) {
        commit('setType', value)
    },
    setSectionColor({ commit }, value) {
        commit('setSectionColor', value)

    },
    setSort_order({ commit }, value) {
        commit('setSort_order', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setErrorGeneralTab({commit},value){
        commit('setErrorGeneralTab', value)
    },
    setTimeZone({ commit }, value){
        commit('setTimeZone', value)
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },
    setErrorTranslationTab({commit},value){
        commit('setErrorTranslationTab', value)
    },
    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setItem({commit},value){
        commit('setItem', value)
    }
}

const mutations = {
    setTranslations(state, value) {
         if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null,  }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;
    },
    setErrorGeneralTab(state,value){
        state.error_general_tab = value
    },
    setErrorTranslationTab(state, value) {
        state.error_translation_tab = value
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setLanguage(state, value) {
        state.item.language = value
    },
    setLanguagesAll(state, value) {
        state.languagesAll = value
    },
    setType(state, value) {
        state.item.type = value
    },   
    setLoading(state, loading) {
        state.loading = loading
    },
    setSectionColor(state, value) {
        state.item.section_color = value

    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setTitle(state, value) {
    },
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
