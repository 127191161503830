import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id: null,
            company_name: null,
            phone: null,
            company_email: null,
            contactus_email: null,
            company_logo: null,
            address: null,
            company_description: null,
        },
        is_new: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    is_new: state => state.is_new,
    loading: state => state.loading,
}

const actions = {
    fetchData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/company/' + id, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .catch(error => {
                const message = error.response.data.message || error.message
                console.error(message)
            })
    },
    storeData({ commit, state }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;

       /* if (!_.has(params.company_logo, 'key')) {
            delete params.company_logo;
        }*/

        if (params.company_logo) {
            params.company_logo = {
                old_key: state.item.company_logo.key,
                key: state.item.company_logo.key,
                temp_key:  state.item.company_logo.temp_key,
                size: state.item.company_logo.size,
                extension: state.item.company_logo.extension,
                uploadedBy: state.item.company_logo.uploadedBy,
                uploadedAt: state.item.company_logo.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/company', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.is_deleted;
        delete params.status;
        delete params.createdAt;
        delete params.updatedAt;
        if (params.company_logo) {
            params.company_logo = {
                key: state.item.company_logo.key,
                old_key: state.item.company_logo.key,
                size: state.item.company_logo.size,
                extension: state.item.company_logo.extension,
                uploadedBy: state.item.company_logo.uploadedBy,
                uploadedAt: state.item.company_logo.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/company/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data);
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })

    },
    destroyData({commit, state}, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/company/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    setCompany_name({ commit }, value) {
        commit('setCompany_name', value)
    },
    setPhone({ commit }, value) {
        commit('setPhone', value)
    },
    setCompany_email({ commit }, value) {
        commit('setCompany_email', value)
    },
    setContactus_email({ commit }, value) {
        commit('setContactus_email', value)
    },
    setCompany_logo({ commit }, value) {
        commit('setCompany_logo', value)
    },
    setAddress({ commit }, value) {
        commit('setAddress', value)
    },
    setCompany_description({ commit }, value) {
        commit('setCompany_description', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setCompany_name(state, value) {
        state.item.company_name = value
    },
    setPhone(state, value) {
        state.item.phone = value
    },
    setCompany_email(state, value) {
        state.item.company_email = value
    },
    setContactus_email(state, value) {
        state.item.contactus_email = value
    },
    setCompany_logo(state, value) {
        state.item.company_logo = value
    },
    setAddress(state, value) {
        state.item.address = value
    },
    setCompany_description(state, value) {
        state.item.company_description = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setIsNew(state, value) {
        state.is_new = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
        console.log('reset')
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
