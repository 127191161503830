import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {},
        categoryEnum: [ { value: 'SILVER', label: 'SILVER' }, { value: 'GOLD', label: 'GOLD' }, { value: 'PLATINUM', label: 'PLATINUM' }, ],
        is_new: false,
        is_editing: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    is_new: state => state.is_new,
    categoryEnum: state => state.categoryEnum,
}

const actions = {
    storeData({ commit, state }, event_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        console.log("params",params);
        if (params.logo) {
            params.logo = {
                old_key: state.item.logo.key,
                temp_key:  state.item.logo.temp_key,
                size: state.item.logo.size,
                extension: state.item.logo.extension,
                uploadedBy: state.item.logo.uploadedBy,
                uploadedAt: state.item.logo.uploadedAt,
            }
        }
        params.event_id = event_id;
        console.log("params after",params);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/events/' + event_id + '/sponsors', params, {headers: headers})
            .then(response => {
                commit('resetState')
            }).finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state }, event_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;

        if (params.logo) {
            params.logo = {
                old_key: state.item.logo.key,
                temp_key:  state.item.logo.temp_key,
                size: state.item.logo.size,
                extension: state.item.logo.extension,
                uploadedBy: state.item.logo.uploadedBy,
                uploadedAt: state.item.logo.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/events/' + event_id + '/sponsors/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit }, {event_id, id}) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/events/' + event_id + '/sponsors/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    fetchData({ commit, dispatch }, {event_id, id}) {
        console.log("Working hit");
        console.log(event_id, id);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/events/' + event_id + '/sponsors/' + id, {headers: headers})
            .then(response => {
                commit('setItem', _.get(response, 'data.data', {}));
                return response;
            })
    },
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setLink({ commit }, value) {
        commit('setLink', value)
    },
    setLogo({ commit }, value) {
        commit('setLogo', value)
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setCategory({ commit }, value) {
        commit('setCategory', value)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setName(state, value) {
        state.item.name = value
    },
    setLink(state, value) {
        state.item.link = value
    },
    setLogo(state, value) {
        state.item.logo = value
    },
    setCategory(state, value) {
        state.item.category = value
    },
    setEventsAll(state, value) {
        state.eventsAll = value
    },
    setCategoryEnum(state, value) {
        state.categoryEnum = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
