<template>
  <div class="row">
    <div class="col-md-10 event_detail_ls">      
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">grace_period</div>
          <div class="col-md-10">{{ configurations.grace_period }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_training_room</div>
          <div class="col-md-10">{{ configurations.max_cap_training_room }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_roundtable_room</div>
          <div class="col-md-10">{{ configurations.max_cap_roundtable_room }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_auditorium_live</div>
          <div class="col-md-10">{{ configurations.max_cap_auditorium_live }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_exhibitoin_area</div>
          <div class="col-md-10">{{ configurations.max_cap_exhibitoin_area }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_panel_discussion</div>
          <div class="col-md-10">{{ configurations.max_cap_panel_discussion }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">socket_server_address</div>
          <div class="col-md-10">{{ configurations.socket_server_address }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">socket_server_port</div>
          <div class="col-md-10">{{ configurations.socket_server_port }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_panel_discussion_2d</div>
          <div class="col-md-10">{{ configurations.max_cap_panel_discussion_2d }}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">max_cap_roundtable_room_2d</div>
          <div class="col-md-10">{{ configurations.max_cap_roundtable_room_2d }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="setting_wrapper">        
        <a class="" @click="editData()" href="#">
          <i class="fa fa-edit"></i>
        </a>
      </div>
    </div>
  
  </div>
  
</template>

<script>
import constants from "@/config/constants";
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "ShowItem",  
  props:['configurations'],
  created() {
    this.fetchData();
  },
  methods:{
    ...mapActions('ConfigurationsSingle', [ 'fetchData','setIsEdit']),    
    editData() {
      this.setIsEdit(true);      
    },
  }
 
};
</script>

<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
}
</style>