<template>
  <div class="row">
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Speaker</div>
          <div class="content_label_text" v-if="item.event_users">
            {{ item.event_users.first_name +" "+ item.event_users.last_name }}
          </div>
        </div>
        <div class="row">
          <div class="event_ls_label col-md-2">Support User</div>
          <div class="content_label_text" v-if="item.event_userss">
            {{ item.event_userss.first_name +" "+ item.event_userss.last_name}}
          </div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Start Time</div>
          <div class="col-md-8">{{item.start_time}}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">End Time</div>
          <div class="col-md-8">{{item.end_time}}</div>
        </div>
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Description</div>
          <div class="col-md-8">
            <div v-if="item.event" v-html="item.event.language.length && item['description_'+item.event.language[0].language]"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mt-3 mr-5">
      <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(item)">Edit</button>
      <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(item)">Delete</button>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex';
import moment from 'moment';
import axios from "axios";
import constants from "@/config/constants";

export default {
  name: "ShowItem",
  data() {
    return {
      speaker_name: null,
    }
  },
  props: {
    item: {
      type: Object,
    }
  },

  methods: {
    ...mapActions('PanelAgendasIndex', ['fetchData', 'destroyData']),
    ...mapActions('PanelAgendasSingle', ['setItem', 'setIsNew', 'setIsEditing']),
    deleteData(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData(item._id).then(() => {
            this.fetchData();
            this.setIsNew(false);
            this.setIsEditing(false);
          })
        }
      });
    },
    editData(item) {
      this.setItem(item);
      this.setIsNew(false);
      this.setIsEditing(true);
      this.$forceUpdate();
    },
  },

}
</script>

<style lang="scss" scoped>
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}

.tabs-vertical {
  border-top: 0px;
}

.toggle-content{
  background:#fff;
}
</style>
