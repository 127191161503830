import axios from 'axios';
import constants from "@/config/constants";

export default async function auth(to, from, next) {
    console.log("Middleware hit");
    console.log("Middleware hit token",localStorage.getItem('token'));
    console.log("Middleware hit user",localStorage.getItem('user'));
    console.log("Middleware hit userid",localStorage.getItem('user_id'));
    if (localStorage.getItem('token')) {
        if (localStorage.getItem('user')) {
            return next();
        }

        if (localStorage.getItem('user_id')) {
            try {
                const resp = await axios.get(constants.API_AUTH_URL + '/api/v1/users/' + localStorage.getItem('user_id'));
                localStorage.setItem('user', JSON.stringify(resp.data.data));
                return next();
            } catch (e) {
                console.log("exception middleware:",e);
                return next({
                    name: 'login'
                });
            }
        }
    }
    
    return next({
        name: 'login'
    });
}