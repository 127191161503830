import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"



function initialState() {
    return {
        item: {
                _id:null,
                translations: [],
                module_type: null,
           },
        languagesAll: [], 
        lang_key : null,
        lang_title : null,
        lang_descirption : '',
        
       
        moduleEnum: [ 
            { value: 'Registration', label: 'Registration' },
            { value: 'Event Hall', label: 'Event Hall' },
            { value: 'Live Presentation', label: 'Live Presentation'},
            { value: 'Panel Discussion', label: 'Panel Discussion' },
            { value: 'On Demand', label: 'On Demand' }, 
            { value: 'Virtual Round Table', label: 'Virtual Round Table' }, 
            { value: 'Meeting Session', label: 'Meeting Session' },
            { value: 'Meeting Room', label: 'Meeting Room' } ,
            { value: 'Event Store', label: 'Event Store' } ,
            { value: 'Meeting Session', label: 'Meeting Session' },
            { value: 'Meeting Room', label: 'Meeting Room' },
            { value: 'Event Store', label: 'Event Store' },
            { value: 'Exhibition Booth', label: 'Exhibition Booth' },
            { value: 'Technical Support', label: 'Technical Support' },
            { value: 'Navigation', label: 'Navigation' }
        ],
        showAssetsFileds:null,
        loading: false,
        is_new: false,
        is_editing: false,
        

    }
}

const getters = {
    item                        : state => state.item,
    loading                     : state => state.loading,
    element_typeEnum            : state => state.element_typeEnum,
    layoutEnum                  : state => state.layoutEnum,
    showAssetsFileds            : state => state.showAssetsFileds,
    moduleEnum                  : state => state.moduleEnum,
    languagesAll                : state => state.languagesAll,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
    selectedLanguageKey         : state => state.lang_key,
    selectedLanguageTitle       : state => state.lang_title,
    selectedLanguageDescirption : state => state.lang_descirption,
}

const actions = {
    setItem({commit,state,dispatch},value){
        commit('setItem', value)
    },
    storeData({ commit, state, dispatch },docId) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.post(constants.API_URL + '/api/doc-section/'+docId+"/page-content", params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
       
    },
    updateData({ commit, state, dispatch },docId) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        _.map(params.translations, i => delete i._id);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/doc-section/'+docId+"/page-content/"+state.item._id, params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
   
    },
    
    
    fetchData({ commit, dispatch }, params) {
        axios.get('/api/v1/docs-modules/' + params.id)
            .then(response => {
                commit('setItem', response.data.data)
            })
    },
    fetchSectionsAll({ commit }, section_id) {        
        commit('setSection', section_id)           
    },
    fetchLanguagesAll({ commit }) {
        axios.get('/api/v1/languages')
        .then(response => {
            commit('setLanguagesAll', response.data.data)
        })
    }, 
   


    setModuleType({ commit }, value) {        
        commit('setModuleType', value)
    },

    setSection({ commit }, value) {
        commit('setSection', value)
    },
    setElement_type({ commit }, value) {
        commit('setElement_type', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    
    setLinks({ commit }, value) {
        commit('setLinks', value)
    },
    setAssets({ commit }, value) {
        commit('setAssets', value)
    },
    setAssetsCaptions({ commit }, value) {
        commit('setAssetsCaptions', value)
    },
    destroyAssets({ commit }, value) {
        commit('destroyAssets', value)
    },
    destroyUploadedAssets({ commit }, value) {
        commit('destroyUploadedAssets', value)
    },
    setCaptions({ commit }, value) {
        commit('setCaptions', value)
    },
    setLayout({ commit }, value) {
        commit('setLayout', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setSort_order({ commit }, value) {
        commit('setSort_order', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setTranslations({ commit }, value) {
        commit('setTranslations', value)
    },
    setLangKey({ commit }, value) {
        commit('setLangKey', value)
    },
    setLangTitle({ commit }, value) {
        commit('setLangTitle', value)
    },
    setLangDescirption({ commit }, value) {
        commit('setLangDescirption', value)
    }
}

const mutations = {
    setTranslations(state, value) {
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "title": null, "description": '' }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;

    },

    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setLanguage(state, value) {
        state.item.language = value
    },

    setSection(state, value) {
        state.item.section_id = value
    },
    setElement_type(state, value) {
        state.item.element_type = value
        let val = value.value;
        if(value.value == undefined){
            val = value;
        }
        
        if((val.includes('Img') || val.includes('Video') || val.includes('Slider')) && !val.includes('Text')){
            state.showAssetsFileds = true
            
        }else{
            state.showAssetsFileds = false
            //setTimeout(function () { state.showAssetsFileds = false }, 200)
        }
        //debugger;
    },
    setLangDescirption(state, value) {
        state.lang_descirption = value
    },
    setLinks(state, value) {
        state.item.links = value
    },
    setAssets(state, value) {
        for (let i in value) {
            let assets = value[i];
            if (typeof assets === "object") {
                state.item.assets.push(assets);
                
            }
        }
        //console.log(state.item.assets);
    },
    setAssetsCaptions(state, parms) {
        let custom_properties = {"caption":parms.value};
        state.item.assets[parms.index].custom_properties = custom_properties;
        console.log(state.item.assets);
    },
    destroyAssets(state, value) {
        for (let i in state.item.assets) {
            if (i == value) {
                state.item.assets.splice(i, 1);
            }
        }
    },
    destroyUploadedAssets(state, value) {
        for (let i in state.item.uploaded_assets) {
            let data = state.item.uploaded_assets[i];
            if (data.id === value) {
                state.item.uploaded_assets.splice(i, 1);
            }
        }
    },
    setCaptions(state, value) {
        state.item.captions = value
    },
    setLayout(state, value) {
        state.item.layout = value
    },
    setStatus(state, value) {
        state.item.status = value
    },
    setSort_order(state, value) {
        state.item.sort_order = value
    },
    setPagesAll(state, value) {
        state.pagesAll = value
    },
    setElement_typeEnum(state, value) {
        state.element_typeEnum = value
    },
    setLayoutEnum(state, value) {
        state.layoutEnum = value
    },
    setShowAssetFields(state, value) {
        state.showAssetsFileds = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setLanguagesAll(state, value) {
        state.languagesAll = value
    },
    setModuleType(state, value) {       
        state.item.module_type = value
    }, 
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setTitle(state, value) {
    },
    setLangKey(state, value) {
        state.lang_key = value
    },
    setLangTitle(state, value) {
        state.lang_title = value
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
