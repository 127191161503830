<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="snippet general-event-config-snippet">
    
      <div class="form-group row mb-4">
        <div class="col-lg-6">

          
          <label>Title*</label>
          <div class="kt-input-icon">
            <input type="text" class="form-control" name="name" maxlength="200" placeholder="Enter Title" :value="item.title" @input="updateName" />
            <ValidationMessages :element="v$.item.title" />
          </div>
        </div>
        <div class="col-lg-6">
          <label>Link</label>
          <!-- <v-select name="category" class="" placeholder="Select Category" label="label" @option:selected="updateCategory" :options="categoryEnum" :modelValue="sponsor.category" /> -->
          <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Sponsor Link http://www.test.com" :value="item.url" @input="updateLink" />
          <ValidationMessages :element="v$.item.url" />
        </div>
      </div>
      <div class="form-group row mb-4">
        <div class="col-lg-12">
          <label>Open in new window</label>
          <br />
           
            <div class="switch switch-sm switch-primary">
                <input-switch name="is_open_new_window" :value="item.is_open_new_window" id="customSwitchesEx" class="custom-control-input"
                  @change="UpdateIsNewWindow" />
              </div>

              
          
        </div>
      </div>
     
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <FlashMessage group="footerlink_edit"/>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import InputSwitch from "@/components/Commons/InputSwitch";
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
  name: "FooterLinkEdit",
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: [],
    }
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },

    validations() {
        return {
            item: {
               
                title: {
                    required,
                },
                url: {
                    required,
                }
            }
        };
    },
  props: {
    footerlink_id: String
  },

  components: {
    InputSwitch,
    ValidationMessages,    
  }, 

  computed: {
   // ...mapGetters("BlogsSingle", {event: "item"}),
    ...mapGetters("FooterLinkSingle", [
      'item',
      'loading',
      // categoryEnum: "categoryEnum",
      ])
  },
  
  mounted() {
   // this.fetchEvent(this.$route.params.event_id)

   // this.fetchBlog(this.footerlink_id);
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.footerlink_edit;
  },
  methods: {
    // ...mapActions('EventsSingle', {
    //   fetchEvent: 'fetchData',
    // }),
    ...mapActions('FooterLinkSingle', {
      fetchBlog: "fetchData",
      setLogo: "setLogo",
      setName: "setName",
      setLink: "setLink",     
      updateData: "updateData",
      resetSponsorItem: "resetState",
      setIsNewWindow: "setIsNewWindow",
    }),
    ...mapActions('FooterLinkIndex', {
      fetchAllSponsors: "fetchData",
      setEditId: "setEditId",
    }),
    updateCategory(e) {
      this.setCategory(e.value);
    },
    updateName(e) {
      this.setName(e.target.value)
    },
    updateLink(e) {
      this.setLink(e.target.value);
    },

    UpdateIsNewWindow(e) {
      console.log('tttttt',e.target.checked)
      this.setIsNewWindow(e.target.checked);
    },
  
    getLanguageName() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'name', '');
    },
    getLanguageDescription() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'description', '');
    },
    async submitForm(e) {


      const isFormCorrect = await this.v$.$validate();

        if (!isFormCorrect) {

        _.map(this.v$.$errors, (item) => {
          if (item.$property.indexOf(['title','url'])) {
            // this.error_general_tab = true;
            // this.setErrorGeneralTab = true;
          } else {
            // this.setErrorGeneralTab = false;
          }
        });


        return
        }




      this.updateData(this.$route.params.event_id)
          .then(async(res) => {
            this.$flashMessage.show({
              group: 'footerlink',
              type: 'success',
              title: 'Success',
              text: "Footer Link updated successfully",
            });
           
            await this.fetchAllSponsors(this.$route.params.event_id);
            await this.$router.push({name: 'footer_link.index', params: {event_id: this.$route.params.event_id}});
            
            setTimeout(() =>  this.cancelEdit(), 2000);
            
          })
          .catch(error => {
            let html = gettingErrorMessage(error);
            this.$flashMessage.show({
              group: 'footerlink_edit',
              type: 'error',
              title: 'Failed',
              text: html,
              html:html
            });
          });
    },
    cancelEdit() {
      this.resetSponsorItem();
      this.setEditId(null);
    }
  }
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>