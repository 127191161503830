import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            event_id: null,
            background_picture: null,
          
        },
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
}

const actions = {
    storeData({commit, state}) {
        
    },
    updateData({commit, state}) {
        commit('setLoading', true)

        let params = _.cloneDeep(state.item);
         params = _.omitBy(params, _.isNil);
       
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;

        if (params.background_picture) {
            params.background_picture = {
                key: state.item.background_picture.key || state.item.background_picture.key,
                old_key: state.item.background_picture.key,
                size: state.item.background_picture.size,
                extension: state.item.background_picture.extension,
                uploadedBy: state.item.background_picture.uploadedBy,
                uploadedAt: state.item.background_picture.uploadedAt,
            }
        }

     return axios.patch(constants.API_URL + '/api/exhibition-hall/' + state.item._id,     params)
            .then(response => {
                commit('setItem', response.data.data.value)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, params) {
        commit('setLoading', true)
        return axios.get(constants.API_URL + `/api/exhibition-hall/${params.event_exhibition_hall_id}`)
            .then(response => {
                
                commit('setItem', response.data.data)
                return response
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    
    setEvent({ commit }, value) {
        commit('setEvent', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
   
}

const mutations = {
    
    setItem(state, item) {
        state.item = item
    },
    setEvent(state, value) {
        state.item.event_id = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
