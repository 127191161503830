<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_languages.index', params: {event_id: $route.params.event_id}}" />
      </div>
    </template>
   
    <template v-slot:content>
    
        <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Website Static Content</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#translation-settings`" :href="`#translation-settings`" data-bs-toggle="tab">Email Text</a>
            </li>
        </ul>
        <div class="tab-content">
            <div :id="`language-settings`" class="tab-pane active">

                    <div class="roles-form-create">
                      <div class="container-fluid translates-container">
                  <div class="row">
                    <div class="col-lg-12">
                      <OverlayLoading :isLoading="loading" />
                      <div v-if="item.translates">
                        <div v-for="(content, index) in item.translates" :key="index" class="row mb-3">
                          <div class="col-lg-2 v-center">
                            <label>{{ index.toUpperCase().replace(/_/g, " ") }}</label>
                          </div>
                          <div class="col-lg-10">
                            <label for="blog_link">Translation</label>
                            <div class="kt-input-icon">
                              <input
                                  type="text"
                                  class="form-control"
                                  name="translation[]"
                                  placeholder="Enter translation"
                                  @input="updateTranslation($event, index)"
                                  :value="content"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <div class="col-lg-12">
                      <button class="btn btn-primary" @click="saveData">Save</button>
                
                    </div>
                    </div>

                </div>

                  </div>

            </div>
            <div :id="`translation-settings`" class="tab-pane">
              <EventLanguageEmailTextFrom  :id="id"/>
     
            </div>
        </div>
        </div>
        <FlashMessage group="languages_event_edit" />
      
      </template>
  
</GeneralDashboardLayout>

</template>
<script>
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import EventLanguageEmailTextFrom from "@/components/cruds/EventLanguages/EditEmailText.vue"
import BackButton from "@/components/Commons/BackButton";
import {mapGetters, mapActions} from "vuex";
import _ from "lodash";

export default {
  name: "EventLanguagesEdit",
  components: {
      GeneralDashboardLayout,
      BackButton,
      EventLanguageEmailTextFrom
    },
  created() {
    this.fetchEventLangTranslates({event_id: this.$route.params.event_id, lang_id: this.$route.params.language_id})
        .catch(() => {
          this.$flashMessage.show({
            group: 'languages_event_edit',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        })
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.languages_event_edit;
  },
  computed: {
    ...mapGetters('EventLangTranslatesSingle', {item: "item", loading: "loading"}),
    translate: function() {
      return _.get(this.item, 'language_id', null);
    }
  },
  methods: {
    ...mapActions('EventLangTranslatesSingle', {
      fetchEventLangTranslates: "fetchData",
      updateData: "updateData",
      setItem: "setItem",
      resetState: "resetState"
    }),
    updateTranslation($event, index) {
      const value = $event.target.value;
      const newItem = _.cloneDeep(this.item);
      newItem.translates[index] = value;
      this.setItem(newItem)
    },
    saveData(e) {
      e.preventDefault();
      this.updateData({event_id: this.$route.params.event_id, lang_id: this.$route.params.language_id})
          .then(() => {
            this.$router.push({name: 'event_languages.index', params: {event_id: this.$route.params.event_id}});
          })
          .catch(() => {
            this.$flashMessage.show({
              group: 'languages_event_edit',
              type: 'error',
              title: 'Error',
              text: 'Something went wrong.'
            });
          })
    }
  }
}
</script>

<style lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}


.translates-container {
  .v-center {
    display: flex;
    align-self: center;
  }
  /*--------------- Tabs --------------- */
  .tabs-component {
    margin: 0;
  }

  .tabs-component-tabs {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin-bottom: 5px;
  }

  @media (min-width: 700px) {
    .tabs-component-tabs {
      border: 0;
      align-items: stretch;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1px;
    }
  }

  .tabs-component-tab {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    margin-right: 0;
    list-style: none;

    a:hover {
      color: #000;
      text-decoration: none;
    }

    &:not(:last-child) {
      border-bottom: dotted 1px #ddd;
    }

    &:hover {
      color: #666;
    }
    &.is-active {
      margin-bottom: -2px;
      display:flex;

      a:hover{
        color: #fff;
        text-decoration: none;
      }

      .tabs-component-tab-a{
        background:#3b8be4;
        color:#fff;
      }
    }

    &.is-disabled * {
      color: #cdcdcd;
      cursor: not-allowed !important;
    }
  }

  .tabs-component-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: .75em 1em;
    text-decoration: none;
  }

  .tabs-component-panels {
    padding: 2em;
  }

  @media (min-width: 700px) {
    .tabs-component-tab {
      background-color: #fff;
      border: solid 1px #ddd;
      border-radius: 3px 3px 0 0;
      margin-right: .5em;
      transform: translateY(2px);
      transition: transform .3s ease;

      &.is-active {
        border-bottom: solid 1px #fff;
        z-index: 2;
        transform: translateY(0);
      }
    }
  }

  @media (min-width: 700px) {
    .tabs-component-panels {
      background-color: #fff;
      border: solid 1px #ddd;
      border-radius: 0 6px 6px 6px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    }
  }
}
</style>