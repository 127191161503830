<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Event Booths</h1>
        <span v-if="can('booth_create',checkPermssions)">
           
          <button class="btn btn-primary" @click="createClick" v-if="is_new === false">Create a New Booth</button>
          <button class="btn btn-primary" @click="cancelClick" v-else>Cancel</button>
        </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid company-container">
        <div class="row">
          <div class="col-lg-12">
            <div id="live-presentations">
              <OverlayLoading :isLoading="loading" />
              <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage" v-if="can('booth_view',checkPermssions)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row search_bar">
                      <div class="col-md-2">
                        <div class="input-group w-70">
                          <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <span class="ml-5 rpg_txt">records per page</span>
                      </div>
                      <div class="col-md-2 offset-md-8 ">
                        <div class="input-group">
                          <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                          <span class="input-group-text">
                            <i class="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="labelhdwrapper row">
                  <span class="col2">Booth Title</span>
                </div>
                <TogglePrimaryActive v-if="is_new">
                  <template v-slot:default>
                    <BoothCreateForm />
                  </template>
                </TogglePrimaryActive>
                <TogglePrimary v-for="(booth, index) in props.all" v-bind:key="index">
                <template v-slot:label>
                      <span class="labelwrapper">
                        <span class="col2">{{ booth.title }}</span>
      
                      </span>
                </template>
   
                  <template v-slot:default>
                    <BoothEditForm :booth_id="booth._id" v-if="edit_id === booth._id" />
                 
                    <ShowItem :checkPermssions="checkPermssions" :booths="booth" v-else/>
          
                  </template>
                </TogglePrimary>
              </SearchableContainer>
            </div>
          </div>
             <FlashMessage group="booths" />
     
        </div>
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import _ from "lodash";

import { mapGetters, mapActions } from 'vuex'
import GeneralDashboardLayout from '../../layouts/GeneralDashboardLayout';
import BackButton from "@/components/Commons/BackButton";
import BoothEditForm from "@/components/cruds/Booth/Forms/Edit";
import BoothCreateForm from "@/components/cruds/Booth/Forms/Create";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import ShowItem from "@/components/cruds/Booth/Forms/Show";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
  name: 'BoothIndex',
  components: {SearchableContainer, ShowItem, TogglePrimary, BackButton, GeneralDashboardLayout,BoothEditForm,BoothCreateForm,TogglePrimaryActive},
  data() {
    return {
      search: null,
      perPage: 5,
      system_users: [],
     
    }
  },
  watch: {
        all(items) {
            this.system_users = _.filter(items, i => i.role === 'admin');
        }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.booths;
  },
  created() {
    //this.fetchPermissions()
    this.verifyPermissions(this.$route.params.event_id)
    
    this.fetchData(this.$route.params.event_id);
  },
  unmounted() {
    this.resetIndexState()
    this.resetState()
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters('BoothsIndex', ['all', 'total', 'loading', 'edit_id']),
    ...mapGetters('BoothsSingle', ['item', 'is_new', 'is_editing']),
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('BoothsIndex', {fetchData: "fetchData", resetIndexState: "resetState"}),
    ...mapActions('BoothsSingle', [ 'resetState', "setIsNew", "setIsEditing"]),
      createClick() {
        this.setIsNew(true);
      },
      cancelClick() {
        this.resetState();
      },
      perRowChange(e) {
        this.perPage = Number(e.target.value);
      },
      can(permit,permissions){
          return isPermits(permit,permissions);
      }
    
   
  }
}
</script>

<style scoped lang="scss">
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.company-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
